import React, { useEffect, useState } from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../../../components/bootstrap/Modal";
import Input from "../../../../../../components/bootstrap/forms/Input";
import { useFormik } from "formik";
import showNotification from "../../../../../../components/extras/showNotification";
import Button from "../../../../../../components/bootstrap/Button";
import {
  useClearCacheMutation,
  useGetSchoolStudentsWithoutClassQuery,
} from "../../../../../../store/features/apiSlice";
import useSortableData from "../../../../../../hooks/useSortableData";
import Checks from "../../../../../../components/bootstrap/forms/Checks";
import { nanoid } from "nanoid";
import {
  useAddStudentToClassMutation,
  useGetSchoolStudentsWithoutClassForSchoolManagerQuery,
} from "../../../../teacher/teacherApi";
import Icon from "../../../../../../components/icon/Icon";
import Logo from "../../../../../../components/Logo";
import USER_ROLES from "../../../../../../common/data/enumUserRole";
import PaginationButtons from "../../../../../../components/PaginationButtons";

const WithoutClassStudentsModal = ({
  isOpenStudentModal,
  setIsOpenStudentModal,
  classId,
}) => {
  const role = localStorage.getItem("roles");
  const isAdmin = Number(role) === USER_ROLES.ADMIN.id;
  const isSchoolManager = Number(role) === USER_ROLES.SCHOOL_MANAGER.id;

  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const schoolId = isAdmin
    ? localStorage.getItem("schoolId")
    : localStorage.getItem("school");

  const { data: wihoutClassStudentsForAdmin, refetch } =
    useGetSchoolStudentsWithoutClassQuery(
      {
        schoolId,
        page: `page=${currentPage}`,
        page_size: `page_size=${perPage}`,
      },
      { skip: !isAdmin }
    );
  const {
    data: wihoutClassStudentsForSchoolManager,
    refetch: refetchForManager,
  } = useGetSchoolStudentsWithoutClassForSchoolManagerQuery(
    {
      schoolId,
      page: `page=${currentPage}`,
      page_size: `page_size=${perPage}`,
    },
    { skip: !isSchoolManager }
  );

  const wihoutClassStudents = isAdmin
  ? wihoutClassStudentsForAdmin?.results || []
  : wihoutClassStudentsForSchoolManager?.results || [];

  const [addStudent] = useAddStudentToClassMutation();
  const [clearCache] = useClearCacheMutation();

  const { items } = useSortableData(
    filteredStudents?.length > 0
      ? filteredStudents
      : wihoutClassStudents
      ? wihoutClassStudents
      : []
  );

  useEffect(() => {
    setSelectAll(false);
  }, [isOpenStudentModal]);

  useEffect(() => {
    if (isOpenStudentModal) {
      setIsOpenStudentModal(true);
    } else {
      setIsOpenStudentModal(false);
    }
    // eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {
      students: [],
    },
    // validate,
    onSubmit: () => {
      handleUser();
    },
  });

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allItemIds = items.map((item) => item.id);
      setSelectedItems(allItemIds);
    } else {
      setSelectedItems([]);
    }
    setSelectAll(e.target.checked);
  };
  const handleCheckboxChange = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  useEffect(() => {
    if (selectedItems) {
      formik.setFieldValue("students", selectedItems);
    }
    // eslint-disable-next-line
  }, [selectedItems]);

  const handleUser = async () => {
    const response = await addStudent({
      class_id: classId,
      students: { students: selectedItems },
    });
    if (response.error) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Kayıt Başarısız</span>
        </span>,
        "Bir hata oluştu",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Kayıt Başarılı</span>
        </span>,
        "Kullanıcı başarıyla eklendi.",
        "success"
      );
      isAdmin && refetch();
      isSchoolManager && refetchForManager();
    }
    clearCache();
    setIsOpenStudentModal(false);
    formik.resetForm();
  };
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (newValue) => {
    setSearchValue(newValue);
  };

  const filterStudents = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const newStudents = Array.isArray(wihoutClassStudents)
      ? wihoutClassStudents.filter(
          (item) =>
            (item.first_name &&
              item.first_name.toLowerCase().includes(searchValue)) ||
            (item.last_name &&
              item.last_name.toLowerCase().includes(searchValue)) ||
            (item.class_name &&
              item.class_name.toLowerCase().includes(searchValue))
        )
      : [];
    setFilteredStudents(newStudents);
  };

  //effects
  useEffect(() => {
    filterStudents();
    // eslint-disable-next-line
  }, [searchValue]);

  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenStudentModal}
      setIsOpen={setIsOpenStudentModal}
      titleId="example-title"
      size={null}
    >
      <ModalHeader setIsOpen={setIsOpenStudentModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} /> <span className="ps-2">Yeni Öğrenci</span>
          <span className="ps-2">
            <Icon icon="Person" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="d-flex">
          <Checks
            // id="selectAll"
            checked={selectAll}
            onChange={handleSelectAll}
          />
          <label>Tümünü Seç</label>
        </div>
        <div className="row gap-3 d-flex justify-content-center">
          <div className="row g-4">
            <div
              className="col-12"
              style={{ height: "250px", overflow: "scroll" }}
            >
              <Input
                value={searchValue}
                onChange={(e) => handleSearchChange(e.target.value)}
                type="text"
                placeholder="Ara.."
                className="mb-2"
              />
              {items.length === 0 ? (
                <div>
                  <span>Kayıtlı öğrenci bulunamadı</span>
                </div>
              ) : (
                items.map((item, i) => {
                  const userName =
                    item.first_name && item.first_name !== null
                      ? `${item.first_name} ${item.last_name}`
                      : item.email.split("@")[0];
                  return (
                    <div className="d-flex" key={nanoid()}>
                      <div>
                        <Checks
                          key={item.id}
                          id={item.id.toString()}
                          name="selectedList"
                          value={item.id}
                          onChange={() => handleCheckboxChange(item.id)}
                          checked={selectedItems.includes(item.id)}
                        />
                      </div>
                      <div className="text-dark">{userName}</div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={async () => {
            formik.handleSubmit();
          }}
        >
          Kaydet
        </Button>
      </ModalFooter>
      <PaginationButtons
        data={items}
        label="öğrenci"
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </Modal>
  );
};

export default WithoutClassStudentsModal;
