import React from 'react'
import Button from '../../../../../components/bootstrap/Button';
import { CardBody } from '../../../../../components/bootstrap/Card';
import { IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import showNotification from '../../../../../components/extras/showNotification';
import Icon from '../../../../../components/icon/Icon';
import { useNavigate } from 'react-router-dom';
import { useDeleteGuidanceMutation, useGuidancesQuery } from '../teacherEducationApi';
import { useState } from 'react';
import useSortableData from '../../../../../hooks/useSortableData';
import { useEffect } from 'react';
import Confirm from '../../../../common/Confirm';
import CreateGuidanceModal from './3-CreateGuidanceModal';
import { TEACHER_COURSE_STATUS } from '../../../../../common/data/enumTeacherEducationCourseState';

const rowStyle ={
  cursor: 'pointer'
}

const GuidanceListTable = () => {
  const navigate = useNavigate();

  const { data} = useGuidancesQuery();
  const [deleteGuidance] = useDeleteGuidanceMutation();

  const [search, setSearch] = useState("");
  const [filteredGuidances, setFilteredGuidances] = useState("");

  const { items} = useSortableData(
    filteredGuidances ? filteredGuidances : data ? data : []
  );

  const filterGuidance = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const newGuidances =
      data &&
      data.filter(
        (item) => item.title && item.title.toLowerCase().includes(search)
      );
    setFilteredGuidances(newGuidances);
  };

  useEffect(() => {
    filterGuidance();
    //eslint-disable-next-line
  }, [search, data]);

  const [isOpenNewGuidanceModal, setIsOpenNewGuidanceModal] = useState(false);
  const [guidanceId, setGuidanceId] = useState(null);
  const [open, setOpen] = useState(false);

  const addGuidance = () => {
    setIsOpenNewGuidanceModal(true);
  };

  //! delete guidance--------
  const removeGuidance = (e) => {
    setOpen(true);
    setGuidanceId(e.currentTarget.id && e.currentTarget.id);
  };
  const handleDelete = async () => {
    const response = await deleteGuidance(guidanceId);
    setOpen(false);
    if (response.error) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Silme İşlemi Başarısız</span>
        </span>,
        "Bir hata meydana geldi",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Silme Başarılı</span>
        </span>,
        "Rehber başarıyla silindi.",
        "success"
      );
    }
  };
  const handleCancel = () => setOpen(false);
  //!-----------------------------------------------
  return (
    <>
    <CreateGuidanceModal
      isOpenNewGuidanceModal={isOpenNewGuidanceModal}
      setIsOpenNewGuidanceModal={setIsOpenNewGuidanceModal}
    />
    <Confirm
      open={open}
      setOpen={setOpen}
      onConfirm={handleDelete}
      onCancel={handleCancel}
    />
    <div className="row">
      <div className="col-3">
        <Button
          color="info"
          className={`ms-5 mb-4 shadow-3d-up-hover}`}
          onClick={() => addGuidance()}
        >
          Yeni Rehber Ekle
        </Button>
      </div>
      <div className="col-6">
        <form className="d-flex gap-1" 
        onSubmit={filterGuidance}
        >
          <input
            className="form-control"
            type="search"
            placeholder="Aramak istediğiniz sunumu yazınız"
            value={search}
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
          />
          <button className="btn btn-warning w-25">Bul</button>
        </form>
      </div>
    </div>
    <CardBody>
      <table className="table table-modern">
        <thead style={{ fontSize: "16px" }}>
          <tr>
            <th>Başlık</th>
            <th>Açıklama/Not</th>
            <th style={{ textAlign: "center" }}>Sırası</th>
            <th className="text-center">Aktiflik</th>
            <th className="text-center">Sil</th>
            <th style={{ textAlign: "center" }}>Ders Detayı</th>
          </tr>
        </thead>
        <tbody style={{ fontSize: "16px" }}>
          {(items && items.length > 0)
            ? items.map((presentation) => {
                const {
                  id,
                  title,
                  description,
                  order_number,
                  status,
                } = presentation;
                return (
                  <tr key={id}>
                    <td style={rowStyle} 
                   onClick={() => navigate(`/admin/updateGuidance/${id}`)}
                    >{title}</td>
                    <td> {description}</td>
                    <td style={{ textAlign: "center" }}>{order_number}</td>
                    <td className="text-center">
                      {status === TEACHER_COURSE_STATUS.ACTIVE.id
                        ? "Aktif"
                        : status === TEACHER_COURSE_STATUS.DRAFT.id
                        ? "Taslak"
                        : "Pasif"}
                    </td>
                    <td className="text-center">
                      <IconButton id={id} onClick={(e) => removeGuidance(e)}>
                        <Delete
                          className="fs-2"
                        />
                      </IconButton>
                    </td>
                    <td className="text-center">
                      <IconButton id={id} onClick={() => navigate(`/admin/updateGuidance/${id}`)}>
                        <Edit
                          className="fs-2"
                        />
                      </IconButton>
                    </td>
                  </tr>
                );
              }) : <tr><td>Rehber bulunamadı</td></tr>
          }
        </tbody>
      </table>
    </CardBody>
  </>
  )
}

export default GuidanceListTable