import React from "react";
import { useState } from "react";
import Button from "../../../../../components/bootstrap/Button";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../../../components/bootstrap/Card";
import Icon from "../../../../../components/icon/Icon";
import Page from "../../../../../layout/Page/Page";
import { get, patch, post } from "../../../../../utils/service";
import AddStudentForm from "./add-student-form";
import StudentsTable from "./students-table";
import { useDispatch, useSelector } from "react-redux";
import { setStudents } from "../../teacherSlice";

const ClassStudents = () => {
const [modalStatus, setModalStatus] = useState(false);
const dispatch = useDispatch();
const {students} = useSelector(store=>store.teacher);
console.log(students);

/*
   *   HANDLERS
   */
// const handleGetStudents = async () => {
//     const {data} = await get("classes/invite_student/");
//    if(data.data.length > 0){
//     setStudents([...data.data]);
//    }
//  };

 const handleGetStudents = async () => {
    try {
      const { data } = await get(`classes/invite_student/`);
      dispatch(setStudents(data));
    } catch (err) {
    } finally {
    }
  };

 const handleSaveStudents = async (data) => {
   if (data.id > 0) {
     await patch("classes/invite_student/" + data.id + "/", data);
   } else {
     await post("classes/invite_student/", data);
   }
   console.log(data);
   handleGetStudents();
 }
 /*------------------*/


const openStudentForm = () => {
    setModalStatus(true);
  }
console.log("students",students);
  return (
    <Page>
      <Card stretch tag="form" noValidate>
        <CardHeader>
          <CardLabel icon="Groups" iconColor="info">
            <CardTitle>Sınıftaki Öğrenciler</CardTitle>
          </CardLabel>
          <CardActions>
            <div>
          <Button
            onClick={()=>openStudentForm()}
            className="fs-5 fw-bold"
          >
            <Icon icon="Person" color="info" size="2x" />
            Yeni Öğrenci Ekle
          </Button>
          <AddStudentForm  handleSaveStudents={handleSaveStudents} handleGetStudents={handleGetStudents} modalStatus={modalStatus} setModalStatus={setModalStatus} />
          </div>
          </CardActions>
        </CardHeader>
        <CardBody className="pb-0" style={{height:"auto"}} isScrollable>
          <div className="row g-4">
            <div className="col-12">
              {/* Student table */}
              <StudentsTable students={students}/>
            </div>
          </div>
        </CardBody>
      </Card>
    </Page>
  );
};

export default ClassStudents;
