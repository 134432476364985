// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Banner_bannerContainer__D7X1E {\n  max-width: 1320px;\n  height: 500px;\n  border: solid;\n}\n.Banner_bannerContainer__D7X1E img {\n  width: 100%;\n  height: 100%;\n  -o-object-fit: contain;\n     object-fit: contain;\n}\n\n.Banner_bannerArea__A\\+U5i {\n  height: 100%;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: center;\n      justify-content: center;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  text-align: center;\n  font-size: 18px;\n  font-weight: 600;\n}\n.Banner_bannerArea__A\\+U5i span {\n  font-size: 14px;\n  font-weight: 400;\n}", "",{"version":3,"sources":["webpack://./src/pages/t14/schoolManager/banner-management/contents/banner/Banner.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,aAAA;EACA,aAAA;AACJ;AAAI;EACI,WAAA;EACA,YAAA;EACA,sBAAA;KAAA,mBAAA;AAER;;AACA;EACI,YAAA;EACA,oBAAA;EAAA,aAAA;EACA,sBAAA;MAAA,mBAAA;EACA,qBAAA;MAAA,uBAAA;EACA,0BAAA;MAAA,sBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;AAEJ;AADI;EACI,eAAA;EACA,gBAAA;AAGR","sourcesContent":[".bannerContainer{\n    max-width:1320px;\n    height: 500px;\n    border: solid;\n    img{\n        width: 100%;\n        height: 100%;\n        object-fit: contain;\n    }\n}\n.bannerArea{\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    text-align: center;\n    font-size: 18px;\n    font-weight: 600;\n    span{\n        font-size: 14px;\n        font-weight: 400;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerContainer": "Banner_bannerContainer__D7X1E",
	"bannerArea": "Banner_bannerArea__A+U5i"
};
export default ___CSS_LOADER_EXPORT___;
