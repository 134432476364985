import React, { useEffect, useState } from "react";
import Input from "../../../../../../components/bootstrap/forms/Input";
import Icon from "../../../../../../components/icon/Icon";

const SimpleSelectList = ({
  items,
  selectedItems,
  onSelect,
  selectKey,
  displayKey,
}) => {
  const [filteredItems, setFilteredItems] = useState([]);
  useEffect(() => {
    if (items) {
      setFilteredItems(items);
    }
  }, [items]);
  return (
    <div style={{height:"260px", overflow:"scroll"}}>
    <Input
      onChange={(e) => {
        setFilteredItems(
          items.filter((item) =>
            item[displayKey]
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          )
        );
      }}
      placeholder="Arama yapınız"
    />

    <table className="table mt-4">
      <tbody>
        {filteredItems.map((item) => (
          <tr key={item[selectKey]}>
            <td className="d-flex align-items-center gap-2">
              <input
                type="checkbox"
                checked={selectedItems?.includes(item[selectKey])}
                id={item[selectKey].toString()}
                onChange={(e) => {
                  onSelect(e.target.id, e.target.checked);
                }}
              />
              {item[displayKey]} {selectedItems?.includes(item[selectKey]) && <Icon icon="Lock" color="danger" size="lg" className="me-1" />}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  );
};

export default SimpleSelectList;
