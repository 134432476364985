import * as React from "react";
import { useState, useEffect } from "react";
import { get, put } from "../../../../utils/service";
import FormGroup from "@mui/material/FormGroup";
import { useParams } from "react-router-dom";
import LearningPathPractices from "./AdminLearningPathPractices";
import _ from "underscore";
import showNotification from "../../../../components/extras/showNotification";
import Icon from "../../../../components/icon/Icon";
import { useFormik } from "formik";
import Input from "../../../../components/bootstrap/forms/Input";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/bootstrap/Button";
import Card, {
  CardActions,
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../../components/bootstrap/Card";
import Spinner from "../../../../components/bootstrap/Spinner";
import Select from "../../../../components/bootstrap/forms/Select";
import Label from "../../../../components/bootstrap/forms/Label";
import { nanoid } from "nanoid";
import { useClearCacheMutation } from "../../../../store/features/apiSlice";

const AdminLearningPathContent = (props) => {
  const [clearCache] = useClearCacheMutation();
  const navigate = useNavigate();
  const [practices, setPractices] = useState([]);
  const [loading, setLoading] = useState(true);

  const { id } = useParams();
  const spinner = (
    <Spinner color="primary" style={{ marginTop: "20px" }} />
  )
  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = "Başlık giriniz!";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      title: "",
      explanation: "",
      technology: "python",
      award_point: "",
      order_number:10000,
      access_state:1,
      state:1
    },
    validate,
    onSubmit: async () => {
      updateLearningPath(practices);
    },
  });

  const getLearningPathInformation = async () => {
    try {
      const { data } = await get(`learningpaths/${id}`);

      formik.values.title = data.title;
      formik.values.explanation = data.explanation;
      formik.values.state = data.state;
      formik.values.technology = data.technology;
      formik.values.award_point = data.award_point;
      formik.values.order_number = data.order_number;
      formik.values.access_state = data.access_state;
      setLoading(false);
    } catch (err) {
    } finally {
    }
  };

  const getLearningPathPractices = async () => {
    try {
      const { data } = await get(`learningpaths/${id}/practices`);

      const practices = _.sortBy(
        data.practices,
        "order_number"
      );
      setPractices(practices);
    } catch (err) {
    } finally {
    }
  };

  const updateLearningPath = async (practiceList) => {
    try {
      await put(
        `learningpaths/${id}/`,
        {
          ...formik.values,
          id:parseInt(id),
          practices:practiceList
      }
      ).then(() => {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Kaydedildi</span>
          </span>,
          "Ders başarıyla güncellendi",
          "success"
        );
        getLearningPathInformation();
        getLearningPathPractices();
        clearCache();
      });
    } catch (err) {
      console.log({ err });
    }
  };

  const updatePracticeList = async (updatedPracticeList) => {
    updateLearningPath(updatedPracticeList);
  };

  useEffect(() => {
    getLearningPathInformation();
    getLearningPathPractices();
    //eslint-disable-next-line
  }, []);

  return (
    <div style={{ marginTop: "10px", marginLeft: "10px" }}>
      <Card className="">
        <CardHeader>
          <CardLabel icon="Assignment">
            <CardTitle>Ders Bilgileri {loading ? spinner:''}</CardTitle>
          </CardLabel>
          <CardActions>
            <Button onClick={() => navigate("/admin/learning-paths")} color="success"  hoverShadow="default" icon="ArrowReturnLeft" 
            isLight>
              Ders Listesine Dön
            </Button>
          </CardActions>
        </CardHeader>
        <CardBody>
          <div className="row g-4">
            <div className="col-12">
              <FormGroup id="title" label="Başlık">
                <Input
                  name="title"
                  placeholder="Başlık"
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  isValid={formik.isValid}
                  onBlur={formik.handleBlur}
                  isTouched={formik.touched.title}
                  invalidFeedback={formik.errors.title}
                
                />
              </FormGroup>
            </div>
            <div className="col-12">
              <FormGroup id="explanation" label="Açıklama">
                <Input
                  name="explanation"
                  placeholder="Açıklama"
                  onChange={formik.handleChange}
                  value={formik.values.explanation}
                />
              </FormGroup>
            </div>
            <div className="col-12">
            <Label>Program Dili</Label>
            <Select
                        list={[
                          { text: "Python", value: "python", key:nanoid() },
                          { text: "JavaScript", value: "javascript", key:nanoid() },
                          { text: "CSharp", value: "csharp", key:nanoid() },
                          { text: "Html", value: "HTML-CSS", key:nanoid() },
                          { text: "CSS", value: "HTML-CSS", key:nanoid() },
                        ]}
                        onChange={formik.handleChange}
                        value={formik.values.technology}
                        name="technology"
                        isValid={formik.isValid}
                        onBlur={formik.handleBlur}
                        isTouched={formik.touched.technology}
                        invalidFeedback={formik.errors.technology}
                      />
            </div>
            <div className="col-12">
              <FormGroup id="award_point" label="Ödül Puanı" isFloating>
                <Input
                  placeholder="Ödül Puanı"
                  name="award_point"
                  onChange={formik.handleChange}
                  value={formik.values.award_point}
                  type="number"
                />
              </FormGroup>
            </div>
            <div className="col-12">
            <Label>Sıra Numarası</Label>
            <FormGroup id="order_number" label="Sıra Numarası" isFloating>
                <Input
                  placeholder="Sıra Numarası"
                  name="order_number"
                  onChange={formik.handleChange}
                  value={formik.values.order_number}
                  type="number"
                />
              </FormGroup>
            </div>
            <div className="col-12">
            <Label>Erişim Durumu</Label>
            <Select
                  list={[
                    {
                      value: 1,
                      text: "Özel",
                    },
                    {
                      value: 2,
                      text: "Herkese Açık",
                    },
                  ]}
                  onChange={formik.handleChange}
                  value={formik.values.access_state}
                  name="access_state"
                  isValid={formik.isValid}
                  onBlur={formik.handleBlur}
                  isTouched={formik.touched.access_state}
                  invalidFeedback={formik.errors.access_state}
                />
            </div>
            <div className="col-12">
            <Label>Durum</Label>
            <Select
                  list={[
                    {
                      value: 0,
                      text: "Bilinmiyor",
                    },
                    {
                      value: 1,
                      text: "Aktif",
                    },
                    {
                      value: 2,
                      text: "Pasif",
                    },
                    {
                      value: 3,
                      text: "Taslak",
                    },
                  ]}
                  onChange={formik.handleChange}
                  value={formik.values.state}
                  name="state"
                  isValid={formik.isValid}
                  onBlur={formik.handleBlur}
                  isTouched={formik.touched.state}
                  invalidFeedback={formik.errors.state}
                />
            </div>
            <div className="col-12">
              <Button
                onClick={() => formik.handleSubmit()}
                color="success"
                icon="Save"
                isLight
              >
                Kaydet
              </Button>
              
            </div>
          </div>
        </CardBody>
      </Card>

      <LearningPathPractices
        practices={practices}
        updatePracticeList={updatePracticeList}
      />
    </div>
  );
};

export default AdminLearningPathContent;
