import React from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Button from "../../../../components/bootstrap/Button";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import {
  useGamesQuery,
  useGameStepsWithSectionQuery,
  useGamesWithSectionQuery,
} from "../../../../store/features/apiSlice";
import DashboardHeader from "../../../common/Headers/DashboardHeader";
import _ from "underscore";
import USER_SUBSCRIPTION_TYPE from "../../../../common/data/enumUserSubscriptionType";
import { getGameStepStatusColor, getGameStepStatusText } from "../gameFunctions";
import { getDifficultyText } from "../../../../common/data/enumGameDifficulty";

const AllGameStepsWithSection = ({ isFluid }) => {
  const userSubscriptionType = localStorage.getItem("subscription_type")
  // const userSubscriptionType = 2;
  const { id, sectionId } = useParams();
  const { data: games} = useGamesQuery();
  const { data: sections } = useGamesWithSectionQuery({ id });
  const { data: steps } = useGameStepsWithSectionQuery({ id, sectionId });
  const sortedSteps = _.sortBy(steps, "step_number");
  const stepsTitle =
    sections && sections.filter((section) => Number(section.id) === Number(sectionId));
  let sectionStep = games && games.filter((game) => Number(game.id) === Number(id));
  const navigate = useNavigate();

  const handleNavigation = (path, activeTab) => {
    navigate(`${path}?activeTab=${activeTab}`);
  }; 
  return (
    <PageWrapper title={stepsTitle && stepsTitle[0]?.title}>
      <DashboardHeader />
      <Page container={false} style={{ padding: "0" }}>
        <div className="row">
          <div className="col-lg-12">
            <div className="card-header px-0 bg-transparent">
              <div className="card-label">
                <div className="card-title-wrapper">
                  <h5 className="card-title">
                    {stepsTitle && stepsTitle[0]?.title}
                  </h5>
                </div>
              </div>
            </div>
            {sortedSteps?.map((step) => {
              const {
                id: stepId,
                title,
                subscription_type: gameSubscriptionType,
                user_status
              } = step;
              return (
                <div className="card" key={stepId}>
                  <div className="card-body">
                    <div className="row g-3 align-items-center">
                      <div className="col d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <div
                            className="ratio ratio-1x1"
                            style={{ width: "72px" }}
                          >
                            <div className="rounded-2 d-flex align-items-center justify-content-center bg-l10-info">
                              <img
                                src={sectionStep && sectionStep[0]?.image_path}
                                alt="Bank Of America"
                                width={50}
                                height="50px"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex-grow-1 ms-3 d-flex justify-content-between align-items-center">
                          <div>
                            <div className="fw-bold fs-6 mb-0">{title}</div>
                            <div className="text-muted">
                              <small>
                                Seviye:{" "}
                                <span className="text-normal fw-bold">
                                  {getDifficultyText(stepsTitle && stepsTitle[0]?.difficulty)}
                                </span>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      {Number(gameSubscriptionType) ===
                      USER_SUBSCRIPTION_TYPE.FREE.id ? (
                        <div className="col-auto">
                          <Button
                            className={`bg-l10-${getGameStepStatusColor(user_status)} text-${getGameStepStatusColor(user_status)} fw-bold px-3 py-2 rounded-pill}`}
                            tag="a"
                            to={`${stepId}`}
                          >
                            {getGameStepStatusText(user_status)}
                          </Button>
                        </div>
                      ) : (
                        Number(userSubscriptionType) === USER_SUBSCRIPTION_TYPE.STANDART.id && Number(gameSubscriptionType) ===
                        USER_SUBSCRIPTION_TYPE.STANDART.id ?
                        <div className="col-auto">
                          <Button
                            className={`bg-l10-${getGameStepStatusColor(user_status)} text-${getGameStepStatusColor(user_status)} fw-bold px-3 py-2 rounded-pill}`}
                            tag="a"
                            to={`${stepId}`}
                          >
                            {getGameStepStatusText(user_status)}
                          </Button>
                        </div> : (
                          Number(userSubscriptionType) === USER_SUBSCRIPTION_TYPE.PREMIUM.id ?
                          <div className="col-auto">
                         <Button
                          className={`bg-l10-${getGameStepStatusColor(user_status)} text-${getGameStepStatusColor(user_status)} fw-bold px-3 py-2 rounded-pill}`}
                           tag="a"
                           to={`${stepId}`}
                         >
                           {getGameStepStatusText(user_status)}
                         </Button>
                       </div> :
                       <div className="col-auto">
                       <Button
                         className={`bg-danger text-white fw-bold px-3 py-2 rounded-pill}`}
                         tag="a"
                         onClick={()=>handleNavigation(`/profile`,"Account Details")}
                       >
                         Planı Yükselt
                       </Button>
                     </div>
                        )
                         
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default AllGameStepsWithSection;
