import React from 'react'
import style from "./AttendanceRate.module.scss"

const Information = ({description, value, index, email}) => {
  function checkFullName(description) {
    if (description) {
      return `${description}`;
      } else {
        return `${email.split("@")[0]}`;
      }
  }
  return (
      <>
      <div>
      <div>{index + 1}. {checkFullName(description)}</div>
        <div className={`${style.line}`}></div>
      </div>
        <div><span>% {value}</span></div>
      </>
  )
}

export default Information