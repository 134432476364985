import { useFormik } from "formik";
import Button from "../../../components/bootstrap/Button";
import Card, {
    CardBody,
    CardFooter,
    CardFooterLeft,
    CardFooterRight,
    CardHeader,
    CardLabel,
    CardTitle,
} from "../../../components/bootstrap/Card";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import showNotification from "../../../components/extras/showNotification";
import Icon from "../../../components/icon/Icon";
import { put } from "../../../utils/service";




const ChangePassword = () => {
    const userId = localStorage.getItem("id");
    const validate = (values) => {
        const errors = {};
        if (!values.currentPassword) {
            errors.currentPassword = "Şifre gereklidir.";
        }
        if (!values.newPassword) {
            errors.newPassword = "Şifre gereklidir.";
        }
        if (
            values.newPassword.length > 0 &&
            !/[a-z|A-Z]/.test(values.newPassword)
        ) {
            errors.newPassword = "Şifre en az bir harf içermelidir.";
        }
        if (values.newPassword.length > 0 && !/[0-9]/.test(values.newPassword)) {
            errors.newPassword = "Şifre en az bir rakam içermelidir.";
        } else if (values.newPassword.length > 0 && values.newPassword.length < 8) {
            errors.newPassword = "Şifre en az 8 karakter olmalıdır.";
        }
        if (!values.confirmPassword) {
            errors.confirmPassword = "Şifre tekrarı gereklidir.";
        }
        if (!errors.newPassword && values.newPassword !== values.confirmPassword) {
            errors.confirmPassword = "Şifreler eşleşmiyor.";
        }
        return errors;
    };

    const formikPassword = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
        validate,
        onSubmit: (values) => {
            put(`auth/change_password/${userId}/`, {
                old_password: values.currentPassword,
                password: values.newPassword,
                password2: values.confirmPassword,
            })
                .then((res) => {
                    if (res.error.response.status === 200) {
                        // console.log("if", res);
                        showNotification(
                            <span className="d-flex align-items-center">
                                <Icon icon="Info" size="lg" className="me-1" />
                                <span>Güncelleme Başarılı</span>
                            </span>,
                            "Parola başarıyla güncellendi.",
                            "success"
                        );
                    } else if (res.error.response.status === 400) {
                        // console.log("else", res);
                        // error_message = res.error.response.data["old_password"];
                        // console.log("else", error_message);
                        showNotification(
                            <span className="d-flex align-items-center">
                                <Icon icon="Info" size="lg" className="me-1" />
                                <span>Güncelleme Başarılı Değil</span>
                            </span>,
                            <p style={{ fontSize: "14px" }}>Eski parola yanlış</p>,
                            "danger"
                        );
                    }
                })
                .catch((err) => {
                    console.log("err", err);
                });
        },
    });
    return (
        <Card
            stretch
            tag="form"
            noValidate
            onSubmit={formikPassword.handleSubmit}
        >
            <CardHeader>
                <CardLabel icon="LocalPolice" iconColor="success">
                    <CardTitle>Parola Değiştir</CardTitle>
                </CardLabel>
            </CardHeader>
            <CardBody className="pb-0" isScrollable>
                <div className="row g-4">
                    <div className="col-12">
                        <FormGroup
                            id="currentPassword"
                            label="Geçerli Parola"
                            isFloating
                        >
                            <Input
                                name="currentPassword"
                                type="password"
                                placeholder="Geçerli parola"
                                autoComplete="current-password"
                                onChange={formikPassword.handleChange}
                                onBlur={formikPassword.handleBlur}
                                value={formikPassword.values.currentPassword}
                                isValid={formikPassword.isValid}
                                isTouched={formikPassword.touched.currentPassword}
                                invalidFeedback={
                                    formikPassword.errors.currentPassword
                                }
                            />
                        </FormGroup>
                    </div>
                    <div className="col-12">
                        <FormGroup
                            id="newPassword"
                            label="Yeni Parola"
                            isFloating
                        >
                            <Input
                                type="password"
                                placeholder="Yeni parola"
                                autoComplete="new-password"
                                onChange={formikPassword.handleChange}
                                onBlur={formikPassword.handleBlur}
                                value={formikPassword.values.newPassword}
                                isValid={formikPassword.isValid}
                                isTouched={formikPassword.touched.newPassword}
                                invalidFeedback={formikPassword.errors.newPassword}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-12">
                        <FormGroup
                            id="confirmPassword"
                            label="Yeni Parolayı Onayla"
                            isFloating
                        >
                            <Input
                                type="password"
                                placeholder="Yeni parolayı onayla"
                                autoComplete="new-password"
                                onChange={formikPassword.handleChange}
                                onBlur={formikPassword.handleBlur}
                                value={formikPassword.values.confirmPassword}
                                isValid={formikPassword.isValid}
                                isTouched={formikPassword.touched.confirmPassword}
                                invalidFeedback={
                                    formikPassword.errors.confirmPassword
                                }
                            />
                        </FormGroup>
                    </div>
                </div>
            </CardBody>
            <CardFooter>
                <CardFooterLeft>
                    <Button
                        color="info"
                        isLink
                        type="reset"
                        onClick={formikPassword.resetForm}
                    >
                        Sıfırla
                    </Button>
                </CardFooterLeft>
                <CardFooterRight>
                    <Button
                        type="submit"
                        icon="Save"
                        color="info"
                        isOutline
                        isDisable={
                            !formikPassword.isValid && !!formikPassword.submitCount
                        }
                    >
                        Kaydet
                    </Button>
                </CardFooterRight>
            </CardFooter>
        </Card>
    );
};

export default ChangePassword;