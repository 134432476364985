import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { setSelectedHomework } from "../../teacherSlice";
import {
  useGetActiveClassesQuery,
  useUpdateHomeWorkMutation,
  useCreateHomeWorkMutation,
  useGetClassesQuery,
} from "../../teacherApi";
import Icon from "../../../../../components/icon/Icon";
import Option from "../../../../../components/bootstrap/Option";
import showNotification from "../../../../../components/extras/showNotification";
import TaskAdd from "./TaskAdd";
import TaskPreview from "./TaskPreview";
import Textarea from "../../../../../components/bootstrap/forms/Textarea";
import { useNavigate } from "react-router-dom";
import {
  combineDateAndTime,
  splitDateAndTime,
} from "../../../../../utils/formatDate";
import style from "./teacherHomeworks.module.scss";
import FormGroup from "../../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../../components/bootstrap/forms/Input";
import Select from "../../../../../components/bootstrap/forms/Select";
import Button from "../../../../../components/bootstrap/Button";
import { getTotalPoints } from "./CalculatePoints";
import { showError, showSuccess } from "../../../profilPage/utils";

const AddHomeworkForm = ({ selectedHomeWork }) => {
  //constants
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const schoolId = localStorage.getItem("school");
  const isSchoolId = schoolId > 0;

  //states
  const [content, setContent] = useState({
    course_lessons: [],
    learning_paths: [],
    game_steps: [],
    teacher_practices: [],
  });
  const [openAddTaskForm, setOpenAddTaskForm] = useState(false); //TODO false olmalı
  //queries and mutations
  const {
    data: classListData,
    isSuccess,
    isLoading,
  } = useGetActiveClassesQuery();
  const {
    data: classesData,
    isSuccess: classesDataIsSuccess,
    isLoading: classesDataIsLoading,
  } = useGetClassesQuery();
  let classList = isSchoolId
    ? classesData?.length > 0 &&
      classesData.filter((item) => item.school === Number(schoolId))
    : classListData;

  const [createHomeWork] = useCreateHomeWorkMutation();
  const [update] = useUpdateHomeWorkMutation();

    const { course_lessons = [], learning_paths = [], game_steps = [], teacher_practices = [] } = content || {};


  //formik
  const validate = (values) => {
    const errors = {};
    // if (!values.content) {
    //   errors.content = "İçerik giriniz!";
    // }
    if (!values.title) {
      errors.title = "Başlık giriniz!";
    }
    if (!values.description) {
      errors.description = "İçerik giriniz!";
    }
    if (!values.start_date) {
      errors.start_date = "Başlangıç Tarihi giriniz!";
    }
    if (!values.end_date) {
      errors.end_date = "Bitiş Tarihi giriniz!";
    }
    if (!values.points) {
      errors.points = "Ödev tanımlayınız!";
    }
    if (!values.classroom) {
      errors.classroom = "Sınıf giriniz!";
    }
    let start_date = combineDateAndTime(
      values.start_date,
      values.start_date_time
    );
    let end_date = combineDateAndTime(values.end_date, values.end_date_time);
    const today = new Date();
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);
    const isDateError = startDate.getTime() >= endDate.getTime();
    if (isDateError) {
      errors.start_date = "Başlangıç tarihi ve saatini kontrol ediniz!";
      errors.end_date = "Bitiş tarihi ve saatini kontrol ediniz!";
      errors.start_date_time = "Başlangıç tarihi ve saatini kontrol ediniz!";
      errors.end_date_time = "Bitiş tarihi ve saatini kontrol ediniz!";
    }
    const isDateErrorForToday = endDate.getTime() < today.getTime();
    if (isDateErrorForToday) {
      errors.end_date = "Bitiş tarihi ve saati geçmiş zaman olamaz!";
      errors.end_date_time = "Bitiş tarihi ve saati geçmiş zaman olamaz!";
    }
    if (!values.start_date_time) {
      errors.start_date_time = "Başlangıç saatini yazınız";
    }
    if (!values.end_date_time) {
      errors.end_date_time = "Bitiş saatini yazınız";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      id: "",
      content: {
        //TODO isimlendirmeler yanlış,
        //lp ile course yer değiştirmeli
        learning_paths: [], //[[1, [1]]],
        teacher_practices: [],
        course_lessons: [],
        game_steps: [],
      },
      title: "",
      description: "",
      start_date: new Date().toISOString(),
      start_date_time: "00:00",
      end_date: new Date().toISOString(),
      end_date_time: "00:00",
      points: getTotalPoints(
        course_lessons,
        learning_paths,
        game_steps,
        teacher_practices
      ),
      classroom: "0",
    },
    validate,
    onSubmit: async (values) => {
      if (classList.length < 1 || values.classroom === "0") {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="danger" size="lg" className="me-1" />
            <span>Kayıt Başarısız</span>
          </span>,
          "Sınıf Bilgisi Yok",
          "danger"
        );
      } else {
        saveHomeWork(values);
        dispatch(setSelectedHomework({ ...selectedHomeWork }));
        navigate(-1);
        formik.resetForm();
      }
    },
  });
  //side effects
  useEffect(() => {
    initialize();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ((isSuccess, classesDataIsSuccess)) {
      if (classList.length > 0) {
        formik.setFieldValue("classroom", classList[0].id);
      }
    }
    //eslint-disable-next-line
  }, [isSuccess, classesDataIsSuccess]);

  useEffect(() => {
    const { course_lessons, learning_paths, game_steps, teacher_practices } =
      content;
    const newTotalPoints = getTotalPoints(
      course_lessons,
      learning_paths,
      game_steps,
      teacher_practices
    );
    formik.setFieldValue("points", newTotalPoints);
    //eslint-disable-next-line
  }, [content]);

  //methods
  const initialize = async () => {
    formik.resetForm();
    if (selectedHomeWork) {
      // console.log("Selected HW exist");
      let { dateString: startDate, time: startTime } = splitDateAndTime(
        selectedHomeWork.start_date
      );
      let { dateString: endDate, time: endTime } = splitDateAndTime(
        selectedHomeWork.end_date
      );
      // console.log(startDate, startTime, endDate, endTime);
      let hwData = {
        ...selectedHomeWork,
        content: convertContent(selectedHomeWork.content),
        start_date: startDate,
        start_date_time: startTime,
        end_date: endDate,
        end_date_time: endTime,
      };
      formik.setValues(hwData);
      setContent(selectedHomeWork.content);
    }
  };
  function convertContent(content) {
    if (!content)
      return {
        course_lessons: [],
        learning_paths: [],
        game_steps: [],
        teacher_practices: [],
      };
    let { course_lessons, learning_paths, game_steps, teacher_practices } =
      content;
    if (course_lessons.length > 0) {
      course_lessons = convertCourseLessons(course_lessons);
    }
    if (learning_paths.length > 0) {
      learning_paths = convertLearningPaths(learning_paths);
    }
    if (game_steps.length > 0) {
      game_steps = convertGameSteps(game_steps);
    }
    if (teacher_practices.length > 0) {
      teacher_practices = convertTeacherPractices(teacher_practices);
    }
    return { course_lessons, learning_paths, game_steps, teacher_practices };
  }

  const saveHomeWork = async (_data) => {
    let data = {
      ..._data,
      start_date: combineDateAndTime(_data.start_date, _data.start_date_time),
      end_date: combineDateAndTime(_data.end_date, _data.end_date_time),
    };
    delete data.start_date_time;
    delete data.end_date_time;
    try {
      if (selectedHomeWork) {
        const response = await update({ ...data });
        if (response.error) {
          showError();
        } else {
          showSuccess("Ödev güncellendi");
        }
      } else {
        const response = await createHomeWork(data);
        if (response.error) {
          showError();
        } else {
          showSuccess("Ödev oluşturuldu");
        }
        formik.resetForm();
        dispatch(setSelectedHomework(null));
      }
    } catch (error) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Kayıt Başarısız</span>
        </span>,
        "Bir hata oluştu",
        "danger"
      );
    } finally {
    }
  };
  const setCurrentContent = (content) => {
    formik.setFieldValue("content", content);
  };
  function setCourses(courses) {
    setContent((prev) => ({ ...prev, course_lessons: courses }));

    setCurrentContent({
      ...formik.values.content,
      course_lessons: convertCourseLessons(courses),
    });
  }
  function setPractices(lps) {
    setContent((prev) => ({ ...prev, learning_paths: lps }));

    setCurrentContent({
      ...formik.values.content,
      learning_paths: convertLearningPaths(lps),
    });
  }
  function setGameSteps(games) {
    setContent((prev) => ({ ...prev, game_steps: games }));

    setCurrentContent({
      ...formik.values.content,
      game_steps: convertGameSteps(games),
    });
  }
  function setTeacherPractices(tp) {
    setContent((prev) => ({ ...prev, teacher_practices: tp }));

    setCurrentContent({
      ...formik.values.content,
      teacher_practices: convertTeacherPractices(tp),
    });
  }
  function convertCourseLessons(course_lessons) {
    const lessonIds = [];

    for (const course of course_lessons) {
      for (const chapter of course.chapters) {
        for (const lesson of chapter.lessons) {
          lessonIds.push(lesson.id);
        }
      }
    }
    return lessonIds;
  }
  function convertLearningPaths(learning_paths) {
    const lessonIds = [];

    for (const lp of learning_paths) {
      let id = lp.id;
      let practiceIds = [];
      for (const p of lp.practices) {
        practiceIds.push(p.id);
      }
      lessonIds.push([id, practiceIds]);
    }
    return lessonIds;
  }
  function convertGameSteps(game_steps) {
    const stepIds = [];

    game_steps.forEach((game) => {
      if (game.sections) {
        game.sections.forEach((section) => {
          section.steps.forEach((step) => {
            stepIds.push(step.id);
          });
        });
      } else {
        game.steps.forEach((step) => {
          stepIds.push(step.id);
        });
      }
    });

    // console.log("STEPS", stepIds);
    return stepIds;
  }
  function convertTeacherPractices(teacher_practices) {
    const practiceIds = [];
    for (const p of teacher_practices) {
      practiceIds.push(p.id);
    }
    return practiceIds;
  }

  if (isLoading || classesDataIsLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <div className="row g-4">
        <div className="col-12">
          <div className="mb-0">
            <div className="">
              <form
                className={`${style.homeworkForm} row g-4`}
                onSubmit={formik.handleSubmit}
              >
                {/* Ödev Başlığı */}
                <div className="col-12">
                  <FormGroup id="title" label="Ödev Başlığı" isFloating>
                    <Input
                      placeholder="title"
                      onChange={formik.handleChange}
                      value={formik.values.title}
                      isValid={formik.isValid}
                      onBlur={formik.handleBlur}
                      isTouched={formik.touched.title}
                      invalidFeedback={formik.errors.title}
                    />
                  </FormGroup>
                </div>
                {/* Açıklama */}
                <div className="col-12">
                  <FormGroup id="description" label="Açıklama" isFloating>
                    <Textarea
                      placeholder="Açıklama"
                      onChange={formik.handleChange}
                      value={formik.values.description}
                      isValid={formik.isValid}
                      onBlur={formik.handleBlur}
                      isTouched={formik.touched.description}
                      invalidFeedback={formik.errors.description}
                      rows={5}
                    />
                  </FormGroup>
                </div>

                <div className="d-flex flex-wrap gap-1">
                  {/* Başlangıç Zamanı */}
                  <div
                    className={`${style.startDate} d-flex align-items-center gap-2 col-12 col-md-6 `}
                  >
                    <div className="col">
                      <FormGroup
                        id="start_date"
                        label="Başlangıç Tarihi"
                        isFloating
                      >
                        <Input
                          type={"date"}
                          value={formik.values.start_date ? formik.values.start_date.substring(0, 10) : ""}
                          placeholder="Başlangıç Tarihini giriniz"
                          onChange={formik.handleChange}
                          isValid={formik.isValid}
                          onBlur={formik.handleBlur}
                          isTouched={formik.touched.start_date}
                          invalidFeedback={formik.errors.start_date}
                        />
                      </FormGroup>
                    </div>
                    <div className="col">
                      <FormGroup
                        id="start_date_time"
                        label="Başlangıç saati"
                        isFloating
                      >
                        <Input
                          type={"time"}
                          value={formik.values.start_date_time}
                          onChange={formik.handleChange}
                          isValid={formik.isValid}
                          onBlur={formik.handleBlur}
                          isTouched={formik.touched.start_date_time}
                          invalidFeedback={formik.errors.start_date_time}
                        />
                      </FormGroup>
                    </div>
                  </div>

                  {/* Sınıf */}
                  <div className="col order-md-0 order-1">
                    <FormGroup id="classroom" label="Sınıf" isFloating>
                      <Select
                        ariaLabel="Select"
                        value={formik.values.classroom.toString()}
                        // style={{ width: "100px" }}
                        onChange={(e) => {
                          formik.setFieldValue("classroom", e.target.value);
                        }}
                      >
                        {classList.length > 0 &&
                          classList?.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    </FormGroup>
                  </div>
                  {/* Bitiş Zamanı */}
                  <div
                    className={`${style.endDate} d-flex align-items-center gap-2 col-12 col-md-6 `}
                  >
                    <div className="col">
                      <FormGroup id="end_date" label="Bitiş Tarihi" isFloating>
                        <Input
                          type="date"
                          onChange={formik.handleChange}
                          // value={formik.values.end_date.substring(0, 10)}
                          value={formik.values.end_date ? formik.values.end_date.substring(0, 10) : ""}
                          isValid={formik.isValid}
                          onBlur={formik.handleBlur}
                          isTouched={formik.touched.end_date}
                          invalidFeedback={formik.errors.end_date}
                        />
                      </FormGroup>
                    </div>
                    <div className="col">
                      <FormGroup
                        id="end_date_time"
                        label="Bitiş Saati"
                        isFloating
                      >
                        <Input
                          type={"time"}
                          value={formik.values.end_date_time}
                          onChange={formik.handleChange}
                          isValid={formik.isValid}
                          onBlur={formik.handleBlur}
                          isTouched={formik.touched.end_date_time}
                          invalidFeedback={formik.errors.end_date_time}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  {/* Puan */}
                  <div className="col order-md-0 order-2">
                    <FormGroup id="points" label="Puan" isFloating>
                      <Input
                        onChange={formik.handleChange}
                        value={formik.values.points}
                        isValid={formik.isValid}
                        onBlur={formik.handleBlur}
                        isTouched={formik.touched.points}
                        invalidFeedback={formik.errors.points}
                        type="number"
                        readOnly
                      />
                    </FormGroup>
                  </div>
                </div>
                {/* Task */}
                <TaskPreview
                  content={content}
                  setOpenAddTaskForm={setOpenAddTaskForm}
                  openAddTaskForm={openAddTaskForm}
                />
                {openAddTaskForm && (
                  <TaskAdd
                    content={content}
                    setCurrentContent={setCurrentContent}
                    setCourses={setCourses}
                    setPractices={setPractices}
                    setTeacherPractices={setTeacherPractices}
                    setOpenAddTaskForm={setOpenAddTaskForm}
                    setGameSteps={setGameSteps}
                    currentClass={formik.values.classroom}
                  />
                )}
                {/* Buttons */}
                <div className="d-flex justify-content-end gap-3">
                  <div className={`${style.buttonsContainer} d-flex gap-2`}>
                    <Button
                      className={`${style.cancelButton}`}
                      onClick={() => {
                        formik.resetForm();
                        dispatch(setSelectedHomework(null));
                        navigate(-1);
                      }}
                    >
                      İptal
                    </Button>
                    <Button
                      className={`${style.saveButton}`}
                      type="submit"
                      onClick={async () => {
                        formik.handleSubmit();
                      }}
                    >
                      Kaydet
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddHomeworkForm;
