import React, { createContext, useContext, useState } from "react";

const CompetitionContext = createContext();

export const CompetitionProvider = ({ children }) => {
  const [competitionLessons, setCompetitionLessons] = useState(
    JSON.parse(localStorage.getItem("competitionLessons")) || []
  );

  const updateCompetitionLessons = (newLessons) => {
    localStorage.setItem("competitionLessons", JSON.stringify(newLessons));
    setCompetitionLessons(newLessons);
  };

  return (
    <CompetitionContext.Provider value={{ competitionLessons, updateCompetitionLessons }}>
      {children}
    </CompetitionContext.Provider>
  );
};

export const useCompetition = () => useContext(CompetitionContext);
