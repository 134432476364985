import React from "react";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import DashboardHeader from "../../../common/Headers/DashboardHeader";
import Page from "../../../../layout/Page/Page";

function createMarkup() {
  return {__html: '<iframe src="https://gamelab.bilisimschool.com/codeart/codeart.html" style="flex-grow: 1; border: none; margin: 0; padding: 0;"></iframe>'};
}

const CodeArtFreeStyle = ({ isFluid }) => {
  return (
    <PageWrapper title={"CodeArt"}>
      <DashboardHeader />      
      <Page container={false} style={{padding: '0'}}>
        <div style={{display: 'flex', width: '100%', height: '100%'}} dangerouslySetInnerHTML={createMarkup() } />
      </Page>
    </PageWrapper>
  );
};

export default CodeArtFreeStyle;
