import React, { useState } from 'react'
import style from "./HomeworkManagement.module.scss"
import lessonsLogo1 from "../../../../assets/lessons/lessonsLogo1.svg";
import lessonsLogo2 from "../../../../assets/lessons/lessonsLogo2.svg";
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import DashboardHeader from '../../../common/Headers/DashboardHeader';
import Page from '../../../../layout/Page/Page';
import Card from '../../../../components/bootstrap/Card';
import HomeworkTrackingWrapper from './homeworkTracking/HomeworkTrackingWrapper';
import TeacherHomeworkWrapper from './teacher-homeworks/TeacherHomeworkWrapper';

const HomeworkManagement = () => {
    const _activeTab = sessionStorage.getItem("homeworkActiveTab");
    const [activeTab, setActiveTab] = useState(Number(_activeTab) || 1);
  
    const tabContents = [
      {
        content: <TeacherHomeworkWrapper />,
        tabName: "Ödev Yönetimi",
        icon1: lessonsLogo1,
        icon2: lessonsLogo2,
      },
      {
        content: <HomeworkTrackingWrapper />,
        tabName: "Ödev Takibi",
        icon1: lessonsLogo1,
        icon2: lessonsLogo2,
      },
    ];
  
    const handleTabClick = (tabNumber) => {
      if (tabNumber >= 1 && tabNumber <= tabContents.length) {
      sessionStorage.setItem("homeworkActiveTab", tabNumber);
      setActiveTab(tabNumber);}
    };
  return (
    <PageWrapper title={"Dersler"}>
      <DashboardHeader />
      <Page container={false} style={{ padding: "0" }}>
      <Card className="h-100 mt-3 mx-lg-3">
        <div
          className={`${style.tabContainer} d-flex justify-content-evenly align-items-center mx-auto mt-2 mb-5 px-3 gap-1`}
        >
          {tabContents.map((tab, index) => (
            <div
              key={index}
              className={`${style.tab} ${
                activeTab === index + 1 ? `${style.activetab}` : ""
              } d-flex justify-content-center align-items-center gap-2 p-3`}
              onClick={() => handleTabClick(index + 1)}
            >
              <div className={`${style.tabsLogo}`}>
                <img
                  src={activeTab === index + 1 ? tab.icon2 : tab.icon1}
                  alt=""
                />
              </div>
              <div className={`${style.tabsDescription}`}>
                <span>{tab.tabName}</span>
              </div>
            </div>
          ))}
        </div>
         <div className="tab-content">
            {tabContents[activeTab - 1] && tabContents[activeTab - 1]?.content}
          </div>
        </Card>
      </Page>
    </PageWrapper>
  )
}

export default HomeworkManagement