import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PracticeTable from "./PracticeTable";
import Button from "../../../../components/bootstrap/Button";
import Card, {
  CardActions,
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../../components/bootstrap/Card";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../components/bootstrap/Modal";
import Input from "../../../../components/bootstrap/forms/Input";


const LearningPathPractices = ({ practices, updatePracticeList }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [practice, setPractice] = useState({
    practice_title: "",
    id: 0,
    order_number: 0,
  });

  const { id } = useParams();
  let navigate = useNavigate();

  function removePracticeFromPath(practiceId) {
    var np = practices.filter((value) => {
      return value.practice !== practiceId;
    });
    updatePracticeList(np);
  }

  function openAddNew() {
    navigate(`/admin/add-practice-to-path/${id}`);
  }
  function openDialog(practice) {
    setIsOpenModal(true);
    setPractice(practice);
  }
  function savePracticeOrder() {
    setIsOpenModal(false);
    practices.forEach((element) => {
      if (element.practice === practice.id)
        element.order_number = practice.order_number;
    });
    updatePracticeList(practices);
  }
  function onInputChange(event) {
    setPractice({
      ...practice,
      order_number: parseInt(event.target.value),
    });
    // console.log({ practice });
  }

  return (
    <div>
      <Modal
        id="example-modal"
        isCentered
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        titleId="example-title"
      >
        <ModalHeader>
          <ModalTitle id="example-title">Sırayı Değiştir</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Input
            type="number"
            onChange={(e) => onInputChange(e)}
            value={practice.order_number}
          ></Input>
        </ModalBody>
        <ModalFooter>
          <Button
            icon="Close"
            color="danger"
            isLink
            onClick={() => setIsOpenModal(false)}
          >
            İptal
          </Button>

          <Button
            icon="DoneOutline"
            color="success"
            isLight
            onClick={() => savePracticeOrder()}
          >
            Kaydet
          </Button>
        </ModalFooter>
      </Modal>
      <Card className="">
        <CardHeader>
          <CardLabel icon="DirectionsRun">
            <CardTitle>Derse Tanımlı Alıştırmalar</CardTitle>
          </CardLabel>
          <CardActions>
            <Button
              onClick={() => openAddNew()}
              color="success"
              hoverShadow="default"
              icon="Save"
              isLight
            >
              Alıştırma Ekle
            </Button>
          </CardActions>
        </CardHeader>
        <CardBody>
          {practices && (
            <PracticeTable
              practices={practices}
              changeOrder={openDialog}
              deleteItem={removePracticeFromPath}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default LearningPathPractices;
