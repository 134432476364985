import { useFormik } from 'formik';
import React, { useState } from 'react'
import { showError, showSuccess } from '../../../../profilPage/utils';
import Modal, { ModalBody, ModalFooter } from '../../../../../../components/bootstrap/Modal';
import FormGroup from '../../../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../../../components/bootstrap/forms/Input';
import Button from '../../../../../../components/bootstrap/Button';
import Spinner from '../../../../../../components/bootstrap/Spinner';

const BannerModal = ({ isOpen, setIsOpen, isBanner=false, updateBanner, addBanner }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const handleFileChange = (e) => {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        formik.setFieldValue("banner", selectedFile);
        setErrorMessage(false);
        console.log(selectedFile);
      } else {
        formik.setFieldValue("banner", null);
        setErrorMessage(true);
      }
    };

    const formik = useFormik({
      initialValues: {
        banner: "",
      },
      // validate,
      onSubmit: async (values) => {
        if (!formik.values.banner) {
          setErrorMessage(true);
        } else {
          if (isSubmitting) return;
          setIsSubmitting(true);
          const formData = new FormData();
          formData.append("banner", formik.values.banner);
          const response = isBanner ? await updateBanner(formData) : await addBanner(formData);
          if (response.error) {
            showError();
          } else {
            showSuccess("Banner Eklendi");
          }
          setIsSubmitting(false);
          setIsOpen(false)
        }
      },
    });
    return (
      <Modal
        id="example-modal"
        isCentered
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        titleId="example-title"
        size={null}
      >
        <ModalBody>
          <form
            onSubmit={formik.handleSubmit}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                formik.handleSubmit();
              }
            }}
          >
            <div className="col-12">
              <FormGroup id="Banner">
                <Input
                  onChange={handleFileChange}
                  type="file"
                  accept=".png, .jpg"
                />
              </FormGroup>
              {errorMessage && (
                <div>
                  <p
                    style={{
                      color: "#f46639",
                      fontSize: "11px",
                      margin: "3px",
                    }}
                  >
                    Logo Giriniz
                  </p>
                </div>
              )}
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            icon="DoneOutline"
            color="success"
            isLight
            isDisable={isSubmitting}
            onClick={async () => {
              formik.handleSubmit();
            }}
          >
            {isSubmitting ? (
              <Spinner inButton isSmall>
                Loading...
              </Spinner>
            ) : (
              "Gönder"
            )}
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

export default BannerModal