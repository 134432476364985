import React from 'react'
import { CreateNewFolder, SubdirectoryArrowLeft, Check } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Result = ({url1, url2, url3, setResult,id}) => {
  const navigate = useNavigate()
  return (
    <div className='d-flex flex-column justify-content-center align-items-center mt-5' style={{ height: "80vh" }}>
      <div>
      <button className='btn btn-success p-3' style={{ width: "300px", fontSize:"1.5rem"}}>Alıştırma Kaydedildi!</button>
      </div>
    <div className='d-flex justify-content-center align-items-center gap-3 mt-5'>
        <button className='btn btn-outline-warning' onClick={()=>navigate(`/${url1}`)}><SubdirectoryArrowLeft/> ALIŞTIRMALAR</button>
        <button className='btn btn-outline-success' onClick={()=>navigate(`/${url2}`)}><CreateNewFolder/> YENİ ALIŞTIRMA</button>
        <button className='btn btn-outline-primary' onClick={()=>navigate(`/${url3}/${id}`)}><Check/> TEST ET</button>
    </div>
    </div>
  )
}

export default Result
