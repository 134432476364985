import React, { useEffect, useState } from "react";
import ContentLeftMenu from "./ContentLeftMenu";
import CourseList from "./CourseList";
import { menuList } from "./menuList";
import { useSelector } from "react-redux";
import TrailList from "./TrailList";
import Card, { CardBody } from "../../../../../components/bootstrap/Card";
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup'
import Select from '../../../../../components/bootstrap/forms/Select'
import CodelabAndGamesList from "./CodelabAndGamesList";
import { useDispatch } from "react-redux";
import { setCurrentClass } from "../../../admin/school management/school restrictions/restrictionSlice";
import style from "./ContentRestrictions.module.scss"

const ContentRestrictions = ({activeClasses}) => {
  const dispatch = useDispatch();
  const [selectedClassId, setSelectedClassId] = useState()

  useEffect(() => {
    if (activeClasses && activeClasses.length > 0) {
      setSelectedClassId(activeClasses[0].id)
      dispatch(setCurrentClass(activeClasses[0].id));
    }
    // eslint-disable-next-line
  }, [])

  const { menu } = useSelector((store) => store.restriction);
  return (
    <>
     <div className={`${style.classDropdown} pe-5 my-2 mb-4`}>
      <FormGroup id="state" label="Sınıf" isFloating className={`${style.classDropdownForm}`}>
        <Select
          value={String(selectedClassId)}
          id="state"
          ariaLabel="Sınıf"
          placeholder="Sınıf Seçiniz.."
          list={activeClasses?.map(c => ({ value: c.id, text: c.name }))}
          onChange={(e) => { console.log(e.target.value); setSelectedClassId(e.target.value); dispatch(setCurrentClass(e.target.value)); }}
          className={`${style.classDropdownSelect}`}
        />
      </FormGroup>
    </div>
    <Card className={`${style.cardContainer}`}>
      <CardBody>
        <div className="row">
          {/* Sol Menü */}
          <div className="col-12 col-lg-4 col-xl-3">
            <ContentLeftMenu />
          </div>

          {/* İçerik */}
          <div className="col-12 col-lg-8 col-xl-9">
            {menu === menuList.COURSES ? (
              <CourseList selectedClassId={String(selectedClassId)}/>
            ) : menu === menuList.TRAILS ? (
              <TrailList selectedClassId={String(selectedClassId)}/>
            ) : menu === menuList.PLATFORM_APPLICATIONS ? (
              <CodelabAndGamesList selectedClassId={String(selectedClassId)}/>
            ) : (
              <CourseList />
            )}
          </div>
        </div>
      </CardBody>
    </Card>
    </>
  );
};

export default ContentRestrictions;
