const LEARNING_PATH_STATE = {   
    UNKNOWN: {
        id: 0,
        name: 'Unknown',
    },
    ACTIVE: {
        id: 1,
        name: 'Active',
    },
    PASSIVE: {
        id: 2,
        name: 'Passive',
    },
    DRAFT: {
        id: 3,
        name: 'Draft',
    },    
};
export default LEARNING_PATH_STATE;