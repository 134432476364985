import React, { useState } from "react";
import PaginationButtons, {
  dataPagination,
} from "../../../../components/PaginationButtons";
import useSortableData from "../../../../hooks/useSortableData";
import useSelectTable from "../../../../hooks/useSelectTable";
import Checks from "../../../../components/bootstrap/forms/Checks";
import PathIcon from "../../../../components/PathIcon";
import { IconButton, Tooltip } from "@mui/material";
import { Delete, Edit, Check } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Confirm from "../../../common/Confirm";
import { useEffect } from "react";
import style from "./TeacherPractice.module.scss";

const PracticeTable = ({ practices, deleteItem, filteredPractice }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const { items} = useSortableData(
    filteredPractice ? filteredPractice : practices
  );
  const onCurrentPageData = dataPagination(items, currentPage, perPage);
  const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageData);

  const handleDelete = () => {
    if (selected.length > 0) {
      selected.forEach((item) => deleteItem(item));
    }
    setOpen(false);
    setSelected([]);
  };

  const handlCancel = () => setOpen(false);

  useEffect(() => {
    if (selectTable.values.selectedList) {
      setSelected(selectTable.values.selectedList);
    }
  }, [selectTable.values.selectedList]);

  return (
    <>
      <Confirm
        open={open}
        setOpen={setOpen}
        onConfirm={handleDelete}
        onCancel={handlCancel}
      />
      {selected.length > 0 ? (
        <button
          className={`${style.allDeleteButton} btn btn-danger`}
          onClick={() => {
            setSelected(selected);
            setOpen(true);
          }}
        >
          Seçilenleri Sil
        </button>
      ) : (
        <button
          style={{ display: "none" }}
          onClick={() => {
            setSelected(selected);
            setOpen(true);
          }}
        >
          Seçilenleri Sil
        </button>
      )}
      <div className={`${style.customTable} mt-5 px-4`}>
        <div className={`${style.customHeadContainer} align-items-center d-flex my-3 px-2`}>
        <div
              className={`${style.checkContainer} ${style.customTableHead}`}
            >{SelectAllCheck}</div>
          <div className={`${style.customTableHead1} ${style.customTableHead}`}>
            Başlık
          </div>
          <div className={`${style.customTableHead2} ${style.customTableHead}`}>
            Açıklama
          </div>
          <div
            className={`${style.customTableHead3} ${style.customTableHead} text-center`}
          >
            Durum
          </div>
          <div
            className={`${style.customTableHead4} ${style.customTableHead} text-center`}
          >
            Puan
          </div>
          <div
            className={`${style.customTableHead5} ${style.customTableHead} text-center`}
          >
            Eylemler
          </div>
        </div>
        <div className={`${style.customRowContainer}`}>
          {onCurrentPageData.length === 0 ? (
            <div>
              <span>Kayıtlı alıştırma bulunamadı</span>
            </div>
          ) : (
            dataPagination(items, currentPage, perPage).map((item, i) => (
              <div
                key={item.id}
                className={`${style.customTableRow} ${
                  i % 2 === 0 && style.customBackgroundColor
                } d-flex align-items-center mt-1 px-2`}
              >
                <div className={`${style.checkContainer}`}>
                  <Checks
                    id={item.id.toString()}
                    name="selectedList"
                    value={item.id}
                    onChange={selectTable.handleChange}
                    checked={selectTable.values.selectedList.includes(
                      item.id.toString()
                    )}
                  />
                </div>
                <div className={`${style.customTableColumn1}`}>
                  <div>
                    <div className="d-flex align-items-center gap-2">
                      <div
                        className={`${style.PathIcon} d-flex justify-content-center align-items-center`}
                      >
                        <PathIcon
                          technology={item.language}
                          // width="30px"
                          // height="30px"
                        />
                      </div>
                      <div>
                          <div className={`${style.titleContainer}`}>
                      <div className={`${style.titleShort}`}>{item.title}</div>
                      <div className={`${style.titleFull}`}>{item.title}</div>
                        </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div className={`${style.customTableColumn2}`}>
                  <div className={`${style.descriptionContainer}`}>
                      <div className={`${style.descriptionShort}`}>{item.explanation}</div>
                      <div className={`${style.descriptionFull}`}>{item.explanation}</div>
                        </div>
                </div>
                <div className={`${style.customTableColumn3} text-center`}>
                  <div
                    className="mx-auto py-1"
                    style={{
                      background:
                        item.is_active === true ? "#0fa958" : "#D9D9D9",
                    }}
                  >
                    {item.is_active ? <span>aktif</span> : <span>pasif</span>}
                  </div>
                </div>
                <div className={`${style.customTableColumn4} text-center`}>
                  <div>
                    <span>{item.points}</span>
                  </div>
                </div>
                <div
                  className={`${style.customTableColumn5} d-flex justify-content-center`}
                >
                  <Tooltip title="Delete" className={`${style.actionIcon}`}>
                    <IconButton
                      onClick={() => {
                        setSelected([item.id]);
                        setOpen(true);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit" className={`${style.actionIcon}`}>
                    <IconButton
                      onClick={() => {
                        navigate("/teacher/practice-form/" + item.id);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Test" className={`${style.actionIcon}`}>
                    <IconButton
                      onClick={() => {
                        navigate("/teacher/test/" + item.id);
                      }}
                    >
                      <Check />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <PaginationButtons
        data={items}
        label="alıştırma"
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </>
  );
};

export default PracticeTable;
