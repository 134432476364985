import React from "react";
import style from "./CompletedStages.module.scss";
import { nanoid } from "nanoid";
import { Card, Spinner } from "react-bootstrap";
import StageInfo from "./StageInfo";
import ChartInfo from "./ChartInfo";
import Information from "./Information";

const CompletedStagesForTeacher = ({ data, isLoading }) => {
  const course = data?.course ?? 0;
  const codelab = data?.codelab ?? 0;
  const codemino = data?.game.codemino ?? 0;
  const codeboo = data?.game.codeboo ?? 0;
  const codecizz = data?.game.codecizz ?? 0;
  const value = [course, codelab, codemino, codeboo, codecizz];

  const stages = [
    { title: "Dersler", color: "#6C5DD3" },
    { title: "CodeLab", color: "#998FE3" },
    { title: "CodeMino", color: "#352985" },
    { title: "CodeBoo", color: "#918CB3" },
    { title: "CodeÇizz", color: "#D3D2D8" },
  ];

  const studentsCompletionRatio =
    data && data.student_practice_step_lesson_counts;
  return (
    <Card className={`${style.completedStagesContainer} p-3 px-5`}>
      <div className={`${style.chartContainer}`}>
        <div className={`${style.title} my-3 px-3`}>Tamamlanan Aşamalar</div>
        <div className="d-flex">
          <div className={`${style.stageContainer}`}>
            {stages?.map((stage) => (
              <StageInfo key={nanoid()} stage={stage} />
            ))}
          </div>
          {isLoading ? (
            <div className="w-100 d-flex justify-content-center align-items-center" style={{ height: '70vh' }}>
            <div className="text-center">
              <Spinner animation="border" role="status" style={{ width: '3rem', height: '3rem', color: '#007bff' }}>
                <span className="sr-only">...</span>
              </Spinner>
              <h3 className="mt-3" style={{ color: '#007bff', fontWeight: 'bold' }}>Yükleniyor...</h3>
            </div>
          </div>
          ) : (
            <div className={`${style.chartDiv}`}>
              <ChartInfo value={value} />
            </div>
          )}
        </div>
      </div>
      <div className={`${style.informationsContainer}`}>
        <div className={`${style.title} my-3 mb-4 px-3`}>
          Tamamlanan Aşamalar Oranı En Yüksek 5 Öğrenci
        </div>
        {studentsCompletionRatio && studentsCompletionRatio.length < 1 && (
          <div className={`${style.emptyList}`}>
            <span>Öğrenci bulunmamaktadır</span>
          </div>
        )}
        {studentsCompletionRatio &&
          studentsCompletionRatio.length > 0 &&
          studentsCompletionRatio.slice(0,5).map((item, index) => {
            return (
              <div
                className={`${style.information} py-3 px-3 my-3`}
                key={nanoid()}
              >
                <Information
                  description={item.name}
                  email={item.email}
                  value={""}
                  index={index}
                />
              </div>
            );
          })}
      </div>
    </Card>
  );
};

export default CompletedStagesForTeacher;
