import React, { useEffect} from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../../components/bootstrap/Modal";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import Logo from "../../../../../components/Logo";
import Icon from "../../../../../components/icon/Icon";
import Button from "../../../../../components/bootstrap/Button";
import Input from "../../../../../components/bootstrap/forms/Input";
import {
  useGet_a_LiveLectureQuery,
  useUpdateLiveLectureMutation,
} from "../../teacherApi";
import FormGroup from "../../../../../components/bootstrap/forms/FormGroup";
import showNotification from "../../../../../components/extras/showNotification";

const RecordedLiveLessonModal = ({
  isOpenRecordedModal,
  setIsOpenRecordedModal,
  selectedClassId,
  selectedClassName
}) => {
  //states
  const selectedLectureId = useSelector((status) => status.teacher.selectedLectureId);

  // mutations and queries
  const [updateLiveLectureMutation] = useUpdateLiveLectureMutation();

  let tempData = { lectureId: selectedLectureId ?? null };
  const { data: singleLectureData, isSuccess: singleLectureSuccess } =
  useGet_a_LiveLectureQuery(tempData);

  //useEffects
  useEffect(() => {
    if (isOpenRecordedModal) {
      setIsOpenRecordedModal(true);
    } else {
      setIsOpenRecordedModal(false);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (singleLectureSuccess && singleLectureData) {
      formik.setFieldValue("id", singleLectureData.id);
      formik.setFieldValue("classroom", singleLectureData.classroom);
      formik.setFieldValue("classroom_name", singleLectureData.classroom_name);
      
      // live_lecture alt alanı için de aynı şekilde setFieldValue kullanabilirsiniz
      formik.setFieldValue("live_lecture.id", singleLectureData.live_lecture.id);
      formik.setFieldValue("live_lecture.title", singleLectureData.live_lecture.title);
      formik.setFieldValue("live_lecture.description", singleLectureData.live_lecture.description);
      formik.setFieldValue("live_lecture.live_link", singleLectureData.live_lecture.live_link);
      formik.setFieldValue("live_lecture.recorded_link", singleLectureData.live_lecture.recorded_link);
      formik.setFieldValue("live_lecture.date", singleLectureData.live_lecture.date);
      formik.setFieldValue("live_lecture.start_time", singleLectureData.live_lecture.start_time);
      formik.setFieldValue("live_lecture.end_time", singleLectureData.live_lecture.end_time);
    }
    //eslint-disable-next-line
  }, [singleLectureSuccess, singleLectureData]);
  

  //functions

  function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const validate = (values) => {
    const errors = {};
    if (!values.live_lecture || !values.live_lecture.recorded_link) {
      errors.live_lecture = {
        ...errors.live_lecture,
        recorded_link : "Kayıt linkini giriniz"
      }
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      id: 0,
      live_lecture: {
        id: 0,
        title: "",
        description: "",
        live_link: "",
        recorded_link: "",
        date: formatDateToYYYYMMDD(new Date()),
        start_time: "00:00",
        end_time: "00:00",
      },
      classroom: selectedClassId,
      classroom_name:selectedClassName && selectedClassName
    },
    validate,
    onSubmit: (values) => {
      updateLiveLecture();
    },
  });

  const updateLiveLecture = async () => {
    const lectureValues = { ...formik.values };
    const liveLectureValues = {...lectureValues.live_lecture}
    console.log(liveLectureValues);
    const response = await updateLiveLectureMutation({
   id:lectureValues.id,
   live_lecture:liveLectureValues,
   classroom:lectureValues.classroom
    });
    // console.log(response);
    if (response.error) {
      showError();
    } else {
      showSuccess("Kayıt linki başarıyla eklendi");
    }
    setIsOpenRecordedModal(false);
    formik.resetForm();
  };

  function showError() {
    showNotification(
      <span className="d-flex align-items-center">
        <Icon icon="danger" size="lg" className="me-1" />
        <span>Kayıt Başarısız</span>
      </span>,
      "Bir hata oluştu",
      "danger"
    );
  }

  function showSuccess(message) {
    showNotification(
      <span className="d-flex align-items-center">
        <Icon icon="Info" size="lg" className="me-1" />
        <span>Kayıt Başarılı</span>
      </span>,
      message,
      "success"
    );
  }

  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenRecordedModal}
      setIsOpen={setIsOpenRecordedModal}
      titleId="example-title"
      size={null}
    >
      <ModalHeader setIsOpen={setIsOpenRecordedModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} /> <span className="ps-2">Ders Kaydı Ekle</span>
          <span className="ps-2">
            <Icon icon="Computer" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="row gap-3 d-flex justify-content-center">
          <div className="col-lg-8">
            <FormGroup
              // className="col-4"
              id="live_lecture.recorded_link"
              isFloating
              label="Ders Kayıt Linki"
            >
              <Input
                onChange={formik.handleChange}
                value={formik.values.live_lecture.recorded_link ?? ""}
                onBlur={formik.handleBlur}
                isValid={formik.isValid}
                isTouched={formik.touched.live_lecture?.recorded_link}
                invalidFeedback={formik.errors.live_lecture?.recorded_link}
              />
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup id="classroom_name" label="Sınıf" isFloating>
              <Input
                value={formik.values.classroom_name}
                readOnly
              />
            </FormGroup>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={async () => {
            formik.handleSubmit();
          }}
        >
          Kaydet
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RecordedLiveLessonModal;
