import React from "react";
import style from "../ParentReportWithClass.module.scss";
import ChartInfo from "./ChartInfo";
import { Card } from "react-bootstrap";

const LessonCompletedRate = ({ data }) => {
  const defaultLessons = [13,15,14,19,2,3,4,5]
  let firstLessonsToShow = data.course_completion_rate.filter((item)=>defaultLessons.includes(item.id))
  firstLessonsToShow = defaultLessons.map(id => firstLessonsToShow.find(item => item.id === id)).filter(item => item !== undefined);
  const otherLessons = data.course_completion_rate.filter((item)=>!defaultLessons.includes(item.id))
  const startedLessons = otherLessons.filter((item)=>item.percentage > 0);
  const lessonCompletionRateData = firstLessonsToShow.concat(startedLessons);
  
  return (
    <Card className={`${style.LessonCompletedRateContainer} p-3 px-5`}>
      <div className={`${style.title} my-3 px-3`}>Ders Tamamlama Oranı</div>
      <div className="d-flex">
        <div className={`${style.chartDiv}`}>
          <ChartInfo value={lessonCompletionRateData}/>
        </div>
      </div>
    </Card>
  );
};

export default LessonCompletedRate;
