import * as React from "react";
import { useState, useEffect } from "react";
import { get, put } from "../../../../utils/service";
import { useParams } from "react-router-dom";
import { componentsMenu } from "../../../../menu";
import SubHeader, {
  SubHeaderLeft,
} from "../../../../layout/SubHeader/SubHeader";
import Breadcrumb from "../../../../components/bootstrap/Breadcrumb";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Card, {
  CardBody,
} from "../../../../components/bootstrap/Card";
import useSortableData from "../../../../hooks/useSortableData";
import PaginationButtons, {
  dataPagination,
} from "../../../../components/PaginationButtons";
import useSelectTable from "../../../../hooks/useSelectTable";
import Checks from "../../../../components/bootstrap/forms/Checks";
import Icon from "../../../../components/icon/Icon";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/bootstrap/Button";

const AdminAddPracticeToLearningPath = (props) => {
  const [search, setSearch] = useState("")
  const [filteredPractice, setFilteredPractice] = useState("")
  const [practices, setPractices] = useState([]);
  const [path, setPath] = useState({
    id: 0,
    title: "",
    technology: "",
    explanation: "",
    award_point: 0,
    is_active: false,
  });
  const [currentPractices, setCurrentPractices] = useState([]);

  let navigate = useNavigate();
  const { id } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(3);
  const { items, requestSort, getClassNamesFor } = useSortableData(!filteredPractice ? practices : filteredPractice);
  const onCurrentPageData = dataPagination(items, currentPage, perPage);
  const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageData);

  const updateLearningPathPractices = async () => {
    let practicesToAdd = [];

    if (selectTable.values.selectedList.length > 0) {
      selectTable.values.selectedList.forEach((i) => {
        let id = parseInt(i);
        let foundPractice = currentPractices?.find(
          (item) => item.practice === id
        );
        if (foundPractice)
          practicesToAdd.push({
            practice: foundPractice.practice,
            order_number: foundPractice.order_number,
          });
        else practicesToAdd.push({ practice: id, order_number: 0 });
      });
    }
    try {
      await put(`learningpaths/${id}/`, {
        ...path,
        practices: practicesToAdd,
      });
      navigate(`/admin/learning-paths-content/${id}`);
    } catch (err) {}
  };

  const getAllPractices = async () => {
    try {
      const { data } = await get(`practices/`);
      const practices = data;
      setPractices(practices);
    } catch (err) {
    } finally {
    }
  };

  const getLearningPath = async () => {
    try {
      const { data } = await get(`learningpaths/${id}`);
      setPath(data);
    } catch (err) {
    } finally {
    }
  };

  const getLearningPathsPractices = async () => {
    try {
      const { data } = await get(`learningpaths/${id}/practices`);
      var learningPathPractices = data.practices;
      setCurrentPractices(learningPathPractices);
      selectTable.setFieldValue("selectedList", [
        ...selectTable.values.selectedList,
        ...learningPathPractices.map((s) => s.practice.toString()),
      ]);

      setPath(data);
    } catch (err) {
    } finally {
    }
  };

  useEffect(() => {
    getAllPractices();
    getLearningPath();
    getLearningPathsPractices();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    filterPractice();
    //eslint-disable-next-line
  }, [search, practices]);

  
  
  const filterPractice = (e) => {
    if (e && e.preventDefault) { e.preventDefault(); }
    const newPractices = practices?.filter((item)=>item.title.toLocaleLowerCase().includes(search))
    setFilteredPractice(newPractices)
  }
  return (
    <PageWrapper title={componentsMenu.components.subMenu.table.text}>
      <SubHeader>
        <SubHeaderLeft>
          <Breadcrumb
            list={[
              {
                title: 'Ders içeriği',
                to: `/admin/learning-paths-content/${id}`,
              },
              {
                title: 'Alıştırmalar',
                to: `/admin/learning-paths`,
              },
            ]}
          />
        </SubHeaderLeft>
      </SubHeader>
      <Page container="fluid">
        <div className="row">
        <div className="col-12 col-md-6 mx-auto">
            <form className="d-flex gap-1" onSubmit={filterPractice}>
            <input className="form-control" type="search" placeholder="Aramak istediğiniz soruyu yazınız" value={search} onChange={(e)=>setSearch(e.target.value.toLowerCase())}/>
            <button className="btn btn-warning w-25">Bul</button>
          </form> 
          </div>
          <div className="col-12">
            <Card>
              <CardBody>
                <table className="table table-modern">
                  <thead>
                    <tr>
                      <th style={{ width: 50 }}>{SelectAllCheck}</th>
                      <th
                        onClick={() => requestSort("firstName")}
                        className="cursor-pointer text-decoration-underline"
                      >
                        Başlık{" "}
                        <Icon
                          size="lg"
                          className={getClassNamesFor("firstName")}
                          icon="FilterList"
                        />
                      </th>
                      <th
                        onClick={() => requestSort("lastName")}
                        className="cursor-pointer text-decoration-underline"
                      >
                        Açıklama{" "}
                        <Icon
                          size="lg"
                          className={getClassNamesFor("lastName")}
                          icon="FilterList"
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {onCurrentPageData.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <Checks
                            id={item.id.toString()}
                            name="selectedList"
                            value={item.id}
                            onChange={selectTable.handleChange}
                            checked={selectTable.values.selectedList.includes(
                              item.id.toString()
                            )}
                          />
                        </td>
                        <td>{item.title}</td>
                        <td>{item.explanation}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardBody>
              <PaginationButtons
                data={items}
                label="ders"
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </Card>
            <Button
              color="success"
              onClick={updateLearningPathPractices}
            >
              Kaydet
            </Button>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default AdminAddPracticeToLearningPath;
