import React, { useState, useCallback, useMemo } from "react";
import style from "./PathsWithoutClassWrapper.module.scss";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../../layout/Page/Page";
import PathsWithoutClass from "../PathsWithoutClass";
import DashboardHeader from "../../../../common/Headers/DashboardHeader";
import { useParams } from "react-router-dom";
import classPathsLogo1 from "../../../../../assets/lessons/classPathsLogo1.svg";
import classPathsLogo2 from "../../../../../assets/lessons/classPathsLogo2.svg";
import AllLessons from "../../allLessons/AllLessons";
import { useCoursesQuery, useTrailsQuery } from "../../../../../store/features/apiSlice";

const PathsWithoutClassWrapper = () => {
  const _activeTab = sessionStorage.getItem("activeTabTrailSource");
  const [activeTab, setActiveTab] = useState(Number(_activeTab) || 1);

  const schoolParams = `school_status=1`;
  const { data: courses, isLoading } = useCoursesQuery(schoolParams);

  const { data: trails, isLoading:isLoadingTrail } = useTrailsQuery(schoolParams);

  const { contentName } = useParams();
  // h5p öğrenme patikaları içerikleri
  const contents = [
    {
      _contentName: "bilisime_ilk_adim",
      _contentId: "2782644566",
    },
    {
      _contentName: "ygz_orta",
      _contentId: "4083489009",
    },
    {
      _contentName: "ygz_ileri",
      _contentId: "2248244296",
    },
    {
      _contentName: "sg_orta",
      _contentId: "1834753346",
    },
    {
      _contentName: "sg_ileri",
      _contentId: "3940766963",
    },
    {
      _contentName: "wg_orta",
      _contentId: "3408191006",
    },
    {
      _contentName: "wg_ileri",
      _contentId: "3280236086",
    },
    {
      _contentName: "ok_orta",
      _contentId: "1431534494",
    },
    {
      _contentName: "ok_ileri",
      _contentId: "3033468924",
    },
    {
      _contentName: "robotik_orta",
      _contentId: "3265714886",
    },
  ];
  // eslint-disable-next-line
  const isContent = contents?.filter(
    (item) => item._contentName === contentName
  );
  const tabContents = useMemo(() => [
    { content: <PathsWithoutClass trails={trails} isLoadingTrail={isLoadingTrail} />, tabName: "Patikalar", icon1: classPathsLogo1, icon2: classPathsLogo2 },
    { content: <AllLessons courses={courses} isLoading={isLoading} />, tabName: "Dersler", icon1: classPathsLogo1, icon2: classPathsLogo2 },
  ], [trails, isLoadingTrail, courses, isLoading]);
  // const tabContents = [
  //   {
  //     content: <PathsWithoutClass trails={trails} isLoadingTrail={isLoadingTrail} />,
  //     tabName: "Patikalar",
  //     icon1: classPathsLogo1,
  //     icon2: classPathsLogo2,
  //   },
  //   {
  //     content: <AllLessons courses={courses} isLoading={isLoading} />,
  //     tabName: "Dersler",
  //     icon1: classPathsLogo1,
  //     icon2: classPathsLogo2,
  //   },
  //   // {
  //   //   content: (
  //   //     <div className={`${style.H5Psize}`}>
  //   //       {isContent && isContent.length > 0 ? (
  //   //         <H5pWrapper h5pContentId={isContent[0]._contentId} />
  //   //       ) : (
  //   //         <H5pWrapper h5pContentId={282070266} />
  //   //       )}
  //   //     </div>
  //   //   ),
  //   //   tabName: "Ada Görünümü",
  //   //   icon1: classPathsLogo1,
  //   //   icon2: classPathsLogo2,
  //   // },
  // ];
  const handleTabClick = useCallback((tabNumber) => {
    sessionStorage.setItem("activeTabTrailSource", tabNumber);
    setActiveTab(tabNumber);
  }, [setActiveTab]); 
  return (
    <PageWrapper title={"Dersler"}>
      <DashboardHeader />
      <Page
        container={false}
        style={{ padding: "0" }}
        className={`${style.pageContainer}`}
      >
        <div
          className={`${style.tabContainer} d-flex justify-content-evenly align-items-center mx-auto mb-5 px-3 gap-1`}
        >
                 {tabContents.map((tab, index) => (
            <div
              key={index}
              className={`${style.tab} ${
                activeTab === index + 1 ? `${style.activetab}` : ""
              } d-flex justify-content-center align-items-center gap-2 p-3`}
              onClick={() => handleTabClick(index + 1)}
            >
              <div className={`${style.tabsLogo}`}>
                <img
                  src={activeTab === index + 1 ? tab.icon2 : tab.icon1}
                  alt=""
                />
              </div>
              <div className={`${style.tabsDescription}`}>
                <span>{tab.tabName}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="tab-content">
            {tabContents[activeTab - 1] && tabContents[activeTab - 1]?.content}
          </div>
      </Page>
    </PageWrapper>
  );
};

export default PathsWithoutClassWrapper;
