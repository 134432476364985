import React from "react";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../layout/Page/Page";
import PathListTable from "./2-PathListTable";

const PathManagement = () => {
  return (
    <PageWrapper>
      <Page container="fluid">
        <PathListTable/>
      </Page>
    </PageWrapper>
  );
};

export default PathManagement;
