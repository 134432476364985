import { apiSlice } from "../../../../store/features/apiSlice";

export const classRestrictionApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getClassRestrictions: builder.query({
            query: (id) => ({
                url: `restrictions/schools/classrooms/${id}/`,
                method: "get",
            }),
            providesTags: ["restrictions"],
        }),
        updateClassRestrictions: builder.mutation({
            query: (data) => ({
                url: `restrictions/schools/classrooms/${data.id}/`,
                method: "put",
                body:data
            }),
            invalidatesTags: ["restrictions"],
        }),
        getClassTrailRestrictions: builder.query({
            query: (id) => ({
                url: `restrictions/schools/classrooms/${id}/trails`,
                method: "get",
            }),
            providesTags: ["restrictions"],
        }),
        updateClassTrailRestrictions: builder.mutation({
            query: (data) => ({
                url: `restrictions/schools/classrooms/${data.id}/trails/`,
                method: "put",
                body:data
            }),
            invalidatesTags: ["restrictions"],
        }),
        getSchoolCoursesInClassRestrictions: builder.query({
            query: (id) => ({
                url: `restrictions/schools/classrooms/${id}/courses/`,
                method: "get",
            }),
            providesTags: ["restrictions"],
        }),
        updateSchoolCoursesInClassRestrictions: builder.mutation({
            query: (data) => ({
                url: `restrictions/schools/classrooms/${data.id}/courses/`,
                method: "put",
                body:data
            }),
            invalidatesTags: ["restrictions"],
        }),
        getSchoolChaptersInClassRestrictions: builder.query({
            query: ({id, course}) => ({
                // url: `restrictions/schools/${id}/chapters/?course=${course}`,
                url: `restrictions/schools/classrooms/${id}/chapters/`,
                method: "get",
            }),
            providesTags: ["restrictions"],
        }),
        updateSchoolChaptersInClassRestrictions: builder.mutation({
            query: (data) => ({
                url: `restrictions/schools/classrooms/${data.id}/chapters/`,
                method: "put",
                body:data
            }),
            invalidatesTags: ["restrictions"],
        }),

        //--endpoints
    }),
});

export const {
 useGetClassRestrictionsQuery,
 useUpdateClassRestrictionsMutation,
 useGetClassTrailRestrictionsQuery,
 useUpdateClassTrailRestrictionsMutation,
 useGetSchoolCoursesInClassRestrictionsQuery,
 useUpdateSchoolCoursesInClassRestrictionsMutation,
 useGetSchoolChaptersInClassRestrictionsQuery,
 useUpdateSchoolChaptersInClassRestrictionsMutation,

} = classRestrictionApi;