import React from "react";
import { useFormik } from "formik";
import Modal, {
  ModalBody,
  ModalHeader,
} from "../../../../components/bootstrap/Modal";
import { OffCanvasTitle } from "../../../../components/bootstrap/OffCanvas";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import Select from "../../../../components/bootstrap/forms/Select";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/bootstrap/Button";
import { setSelectedClass } from "../teacherSlice";

const ClassForm = ({
  saveClass,
  selectedSchoolId,
  modalStatus,
  setModalStatus,
}) => {
  const { selectedClass } = useSelector((store) => store.teacher);
  const dispatch = useDispatch();

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Ad giriniz!";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      id: 0,
      name: "",
      // school: "",
      state: "1",
      // learning_paths: [],
    },
    validate,
    onSubmit: async (values) => {
      saveClass({
        id: values.id,
        name: values.name,
        state: parseInt(values.state, 10),
        // school: parseInt(values.school, 10),
        // learning_paths: selectedClass.learning_paths,
      });
      dispatch(setSelectedClass({ ...selectedClass }));
      setModalStatus(false);
      formik.resetForm();
    },
  });

  const initialize = async () => {
    formik.resetForm();
    if (selectedClass) formik.setValues(selectedClass);
    // await handleGetSchools();
  };

  React.useEffect(() => {
    initialize();
    //eslint-disable-next-line
  }, [modalStatus]);

  return (
    <div>
      <Modal
        isOpen={modalStatus}
        setIsOpen={setModalStatus}
        titleId="upcomingEdit"
        isCentered
        isScrollable
        size="lg"
      >
        <ModalHeader setIsOpen={setModalStatus}>
          <OffCanvasTitle id="upcomingEdit">
            {selectedClass
              ? "Sınıf Düzenleme Ekranı"
              : "Yeni Sınıf Ekleme Ekranı"}
          </OffCanvasTitle>
        </ModalHeader>
        <ModalBody>
          <div className="row g-4">
            <div className="col-12">
              <div
                className="mb-0"
                stretch={false}
                onSubmit={formik.handleSubmit}
              >
                {/* <WizardItem id="content"> */}
                <div>
                  <form className="row g-4" onSubmit={formik.handleSubmit}>
                    <div className="col-12">
                      <FormGroup id="name" label="Ad" isFloating>
                        <Input
                          placeholder="Ad"
                          onChange={formik.handleChange}
                          value={formik.values.name}
                          isValid={formik.isValid}
                          onBlur={formik.handleBlur}
                          isTouched={formik.touched.name}
                          invalidFeedback={formik.errors.name}
                        />
                      </FormGroup>
                    </div>
                    {/* <div className="col-12">
                        <Select
                          id="school"
                          list={[
                            { value: "Bilisim School", text: "Bilisim School" },
                            {
                              value: "Büyük Ender Okulları",
                              text: "Büyük Ender Okulları",
                            },
                            { value: "ABC Koleji", text: "ABC Koleji" },
                          ]}
                          ariaLabel="Aria Label"
                          placeholder="Okul seçiniz..."
                          onChange={formik.handleChange}
                          value={formik.values.school}
                          isValid={formik.isValid}
                          onBlur={formik.handleBlur}
                          isTouched={formik.touched.school}
                          invalidFeedback={formik.errors.school}
                        />
                      </div> */}
                    <div className="col-12">
                      <FormGroup id="state" label="Durum" isFloating>
                        <Select
                          id="state"
                          ariaLabel="Durum"
                          placeholder="Durum seçiniz..."
                          list={[
                            { value: "1", text: "Aktif" },
                            { value: "2", text: "Pasif" },
                            { value: "3", text: "Tamamlandı" },
                          ]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.state}
                          isValid={formik.isValid}
                          isTouched={formik.touched.state}
                          invalidFeedback={formik.errors.state}
                        />
                      </FormGroup>
                    </div>
                    <div className="d-flex justify-content-end gap-3">
                      <div className="d-flex ">
                        <Button
                          onClick={formik.handleSubmit}
                          className="bg-info"
                        >
                          Kaydet
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
                {/* </WizardItem> */}

                {/* <WizardItem id="learning-paths">
                <ClassLearningPaths/>
                </WizardItem> */}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ClassForm;
