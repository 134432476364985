import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Checks from "../../../../components/bootstrap/forms/Checks";
import Page from "../../../../layout/Page/Page";
import {
  useAddUserLessonActivitiesMutation,
  useAllUserLessonActivitiesQuery,
  useRemoveUserLessonActiviteMutation,
} from "../../../../store/features/apiSlice";
import { setLessonCompleted } from "../../../../store/pathWithVideoSlice";
import style from "./lessonsContent.module.scss";
import H5PIframe from "./5.H5PIframe";
import { H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../../admin/course management/h5p/services/ContentService";
import { useRef } from "react";

const LectureVideo = () => {
  const [addUserLessonActivities] = useAddUserLessonActivitiesMutation();
  const [removeUserLessonActivite] = useRemoveUserLessonActiviteMutation();
  const { data, isSuccess } = useAllUserLessonActivitiesQuery();

  const urlParams = new URLSearchParams(window.location.search);
  const competition = urlParams.get("competition");

  const { lectureVideoContent } = useSelector((store) => store.pathWithVideo);
  
  
  let currentVideo =
  data && data.find((item) => item.lesson === lectureVideoContent.videoId);
  // console.log('%c4-LectureVideo.js line:25 currentVideo', 'color: white; background-color: #007acc;', currentVideo);
  data && data.find((item) => item.lesson === lectureVideoContent.videoId);
  // console.log('%c4-LectureVideo.js line:25 currentVideo', 'color: white; background-color: #007acc;', currentVideo);
  const completedVideo = JSON.parse(localStorage.getItem("finishedLessons"));
  const isCompleted =
    completedVideo && completedVideo.includes(lectureVideoContent.videoId);
  const { lessonCompleted } = useSelector((store) => store.pathWithVideo);
  const dispatch = useDispatch();

  const [first, setFirst] = useState(true);
  const [h5pServerRuns, seth5pServerRuns] = useState(true);

  const addVideo = () => {
    if (lessonCompleted && !currentVideo) {
      addUserLessonActivities({
        lesson: lectureVideoContent.videoId,
        points: 0,
        competition: competition || "",
      });
      localStorage.setItem(
        "finishedLessons",
        JSON.stringify([...completedVideo, lectureVideoContent.videoId])
      );
    }
  };


  const removeVideo = () => {
    if (!lessonCompleted && currentVideo) {
      removeUserLessonActivite(currentVideo.id);
      const newList = completedVideo?.filter(
        (item) => item !== lectureVideoContent.videoId
      );
      localStorage.setItem("finishedLessons", JSON.stringify(newList));
    }
  };

  const handleChange = () => {
    dispatch(setLessonCompleted(!lessonCompleted));
  };

  useEffect(() => {
    if (isSuccess)
      localStorage.setItem(
        "finishedLessons",
        JSON.stringify(data?.map((item) => item.lesson))
      );
      //eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (lectureVideoContent.videoId) {
      if (isCompleted) {
        dispatch(setLessonCompleted(true));
      } else {
        dispatch(setLessonCompleted(false));
      }
    }
    //eslint-disable-next-line
  }, [lectureVideoContent.videoId]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(setLessonCompleted(isCompleted ? true : false));
    }, 5);
    return () => clearTimeout(timeout);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (lessonCompleted) {
      addVideo();
    } else if (!lessonCompleted && !first) {
      removeVideo();
    }
    //eslint-disable-next-line
  }, [lessonCompleted]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFirst(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const h5pPlayer = useRef();
  const contentService = new ContentService("/h5p");
  const [loading, setLoading] = useState(false);
  const onPlayerInitialized = () => {
    setLoading(false);
    console.log("Player initialized, loading state:", loading);
  };
  function handleXApi(statement, context, event) {
    console.log('%c handleXApi', 'color: white; background-color: #007acc;', );
    console.log(statement, context, event)


    if(statement.verb?.display['en-US'] === "completed"){
        console.log('\x1b[41m%s\x1b[0m', 'completed ', );
        //Here runs only on complete 
        let _pointSavedData = localStorage.getItem("lecturePoint");
        console.log("lecturePoint:", _pointSavedData)
        let lecturePoint = false;
        if(_pointSavedData){
          lecturePoint = JSON.parse(_pointSavedData);
        }
        let points = statement.result?.score?.scaled * 5 | 0; 
        const userStr = localStorage.getItem("user");


        if(userStr){
            let videoData = localStorage.getItem("lectureVideoContent");
            let videoDataObj = videoData ? JSON.parse(videoData) : null;
            const data = {
                lesson : videoDataObj.videoId,
                points,
                competition: competition || "",
            }
            if(videoDataObj.videoId !== lecturePoint.videoId){

              localStorage.setItem("lecturePoint",JSON.stringify({
                videoId:videoDataObj.videoId,
                points,
                isSaved:true
              }))

              console.log('\x1b[42m%s\x1b[0m', 'Fetch isteği atıldı', data);
              // fetch(`${process.env.REACT_APP_CENTRAL_API_URL}useractivies/lessons/`, {
              //   method: "POST",
              //   headers: {
              //     'Content-Type': 'application/json',
              //     'Authorization': `Bearer ${getToken()}`
              //   },
              //   body: JSON.stringify(data)
              // });
              addUserLessonActivities(data);
              localStorage.setItem(
                "finishedLessons",
                JSON.stringify([...completedVideo, lectureVideoContent.videoId])
              );
            }

        }
    }
}
// eslint-disable-next-line
const H5PServerHealthCheck = useCallback(async () => {
  try {

    const response = await fetch(`${process.env.REACT_APP_H5P_API_URL}/hello`);
    if (response.ok) {
    } else {
      throw new Error("H5P server is not available.");
    }
  } catch (error) {
    seth5pServerRuns(false);
  }
}, []); // eslint-disable-line react-hooks/exhaustive-deps

const contentSource = useMemo(() => {
  if (h5pServerRuns && lectureVideoContent.content) {
    return `h5p`;
  }
  return 'lumi';
}, [h5pServerRuns, lectureVideoContent.content]);

// useEffect(() => {
//   H5PServerHealthCheck();
// }, []);

  return (
    <Page className={`${style.pageContainerLectureVideo}`}>
      <div className={`${style.videoContainer}`}>
        {contentSource === 'h5p' ? (
          <>
          {/* {busy && (
                      <Spinner color="primary" inButton isSmall size="2x" style={{width:"50px", height:"50px"}}>
                        Loading...
                      </Spinner>
                    )} */}
            <H5PPlayerUI
              ref={h5pPlayer}
              contentId={lectureVideoContent.content}
              contextId={undefined}
              asUserId={"1234"}
              readOnlyState={true}
              loadContentCallback={contentService.getPlay}
              onInitialized={onPlayerInitialized}
              onxAPIStatement={(
                statement,
                context,
                event
            ) => handleXApi(statement, context, event)}
            />
          </>
        ) : (
          <H5PIframe src={`${lectureVideoContent.url}`} />
        )}

        {contentSource === 'lumi' && (
          <div
            className={`${style.lessonCheck} fs-4 d-flex justify-content-start`}
          >
            {/* <Checks
              type="switch"
              value={lessonCompleted}
              label="Dersi Tamamladım"
              checked={lessonCompleted}
              onChange={() => handleChange()}
              disabled
            /> */}
            <span style={{color: "#666", opacity: "0.6"}}>🔒Dersi Tamamladım</span>
          </div>
        )}
        {contentSource === 'lumi' &&(
          <div className="d-flex flex-column mt-3">
            <h3>AÇIKLAMA</h3>
            <p
              className="mt-4 p-1"
              style={{ textAlign: "justify", fontSize: "16px" }}
            >
              {lectureVideoContent.description
                ? lectureVideoContent.description
                : ""}
            </p>
          </div>
        )}
   
      

      </div>
    </Page>
  );
};

export default LectureVideo;
