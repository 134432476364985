import React, { useEffect, useState } from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../components/bootstrap/Modal";
import Logo from "../../../../components/Logo";
import Icon from "../../../../components/icon/Icon";
import Button from "../../../../components/bootstrap/Button";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import { useFormik } from "formik";
import showNotification from "../../../../components/extras/showNotification";
import {
  useCreateSchoolMutation,
  useGetSchoolSubscriptionQuery,
  useGet_a_SchoolQuery,
  useUpdateSchoolMutation,
  useUpdateSchoolSubscriptionMutation,
} from "../../teacher/teacherApi";
import Label from "../../../../components/bootstrap/forms/Label";
import CustomFormField from "../../../../common/customFormField/CustomFormField";

const ShowSchoolModal = ({
  isOpenShowSchoolModal,
  setIsOpenShowSchoolModal,
  schoolId,
}) => {
  const [createSchool] = useCreateSchoolMutation();
  const [updateSchool] = useUpdateSchoolMutation();
  const { data, isSuccess, isLoading} = useGet_a_SchoolQuery(schoolId, {
    skip: !schoolId,
  });
  const [updateSchoolSubscription] = useUpdateSchoolSubscriptionMutation();
  const {
    data: subscriptionData,
    isSuccess: subscriptionSuccess,
    isLoading: subscriptionIsLoading,
  } = useGetSchoolSubscriptionQuery(schoolId, {
    skip: !schoolId,
  });
  const [schoolLogo, setSchoolLogo] = useState("")

  useEffect(() => {
      if (isSuccess){
      formik.setFieldValue("id", data.id);
      formik.setFieldValue("title", data.title);
      // formik.setFieldValue("logo", data.name);
      setSchoolLogo(data.title)
      formik.setFieldValue("max_student", data.max_student);
      formik.setFieldValue("max_teacher", data.max_teacher);
      formik.setFieldValue("max_classroom", data.max_classroom);
      formik.setFieldValue("max_course", data.max_course);
      formik.setFieldValue("max_lesson", data.max_lesson);
    }
    if(subscriptionSuccess){
      formik.setFieldValue(
        "subscription_type",
        subscriptionData.subscription_type || "3"
      );
      formik.setFieldValue(
        "subscription_period",
        subscriptionData.subscription_period || "2"
      );
      formik.setFieldValue(
        "subscription_start_date",
        subscriptionData.subscription_start_date || new Date().toISOString()
      );
      formik.setFieldValue(
        "subscription_expiration_date",
        subscriptionData.subscription_expiration_date ||
          new Date().toISOString()
      );
    }
     else {
      formik.setFieldValue("logo", null);
      setSchoolLogo("")
      formik.resetForm();
    }
    //eslint-disable-next-line
  }, [isSuccess, data, subscriptionSuccess, subscriptionData]);

  const [errorMessage, setErrorMessage] = useState(false);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if(selectedFile){
      formik.setFieldValue("logo", selectedFile);
      setErrorMessage(false);
      console.log(selectedFile);
    }else{
      formik.setFieldValue("logo", null);
      setErrorMessage(true);
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Kurumun adını giriniz";
    }
    if (!values.max_student || values.max_student < 1) {
      errors.max_student = "Geçerli bir sayı giriniz";
    }
    if (!values.max_teacher || values.max_teacher < 1) {
      errors.max_teacher = "Geçerli bir sayı giriniz";
    }
    if (!values.max_classroom || values.max_classroom < 1) {
      errors.max_classroom = "Geçerli bir sayı giriniz";
    }
    if (!values.max_course || values.max_course < 1) {
      errors.max_course = "Geçerli bir sayı giriniz";
    }
    if (!values.max_lesson || values.max_lesson < 1) {
      errors.max_lesson = "Geçerli bir sayı giriniz";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      id: null,
      title: "",
      logo: null,
      max_student: 20,
      max_teacher: 10,
      max_classroom: 5,
      max_course: 2,
      max_lesson: 10,
      subscription_type: "2",
      subscription_period: "2",
      subscription_start_date: new Date().toISOString(),
      subscription_expiration_date: new Date().toISOString(),
    },
    validate,
    onSubmit: () => {
       if (!formik.values.logo && !formik.values.id) {
        setErrorMessage(true)
    }else{
      handleSchool();
    }
    },
  });
  const isCustom = Number(formik.values.subscription_period) === 3;
  const handleSchool = async () => {
    const schoolValues = {
      id: formik.values.id,
      title: formik.values.title,
      logo: formik.values.logo,
      max_student: formik.values.max_student,
      max_teacher: formik.values.max_teacher,
      max_classroom: formik.values.max_classroom,
      max_course: formik.values.max_course,
      max_lesson: formik.values.max_lesson,
    };
    const schoolSubscriptionValues = {
      id: formik.values.id,
      subscription_type: formik.values.subscription_type,
      subscription_period: formik.values.subscription_period,
      subscription_start_date: formik.values.subscription_start_date,
      subscription_expiration_date: isCustom ? formik.values.subscription_expiration_date : null,
    };
    if (schoolValues.id > 0) {
      const formData = new FormData();
      formData.append("id", schoolValues.id);
      formData.append("title", schoolValues.title);
      schoolValues.logo && formData.append("logo", schoolValues.logo);
      formData.append("max_student", schoolValues.max_student);
      formData.append("max_teacher", schoolValues.max_teacher);
      formData.append("max_classroom", schoolValues.max_classroom);
      formData.append("max_course", schoolValues.max_course);
      formData.append("max_lesson", schoolValues.max_lesson);
      const response = await updateSchool({id:data.id, formData});
      const subscriptionResponse = await updateSchoolSubscription({
        id: data.id,
        ...schoolSubscriptionValues,
      });
      if (response.error || subscriptionResponse.error) {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="danger" size="lg" className="me-1" />
            <span>Kayıt Başarısız</span>
          </span>,
          "Bir hata oluştu",
          "danger"
        );
      } else {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Kayıt Başarılı</span>
          </span>,
          "Kurum başarıyla güncellendi.",
          "success"
        );
      }
    } else {
      const formData = new FormData();
      formData.append("id", schoolValues.id);
      formData.append("title", schoolValues.title);
      formData.append("logo", schoolValues.logo);
      formData.append("max_student", schoolValues.max_student);
      formData.append("max_teacher", schoolValues.max_teacher);
      formData.append("max_classroom", schoolValues.max_classroom);
      const response = await createSchool(formData);
      if (response.error) {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="danger" size="lg" className="me-1" />
            <span>Kayıt Başarısız</span>
          </span>,
          "Bir hata oluştu",
          "danger"
        );
      } else {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Kayıt Başarılı</span>
          </span>,
          "Kurum başarıyla eklendi.",
          "success"
        );
      }
    }
    setIsOpenShowSchoolModal(false);
  };
  if (isLoading || subscriptionIsLoading) {
    return <div>Loading...</div>;
  }
  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenShowSchoolModal}
      setIsOpen={setIsOpenShowSchoolModal}
      titleId="example-title"
      size={null}
    >
      <ModalHeader setIsOpen={setIsOpenShowSchoolModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} />{" "}
          <span className="ps-2">{`${
            !schoolId ? "Yeni Kurum" : "Kurumu Güncelle"
          }`}</span>
          <span className="ps-2">
            <Icon icon="Computer" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="row gap-3 d-flex justify-content-center">
          <div className="col-lg-8">
            <CustomFormField type="text" label="Kurum Adı" name={"title"} formik={formik}/>
          </div>
          <div className="col-lg-8">
            <Label>Logo:{schoolLogo}</Label>
            <FormGroup
              id="logo"
            >
              <Input onChange={handleFileChange} type="file" accept=".png" />
            </FormGroup>
            {errorMessage && <div><p style={{ color: "#f46639", fontSize:"11px", margin:"3px" }}>Logo Giriniz</p></div> }
          </div>
          <div className="col-lg-8">
            <CustomFormField type="number" label="Maksimum Öğrenci" name={"max_student"} formik={formik}/>
          </div>
          <div className="col-lg-8">
            <CustomFormField type="number" label="Maksimum Öğretmen" name={"max_teacher"} formik={formik}/>
          </div>
          <div className="col-lg-8">
            <CustomFormField type="number" label="Maksimum Sınıf" name={"max_classroom"} formik={formik}/>
          </div>
          <div className="col-lg-8">
            <CustomFormField type="number" label="Maksimum Ders" name={"max_course"} formik={formik}/>
          </div>
          <div className="col-lg-8">
            <CustomFormField type="number" label="Maksimum Video" name={"max_lesson"} formik={formik}/>
          </div>
          {schoolId > 0 && (
            <>
              <div className="col-lg-8">
                <CustomFormField type="select"  label="Üyelik Tipi" name={"subscription_type"} options={[
                    { value: "2", text: "Standart" },
                    { value: "3", text: "Premium" },
                  ]} formik={formik}/>
              </div>
              <div className="col-lg-8">
                <CustomFormField type="select"  label="Üyelik Süresi" name={"subscription_period"} options={[
                      { value: "3", text: "Özel" },
                      { value: "1", text: "Aylık" },
                      { value: "2", text: "Yıllık" },
                  ]} formik={formik}/>
              </div>
              <div className="col-lg-8">
                <FormGroup
                  id="subscription_start_date"
                  label="Başlangıç Tarihi"
                  isFloating
                >
                  <Input
                    type={"date"}
                    value={formik.values.subscription_start_date.substring(
                      0,
                      10
                    )}
                    placeholder="Başlangıç Tarihini giriniz"
                    onChange={formik.handleChange}
                    isValid={formik.isValid}
                    onBlur={formik.handleBlur}
                    isTouched={formik.touched.subscription_start_date}
                    invalidFeedback={formik.errors.subscription_start_date}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-8">
                <FormGroup
                  id="subscription_expiration_date"
                  label="Bitiş Tarihi"
                  isFloating
                >
                  <Input
                    type={"date"}
                    value={formik.values.subscription_expiration_date.substring(
                      0,
                      10
                    )}
                    placeholder="Bitiş Tarihini giriniz"
                    onChange={formik.handleChange}
                    isValid={formik.isValid}
                    onBlur={formik.handleBlur}
                    isTouched={formik.touched.subscription_expiration_date}
                    invalidFeedback={formik.errors.subscription_expiration_date}
                    readOnly={!isCustom}
                  />
                </FormGroup>
              </div>
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={async () => {
            formik.handleSubmit();
          }}
        >
          {`${!schoolId ? "Kaydet" : "Güncelle"}`}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ShowSchoolModal;
