import React from 'react'
import { useCoursesQuery, useTrailsQuery } from '../../../../../store/features/apiSlice';
import SchoolOrPartnerContents from './SchoolOrPartnerContents';
import PageWrapper from '../../../../../layout/PageWrapper/PageWrapper';
import Page from '../../../../../layout/Page/Page';

const SchoolOrPartnerContentsWrapper = ({isPartner}) => {
  const schoolId = localStorage.getItem("school");
  const schoolParams = `school_status=2&school=${schoolId}`
  const { data: schoolCourses, isLoading:schoolCourseLoading  } = useCoursesQuery(schoolParams, {skip:isPartner});

  const partnerParams = `school_status=3`
  const { data: partnerCourses, isLoading:partnerCourseLoading  } = useCoursesQuery(partnerParams, {skip:!isPartner});
  const { data: partnerTrails, isLoading:partnerTrailLoading  } = useTrailsQuery(partnerParams, {skip:!isPartner});

  return (
    <PageWrapper>
      <Page>
      {isPartner && <SchoolOrPartnerContents isPartner={isPartner} trails={partnerTrails} trailLoading={partnerTrailLoading} courses={partnerCourses} courseLoading={partnerCourseLoading} />}
      {!isPartner && <SchoolOrPartnerContents isPartner={isPartner} courses={schoolCourses} courseLoading={schoolCourseLoading} />}
      </Page>
    </PageWrapper>
  )
}

export default SchoolOrPartnerContentsWrapper