import React, { useEffect, useState } from "react";
import Card, { CardBody } from "../../../../../components/bootstrap/Card";
import style from "./CompetitionJoinedStudents.module.scss";
import {
  useGetListCompetitionJoinedStudentsQuery,
  useUpdateStudentProjectPointsMutation,
  useLazyGetStudentProjectFileQuery,
} from "../talentCupApi";
import { useParams } from "react-router-dom";
import PaginationButtons, {
  dataPagination,
  PER_COUNT,
} from "../../../../../components/PaginationButtons";
import useSortableData from "../../../../../hooks/useSortableData";
import { Edit } from "../../../../../components/icon/material-icons";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../../components/bootstrap/Modal";
import { useFormik } from "formik";
import showNotification from "../../../../../components/extras/showNotification";
import Icon from "../../../../../components/icon/Icon";
import CustomFormField from "../../../../../common/customFormField/CustomFormField";
import Button from "../../../../../components/bootstrap/Button";
import Logo from "../../../../../components/Logo";
import { downloadExcel } from "../../../../../common/downloadExcel";

const CompetitionJoinedStudents = () => {
  let { id: competitionId, type } = useParams();
  const isProject = type === "Project";
  const [triggerDownload] = useLazyGetStudentProjectFileQuery();
  const handleDownload = async (userId) => {
    try {
      const data = await triggerDownload({
        id: competitionId,
        userId,
      }).unwrap(); // unwrap ile promise döndür

      if (!data) {
        console.error("Dosya alınamadı!");
        return;
      }

      // Blob'u indirilebilir bir dosyaya çevir
      const blob = new Blob([data], { type: "application/zip" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `project_${userId}.zip`; // Dinamik dosya adı
      link.click();

      // URL belleğini temizle
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Dosya indirilemedi:", error);
    }
  };
  const { data } = useGetListCompetitionJoinedStudentsQuery(competitionId, {
    skip: !competitionId,
  });
  const sortData = (data) => {
    // Eğer data yoksa veya tanımlı değilse boş bir dizi döndür
    if (!Array.isArray(data)) {
      return [];
    }

    // Orijinal diziyi bozmamak için kopyasını alın
    const sortedData = [...data].sort((a, b) => {
      // Öncelikle student_competition_score'a göre azalan sıralama
      if (b.student_competition_score !== a.student_competition_score) {
        return b.student_competition_score - a.student_competition_score;
      }
      // Eğer student_competition_score eşitse, general_points'a göre azalan sıralama
      if (b.user.general_points !== a.user.general_points) {
        return b.user.general_points - a.user.general_points;
      }
      // Eğer general_points da eşitse, active_days_count'a göre azalan sıralama
      return b.user.active_days_count - a.user.active_days_count;
    });

    return sortedData;
  };

  const sortedData = sortData(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_COUNT["10"]);
  const { items } = useSortableData(sortedData ? sortedData : []);

  const [isOpen, setIsOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState(false);

  function updatePoints(joined) {
    setCurrentProject(joined);
    setIsOpen(true);
  }
  const handleReport = () => {
    const data = items;
    const headers = [
      { key: "user.full_name", label: "Kullanıcı Adı" },
      { key: "user.email", label: "E-posta" },
      { key: "user.school.title", label: "Okul" },
      { key: "student_competition_score", label: "Yarışma Puanı" },
      { key: "user.general_points", label: "Genel Puan" },
      { key: "user.active_days_count", label: "Aktif Günler" },
      { key: "user.classroom_points", label: "Sınıf Puanı" },
      { key: "user.parent_telephone_number", label: "Veli Telefon Numarası" },
    ];
    const fileName = "kullanici_listesi.xlsx";
    downloadExcel(data, headers, fileName);
  };
  return (
    <Card className={`${style.cardContainer} w-100`}>
      <UpdateProjectPoints
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        currentProject={currentProject}
        competitionId={competitionId}
      />
      <CardBody>
        <div className={`${style.customTable} mt-5 px-4`}>
          <Button
            className={`${style.messageButton} btn`}
            style={{ width: "150px" }}
            color="primary"
            onClick={() => handleReport()}
          >
            Excel olarak indir
          </Button>
          <div
            className={`${style.customHeadContainer} d-flex align-items-center my-3 px-2`}
          >
            <div
              className={`${style.customTableHead1} ${style.customTableHead}`}
            >
              Ad Soyad
            </div>
            <div
              className={`${style.customTableHead2} ${style.customTableHead}`}
            >
              Email
            </div>

            <div
              className={`${style.customTableHead3} ${style.customTableHead} text-center`}
            >
              Kurum
            </div>
            <div
              className={`${style.customTableHead4} ${style.customTableHead} text-center`}
            >
              Yarışma Puanı
            </div>
            <div
              className={`${style.customTableHead5} ${style.customTableHead} text-center`}
            >
              Genel Puan
            </div>
            <div
              className={`${style.customTableHead6} ${style.customTableHead} text-center`}
            >
              Aktif Günler
            </div>
            <div
              className={`${style.customTableHead7} ${style.customTableHead} text-center`}
            >
              Sınıf Puanı
            </div>
          </div>
          <div className={`${style.customRowContainer}`}>
            {items.length === 0 ? (
              <div>
                <span>Kayıtlı öğrenci bulunamadı</span>
              </div>
            ) : (
              dataPagination(items, currentPage, perPage).map((joined, i) => {
                const {
                  id: studentProjectId,
                  points,
                  student_competition_score,
                  user,
                  youtube_url,
                } = joined;
                return (
                  <div
                    key={studentProjectId}
                    className={`${style.customTableRow} ${
                      i % 2 === 0 && style.customBackgroundColor
                    } d-flex align-items-center mt-1 px-2`}
                  >
                    <div className={`${style.customTableColumn1}`}>
                      <div>
                        <div className={`${style.titleContainer}`}>
                          <div className={`${style.titleShort}`}>
                            {user.full_name
                              ? user.full_name
                              : user.email.split("@")[0]}
                          </div>
                          <div className={`${style.titleFull}`}>
                            {user.full_name
                              ? user.full_name
                              : user.email.split("@")[0]}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${style.customTableColumn2}`}>
                      <div className={`${style.descriptionContainer}`}>
                        <div className={`${style.descriptionShort}`}>
                          {user.email}
                        </div>
                        <div className={`${style.descriptionFull}`}>
                          {user.email}
                        </div>
                      </div>
                    </div>
                    <div className={`${style.customTableColumn3} text-center`}>
                      <div>{user.school?.title}</div>
                    </div>
                    <div className={`${style.customTableColumn4} text-center`}>
                      {student_competition_score}
                      {isProject && (
                        <>
                          <Edit
                            className="cursor-pointer ms-3"
                            onClick={(e) => updatePoints(joined)}
                            // id={id}
                          />
                          <Icon
                            icon="Download"
                            className="cursor-pointer ms-3"
                            onClick={(e) => handleDownload(user.id)}
                          />
                            {youtube_url && <Icon
                              icon="OndemandVideo"
                              className="cursor-pointer ms-3"
                              onClick={() => {
                                const youtubeLink = youtube_url;
                                if (youtubeLink) {
                                  window.open(youtubeLink, "_blank");
                                } else {
                                  alert("YouTube linki bulunamadı.");
                                }
                              }}
                            />}
                        </>
                      )}
                    </div>
                    <div className={`${style.customTableColumn5} text-center`}>
                      {user.general_points}
                    </div>
                    <div className={`${style.customTableColumn6} text-center`}>
                      {user.active_days_count}
                    </div>
                    <div className={`${style.customTableColumn7} text-center`}>
                      {user.classroom_points}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <PaginationButtons
          data={items}
          label="kullanıcı"
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </CardBody>
    </Card>
  );
};

export default CompetitionJoinedStudents;

export const UpdateProjectPoints = ({
  isOpen,
  setIsOpen,
  currentProject,
  competitionId,
}) => {
  const [updatePoints] = useUpdateStudentProjectPointsMutation();
  const formik = useFormik({
    initialValues: {
      student_competition_score: currentProject.student_competition_score,
    },
    // validate,
    onSubmit: async (values) => {
      const response = await updatePoints({
        competitionId: competitionId,
        studentProjectId: currentProject.id,
        points: { points: formik.values.student_competition_score },
      });
      if (response.error) {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="danger" size="lg" className="me-1" />
            <span>Kayıt Başarısız</span>
          </span>,
          "Bir hata oluştu",
          "danger"
        );
      } else {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Kayıt Başarılı</span>
          </span>,
          "Puan Güncellendi",
          "success"
        );
        setIsOpen(false);
        formik.resetForm();
      }
    },
  });
  useEffect(() => {
    initialize();
    //eslint-disable-next-line
  }, [isOpen]);
  //methods
  const initialize = async () => {
    console.log(currentProject);
    formik.resetForm();
    if (currentProject) {
      formik.setFieldValue("student_competition_score", currentProject.student_competition_score);
    }
  };
  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      titleId="example-title"
      size={null}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} /> <span className="ps-2">Puanı Güncelle</span>
          <span className="ps-2">
            <Icon icon="Computer" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="row gap-3 d-flex justify-content-center">
          <div className="col-lg-8">
            <CustomFormField
              type="text"
              label="points"
              name={"student_competition_score"}
              formik={formik}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={async () => {
            formik.handleSubmit();
          }}
        >
          Kaydet
        </Button>
      </ModalFooter>
    </Modal>
  );
};
