import React from 'react'
import style from "./PlatformRestrictions.module.scss"

const PlatformRestrictions = () => {
  return (
    <div className={`${style.lesssonManagementContainer}`}>
        <div className={`${style.defaultBox} d-flex justify-content-center align-items-center`}>
        <div className='d-flex justify-content-center align-items-center mx-auto'>
            <span>
            Yakında...
            </span>
        </div>
        </div>
    </div>
  )
}

export default PlatformRestrictions