import React, {useState } from 'react';
import Breadcrumb from '../../../../../components/bootstrap/Breadcrumb';
import Button from '../../../../../components/bootstrap/Button';
import PageWrapper from '../../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from '../../../../../layout/SubHeader/SubHeader';
import Page from '../../../../../layout/Page/Page';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../../components/bootstrap/Card';
import LearninPathsTable from '../5-learning-paths-table';
import ClassStudents from './class-students';

const ClassContent = (props) => {

const TABS = {
    STUDENTS: 'Öğrenciler',
    LEARNINGPATHS: 'Dersler',
};
const [activeTab, setActiveTab] = useState(TABS.STUDENTS);

const handleAddUpdateClass = async (data) => {
	console.log(props)
	console.log(data);
	//await put("classes/",{id:1,...data});

}

    return (
        <PageWrapper title={'Sınıf İçeriği'}>
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						list={[
							{ title: 'Sınıflarım', to: '/teacher-classes' },
							{ title: 'Sınıf Ayarları', to: '/' },
						]}
					/>
					<SubheaderSeparator />
					<span className='text-muted'>Sınıf Ayarları</span>
				</SubHeaderLeft>
			</SubHeader>
			<Page container='fluid'>
				<div className='row h-100'>
					<div className='col-xl-3 col-lg-4 col-md-6'>
						<Card stretch>
							<CardHeader>
								<CardLabel icon='Class' iconColor='info'>
									<CardTitle>Sınıf Ayarları</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody isScrollable>
								<div className='row g-3'>
									<div className='col-12'>
										<Button
											icon='Groups'
											color='info'
											className='w-100 p-3'
											isLight={TABS.STUDENTS !== activeTab}
											onClick={() => setActiveTab(TABS.STUDENTS)}>
											{TABS.STUDENTS}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='MenuBook'
											color='info'
											className='w-100 p-3'
											isLight={TABS.LEARNINGPATHS !== activeTab}
											onClick={() => setActiveTab(TABS.LEARNINGPATHS)}>
											{TABS.LEARNINGPATHS}
										</Button>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-xl-9 col-lg-8 col-md-6'>
						{TABS.STUDENTS === activeTab && (
							<ClassStudents/>
						)}
						{TABS.LEARNINGPATHS === activeTab && (
							<Card
								stretch
								tag='form'
								noValidate
								>
								<CardHeader>
									<CardLabel icon='Place' iconColor='info'>
										<CardTitle>{TABS.LEARNINGPATHS}</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody className='pb-0' isScrollable>
									<div className='row g-4'>
										<div className='col-lg-12'>
											<LearninPathsTable onAdd={handleAddUpdateClass}/>											
										</div>
										
									</div>
								</CardBody>
								
							</Card>
						)}
					</div>
				</div>
			</Page>
		</PageWrapper>
    );
};

export default ClassContent;
