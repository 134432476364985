import React, { useState } from "react";
import style from "./Students.module.scss";
import Card from "../../../../../components/bootstrap/Card";
import { nanoid } from "nanoid";
import PaginationButtons, { dataPagination } from "../../../../../components/PaginationButtons";
import starIcon from "../../../../../assets/school/starIcon.svg"
import { useSchoolUsersQuery } from "../../schoolManagerApi";
import useSortableData from "../../../../../hooks/useSortableData";

const Students = () => {
  const schoolStudents = `role=1`
  const { data:students } = useSchoolUsersQuery(schoolStudents);
  const { items } = useSortableData(
    students
      ? students.results
      : []
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  return (
    <Card className={`${style.cardContainer}`}>
      <div className={`${style.title} pt-2 my-2`}>
        <span className={`ms-5`}>
          Öğrenciler
        </span>
      </div>
      <div className={`${style.customTable} px-4`}>
        <div
          className={`${style.customHeadContainer} d-flex align-items-center justify-content-between my-1 px-2`}
        >
          <div className={`${style.customTableHead1} ${style.customTableHead}`}>
            Ad Soyad
          </div>
          <div className={`${style.customTableHead2} ${style.customTableHead}`}>
            {/* Sınıf */}
          </div>
          <div className={`${style.customTableHead3} ${style.customTableHead}`}>
            {/* Puan */}
          </div>
        </div>

        <div className={`${style.customRowContainer}`}>
          {items && items.length === 0 ? (
            <div className={`${style.emptyTable}`}>
              <span>Öğrenci bulunamadı</span>
            </div>
          ) : (
            items &&
            dataPagination(items, currentPage, perPage).map((item, i) => (
              <div
                key={nanoid()}
                className={`${style.customTableRow} d-flex align-items-center justify-content-between mb-2 px-2`}
              >
                  <div
                  className={`${style.customTableColumn1} ${style.tableColumn}`}
                >
                  <div>
                    <div className={`${style.studentNameContainer}`}>
                      <div className={`${style.studentNameShort}`}>
                        {item.full_name}
                      </div>
                      <div className={`${style.studentNameFull}`}>
                      {item.full_name}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`${style.customTableColumn2} ${style.tableColumn}`}
                >
                  <div className={`${style.classNameContainer}`}>
                      <div className={`${style.classNameShort}`}>
                      {(item.classrooms !== null) ? item.classrooms?.name : ""}
                      </div>
                      <div className={`${style.classNameFull}`}>
                      {(item.classrooms !== null) ? item.classrooms?.name : ""}
                      </div>
                    </div>
                </div>
                <div
                  // className={`${style.customTableColumn3} ${style.tableColumn}`}
                >
                  <div className="d-flex align-items-center gap-1">
                    <div>
                    {/* <img src={starIcon} alt="" /> */}
                    </div>
                    <div>
                 {/* {item.student_points} */}
                    </div>
                  </div>
                </div>
              
              </div>
            ))
          )}
        </div>
      </div>
      <div className={`${style.paginationButtons}`}>
      <PaginationButtons
          data={items}
          label="öğrenci"
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </div>
    </Card>
  );
};

export default Students;
