import React, { useState, useEffect } from "react";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import SubHeader, {
  SubHeaderLeft,
} from "../../../../layout/SubHeader/SubHeader";
import { adminPages } from "../../../../menu";
import LearningPathsTable from "../../learning-paths/LearningPathsTable";
import { get, post, del } from "../../../../utils/service";
import LearninPathForm from "../../learning-paths/LearninPathForm";
import Spinner from "../../../../components/bootstrap/Spinner";
import { useNavigate } from "react-router-dom";

const AdminLearningPaths = () => {
  const [paths, setPaths] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleDeleteItem = (id) => {
    try {
      del("learningpaths/" + id).then(() => handleGetPaths());
    } catch (error) {
    } finally {
    }
  };

  const handleEditItem = (id) => {
    navigate("/admin/learning-paths-content/"+id);
  };

  const handleGetPaths = async () => {
    const result = await get("learningpaths/");
    setPaths(result.data);
    setLoading(false);
  };
  const handleSavePath = async (path = {}) => {
    try {
      await post("learningpaths/", path);
      handleGetPaths();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetPaths();
  }, []);
  return (
    <PageWrapper title={adminPages.learningPath.text}>
      <SubHeader>
        <SubHeaderLeft>
          <div className="text-muted">
            <strong>
              <span className="text-info fw-bold">CodeLab</span>
            </strong>
          </div>
        </SubHeaderLeft>
      </SubHeader>
      <Page>
        <LearninPathForm savePath={handleSavePath} />
        <br />
        {loading ? (
          <Spinner color="success">Loading...</Spinner>
        ) : (
          <LearningPathsTable paths={paths} deleteItem={handleDeleteItem} editItem={handleEditItem} />
        )}
      </Page>
    </PageWrapper>
  );
};

export default AdminLearningPaths;
