import React from 'react'
import Page from '../../../../../../layout/Page/Page'
import DocumentListTable from './2-DocumentListTable'

const DocumentManagement = () => {
  return (
    <>
    <Page container="fluid">
      <DocumentListTable/>
    </Page>
  </>
  )
}

export default DocumentManagement