import React from 'react';
import Card , { CardBody } from '../../../../../../components/bootstrap/Card';

const TeacherCard = (
    {children}
) => {
    return (
        <Card style={{width: '100%', height:'90%'}}>
            <CardBody>
                {children}
            </CardBody>
        </Card>
    );
};

export default TeacherCard;