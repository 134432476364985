import React, { useState } from "react";
import RestrictionCard from "../components/RestrictionCard";
import SimpleSelectList from "../components/SimpleSelectList";
import {
  useGetSchoolTrailRestrictionsQuery,
  useUpdateSchoolTrailRestrictionsMutation,
} from "../restrictionApi";
import Confirm from "../../../../../common/Confirm";

const TrailList = () => {
  const schoolId = localStorage.getItem("schoolId");

  const {
    data: trailsForSchoool,
    isLoading,
    error,
  } = useGetSchoolTrailRestrictionsQuery(schoolId);
  let selectedTrails = trailsForSchoool
    ?.filter((item) => item.restricted === true)
    ?.map((item) => item.id);

  const [updateSchoolTrails] = useUpdateSchoolTrailRestrictionsMutation();

   //! confirm
   const [open, setOpen] = useState(false);
   const [courseId, setCourseId] = useState(null);
   const handleCancel = () => setOpen(false);
   const changeStatus = (id) => {
     setCourseId(id);
     setOpen(true);
   };
   const handleTrails = async () => {
    setRestrictedTrails(courseId);
   };

  function setRestrictedTrails(id) {
    const isIdPresent = selectedTrails.includes(Number(id));

    if (!isIdPresent) {
      selectedTrails.push(Number(id));
    } else {
      const filteredTrails = selectedTrails.filter(
        (trailId) => trailId !== Number(id)
      );
      selectedTrails = filteredTrails;
    }
    updateSchoolTrails({ id: schoolId, trails: selectedTrails });
    setOpen(false);
  }

  return (
    <div>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error</p>}
      {!isLoading && !error && (
        <div className="row">
          <Confirm
        open={open}
        setOpen={setOpen}
        onConfirm={handleTrails}
        onCancel={handleCancel}
      />
          <div className="col">
          <RestrictionCard>
            <div style={{ minWidth: "250px" }}>
              <SimpleSelectList
                items={trailsForSchoool}
                onSelect={(id) => changeStatus(id)}
                selectedItems={selectedTrails}
                selectKey={"id"}
                displayKey={"title"}
              />
            </div>
          </RestrictionCard>
          </div>
        </div>
      )}
    </div>
  );
};

export default TrailList;
