import React from 'react';
// import { useGetActiveClassesQuery } from '../../teacherApi'
import Activities from './Activities';
import { useGetSchoolClassesQuery } from '../../schoolManagerApi';
const ActivitiesWrapper = () => {
    // const { data: activeClasses,isSuccess } = useGetActiveClassesQuery
    // ();
    const { data: activeClasses, isSuccess } = useGetSchoolClassesQuery();
    // const activeClasses = [0];
    // const isSuccess = true; 
    return (
        <>
            {isSuccess && activeClasses 
            && activeClasses.length > 0 ? 
            <Activities activeClasses={activeClasses} /> 
            : null}
        </>
    );
};

export default ActivitiesWrapper;