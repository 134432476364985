import * as XLSX from 'xlsx';

// Derin erişim için bir yardımcı fonksiyon
const getValueFromObject = (obj, key) => {
  if (key.includes('.')) {
    // Eğer key nokta içeriyorsa, derin erişim yap
    return key.split('.').reduce((o, i) => (o ? o[i] : null), obj);
  } else {
    // Nokta içermiyorsa, doğrudan erişim yap
    return obj[key];
  }
};

export const downloadExcel = (data, headers, filename) => {
  // Başlık ve veri eşlemesini yap
  const worksheet = XLSX.utils.json_to_sheet(data.map(item => {
    const mappedItem = {};
    headers.forEach(header => {
      mappedItem[header.label] = header.transform
        ? header.transform(getValueFromObject(item, header.key))
        : getValueFromObject(item, header.key);
    });
    return mappedItem;
  }));
  
  // Çalışma kitabı oluştur
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sayfa1');

  XLSX.writeFile(workbook, filename);
};

