import React from "react";
import Button from "../../../../../../components/bootstrap/Button";
import { CardBody } from "../../../../../../components/bootstrap/Card";
import { IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import showNotification from "../../../../../../components/extras/showNotification";
import Icon from "../../../../../../components/icon/Icon";
import { useState } from "react";
import useSortableData from "../../../../../../hooks/useSortableData";
import { useEffect } from "react";
import Confirm from "../../../../../common/Confirm";
import {
  useDeleteVideoMutation,
  useVideosQuery,
} from "../../teacherEducationApi";
import ShowVideoModal from "./3-ShowVideoModal";
import { TEACHER_COURSE_STATUS } from "../../../../../../common/data/enumTeacherEducationCourseState";

const rowStyle = {
  cursor: "pointer",
};

const VideoListTable = () => {
  const { data} = useVideosQuery();
  const [deleteVideo] = useDeleteVideoMutation();

  const [search, setSearch] = useState("");
  const [filteredVideos, setFilteredVideos] = useState("");

  const { items } = useSortableData(
    filteredVideos ? filteredVideos : data ? data : []
  );

  const filterVideo = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const newVideos =
      data &&
      data.filter(
        (item) => item.title && item.title.toLowerCase().includes(search)
      );
    setFilteredVideos(newVideos);
  };

  useEffect(() => {
    filterVideo();
    //eslint-disable-next-line
  }, [search, data]);

  const [isOpenVideoModal, setIsOpenVideoModal] = useState(false);
  const [videoId, setVideoId] = useState(null);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [open, setOpen] = useState(false);

  //! handle video-------
  const handleVideo = (e) => {
    console.log(
      "%cUpdateVideo.js line:180 e",
      "color: white; background-color: #007acc;",
      e
    );
    let currentVideo =
      data && data.find((video) => Number(video.id) === Number(e.currentTarget.id));
    setCurrentVideo(currentVideo);
    setIsOpenVideoModal(true);
  };

  //! delete video--------
  const removeVideo = (e) => {
    setOpen(true);
    setVideoId(e.currentTarget.id && e.currentTarget.id);
  };
  const handleDelete = async () => {
    const response = await deleteVideo(videoId);
    setOpen(false);
    if (response.error) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Silme İşlemi Başarısız</span>
        </span>,
        "Bir hata meydana geldi",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Silme Başarılı</span>
        </span>,
        "Video başarıyla silindi.",
        "success"
      );
    }
  };
  const handleCancel = () => setOpen(false);
  //!-----------------------------------------------

  return (
    <>
      <ShowVideoModal
        isOpenVideoModal={isOpenVideoModal}
        setIsOpenVideoModal={setIsOpenVideoModal}
        currentVideo={currentVideo}
      />
      <Confirm
        open={open}
        setOpen={setOpen}
        onConfirm={handleDelete}
        onCancel={handleCancel}
      />
      <div className="row">
        <div className="col-3">
          <Button
            color="info"
            className={`ms-5 mb-4 shadow-3d-up-hover}`}
            onClick={(e) => handleVideo(e)}
          >
            Yeni Video Ekle
          </Button>
        </div>
        <div className="col-6">
          <form className="d-flex gap-1" onSubmit={filterVideo}>
            <input
              className="form-control"
              type="search"
              placeholder="Aramak istediğiniz videoyu yazınız"
              value={search}
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
            />
            <button className="btn btn-warning w-25">Bul</button>
          </form>
        </div>
      </div>
      <CardBody>
        <table className="table table-modern">
          <thead style={{ fontSize: "16px" }}>
            <tr>
              <th>Başlık</th>
              <th>Açıklama/Not</th>
              <th style={{ textAlign: "center" }}>Sırası</th>
              <th className="text-center">Aktiflik</th>
              <th className="text-center">Sil</th>
              <th style={{ textAlign: "center" }}>Video Detayı</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "16px" }}>
            {items && items.length > 0 ? (
              items.map((presentation) => {
                const {
                  id,
                  title,
                  description,
                  order_number,
                  status,
                } = presentation;
                return (
                  <tr key={id}>
                    <td style={rowStyle} onClick={(e) => handleVideo(e)}>
                      {title}
                    </td>
                    <td> {description}</td>
                    <td style={{ textAlign: "center" }}>{order_number}</td>
                    <td className="text-center">
                      {status === TEACHER_COURSE_STATUS.ACTIVE.id
                        ? "Aktif"
                        : status === TEACHER_COURSE_STATUS.DRAFT.id
                        ? "Taslak"
                        : "Pasif"}
                    </td>
                    <td className="text-center">
                      <IconButton id={id} onClick={(e) => removeVideo(e)}>
                        <Delete className="fs-2" />
                      </IconButton>
                    </td>
                    <td className="text-center">
                      <IconButton id={id} onClick={(e) => handleVideo(e)}>
                        <Edit className="fs-2" />
                      </IconButton>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td>Video bulunamadı</td>
              </tr>
            )}
          </tbody>
        </table>
      </CardBody>
    </>
  );
};

export default VideoListTable;
