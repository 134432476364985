import React from 'react'
import style from "./paths.module.scss"
import Button from '../../../../components/bootstrap/Button'
import useDarkMode from '../../../../hooks/useDarkMode';

const PathContent = ({title, description, completion_status}) => {
  const { darkModeStatus } = useDarkMode();
let pathStatus;
 if (Number(completion_status) === 1){
pathStatus = "Henüz Başlamadın"
 } else if(Number(completion_status) === 2){
  pathStatus = "Devam Ediyorsun"
 }else{
  pathStatus = "Tamamladın"
 }

  return (
    <div className={`${style.pathContentContainer} d-flex flex-column justify-content-evenly align-items-center`}>
      <div className={`${style.pathTitle} text-center`}>{title}</div>
      <div className={`${style.pathDescription} text-center`} style={{color:darkModeStatus && "#E7EEF8"}}>{description}</div>
      <div className={`${style.pathButton}`}><Button style={{background:Number(completion_status)===1 ? "#EF7A1F": Number(completion_status)===2 ?"#a253ff" : "#a253ff"}}>{pathStatus}</Button></div>
    </div>
  )
}

export default PathContent