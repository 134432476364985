// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SimpleTable_customRow__Ru7KN{\n    border-radius: 10px;\n    padding: 10px !important;\n    border-bottom: 1px solid rgb(144, 140, 140);\n    margin-top: 5px;\n}\n.SimpleTable_selectedRow__hUkyE{\n    border-radius: 10px !important;\n    background-color: #e5dfd8;\n}\n.SimpleTable_borderedRow__5j\\+6W td{\n    border: 2px solid black;\n    border-radius: 10px !important;\n}", "",{"version":3,"sources":["webpack://./src/pages/t14/admin/talent cup/components/simple-table/SimpleTable.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,wBAAwB;IACxB,2CAA2C;IAC3C,eAAe;AACnB;AACA;IACI,8BAA8B;IAC9B,yBAAyB;AAC7B;AACA;IACI,uBAAuB;IACvB,8BAA8B;AAClC","sourcesContent":[".customRow{\n    border-radius: 10px;\n    padding: 10px !important;\n    border-bottom: 1px solid rgb(144, 140, 140);\n    margin-top: 5px;\n}\n.selectedRow{\n    border-radius: 10px !important;\n    background-color: #e5dfd8;\n}\n.borderedRow td{\n    border: 2px solid black;\n    border-radius: 10px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customRow": "SimpleTable_customRow__Ru7KN",
	"selectedRow": "SimpleTable_selectedRow__hUkyE",
	"borderedRow": "SimpleTable_borderedRow__5j+6W"
};
export default ___CSS_LOADER_EXPORT___;
