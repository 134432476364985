import React from "react";
import Chart from "../../../../../../components/extras/Chart";

const ChartInfo = ({value}) => {
  return (
     <div>
          <Chart
           type={"donut"}
           height={"300px"}
           width={"300px"}
           series={value}
           options={{
             colors: ["#6C5DD3", "#998FE3", "#352985", "#918CB3", "#D3D2D8"],
             labels: ["Dersler","CodeLab","CodeMino","CodeBoo","CodeÇizz"],
             legend: {
               show: false
              },
              dataLabels: {
                enabled: false
              },
              plotOptions: {
                pie: {
                offsetY: 25,
                donut: {
                  labels: {
                    show: true,
                    name: {
                        offsetY: -5,
                    },                        
                    total: {
                        show: true,
                        showAlways: true,
                        fontSize: '30px',
                        fontWeight: 700,
                        label: `Toplam`,
                        color: '#6C5DD3',  
                        // formatter: function (w) {
                        //     return "";
                        // },
                      },
                    value: {
                      offsetY: 15,
                        show: true,
                        showAlways: true,
                        fontSize: '30px',
                        fontWeight: 700,
                        label: value.reduce((a, b) => a + b, 0),
                        color: '#6C5DD3',  
                        formatter: function (w) {
                            return "";
                        },
                      },
                    }
                  
                  },
              },
            },
          }}
          />
        </div>
  );
};

export default ChartInfo;
