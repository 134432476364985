import React, { useRef } from "react";
import style from "./TeacherReport.module.scss";
import talent from "../../../../assets/dashboardImages/talent.png";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../layout/Page/Page";
import ReportDetail from "./components/reportDetail/ReportDetail";
import DashboardHeader from "../../../common/Headers/DashboardHeader";
import Icon from "../../../../components/icon/Icon";
import { useReactToPrint } from "react-to-print";

const TeacherReport = () => {
  // let publicId = useParams()
  // Bugünün tarihini al
  const today = new Date();

  // Tarih öğelerini al (gün, ay, yıl)
  const day = today.getDate();
  const month = today.getMonth() + 1; // Ay 0'dan başlar, bu nedenle 1 ekliyoruz.
  const year = today.getFullYear();
  const contentRef = useRef(null);
  const reactToPrintFn = useReactToPrint({ contentRef });
  return (
    <PageWrapper>
      <DashboardHeader />
      <Page ref={contentRef} className={`${style.mainReportContainer}`}>
        <div
          className={`${style.logoAndDateContainer} d-flex flex-md-row flex-column-reverse`}
        >
          <div className={`${style.brandLogo} mx-auto`}>
            <img src={talent} alt="" />
          </div>
          <div
            className={`${style.printIcon} align-self-end cursor-pointer`}
            onClick={reactToPrintFn}
          >
            <Icon icon="Print" size="2x" color="info" />
            <span>Rapor Al</span>
          </div>
          <div className={`${style.date} align-self-end`}>
            {day}.{month}.{year}
          </div>
        </div>
        <div>
          <ReportDetail />
        </div>
      </Page>
    </PageWrapper>
  );
};

export default TeacherReport;
