import React from "react";
import style from "./Homeworks.module.scss";
import { useHomeworksCompletionRateQuery } from "../../../teacher/teacherApi";
import { useParams } from "react-router-dom";
import { nanoid } from "nanoid";
import Information from "./Information";
import Card from "../../../../../components/bootstrap/Card";

const HomeWorkCompleted = () => {
  const { classId } = useParams();
  const { data:studentsHomeworkRatio } = useHomeworksCompletionRateQuery(classId);
  return (
    <Card className={`${style.cardContainer}`}>
      <div className={`${style.informationsContainer}`}>
        <div className={`${style.title} my-3 mb-3 px-3`}>
          Ödev Tamamlama Oranı En Yüksek 5 Öğrenci
        </div>
        {studentsHomeworkRatio && studentsHomeworkRatio.length < 1 && (
          <div className={`${style.emptyList}`}>
            <span>Öğrenci bulunmamaktadır</span>
          </div>
        )}
        {studentsHomeworkRatio &&
          studentsHomeworkRatio.length > 0 &&
          studentsHomeworkRatio.map((item, index) => {
            const userName = item.full_name.includes("None") ? item.email.split("@")[0] : item.full_name
            return (
              <div
                className={`${style.information} py-3 px-3 my-2`}
                key={nanoid()}
              >
                <Information
              description={userName}
              value={item.completion_rate}
              index={index}
            //   email={item.email}
            />
              </div>
            );
          })}
      </div>
    </Card>
  );
};

export default HomeWorkCompleted;
