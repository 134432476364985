import React, { useEffect, useState } from "react";
import style from "./ActivationCodeListForSchool.module.scss";
import PaginationButtons, {
  PER_COUNT,
  dataPagination,
} from "../../../../../../components/PaginationButtons";
import useSortableData from "../../../../../../hooks/useSortableData";
import USER_ROLES from "../../../../../../common/data/enumUserRole";
import Icon from "../../../../../../components/icon/Icon";
import { useDeleteActivationKeysOfSchoolMutation, useGetActivationKeysOfSchoolForAdminQuery, useSearchActivationKeysOfSchoolForAdminQuery } from "../../../../../../store/features/apiSlice";
import { useMediaQuery } from "react-responsive";
import USER_SUBSCRIPTION_TYPE from "../../../../../../common/data/enumUserSubscriptionType";
import USER_SUBSCRIPTION_PERIOD from "../../../../../../common/data/enumUserSubscriptionPeriod";
import { formatDate2TurkishShortDate } from "../../../../../../utils/formatDate";
import useSelectTable from "../../../../../../hooks/useSelectTable";
import Checks from "../../../../../../components/bootstrap/forms/Checks";
import ConfirmButton from "../../../../teacher/components/confirm-button";
import Confirm from "../../../../../common/Confirm";
import Button from "../../../../../../components/bootstrap/Button";
import CreateActivationCodeModalForSchool from "./CreateActivationCodeModalForSchool";
import ShowActivationMessageModalForSchool from "./ShowActivationMessageModalForSchool";
import showNotification from "../../../../../../components/extras/showNotification";
import {
  getActivationStatusColor,
  getActivationStatusText,
} from "./activationFunctions";
import ShowActivationKeysModalForSchool from "./ShowActivationKeysModalForSchool";
import _ from "underscore";
import { useGetActivationKeysOfSchoolForSchoolManagerQuery, useSearchActivationKeysOfSchoolForSchoolManagerQuery } from "../../../schoolManagerApi";
import { debounce } from 'lodash';

const ActivationCodeListForSchool = () => {
  const role = localStorage.getItem("roles");
  const isAdmin = Number(role) === USER_ROLES.ADMIN.id;
  const isSchoolManager = Number(role) === USER_ROLES.SCHOOL_MANAGER.id;
  const schoolId = localStorage.getItem("schoolId")
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_COUNT["10"]);
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  const handleSearchChange = debounce((value) => {
    setDebouncedSearchTerm(value);
  }, 500);

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
    handleSearchChange(e.target.value);
  };

  const { data:activationCodesForAdmin } = useGetActivationKeysOfSchoolForAdminQuery({schoolId,  page: `page=${currentPage}`,
    page_size: `page_size=${perPage}`,}, {skip:!isAdmin, refetchOnMountOrArgChange: false});
  const { data:searchActivationCodesForAdmin } = useSearchActivationKeysOfSchoolForAdminQuery({schoolId,  page: `page=${currentPage}`,
    page_size: `page_size=${perPage}`, search:`search=${debouncedSearchTerm}`}, {skip:(!isAdmin || !debouncedSearchTerm), refetchOnMountOrArgChange: false});
  const { data:activationCodesForSchoolManager } = useGetActivationKeysOfSchoolForSchoolManagerQuery({ page: `page=${currentPage}`,
    page_size: `page_size=${perPage}`,}, {skip:!isSchoolManager, refetchOnMountOrArgChange: false});
  const { data:searchActivationCodesForSchoolManager } = useSearchActivationKeysOfSchoolForSchoolManagerQuery({ page: `page=${currentPage}`,
    page_size: `page_size=${perPage}`, search:`search=${debouncedSearchTerm}`}, {skip:(!isSchoolManager || !debouncedSearchTerm), refetchOnMountOrArgChange: false});
  const allUsers = isAdmin ? activationCodesForAdmin : activationCodesForSchoolManager;
  const allSearchCodes = isAdmin ? searchActivationCodesForAdmin : searchActivationCodesForSchoolManager;
  const users = allUsers?.results;
  const searchCodes = allSearchCodes?.results;
  const totalUsers = allUsers?.count;
  const totalSearchCodes = allSearchCodes?.count;
  const isMobile = useMediaQuery({ minWidth: 768 });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenMessageModal, setIsOpenMessageModal] = useState(false);
  const [isOpenActivationKeysModal, setIsOpenActivationKeysModal] =
    useState(false);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);

  const allItems = searchCodes ? searchCodes : users ?? []
  const { items, requestSort, getClassNamesFor } = useSortableData(
    allItems
  );
  const onCurrentPageData = dataPagination(items, currentPage, perPage);
  const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageData);

  const [
    deleteActivationCode,
    { isSuccess: deleteSuccess, isError: deleteError },
  ] = useDeleteActivationKeysOfSchoolMutation();

  const handleDelete = (item) => {
    if (item.hasOwnProperty("currentItem")) {
      deleteActivationCode({
        schoolId,
        activation_key_ids: [item.currentItem],
      });
    } else if (selected.length > 0) {
      deleteActivationCode({ schoolId, activation_key_ids: selected });
    }
    setOpen(false);
    setSelected([]);
  };
  const handlCancel = () => setOpen(false);

  useEffect(() => {
    if (deleteSuccess) {
      (function showSuccess(message) {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Silme Başarılı</span>
          </span>,
          message,
          "success"
        );
      })();
    }
    if (deleteError) {
      (function showError() {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="danger" size="lg" className="me-1" />
            <span>Silme işlemi Başarısız</span>
          </span>,
          "Bir hata oluştu",
          "danger"
        );
      })();
    }
  }, [deleteSuccess, deleteError]);

  useEffect(() => {
    if (selectTable.values.selectedList) {
      setSelected(selectTable.values.selectedList);
    }
  }, [selectTable.values.selectedList]);

  const openModal = (e) => {
    setIsOpenModal(true);
  };
  const openMessageModal = (e) => {
    setIsOpenMessageModal(true);
  };
  const openActivationKeysModal = (e) => {
    setIsOpenActivationKeysModal(true);
  };
  return (
    <div>
      <CreateActivationCodeModalForSchool
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
      />
      <ShowActivationMessageModalForSchool
        isOpenMessageModal={isOpenMessageModal}
        setIsOpenMessageModal={setIsOpenMessageModal}
      />
      <ShowActivationKeysModalForSchool
        isOpenActivationKeysModal={isOpenActivationKeysModal}
        setIsOpenActivationKeysModal={setIsOpenActivationKeysModal}
        users={users}
      />
      <Confirm
        open={open}
        setOpen={setOpen}
        onConfirm={handleDelete}
        onCancel={handlCancel}
      />
      <div
        className={`${style.allDeleteButtonContainer} ${
          isSchoolManager ? style.styleForSchoolManager : ""
        } pe-5`}
      >
        {selected.length > 0 ? (
          <button
            className={`${style.allDeleteButton} btn btn-danger`}
            onClick={() => {
              setSelected(selected);
              setOpen(true);
            }}
          >
            Seçilenleri Sil
          </button>
        ) : (
          <button
            style={{ visibility: "hidden" }}
            onClick={() => {
              setSelected(selected);
              setOpen(true);
            }}
          >
            Seçilenleri Sil
          </button>
        )}
      </div>

      <div
        className={`${style.activationKeyActionContainer} ${
          isSchoolManager ? style.styleForSchoolManager : ""
        } d-flex px-2 mx-4 gap-3 mx-auto`}
      >
        <Button
          className={`${style.codeButton} btn`}
          color="info"
          onClick={() => {
            openModal();
          }}
        >
          Kod Oluştur
        </Button>
        <Button
          className={`${style.messageButton} btn`}
          color="info"
          onClick={() => {
            openMessageModal();
          }}
        >
          Aktivasyon Mesajı
        </Button>
        <Button
          className={`${style.messageButton} btn`}
          color="success"
          onClick={() => {
            openActivationKeysModal();
          }}
        >
          Aktif Kodları Al
        </Button>
      </div>

      <div className={`${style.actionsContainer} px-2 mx-4 gap-3 mx-auto`}>
        <div className={`${style.inputContainer} `}>
          <div
            className={`${style.searchContainer} d-flex align-items-center justify-content-center`}
          >
            <form className="d-flex gap-1 w-100">
              <label className="" htmlFor="searchInput">
                <Icon
                  icon="Search"
                  size="2x"
                  style={{ color: "#000", opacity: "0.3" }}
                />
              </label>
              <input
                className="form-control border-0 shadow-none bg-transparent"
                type="search"
                placeholder={
                  isMobile ? "Aramak istediğiniz kodu yazınız" : ""
                }
                // placeholder="Aramak istediğiniz kullanıcıyı yazınız"
                value={searchValue}
                onChange={handleInputChange}
              />
            </form>
          </div>
        </div>
      </div>

      <div className={`${style.customTable} mt-5 px-2`}>
        <div
          className={`${style.customHeadContainer} d-flex align-items-center my-3 px-2`}
        >
          <div
            className={`${style.checkContainer} ${style.customTableHead} ${
              isSchoolManager ? style.styleForSchoolManager : ""
            }`}
          >
            {SelectAllCheck}
          </div>
          <div className={`${style.customTableHead1} ${style.customTableHead}`}>
            Kullanıcı adı
          </div>
          <div className={`${style.customTableHead2} ${style.customTableHead}`}>
            E-posta
          </div>
          <div
            className={`${style.customTableHead3} ${style.customTableHead} text-center`}
          >
            Üyelik Tipi
          </div>
          <div
            className={`${style.customTableHead4} ${style.customTableHead} text-center`}
          >
            Üyelik Süresi
          </div>
          <div
            className={`${style.customTableHead5} ${style.customTableHead} text-center`}
          >
            Aktivasyon Kodu
          </div>
          <div
            className={`${style.customTableHead6} ${style.customTableHead} text-center`}
            onClick={() => requestSort("status")}
          >
            Kullanım Durumu
            <Icon
              size="lg"
              className={getClassNamesFor("status")}
              icon="FilterList"
            />
          </div>
          <div
            className={`${style.customTableHead7} ${style.customTableHead} text-center`}
            onClick={() => requestSort("activated_date")}
          >
            Aktivasyon Tarihi
            <Icon
              size="lg"
              className={getClassNamesFor("activated_date")}
              icon="FilterList"
            />
          </div>
          <div
            className={`${style.customTableHead8} ${style.customTableHead} text-center`}
            onClick={() => requestSort("code_expiry_date")}
          >
            Bitiş Tarihi
            <Icon
              size="lg"
              className={getClassNamesFor("code_expiry_date")}
              icon="FilterList"
            />
          </div>
          <div
            className={`${style.customTableHead9} ${style.customTableHead} text-center`}
          >
            Eylemler
          </div>
        </div>
        <div className={`${style.customRowContainer}`}>
          {allItems && allItems.length === 0 ? (
            <div>
              <span>Kayıtlı kullanıcı bulunamadı</span>
            </div>
          ) : (
                allItems?.map((item, i) => (
              <div
                key={item.id}
                className={`${style.customTableRow} ${
                  i % 2 === 0 && style.customBackgroundColor
                } d-flex align-items-center mt-1 px-2`}
              >
                <div
                  className={`${style.checkContainer} ${
                    isSchoolManager ? style.styleForSchoolManager : ""
                  }`}
                >
                  <Checks
                    id={item.id.toString()}
                    name="selectedList"
                    value={item.id}
                    onChange={selectTable.handleChange}
                    checked={selectTable.values.selectedList.includes(
                      item.id.toString()
                    )}
                  />
                </div>
                <div className={`${style.customTableColumn1} cursor-pointer`}>
                  <div className={`${style.titleContainer}`}>
                    <div className={`${style.titleShort}`}>
                      {item.user?.full_name
                        ? item.user?.full_name
                        : item.user?.email.split("@")[0]}
                    </div>
                    <div className={`${style.titleFull}`}>
                      {item.user?.full_name
                        ? item.user?.full_name
                        : item.user?.email.split("@")[0]}
                    </div>
                  </div>
                </div>
                <div className={`${style.customTableColumn2}`}>
                  <div className={`${style.emailContainer}`}>
                    <div className={`${style.emailShort}`}>{`${
                      item.user ? item.user?.email : ""
                    }`}</div>
                    <div className={`${style.emailFull}`}>{`${
                      item.user ? item.user?.email : ""
                    }`}</div>
                  </div>
                </div>
                <div className={`${style.customTableColumn3} text-center`}>
                  <div>
                    {Number(item.subscription_type) ===
                    USER_SUBSCRIPTION_TYPE.FREE.id
                      ? "Ücretsiz"
                      : Number(item.subscription_type) ===
                        USER_SUBSCRIPTION_TYPE.STANDART.id
                      ? "Standart"
                      : "Premium"}
                  </div>
                </div>
                <div className={`${style.customTableColumn4} text-center`}>
                  <div>
                    {Number(item.subscription_period) ===
                    USER_SUBSCRIPTION_PERIOD.MONTHLY.id
                      ? "Aylık"
                      : Number(item.subscription_period) ===
                        USER_SUBSCRIPTION_PERIOD.ANNUALLY.id
                      ? "Yıllık"
                      : ""}
                  </div>
                </div>
                <div className={`${style.customTableColumn5} text-center`}>
                  <div>{item.code}</div>
                </div>
                <div className={`${style.customTableColumn6} text-center`}>
                  <div
                    className={`text-${getActivationStatusColor(item.status)}`}
                  >
                    {getActivationStatusText(item.status)}
                  </div>
                </div>
                <div className={`${style.customTableColumn7} text-center`}>
                  <div>
                    {item.activated_date
                      ? formatDate2TurkishShortDate(item.activated_date)
                      : null}
                  </div>
                </div>
                <div className={`${style.customTableColumn8} text-center`}>
                  <div>
                    {item.code_expiry_date
                      ? formatDate2TurkishShortDate(item.code_expiry_date)
                      : null}
                  </div>
                </div>

                {/* Eylemler */}
                <div
                  className={`${style.customTableColumn9} d-flex justify-content-center mx-auto`}
                >
                  {isSchoolManager ? (
                    "-"
                  ) : (
                    <ConfirmButton
                      icon="Delete"
                      handleClick={() => handleDelete({ currentItem: item.id })}
                      parameter={item.id}
                      // onClick={()=>setItemId(item.id)}
                    />
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <PaginationButtons
        data={totalSearchCodes ? totalSearchCodes : totalUsers}
        label="kullanıcı"
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        perPage={perPage}
        setPerPage={setPerPage}
        total={true}
      />
    </div>
  );
};

export default ActivationCodeListForSchool;
