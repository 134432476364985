import React from 'react';
import CodeRunSuccess from './CodeRunSuccess'
import CodeRunFail from './CodeRunFail'

function CodeRunResult({path_id, codeRunResult}) {
    const isRunSuccess = codeRunResult.practice_run_result.successful;
    const tests = codeRunResult.practice_run_result.test_results;
    let result= isRunSuccess ? <CodeRunSuccess path_id={path_id} codeRunResult={codeRunResult} tests={tests}/>
                             :  <CodeRunFail tests={tests}/>

    return (
        <div>
            {result}
        </div>
    );
}

export default CodeRunResult;
