import React from "react";
import approved from "../../../../assets/dashboardImages/approved.png";

const UnAuthorizedTeacherDashboard = () => {
  return (
    <>
        <div className="d-flex flex-column flex-md-row align-items-center">
        <div>
          <p className="text-primary fw-bold" style={{ fontSize: "calc(1rem + 2vw)" }}>Belge Onayı Bekleniyor</p>
          <p className="h-4" style={{ color: "#68686d", fontSize: "20px" }}>
          Öğretmen olarak platformu kullanabilmeniz için onay süreciniz devam
      ediyor. Başvurunuzun onaylanmasının ardından öğretmen profiliniz aktif
      edilecek ve bütün içeriklere erişebileceksiniz.
          </p>
        </div>
        <div style={{height:"500px"}}>
        <img src={approved} alt="" style={{ width: "500px", height:"100%" }} />{" "}
        </div>
      </div>
    </>
  );
};

export default UnAuthorizedTeacherDashboard;
