// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CertificateThumbnail_certificateContainer__jAwwH {\n  position: relative;\n  display: inline-block;\n  width: 136px;\n  height: 200px;\n}\n@media screen and (max-width: 500px) {\n  .CertificateThumbnail_certificateContainer__jAwwH {\n    width: 124.077px;\n    height: 182.466px;\n  }\n}\n\n.CertificateThumbnail_nameContainer__KDEKZ {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  -webkit-transform: translate(-50%, -50%);\n      -ms-transform: translate(-50%, -50%);\n          transform: translate(-50%, -50%);\n  font-family: \"Arial\", sans-serif;\n  font-size: 13px;\n  font-weight: 900;\n  text-align: center;\n  color: #3f59a9;\n  width: 108px;\n  max-height: 40px;\n}", "",{"version":3,"sources":["webpack://./src/pages/t14/reports/parentReports/parentReportWithClass/certificates/DynamicThumbnail/CertificateThumbnail.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;EACA,YAAA;EACA,aAAA;AACF;AAAE;EALF;IAOI,gBAAA;IACA,iBAAA;EAEF;AACF;;AACA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,wCAAA;MAAA,oCAAA;UAAA,gCAAA;EACA,gCAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;EACA,gBAAA;AAEF","sourcesContent":[".certificateContainer {\n  position: relative;\n  display: inline-block;\n  width: 136px;\n  height: 200px;\n  @media screen and (max-width: 500px) {\n    // border: solid;\n    width: 124.077px;\n    height: 182.466px;\n  }\n}\n\n.nameContainer {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  font-family: \"Arial\", sans-serif;\n  font-size: 13px;\n  font-weight: 900;\n  text-align: center;\n  color: #3f59a9;\n  width: 108px;\n  max-height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"certificateContainer": "CertificateThumbnail_certificateContainer__jAwwH",
	"nameContainer": "CertificateThumbnail_nameContainer__KDEKZ"
};
export default ___CSS_LOADER_EXPORT___;
