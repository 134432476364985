import React, { useEffect, useState } from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../components/bootstrap/Modal";
import Logo from "../../../../components/Logo";
import Icon from "../../../../components/icon/Icon";
import Button from "../../../../components/bootstrap/Button";
import Input from "../../../../components/bootstrap/forms/Input";
import { useFormik } from "formik";
import {
  useClearCacheMutation,
  useUpdateCourseMutation,
} from "../../../../store/features/apiSlice";
import showNotification from "../../../../components/extras/showNotification";
import _ from "underscore";
import Checks from "../../../../components/bootstrap/forms/Checks";
import { ContentService } from "./h5p/services/ContentService";
import Card, {
  CardBody,
  CardHeader,
} from "../../../../components/bootstrap/Card";
import CustomFormField from "../../../../common/customFormField/CustomFormField";
import { getCustomErrorMessage, handleErrorMessage } from "../../../../utils/customErrors";

const CreateLessonModal = ({
  isOpenCreateLessonModal,
  setIsOpenCreateLessonModal,
  data,
  chapterId,
  currentChapter,
}) => {
  const [updateCourse] = useUpdateCourseMutation();
  const [clearCache] = useClearCacheMutation();
  const [contents, setContents] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredContents, setFilteredContents] = useState([]);
  const isPartner = localStorage.getItem("schoolStatus")==="partner";

  const contentService = new ContentService("/h5p");

  useEffect(() => {
    async function getContent() {
      const contentList = await contentService.list();
      setContents(
        contentList.map((m) => {
          return {
            content_id: m.contentId,
            mainLibrary: m.mainLibrary,
            title: m.title,
          };
        })
      );
    }
    getContent();
    //eslint-disable-next-line
  }, []);

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Konunun adını giriniz";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      id: null,
      title: "",
      order_number: 9999,
      description: null,
      video: "",
      subscription_type:isPartner ? 3 : 1,
      points: 5,
      content_id: "",
    },
    validate,
    onSubmit: () => {
      handleLesson();
    },
  });

  const handleLesson = async () => {
    const { ...lessonValues } = formik.values;

    let updatedLessons = currentChapter && [
      ...currentChapter.lessons,
      lessonValues,
    ];
    updatedLessons = _.sortBy(updatedLessons, "order_number");
    let updatedChapter = currentChapter && {
      ...currentChapter,
      lessons: updatedLessons,
    };
    let newChapters =
      data &&
      data.chapters.map((chapter) =>
        chapter.title === currentChapter.title
          ? { ...chapter, ...updatedChapter }
          : chapter
      );
    newChapters = _.sortBy(newChapters, "order_number");
    const response = await updateCourse({
      id: data.id,
      title: data.title,
      ...data,
      chapters: newChapters,
    });
    if (response.error) {
      const customError = handleErrorMessage(response.error.data)
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>{getCustomErrorMessage(customError, "Kayıt Başarısız")}</span>
        </span>,
        "Bir hata oluştu",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Kayıt Başarılı</span>
        </span>,
        "Konu başarıyla eklendi.",
        "success"
      );
    }
    clearCache();
    setIsOpenCreateLessonModal(false);
    formik.resetForm();
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      saveForm();
    }
  };
  const saveForm = async () => {
    try {
      await formik.handleSubmit();
      setIsOpenCreateLessonModal(false);
    } catch (error) {}
  };
  const handleCheckboxClick = (event) => {
    const { name, value, checked } = event.target;

    if (name === "content_id" && checked) {
      formik.setFieldValue(name, value);
    } else if (name === "content_id" && !checked) {
      formik.setFieldValue(name, null);
    }
  };
  useEffect(() => {
    if (searchValue) {
      setFilteredContents(
        contents.filter((content) =>
          content.title.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    }
  }, [contents, searchValue]);

  const _contents = searchValue ? filteredContents : contents;

  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenCreateLessonModal}
      setIsOpen={setIsOpenCreateLessonModal}
      titleId="example-title"
      size={null}
      onKeyDown={handleKeyDown}
    >
      <ModalHeader setIsOpen={setIsOpenCreateLessonModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} /> <span className="ps-2">Yeni Konu</span>
          <span className="ps-2">
            <Icon icon="Computer" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="row gap-3 d-flex justify-content-center">
          <div className="col-lg-8">
            <CustomFormField
              type="text"
              label="Başlık"
              name={"title"}
              formik={formik}
            />
          </div>
          <div className="col-lg-8">
            <CustomFormField
              type="text"
              label="Sıra Numarası"
              name={"order_number"}
              formik={formik}
            />
          </div>
          <div className="col-lg-8">
            <CustomFormField
              type="textArea"
              label="Açıklama"
              name={"description"}
              formik={formik}
              style={{ height: "150px" }}
            />
          </div>
          <div
            style={{ overflow: "scroll", height: "250px" }}
            className="col-lg-8"
          >
            <Card className="">
              <CardHeader>
                <Input
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  type="text"
                  placeholder="İçeriklerde Ara.."
                />
              </CardHeader>
              <CardBody>
                {
                  <table className="table table-modern">
                    <tbody>
                      {_contents?.map((item) => (
                        <tr key={item.content_id}>
                          <td>
                            <Checks
                              id={item.content_id.toString()}
                              name="content_id"
                              value={item.content_id}
                              onChange={handleCheckboxClick}
                              checked={
                                formik.values.content_id === item.content_id
                              }
                            />
                          </td>
                          <td style={{ textAlign: "justify" }}>{item.title}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                }
              </CardBody>
            </Card>
          </div>
          <div className="col-lg-8">
            <CustomFormField
              type="text"
              label="Embed Code"
              name={"video"}
              formik={formik}
            />
          </div>
         {!isPartner && <div className="col-lg-8">
            <CustomFormField
              type="select"
              label="Video erişim durumu"
              name={"subscription_type"}
              options={[
                { value: "1", text: "Ücretsiz" },
                { value: "2", text: "Standart" },
                { value: "3", text: "Premium" },
              ]}
              formik={formik}
            />
          </div>}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={async () => {
            formik.handleSubmit();
          }}
        >
          Kaydet
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateLessonModal;
