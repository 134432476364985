import React, { useContext } from "react";
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
} from "../../../components/bootstrap/Modal";
import MDEditor from "@uiw/react-md-editor";
import Button from "../../../components/bootstrap/Button";
import { AuthContext } from "../../../store/auth-store";
import { post } from "../../../utils/service";
import { useNavigate } from "react-router-dom";

const ActivationInfoModal = ({ open, setOpen, message }) => {
  const navigate = useNavigate();
  const { logout, token } = useContext(AuthContext);
  const logoutUser = async () => {
    try {
      if (token) {
        await post("auth/logout/", token);
        logout();
      }
      return;
    } catch ({ response }) {
      // console.log(response);
    } finally {
      navigate(`/`);
    }
  };
  return (
    <div>
      <Modal
        id="example-modal"
        isCentered
        isOpen={open}
        setIsOpen={setOpen}
        titleId="example-title"
        size={null}
      >
        <ModalHeader>
          <ModalTitle id="example-title">
            Aktivasyon Başarılı
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
        <MDEditor.Markdown className="practice-detail-body"  source={message} />
          <div className="mx-auto text-justify">
            <p style={{ fontSize: "14px" }}>Yeniden giriş yaparak hesabınızı kullanmaya devam edebilirsiniz.</p>
          </div>
          <div className="d-flex justify-content-end">
          <Button
            className={`btn`}
            color="info"
            icon="person"
            onClick={() => {
              logoutUser()
            }}
          >
            Yeniden Giriş Yap
          </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ActivationInfoModal;
