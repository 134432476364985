import React from "react";
import CompetitionLeftMenu from "../CompetitionLeftMenu";
import { useSelector } from "react-redux";
import { menuList } from "../talentCupMenuList";
import GameContent from "./GameContent";
import CompetitionPracticeListContent from "./CompetitionPracticeListContent";
import LessonList from "./LessonList";

const TaskAdd = ({
  currentGames,
  setCurrentGames,
  currentPractices,
  setCurrentPractices,
  currentLessons,
  setCurrentLessons
}) => {
  const { menu } = useSelector((store) => store.talentCup);
  return (
    <>
      <div className="col-12 col-lg-4 col-xl-3">
        <CompetitionLeftMenu />
      </div>

      <div className="col-12 col-lg-8 col-xl-9">
        {
        menu === menuList.LESSONS ? (
          <LessonList
          setCurrentLessons={setCurrentLessons}
          currentLessons={currentLessons}
          />
        ) 
        : menu === menuList.CODELAB ? (
          <CompetitionPracticeListContent
          setCompetitionPractices={setCurrentPractices}
          currentPractices={currentPractices}
          />
        ) 
        : menu === menuList.CODEMINO ? (
          <GameContent
            setGameSteps={setCurrentGames}
            currentGameSteps={currentGames}
          />
        )
        : menu === menuList.CODEBOO ? (
          <GameContent
            setGameSteps={setCurrentGames}
            currentGameSteps={currentGames}
          />
        )
        : menu === menuList.CODECIZZ ? (
          <GameContent
            setGameSteps={setCurrentGames}
            currentGameSteps={currentGames}
          />
        )
        : (
          ""
        )}
      </div>
    </>
  );
};

export default TaskAdd;
