import React from 'react'
import style from "../ParentReportWithoutClass.module.scss"
import StudentInfo from './StudentInfo'
import studentLogoPrimary from "../../../../../../assets/dashboardImages/studentLogoPrimary.svg";
import studentLogoWhite from "../../../../../../assets/dashboardImages/studentLogoWhite.svg";
import starPrimary from "../../../../../../assets/dashboardImages/starPrimary.svg";
import starWhite from "../../../../../../assets/dashboardImages/starWhite.svg";

const ParentHeader = ({data}) => {
    const rankInGeneral = data.rank_in_general
    const GeneralPoints = data.general_points
    const fullName = data.full_name;
    const defaultName = data.email.split("@")[0]
  return (
    <div className={`${style.headerContainer} mx-auto`}>
                <div className={`${style.headerLeft}`}>
                    <p>{fullName !== " " ? fullName : defaultName}</p>
                </div>
                <div className={`${style.headerRight}`}>
                <StudentInfo logo={studentLogoPrimary} logo2={studentLogoWhite} generalDescription={"Genel Sıralama"} generalDetail={rankInGeneral}/>
                <StudentInfo logo={starPrimary} logo2={starWhite} generalDescription={"Genel Puanı"} generalDetail={GeneralPoints}/>
                </div>
    </div>
  )
}

export default ParentHeader