
import { useClassLeaderBoardQuery } from '../teacherApi';
import LeaderBoard from '../../leader-board/LeaderBoard';


const ClassLeaderBoard = ({ classId }) => {
  
  const { data, isLoading, isError, isSuccess } = useClassLeaderBoardQuery(classId);
  if (isLoading) return <div>Loading...</div>;

  if (isError) return <div>Error</div>;

  if (isSuccess && data.length >0) {
  
    return (

      <LeaderBoard data={data} />
    );
  }
};

export default ClassLeaderBoard;