import { apiSlice } from "../../../store/features/apiSlice";

export const schoolManagerApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSchoolClasses: builder.query({
      query: () => ({
        url: `schools/classes/`,
        method: "get",
      }),
      providesTags: ["classes"],
    }),
    get_a_SchoolClass: builder.query({
      query: (id) => ({
        url: `schools/classes/${id}`,
        method: "get",
      }),
    }),
    createSchoolClass: builder.mutation({
      query: (data) => ({
        url: `schools/classes/`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["classes"],
    }),
    updateSchoolClass: builder.mutation({
      query: (data) => ({
        url: `schools/classes/${data.id}/`,
        method: "put",
        body: data,
      }),
      invalidatesTags: ["classes"],
    }),
    deleteSchoolClass: builder.mutation({
      query: (id) => ({
        url: `schools/classes/${id}/`,
        method: "delete",
      }),
      invalidatesTags: ["classes"],
    }),
    schoolUsersSearch: builder.query({
      query: (search, page, page_size) => ({
        url: `schools/users/?${search}&${page}&${page_size}`,
        method: "get",
      }),
      providesTags: ["user", "students-in-class"],
    }),
    schoolStudentsDetail: builder.query({
      query: (id) => ({
        url: `schools/${id}/students/`,
        method: "get",
      }),
      providesTags: ["user", "students-in-class"],
    }),
    schoolUsers: builder.query({
      query: (classroom, role, page, page_size) => ({
        url: `schools/users/?${role}&${classroom}&${page}&${page_size}`,
        method: "get",
      }),
      providesTags: ["user", "students-in-class"],
    }),
    get_a_SchoolUser: builder.query({
      query: (id) => ({
        url: `schools/users/${id}/`,
        method: "get",
      }),
    }),
    updateSchoolUser: builder.mutation({
      query: ({
        id,
        first_name,
        last_name,
        email,
        role,
        telNumber,
        school,
      }) => ({
        url: `schools/users/${id}/`,
        method: "put",
        body: {
          id,
          first_name,
          last_name,
          email,
          role,
          telNumber,
          school,
        },
      }),
      invalidatesTags: ["user"],
    }),
    classActivities: builder.query({
      query: (id) => ({
        url: `schools/classes/${id}/activities/`,
        method: "get",
      }),
      providesTags: ["homeWork"],
    }),

    updatePasswordWithSchoolManager: builder.mutation({
      query: ({ id, password, password2 }) => ({
        url: `schools/users/${id}/password/`,
        method: "put",
        body: { id, password, password2 },
      }),
    }),

    searchActivationKeysOfSchoolForSchoolManager: builder.query({
      query: ({page, page_size, search}) => ({
        url: `schools/activation_key/?${search}&${page}&${page_size}`,
        method: "get",
      }),
      providesTags: ["activationKey"],
    }),

    getActivationKeysOfSchoolForSchoolManager: builder.query({
      query: ({page, page_size, status, created_date}) => ({
        url: `schools/activation_key/?${page}&${page_size}&${status}&${created_date}`,
        method: "get",
      }),
      providesTags: ["activationKey"],
    }),

    getSchoolCourses: builder.query({
      query: (school_status) => ({
        url: `schools/courses/?${school_status}`,
        method: "get",
      }),
      providesTags: ["course"],
    }),
    getSingleSchoolCourse: builder.query({
      query: (id) => ({
        url: `schools/courses/${id}`,
        method: "get",
      }),
      providesTags: ["course"],
    }),
    createSchoolCourse: builder.mutation({
      query: ({
        title,
        technology,
        description,
        order_number,
        status,
        level,
        school,
        school_status,
        chapters,
      }) => ({
        url: `schools/courses/`,
        method: "post",
        body: {
          title,
          technology,
          description,
          order_number,
          status,
          level,
          school,
          school_status,
          chapters,
        },
      }),
      invalidatesTags: ["course"],
    }),
    updateSchoolCourse: builder.mutation({
      query: ({
        id,
        title,
        technology,
        description,
        order_number,
        status,
        level,
        chapters,
        points,
        labels,
        school,
        school_status,
      }) => ({
        url: `schools/courses/${id}/`,
        method: "put",
        body: {
          id,
          title,
          technology,
          description,
          order_number,
          status,
          level,
          chapters,
          points,
          labels,
          school,
          school_status,
        },
      }),
      invalidatesTags: ["course"],
    }),
    deleteSchoolCourse: builder.mutation({
      query: (id) => ({
        url: `schools/courses/${id}/`,
        method: "delete",
      }),
      invalidatesTags: ["course"],
    }),

    // Banner //
    getSchoolBanner: builder.query({
      query: () => ({
        url: `schools/banner/`,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token') && JSON.parse(localStorage.getItem('token'))}`,
        },
        responseHandler: async (response) => {
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        },
        method: "get",
      }),
      providesTags: ["banner"],
    }),
    createSchoolBanner: builder.mutation({
      query: (data) => ({
        url: `schools/banner/`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["banner"],
    }),
    updateSchoolBanner: builder.mutation({
      query: (data) => ({
        url: `schools/banner/`,
        method: "put",
        body: data,
      }),
      invalidatesTags: ["banner"],
    }),
    deleteSchoolBanner: builder.mutation({
      query: (data) => ({
        url: `schools/banner/`,
        method: "delete",
        body: data,
      }),
      invalidatesTags: ["banner"],
    }),

    // Announcement  //
    getAllSchoolAnnouncement: builder.query({
      query: () => ({
        url: `schools/annocucements/`,
        method: "get",
      }),
      providesTags: ["announcement"],
    }),
    getSchoolAnnouncement: builder.query({
      query: (id) => ({
        url: `schools/annocucements/${id}`,
        method: "get",
      }),
      providesTags: ["announcement"],
    }),
    createSchoolAnnouncement: builder.mutation({
      query: (formData) => ({
        url: `schools/annocucements/`,
        method: "post",
        prepareHeaders: (headers) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
        body: formData,
      }),
      invalidatesTags: ["announcement"],
    }),
    updateSchoolAnnouncement: builder.mutation({
      query: ({formData, id}) => ({
        url: `schools/annocucements/${id}/`,
        method: "put",
        prepareHeaders: (headers) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
        body: formData,
      }),
      invalidatesTags: ["announcement"],
    }),
    deleteSchoolAnnouncement: builder.mutation({
      query: (id) => ({
        url: `schools/annocucements/${id}`,
        method: "delete",
      }),
      invalidatesTags: ["announcement"],
    }),
    //--endpoints
  }),
});

export const {
  useGetSchoolClassesQuery,
  useGet_a_SchoolClassQuery,
  useCreateSchoolClassMutation,
  useUpdateSchoolClassMutation,
  useDeleteSchoolClassMutation,

  useSchoolUsersSearchQuery,
  useSchoolStudentsDetailQuery,
  useSchoolUsersQuery,
  useGet_a_SchoolUserQuery,
  useUpdateSchoolUserMutation,
  useClassActivitiesQuery,

  useUpdatePasswordWithSchoolManagerMutation,

  useSearchActivationKeysOfSchoolForSchoolManagerQuery,
  useGetActivationKeysOfSchoolForSchoolManagerQuery,

  useGetSchoolCoursesQuery,
  useGetSingleSchoolCourseQuery,
  useCreateSchoolCourseMutation,
  useUpdateSchoolCourseMutation,
  useDeleteSchoolCourseMutation,

  useGetSchoolBannerQuery,
  useCreateSchoolBannerMutation,
  useUpdateSchoolBannerMutation,
  useDeleteSchoolBannerMutation,

  useGetAllSchoolAnnouncementQuery,
  useGetSchoolAnnouncementQuery,
  useCreateSchoolAnnouncementMutation,
  useUpdateSchoolAnnouncementMutation,
  useDeleteSchoolAnnouncementMutation,
} = schoolManagerApi;
