import React from "react";
import style from "./CompletedStages.module.scss";

const StageInfo = ({ stage }) => {
  const title = stage.title;
  const color = stage.color;
  return (
    <div
      className={`${style.singleStage} d-flex align-items-center my-4`}
    >
      <div style={{backgroundColor:`${color}`}}></div>
      <div className={`d-flex justify-content-between align-items-center my-2 px-3`}>{title}</div>
    </div>
  );
};

export default StageInfo;
