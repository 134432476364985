import React, { useEffect, useState } from "react";
import Button from "../../../../../components/bootstrap/Button";
import FormGroup from "../../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../../components/bootstrap/forms/Input";
import { useFormik } from "formik";
import showNotification from "../../../../../components/extras/showNotification";
import Label from "../../../../../components/bootstrap/forms/Label";
import CustomFormField from "../../../../../common/customFormField/CustomFormField";
import style from "./Competition.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import {
  combineDateAndTime,
  splitDateAndTime,
} from "../../../../../utils/formatDate";
// import TaskPreviewForCompetition from "../task add/TaskPreviewForCompetition";
import {
  useCreateCompetitionMutation,
  useGetListCompetitionPracticesQuery,
  useUpdateCompetitionMutation,
  useUpdateCompetitionPracticesMutation,
} from "../talentCupApi";
import { showError, showSuccess } from "../../../profilPage/utils";
import Icon from "../../../../../components/icon/Icon";
import Spinner from "../../../../../components/bootstrap/Spinner";
import TaskAdd from "../task add/TaskAdd";
import TaskPreviewForCompetition from "./TaskPreviewForCompetition";

const ShowCompetition = ({ selectedCompetition }) => {
  const { id } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [competitionLogo, setCompetitionLogo] = useState("");
  const [isSaveDisabled, setIsSaveDisabled] = useState(false); //yarışma başladıktan sonra güncelleme yapılamaz

  const { data: competitionContent, isLoading } =
    useGetListCompetitionPracticesQuery(id, {
      skip: !id || !(Number(selectedCompetition?.kind) === 1),
    });

  const competitionCurrentPractices = competitionContent && competitionContent;
  // console.log("competitionContent",competitionContent);
  const curPractices = competitionCurrentPractices?.practices;
  const curLessons = competitionCurrentPractices?.lessons;
  const curGames = competitionCurrentPractices?.games;

  // eslint-disable-next-line
  const [currentPractices, setCurrentPractices] = useState(curPractices || []);
  // eslint-disable-next-line
  const [currentGames, setCurrentGames] = useState(curGames || []);
  // eslint-disable-next-line
  const [currentLessons, setCurrentLessons] = useState(curLessons || []);

  const [selectedPracticeIds, setSelectedPracticeIds] = useState([]);
  const [selectedGameIds, setSelectedGameIds] = useState([]);
  const [selectedLessonIds, setSelectedLessonIds] = useState([]);

  const [openAddTaskForm, setOpenAddTaskForm] = useState(false);

  const [createCompetition] = useCreateCompetitionMutation();
  const [updateCompetition] = useUpdateCompetitionMutation();
  const [updateCompetitionPractices] = useUpdateCompetitionPracticesMutation();


//! practices
  const getPracticeIds = (practicesData) => {
    return practicesData?.map((practice) => practice.id);
  };

  useEffect(() => {
    if (currentPractices) {
      const practiceIds = getPracticeIds(currentPractices);
      setSelectedPracticeIds(practiceIds);
    }
    // eslint-disable-next-line
  }, [currentPractices, competitionContent]);


//! games
  const getStepIds = (gameData) => {
    return gameData?.flatMap((adventure) => {
      if (adventure.sections) {
        // Eğer sections varsa, sections içindeki steps'i al
        return adventure.sections.flatMap((section) =>
          section.steps.map((step) => step.id)
        );
      } else if (adventure.steps) {
        // Eğer doğrudan steps varsa, steps içindeki id'leri al
        return adventure.steps.map((step) => step.id);
      } else {
        // Eğer ne sections ne de steps varsa, boş bir dizi döner
        return [];
      }
    });
  };

  useEffect(() => {
    if (currentGames) {
      const stepIds = getStepIds(currentGames);
      setSelectedGameIds(stepIds);
    }
    // eslint-disable-next-line
  }, [currentGames, competitionContent]);


//! lessons 
  const getLessonIds = (lessonsData) => {
    const lessonIds = [];

    for (const course of lessonsData) {
      for (const chapter of course.chapters) {
        for (const lesson of chapter.lessons) {
          lessonIds.push(lesson.id);
        }
      }
    }
    return lessonIds;
};

useEffect(() => {
  if (currentLessons) {
      const lessonIds = getLessonIds(currentLessons);
      setSelectedLessonIds(lessonIds);
    }
    // eslint-disable-next-line
  }, [currentLessons, competitionContent]);

  const [errorMessage, setErrorMessage] = useState(false);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      formik.setFieldValue("thumbnail", selectedFile);
      setErrorMessage(false);
      console.log(selectedFile);
    } else {
      formik.setFieldValue("thumbnail", null);
      setErrorMessage(true);
    }
  };

  function getTotalPoints(games, practices, lessons) {
    if(!isProjectCompetition){
    const totalPoints = 50 * (games?.length + practices?.length + lessons?.length);
    return totalPoints;}
    else{
      formik.setFieldValue("points",formik.values.points)
    }
  }
  useEffect(() => {
    if(!isProjectCompetition){
      const newTotalPoints = getTotalPoints(selectedGameIds, selectedPracticeIds, selectedLessonIds);
      formik.setFieldValue("points", newTotalPoints);
    }
    // eslint-disable-next-line
  }, [selectedGameIds, selectedPracticeIds, selectedLessonIds]);

  //formik
  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = "Başlık giriniz!";
    }
    if (!values.description) {
      errors.description = "İçerik giriniz!";
    }
    if (!values.start_date) {
      errors.start_date = "Başlangıç Tarihi giriniz!";
    }
    if (!values.end_date) {
      errors.end_date = "Bitiş Tarihi giriniz!";
    }
    let start_date = combineDateAndTime(
      values.start_date,
      values.start_date_time
    );
    let end_date = combineDateAndTime(values.end_date, values.end_date_time);
    const today = new Date();
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);
    const isDateError = startDate.getTime() >= endDate.getTime();
    if (isDateError) {
      errors.start_date = "Başlangıç tarihi ve saatini kontrol ediniz!";
      errors.end_date = "Bitiş tarihi ve saatini kontrol ediniz!";
      errors.start_date_time = "Başlangıç tarihi ve saatini kontrol ediniz!";
      errors.end_date_time = "Bitiş tarihi ve saatini kontrol ediniz!";
    }
    const isDateErrorForToday = endDate.getTime() < today.getTime();
    if (isDateErrorForToday) {
      errors.end_date = "Bitiş tarihi ve saati geçmiş zaman olamaz!";
      errors.end_date_time = "Bitiş tarihi ve saati geçmiş zaman olamaz!";
    }
    if (!values.start_date_time) {
      errors.start_date_time = "Başlangıç saatini yazınız";
    }
    if (!values.end_date_time) {
      errors.end_date_time = "Bitiş saatini yazınız";
    }
    if (!values.points && values.kind === 1) {
      errors.points = "Ödev tanımlayınız!";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      id: "",
      thumbnail: null,
      title: "",
      description: "",
      start_date: new Date().toISOString(),
      start_date_time: "00:00",
      end_date: new Date().toISOString(),
      end_date_time: "00:00",
      kind: "1",
      is_team: false,
      points: getTotalPoints(selectedGameIds, selectedPracticeIds, selectedLessonIds),
      //! alıştırma bölümü için
      practices: [],
      lessons: [],
      games: [],
    },
    validate,
    onSubmit: async (values) => {
      if (!formik.values.thumbnail && !formik.values.id) {
        setErrorMessage(true);
      } else {
        if (isSubmitting) return;
        setIsSubmitting(true);
        saveCompetition(values);
      }
    },
  });
  //side effects
  useEffect(() => {
    initialize();
    //eslint-disable-next-line
  }, []);
  //methods
  const initialize = async () => {
    formik.resetForm();
    if (selectedCompetition) {
      setCompetitionLogo(selectedCompetition.title);
      let { dateString: startDate, time: startTime } = splitDateAndTime(
        selectedCompetition.start_date
      );
      let { dateString: endDate, time: endTime } = splitDateAndTime(
        selectedCompetition.end_date
      );
      let competitionData = {
        ...selectedCompetition,
        thumbnail: competitionLogo,
        start_date: startDate,
        start_date_time: startTime,
        end_date: endDate,
        end_date_time: endTime,
      };
      formik.setValues(competitionData);
    }
    if (competitionContent) {
    }
  };
  const saveCompetition = async (_data) => {
    let data = {
      ..._data,
      start_date: combineDateAndTime(
        _data.start_date,
        _data.start_date_time
      ).toISOString(),
      end_date: combineDateAndTime(
        _data.end_date,
        _data.end_date_time
      ).toISOString(),
      application_deadline: (() => {
        // end_date'i alıyoruz
        const endDate = combineDateAndTime(_data.end_date, _data.end_date_time);
        // endDate'den 1 saat çıkar
        const deadline = new Date(endDate);
        deadline.setHours(deadline.getHours() - 1);
        return deadline.toISOString(); // ISO formatında döndür
      })(),
    };
    delete data.start_date_time;
    delete data.end_date_time;

    try {
      if (selectedCompetition) {
        const formData = new FormData();
        formData.append("id", id);
        formData.append("title", data.title);
        data.thumbnail && formData.append("thumbnail", data.thumbnail);
        formData.append("description", data.description);
        // formData.append("application_deadline", data.application_deadline);
        formData.append("start_date", data.start_date);
        formData.append("end_date", data.end_date);
        formData.append("kind", data.kind);
        formData.append("is_team", data.is_team);
        formData.append("points", data.points);
        const responsePractices = await updateCompetitionPractices({
          id: id,
          practices: {
            practices: selectedPracticeIds,
            games: selectedGameIds,
            lessons: selectedLessonIds,
          },
        });
        const response = await updateCompetition({ id: data.id, formData });
        if (response.error) {
          showError();
        } else {
          showSuccess("Yarışma güncellendi");
        }
        if (responsePractices.error) {
          showError();
        } else {
          showSuccess("Alıştırmalar oluşturuldu");
        }
        formik.resetForm();
        navigate(-1);
      } else {
        const formData = new FormData();
        formData.append("id", data.id);
        formData.append("title", data.title);
        data.thumbnail && formData.append("thumbnail", data.thumbnail);
        formData.append("description", data.description);
        // formData.append("application_deadline", data.application_deadline);
        formData.append("start_date", data.start_date);
        formData.append("end_date", data.end_date);
        formData.append("kind", data.kind);
        formData.append("is_team", data.is_team);
        formData.append("points", data.points);
        const response = await createCompetition(formData);

        if (response.error) {
          showError();
        } else {
          showSuccess("Yarışma oluşturuldu");
        }
        formik.resetForm();
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Kayıt Başarısız</span>
        </span>,
        "Bir hata oluştu",
        "danger"
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  const isProjectCompetition = Number(formik.values.kind) === 2;

  // Yarışma başladıktan sonra güncelleme yapma
  useEffect(() => {
    if (selectedCompetition) {
      const startDate = combineDateAndTime(
        formik.values.start_date,
        formik.values.start_date_time
      );
      const now = new Date();
      const startDateTime = new Date(startDate);

      if (startDateTime <= now) {
        setIsSaveDisabled(true); // Eğer başlangıç tarihi geçmişse butonu pasif yap
      } else {
        setIsSaveDisabled(false); // Aksi durumda buton aktif olur
      }
    }
    //eslint-disable-next-line
  }, [formik.values.start_date, formik.values.start_date_time]);

  return (
    <div>
      <div className="row g-4">
        <div className="col-12">
          <div className="mb-0">
            <div className="">
              <form
                className={`${style.competitionForm} row g-4`}
                onSubmit={formik.handleSubmit}
              >
                {/* Yarışma Türü */}
                <div className="col-12">
                  <CustomFormField
                    type="select"
                    label="Yarışma Türü"
                    name={"kind"}
                    options={[
                      { value: "1", text: "Alıştırma" },
                      { value: "2", text: "Proje" },
                    ]}
                    formik={formik}
                    disabled={selectedCompetition}
                  />
                </div>

                {/* Yarışma Başlığı */}
                <div className="col-12">
                  <CustomFormField
                    type="text"
                    label="Yarışma Başlığı"
                    name={"title"}
                    formik={formik}
                  />
                </div>

                {/* Yarışma Logosu */}
                <div className="col-12">
                  <Label>Logo</Label>
                  <FormGroup id="logo">
                    <Input
                      onChange={handleFileChange}
                      type="file"
                      accept=".png"
                    />
                  </FormGroup>
                  {errorMessage && (
                    <div>
                      <p
                        style={{
                          color: "#f46639",
                          fontSize: "11px",
                          margin: "3px",
                        }}
                      >
                        Logo Giriniz
                      </p>
                    </div>
                  )}
                </div>
                {/* Açıklama */}
                <div className="col-12">
                  <CustomFormField
                    type="textArea"
                    label="Açıklama"
                    name={"description"}
                    formik={formik}
                    style={{ height: "100px" }}
                  />
                </div>

                <div className="d-flex flex-wrap gap-1">
                  {/* Puan */}
                  {selectedCompetition && (
                    <div className="col order-md-0 order-2">
                      <FormGroup id="points" label="Puan" isFloating>
                        <Input
                          onChange={formik.handleChange}
                          value={formik.values.points}
                          isValid={formik.isValid}
                          onBlur={formik.handleBlur}
                          isTouched={formik.touched.points}
                          invalidFeedback={formik.errors.points}
                          type="number"
                          readOnly={!isProjectCompetition}
                        />
                      </FormGroup>
                    </div>
                  )}
                </div>

                {/* Başlama ve Bitiş Zamanı */}
                {/* Başlangıç Zamanı */}
                <div
                  className={`${style.startDate} d-flex align-items-center gap-2 col-12 col-md-6 `}
                >
                  <div className="col">
                    <FormGroup
                      id="start_date"
                      label="Başlangıç Tarihi"
                      isFloating
                    >
                      <Input
                        type={"date"}
                        value={formik.values.start_date.substring(0, 10)}
                        placeholder="Başlangıç Tarihini giriniz"
                        onChange={formik.handleChange}
                        isValid={formik.isValid}
                        onBlur={formik.handleBlur}
                        isTouched={formik.touched.start_date}
                        invalidFeedback={formik.errors.start_date}
                        disabled={isSaveDisabled}
                      />
                    </FormGroup>
                  </div>
                  <div className="col">
                    <FormGroup
                      id="start_date_time"
                      label="Başlangıç saati"
                      isFloating
                    >
                      <Input
                        type={"time"}
                        value={formik.values.start_date_time}
                        onChange={formik.handleChange}
                        isValid={formik.isValid}
                        onBlur={formik.handleBlur}
                        isTouched={formik.touched.start_date_time}
                        invalidFeedback={formik.errors.start_date_time}
                        disabled={isSaveDisabled}
                      />
                    </FormGroup>
                  </div>
                </div>
                {/* Bitiş Zamanı */}
                <div
                  className={`${style.endDate} d-flex align-items-center gap-2 col-12 col-md-6 `}
                >
                  <div className="col">
                    <FormGroup id="end_date" label="Bitiş Tarihi" isFloating>
                      <Input
                        type="date"
                        onChange={formik.handleChange}
                        value={formik.values.end_date.substring(0, 10)}
                        isValid={formik.isValid}
                        onBlur={formik.handleBlur}
                        isTouched={formik.touched.end_date}
                        invalidFeedback={formik.errors.end_date}
                      />
                    </FormGroup>
                  </div>
                  <div className="col">
                    <FormGroup
                      id="end_date_time"
                      label="Bitiş Saati"
                      isFloating
                    >
                      <Input
                        type={"time"}
                        value={formik.values.end_date_time}
                        onChange={formik.handleChange}
                        isValid={formik.isValid}
                        onBlur={formik.handleBlur}
                        isTouched={formik.touched.end_date_time}
                        invalidFeedback={formik.errors.end_date_time}
                      />
                    </FormGroup>
                  </div>
                </div>
                {/* Task */}
                {!isProjectCompetition && selectedCompetition && (
                  <TaskPreviewForCompetition
                    setOpenAddTaskForm={setOpenAddTaskForm}
                    openAddTaskForm={openAddTaskForm}
                    competitionCurrentPractices={competitionContent || []}
                    selectedGameIds={selectedGameIds}
                    selectedPracticeIds={selectedPracticeIds}
                    selectedLessonIds={selectedLessonIds}
                    currentGames={currentGames}
                    currentPractices={currentPractices}
                    currentLessons={currentLessons}
                  />
                )}
                {!isProjectCompetition &&
                  openAddTaskForm &&
                  (isLoading ? (
                    <div>Loading...</div>
                  ) : (
                    <TaskAdd
                      setCurrentPractices={setCurrentPractices}
                      currentPractices={currentPractices}
                      currentGames={currentGames}
                      setCurrentGames={setCurrentGames}
                      currentLessons={currentLessons}
                      setCurrentLessons={setCurrentLessons}
                    />
                  ))}
                {/* Buttons */}
                <div className="d-flex justify-content-end gap-3">
                  <div
                    className={`${style.buttonsContainer} ${
                      isProjectCompetition && style.buttonsContainerForProject
                    } d-flex gap-2`}
                  >
                    <Button
                      className={`${style.cancelButton}`}
                      onClick={() => {
                        formik.resetForm();
                        navigate(-1);
                      }}
                    >
                      İptal
                    </Button>
                    <Button
                      className={`${style.saveButton}`}
                      type="submit"
                      onClick={async () => {
                        formik.handleSubmit();
                      }}
                      isDisable={isSubmitting}
                    >
                      {/* Kaydet */}
                      {isSubmitting ? (
                        <Spinner inButton isSmall>
                          Loading...
                        </Spinner>
                      ) : (
                        "Gönder"
                      )}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowCompetition;
