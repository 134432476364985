import React, { useState } from 'react'
import style from "./ClassRestrictions.module.scss"
import contentLogoPrimary from "../../../../assets/school/contentLogoPrimary.svg";
import contentLogoWhite from "../../../../assets/school/contentLogoWhite.svg";
import ContentRestrictions from './content restrictions/ContentRestrictions';
import { useGetSchoolClassesQuery } from '../schoolManagerApi';

const ClassRestrictions = () => {

    const [activeTab, setActiveTab] = useState(1);

    const { data: activeClasses, isSuccess } = useGetSchoolClassesQuery();


    const tabContents = [
      // {
      //   content: <PlatformRestrictions/>,
      //   tabName: "Yetki Yönetimi",
      //   icon1: settingsLogoPrimary,
      //   icon2: settingsLogoWhite,
      // },
      {
        content: isSuccess && activeClasses && activeClasses.length > 0 ? <ContentRestrictions activeClasses={activeClasses} /> 
          : null,
        tabName: "İçerik Yönetimi",
        icon1: contentLogoPrimary,
        icon2: contentLogoWhite,
      },
    ];
  
    const handleTabClick = (tabNumber) => {
      if (tabNumber >= 1 && tabNumber <= tabContents.length) {
      sessionStorage.setItem("userListActiveTab", tabNumber);
      setActiveTab(tabNumber);}
    };
  return (
      <>
      <div className="h-100 mt-3 mx-lg-3">
        <div
          className={`${style.tabContainer} d-flex justify-content-evenly align-items-center mx-auto mt-2 mb-5 px-3 gap-1`}
        >
          {tabContents.map((tab, index) => (
            <div
              key={index}
              className={`${style.tab} ${
                activeTab === index + 1 ? `${style.activetab}` : ""
              } d-flex justify-content-center align-items-center gap-2 p-3`}
              onClick={() => handleTabClick(index + 1)}
            >
              <div className={`${style.tabsLogo}`}>
                <img
                  src={activeTab === index + 1 ? tab.icon2 : tab.icon1}
                  alt=""
                />
              </div>
              <div className={`${style.tabsDescription}`}>
                <span>{tab.tabName}</span>
              </div>
            </div>
          ))}
        </div>
         <div className="tab-content">
            {tabContents[activeTab - 1] && tabContents[activeTab - 1]?.content}
          </div>
        </div>
      </>
  )
}

export default ClassRestrictions