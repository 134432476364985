import React from "react";
import HomeWorkLeftMenu from "./HomeWorkLeftMenu";
import LessonList from "./LessonList";
import LearningPathList from "./LearningPathList";
import GameContent from "./GameContent";
import { menuList } from "./menuList";
import { useSelector } from "react-redux";
import TeacherPracticeListContent from "./TeacherPracticeListContent";
import SchoolLessonList from "./SchoolLessonList";

const TaskAdd = ({
  setCurrentContent,
  setOpenAddTaskForm,
  content,
  setCourses,
  setPractices,
  setGameSteps,
  currentClass,
  setTeacherPractices,
}) => {
  const { menu } = useSelector((store) => store.teacher);
  return (
    <>
      {/* Sol Menü */}
      <div className="col-12 col-lg-4 col-xl-3">
        <HomeWorkLeftMenu currentClass={currentClass} />
      </div>

      {/* İçerik */}
      <div className="col-12 col-lg-8 col-xl-9">
        {menu === menuList.LESSONS ? (
          <LessonList
            setCourses={setCourses}
            currentCourses={content.course_lessons}
            currentClass={currentClass}
          />
        ) : menu === "SCHOOL_COURSE_LESSONS" ? (
          <SchoolLessonList
            setCourses={setCourses}
            currentCourses={content.course_lessons}
            currentClass={currentClass}
          />
        ) : menu === menuList.CODELAB ? (
          <LearningPathList
            setPractices={setPractices}
            currentPractices={content.learning_paths}
          />
        ) : menu === "TEACHER_PRACTICES" ? (
          <TeacherPracticeListContent
            setTeacherPractices={setTeacherPractices}
            currentPractices={content.teacher_practices}
          />
        ) : menu === menuList.CODEMINO ? (
          <GameContent
            setGameSteps={setGameSteps}
            currentGameSteps={content.game_steps}
          />
        ) : menu === menuList.CODEBOO ? (
          <GameContent
            setGameSteps={setGameSteps}
            currentGameSteps={content.game_steps}
          />
        ) : menu === menuList.CODECIZZ ? (
          <GameContent
            setGameSteps={setGameSteps}
            currentGameSteps={content.game_steps}
          />
        ) : (
          "content"
        )}
      </div>
    </>
  );
};

export default TaskAdd;
