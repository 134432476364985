import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGamesQuery, useGamesWithSectionQuery, useGameStepsWithSectionQuery, useGameStepsWithoutSectionQuery } from '../../../../../store/features/apiSlice';
import { menuList } from './menuList';
import SimpleSelectList from '../../components/SimpleSelectList';
import SimpleTable from '../../components/simple-table/SimpleTable';
import _ from 'lodash';
import TeacherCard from '../../components/teacher-card/TeacherCard';

const GameContent = ({ currentGameSteps, setGameSteps }) => {

    const [codeMinoGames, setCodeMinoGames] = useState(null);
    const [codeBooGames, setCodeBooGames] = useState(null);
    const [codeCizzGames, setCodeCizzGames] = useState(null);

    const { menu } = useSelector((state) => state.teacher);
    const { data: games, isSuccess} = useGamesQuery();

  function handleSelectStep(game, section, step) {
    let games = _.cloneDeep(currentGameSteps);
    let existionGameIndex = games.findIndex((g) => g.id === game.id);
    if (section) {
      if (Number(existionGameIndex) !== -1) {
        //Game exist
        let existionSectionIndex = games[existionGameIndex].sections.findIndex(
          (s) => s.id === section.id
        );
        if (Number(existionSectionIndex) !== -1) {
          //Section exist
          let existionStepIndex = games[existionGameIndex].sections[
            existionSectionIndex
          ].steps.findIndex((s) => s.id === step.id);
          if (Number(existionStepIndex) !== -1) {
            //Step exist
            games[existionGameIndex].sections[
              existionSectionIndex
            ].steps.splice(existionStepIndex, 1);

            if (
              Number(games[existionGameIndex].sections[existionSectionIndex].steps
                .length) === 0
            ) {
              //remove section
              games[existionGameIndex].sections.splice(existionSectionIndex, 1);
            }
            if (Number(games[existionGameIndex].sections.length) === 0) {
              //remove game
              games.splice(existionGameIndex, 1);
            }
          } else {
            //Step doesn't exist
            games[existionGameIndex].sections[existionSectionIndex].steps.push({
              id: step.id,
              title: step.title,
            });
          }
        } else {
          //Section doesn't exist
          games[existionGameIndex].sections.push({
            id: section.id,
            title: section.title,
            steps: [{ id: step.id, title: step.title }],
          });
        }
      } else {
        //Game doesn't exist
        games.push({
          id: game.id,
          title: game.title,
          sections: [
            {
              id: section.id,
              title: section.title,
              steps: [{ id: step.id, title: step.title }],
            },
          ],
        });
      }
    } else {
      if (Number(existionGameIndex) !== -1) {
        //Game exist
        let existionStepIndex = games[existionGameIndex].sections[0].steps.findIndex(
          (s) => s.id === step.id
        );
        if (Number(existionStepIndex) !== -1) {
          //Step exist
          games[existionGameIndex].sections[0].steps.splice(existionStepIndex, 1);

          if (Number(games[existionGameIndex].sections[0].steps.length) === 0) {
            //remove step
            games[existionGameIndex].sections[0].steps.splice(existionStepIndex, 1);
          }
        } else {
          //Step doesn't exist
          games[existionGameIndex].sections[0].steps.push({
            id: step.id,
            title: step.title,
          });
        }
      } else {
        //Game doesn't exist
        games.push({
          id: game.id,
          title: game.title,
          // steps: [{ id: step.id, title: step.title }],
          sections: [
            {
              id: null,
              title: null,
              steps: [{ id: step.id, title: step.title }],
            },
          ],
        });
      }
    }
    setGameSteps(games);
  }

  useEffect(() => {
    if (isSuccess) {
      setCodeMinoGames(
        games.filter(
          (game) => game.title !== "CodeBoo" && game.title !== "CodeCizz"
        )
      );
      setCodeBooGames(games.filter((game) => game.title === "CodeBoo"));
      setCodeCizzGames(games.filter((game) => game.title === "CodeCizz"));
    }
  }, [isSuccess, games]);
  return (
    <div className="col-12">
      {menu === menuList.CODEMINO ? (
        <GameList
          games={codeMinoGames}
          handleSelectStep={handleSelectStep}
          currentGameSteps={currentGameSteps}
        />
      ) : menu === menuList.CODEBOO ? (
        <GameList
          games={codeBooGames}
          handleSelectStep={handleSelectStep}
          currentGameSteps={currentGameSteps}
        />
      ) : menu === menuList.CODECIZZ ? (
        <GameList
          games={codeCizzGames}
          handleSelectStep={handleSelectStep}
          currentGameSteps={currentGameSteps}
        />
      ) : null}
    </div>
  );
};

function GameList({
  games,
  isLoading,
  error,
  handleSelectStep,
  currentGameSteps,
}) {
  const [selectedGame, setSelectedGame] = React.useState(null);
  const [selectedSection, setSelectedSection] = React.useState(null);

  const selectedGameSteps = useMemo(() => {
    if (selectedGame) {
        if(selectedSection){
            let currentGame = currentGameSteps.find(
              (gameStep) => gameStep.id === selectedGame.id
            );
            if (currentGame) {
              let currentSection = currentGame.sections?.find(
                (section) => section.id === selectedSection.id
              );
              if (currentSection) {
                return currentSection.steps.map((s) => s.id);
              }
            }
        }else{
            let currentGame = currentGameSteps.find(
                (gameStep) => gameStep.id === selectedGame.id
              );
              if (currentGame) {
                return currentGame.sections[0].steps?.map((s) => s.id);
              }
        }
    }
    return [];
  }, [selectedGame, selectedSection, currentGameSteps]);

  function setSelectedStep(selectedStep) {
    handleSelectStep(selectedGame, selectedSection, selectedStep);
  }

  useEffect(() => {
    setSelectedSection(null);
  }, [selectedGame]);

  useEffect(() => {
    setSelectedGame(null);
  }, [games]);
  return (
    <div className="row">
      {isLoading && <p>Loading...</p>}
      {error && <p>Error</p>}

      <div className="col" style={{ minHeight: "310px", minWidth: "250px" }}>
        <TeacherCard>
          {games && (
            <SimpleTable
              items={games}
              onSelect={(id) =>
                setSelectedGame(games.find((game) => game.id === id))
              }
              selectedItems={currentGameSteps.map((s) => s.id)}
              displayKey={"title"}
              selectKey={"id"}
            />
          )}
        </TeacherCard>
      </div>
      {selectedGame && selectedGame.has_section && (
        <Sections
          gameId={selectedGame?.id}
          setSection={(sectionId) => setSelectedSection(sectionId)}
          selectedSectionId={selectedSection?.id}
        />
      )}
      {selectedGame && (selectedGame.has_section === false) && (
        <StepsWithoutSection
          gameId={selectedGame?.id}
          setSelectedStep={setSelectedStep}
          selectedGameSteps={selectedGameSteps}
        />
      )}
      {selectedSection && (
        <Steps
          gameId={selectedGame?.id}
          sectionId={selectedSection?.id}
          setSelectedStep={setSelectedStep}
          selectedGameSteps={selectedGameSteps}
        />
      )}
    </div>
  );
}
function Sections({ gameId, selectedSectionId, setSection }) {
  const {
    data: gamesWithSection,
    error: errorWithSection,
  } = useGamesWithSectionQuery({ id: gameId });
  return (
    <div className="col" style={{ minWidth: "250px" }}>
      <TeacherCard>
        {gamesWithSection && (
          <SimpleTable
            items={gamesWithSection}
            onSelect={(id) =>
              setSection(gamesWithSection.find((game) => game.id === id))
            }
            displayKey={"title"}
            selectKey={"id"}
            selectedItems={[selectedSectionId]}
          />
        )}
      </TeacherCard>
      {errorWithSection && <p>Error</p>}
    </div>
  );
}

function Steps({ gameId, sectionId, setSelectedStep, selectedGameSteps }) {
  const {
    data: gameStepWithSectionId,
  } = useGameStepsWithSectionQuery({ id: gameId, sectionId });

  function handleStepSeleciton(id) {
    let step = gameStepWithSectionId.find((step) => Number(step.id) === Number(id));

    if (step) setSelectedStep(step);
    else console.log("step bulunamadı");
  }
  return (
    <div className="col" style={{ minWidth: "250px" }}>
      <TeacherCard>
        {gameStepWithSectionId && (
          <SimpleSelectList
            items={gameStepWithSectionId}
            displayKey={"title"}
            selectKey={"id"}
            onSelect={handleStepSeleciton}
            selectedItems={selectedGameSteps}
          />
        )}
      </TeacherCard>
    </div>
  );
}
function StepsWithoutSection({ gameId, setSelectedStep, selectedGameSteps }) {
  const {
    data: gameStepWithoutSectionId,
  } = useGameStepsWithoutSectionQuery({ id: gameId });

  function handleStepSeleciton(id) {
    let step = gameStepWithoutSectionId.find((step) => Number(step.id) === Number(id));
    if (step) setSelectedStep(step);
    else console.log("step bulunamadı");
  }
  return (
    <div className="col" style={{ minWidth: "250px" }}>
      <TeacherCard>
        {gameStepWithoutSectionId && (
          <SimpleSelectList
            items={gameStepWithoutSectionId}
            displayKey={"title"}
            selectKey={"id"}
            onSelect={handleStepSeleciton}
            selectedItems={selectedGameSteps}
          />
        )}
      </TeacherCard>
    </div>
  );
}

export default GameContent;
