import React from 'react';
import style from "./TestResult.module.css";
import TestResult from './TestResult';
import { NavLink } from "react-router-dom";

function CodeRunSuccess({path_id, codeRunResult, tests}) {
    var x = tests.map((test)=>{
        return <TestResult key={test.test_id} test={test}/>
    });
    const next_practice_id = codeRunResult.next_practice_id;
    return (
        <div className={style.success}>
            <h2>Tebrikler! Kod Çalıştı :)</h2>
          
            <img className={style.thumb} src="/images/friday-happy.gif" alt="tebrikler" />
            {next_practice_id && <NavLink type="button" to={`/paths/${path_id}/practice/${next_practice_id}`} className="btn btn-outline-success btn-lg btn-hover-shadow-lg shadow-none rounded-2">Sonraki Alıştırma >> </NavLink> }
            {x}
        </div>
    );
}

export default CodeRunSuccess;
