import React, { useEffect, useState } from 'react';
import { ArrowLeft, ArrowRight } from '../../../../../../components/icon/bootstrap';
import Input from '../../../../../../components/bootstrap/forms/Input';
import styles from "./SimpleTable.module.css"

const SimpleTable = ({ items, onSelect,selectedItemId, selectedItems, displayKey }) => {
    const [page, setPage] = useState(1);
    const [limit] = useState(5);
    const [pages, setPages] = useState(0);
    const [filteredItems, setFilteredItems] = useState(items)

    function displayPage(pageNumber, items) {
        if (!items) return [];

        const startIndex = (pageNumber - 1) * limit;
        const endIndex = startIndex + limit;
        return items.slice(startIndex, endIndex);
    }
    useEffect(() => {
        if (items) {
            setFilteredItems(items)
            setPages(Math.ceil(items.length / limit));
            setPage(1);
        }
        //eslint-disable-next-line
    }, [items])

    return (
        <div>
            <Input
                onChange={(e) => {
                    setFilteredItems(items.filter(item => item[displayKey].toLowerCase().includes(e.target.value.toLowerCase())));
                    setPage(1);
                }} placeholder="Arama yapınız"/>
            <br />
            <table className="table">
                <tbody>
                    {displayPage(page, filteredItems).map((item) => (

                        <tr key={item.id}
                            className={`cursor-pointer ${selectedItems.includes(item.id) ? styles.selectedRow : ''} ${selectedItemId === item.id ? styles.borderedRow:""}`}
                            onClick={() => onSelect(item.id)}>
                                <td>
                                    {item[displayKey]}
                                </td>
                            </tr>
                    ))}
                </tbody>
                {pages > 1 &&
                    <tfoot>
                        <tr>
                            <td>
                                <div>
                                    <ArrowLeft onClick={() => page === 1 ? setPage(pages) : setPage(page - 1)} className="cursor-pointer"/>
                                    {'  '}
                                    {page}/{pages}
                                    {'  '}
                                    <ArrowRight onClick={() => page === pages ? setPage(1) : setPage(page + 1)} className="cursor-pointer"/>
                                </div>
                            </td>
                        </tr>
                    </tfoot>}
            </table>
        </div>
    );
};

export default SimpleTable;