import React from 'react';
import { Outlet } from 'react-router-dom';

const TeacherWrapper = ({children}) => {
    // useEffect(() => {
    //     if (window.Tawk_API) {
    //       window.Tawk_API.hideWidget();
    //     }
    //     return () => {
    //       if (window.Tawk_API) {
    //         window.Tawk_API.showWidget();
    //       }
    //     };
    //   }, []);
    return (

            <Outlet />

    );
};

export default TeacherWrapper;