import React, { useState } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/webpack-resolver";
import Card, {
  CardBody,
} from "../../../components/bootstrap/Card";
import CodeEditorToolbar from "./CodeEditorToolbar";
import Button from "../../../components/bootstrap/Button";
import style from "./practice.module.scss"

function CodeEditor({
  handleUserCodeChange,
  onUserCodeFocus,
  codeEditorHeight,
  userCode,
  nextPractice,
  previousPractice,
  currentPracticeIndex,
  lastIndex,
  withoutLearningPath=false
}) {
  const [mode, setMode] = useState("python");
  const [theme, setTheme] = useState({ name: "Koyu (Default)", value: "monokai" });

  const onChange = (value) => {
    handleUserCodeChange(value);
  };
  return (

<>
<div className={`${style.codeEditorContainer} d-flex justify-content-between`}>
  <div className={`${style.editorButtons} `}>
  <CodeEditorToolbar mode={mode} theme={theme.name} setMode={setMode} setTheme={setTheme}/>
  </div>
{<div className={`${style.actionButtons} d-flex gap-1`}>
    {(currentPracticeIndex > 0 && !withoutLearningPath) && <div className={`${style.previousButton}`}><Button
						icon='ArrowBackIos'
						color='info'
						isLight
						onClick={()=>previousPractice()}
            >
						önceki
					</Button>
          </div>}
{(currentPracticeIndex < lastIndex && !withoutLearningPath) && <div className={`${style.nextButton}`}><Button
						icon='ArrowForwardIos'
						color='info'
						isLight
						onClick={()=>nextPractice()}
            >
						sonraki
					</Button>
          </div>}
  </div>}
</div>

    <Card stretch={true} className={`${style.cardContainerAceEditor} `}>
     
        <CardBody className="table-responsive" isScrollable={false}>
        <AceEditor
        className={`${style.aceEditor}`}
          value={userCode}
          height={`${codeEditorHeight}px`}
          width="60vw"
          mode={mode}
          theme={theme.value}
          onChange={onChange}
          name="my_code_editor"
          onFocus={onUserCodeFocus}
          editorProps={{
            $blockScrolling: true,
          }}
          fontSize={18}
          showPrintMargin={false}
          showGutter={true}
          highlightActiveLine={false}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 1,
          }}
          wrapEnabled={true}
          />
        </CardBody>
      </Card>
          </>
  );
}

export default CodeEditor;
