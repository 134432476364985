// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@charset \"UTF-8\";\n.TalentResponsiveImage_imageContainer__g3LuF {\n  position: relative;\n  width: 100%; /* Genişlik konteynerin genişliğine uyacak şekilde ayarlanır */\n  height: 0;\n  padding-top: 56.25%; /* 16:9 en-boy oranı için */\n  overflow: hidden;\n}\n\n.TalentResponsiveImage_responsiveImage__lMKd8 {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  -o-object-fit: cover;\n     object-fit: cover; /* Resmi kapsayıcıya göre kırpmak için */\n}", "",{"version":3,"sources":["webpack://./src/pages/t14/admin/talent cup/components/TalentResponsiveImage.module.scss","webpack://./src/pages/t14/admin/talent%20cup/components/TalentResponsiveImage.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;ACAhB;EACI,kBAAA;EACA,WAAA,EAAA,8DAAA;EACA,SAAA;EACA,mBAAA,EAAA,2BAAA;EACA,gBAAA;ADEJ;;ACCE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;KAAA,iBAAA,EAAA,wCAAA;ADEJ","sourcesContent":["@charset \"UTF-8\";\n.imageContainer {\n  position: relative;\n  width: 100%; /* Genişlik konteynerin genişliğine uyacak şekilde ayarlanır */\n  height: 0;\n  padding-top: 56.25%; /* 16:9 en-boy oranı için */\n  overflow: hidden;\n}\n\n.responsiveImage {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  object-fit: cover; /* Resmi kapsayıcıya göre kırpmak için */\n}",".imageContainer {\n    position: relative;\n    width: 100%; /* Genişlik konteynerin genişliğine uyacak şekilde ayarlanır */\n    height: 0;\n    padding-top: 56.25%; /* 16:9 en-boy oranı için */\n    overflow: hidden;\n  }\n  \n  .responsiveImage {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    object-fit: cover; /* Resmi kapsayıcıya göre kırpmak için */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageContainer": "TalentResponsiveImage_imageContainer__g3LuF",
	"responsiveImage": "TalentResponsiveImage_responsiveImage__lMKd8"
};
export default ___CSS_LOADER_EXPORT___;
