import React from "react";
import ContentLeftMenu from "./ContentLeftMenu";
import CourseList from "./CourseList";
import { menuList } from "./menuList";
import { useSelector } from "react-redux";
import TrailList from "./TrailList";
import Card, { CardBody } from "../../../../../../components/bootstrap/Card";
import CodelabAndGamesList from "./CodelabAndGamesList";

const ContentRestrictions = () => {
  const { menu } = useSelector((store) => store.restriction);
  const style = {
    overflow: "scroll",
    height: "400px",
  };
  return (
    <Card style={style}>
      <CardBody>
        <div className="row">
          {/* Sol Menü */}
          <div className="col-12 col-lg-4 col-xl-3">
            <ContentLeftMenu />
          </div>

          {/* İçerik */}
          <div className="col-12 col-lg-8 col-xl-9">
            {menu === menuList.COURSES ? (
              <CourseList />
            ) : menu === menuList.TRAILS ? (
              <TrailList />
            ) : menu === menuList.PLATFORM_APPLICATIONS ? (
              <CodelabAndGamesList />
            ) : (
              <CourseList />
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ContentRestrictions;
