import React from "react";
import Chart from "../../../../../../components/extras/Chart";

const ChartInfo = ({value}) => {
  return (
    <div>
      <Chart
        type={"radialBar"}
        height={"200px"}
        width={"200px"}
        series={[value]}
        options={{
          colors: ["#6C5DD3"],
          labels: [""],
          plotOptions: {
            radialBar: {
              offsetX: 0,
              offsetY: 20,
              startAngle: 0,
              endAngle: 360,
              hollow: {
                size: "50%",
                imageWidth: 150,
                imageHeight: 150,
                imageOffsetX: 0,
                imageOffsetY: 0,
                dropShadow: {
                  enabled: false,
                  top: 0,
                  left: 0,
                  blur: 3,
                  opacity: 0.5,
                },
              },
              //radial bardaki renksiz bölüm
              track: {
                show: true,
                startAngle: undefined,
                endAngle: undefined,
                background: "#f2f2f2",
                strokeWidth: "97%",
                opacity: 1,
                margin: 5,
                dropShadow: {
                  enabled: false,
                  top: 0,
                  left: 0,
                  blur: 3,
                  opacity: 0.5,
                },
              },
              dataLabels: {
                show: true,
                // name:{
                //   offsetY:25,
                //   show:true,
                //   color:"#000",
                //   fontSize:"16px"
                // },
                value: {
                  offsetY: -5,
                  color: "#6C5DD3",
                  fontSize: "30px",
                  fontWeight: "700",
                  show: "true",
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

export default ChartInfo;
