import React from "react";
import ShowCompetition from "./ShowCompetition";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../../../../components/bootstrap/Card";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../../layout/Page/Page";
import style from "./Competition.module.scss";
import { useParams } from "react-router-dom";
import { useGet_a_Competition_ThumbnailQuery } from "../talentCupApi";
import { useSelector } from "react-redux";

const Competition = ({ isFluid }) => {
  const { id } = useParams();
  const title = id ?`${"DÜZENLE"}` : "YENİ YARIŞMA";

  const selectedCompetition = useSelector((state) => state.talentCup.selectedCompetition);

  const { data: logoUrl } = useGet_a_Competition_ThumbnailQuery(id, {skip:!id});
  return (
    <PageWrapper>
      <Page>
        <Card stretch={isFluid} className="h-100">
          <CardHeader>
            <CardLabel>
              <CardTitle className={`${style.title} ps-3`}>{title}</CardTitle>
            </CardLabel>
          </CardHeader>
          <CardBody className="table-responsive" isScrollable={isFluid}>
            {id ? <ShowCompetition id={id} selectedCompetition={selectedCompetition} logoUrl={logoUrl}/> :
            <ShowCompetition/>}
          </CardBody>
        </Card>

      </Page>
    </PageWrapper>
  );
};

export default Competition;