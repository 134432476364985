import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Page from "../../../../../layout/Page/Page";
import { setSelectedLectureId } from "../../teacherSlice";
import {
  useDeleteLiveLectureMutation,
  useGetActiveClassesQuery,
  useGetLiveLecturesQuery,
} from "../../teacherApi";
import useSelectTable from "../../../../../hooks/useSelectTable";
import useSortableData from "../../../../../hooks/useSortableData";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import DashboardHeaderWithTitle from "../../../../common/Headers/DashboardHeaderWithTitle";
import PaginationButtons, {
  PER_COUNT,
  dataPagination,
} from "../../../../../components/PaginationButtons";
import style from "./LiveLesson.module.scss";
import plus from "../../../../../assets/dashboardImages/plus.svg";
import recorded from "../../../../../assets/lessons/recorded.svg";
import { IconButton, Tooltip } from "@mui/material";
import ConfirmButton from "../../components/confirm-button";
import { Edit } from "@mui/icons-material";
import showNotification from "../../../../../components/extras/showNotification";
import Icon from "../../../../../components/icon/Icon";
import FormGroup from "../../../../../components/bootstrap/forms/FormGroup";
import Select from "../../../../../components/bootstrap/forms/Select";
import CircleSvg from "./CircleSvg";
import CreateLiveLessonModal from "./CreateLiveLessonModal";
import RecordedLiveLessonModal from "./RecordedLiveLessonModal";
import Confirm from "../../../../common/Confirm";
import Checks from "../../../../../components/bootstrap/forms/Checks";
import {
  formatDate2TurkishShortDate,
  getHourMinute,
} from "../../../../../utils/formatDate";
import AttendanceOfLiveLessonModal from "./AttendanceForLiveLesson/AttendanceOfLiveLessonModal";
import _ from "underscore";

const LiveLesson = ({ isFluid }) => {
  const dispatch = useDispatch();

  const [isOpenNewPathModal, setIsOpenNewPathModal] = useState(false);
  const [isOpenRecordedModal, setIsOpenRecordedModal] = useState(false);
  const [isOpenAttendanceModal, setIsOpenAttendanceModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState();
  const [selectedClassName, setSelectedClassName] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_COUNT["10"]);
  const [filteredLiveLectures, setFilteredLiveLectures] = useState([]);
  const { data: liveLessons } = useGetLiveLecturesQuery();
  const { data: activeClasses, isSuccess } = useGetActiveClassesQuery();

  const customOrder = [2, 1, 3];

  let sortedLiveLessons  = _.sortBy(liveLessons, item => item.live_lecture.date);
  sortedLiveLessons  = _.sortBy(sortedLiveLessons, item => customOrder.indexOf(item.status));

  const statusData = {
    1: {
      color: "#6697EF",
      text: "BAŞLAMADI",
    },
    2: {
      color: "#618E38",
      text: "BAŞLADI",
    },
    3: {
      color: "#F24F1D",
      text: "BİTTİ",
    },
  };

  function getStatusColor(status) {
    return statusData[status]?.color || "Bilinmeyen Durum";
  }

  function getStatusText(status) {
    return statusData[status]?.text || "Bilinmeyen Durum";
  }

  const { items } = useSortableData(
    filteredLiveLectures?.length > 0
      ? filteredLiveLectures
      : []
  );
  const onCurrentPageData = dataPagination(items, currentPage, perPage);
  const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageData);

  const [
    deleteLiveLecture,
    { isSuccess: deleteSuccess, isError: deleteError },
  ] = useDeleteLiveLectureMutation();

  const handleDelete = () => {
    if (selected.length > 0) {
      selected.forEach((item) => deleteLiveLecture(item));
    }
    setOpen(false);
    setSelected([]);
  };
  const handlCancel = () => setOpen(false);

  useEffect(() => {
    if (selectTable.values.selectedList) {
      setSelected(selectTable.values.selectedList);
    }
  }, [selectTable.values.selectedList]);

  useEffect(() => {
    if (deleteSuccess) {
      (function showSuccess(message) {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Silme Başarılı</span>
          </span>,
          message,
          "success"
        );
      })();
    }
    if (deleteError) {
      (function showError() {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="danger" size="lg" className="me-1" />
            <span>Silme işlemi Başarısız</span>
          </span>,
          "Bir hata oluştu",
          "danger"
        );
      })();
    }
  }, [deleteSuccess, deleteError]);

  useEffect(() => {
    if (isSuccess) {
      const timeout = setTimeout(() => {
        setSelectedClassId(activeClasses[0].id);
        setSelectedClassName(activeClasses[0].name);
      }, 4);
      return () => clearTimeout(timeout);
    }
    //eslint-disable-next-line
  }, [isSuccess]);

  useEffect(() => {
    if (selectedClassId) filterLiveLectures();
    //eslint-disable-next-line
  }, [selectedClassId, liveLessons]);

  const filterLiveLectures = () => {
    const newLectures =
      sortedLiveLessons &&
      sortedLiveLessons.filter(
        (item) => item.classroom && Number(item.classroom) === Number(selectedClassId)
      );
    setFilteredLiveLectures(newLectures);
  };
  return (
    <PageWrapper>
      <DashboardHeaderWithTitle title="Canlı Ders" />
      {isOpenNewPathModal && (
        <CreateLiveLessonModal
          isOpenNewPathModal={isOpenNewPathModal}
          setIsOpenNewPathModal={setIsOpenNewPathModal}
          selectedClassId={selectedClassId}
          selectedClassName={selectedClassName}
        />
      )}
      {isOpenRecordedModal && (
        <RecordedLiveLessonModal
          isOpenRecordedModal={isOpenRecordedModal}
          setIsOpenRecordedModal={setIsOpenRecordedModal}
          selectedClassId={selectedClassId}
          selectedClassName={selectedClassName}
        />
      )}
      {isOpenAttendanceModal && (
        <AttendanceOfLiveLessonModal
          isOpenAttendanceModal={isOpenAttendanceModal}
          setIsOpenAttendanceModal={setIsOpenAttendanceModal}
          selectedClassId={selectedClassId}
          selectedClassName={selectedClassName}
          liveLessons={items}
        />
      )}
      <Page className={`${style.pageContainer}`}>
        <div className="row">
          <div className="col-12">
            <div className={`${style.cardContainer}`}>
              <Confirm
                open={open}
                setOpen={setOpen}
                onConfirm={handleDelete}
                onCancel={handlCancel}
              />
              <div className={`${style.allDeleteButtonContainer} pe-5`}>
                {selected.length > 0 ? (
                  <button
                    className={`${style.allDeleteButton} btn btn-danger`}
                    onClick={() => {
                      setSelected(selected);
                      setOpen(true);
                    }}
                  >
                    Seçilenleri Sil
                  </button>
                ) : (
                  <button
                    style={{ visibility: "hidden" }}
                    onClick={() => {
                      setSelected(selected);
                      setOpen(true);
                    }}
                  >
                    Seçilenleri Sil
                  </button>
                )}
              </div>

              {/* Set class - Show Attendance - Add Live Lesson */}
              <div
                className={`${style.actionsContainer} d-flex align-items-center justify-content-between mx-4 mt-5 px-3`}
              >
                <div className={`${style.classDropdown}`}>
                  <FormGroup id="state" label="Sınıf" isFloating>
                    <Select
                      value={String(selectedClassId)}
                      id="state"
                      ariaLabel="Sınıf"
                      placeholder="Sınıf Seçiniz.."
                      list={activeClasses?.map((c) => ({
                        value: c.id,
                        text: c.name,
                      }))}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setSelectedClassId(e.target.value);
                        setSelectedClassName(
                          e.target.options[e.target.selectedIndex].text
                        );
                      }}
                    />
                  </FormGroup>
                </div>

                <div className="d-flex gap-2">
                  {/* Show attendance for live class */}
                  <div
                    className={`${style.addClassContainer} ${style.attendance} d-flex align-items-center justify-content-center gap-2 ps-2`}
                    onClick={() => {
                      dispatch(setSelectedLectureId(undefined));
                      setIsOpenAttendanceModal(true);
                    }}
                  >
                    {/* <span className="d-flex">
                      <img src={plus} alt="" />
                    </span> */}
                    <span>Yoklama</span>
                  </div>

                  {/* Add live lesson */}
                  <div
                    className={`${style.addClassContainer} ${style.addLiveLesson} d-flex align-items-center gap-2 ps-2`}
                    onClick={() => {
                      dispatch(setSelectedLectureId(undefined));
                      setIsOpenNewPathModal(true);
                    }}
                  >
                    <span className="d-flex">
                      <img src={plus} alt="" />
                    </span>
                    <span>Canlı Ders Ekle</span>
                  </div>
                </div>
              </div>

              {/* Live Lessons Table */}
              <div className={`${style.customTable} mt-5 px-4`}>
                <div
                  className={`${style.customHeadContainer} d-flex align-items-center my-3 px-2`}
                >
                  <div
                    className={`${style.checkContainer} ${style.customTableHead}`}
                  >
                    {SelectAllCheck}
                  </div>
                  <div
                    className={`${style.customTableHead1} ${style.customTableHead}`}
                  >
                    Başlık
                  </div>
                  <div
                    className={`${style.customTableHead2} ${style.customTableHead}`}
                  >
                    Açıklama
                  </div>

                  <div
                    className={`${style.customTableHead3} ${style.customTableHead} text-center`}
                  >
                    Link
                  </div>
                  <div
                    className={`${style.customTableHead4} ${style.customTableHead} text-center`}
                  >
                    Tarih
                  </div>
                  <div
                    className={`${style.customTableHead5} ${style.customTableHead} text-center`}
                  >
                    Saat
                  </div>
                  <div
                    className={`${style.customTableHead6} ${style.customTableHead} text-start`}
                  >
                    Durum
                  </div>
                  <div
                    className={`${style.customTableHead7} ${style.customTableHead} text-center`}
                  >
                    Eylemler
                  </div>
                </div>
                <div className={`${style.customRowContainer}`}>
                  {items.length === 0 ? (
                    <div>
                      <span>Kayıtlı ders bulunamadı</span>
                    </div>
                  ) : (
                    dataPagination(items, currentPage, perPage).map(
                      (item, i) => (
                        <div
                          key={item.id}
                          className={`${style.customTableRow} ${
                            i % 2 === 0 && style.customBackgroundColor
                          } d-flex align-items-center mt-1 px-2`}
                        >
                          <div className={`${style.checkContainer}`}>
                            <Checks
                              id={item.id.toString()}
                              name="selectedList"
                              value={item.id}
                              onChange={selectTable.handleChange}
                              checked={selectTable.values.selectedList.includes(
                                item.id.toString()
                              )}
                            />
                          </div>
                          <div className={`${style.customTableColumn1}`}>
                            <div>
                              <div className={`${style.titleContainer}`}>
                                <div className={`${style.titleShort}`}>
                                  {item.live_lecture.title}
                                </div>
                                <div className={`${style.titleFull}`}>
                                  {item.live_lecture.title}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={`${style.customTableColumn2}`}>
                            <div className={`${style.descriptionContainer}`}>
                              <div className={`${style.descriptionShort}`}>
                                {item.live_lecture.description}
                              </div>
                              <div className={`${style.descriptionFull}`}>
                                {item.live_lecture.description}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`${style.customTableColumn3} text-center`}
                          >
                            <div>
                              <a
                                href={item.live_lecture.live_link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Ders Linki
                              </a>
                            </div>
                          </div>
                          <div
                            className={`${style.customTableColumn4} text-center`}
                          >
                            <div className={`${style.dateContainer}`}>
                                <div className={`${style.dateShort}`}>
                                    {formatDate2TurkishShortDate(
                                item.live_lecture.date
                              )}
                                </div>
                                <div className={`${style.dateFull}`}>
                                    {formatDate2TurkishShortDate(
                                item.live_lecture.date
                              )}
                                </div>
                              </div>
                          </div>
                          <div
                            className={`${style.customTableColumn5} text-center`}
                          >
                            <div>
                              {getHourMinute(item.live_lecture.start_time)} -{" "}
                              {getHourMinute(item.live_lecture.end_time)}
                            </div>
                          </div>
                          <div
                            className={`${style.customTableColumn6} d-flex align-items-center justify-content-start`}
                          >
                            <div
                              className={`${style.circleAndStatus} d-flex align-items-center justify-content-center justify-content-lg-start gap-2`}
                            >
                              <div className="d-none d-md-block">
                                <CircleSvg
                                  fillColor={getStatusColor(item.status)}
                                />
                              </div>
                              {getStatusText(item.status)}
                            </div>
                          </div>

                          {/* Eylemler */}
                          <div
                            className={`${style.customTableColumn7} text-center`}
                          >
                            <div
                              className={`${style.actionIcons} d-flex justify-content-center mx-auto`}
                            >
                              <div className={`${style.deleteIcon}`}>
                              <ConfirmButton
                                icon="Delete"
                                handleClick={deleteLiveLecture}
                                parameter={item.id}
                              />
                              </div>

                              <Tooltip title="Edit" className={`${style.editIcon}`}>
                                <IconButton
                                  onClick={() => {
                                    dispatch(setSelectedLectureId(item.id));
                                    setIsOpenNewPathModal(true);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                              <div className={`${style.cameraIcon} d-flex align-items-center p-2`}>
                                <img
                                  src={recorded}
                                  alt=""
                                  style={{ transform: "scale(1.2)" }}
                                  className="cursor-pointer"
                                  onClick={() => {
                                    dispatch(setSelectedLectureId(item.id));
                                    setIsOpenRecordedModal(true);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )
                  )}
                </div>
              </div>
              <PaginationButtons
                data={items}
                label="canlı ders"
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </div>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default LiveLesson;
