//! mesajaları birleştir
export const handleErrorMessage = (errorData) => {
  console.log(errorData);
  // Hata mesajını diziye çevirme
  let messages = [];

  if (Array.isArray(errorData)) {
    // Birden çok hata mesajı varsa, hepsini diziye ekleyin
    // messages = errorData.map(item => item.detail);
    // en sona eklenen item array ancak başka birşey yok. detail, title vs.
    messages = errorData.map((item) => item.detail || item.title || item.username || item).flat();
    console.log(messages);
  } else {
    // Tek bir hata mesajı varsa
    if (errorData && errorData.detail) {
      if (Array.isArray(errorData.detail)) {
        messages = messages.concat(errorData.detail);
      }
      else {
        messages.push(errorData.detail);
      }
    }
    if (errorData && errorData.title) {
      // Eğer 'title' bir dizi ise, tüm öğeleri ekleyin; değilse doğrudan ekleyin
      if (Array.isArray(errorData.title)) {
        messages = messages.concat(errorData.title);
      }
      else {
        messages.push(errorData.title);
      }
    }
    if (errorData && errorData.username) {
      // Eğer 'username' bir dizi ise, tüm öğeleri ekleyin; değilse doğrudan ekleyin
      if (Array.isArray(errorData.username)) {
        messages = messages.concat(errorData.username);
      } else {
        messages.push(errorData.username);
      }
    }
    if (errorData && errorData.start_date) {
      // Eğer 'start_date' bir dizi ise, tüm öğeleri ekleyin; değilse doğrudan ekleyin
      if (Array.isArray(errorData.start_date)) {
        messages = messages.concat(errorData.start_date);
      } else {
        messages.push(errorData.start_date);
      }
    }
  }
  // Tüm hata mesajlarını birleştir veya varsayılan bir mesaj göster
  console.log("messages", messages);
  return messages.length > 0
    ? messages.join(", ")
    : "Bilinmeyen bir hata oluştu.";
};

//! error control
export const getCustomErrorMessage = (errorMessage, defaultMesage) => {
  const message = errorMessage.toLowerCase();

  if (message.includes("student limit")) {
    return "Maksimum öğrenci sınırına ulaştınız.";
  } else if (message.includes("teacher limit")) {
    return "Maksimum öğretmen sınırına ulaştınız.";
  } else if (message.includes("classrooms limit")) {
    return "Maksimum sınıf sınırına ulaştınız.";
  } else if (message.includes("number of courses")) {
    return "Bu kurum için maksimum ders sınırına ulaştınız.";
  } else if (message.includes("number of lessons")) {
    return "Bu kurum için maksimum video sınırına ulaştınız.";
  } else if (message.includes("course with this title already")) {
    return "Bu ders ismi zaten kullanılmaktadır.";
  } else if (message.includes("to use the parental information system, the subscription type must be standard or premium")) {
    return "Standart ya da Premium kullanıcı olmalısınız.";
  } else if (message.includes("user with that username already exists.")) {
    return "Bu kullanıcı adı kullanılmaktadır.";
  } else if (message.includes("premium")) {
    return "Yarışmaya premium üyeler katılabilir.";
  } else if (message.includes("this user alresdy joined.")) {
    return "Bu kullanıcı zaten yarışmaya başvurdu.";
  } else if (message.includes("updated or deleted")) {
    return "Başlama tarihi geçen bir kupon için işlem yapamazsınız";
  } else if (message.includes("invalid or inactive.")) {
    return "Bu indirim kupon kodu geçersiz veya aktif değil.";
  } else if (message.includes("eligible")) {
    return "Seçilen plan bu indirim kuponu için uygun değil";
  }

  return defaultMesage || "Güncelleme başarısız";
};

export const getCustomActivationKeyErrorMessage = (
  errorMessage,
  defaultMesage
) => {
  const message = errorMessage.toLowerCase();
  const numberPattern = /\d+/;
  const extractedNumber = message.match(numberPattern)[0];

  if (message.includes("you can generate a maximum of")) {
    return `Maksimum ${extractedNumber} tane aktivasyon kodu oluşturabilirsiniz.`;
  }
  return defaultMesage || "Başarısız işlem";
};
