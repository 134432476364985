import React, { useState, useEffect } from "react";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import SubHeader, {
  SubHeaderLeft,
} from "../../../../layout/SubHeader/SubHeader";
import PracticeTable from "./PracticeTable";
import { adminPages } from "../../../../menu";
import { get, del } from "../../../../utils/service";
import Button from "../../../../components/bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useClearCacheMutation } from "../../../../store/features/apiSlice";

const AdminPractices = () => {
  const [clearCache ] = useClearCacheMutation();
  const navigate = useNavigate();
  const [practices, setPaths] = useState([
    {
      id: 1,
      explanation:
        "Temel Python konular\u0131 ve programlama d\u00fcnyas\u0131na gir\u015f",
      technology: "python",
      is_active: true,
      title: "Python'a Giri\u015f",
      award_point: 500,
    },
  ]);
  const [search, setSearch] = useState("")
  const [filteredPractice, setFilteredPractice] = useState("")

  const deleteItem = (id) => {
    try {
      del("practices/" + id).then(() => handlePosts());
    } catch (error) {
    } finally {
      clearCache();
    }
  };
  
  const handlePosts = async () => {
    const result = await get("practices/");
    setPaths(result.data);
  };

  useEffect(() => {
    handlePosts();
  }, []);

  useEffect(() => {
    filterPractice();
    //eslint-disable-next-line
  }, [search, practices]);

  
  
  const filterPractice = (e) => {
    if (e && e.preventDefault) { e.preventDefault(); }
    const newPractices = practices.filter((item)=>item.title.toLowerCase().includes(search) || item.explanation.toLowerCase().includes(search))
    setFilteredPractice(newPractices)
  }
  
  return (
    <PageWrapper title={adminPages.practice.text}>
      <SubHeader>
        <SubHeaderLeft>
          <div className="text-muted">
            <strong>
              <span className="text-info fw-bold">{"Alıştırmalar"}</span>
            </strong>
          </div>
        </SubHeaderLeft>
      </SubHeader>
      <Page>
        <div className="row">
          <div className="col-3">
            <Button
              className="btn btn-success"
              onClick={() => navigate("/admin/practice-form")}
            >
              Yeni Alıştırma Ekle
            </Button>
          </div>
          <div className="col-6">
            <form className="d-flex gap-1" onSubmit={filterPractice}>
            <input className="form-control" type="search" placeholder="Aramak istediğiniz soruyu yazınız" value={search} onChange={(e)=>setSearch(e.target.value.toLowerCase())}/>
            <button className="btn btn-warning w-25">Bul</button>
          </form> 
          </div>
         
        </div>
        <br />
        <PracticeTable
          practices={practices}
          deleteItem={deleteItem}
          filteredPractice={filteredPractice}
        />
      </Page>
    </PageWrapper>
  );
};

export default AdminPractices;
