import React, { useEffect, useMemo, useState } from 'react';
import { useCoursesQuery, useSingleCourseQuery } from '../../../../../store/features/apiSlice';
import _ from 'lodash';
import SimpleSelectList from '../components/SimpleSelectList';
import SimpleTable from '../components/simple-table/SimpleTable';
import TeacherCard from '../components/teacher-card/TeacherCard';

const LessonList = ({ setCurrentLessons, currentLessons }) => {
  
  const [selectedLesson, setSelectedLesson] = useState(null);
  //TODO remove from store

  const schoolStatusParamForPartner = `school_status=3`;
  const { data: allLessonsForPartner, isLoading:PartnerIsLoading, error:partnerError } = useCoursesQuery(schoolStatusParamForPartner);
  const schoolStatusParamForAdmin = `school_status=1`;
  const { data: allLessonsForAdmin, isLoading, error } = useCoursesQuery(schoolStatusParamForAdmin);
  const allLessons = (allLessonsForPartner && allLessonsForAdmin) && allLessonsForAdmin.concat(allLessonsForPartner)
  const lessons = useMemo(() => {
    if (!isLoading && !error && !PartnerIsLoading && !partnerError && allLessons) {
      return allLessons.filter(lesson => lesson.status === 1);
    }
    return [];
  }, [isLoading, error, PartnerIsLoading,  partnerError, allLessons])

  function setSelectedLessonById(id) {
    const lesson = lessons.find(lesson => lesson.id === id);
    if (lesson) {
      setSelectedLesson({ ...lesson });
    }
  }

  function updateCourses(chapterObj, lessonObj) {

    let courseObj = selectedLesson;
    let courses = _.cloneDeep(currentLessons);

    const existingCourseIndex = courses.findIndex(course => course.id === courseObj.id);

    if (existingCourseIndex !== -1) {
      console.log("lesson exist");

      const existingCourse = _.cloneDeep(courses[existingCourseIndex]);
      const existingChapterIndex = existingCourse.chapters.findIndex(chapter => chapter.id === chapterObj.id);

      if (existingChapterIndex !== -1) {
        console.log("chapter exist",existingChapterIndex)

        const existingChapter = _.cloneDeep(existingCourse.chapters[existingChapterIndex]);
        const existingLessonIndex = existingChapter.lessons.findIndex(lesson => lesson.id === lessonObj.id);

        if (existingLessonIndex !== -1) {
          
          // Lesson already exists, remove it
          courses[existingCourseIndex].chapters[existingChapterIndex].lessons.splice(existingLessonIndex, 1);
          
          if(courses[existingCourseIndex].chapters[existingChapterIndex].lessons.length === 0){
            // Chapter doesn't have any lesson, remove it
            courses[existingCourseIndex].chapters.splice(existingChapterIndex, 1);
          }
          
          if(courses[existingCourseIndex].chapters.length === 0){
            // Course doesn't have any chapter, remove it
            console.log("!!!",courses);
            console.log(existingCourseIndex);
            courses.splice(existingCourseIndex, 1);
          }
        }
        else {
          
          // Lesson doesn't exist, add it
          existingChapter.lessons.push({ id: lessonObj.id, title: lessonObj.title });
          courses[existingCourseIndex].chapters[existingChapterIndex] = existingChapter;
        }
      } else {
        // Chapter doesn't exist, add the chapter with the lesson
        existingCourse.chapters.push({
          id: chapterObj.id,
          title: chapterObj.title,
          lessons: [{ id: lessonObj.id, title: lessonObj.title }]
        });
        courses[existingCourseIndex].chapters = existingCourse.chapters;
      }
    } else {
      // Course doesn't exist, add the course with chapter and lesson
      courses.push({
        id: courseObj.id,
        title: courseObj.title,
        school_status: courseObj.school_status,
        chapters: [
          {
            id: chapterObj.id,
            title: chapterObj.title,
            lessons: [{ id: lessonObj.id, title: lessonObj.title }]
          }
        ]
      });
    }
    console.log("********** courses:", courses);
    
    setCurrentLessons(courses);

  }

  const lessonIds = useMemo(() => {
    let temp = [];
    for (const course of currentLessons) {
      for (const chapter of course.chapters) {
        for (const lesson of chapter.lessons) {
          temp.push(lesson.id);
        }
      }
    }
    return temp;
  },[currentLessons]);

  useEffect(()=>{
    console.log("CURRENT COURSES:", currentLessons);
  },[currentLessons]);
 console.log(currentLessons.map(c=>c.id))
  return (
    <div>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error</p>}
      {!isLoading && !error && (
        <div className='row'>
          <div className="col" style={{minWidth:"250px", minHeight:"150px"}}>
            <TeacherCard>
             {<SimpleTable items={lessons}
                displayKey={'title'}
                onSelect={setSelectedLessonById}
                selectedItems={currentLessons.map(c=>c.id)}
                selectedItemId={selectedLesson?.id} />}

            </TeacherCard>
          </div>
          {selectedLesson && <ChapterList setChapter={updateCourses} selectedLessons={lessonIds} selectedLesson={selectedLesson} />}
        </div>
      )
      }
    </div>
  );
};

function ChapterList({ setChapter, selectedLessons, selectedLesson }) {

  const { data: singleCourse} = useSingleCourseQuery(selectedLesson.id);

  const [selectedChapter, setSelectedChapter] = useState(null);
  const [lessons, setLessons] = useState(null);
  const [chapterList, setChapterList] = useState(null);


  function selectChapter(id) {
    //find chapter by id
    let chapter = chapterList.find(chapter => chapter.id === id);
    if (chapter != null)
      setSelectedChapter(chapter);
  }
  function setLessonWithChapter(id) {
    var lesson = selectedChapter.lessons.find(lesson => Number(lesson.id) === Number(id));
    setChapter(selectedChapter, lesson);
  }

  useEffect(() => {
    if (selectedChapter) {
      setLessons(selectedChapter.lessons);
    }
  }, [selectedChapter])

  useEffect(() => {
    if (singleCourse) {
      setChapterList(singleCourse.chapters);
      setSelectedChapter(singleCourse.chapters[0]);
    }
  }, [singleCourse])

  return (
    <>
      <div className="col" style={{minWidth:"250px"}}>

        {chapterList &&
          <TeacherCard>
            <SimpleTable
              onSelect={selectChapter}
              selectedItems={[selectedChapter?.id]}
              selectedItemId={selectedChapter?.id}
              displayKey={'title'}
              items={chapterList} />
          </TeacherCard>
        }

      </div>
      <div className='col' style={{minWidth:"250px"}}>
        <TeacherCard>
        {selectedChapter && <SimpleSelectList
          items={lessons}
          onSelect={(id) => setLessonWithChapter(id)}
          selectedItems={selectedLessons}
          selectKey={'id'}
          displayKey={'title'} />}
        </TeacherCard>
      </div>
    </>
  )

}

export default LessonList;