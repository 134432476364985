import React from "react";
import MDEditor from '@uiw/react-md-editor';
import {
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../../components/bootstrap/Card";

const TestTable = ({ practice }) => {
  // console.log("Test Table practice:",practice);
  const { title, explanation, language} =   practice;
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <CardHeader className="px-0 bg-transparent">
            <CardLabel className="mx-auto">
              <CardTitle className="text-capitalize h3">{title?title:""}</CardTitle>
            </CardLabel>
          </CardHeader>
          <CardBody className="d-flex flex-column gap-3">
            <h3 className="text-capitalize">{language ? language:""}</h3>
            <MDEditor.Markdown className="practice-detail-body"  source={explanation ? explanation:""} />

            {/* <h3 className="text-capitalize">{explanation ? explanation:""}</h3> */}
          </CardBody>
        </div>
      </div>
    </div>
  );
};

export default TestTable;
