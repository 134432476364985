import React from "react";
import style from "./BadgeCard.module.scss";
import Modal, {
  ModalBody,
} from "../../../../../../../components/bootstrap/Modal";
import kesifciCard from "../../../../../../../assets/badges/badgeCards/kesifciCard.svg";
import closeButton from "../../../../../../../assets/badges/badgeCards/closeButton.svg";
import badgeCardTopLeftIcon from "../../../../../../../assets/badges/badgeCards/badgeCardTopLeftIcon.svg";
import badgeCardRightIcon from "../../../../../../../assets/badges/badgeCards/badgeCardRightIcon.svg";
import badgeCardBottomIcon from "../../../../../../../assets/badges/badgeCards/badgeCardBottomIcon.svg";
import { useMediaQuery } from "react-responsive";

const BadgeCardModal = ({ isOpen, setIsOpen, badge }) => {
  const isMobile = useMediaQuery({ maxWidth: 420 });
  if (!isOpen || !badge) return null;
  const badgeNameUpperCase = badge.name.toLocaleUpperCase('tr-TR');
  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      titleId="example-title"
      size={"xs"}
      customWidth={`${isMobile ? null : "400px"}`}
    >
      <ModalBody>
        <div className={`${style.badgeCard} mx-auto`} style={{ opacity:`${!(badge.is_earned) ? "0.3" : "1"}` }}>
          <div className={`${style.closeButtonDiv} d-flex justify-content-end p-2`}>
            <img src={closeButton} alt="" onClick={()=>setIsOpen(false)}/>
          </div>
          <div className={`${style.badgeTitle} mt-2`}>{badgeNameUpperCase}</div>
          <div className={`${style.BadgeImg}`}>
            <img src={badge.avatar} alt="" />
          </div>
          <div className={`${style.badgeDescription} px-5`}><p>{badge.desc}</p></div>
          <div className={`${style.icons}`}>
          <img className={`${style.icon1}`} src={badgeCardTopLeftIcon} alt="" />
          <img className={`${style.icon2}`} src={badgeCardRightIcon} alt="" />
          <img className={`${style.icon3}`} src={badgeCardBottomIcon} alt="" />
          <img className={`${style.floorIcon}`} src={kesifciCard} alt="" />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BadgeCardModal;
