import React, { useState } from "react";
import style from "./Students.module.scss";
import Card from "../../../../../components/bootstrap/Card";
import { useNavigate, useParams } from "react-router-dom";
import { nanoid } from "nanoid";
import PaginationButtons, {
  dataPagination,
} from "../../../../../components/PaginationButtons";
import starIcon from "../../../../../assets/school/starIcon.svg";
import ConfirmButton from "../../../teacher/components/confirm-button";
import WithoutClassStudentsModal from "./without-class-students/WithoutClassStudentsModal";
import {
  useGetClassStudentQuery,
  useRemoveStudentFromClassMutation,
} from "../../../teacher/teacherApi";
import Confirm from "../../../../common/Confirm";
import { useClearCacheMutation } from "../../../../../store/features/apiSlice";
import Icon from "../../../../../components/icon/Icon";

const Students = () => {
  const navigate = useNavigate();
  const { classId } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const { data: students } = useGetClassStudentQuery({id:classId, page:`page=${currentPage}`, page_size:`page_size=${perPage}`});
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [isOpenStudentModal, setIsOpenStudentModal] = useState(false);
  const items = students ? students.results : [];

  const [removeStudentFromClass] = useRemoveStudentFromClassMutation();
  const [clearCache] = useClearCacheMutation();
  //methods
  const addStudent = () => {
    setIsOpenStudentModal(true);
  };

  function handleRemoveStudentFromClass(id) {

    removeStudentFromClass({
      class_id: classId,
      student_in_class_id: id,
    });
    setOpenConfirmModal(false);
    clearCache();
  }
  return (
    <Card className={`${style.cardContainer}`}>
      <Confirm
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        onConfirm={handleRemoveStudentFromClass}
        onCancel={() => setOpenConfirmModal(false)}
      />
      <WithoutClassStudentsModal
        isOpenStudentModal={isOpenStudentModal}
        setIsOpenStudentModal={setIsOpenStudentModal}
        classId={classId}
      />
      <div className={`${style.title} pt-2 my-2`}>
        <span className={`ms-5`}>Öğrenciler</span>
      </div>
      <div className={`${style.customTable} px-4`}>
      <div
          className={`${style.addStudentContainer} d-flex justify-content-end`}
        >
          
          <span onClick={() => addStudent()}><Icon icon="PersonAdd" color="info" size="lg" className="me-1" />Öğrenci Ekle</span>
        </div>
        <div
          className={`${style.customHeadContainer} d-flex align-items-center justify-content-between my-1 px-2`}
        >
          <div className={`${style.customTableHead1} ${style.customTableHead}`}>
            Ad Soyad
          </div>
          <div className={`${style.customTableHead2} ${style.customTableHead}`}>
            Puan
          </div>
          <div className={`${style.customTableHead3} ${style.customTableHead}`}>
            Sınıftan Çıkar
          </div>
        </div>

        <div className={`${style.customRowContainer}`}>
          {items && items.length === 0 ? (
            <div className={`${style.emptyTable}`}>
              <span>Öğrenci bulunamadı</span>
            </div>
          ) : (
            items &&
            dataPagination(items, currentPage, perPage).map((item, i) => {
              const userName = (item.first_name && item.first_name !== null) ? `${item.first_name} ${item.last_name}` : item.email.split("@")[0]
              return(
              <div
                key={nanoid()}
                className={`${style.customTableRow} d-flex align-items-center justify-content-between mb-2 px-2`}
              >
                <div
                  className={`${style.customTableColumn1} ${style.tableColumn}`}
                >
                  <div
                    className="cursor-pointer"
                    onClick={() => navigate(`/users/information/${item.user_public_id}`)}
                  >
                    <div className={`${style.studentNameContainer}`}>
                      <div className={`${style.studentNameShort}`}>
                        {userName}
                      </div>
                      <div className={`${style.studentNameFull}`}>
                        {userName}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`${style.customTableColumn2} ${style.tableColumn}`}
                >
                  <div className="d-flex align-items-center gap-1">
                    <div>
                      <img src={starIcon} alt="" />
                    </div>
                    <div>{item.class_points}</div>
                  </div>
                </div>

                <div
                  className={`${style.customTableColumn3} ${style.tableColumn}`}
                >
                  <div
                    className="d-flex justify-content-center mx-auto"
                    onClick={() => {
                      //  setClassId(item.class_field);
                    }}
                  >
                    {/* Eylemler */}
                    <ConfirmButton
                      handleClick={handleRemoveStudentFromClass}
                      parameter={item.id}
                    />
                  </div>
                </div>
              </div>
            )})
          )}
        </div>
      </div>
      <div className={`${style.paginationButtons}`}>
        <PaginationButtons
          data={items}
          label="öğrenci"
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </div>
    </Card>
  );
};

export default Students;
