import React from "react";
import style from "./FollowingLessons.module.scss";
import rightArrow from "../../../../../../src/assets/dashboardImages/rightArrow.svg";
import check2 from "../../../../../../src/assets/dashboardImages/check2.png";
import { useNavigate } from "react-router-dom";

const SingleTopic = ({ topicName, rate, id }) => {
  const navigate = useNavigate();

  function handleLesson() {
    if (rate !== 100)
      navigate(`/lessons/AllLessonsAndContent/${id}`);
  }
  return (
    <div className={`${style.singleTopicContainer}`}>
      <div className={`${style.topicName}`}>{topicName}</div>
      <div className={`${style.followingLessonsItem} d-flex justify-content-between`}>
        <div
          className={`${style.progress_bar} text-center`}
          style={{ width: `${rate}%` }}
        >
          {" "}
          <span>{rate}%</span>
        </div>
        {rate === 100 ? 
        <div className={`${style.logoDiv1}`}>
        <img src={check2} alt="" />
      </div> :
      <div className={`${style.logoDiv}`} onClick={() => handleLesson()}>
      <img src={rightArrow} alt="" />
    </div>}
        
      </div>
    </div>
  );
};

export default SingleTopic;
