import { useFormik } from "formik";
import Button from "../../../components/bootstrap/Button";
import Card, {
  CardBody,
  CardFooter,
  CardFooterLeft,
  CardFooterRight,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../components/bootstrap/Card";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import showNotification from "../../../components/extras/showNotification";
import Icon from "../../../components/icon/Icon";
import {
  useEnterActivationCodeByStudentMutation,
  useGetActivationCodeForStudentQuery,
  useGetStudentSubscribedClassesQuery,
} from "../../../store/features/apiSlice";
import { useEffect, useState } from "react";
import ActivationInfoModal from "./ActivationInfoModal";
import USER_SUBSCRIPTION_TYPE from "../../../common/data/enumUserSubscriptionType";
import { formatDate2TurkishShortDate } from "../../../utils/formatDate";

const ActivationKey = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const school = localStorage.getItem("school");
  const { data } = useGetStudentSubscribedClassesQuery();
  const [sendActivationCode] = useEnterActivationCodeByStudentMutation();
  const subscribedStudent =
    school > 0 || (data && Object.keys(data).length !== 0);
  const { data: activationCode, isSuccess } =
    useGetActivationCodeForStudentQuery(undefined, { skip: !school });

  const subscription_expiration_date = localStorage.getItem(
    "subscription_expiration_date"
  );
  const subscriptionType = localStorage.getItem("subscription_type");
  const isFree = Number(subscriptionType) === USER_SUBSCRIPTION_TYPE.FREE.id;
  const isStandart =
    Number(subscriptionType) === USER_SUBSCRIPTION_TYPE.STANDART.id;

  useEffect(() => {
    if (isSuccess && activationCode) {
      if (activationCode.code) {
        formik.setFieldValue("code", activationCode.code);
      } else {
        formik.setFieldValue("code", "");
      }
    }
    //eslint-disable-next-line
  }, [isSuccess, activationCode]);

  const validate = (values) => {
    const errors = {};
    if (!values.code) {
      errors.code = "Aktivasyon Kodunu Giriniz";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validate,
    onSubmit: () => {
      handleActivationCode();
    },
  });
  const handleActivationCode = async () => {
    if (subscribedStudent) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="warning" size="lg" className="me-1" />
          {school > 0 ? (
            <span>
              Aktif olarak bir kuruma kayıtlısınız. Kurum değişikliği yapmak
              için lütfen aktif kurumunuzla iletişime geçiniz.
            </span>
          ) : (
            <span>Yeni Hesap Oluşturun</span>
          )}
        </span>,
        "Uyarı",
        "warning"
      );
    } else {
      const codeValues = { ...formik.values };
      const response = await sendActivationCode({
        ...codeValues,
      });
      if (response.error) {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="danger" size="lg" className="me-1" />
            <span>Girdiğiniz aktivasyon kodu geçerli değil</span>
          </span>,
          "Bir hata oluştu",
          "danger"
        );
      } else {
        setMessage(response.data.message);
        setOpen(true);
      }
      formik.resetForm();
      // setIsOpenModal(false);
    }
  };
  const handleCancel = () => setOpen(false);
  return (
    <>
      <ActivationInfoModal
        open={open}
        setOpen={setOpen}
        onCancel={handleCancel}
        message={message}
      />
      <Card stretch tag="form" noValidate onSubmit={formik.handleSubmit}>
        <CardHeader>
          <CardLabel icon="LocalPolice" iconColor="success">
            <CardTitle>Aktivasyon Kodu Gönder</CardTitle>
          </CardLabel>
        </CardHeader>
        <CardBody className="pb-0" isScrollable>
          <div className="row g-4">
            <div className="col-12">
              <FormGroup id="code" label="Aktivasyon Kodu" isFloating>
                <Input
                  name="code"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.code}
                  isValid={formik.isValid}
                  isTouched={formik.touched.code}
                  invalidFeedback={formik.errors.code}
                  readOnly={formik.values.code?.length > 11 ? true : false}
                />
              </FormGroup>
            </div>
          </div>
          <div>
            <p className={`d-${isFree && "none"} text-center mt-5`}>
              Hesabınızı{" "}
              {isStandart ? (
                <strong>STANDART</strong>
              ) : (
                <strong>PREMIUM</strong>
              )}{" "}
              olarak{" "}
              {
                <strong>
                  {formatDate2TurkishShortDate(subscription_expiration_date)}
                </strong>
              }{" "}
              tarihine kadar kullanabilirsiniz.
            </p>
          </div>
        </CardBody>
        <CardFooter>
          <CardFooterLeft>
            <Button color="info" isLink type="reset" onClick={formik.resetForm}>
              Sıfırla
            </Button>
          </CardFooterLeft>
          <CardFooterRight>
            <Button
              type="submit"
              icon="Save"
              color="info"
              isOutline
              // isDisable={
              //     !formik.isValid && !!formik.submitCount
              // }
            >
              Gönder
            </Button>
          </CardFooterRight>
        </CardFooter>
      </Card>
    </>
  );
};

export default ActivationKey;
