import React, { useEffect, useState } from 'react';
import Button from "../../../components/bootstrap/Button";
import Card, {
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from "../../../components/bootstrap/Card";
import Icon from "../../../components/icon/Icon";
import Checks from "../../../components/bootstrap/forms/Checks";
import style from "./profilPage.module.scss";
import USER_SUBSCRIPTION_TYPE from "../../../common/data/enumUserSubscriptionType";
import { useDispatch, useSelector } from "react-redux";
import { setPremiumMounthly, setPremiumPlan, setStandartMounthly, setStandartPlan, setTotalPrice } from "../../../store/paymentSlice";
import USER_PAYMENT_PLAN_TYPE from "../../../common/data/enumUserPaymentPlanType";
// import { formatDate2TurkishShortDate } from '../../../utils/formatDate';

const AccountDetail = () => {
    const [first, setFirst] = useState(true)

    const dispatch = useDispatch();
    // const subscription_expiration_date = localStorage.getItem("subscription_expiration_date")
    const subscriptionType = localStorage.getItem("subscription_type")
    const isFree = Number(subscriptionType) === USER_SUBSCRIPTION_TYPE.FREE.id
    const isStandart = Number(subscriptionType) === USER_SUBSCRIPTION_TYPE.STANDART.id
    const isPremium = Number(subscriptionType) === USER_SUBSCRIPTION_TYPE.PREMIUM.id
    const schoolId = localStorage.getItem("school");
    const isSchoolId = schoolId > 0;

    const { standartMounthly, premiumMounthly, totalPrice} = useSelector(
        (store) => store.payment
    );
    const handleChangePremium = () => {
        dispatch(setPremiumMounthly(!premiumMounthly));
    };

    const addPremiumPrice = () => {
        dispatch(setPremiumPlan(true))
        dispatch(setStandartPlan(false))
        if (!premiumMounthly) {
            dispatch(setTotalPrice(USER_PAYMENT_PLAN_TYPE.PREMIUMMOUNTHLY.newPrice))
            localStorage.setItem("lastTotalPrice", USER_PAYMENT_PLAN_TYPE.PREMIUMMOUNTHLY.newPrice);
        } else {
            dispatch(setTotalPrice(USER_PAYMENT_PLAN_TYPE.PREMIUMYEARLY.newPrice))
            localStorage.setItem("lastTotalPrice", USER_PAYMENT_PLAN_TYPE.PREMIUMYEARLY.newPrice);
        }
    };
    const handleChangeStandart = () => {
        dispatch(setStandartMounthly(!standartMounthly));
    };
    const addStandartPrice = () => {
        dispatch(setPremiumPlan(false))
        dispatch(setStandartPlan(true))
        if (standartMounthly) {
            dispatch(setTotalPrice(USER_PAYMENT_PLAN_TYPE.STANDARTYEARLY.newPrice))
            localStorage.setItem("lastTotalPrice", USER_PAYMENT_PLAN_TYPE.STANDARTYEARLY.newPrice);
        } else {
            dispatch(setTotalPrice(USER_PAYMENT_PLAN_TYPE.STANDARTMOUNTHLY.newPrice))
            localStorage.setItem("lastTotalPrice", USER_PAYMENT_PLAN_TYPE.STANDARTMOUNTHLY.newPrice);
        }
    };
    useEffect(() => {
        localStorage.setItem("lastTotalPrice", totalPrice);
        //eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (!first)
            addStandartPrice();
        //eslint-disable-next-line
    }, [standartMounthly]);

    useEffect(() => {
        if (!first)
            addPremiumPrice();
        //eslint-disable-next-line
    }, [premiumMounthly]);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setFirst(false)
        }, 1000);
        return () => clearTimeout(timeout);
    }, [])
    return (
        <Card
            stretch
            tag="form"
            noValidate

        >
            <CardHeader>
                <CardLabel icon="Contacts" iconColor="info">
                    <CardTitle>Hesap Bilgileri</CardTitle>
                </CardLabel>
            </CardHeader>
            <CardBody className="pb-0 pt-0">
                <div>
            <p className={`d-${isFree && "none"} text-center`}>
                    Hesabınızı {isStandart ? <strong>STANDART</strong> : <strong>PREMIUM</strong>} olarak kullanabilirsiniz.
                </p>
                </div>

               { !isSchoolId && <div className="row g-4 d-flex justify-content-center">
                    <div className={`${!isFree && "d-none"} col-xxl-4 col-xl-6`}>
                        <Card stretch
                            borderColor={`${isFree ? "primary" : "light"}`}
                            borderSize={4}
                            shadow="lg">
                            <CardBody>
                                <div className="row pt-5 g-4 align-items-center">
                                    <div className="col-auto">
                                        <Icon
                                            icon="CustomRocketLaunch"
                                            size="5x"
                                            color="warning"
                                        />
                                    </div>
                                    <div className="col">
                                        <h2>Free</h2>
                                    </div>
                                    <div style={{ height: "90px" }}></div>
                                    <div className="col-12">
                                        <div className="lead">
                                            <Icon icon="Done Outline" color="success" />{" "}
                                            Temel seviyede öğrenim için ihtiyacınız olan
                                            herşey
                                        </div>
                                        <div className="lead">
                                            <Icon icon="Done Outline" color="success" /> 50
                                            den fazla ders içeriği
                                        </div>
                                        <div className="lead">
                                            <Icon icon="Done Outline" color="success" />{" "}
                                            Ebeveyn kontrol paneli
                                        </div>
                                        <div className="lead">
                                            <Icon icon="Done Outline" color="success" />{" "}
                                            Canlı Destek
                                        </div>
                                    </div>

                                    <div style={{ height: "25px" }}></div>
                                    <div className="col-12">
                                        <Button
                                            color="warning"
                                            isLight
                                            className="w-100 py-3 text-uppercase"
                                            size="lg"
                                        >
                                            {`${isFree ? "planınız" : "ücretsiz plan"}`}
                                        </Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className={`${isPremium && "d-none"} col-xxl-4 col-xl-6`}>
                        <Card
                            stretch
                            borderColor={`${isStandart ? "primary" : "light"}`}
                            borderSize={4}
                            shadow="lg"
                        >
                            <CardBody>
                                <div className="row pt-5 g-4 align-items-center">
                                    <div className="col-auto">
                                        <Icon
                                            icon="Maps Home Work"
                                            size="5x"
                                            color="primary"
                                        />
                                    </div>
                                    <div className="col">
                                        <h2>Standart</h2>
                                    </div>
                                    <div
                                        className={`fs-4 d-flex justify-content-start`}
                                    >
                                        <div>
                                            <span>Aylık</span>
                                        </div>
                                        <div className="ps-2">
                                            <Checks
                                                type="switch"
                                                value={standartMounthly}
                                                checked={standartMounthly}
                                                onChange={() => handleChangeStandart()}
                                                style={{ backgroundColor: `${standartMounthly ? "#6C5DD3" : "pink"}` }} />
                                        </div>

                                        <div>
                                            <span>Yıllık</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        {!standartMounthly ?
                                            <h3 className="h3 fw-bold">
                                                <span className="h3">₺</span>{USER_PAYMENT_PLAN_TYPE.STANDARTMOUNTHLY.newPrice}/Aylık
                                                <span
                                                    className="h3 ms-3 fw-bold"
                                                    style={{ textDecoration: "line-through" }}
                                                >
                                                    ₺{USER_PAYMENT_PLAN_TYPE.STANDARTMOUNTHLY.oldPrice}
                                                </span>
                                            </h3>
                                            :
                                            <h3 className="h3 fw-bold">
                                                <span className="h4">₺</span>{USER_PAYMENT_PLAN_TYPE.STANDARTYEARLY.newPrice}/Yıllık
                                                <span
                                                    className="h3 ms-3 fw-bold"
                                                    style={{ textDecoration: "line-through" }}
                                                >
                                                    ₺{USER_PAYMENT_PLAN_TYPE.STANDARTYEARLY.oldPrice}
                                                </span>
                                            </h3>}

                                    </div>
                                    <div className="col-12">
                                        <div className="lead">
                                            <Icon icon="Done Outline" color="success" /> 200
                                            den fazla etkileşimli video ders içeriği
                                        </div>
                                        <div className="lead">
                                            <Icon icon="Done Outline" color="success" />{" "}
                                            50'den fazla alıştırma
                                        </div>
                                        <div className="lead">
                                            <Icon icon="Done Outline" color="success" />{" "}
                                            CodeArt & CodeÇizz Serbest çalışma
                                        </div>
                                        <div className="lead">
                                            <Icon icon="Done Outline" color="success" />{" "}
                                            Kolaydan zora eğitici oyunlar
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <Button
                                            color="primary"
                                            className="w-100 py-3 text-uppercase"
                                            size="lg"
                                            tag="a"
                                            to={`/payment`}
                                            onClick={() => addStandartPrice()}
                                            isDisable={isStandart ? true : false}
                                        >
                                            {`${isStandart ? "planınız" : "planı seç"}`}
                                        </Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-xxl-4 col-xl-6">
                        <Card
                            stretch
                            borderColor={`${isPremium ? "primary" : "light"}`}
                            borderSize={4}
                            shadow="lg"
                        >
                            <CardBody>
                                <div className="row pt-5 g-4 align-items-center">
                                    <div className="col-auto">
                                        <Icon
                                            icon="CustomFactory"
                                            size="5x"
                                            color="info"
                                        />
                                    </div>
                                    <div className="col">
                                        <h2>Talent14 Premium</h2>
                                    </div>
                                    <div
                                        className={`fs-4 d-flex justify-content-start`}
                                    >
                                        <div>
                                            <span>Aylık</span>
                                        </div>
                                        <div className="ps-2">
                                            <Checks
                                                className={`${style.standartCheck}`}
                                                type="switch"
                                                value={premiumMounthly}
                                                checked={premiumMounthly}
                                                onChange={() => handleChangePremium()}
                                                style={{ backgroundColor: `${premiumMounthly ? "#6C5DD3" : "#E4FF70"}` }}
                                            />
                                        </div>

                                        <div>
                                            <span>Yıllık</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        {!premiumMounthly ?
                                            <h3 className="h3 fw-bold">
                                                <span className="h3">₺</span>{USER_PAYMENT_PLAN_TYPE.PREMIUMMOUNTHLY.newPrice}/Aylık
                                                <span
                                                    className="h3 ms-3 fw-bold"
                                                    style={{ textDecoration: "line-through" }}
                                                >
                                                    ₺{USER_PAYMENT_PLAN_TYPE.PREMIUMMOUNTHLY.oldPrice}
                                                </span>
                                            </h3>
                                            :
                                            <h3 className="h3 fw-bold">
                                                <span className="h3">₺</span>{USER_PAYMENT_PLAN_TYPE.PREMIUMYEARLY.newPrice}/Yıllık
                                                <span
                                                    className="h3 ms-2 fw-bold"
                                                    style={{ textDecoration: "line-through" }}
                                                >
                                                    ₺{USER_PAYMENT_PLAN_TYPE.PREMIUMYEARLY.oldPrice}
                                                </span>
                                            </h3>
                                        }
                                    </div>
                                    <div className="col-12">
                                        <div className="lead">
                                            <Icon icon="Done Outline" color="success" /> Tüm
                                            içeriklere sınırsız erişim
                                        </div>
                                        <div className="lead">
                                            <Icon icon="Done Outline" color="success" /> 800
                                            den fazla etkileşimli video ders içeriği
                                        </div>
                                        <div className="lead">
                                            <Icon icon="Done Outline" color="success" />{" "}
                                            CodeArt & CodeÇizz Serbest Çalışma ve
                                            Alıştırmalar
                                        </div>
                                        <div className="lead">
                                            <Icon icon="Done Outline" color="success" />{" "}
                                            Kolaydan zora eğitici oyunlar
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <Button
                                            color="info"
                                            isLight
                                            className="w-100 py-3 text-uppercase"
                                            size="lg"
                                            tag="a"
                                            to={`/payment`}
                                            onClick={() => addPremiumPrice()}
                                            isDisable={isPremium ? true : false}
                                        >
                                            {`${isPremium ? "planınız" : "planı seç"}`}
                                        </Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>}
            </CardBody>
        </Card>
    );
};

export default AccountDetail;