import React from "react";
import CodeRunResult from "./result/CodeRunResult";
import Card, {
  CardActions,
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../../components/bootstrap/Card";

function PracticeResult({
  submitPractice,
  practice,
  userCode,
  path_id,
  codeRunResult,
}) {
  return (
    <div>
      {/* <Grid container spacing={2} p={2}>
            <Grid item xs={12}>
                <Button
                  variant="outlined" onClick={() => submitPractice(practice.id, path_id, userCode)}>
                  KODU ÇALIŞTIR
                </Button>
            </Grid>
            <Grid item xs={12}>
              <div>
                {codeRunResult &&
                  <CodeRunResult codeRunResult={codeRunResult} />}
              </div>
            </Grid>
            <Grid item></Grid>
          </Grid> */}
      <Card stretch={true}>
        <CardHeader borderSize={1}>
          <CardLabel iconColor="info">
            <CardTitle>Sonuç Paneli</CardTitle>
          </CardLabel>
          <CardActions>
            <button
            onClick={() => submitPractice(practice.id, userCode)}
              type="button"
              className="btn btn-outline-success btn-lg btn-hover-shadow-lg shadow-none rounded-2"
            >
              KODU ÇALIŞTIR
            </button>
          </CardActions>
        </CardHeader>
        <CardBody className="table-responsive" isScrollable={false}>
          {codeRunResult &&
                  <CodeRunResult codeRunResult={codeRunResult} />}
        </CardBody>
      </Card>
    </div>
  );
}

export default PracticeResult;
