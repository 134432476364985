import React from "react";
import SinglePath from "../components/1-SinglePath";
import { nanoid } from "nanoid";
import style from "./PathWithoutClass.module.scss";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";

const PathsWithoutClass = ({trails, isLoadingTrail}) => {
  // mutations and queries
  const data = (trails && trails.length > 0) && trails.filter((item)=>item.courses.length > 0)
  
  if(isLoadingTrail){
    return <div>Yükleniyor...</div>
  }
  return (
    <PageWrapper>
      <Page>
    <div className={`${style.pathsContainer} d-flex flex-column mx-2`}>
      {data && data?.map((path) => (
        <SinglePath path={path} key={nanoid()} />
      ))}
    </div>
    </Page>
    </PageWrapper>
  );
};

export default PathsWithoutClass;
