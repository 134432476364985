import React, { useEffect, useState } from "react";
import style from "./StudentWithClass.module.scss";
import defaultSchool from "../../../../../assets/school/defaultSchoolLogo.png";
import bannerStudent from "../../../../../assets/dashboardImages/bannerStudent.png";
import Spinner from "../../../../../components/bootstrap/Spinner";
import { getSchoolLogo } from "../../../schoolManager/dashboard/school-manager-header/SchoolManagerHeader";
import Announcements from "../banner and announcements/announcements/Announcements";

function capitalize(word) {
  if (word) {
    const lower = word.toLowerCase();
    return word.charAt(0).toUpperCase() + lower.slice(1);
  }
  return;
}

const ClassInfo = ({ studentClassName, teacherFulName, schoolName }) => {
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [open, setOpen] = useState(false);
  const schoolId = localStorage.getItem("school");
  const name = localStorage.getItem("name");
  const surname = localStorage.getItem("surname");
  const email = localStorage.getItem("email");
  const userFulName =
    name && name !== "null"
      ? capitalize(name) + " " + capitalize(surname)
      : email.split("@")[0];
  const spinner = <Spinner color="primary" style={{ marginTop: "20px" }} />;

  const [schoolLogo, setSchoolLogo] = useState(null);
  useEffect(() => {
    async function fetchData() {
      const logoUrl = await getSchoolLogo(schoolId);
      if (logoUrl) {
        setSchoolLogo(logoUrl);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    fetchData();
  }, [schoolId]);
  const notificationsCount = parseInt(localStorage.getItem("newNotifications") || '0', 10);
  useEffect(() => {
    if (notificationsCount > 0) {
      setOpen(true);
    }else{
      setOpen(false)
    }
  }, [notificationsCount]);

  return (
    <>
      {/* <UserInfoModal open={open} setOpen={setOpen} /> */}
      <div
        className={`${style.schoolContainer} my-3 d-flex align-items-center justify-content-between`}
      >
        <img className={`${style.banner}`} src={bannerStudent} alt="" />
        <div className={`${style.announcementIcon}`}>
          <Announcements/>
        </div>
        <div
          className={`${style.leftSection} d-flex justify-content-center align-items-start flex-column gap-2`}
        >
          <div className={`${style.classDetail}`}>
            <div className={`${style.classTitleContainer}`}>
              <div className={`${style.classTitleShort}`}>
                {studentClassName}
              </div>
              <div className={`${style.classTitleFull}`}>
                {studentClassName}
              </div>
            </div>
            <div className={`${style.line}`}></div>
            <div className={`${style.teacherTitleContainer}`}>
              <div className={`${style.teacherTitleShort}`}>
                {teacherFulName}
              </div>
              <div className={`${style.teacherTitleFull}`}>
                {teacherFulName}
              </div>
            </div>
          </div>
          <div className={`${style.studentTitleContainer}`}>
            <div className={`${style.studentTitleShort}`}>{userFulName}</div>
            <div className={`${style.studentTitleFull}`}>{userFulName}</div>
          </div>
        </div>
        {schoolId > 0 && (
          <div className={`${style.rightSection}`}>
            {loading ? (
              spinner
            ) : (
              <div className={`${style.schoolLogo}`}>
                {!schoolLogo ? (
                  <img src={defaultSchool} alt="" />
                ) : (
                  <img
                    src={schoolLogo}
                    alt=""
                    width={64}
                    height={64}
                    className="mx-auto d-block"
                  />
                )}
              </div>
            )}
            <div className={`${style.schoolNameContainer}`}>
              <div className={`${style.schoolNameShort}`}>{schoolName}</div>
              <div className={`${style.schoolNameFull}`}>{schoolName}</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ClassInfo;
