import React from "react";
import style from "./Notification.module.scss";
import { getDayMonthYearAsString } from "../../../../../../utils/formatDate";
import Icon from "../../../../../../components/icon/Icon";

const Notification = ({ title, description, date, isNew }) => {
  return (
    <div className={`${style.notification} ${isNew && style.newNotification}`}>
      <div className={`${style.notificationContainer}`}>
      <Icon icon={"info"} size="3x" color="success"></Icon>
      <div className={`${style.notificationDetail}`}>
      <div className={`${style.notificationHeader}`}>
        <h4 className={`${style.notificationTitle}`}>{title}</h4>
        <span className={`${style.notificationDate}`}>{getDayMonthYearAsString(date)}</span>
      </div>
      <div className={`${style.notificationBody}`}>
        <p className={`${style.notificationDescription}`}>{description}</p>
      </div>
      </div>
      </div>
    </div>
  );
};

export default Notification;
