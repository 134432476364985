// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Calendar_calendar__2WkHG {\n  height: 210px;\n}\n\n@media screen and (max-width: 500px) {\n  .Calendar_calendarCard__YUXAk {\n    position: relative;\n    bottom: 10%;\n  }\n  .Calendar_calendarCard__YUXAk .Calendar_title__\\+IZRR {\n    font-size: 16px !important;\n  }\n  .Calendar_calendarCard__YUXAk .Calendar_calendar__2WkHG {\n    height: 100px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/t14/student/dashboard/calendar/Calendar.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AACA;EACI;IACJ,kBAAA;IACA,WAAA;EAEE;EADM;IACI,0BAAA;EAGV;EADM;IACI,aAAA;EAGV;AACF","sourcesContent":[".calendar{\n    height: 210px;\n}\n@media screen and (max-width:500px) {\n    .calendarCard{\nposition: relative;\nbottom: 10%;\n        .title{\n            font-size: 16px !important;\n        }\n        .calendar{\n            height: 100px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendar": "Calendar_calendar__2WkHG",
	"calendarCard": "Calendar_calendarCard__YUXAk",
	"title": "Calendar_title__+IZRR"
};
export default ___CSS_LOADER_EXPORT___;
