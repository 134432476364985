import React from "react";
import style from "./Rewards.module.scss";
import Page from "../../../../../layout/Page/Page";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import {
  useUserBadgesStudentQuery,
} from "../../../../../store/features/apiSlice";
import DashboardHeader from "../../../../common/Headers/DashboardHeader";
import RewardLigCard from "./rewardCards/RewardLigCard";
import BadgeContainer from "./badgeContainer/BadgeContainer";

const Rewards = () => {
  const { data, isLoading } = useUserBadgesStudentQuery();
  const earnedBadgeCount = data && data.filter((item)=>item.is_earned === true).length;
  const badges = data && data;
  let activeLig;
  console.log(badges);
  if (earnedBadgeCount < 4) {
    activeLig = null;
  } else if (earnedBadgeCount >= 4 && earnedBadgeCount < 8) {
    activeLig = "bronze";
  } else if (earnedBadgeCount > 7 && earnedBadgeCount < 12) {
    activeLig = "silver";
  } else if (earnedBadgeCount > 11) {
    activeLig = "gold";
  } else {
    console.log(earnedBadgeCount);
  }
  // useEffect(() => {
  //   if (window.Tawk_API) {
  //     window.Tawk_API.hideWidget();
  //   }
  //   return () => {
  //     if (window.Tawk_API) {
  //       window.Tawk_API.showWidget();
  //     }
  //   };
  // }, []);
  if(isLoading){
    return <div>Yükleniyor...</div>
  }
  return (
    <PageWrapper>
      <DashboardHeader />
      <Page>
        <div className={`${style.rewards} row h-100 justify-content-center gap-5`}>
          <div className="d-flex flex-column justify-content-center gap-5 col-12 col-xl-5">
          <RewardLigCard lig={"bronze"} activeLig={activeLig}/>
          <RewardLigCard lig={"silver"} activeLig={activeLig}/>
          <RewardLigCard lig={"gold"} activeLig={activeLig}/>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center gap-5 col-5">
            <div className="align-self-center align-self-md-start">
            <BadgeContainer floor="first" badges={badges}/>
            </div>
            <div className="align-self-center align-self-md-end">
            <BadgeContainer floor="second" badges={badges}/>
            </div>
            <div className="align-self-center align-self-md-start">
            <BadgeContainer floor="third" badges={badges}/>
            </div>
            <div className="align-self-center align-self-md-end">
            <BadgeContainer floor="fourth" badges={badges}/>
            </div>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default Rewards;
