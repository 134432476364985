import React from "react";
import style from "../ParentReportWithClass.module.scss";
import { nanoid } from "nanoid";
import ChartInfo from "./ChartInfo";
import { Card } from "react-bootstrap";
import HomeworkInfo from "./HomeworkInfo";

const HomeworkReport = ({ data }) => {
  const homeworks = data.activate_homework;
  const value = data.homework_completion_rate
  return (
    <Card className={`${style.homeworkReportContainer} p-3 px-5`}>
      <div className={`${style.title} my-3 px-3`}>Ödev Durumu</div>
      <div className="d-flex">
        <div className={`${style.homeworkContainer}`}>
          {homeworks?.map((homework) => (
            <HomeworkInfo key={nanoid()} homework={homework} />
          ))}
        </div>
        <div className={`${style.chartDiv}`}>
          <ChartInfo value={value}/>
        </div>
      </div>
    </Card>
  );
};

export default HomeworkReport;
