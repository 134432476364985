import React from 'react'
import defaultFile from "../../../../../../assets/defaultFileImage.png"
import style from "./TeacherArticles.module.scss"

const SingleArticle = ({title,url}) => {
  return (
    <div className={`${style.singleArticleContainer} d-flex align-items-center gap-2`}>
        <div className={`${style.articleImageDiv}`}><a href={url} target="_blank" rel="noopener noreferrer"><img src={defaultFile} alt="" /></a></div>
        <div>
        <div className={`${style.title}`}>{title}</div>
        <div className={`${style.url} cursor-pointer`}> <a href={url} target="_blank" rel="noopener noreferrer">{url}</a></div>
        </div>
    </div>
  )
}

export default SingleArticle