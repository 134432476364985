import React from "react";
import style from "./ActiveHomeworks.module.scss";
import Card from "../../../../../components/bootstrap/Card";
import { useHomeworksQuery } from "../../teacherApi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedHomework } from "../../teacherSlice";

const ActiveHomeworks = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeHomeWorkStatus = `status=1`
  const { data:activeHomeWork } = useHomeworksQuery(activeHomeWorkStatus);

  return (
    <Card className={`${style.cardContainer}`}>
    <div className='pt-2 my-3'> 
    <span className={`${style.title} ms-5`} onClick={()=> navigate("/teacher/homeworks")}>Aktif Ödevler</span>
  </div>
    <div className={`${style.customTable} px-4`}>
      <div className={`${style.customHeadContainer} d-flex align-items-center my-3 px-2`}>
        <div
          className={`${style.customTableHead1} ${style.customTableHead}`}
        >
          Ödev
        </div>
        <div
          className={`${style.customTableHead2} ${style.customTableHead}`}
        >
          Sınıf
        </div>
      </div>
      <div className={`${style.customRowContainer}`}>
        {activeHomeWork && activeHomeWork.length === 0 ? (
          <div className={`${style.emptyTable}`}>
            <span>Aktif ödev bulunamadı</span>
          </div>
        ) : (
          activeHomeWork &&
          activeHomeWork.map((item, i) => (
            <div
              key={item.id}
              className={`${style.customTableRow} ${
                i % 2 === 0 && style.customBackgroundColor
              } d-flex align-items-center mt-1 px-2 cursor-pointer`}
              onClick={() => {
                              dispatch(setSelectedHomework(item));
                              navigate(`/teacher/new-homework`);
                            }}
            >
              <div className={`${style.customTableColumn1}`}>
                 <div className={`${style.titleContainer}`}>
                  <div className={`${style.titleShort}`}>{item.title}</div>
                  <div className={`${style.titleFull}`}>{item.title}</div>
                    </div>
              </div>
              <div className={`${style.customTableColumn2}`}>
                 <div className={`${style.classContainer}`}>
                  <div className={`${style.classShort}`}>{item.classroom_name}</div>
                  <div className={`${style.classFull}`}>{item.classroom_name}</div>
                    </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  </Card>
  );
};

export default ActiveHomeworks;
