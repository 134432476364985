import { useFormik } from "formik";
import React, { useEffect } from "react";
import Avatar from "../../../components/Avatar";
import Button from "../../../components/bootstrap/Button";
import Card, {
  CardBody,
  CardFooter,
  CardFooterRight,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../components/bootstrap/Card";
import {
  useUpdateProfilMutation,
} from "../../../store/features/apiSlice";
import AVATARS from "../../../common/data/avatarsDummy";
import USER_ROLES from "../../../common/data/enumUserRole";
import { useGet_a_SchoolQuery } from "../teacher/teacherApi";
import { avatars, capitalize, showError, showSuccess, years } from "./utils";
import CustomFormField from "../../../common/customFormField/CustomFormField";
import { getCustomErrorMessage, handleErrorMessage } from "../../../utils/customErrors";

const ProfileDetail = ({ userRole }) => {
  const userId = localStorage.getItem("id");
  const [updateProfile] = useUpdateProfilMutation();
  const name = localStorage.getItem("name");
  const surname = localStorage.getItem("surname");
  const username = localStorage.getItem("username");
  const email = localStorage.getItem("email");
  const school = localStorage.getItem("school");
  const birth_date = localStorage.getItem("birth_date");
  const user_avatar = localStorage.getItem("user_avatar");
  const defaultUsername = email.split("@")[0]+userId;

  const { data } = useGet_a_SchoolQuery(school, {skip:!(school > 0)});

  useEffect(() => {
    if(data){
      formik.setFieldValue("school", data.title);
    }
    formik.setFieldValue("firstName", name && name !== "null" ? capitalize(name) : email.split("@")[0]+userId);
    formik.setFieldValue("birth_date", (birth_date && birth_date !== "null") && birth_date);
    //eslint-disable-next-line
  }, [data])
  
  const validate = (values) => {
    const errors = {};
    if (values.birth_date === "false" || !values.birth_date) {
      errors.birth_date = "Doğum tarihi seçiniz";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      firstName: "" ,
      lastName: surname ? capitalize(surname) : "",
      email: email,
      role: 1,
      user_avatar: user_avatar ? user_avatar : "",
      school: "null",
      birth_date: "",
      username: username && username !== "null" ? username : defaultUsername,
      parent_telephone_number:null,
    },
    validate,
    onSubmit: async(values) => {
      const userValues = { ...formik.values };
      const response = await updateProfile({
        id: userId,
        first_name: values.firstName,
        last_name: values.lastName,
        ...userValues,
      });
      if (response.error) {
        const customError = handleErrorMessage(response.error.data);
        showError(
          <span>{getCustomErrorMessage(customError, "Kayıt Başarısız")}</span>
        );
      } else {
        showSuccess("Kullanıcı bilgileri başarıyla güncellendi.")
        localStorage.setItem("name", values.firstName);
        localStorage.setItem("surname", values.lastName);
        localStorage.setItem("username", values.username);
        localStorage.setItem("email", values.email);
        localStorage.setItem("school", values.school);
        localStorage.setItem("birth_date", values.birth_date);
        localStorage.setItem("user_avatar", values.user_avatar);
      }
    },
  });
  return (
    <Card stretch tag="form" noValidate onSubmit={formik.handleSubmit}>
      <CardHeader>
        <CardLabel icon="Contacts" iconColor="info">
          <CardTitle>Profilim</CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody className="pb-0" isScrollable>
        <div className="row g-4">
          {userRole === USER_ROLES.STUDENT.name && (
            <div className="col-12">
              <div className="row g-4 align-items-center">
                <div className="col-lg-auto">
                  <Avatar
                    src={
                      formik.values.user_avatar === "null"
                        ? AVATARS.defaultAvatar
                        : formik.values.user_avatar
                    }
                    size={128}
                  />
                </div>
                <div className="col-lg">
                  <div className="row g-4">
                    <div className="col-auto">
                      <CustomFormField type="select"  label="Avatar" name={"user_avatar"} options={avatars} formik={formik}/>
                    </div>
                    <div className="col-12">
                      <p className="lead text-muted">
                        Avatar, arkadaşlarınızın sizi tanımasına yardımcı olur.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-12 border-bottom" />
          <div className="col-lg-8">
            <CustomFormField type="text" label="Kullanıcı Adı" name={"username"} formik={formik}/>
          </div>
          <div className="col-lg-8">
            <CustomFormField type="text" label="Ad" name={"firstName"} formik={formik}/>
          </div>
          <div className="col-lg-8">
            <CustomFormField type="text" label="Soyad" name={"lastName"} formik={formik}/>
          </div>
          {userRole === USER_ROLES.STUDENT.name && (
            <>
              <div className="col-lg-8">
                <CustomFormField type="text" label="Okul" name={"school"} formik={formik} readOnly={true}/>
              </div>
              <div className="col-lg-8">
                <CustomFormField type="select"  label="Doğum Tarihi" name={"birth_date"} options={years} formik={formik}/>
              </div>
            </>
          )}
          <div className="col-lg-8">
            <CustomFormField type="text" label="Email" name={"email"} formik={formik} readOnly={true}/>
          </div>
        </div>
      </CardBody>
      <CardFooter>
        <CardFooterRight>
          <Button
            type="submit"
            icon="Save"
            color="info"
            isOutline
            isDisable={(!formik.isValid && !!formik.submitCount)}
          >
            Kaydet
          </Button>
        </CardFooterRight>
      </CardFooter>
    </Card>
  );
};

export default ProfileDetail;
