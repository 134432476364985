import React, { useEffect, useState } from "react";
import Card from "../../../../../components/bootstrap/Card";
import Button from "../../../../../components/bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { menuList } from "./menuList";
import { setMenu } from "../../../admin/school management/school restrictions/restrictionSlice";
import { useGetAllSchoolRestrictionsQuery } from "../../../admin/school management/school restrictions/restrictionApi";

const ContentLeftMenu = () => {
  const schoolId = localStorage.getItem("school");
  const { menu } = useSelector((store) => store.restriction);
  const [activeButton, setActiveButton] = useState();

  const { data, isLoading } = useGetAllSchoolRestrictionsQuery(schoolId);

const restrictions = {
  "COURSES": data && data[0].course,
  "TRAILS": data && data[0].trail,
  "PLATFORM_APPLICATIONS": data && (data[0].codelab || data[0].codemino || data[0].codeboo || data[0].codecizz)
};

const modifiedMenuList = {};

for (const [key, value] of Object.entries(restrictions)) {
  if (value) {
    modifiedMenuList[key] = menuList[key];
  } else {
    delete modifiedMenuList[key];
  }
}

  const dispatch = useDispatch();
  const handleClick = (menuItem) => {
    dispatch(setMenu(menuItem));
    setActiveButton(menuItem);
  };
  useEffect(() => {
    if(menu){
      setActiveButton(menu)
    }else{
      setActiveButton("Dersler")
    }
  }, [menu])

  if(isLoading){
    return(
      <div>Yükleniyor...</div>
    )
  }
  return (
    <Card
      className="d-flex gap-3 align-items-center justify-content-center py-2"
      style={{ minHeight: "95%" }}
    >
      {Object.values(modifiedMenuList).map((item, index) => (
        <div className="mx-auto" key={item}>
          <Button
            onClick={() => handleClick(item)}
            isActive = {activeButton === item}
            className={`btn btn-${!(activeButton === item) ? 'outline-primary' : 'primary'} fs-5`}
            isOutline={true}
            style={{ width: "150px" }}
          >
            {item}
          </Button>
          <br />
        </div>
      ))}
    </Card>
  );
};

export default ContentLeftMenu;
