import React from "react";
import Card, {
  CardBody,
  CardLabel,
  CardTitle,
} from "../../../../components/bootstrap/Card";
import style from "./LeaderBoardForStudent.module.scss";
import AVATARS from "../../../../common/data/avatarsDummy";

const LeaderBoardLeader = ({ leader, userControl }) => {
  return (
    <Card stretch className={`${style.leaderBoard_leftMenu} pt-5`}>
      <Card className={`${style.leader} mx-auto`}>
        <CardLabel className="justify-content-center mt-3">
          <CardTitle>
            <span style={{fontSize:"32.5px", fontWeight:"500"}}>1</span>
          </CardTitle>
        </CardLabel>
        <CardBody>
          <div className="row">
            <div className="col-12">
              <div className={`${style.imgDiv} mx-auto`}>
                <img
                  src={
                    leader && leader.avatar && leader.avatar !== "null"
                      ? leader.avatar
                      : AVATARS.defaultAvatarSmall
                  }
                  alt=""
                />
              </div>
              <hr className={`${style.line} mx-auto mt-4`} />
              <div className={`${style.leftSectionTitle} mx-auto d-flex justify-content-center my-5`}>
              <div className={`${style.titleContainer}`}>
                                <div className={`${style.titleShort}`}>
                                <h5
                                  style={{
                                    backgroundColor: `${Number(userControl) === Number(leader.id) && "lightGray"
                                      }`,
                                  }}
                                >
                                  {leader && leader.email}
                                </h5>
                                </div>
                                <div className={`${style.titleFull}`}>
                                  {leader && leader.email}
                                </div>
                              </div>
              </div>
              <hr className={`${style.line} mx-auto mt-4`} />
              <div className={`${style.leftSectionPoints} mx-auto text-center my-4`}>
                <h4>{leader && leader.total_points} puan</h4>
              </div>
              <hr className={`${style.line} mx-auto mt-4`} />
            </div>
          </div>
        </CardBody>
      </Card>
    </Card>
  );
};

export default LeaderBoardLeader;
