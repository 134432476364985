import React from 'react'
import style from "./ClassStudents.module.scss"
import Leaders from './Leaders';
import Students from './Students';
import Information from './Information';
import { Card } from 'react-bootstrap';
import { useClassLeaderBoardQuery } from '../../../../teacher/teacherApi';

const ClassStudents = ({data, classId}) => {
    const { data:leaderBoardData} = useClassLeaderBoardQuery(classId, {skip:!classId});
    const leaderBoard = leaderBoardData ?? []
    const activeStudentsCount = data && data.student_count
    const totalpoints = data && data.total_points
    const totalCertificates = data && data.certificates
  return (
    <Card className={`${style.classStudentsContainer} px-5`}>
        <div className={`${style.leadersContainer} `}>
            <Leaders leaderBoard={leaderBoard}/>
        </div>
        <div className={`${style.studentsContainer} `}>
            <Students classId={classId}/>
        </div>
        <div className={`${style.informationsContainer}`}>
            <div className={`${style.information} py-3 px-3 my-2`}>
            <Information description={"Aktif Öğrenci"} value={activeStudentsCount}/>
            </div>
            <div className={`${style.information} py-3 px-3 my-2`}>
            <Information description={"Toplanan Toplam Puan"} value={totalpoints}/>
            </div>
            <div className={`${style.information} py-3 px-3 my-2`}>
            <Information description={"Toplam Kazanılan Sertifika"} value={totalCertificates}/>
            </div>
        </div>
    </Card>
  )
}
export default ClassStudents