import React from "react";
import style from "./StudentProgress.module.scss";

const StudentProgress = ({ studentName, rate, id }) => {
  return (
    <div className={`${style.singleTopicContainer}`}>
      <div className={`${style.topicName}`}>{studentName}</div>
      <div className={`${style.homeworkItem} d-flex justify-content-between`}>
        <div
          className={`${style.progress_bar} text-center`}
          style={{ width: `${rate}%` }}
        >
          {" "}
          <span>{rate}%</span>
        </div>
        
      </div>
    </div>
  );
};

export default StudentProgress;
