import React, { useEffect, useState } from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../components/bootstrap/Modal";
import Logo from "../../../../components/Logo";
import Icon from "../../../../components/icon/Icon";
import Button from "../../../../components/bootstrap/Button";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import { useFormik } from "formik";
import Select from "../../../../components/bootstrap/forms/Select";
import {
  useDeleteUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
} from "../../../../store/features/apiSlice";
import showNotification from "../../../../components/extras/showNotification";
import Confirm from "../../../common/Confirm";
import USER_ROLES from "../../../../common/data/enumUserRole";
import { getCustomErrorMessage, handleErrorMessage } from "../../../../utils/customErrors";
import { executeWithTimeout } from "../../../../utils/commonFunctions";

const UpdateUserModal = ({ isOpenModal, setIsOpenModal, userId, schools }) => {
  const { data, isSuccess } = useGetUserQuery(userId, {skip:userId===null});
  const [updateUser] = useUpdateUserMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [open, setOpen] = useState(false);

  const schoolsList =
    schools &&
    schools.map((item) => {
      return {
        text: item.title,
        value: item.id,
      };
    });
  const defaultSchool = [{ text: null, value: null }];
  const newSchoolList = schoolsList && defaultSchool.concat(schoolsList);

  //! delete user--------
  const handleDelete = async () => {
    const response = await deleteUser(userId);
    if (response.error) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Silme İşlemi Başarısız</span>
        </span>,
        "Bir hata meydana geldi",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Silme Başarılı</span>
        </span>,
        "Kullanıcı başarıyla silindi.",
        "success"
      );
    }
    executeWithTimeout(()=>setOpen(false), 1000)
  };
  const handlCancel = () => setOpen(false);
  //!-----------------------------------------------

  useEffect(() => {
    if (isSuccess && data) {
      formik.setFieldValue(
        "first_name",
        data.first_name === "" ? null : data.first_name
      );
      formik.setFieldValue(
        "last_name",
        data.last_name === "" ? null : data.last_name
      );
      formik.setFieldValue("email", data.email);
      formik.setFieldValue("role", data.role);
      formik.setFieldValue("school", data.school === null ? "" : data.school);
      formik.setFieldValue("subscription_type", data.subscription_type);
      formik.setFieldValue(
        "subscription_period",
        data.subscription_period ? data.subscription_period : "1"
      );
      formik.setFieldValue(
        "parent_telephone_number",
        data.parent_telephone_number
      );
    }else{
      formik.resetForm();
    }
    //eslint-disable-next-line
  }, [isSuccess, data]);

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Email adresi gereklidir.";
    }
    if (
      values.email.length > 0 &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = "Geçersiz email adresi.";
    }
    // if (
    //   Number(formik.values.role) === USER_ROLES.STUDENT.id &&
    //   (!values.parent_telephone_number ||
    //     values.parent_telephone_number.includes("_"))
    // ) {
    //   errors.parent_telephone_number = "Veli telefon numarasını yazınız";
    // }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      first_name: null,
      last_name: null,
      email: "",
      subscription_type: "1",
      subscription_period: "1",
      role: "1",
      telNumber: "",
      parent_telephone_number: null,
      school: null,
    },
    validate,
    onSubmit: () => {
      handleUser();
    },
  });
  const handleUser = async () => {
    const userValues = { ...formik.values };
    console.log(userValues);
    const response = await updateUser({
      id: userId,
      ...userValues,
    });
    if (response.error) {
      const customError = handleErrorMessage(response.error.data)
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>{getCustomErrorMessage(customError)}</span>
        </span>,
        "Bir hata oluştu",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Güncelleme Başarılı</span>
        </span>,
        "Kullanıcı bilgileri başarıyla güncellendi.",
        "success"
      );
    }
    executeWithTimeout(()=>setIsOpenModal(false), 1000)
  };
  const isSchool = Number(formik.values.school) > 0;
  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenModal}
      setIsOpen={setIsOpenModal}
      titleId="example-title"
      size={null}
    >
      <ModalHeader setIsOpen={setIsOpenModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} />{" "}
          <span className="ps-2">Kullanıcı Bilgisi Güncelleme</span>
          <span className="ps-2">
            <Icon icon="Person" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <Confirm
        open={open}
        setOpen={setOpen}
        onConfirm={handleDelete}
        onCancel={handlCancel}
      />

      <ModalBody>
        <div className="row gap-3 d-flex justify-content-center">
          <div className="col-lg-8">
            <FormGroup
              // className="col-4"
              id="first_name"
              isFloating
              label="Ad"
            >
              <Input
                onChange={formik.handleChange}
                value={formik.values.first_name}
                onBlur={formik.handleBlur}
                // isValid={formik.isValid}
                isTouched={formik.touched.first_name}
                invalidFeedback={formik.errors.first_name}
              />
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup
              // className="col-4"
              id="last_name"
              isFloating
              label="Soyad"
            >
              <Input
                onChange={formik.handleChange}
                value={formik.values.last_name}
                onBlur={formik.handleBlur}
                // isValid={formik.isValid}
                isTouched={formik.touched.last_name}
                invalidFeedback={formik.errors.last_name}
              />
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup isFloating label="Email" id="email">
              <Input
                name="email"
                type="email"
                autoComplete="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                isValid={formik.isValid}
                onBlur={formik.handleBlur}
                isTouched={formik.touched.email}
                invalidFeedback={formik.errors.email}
              />
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup id="subscription_type" isFloating label="Üyelik Tipi">
              <Select
                ariaLabel="subscription_type"
                onChange={formik.handleChange}
                value={formik.values.subscription_type}
                isValid={formik.isValid}
                onBlur={formik.handleBlur}
                isTouched={formik.touched.subscription_type}
                invalidFeedback={formik.errors.subscription_type}
                list={[
                  { value: "1", text: "Ücretsiz" },
                  { value: "2", text: "Standart" },
                  { value: "3", text: "Premium" },
                ]}
                className="text-dark"
                disabled={isSchool}
              ></Select>
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup
              id="subscription_period"
              isFloating
              label="Üyelik Süresi"
            >
              <Select
                ariaLabel="subscription_period"
                onChange={formik.handleChange}
                value={formik.values.subscription_period}
                isValid={formik.isValid}
                onBlur={formik.handleBlur}
                isTouched={formik.touched.subscription_period}
                invalidFeedback={formik.errors.subscription_period}
                list={[
                  { value: "1", text: "Aylık" },
                  { value: "2", text: "Yıllık" },
                ]}
                className="text-dark"
                disabled={isSchool}
              ></Select>
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup id="role">
              <Select
                ariaLabel="role"
                placeholder="Profil Tipi"
                onChange={formik.handleChange}
                value={formik.values.role}
                isValid={formik.isValid}
                onBlur={formik.handleBlur}
                isTouched={formik.touched.role}
                invalidFeedback={formik.errors.role}
                list={[
                  { value: "1", text: "Öğrenci" },
                  { value: "2", text: "Öğretmen" },
                  { value: "3", text: "Yönetici" },
                ]}
                className="text-dark"
              ></Select>
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup id="school" label="Okul Seç" isFloating>
              <Select
              ariaLabel="school"
                placeholder="Okul"
                onChange={formik.handleChange}
                value={formik.values.school}
                isValid={formik.isValid}
                onBlur={formik.handleBlur}
                isTouched={formik.touched.school}
                invalidFeedback={formik.errors.school}
                list={newSchoolList}
              />
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup id="telNumber" isFloating label="Telefon numarası">
              <Input
                type="text"
                mask="999 999 99 99"
                onChange={formik.handleChange}
                value={formik.values.telNumber}
                onBlur={formik.handleBlur}
                isValid={formik.isValid}
                isTouched={formik.touched.telNumber}
                invalidFeedback={formik.errors.telNumber}
              />
            </FormGroup>
          </div>
          {Number(formik.values.role) === USER_ROLES.STUDENT.id && (
            <>
              <div className="col-lg-8">
                <FormGroup
                  id="parent_telephone_number"
                  isFloating
                  label="Veli Telefon Numarası"
                >
                  <Input
                    type="text"
                    mask="999 999 99 99"
                    onChange={formik.handleChange}
                    value={formik.values.parent_telephone_number}
                    onBlur={formik.handleBlur}
                    isValid={formik.isValid}
                    isTouched={formik.touched.parent_telephone_number}
                    invalidFeedback={formik.errors.parent_telephone_number}
                  />
                </FormGroup>
              </div>
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="DeleteOutline"
          color="danger"
          isLight
          onClick={() => {
            setOpen(true);
          }}
        >
          Sil
        </Button>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={async () => {
            formik.handleSubmit();
          }}
        >
          Güncelle
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UpdateUserModal;
