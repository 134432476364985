import React, { useState } from "react";
import style from "./StudentWithoutClass.module.scss";
import educationLogo from "../../../../../../src/assets/dashboardImages/educationLogo.svg";
import { useJoinToClassMutation } from "../../../../../store/features/apiSlice";
import showNotification from "../../../../../components/extras/showNotification";
import UserInfoModal from "./UserInfoModal";
import UserInfoWarningModal from "./UserInfoWarningModal";
import { useNavigate } from "react-router-dom";
import UnApprovedTeacherModal from "./UnApprovedTeacherModal";
import TEACHER_ROLE_STATUS from "../../../../../common/data/enumTeacherRoleStatus";
import { useEffect } from "react";

const StudentWithoutClass = () => {
  const navigate = useNavigate();
  const [joinToClass] = useJoinToClassMutation();
  const [classCode, setClassCode] = useState("");
  const [open, setOpen] = useState(false);
  const [openUserInfoWarningModal, setOpenUserInfoWarningModal] =
    useState(false);
  const [openUnApprovedTeacherModal, setOpenUnApprovedTeacherModal] =
    useState(false);

  let name = localStorage.getItem("name");
  let surName = localStorage.getItem("surname");
  const teacherStatus = localStorage.getItem("userRoleStatus");
  const isRejected = Number(teacherStatus) === TEACHER_ROLE_STATUS.REJECTED;

  // Öğretmen onayı reddedildiği tarihten itibaren 5 gün pop up göster
  useEffect(() => {
    let rejectedDate = localStorage.getItem("role_status_date");
    if (rejectedDate !== "null") {
      rejectedDate = new Date(rejectedDate);
      const today = new Date();

      const lastRejectingDisplayDate = new Date(rejectedDate);
      lastRejectingDisplayDate.setDate(rejectedDate.getDate() + 5);

      if (today > lastRejectingDisplayDate) {
      } else {
        if (isRejected) setOpenUnApprovedTeacherModal(true);
      }
    }
    //eslint-disable-next-line
  }, []);

  const userControl = () => {
    if (name === "null" || surName === "null") {
      setOpenUserInfoWarningModal(true);
    } else {
      sendClassCode();
    }
  };
  const handleNavigation = () => {
    navigate("/profile");
    setOpen(false);
  };

  const sendClassCode = async () => {
    const response = await joinToClass(classCode);
    if (response.error) {
      if (
        response.error.data.details.includes("already") ||
        response.error.data.details.includes("Already")
      ) {
        showNotification(
          "Uyarı",
          "Zaten katılma talebi gönderdiniz.",
          "warning"
        );
      } else {
        showNotification("Hata", "Kod gönderme başarısız.", "danger");
      }
    } else {
      showNotification("Bilgi", "Talebiniz başarıyla gönderildi", "success");
      setOpen(true);
    }
    setClassCode("");
  };

  const handleCancel = () => setOpen(false);
  return (
    <>
      {" "}
      <UserInfoModal open={open} setOpen={setOpen} onCancel={handleCancel} />
      <UnApprovedTeacherModal
        openUnApprovedTeacherModal={openUnApprovedTeacherModal}
        setOpenUnApprovedTeacherModal={setOpenUnApprovedTeacherModal}
        onCancel={() => setOpenUnApprovedTeacherModal(false)}
      />
      <UserInfoWarningModal
        openUserInfoWarningModal={openUserInfoWarningModal}
        setOpenUserInfoWarningModal={setOpenUserInfoWarningModal}
        onConfirm={handleNavigation}
      />
      <div
        className={`${style.studentContainer} d-flex justify-content-between align-items-center px-4 flex-wrap`}
      >
        <div className={`${style.headerDiv} d-flex justify-content-start align-items-center gap-2`}>
          <div className={`${style.educationLogo}`}>
            <img src={educationLogo} alt="" />
          </div>
          <div className={`${style.description}`}>
            <span>Öğrenci topluluğumuza katılın</span>
          </div>
        </div>
        <div
          className={`${style.classCodContainer} d-flex align-items-center justify-content-between`}
        >
          <input
            className="ms-3 ps-2"
            type="text"
            value={classCode}
            required
            onChange={(e) => {
              setClassCode(e.target.value);
            }}
            placeholder="Lütfen bir sınıf kodu girin."
          />
          <button onClick={() => classCode && userControl()}>
            <span>Sınıfa Katıl</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default StudentWithoutClass;
