// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CodeEditor_container__VjQSV {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: start;\n      justify-content: flex-start;\n}", "",{"version":3,"sources":["webpack://./src/pages/t14/practice/CodeEditor.module.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EAAA,aAAA;EACA,oBAAA;MAAA,2BAAA;AACJ","sourcesContent":[".container{\n    display: flex;\n    justify-content: flex-start;\n}\n\n// @media screen and (max-width:450px) {\n//     .item1{\n//         // border: solid !important;\n//         display: flex;\n//         align-items: center;\n//         Button{\n//             transform: scalex(80%);\n//             line-height: 1;\n//             display: flex;\n//             font-size: 11px;\n//         }\n//     }  \n//     .item2{\n//         // border: solid !important;\n//         Button{\n//             transform: scale(80%);\n//             line-height: 1;\n//             display: flex;\n//             position: relative;\n//             right: 15%;\n//         }\n//     }  \n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CodeEditor_container__VjQSV"
};
export default ___CSS_LOADER_EXPORT___;
