import React from "react";
import Card from "../../../../components/bootstrap/Card";
import Button from "../../../../components/bootstrap/Button";
import { useDispatch } from "react-redux";
import { setMenu } from "./talentCupSlice";
import { menuList } from "./talentCupMenuList";

const CompetitionLeftMenu = () => {
  const dispatch = useDispatch();
  const handleClick = (menuItem) => {
    dispatch(setMenu(menuItem));
  };

  return (
    <>
      <Card
        className="d-flex gap-3 align-items-center justify-content-center py-2"
        // style={{ minHeight: "80%" }}
        style={{ minHeight: "280px" }}
      >
        {Object.values(menuList).map((item, index) => (
          <div className="mx-auto" key={item}>
            <Button
              onClick={() => handleClick(item)}
              className="btn btn-outline-primary fs-5"
              isOutline={true}
              style={{ width: "150px" }}
            >
              {item}
            </Button>
            <br />
          </div>
        ))}
      </Card>
    </>
  );
};

export default CompetitionLeftMenu;
