
import React from 'react';
import MDEditor from '@uiw/react-md-editor';
import Card, {
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../components/bootstrap/Card";

function PracticeDetail ({title,explanation}){
    return (
      <div>
      <Card stretch={true}>
        <CardHeader borderSize={1}>
          <CardLabel iconColor="info">
            <CardTitle>{title}</CardTitle>
          </CardLabel>
        </CardHeader>
        <CardBody className="table-responsive" isScrollable={false}>
        <MDEditor.Markdown className="practice-detail-body"  source={explanation} />
        </CardBody>
      </Card>
    </div>
    );
  
}

export default PracticeDetail;