const gameStatusData = {
    1: {
      color: "warning",
      text: "Maceraya Devam Et",
    },
    2: {
      color: "success",
      text: "Macera Tamamlandı",
    },
    0: {
      color: "myButton",
      text: "Maceraya Başla",
    },
  };

  export function getGameStatusColor(status) {
    return gameStatusData[status]?.color ||gameStatusData[0]?.color;
  }

  export function getGameStatusText(status) {
    return gameStatusData[status]?.text || gameStatusData[0]?.text;
  }

const gameSectionStatusData = {
    1: {
      color: "warning",
      text: "Devam Et",
    },
    2: {
      color: "success",
      text: "Tamamlandı",
    },
    0: {
      color: "info",
      text: "Başla",
    },
  };

  export function getGameSectionStatusColor(status) {
    return gameSectionStatusData[status]?.color || gameSectionStatusData[0]?.color;
  }

  export function getGameSectionStatusText(status) {
    return gameSectionStatusData[status]?.text || gameSectionStatusData[0]?.text;
  }

const gameStepStatusData = {
    1: {
      color: "warning",
      text: "Devam Et",
    },
    2: {
      color: "danger",
      text: "Başarısız",
    },
    3: {
      color: "success",
      text: "Tamamlandı",
    },
    0: {
      color: "info",
      text: "Oyuna Gir",
    },
  };

  export function getGameStepStatusColor(status) {
    return gameStepStatusData[status]?.color || gameStepStatusData[0]?.color;
  }

  export function getGameStepStatusText(status) {
    return gameStepStatusData[status]?.text || gameStepStatusData[0]?.text;
  }