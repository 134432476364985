import React from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

// The .js references are necessary for requireJs to work in the browser.
// eslint-disable-next-line
import { IContentService, IContentListEntry } from '../services/ContentService';
import ContentListEntryComponent from './ContentListEntryComponent';

export default class ContentList extends React.Component {
    constructor(props) {
        super(props);

        this.state = { contentList:undefined, loading:false, filteredContents:undefined, search:'' };
        this.contentService = props.contentService;
    }

    state: {
        contentList:undefined;
        filteredContents:undefined;
        search:'';
        loading:false;
    };

     contentService;
    /**
     * Keeps track of newly created content to assign a key
     * @memberof ContentList
     */
     newCounter = 0;

    async componentDidMount(){
        await this.updateList();
    }

    render(): React.ReactNode {
        return (
            <div>

                <div className="row">
                    <div className="col-3">
                        <Button variant="primary" onClick={() => this.new()} className="my-2">
                            <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                            Yeni İçerik Ekle
                        </Button>
                    </div>
                    <div className="col-6">
                        <div className="d-flex gap-1" >
                        <input 
                            className="form-control" 
                            type="search" 
                            placeholder="Ara.." 
                            onChange={(e)=>this.setSearch(e.target.value.toLowerCase())}/>

                    </div> 
                    </div>
                    
                </div>

                <ListGroup>
                    {this.state.filteredContents !== undefined ? 
                    this.state.filteredContents?.map((content) => (
                        <ContentListEntryComponent
                            contentService={this.contentService}
                            data={content}
                            key={content.originalNewKey ?? content.contentId}
                            onDiscard={() => this.onDiscard(content)}
                            onDelete={() => this.onDelete(content)}
                            onSaved={(newData) =>
                                this.onSaved(content, newData)
                            }
                            generateDownloadLink={
                                this.contentService.generateDownloadLink
                            }
                        ></ContentListEntryComponent>
                    )) :
                    this.state.contentList?.map((content) => (
                        <ContentListEntryComponent
                            contentService={this.contentService}
                            data={content}
                            key={content.originalNewKey ?? content.contentId}
                            onDiscard={() => this.onDiscard(content)}
                            onDelete={() => this.onDelete(content)}
                            onSaved={(newData) =>
                                this.onSaved(content, newData)
                            }
                            generateDownloadLink={
                                this.contentService.generateDownloadLink
                            }
                        ></ContentListEntryComponent>
                    ))
                }
                </ListGroup>
            </div>
        );
    }

    setSearch = (search) => {
        if(search === ''){
            this.setState({ filteredContents:undefined });
        }
        else {
            const filteredContents = this.state.contentList.filter((item)=>item.title.toLowerCase().includes(search))
            this.setState({ filteredContents });
        }
    }

    async updateList(){
        this.setState({ loading:true });
        let contentList = await this.contentService.list();
        contentList =  orderByName(contentList);
        this.setState({ contentList,loading:false });
    }

    new() {
        this.setState({
            contentList: [
                {
                    contentId: 'new',
                    mainLibrary: undefined,
                    title: 'New H5P',
                    originalNewKey: `new-${this.newCounter++}`
                },
                ...this.state.contentList
            ]
        });
    }

    onDiscard(content) {
        this.setState({
            contentList: this.state.contentList.filter((c) => c !== content)
        });
    }

    async onDelete(content: IContentListEntry) {
        if (!content.contentId) {
            return;
        }
        const isConfirmed = window.confirm('Bu içeriği silmek istediğinizden emin misiniz?');
    
        if (!isConfirmed) {
            return;
        }
        try {
            await this.contentService.delete(content.contentId);
            this.setState({
                contentList: this.state.contentList.filter((c) => c !== content)
            });
        } catch (error) {
            if (error instanceof Error) {
                console.error(error.message);
            }
        }
    }

    async onSaved(
        oldData: IContentListEntry,
        newData: IContentListEntry
    ) {
        this.setState({
            contentList: this.state.contentList.map((c) =>
                c === oldData ? newData : c
            )
        });
    }
}
function orderByName(contentList: any): any {
        return contentList.sort((a: any, b: any) => {
            const titleA = a.title.toLowerCase();
            const titleB = b.title.toLowerCase();
    
            if (titleA < titleB) {
                return -1;
            }
            if (titleA > titleB) {
                return 1;
            }
            return 0;
        });
    
}

