import React from 'react'
import style from "../ParentReportWithClass.module.scss"
import check from "../../../../../../assets/reports/check.png";
import cancel from "../../../../../../assets/reports/cancel.png";
import { formatDate2TurkishShortDate } from '../../../../../../utils/formatDate';

const AttendanceInfo = ({attendance}) => {
    const title = attendance.lecture_title;
    const date = attendance.lecture_date;
    const status = attendance.attendance_status === 2 ? check: cancel;
  return (
    <div className={`${style.singleAttendance} d-flex justify-content-center align-items-center my-2 gap-2`}>
        <div className={`${style.singleAttendanceLeft} d-flex align-items-center p-2`}>
        <div className={`${style.titleContainer}`}>
                      <div className={`${style.titleShort}`}>{title}</div>
                      <div className={`${style.titleFull}`}>{title}</div>
                        </div>
        </div>
        <div className={`${style.singleAttendanceRight} d-flex align-items-center justify-content-between px-3`}>
            <div>{formatDate2TurkishShortDate(date)}</div>
            <div className={`${style.statusLogo} `}><img src={status} alt="" /></div>
        </div>
    </div>
  )
}

export default AttendanceInfo