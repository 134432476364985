import { apiSlice } from "../../../../store/features/apiSlice";

export const teacherEducationApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    coursesTeacher: builder.query({
      query: (data) => ({
        url: "teacher_education/courses/",
        method: "get",
      }),
      providesTags: ["courseTeacher"],
    }),
    singleCourseTeacher: builder.query({
      query: (data) => ({
        url: `teacher_education/courses/${data.id}`,
        method: "get",
      }),
      providesTags: ["courseTeacher"],
    }),
    createCourseTeacher: builder.mutation({
      query: ({
        title,
        technology,
        description,
        order_number,
        status,
        cover_image,
      }) => ({
        url: `teacher_education/courses/`,
        method: "post",
        body: {
          title,
          technology,
          description,
          order_number,
          status,
          cover_image,
        },
      }),
      invalidatesTags: ["courseTeacher"],
    }),
    updateCourseTeacher: builder.mutation({
      query: ({
        id,
        title,
        technology,
        description,
        order_number,
        status,
        cover_image,
        chapters,
      }) => ({
        url: `teacher_education/courses/${id}/`,
        method: "put",
        body: {
          id,
          title,
          technology,
          description,
          order_number,
          status,
          cover_image,
          chapters,
        },
      }),
      invalidatesTags: ["courseTeacher"],
    }),
    deleteCourseTeacher: builder.mutation({
      query: (id) => ({
        url: `teacher_education/courses/${id}/`,
        method: "delete",
      }),
      invalidatesTags: ["courseTeacher"],
    }),

    presentations: builder.query({
      query: (data) => ({
        url: "teacher_education/presentations/",
        method: "get",
      }),
      providesTags: ["presentation"],
    }),
    singlePresentation: builder.query({
      query: (data) => ({
        url: `teacher_education/presentations/${data.id}/`,
        method: "get",
      }),
      providesTags: ["presentation"],
    }),
    createPresentation: builder.mutation({
      query: ({
        title,
        technology,
        description,
        order_number,
        status,
        cover_image,
      }) => ({
        url: `teacher_education/presentations/`,
        method: "post",
        body: {
          title,
          technology,
          description,
          order_number,
          status,
          cover_image,
        },
      }),
      invalidatesTags: ["presentation"],
    }),
    updatePresentation: builder.mutation({
      query: ({
        id,
        title,
        technology,
        description,
        order_number,
        status,
        cover_image,
        slides,
      }) => ({
        url: `teacher_education/presentations/${id}/`,
        method: "put",
        body: {
          id,
          title,
          technology,
          description,
          order_number,
          status,
          cover_image,
          slides,
        },
      }),
      invalidatesTags: ["presentation"],
    }),
    deletePresentation: builder.mutation({
      query: (id) => ({
        url: `teacher_education/presentations/${id}/`,
        method: "delete",
      }),
      invalidatesTags: ["presentation"],
    }),

    guidances: builder.query({
      query: (data) => ({
        url: "teacher_education/guidances/",
        method: "get",
      }),
      providesTags: ["guidance"],
    }),
    singleGuidance: builder.query({
      query: (data) => ({
        url: `teacher_education/guidances/${data.id}/`,
        method: "get",
      }),
      providesTags: ["guidance"],
    }),
    createGuidance: builder.mutation({
      query: ({
        title,
        technology,
        description,
        order_number,
        status,
        cover_image,
      }) => ({
        url: `teacher_education/guidances/`,
        method: "post",
        body: {
          title,
          technology,
          description,
          order_number,
          status,
          cover_image,
        },
      }),
      invalidatesTags: ["guidance"],
    }),
    updateGuidance: builder.mutation({
      query: ({
        id,
        title,
        technology,
        description,
        order_number,
        status,
        cover_image,
        guides,
      }) => ({
        url: `teacher_education/guidances/${id}/`,
        method: "put",
        body: {
          id,
          title,
          technology,
          description,
          order_number,
          status,
          cover_image,
          guides,
        },
      }),
      invalidatesTags: ["guidance"],
    }),
    deleteGuidance: builder.mutation({
      query: (id) => ({
        url: `teacher_education/guidances/${id}/`,
        method: "delete",
      }),
      invalidatesTags: ["guidance"],
    }),

    documents: builder.query({
      query: (data) => ({
        url: "teacher_education/resources/documents/",
        method: "get",
      }),
      providesTags: ["document"],
    }),
    singleDocument: builder.query({
      query: (data) => ({
        url: `teacher_education/resources/documents/${data.id}/`,
        method: "get",
      }),
      providesTags: ["document"],
    }),
    createDocument: builder.mutation({
      query: (formData) => ({
        url: `teacher_education/resources/documents/`,
        method: "post",
        prepareHeaders: (headers) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
        body: formData,
      }),
      invalidatesTags: ["document"],
    }),
    updateDocument: builder.mutation({
      query: ({ id, formData, title }) => ({
        url: `teacher_education/resources/documents/${id}/`,
        method: "put",
        body: formData,
      }),
      invalidatesTags: ["document"],
    }),
    deleteDocument: builder.mutation({
      query: (id) => ({
        url: `teacher_education/resources/documents/${id}/`,
        method: "delete",
      }),
      invalidatesTags: ["document"],
    }),

    videos: builder.query({
      query: (data) => ({
        url: "teacher_education/resources/videos/",
        method: "get",
      }),
      providesTags: ["video"],
    }),
    singleVideo: builder.query({
      query: (data) => ({
        url: `teacher_education/resources/videos/${data.id}/`,
        method: "get",
      }),
      providesTags: ["video"],
    }),
    createVideo: builder.mutation({
      query: ({
        title,
        technology,
        description,
        order_number,
        status,
        cover_image,
        video,
      }) => ({
        url: `teacher_education/resources/videos/`,
        method: "post",
        body: {
          title,
          technology,
          description,
          order_number,
          status,
          cover_image,
          video,
        },
      }),
      invalidatesTags: ["video"],
    }),
    updateVideo: builder.mutation({
      query: ({
        id,
        title,
        technology,
        description,
        order_number,
        status,
        cover_image,
        video,
      }) => ({
        url: `teacher_education/resources/videos/${id}/`,
        method: "put",
        body: {
          id,
          title,
          technology,
          description,
          order_number,
          status,
          cover_image,
          video,
        },
      }),
      invalidatesTags: ["video"],
    }),
    deleteVideo: builder.mutation({
      query: (id) => ({
        url: `teacher_education/resources/videos/${id}/`,
        method: "delete",
      }),
      invalidatesTags: ["video"],
    }),

    articles: builder.query({
      query: (data) => ({
        url: "teacher_education/resources/articles/",
        method: "get",
      }),
      providesTags: ["article"],
    }),
    singleArticle: builder.query({
      query: (data) => ({
        url: `teacher_education/resources/articles/${data.id}/`,
        method: "get",
      }),
      providesTags: ["article"],
    }),
    createArticle: builder.mutation({
      query: ({
        title,
        technology,
        description,
        order_number,
        status,
        cover_image,
        url,
      }) => ({
        url: `teacher_education/resources/articles/`,
        method: "post",
        body: {
          title,
          technology,
          description,
          order_number,
          status,
          cover_image,
          url,
        },
      }),
      invalidatesTags: ["article"],
    }),
    updateArticle: builder.mutation({
      query: ({
        id,
        title,
        technology,
        description,
        order_number,
        status,
        cover_image,
        url,
      }) => ({
        url: `teacher_education/resources/articles/${id}/`,
        method: "put",
        body: {
          id,
          title,
          technology,
          description,
          order_number,
          status,
          cover_image,
          url,
        },
      }),
      invalidatesTags: ["article"],
    }),
    deleteArticle: builder.mutation({
      query: (id) => ({
        url: `teacher_education/resources/articles/${id}/`,
        method: "delete",
      }),
      invalidatesTags: ["article"],
    }),

    //! teacher section
    coursesForTeacher: builder.query({
      query: (data) => ({
        url: "teacher_education/courses/teachers/",
        method: "get",
      }),
      providesTags: ["courseTeacher"],
    }),
    singleCourseForTeacher: builder.query({
      query: (data) => ({
        url: `teacher_education/courses/${data.id}/teachers/`,
        method: "get",
      }),
      providesTags: ["courseTeacher"],
    }),

    guidancesForTeacher: builder.query({
      query: (data) => ({
        url: "teacher_education/guidances/teachers/",
        method: "get",
      }),
      providesTags: ["guidance"],
    }),
    singleGuidanceForTeacher: builder.query({
      query: (data) => ({
        url: `teacher_education/guidances/${data.id}/teachers/`,
        method: "get",
      }),
      providesTags: ["guidance"],
    }),
    presentationsForTeacher: builder.query({
      query: (data) => ({
        url: "teacher_education/presentations/teachers/",
        method: "get",
      }),
      providesTags: ["presentation"],
    }),
    singlePresentationForTeacher: builder.query({
      query: (data) => ({
        url: `teacher_education/presentations/${data.id}/teachers/`,
        method: "get",
      }),
      providesTags: ["presentation"],
    }),

    documentsForTeacher: builder.query({
      query: (data) => ({
        url: "teacher_education/resources/documents/teachers/",
        method: "get",
      }),
      providesTags: ["document"],
    }),
    singleDocumentForTeacher: builder.query({
      query: (data) => ({
        url: `teacher_education/resources/documents/${data.id}/teachers/`,
        method: "get",
      }),
      providesTags: ["document"],
    }),
    videosForTeacher: builder.query({
      query: (data) => ({
        url: "teacher_education/resources/videos/teachers/",
        method: "get",
      }),
      providesTags: ["video"],
    }),
    singleVideoForTeacher: builder.query({
      query: (data) => ({
        url: `teacher_education/resources/videos/${data.id}/teachers/`,
        method: "get",
      }),
      providesTags: ["video"],
    }),
    articlesForTeacher: builder.query({
      query: (data) => ({
        url: "teacher_education/resources/articles/teachers/",
        method: "get",
      }),
      providesTags: ["article"],
    }),
    singleArticleForTeacher: builder.query({
      query: (data) => ({
        url: `teacher_education/resources/articles/${data.id}/teachers/`,
        method: "get",
      }),
      providesTags: ["article"],
    }),
  }),
});

export const {
  useCoursesTeacherQuery,
  useSingleCourseTeacherQuery,
  useCreateCourseTeacherMutation,
  useUpdateCourseTeacherMutation,
  useDeleteCourseTeacherMutation,

  usePresentationsQuery,
  useSinglePresentationQuery,
  useCreatePresentationMutation,
  useUpdatePresentationMutation,
  useDeletePresentationMutation,

  useGuidancesQuery,
  useSingleGuidanceQuery,
  useCreateGuidanceMutation,
  useUpdateGuidanceMutation,
  useDeleteGuidanceMutation,

  useDocumentsQuery,
  useSingleDocumentQuery,
  useCreateDocumentMutation,
  useUpdateDocumentMutation,
  useDeleteDocumentMutation,

  useVideosQuery,
  useSingleVideoQuery,
  useCreateVideoMutation,
  useUpdateVideoMutation,
  useDeleteVideoMutation,

  useArticlesQuery,
  useSingleArticleQuery,
  useCreateArticleMutation,
  useUpdateArticleMutation,
  useDeleteArticleMutation,

  useCoursesForTeacherQuery,
  useSingleCourseForTeacherQuery,
  useGuidancesForTeacherQuery,
  useSingleGuidanceForTeacherQuery,
  usePresentationsForTeacherQuery,
  useSinglePresentationForTeacherQuery,

  useDocumentsForTeacherQuery,
  useSingleDocumentForTeacherQuery,
  useVideosForTeacherQuery,
  useSingleVideoForTeacherQuery,
  useArticlesForTeacherQuery,
  useSingleArticleForTeacherQuery,
} = teacherEducationApi;
