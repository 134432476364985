import React, { useEffect } from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../../components/bootstrap/Modal";
import Logo from "../../../../../components/Logo";
import Icon from "../../../../../components/icon/Icon";
import Button from "../../../../../components/bootstrap/Button";
import { useFormik } from "formik";
import showNotification from "../../../../../components/extras/showNotification";
import CustomFormField from "../../../../../common/customFormField/CustomFormField";
import {
  getCustomErrorMessage,
  handleErrorMessage,
} from "../../../../../utils/customErrors";
import { useCreateDiscountMutation, useSingleDiscountQuery, useUpdateDiscountMutation } from "../../../../../store/features/couponApi";
import FormGroup from "../../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../../components/bootstrap/forms/Input";

const ShowCouponModal = ({ isOpenModal, setIsOpenModal, couponId=null }) => {
  const [createDiscount] = useCreateDiscountMutation();
  const [updateDiscount] = useUpdateDiscountMutation();
  const { data: currentCoupon, isLoading, isSuccess } = useSingleDiscountQuery(couponId, {skip:!couponId});

  useEffect(() => {
    if (isSuccess){
      formik.setFieldValue("id", currentCoupon.id || couponId);
      formik.setFieldValue("title", currentCoupon.title ?? "");
      formik.setFieldValue("code", currentCoupon.code ?? "");
      formik.setFieldValue(
        "discount_type",
        currentCoupon.discount_amount !== null ? "amount" : "percentage"
      );
      formik.setFieldValue("discount_percentage", currentCoupon.discount_percentage ?? null);
      formik.setFieldValue("discount_amount", currentCoupon.discount_amount ?? null);
      formik.setFieldValue("status", currentCoupon.status ?? 1);
      formik.setFieldValue("start_date", currentCoupon.start_date
        ? currentCoupon.start_date.substring(0, 10)
        : new Date().toISOString().substring(0, 10));
      formik.setFieldValue("expiry_date", currentCoupon.expiry_date
        ? currentCoupon.expiry_date.substring(0, 10)
        : new Date().toISOString().substring(0, 10));
      formik.setFieldValue("subscription_type", currentCoupon.subscription_type || []);
      formik.setFieldValue("subscription_period", currentCoupon.subscription_period || []);
  }
   else {
    formik.resetForm();
  }
  //eslint-disable-next-line
}, [isSuccess, currentCoupon]);

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Dersin adını giriniz";
    }

    if (!values.code) {
      errors.code = "Kupon giriniz";
    }
    if (!values.discount_percentage && !values.discount_amount) {
      errors.discount_percentage = "İndirimi ayarlayınız";
      errors.discount_amount = "İndirimi ayarlayınız";
    }
    if (!values.start_date) {
      errors.start_date = "Başlangıç tarihini giriniz";
    } else {
      const startDate = new Date(values.start_date);
      if (isNaN(startDate.getTime())) {
        errors.start_date = "Geçerli bir başlangıç tarihi giriniz";
      }
    }
    if (!values.expiry_date) {
      errors.expiry_date = "Bitiş tarihini giriniz";
    } else {
      const endDate = new Date(values.expiry_date);
      if (isNaN(endDate.getTime())) {
        errors.expiry_date = "Geçerli bir bitiş tarihi giriniz";
      }

      const startDate = new Date(values.start_date);
      if (endDate <= startDate) {
        errors.expiry_date = "Bitiş tarihi, başlangıç tarihinden sonra olmalı";
      }
    }
    // Abonelik Türü Kontrolü
    if (values.subscription_type.length === 0) {
      errors.subscription_type = "Abonelik türü seçiniz";
    }

    // Abonelik Periyodu Kontrolü
    if (values.subscription_period.length === 0) {
      errors.subscription_period = "Abonelik periyodu seçiniz";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      id: couponId,
      title: "",
      code: "",
      discount_type: "percentage",
      discount_percentage: null,
      discount_amount: null,
      status: 1,
      start_date: new Date().toISOString().substring(0, 10),
      expiry_date: new Date().toISOString().substring(0, 10),
      subscription_type: [],
      subscription_period: [],
    },
    validate,
    onSubmit: () => {
      handleDiscount();
    },
  });

  const handleDiscount = async () => {
    const {
      discount_type,
      discount_percentage,
      discount_amount,
      ...otherValues
    } = formik.values;
    // const couponValues = { ...formik.values };
    // Sadece seçili olan alanı göndermek için ayar yapıyoruz
    const couponValues = {
      ...otherValues,
      discount_percentage:
        discount_type === "percentage" ? discount_percentage : null,
      discount_amount: discount_type === "amount" ? discount_amount : null,
    };
    console.log("couponValues", couponValues);
    const response = couponId ? await updateDiscount({id:couponId, data:couponValues}) : await createDiscount({data:couponValues});
    if (response.error) {
      const customError = handleErrorMessage(response.error.data);
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>{getCustomErrorMessage(customError)}</span>
        </span>,
        "Bir hata oluştu",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Kayıt Başarılı</span>
        </span>,
        couponId ? "Kupon başarıyla güncellendi." : "Kupon başarıyla eklendi.",
        "success"
      );
    }
    setIsOpenModal(false);
    formik.resetForm();
  };
  // useEffect(() => {
  //   initialize();
  //   //eslint-disable-next-line
  // }, [isOpenModal]);
  //methods
  // const initialize = async () => {
  //   if (isSuccess) {
  //       formik.setFieldValue("id", currentCoupon.id || couponId);
  //       formik.setFieldValue("title", currentCoupon.title ?? "");
  //       formik.setFieldValue("code", currentCoupon.code ?? "");
  //       formik.setFieldValue("discount_type", currentCoupon.discount_type || "percentage");
  //       formik.setFieldValue("discount_percentage", currentCoupon.discount_percentage ?? null);
  //       formik.setFieldValue("discount_amount", currentCoupon.discount_amount ?? null);
  //       formik.setFieldValue("status", currentCoupon.status ?? 1);
  //       formik.setFieldValue("start_date", currentCoupon.start_date
  //         ? currentCoupon.start_date.substring(0, 10)
  //         : new Date().toISOString().substring(0, 10));
  //       formik.setFieldValue("expiry_date", currentCoupon.expiry_date
  //         ? currentCoupon.expiry_date.substring(0, 10)
  //         : new Date().toISOString().substring(0, 10));
  //       formik.setFieldValue("subscription_type", currentCoupon.subscription_type || []);
  //       formik.setFieldValue("subscription_period", currentCoupon.subscription_period || []);
  //     }
  //     else{
  //       formik.resetForm()
  //     }
  // };
  if(isLoading){
    return <div>yükleniyor</div>
  }
  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenModal}
      setIsOpen={setIsOpenModal}
      titleId="example-title"
      size={null}
    >
      <ModalHeader setIsOpen={setIsOpenModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} /> <span className="ps-2">{couponId ? "Kuponu Düzenle" : "Yeni Kupon"}</span>
          <span className="ps-2">
            <Icon icon="Computer" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form
          onSubmit={formik.handleSubmit}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              formik.handleSubmit();
            }
          }}
        >
          <div className="row gap-3 d-flex justify-content-center">
            <div className="col-lg-8">
              <CustomFormField
                type="text"
                label="Başlık"
                name={"title"}
                formik={formik}
              />
            </div>
            <div className="col-lg-8">
              <CustomFormField
                type="text"
                label="Kupon"
                name={"code"}
                formik={formik}
              />
            </div>
            <div className="col-lg-8">
              <CustomFormField
                type="select"
                label="İndirim Türü"
                name="discount_type"
                options={[
                  { text: "İndirim Oranı", value: "percentage" },
                  { text: "İndirim Miktarı", value: "amount" },
                ]}
                formik={formik}
              />
            </div>
            {formik.values.discount_type === "percentage" ? (
              <div className="col-lg-8">
                <CustomFormField
                  type="number"
                  label="İndirim Oranı (%)"
                  name="discount_percentage"
                  formik={formik}
                />
              </div>
            ) : (
              <div className="col-lg-8">
                <CustomFormField
                  type="number"
                  label="İndirim Miktarı"
                  name="discount_amount"
                  formik={formik}
                />
              </div>
            )}
            <div className="col-lg-8">
              <FormGroup id="start_date" label="Başlangıç Tarihi" isFloating>
                <Input
                  type={"date"}
                  value={formik.values.start_date}
                  placeholder="Başlangıç Tarihini giriniz"
                  onChange={formik.handleChange}
                  isValid={formik.isValid}
                  onBlur={formik.handleBlur}
                  isTouched={formik.touched.start_date}
                  invalidFeedback={formik.errors.start_date}
                />
              </FormGroup>
            </div>
            <div className="col-lg-8">
              <FormGroup id="expiry_date" label="Bitiş Tarihi" isFloating>
                <Input
                  type={"date"}
                  value={formik.values.expiry_date}
                  placeholder="Bitiş Tarihini giriniz"
                  onChange={formik.handleChange}
                  isValid={formik.isValid}
                  onBlur={formik.handleBlur}
                  isTouched={formik.touched.expiry_date}
                  invalidFeedback={formik.errors.expiry_date}
                />
              </FormGroup>
            </div>
            {/* Subscription Type - Standart ve Premium Seçenekleri */}
            <div className="col-lg-8">
              <FormGroup label="Abonelik Türü">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="subscription_type"
                    value={2}
                    checked={formik.values.subscription_type.includes(2)}
                    onChange={() => {
                      const newSelection =
                        formik.values.subscription_type.includes(2)
                          ? formik.values.subscription_type.filter(
                              (id) => id !== 2
                            )
                          : [...formik.values.subscription_type, 2]; // Yoksa ekle
                      formik.setFieldValue("subscription_type", newSelection); // Yeni seçimi güncelle
                    }}
                  />
                  <label className="form-check-label">Standart</label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="subscription_type"
                    value={3}
                    checked={formik.values.subscription_type.includes(3)}
                    onChange={() => {
                      const newSelection =
                        formik.values.subscription_type.includes(3)
                          ? formik.values.subscription_type.filter(
                              (id) => id !== 3
                            ) // Eğer varsa, kaldır
                          : [...formik.values.subscription_type, 3]; // Yoksa ekle
                      formik.setFieldValue("subscription_type", newSelection); // Yeni seçimi güncelle
                    }}
                  />
                  <label className="form-check-label">Premium</label>
                </div>
                {/* Hata Mesajı */}
                {formik.errors.subscription_type &&
                  formik.touched.subscription_type && (
                    <div className="invalid-feedback d-block">
                      {formik.errors.subscription_type}
                    </div>
                  )}
              </FormGroup>
            </div>

            {/* Subscription Period - Aylık ve Yıllık Seçenekleri */}
            <div className="col-lg-8">
              <FormGroup label="Abonelik Süresi">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="subscription_period"
                    value={1} // Aylık
                    checked={formik.values.subscription_period.includes(1)} // Seçili olup olmadığını kontrol et
                    onChange={() => {
                      const newSelection =
                        formik.values.subscription_period.includes(1)
                          ? formik.values.subscription_period.filter(
                              (id) => id !== 1
                            ) // Eğer varsa, kaldır
                          : [...formik.values.subscription_period, 1]; // Yoksa ekle
                      formik.setFieldValue("subscription_period", newSelection); // Yeni seçimi güncelle
                    }}
                  />
                  <label className="form-check-label">Aylık</label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="subscription_period"
                    value={2}
                    checked={formik.values.subscription_period.includes(2)}
                    onChange={() => {
                      const newSelection =
                        formik.values.subscription_period.includes(2)
                          ? formik.values.subscription_period.filter(
                              (id) => id !== 2
                            )
                          : [...formik.values.subscription_period, 2];
                      formik.setFieldValue("subscription_period", newSelection);
                    }}
                  />
                  <label className="form-check-label">Yıllık</label>
                </div>
                {/* Hata Mesajı */}
                {formik.errors.subscription_period &&
                  formik.touched.subscription_period && (
                    <div className="invalid-feedback d-block">
                      {formik.errors.subscription_period}
                    </div>
                  )}
              </FormGroup>
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={async () => {
            formik.handleSubmit();
          }}
        >
          Kaydet
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ShowCouponModal;
