import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentService } from './services/ContentService';
import ContentListComponent from './components/ContentListComponent';
import Page from "../../../../../layout/Page/Page";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import SubHeader, {
    SubHeaderLeft,
} from "../../../../../layout/SubHeader/SubHeader";
import { adminPages } from "../../../../../menu";

const ContentWrapper = () => {
    const navigate = useNavigate();
    // eslint-disable-next-line
    const contentService = new ContentService('/h5p');

    const [isloggedin, setIsLoggedIn] = useState(false);

    useEffect(() => {
        //login as admin
        //TODO check if already loggedin
        const login = (username) => {
            let userString = localStorage.getItem("user")
            if (userString) {
                const user = JSON.parse(userString)
                username = user.username
            }
            if (!username) navigate("login");

            fetch(`${process.env.REACT_APP_H5P_API_URL}login`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    username: username,
                    password: username
                })
            })
                .then(async (res) => {
                    if (res.status === 200) {

                        const loginData = await res.json();
                        if (loginData.csrfToken) {
                            contentService.setCsrfToken(
                                loginData.csrfToken
                            );
                        }
                    }
                })
                .catch((reason) => {
                    console.error('%cContentWrapper.js line:41 reason', 'color: #007acc;', reason);
                });
        };
        login();
        //eslint-disable-next-line
    }, [])

    useEffect(()=>{

       // if(contentService.csrfToken)
        setIsLoggedIn(true);
    },[contentService])

    if (!isloggedin) return "Loading..."

    return (

        <PageWrapper title={adminPages.practice.text}>
            <SubHeader>
            <SubHeaderLeft>
                <div className="text-muted">
                <strong>
                    <span className="text-info fw-bold">{"İçerikler"}</span>
                </strong>
                </div>
            </SubHeaderLeft>
            </SubHeader>
            <Page>
                <ContentListComponent contentService={contentService}> </ContentListComponent>
            </Page>
        </PageWrapper>
    );
};

export default ContentWrapper;