import React from 'react';
import style from './TalentInfo.module.scss';

const TalentInfo = ({ startDate, endDate, type, score }) => {
  return (
    <div className={style.talentInfoContainer}>
      <div className={`${style.talentInfoItem} row`}>
        <span className={`${style.label} col-10 col-md-6 mx-auto`}>Başlangıç:</span>
        <span className={`${style.talentInfoContent} col-10 col-md-6 mx-auto`}>{startDate}</span>
      </div>
      <div className={`${style.talentInfoItem} row`}>
        <span className={`${style.label} col-10 col-md-6 mx-auto`}>Bitiş:</span>
        <span className={`${style.talentInfoContent} col-10 col-md-6 mx-auto`}>{endDate}</span>
      </div>
      <div className={`${style.talentInfoItem} row`}>
        <span className={`${style.label} col-10 col-md-6 mx-auto`}>Tür:</span>
        <span className={`${style.talentInfoContent} col-10 col-md-6 mx-auto`}>{type}</span>
      </div>
      <div className={`${style.talentInfoItem} row`}>
        <span className={`${style.label} col-10 col-md-6 mx-auto`}>Puan:</span>
        <span className={`${style.talentInfoContent} col-10 col-md-6 mx-auto`}>{score}</span>
      </div>
    </div>
  );
};

export default TalentInfo;
