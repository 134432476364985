import React from "react";
import Button from "../../components/bootstrap/Button";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../components/bootstrap/Modal";


const Confirm = ({open,setOpen,onCancel,onConfirm,title = 'Dikkat',message='İşlemi onaylıyor musunuz?'}) => {
    
  return (
    <div>
      <Modal
        isOpen={open}
        setIsOpen={setOpen}
        titleId="example-title"
      >
        <ModalHeader>
          <ModalTitle id="example-title">{title}</ModalTitle>
        </ModalHeader>
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <Button color="info" onClick={onCancel}>
            İptal
          </Button>
          <Button color="success" onClick={onConfirm}>
            Onayla
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Confirm;
