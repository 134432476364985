import React, { useState, useEffect } from "react";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import PracticeTable from "./PracticeTable";
import { get, del } from "../../../../utils/service";
import { useNavigate } from "react-router-dom";
import { teacherPanel } from "../../../../menu";
import { useDispatch, useSelector } from "react-redux";
import { setPractices } from "../teacherSlice";
import Card, {
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../../components/bootstrap/Card";
import style from "./TeacherPractice.module.scss";
import Search from "../class/search";
import plus from "../../../../assets/dashboardImages/plus.svg";
import DashboardHeader from "../../../common/Headers/DashboardHeader";

const TeacherPractices = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { practices } = useSelector((store) => store.teacher);
  const [filteredPractice, setFilteredPractice] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (newValue) => {
    setSearchValue(newValue);
  };

  const deleteItem = (id) => {
    try {
      del("practices/teachers/" + id).then(() => handlePosts());
    } catch (error) {
    } finally {
    }
  };

  const handlePosts = async () => {
    const result = await get("practices/teachers/");
    dispatch(setPractices(result.data));
  };

  useEffect(() => {
    handlePosts();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    filterPractice();
    //eslint-disable-next-line
  }, [searchValue, practices]);

  const filterPractice = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const newPractices = practices.filter(
      (item) =>
        item.title.toLowerCase().includes(searchValue) ||
        item.explanation.toLowerCase().includes(searchValue)
    );
    setFilteredPractice(newPractices);
  };
  return (
    <PageWrapper title={teacherPanel.practice.text}>
      <DashboardHeader/>
      <Page>
        <Card className={`${style.cardContainer} h-100`}>
          <CardHeader>
            <CardLabel>
              <CardTitle className={`${style.title} ps-3`}>
                Alıştırmalarım
              </CardTitle>
            </CardLabel>
          </CardHeader>
          <div
            className={`${style.actionsContainer} d-flex align-items-center justify-content-center px-2 mx-4 gap-3 row mx-auto`}
          >
            <div className={`${style.inputContainer} col-6 col-md-7 col-xl-9`}>
              <Search
                handleSearchChange={handleSearchChange}
                iconColor={"#5454544F"}
              />
            </div>
            <div
              className={`${style.addPracticeContainer} d-flex align-items-center gap-2 ps-2 col`}
              onClick={() => navigate("/teacher/practice-form")}
            >
              <span>
                <img src={plus} alt="" />
              </span>
              <span>Yeni Alıştırma Ekle</span>
            </div>
          </div>
          <PracticeTable
            practices={practices}
            deleteItem={deleteItem}
            filteredPractice={filteredPractice}
          />
        </Card>
      </Page>
    </PageWrapper>
  );
};

export default TeacherPractices;
