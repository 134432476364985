import React, { useState } from "react";
import Icon from "../../../../../components/icon/Icon";
import PaginationButtons, {
} from "../../../../../components/PaginationButtons";
import useSortableData from "../../../../../hooks/useSortableData";
import { IconButton, Tooltip } from "@mui/material";
import { Delete} from "@mui/icons-material";
import Button from "../../../../../components/bootstrap/Button";

const TestsTable = ({ tests, deleteTest, editTest }) => {
  const active = (
    <Button color="success" icon="" isLight rounded={0}>
      Varsayılan
    </Button>
  );
  const passive = (
    <Button color="danger" icon="" isLight rounded={0}>
    Normal
  </Button>
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const { items, requestSort, getClassNamesFor } = useSortableData(tests);
  return (
    <>
      <table className="table table-modern">
        <thead>
          <tr>
            <th onClick={() => requestSort("title")}>
              Girdi Türü
              <Icon
                size="lg"
                className={getClassNamesFor("firstName")}
                icon="FilterList"
              />
            </th>
            <th onClick={() => requestSort("explanation")}>
              Girdiler
              <Icon
                size="lg"
                className={getClassNamesFor("explanation")}
                icon="FilterList"
              />
            </th>
            <th onClick={() => requestSort("technology")}>
              Beklenen Çıktı
              <Icon
                size="lg"
                className={getClassNamesFor("technology")}
                icon="FilterList"
              />
            </th>
            <th onClick={() => requestSort("technology")}>
              Beklenen Çıktı UI
              <Icon
                size="lg"
                className={getClassNamesFor("technology")}
                icon="FilterList"
              />
            </th>
            <th onClick={() => requestSort("is_active")}>
              Varsayılan Mı
              <Icon
                size="lg"
                className={getClassNamesFor("is_active")}
                icon="FilterList"
              />
            </th>
            <th>Sil</th>
          </tr>
        </thead>
        <tbody>
          {tests.map((item) => (
            <tr key={JSON.stringify(item)}>
              <td>{item.input_type}</td>
              <td>{item.inputs}</td>
              <td>{item.expected_outputs}</td>
              <td>{item.expected_outputs_for_ui}</td>
              <td>{item.is_default_test ? active : passive}</td>
              <td>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => {
                      deleteTest(item);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationButtons
        data={items}
        label="alıştırma"
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </>
  );
};

export default TestsTable;

// 1 alıştırma için arama
// 2 acitons kolonu yap. sil düzenle test
// 3 baş.kod. code edit göster
// 4 yeni alıştırma başlık kalsın
// 5 yeni alış 5. adım özet bilgi daha düszün bir tablo

// facit
