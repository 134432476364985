import React, { useEffect, useState } from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../../components/bootstrap/Modal";
import Logo from "../../../../../components/Logo";
import Icon from "../../../../../components/icon/Icon";
import Button from "../../../../../components/bootstrap/Button";
import FormGroup from "../../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../../components/bootstrap/forms/Input";
import { useFormik } from "formik";
import {
  useClearCacheMutation,
} from "../../../../../store/features/apiSlice";
import showNotification from "../../../../../components/extras/showNotification";
import Textarea from "../../../../../components/bootstrap/forms/Textarea";
import _ from "underscore";
import Checks from "../../../../../components/bootstrap/forms/Checks";
import {ContentService} from '../../course management/h5p/services/ContentService';
import Card, { CardBody, CardHeader } from "../../../../../components/bootstrap/Card";
import { useUpdateCourseTeacherMutation } from "../teacherEducationApi";

const CreateLessonModal = ({
  isOpenCreateLessonModal,
  setIsOpenCreateLessonModal,
  data,
  chapterId,
  currentChapter,
}) => {
  const [updateCourse] = useUpdateCourseTeacherMutation();
  const [clearCache] = useClearCacheMutation();
  const [contents,setContents] = useState([]);
  const [searchValue,setSearchValue] = useState('');
  const [filteredContents,setFilteredContents] = useState([]);

  const contentService = new ContentService('/h5p');

  useEffect(() => {
    async function getContent(){
      const contentList = await contentService.list();
      setContents(contentList.map(m=>{
        return {
          content_id:m.contentId,
          mainLibrary : m.mainLibrary,
          title: m.title,
        }
      
      }));
    }
    getContent();
    //eslint-disable-next-line
  }, []);


  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Konunun adını giriniz";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      id:null,
      title: "",
      order_number: 9999,
      description: null,
      video: "",
      content_id:''
    },
    validate,
    onSubmit: () => {
      handleLesson();
    },
  });

  const handleLesson = async () => {

    const { ...lessonValues } = formik.values;
    
    
    let updatedLessons = currentChapter && [...currentChapter.lessons, lessonValues];    
    updatedLessons = _.sortBy(updatedLessons,"order_number") 
    let updatedChapter = currentChapter && {...currentChapter,lessons:updatedLessons}
    let newChapters =
      data &&
      data.chapters.map((chapter) =>
        chapter.title === currentChapter.title
          ? { ...chapter, ...updatedChapter }
          : chapter
      );
      newChapters = _.sortBy(newChapters,"order_number") 
    const response = await updateCourse({
      id: data.id,
      title: data.title,
      ...data,
      chapters: newChapters,
    });
    if (response.error) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Kayıt Başarısız</span>
        </span>,
        "Bir hata oluştu",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Kayıt Başarılı</span>
        </span>,
        "Konu başarıyla eklendi.",
        "success"
      );
    }
    clearCache();
    setIsOpenCreateLessonModal(false);
    formik.resetForm();
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); 
      saveForm(); 
    }
  };
  const saveForm = async () => {
    try {
      await formik.handleSubmit();
      setIsOpenCreateLessonModal(false); 
    } catch (error) {
      
    }
  };
  const handleCheckboxClick = (event) => {
    const { name, value, checked } = event.target;
  
    if (name === "content_id" && checked) {
      formik.setFieldValue(name, value);
    } else if (name === "content_id" && !checked) {
      formik.setFieldValue(name, null);
    }
  };
  useEffect(() => {
    if(searchValue){
      setFilteredContents(contents.filter(content=>content.title.toLowerCase().includes(searchValue.toLowerCase())));
    }
  },[contents,searchValue])
  
  const _contents = searchValue ? filteredContents : contents;

  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenCreateLessonModal}
      setIsOpen={setIsOpenCreateLessonModal}
      titleId="example-title"
      size={null}
      onKeyDown={handleKeyDown} 
    >
      <ModalHeader setIsOpen={setIsOpenCreateLessonModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} /> <span className="ps-2">Yeni Konu</span>
          <span className="ps-2">
            <Icon icon="Computer" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="row gap-3 d-flex justify-content-center">
          <div className="col-lg-8">
            <FormGroup
              // className="col-4"
              id="title"
              isFloating
              label="Başlık"
            >
              <Input
                onChange={formik.handleChange}
                value={formik.values.title}
                onBlur={formik.handleBlur}
                isValid={formik.isValid}
                isTouched={formik.touched.title}
                invalidFeedback={formik.errors.title}
              />
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup isFloating label="Sıra Numarası" id="order_number">
              <Input
                name="order_number"
                type="text"
                autoComplete="order_number"
                value={formik.values.order_number}
                onChange={formik.handleChange}
                isValid={formik.isValid}
                onBlur={formik.handleBlur}
                isTouched={formik.touched.order_number}
                invalidFeedback={formik.errors.order_number}
              />
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup
              // className="col-4"
              id="description"
              isFloating
              label="Açıklama"
            >
              <Textarea
                onChange={formik.handleChange}
                value={formik.values.description}
                onBlur={formik.handleBlur}
                isValid={formik.isValid}
                isTouched={formik.touched.description}
                invalidFeedback={formik.errors.description}
                style={{ height: "150px" }}
              />
            </FormGroup>
          </div>
          <div style={{overflow:'scroll',height:'250px'}} className="col-lg-8">
            <Card className="">
              <CardHeader>
               <Input value={searchValue} onChange={(e)=>setSearchValue(e.target.value)} type="text" placeholder="İçeriklerde Ara.." />
              </CardHeader>
              <CardBody>
              {
                     <table className="table table-modern">
                  
                     <tbody>
                       {
                       _contents?.map((item) => (
                         <tr key={item.content_id}>
                           <td>
                             <Checks
                                id={item.content_id.toString()}
                                name="content_id"
                                value={item.content_id}
                                onChange={handleCheckboxClick}
                                checked={formik.values.content_id === item.content_id}
                             />
                           </td>
                           <td style={{textAlign:"justify"}}>{item.title}</td>
                         </tr>
                       ))}
                     </tbody>
                   </table>
                }
              </CardBody>
            </Card>
          </div>
          <div className="col-lg-8">
            <FormGroup
              // className="col-4"
              id="video"
              isFloating
              label="Embed Code"
            >
              <Input
                onChange={formik.handleChange}
                value={formik.values.video}
                onBlur={formik.handleBlur}
                isValid={formik.isValid}
                isTouched={formik.touched.video}
                invalidFeedback={formik.errors.video}
              />
            </FormGroup>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={async () => {
            formik.handleSubmit();
          }}
        >
          Kaydet
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateLessonModal;
