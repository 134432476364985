import React from "react";
import CodeRunResult from "./result/CodeRunResult";
import Card, {
  CardActions,
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../components/bootstrap/Card";

function PracticeResult({
  submitPractice,
  practice,
  userCode,
  path_id,
  codeRunResult,
}) {
  return (
    <div>
      <Card stretch={true} className="mt-4">
        <CardHeader borderSize={1}>
          <CardLabel iconColor="info">
            <CardTitle>Sonuç Paneli</CardTitle>
          </CardLabel>
          <CardActions>
            <button
            onClick={() => submitPractice(practice.id, path_id, userCode)}
              type="button"
              className="btn btn-outline-success btn-lg btn-hover-shadow-lg shadow-none rounded-2"
            >
              KODU ÇALIŞTIR
            </button>
          </CardActions>
        </CardHeader>
        <CardBody className="table-responsive" isScrollable={false}>
          {codeRunResult ?
                  <CodeRunResult path_id={path_id} codeRunResult={codeRunResult} /> : ""}
        </CardBody>
      </Card>
    </div>
  );
}

export default PracticeResult;
