import React, { useState } from "react";
import style from "./TeacherResourceWrapper.module.scss";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import DashboardHeader from "../../../../common/Headers/DashboardHeader";
import Page from "../../../../../layout/Page/Page";
import document1 from "../../../../../../src/assets/lessons/document1.svg";
import document2 from "../../../../../../src/assets/lessons/document2.svg";
import article1 from "../../../../../../src/assets/lessons/article1.svg";
import article2 from "../../../../../../src/assets/lessons/article2.svg";
import video1 from "../../../../../../src/assets/lessons/video1.svg";
import video2 from "../../../../../../src/assets/lessons/video2.svg";
import TeacherVideos from "./teacherVıdeos/TeacherVideos";
import TeacherDocuments from "./teacherDocuments/TeacherDocuments";
import TeacherArticles from "./teacherArticles/TeacherArticles";

const TeacherResourceWrapper = () => {
  const _activeTab = sessionStorage.getItem("activeTabSource");
  const [activeTab, setActiveTab] = useState(Number(_activeTab) || 1);

  const tabContents = [
    {
      content: <TeacherVideos />,
      tabName: "Videolar",
      icon1: video1,
      icon2: video2,
    },
    {
      content: <TeacherDocuments/>,
      tabName: "Dokümanlar",
      icon1: document1,
      icon2: document2,
    },
    {
      content: <TeacherArticles/>,
      tabName: "Makaleler",
      icon1: article1,
      icon2: article2,
    },
  ];

  const handleTabClick = (tabNumber) => {
    if (tabNumber >= 1 && tabNumber <= tabContents.length) {
    sessionStorage.setItem("activeTabSource", tabNumber);
    setActiveTab(tabNumber);}
  };

  // useEffect(() => {
  //   if (window.Tawk_API) {
  //     window.Tawk_API.hideWidget();
  //   }
  //   return () => {
  //     if (window.Tawk_API) {
  //       window.Tawk_API.showWidget();
  //     }
  //   };
  // }, []);
  return (
    <PageWrapper title={"Dersler"}>
      <DashboardHeader />
      <Page container={false} style={{ padding: "0" }}>
        <div className="page container-xxl">
        <div
          className={`${style.tabContainer} d-flex justify-content-evenly align-items-center mx-auto mb-5 px-3 gap-3`}
        >
          {tabContents.map((tab, index) => (
            <div
              key={index}
              className={`${style.tab} ${
                activeTab === index + 1 ? `${style.activetab}` : ""
              } d-flex flex-column justify-content-center align-items-center gap-2 p-3`}
              onClick={() => handleTabClick(index + 1)}
            >
              <div className={`${style.tabsLogo}`}>
                <img
                  src={activeTab === index + 1 ? tab.icon2 : tab.icon1}
                  alt=""
                />
              </div>
              <div className={`${style.tabsDescription}`}>
                <span>{tab.tabName}</span>
              </div>
            </div>
          ))}
        </div>
         <div className="tab-content">
            {tabContents[activeTab - 1] && tabContents[activeTab - 1]?.content}
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default TeacherResourceWrapper;
