// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media screen and (max-width: 992px) {\n  .PathWithoutClass_pathsContainer__wP0GP {\n    position: relative;\n    bottom: 50px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/t14/lessons/pathWithoutClass/PathWithoutClass.module.scss"],"names":[],"mappings":"AAIA;EACI;IACI,kBAAA;IACA,YAAA;EAHN;AACF","sourcesContent":[".pathsContainer{\n    // border: solid;\n    // width: 1280px;\n}\n@media screen and (max-width:992px) {\n    .pathsContainer{\n        position: relative;\n        bottom: 50px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pathsContainer": "PathWithoutClass_pathsContainer__wP0GP"
};
export default ___CSS_LOADER_EXPORT___;
