import React, { useEffect, useState } from "react";
import Chart from "../../../../../../components/extras/Chart";
import style from "../ParentReportWithClass.module.scss";

const ChartInfo = ({ value }) => {
  const values = value.map((item) => item.percentage);
  const titles = value.map((item) => item.title);
  const [customValue, setCustomValue] = useState(68);
  const isMobile = Number(customValue) === 29;

  // Pencere boyutu değiştiğinde bu işlev çalışacak
  const handleWindowResize = () => {
    const newWindowWidth = window.innerWidth;

    if (newWindowWidth < 501) {
      setCustomValue(29);
    } else {
      setCustomValue(68);
    }
  };

  useEffect(() => {
    // Component monte edildiğinde ve pencere boyutu değiştiğinde işlevi çağır
    window.addEventListener("resize", handleWindowResize);

    // Component kaldırıldığında olay dinleyicisini temizle
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div className={`${style.barChart}`}>
      <Chart
        type={"bar"}
        height={`${isMobile ? "250px" : "400px"}`}
        width={values.length * customValue}
        series={[
          {
            name: "",
            data: values,
          },
        ]}
        options={{
          colors: ["#6C5DD3"],
          labels: ["Dersler"],
          plotOptions: {
            bar: {
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          //! Barların üzerinde değerleri buradan gösterebiliriz.
          dataLabels: {
            enabled: false,
            formatter: function (val) {
              if (Number(val) === 0) {
                return "";
              } else {
                return Number(val).toLocaleString();
              }
            },
            offsetY: -20,
            style: {
              fontSize: "12px",
              colors: ["#304758"],
            },
          },
          xaxis: {
            categories: titles,
            position: "bottom",
            labels: {
              show: true,
              offsetY: 0,
              rotate: -90,
              rotateAlways: true,
              hideOverlappingLabels: true,
              trim: true,
              minHeight: 50,
              showDuplicates: false,
              // minHeight: 60,
              maxHeight: isMobile ? 130 : 175,
              style: {
                fontSize: `${isMobile ? "10px" : "16px"}`,
                fontWeight: 600,
                colors: "#6C5DD3",
                height: 300,
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: true,
            },
            tooltip: {
              enabled: false,
              offsetY: -35,
            },
          },
          yaxis: {
            min: 0,
            max: 100,
            tickAmount: 5,
            axisBorder: {
              show: true,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
              style: {
                fontSize: `${isMobile ? "10px" : "16px"}`,
                fontWeight: 600,
                colors: "#6C5DD3",
              },
              formatter: function (val) {
                return Number(val).toLocaleString();
              },
            },
          },
        }}
      />
    </div>
  );
};

export default ChartInfo;
