import React from "react";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import { teacherPanel } from "../../../../menu";
import TeacherClassTable from "./teacher-class-table";
import DashboardHeaderWithTitle from "../../../common/Headers/DashboardHeaderWithTitle";

const TeacherClassesWrapper = () => {
  const schoolId = localStorage.getItem("school")
  const isSchool = schoolId > 0;
  return (
    <PageWrapper title={teacherPanel.teacher_classes.text}>
      <DashboardHeaderWithTitle title="Sınıflarım" />
      <Page>
        <div className="row h-100">
          <div className="col-12">
            <TeacherClassTable isFluid isSchool={isSchool}/>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default TeacherClassesWrapper;
