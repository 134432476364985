import React from "react";
import style from "./paths.module.scss";
import PathContent from "./2-PathContent";
import SingleLesson from "./3-SingleLesson";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css"; //! styles.scss dosyasında
import { useMediaQuery } from "react-responsive";

const CustomPrevArrow = (props) => {
  const { onClick, currentSlide } = props;
  return (
    <div
      className={`${style.customPrevArrow}`}
      onClick={onClick}
      style={{ display: currentSlide === 0 ? "none" : "block" }}
    >
      {"<"}
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { onClick, currentSlide, slideCount, slideToShow } = props;
  return (
    <div
      className={`${style.customNextArrow}`}
      onClick={onClick}
      style={{
        display: currentSlide === slideCount - slideToShow ? "none" : "block",
      }}
    >
      {">"}
    </div>
  );
};

export { CustomPrevArrow, CustomNextArrow };

const SinglePath = ({ path }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 992 });
  const isDesktop = useMediaQuery({ minWidth: 992, maxWidth: 1199 });
  const isLargeDesktop = useMediaQuery({ minWidth: 1200 });

  const {description, title, order_number, status, courses, completion_status } = path;

  const slideToShow =
    isLargeDesktop && courses.length >= 4
      ? 4
      : isDesktop && courses.length >= 3
      ? 3
      : isTablet && courses.length >= 2
      ? 2
      : isMobile
      ? 1
      : courses.length >= 4
      ? 4
      : courses.length;

  const settings = {
    dots: false, // Nokta navigasyonu göster
    infinite: false, // Sonsuz döngü
    speed: 500, // Slayt geçiş hızı (ms cinsinden)
    slidesToShow: slideToShow, // Ekranda gösterilecek slayt sayısı
    slidesToScroll: 1, // Kaç slayt ilerleyeceği
    className: `${style.mySlider}`, // Özel stil adını burada belirtin
    prevArrow: <CustomPrevArrow slideToShow={slideToShow} />, // Özel sol ok bileşeni
    nextArrow: <CustomNextArrow slideToShow={slideToShow} />, // Özel sağ ok bileşeni
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 768,
    //     settings: {
    //       slidesToShow: 1,
    //     },
    //   },
    // ],
  };

  return (
    <div
      className={`${style.pathContainer} my-2`}
    >
      <div
        className={`${style.leftSection} 
       mx-auto py-3`}
      >
        <PathContent
          title={title}
          description={description}
          status={status}
          order_number={order_number}
          completion_status={completion_status}
        />
      </div>

      <div
        className={`${style.lessonsContainer} mx-auto py-3 px-5`}
      >
        <Slider {...settings} className={`${style.mySlider}`}>
          {courses?.map((course) => (
            <div key={course.id} className="">
              <SingleLesson course={course} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SinglePath;
