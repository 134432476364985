import React from 'react'
import style from "./Banner.module.scss"

const Banner = ({banner}) => {
  return (
    <div className={`${style.bannerContainer}`}>
        <img src={banner} alt="Banner" />
  </div>
  )
}

export default Banner