import React, { useEffect } from 'react'
import style from "./Attendance.module.scss"
import { useCreateAttendanceForClassroomMutation, useGetAttendanceOf_all_LiveLectureQuery, useGetLiveLecturesForClassQuery } from '../../../teacher/teacherApi';
import { useParams } from 'react-router-dom';
import Icon from '../../../../../components/icon/Icon';
import AttendanceOfSingleLecture from './AttendanceOfSingleLecture';
import { formatDate2TurkishShortDate } from '../../../../../utils/formatDate';
import _ from "underscore";
import { nanoid } from "nanoid";

const Attendance = () => {
    const { classId } = useParams();
      // mutations and queries
const { data: liveLessons } = useGetLiveLecturesForClassQuery(classId);

const { data: allAttendanceList } =
  useGetAttendanceOf_all_LiveLectureQuery(classId);
const [createAttendanceForClass] = useCreateAttendanceForClassroomMutation();

//   Mevcut sınıfların yoklama alt yapısı oluşması için bu işlem yapılıyor.
  useEffect(() => {
    if (allAttendanceList) {
      if (
        allAttendanceList.length > 0 &&
        allAttendanceList[0].live_lecture.length < 1 &&
        liveLessons.length > 0
      ) {
        createAttendanceForClass(classId);
      }
    }
     // eslint-disable-next-line
  }, []);

  let orderedLiveLessons = _.sortBy(liveLessons, "id").reverse();
  return (
    <div className={`${style.cardContainer}`}>
    <div
      className={`${style.title} d-flex align-items-center justify-content-center mx-auto`}
    >
      <Icon icon="People" size="2x" color="light" />
      YOKLAMA
    </div>
    <div className={`${style.liveLessonAttendanceTable} px-4`}>
      <div className="d-flex">
        <div className={`${style.tableLeftSection}`}>
          <div
            className={`${style.leftSectionTop} d-flex align-items-center justify-content-center`}
          >
            Öğrenci Ad-Soyad
          </div>

          {/* Student Name*/}
          {allAttendanceList &&
            allAttendanceList.length > 0 &&
            allAttendanceList.map((item) => (
              <div
                className={`${style.leftSectionBottom} d-flex align-items-center justify-content-center`}
                key={nanoid()}
              >
                {item.full_name.includes("None") ? item.email.split("@")[0] : item.full_name}
              </div>
            ))}
        </div>

        <div className={`${style.tableRightSection}`}>
          {/* Live Lesson Titles */}
          <div
            className={`${style.rightSectionTop} d-flex align-items-center`}
          >
            {liveLessons && liveLessons.length < 1 ? (
              <span
                className={`${style.liveLessonTitle} d-flex align-items-center justify-content-center w-100`}
                style={{ borderRadius: "7px" }}
              >
                {/* Sınıfa atanmış ödev bulunmamaktadır */}
              </span>
            ) : (
              orderedLiveLessons &&
              orderedLiveLessons.map((item, i) => {
                return (
                  <div
                    className={`${style.liveLessonTitle} d-flex align-items-center justify-content-center`}
                    key={nanoid()}
                  >
                    <div className={`${style.titleContainer}`}>
                      <div className={`${style.titleShort} text-center`}>
                        <div>{item.title}</div>
                        {/* <div>{item.id}</div> */}
                        <div>
                          {formatDate2TurkishShortDate(
                            item.date
                          )}
                        </div>
                      </div>
                      <div className={`${style.titleFull}`}>
                        {item.title}
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>

          {/* Homework Complete Status */}
          <div className={`${style.rightSectionBottom}`}>
            {allAttendanceList &&
              allAttendanceList.length > 0 &&
              allAttendanceList.map((allLiveLecture) => {
                let orderedStudentLiveLessons =
                  allLiveLecture.live_lecture.filter((item1) =>
                    orderedLiveLessons.some(
                      (item2) => item1.id === item2.id
                    )
                  );
                orderedStudentLiveLessons =
                  orderedStudentLiveLessons.sort((a,b)=>b.id - a.id);
                return (
                  <div
                    className={`${style.rightSectionBottom} d-flex align-items-center`}
                    key={nanoid()}
                  >
                    {orderedStudentLiveLessons.map((attendance, i) => {
                      // console.log("orderedStudentLiveLessons",orderedStudentLiveLessons);
                      return (
                        <div
                          className={`${style.studentLiveLessonStatus} d-flex flex-column align-items-center justify-content-center`}
                          key={nanoid()}
                        >
                          <AttendanceOfSingleLecture attendance={attendance} user={allLiveLecture.user} liveLessons={orderedLiveLessons}/>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Attendance