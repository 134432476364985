import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import Button from '../components/bootstrap/Button';

// Error Boundary bileşeni
function ErrorFallback({ error, resetErrorBoundary }) {
  const navigate = useNavigate();

  const redirectToHome = () => {
    resetErrorBoundary();
    navigate("/");
  }

  return (
    <div className='mx-auto mt-5'>
      <h2>Beklenmedik bir hata oluştu!</h2>
      <p>Sorunu en kısa sürede çözeceğiz</p>
      {/* <p>{error.message}</p> */}
      <Button
                    color="info"
                    isLink
                    icon="ArrowBack"
                    tag="a"
                    onClick={redirectToHome}
                >
                    Ana Sayfaya Geri Dön
                </Button>
    </div>
  );
}

// Error Boundary wrapper bileşeni
function ErrorBoundaryWrapper({ children }) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {children}
    </ErrorBoundary>
  );
}

export default ErrorBoundaryWrapper;

