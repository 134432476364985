import React, { useEffect, useState } from "react";
import Card from "../../../../../../components/bootstrap/Card";
import Button from "../../../../../../components/bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { setMenu } from "../restrictionSlice";
import { menuList } from "./menuList";

const ContentLeftMenu = () => {
  const { menu } = useSelector((store) => store.restriction);
  const [activeButton, setActiveButton] = useState();

  const dispatch = useDispatch();
  const handleClick = (menuItem) => {
    dispatch(setMenu(menuItem));
    setActiveButton(menuItem);
  };

  useEffect(() => {
    if(menu){
      setActiveButton(menu)
    }else{
      setActiveButton("Dersler")
    }
  }, [menu])
  
  return (
    <Card
      className="d-flex gap-3 align-items-center justify-content-center py-2"
      style={{ minHeight: "95%" }}
    >
      {Object.values(menuList).map((item, index) => (
        <div className="mx-auto" key={item}>
          <Button
            onClick={() => handleClick(item)}
            isActive = {activeButton === item}
            className={`btn btn-${!(activeButton === item) ? 'outline-primary' : 'primary'} fs-5`}
            isOutline={true}
            style={{ width: "150px" }}
          >
            {item}
          </Button>
          <br />
        </div>
      ))}
    </Card>
  );
};

export default ContentLeftMenu;
