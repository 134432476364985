import React, { useState } from "react";
import style from "./LearningPathWrapper.module.scss";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Page from "../../../layout/Page/Page";
import DashboardHeader from "../../common/Headers/DashboardHeader";
import lessonsLogo1 from "../../../assets/lessons/lessonsLogo1.svg";
import lessonsLogo2 from "../../../assets/lessons/lessonsLogo2.svg";
import LearninnPaths from "./LearninnPaths";
import TeacherPractices from "./TeacherPractices";

const LearningPathWrapper = () => {
  const _activeTab = sessionStorage.getItem("activeTabCodelab");
  const [activeTab, setActiveTab] = useState(Number(_activeTab) || 1);

  const tabContents = [
    {
      content: <LearninnPaths/>,
      tabName: "Platform Alıştırmaları",
      icon1: lessonsLogo1,
      icon2: lessonsLogo2,
    },
    {
      content: <TeacherPractices />,
      tabName: "Eğitmen Alıştırmaları",
      icon1: lessonsLogo1,
      icon2: lessonsLogo2,
    },
  ];
  const handleTabClick = (tabNumber) => {
    if (tabNumber >= 1 && tabNumber <= tabContents.length) {
    sessionStorage.setItem("activeTabCodelab", tabNumber);
    setActiveTab(tabNumber);}
  };
  return (
    <PageWrapper title={"Dersler"}>
      <DashboardHeader />
      <Page
        container={false}
        style={{ padding: "0" }}
        className={`${style.pageContainer}`}
      >
        <div
          className={`${style.tabContainer} d-flex justify-content-evenly align-items-center mx-auto mb-5 px-3 gap-1`}
        >
                 {tabContents.map((tab, index) => (
            <div
              key={index}
              className={`${style.tab} ${
                activeTab === index + 1 ? `${style.activetab}` : ""
              } d-flex justify-content-center align-items-center gap-2 p-3`}
              onClick={() => handleTabClick(index + 1)}
            >
              <div className={`${style.tabsLogo}`}>
                <img
                  src={activeTab === index + 1 ? tab.icon2 : tab.icon1}
                  alt=""
                />
              </div>
              <div className={`${style.tabsDescription}`}>
                <span>{tab.tabName}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="tab-content">
            {tabContents[activeTab - 1] && tabContents[activeTab - 1]?.content}
          </div>
      </Page>
    </PageWrapper>
  );
};

export default LearningPathWrapper;
