import React, { useEffect, useRef, useState } from "react";
import { useHoverDirty } from "react-use";
import {
  CardLabel,
  CardTitle,
} from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import Spinner from "../../../components/bootstrap/Spinner";
import showNotification from "../../../components/extras/showNotification";
import Icon from "../../../components/icon/Icon";
import PracticeItem from "../../../components/PracticeItem";
import style from "./LearningPath.module.scss"
import { nanoid } from "nanoid";

const LearningPathPractices = ({ learning_path_id, practices, technology }) => {
  const [search, setSearch] = useState("")
  const [filteredPractice, setFilteredPractice] = useState("")

  const ref = useRef(null);
  const isHovering = useHoverDirty(ref);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    localStorage.setItem("sortedPractices",JSON.stringify(practices?.map((item)=>item.practice_id)))
  },[practices])


  useEffect(() => {
    const timer = isLoading
      ? setTimeout(() => {
        setIsLoading(false);
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Updated Successfully</span>
          </span>,
          "Transfer list has been updated successfully"
        );
      }, 1500)
      : null;
    return () => clearTimeout(timer);
  }, [isLoading]);

  useEffect(() => {
    filterPractice();
    //eslint-disable-next-line
  }, [search, practices]);

  
  
  const filterPractice = (e) => {
    if (e && e.preventDefault) { e.preventDefault(); }
    const newPractices = practices?.filter((item)=>item.practice_title.toLocaleLowerCase().includes(search))
    setFilteredPractice(newPractices)
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className={`${style.topSection} px-0 bg-transparent d-flex justify-content-between my-3`}>
            <CardLabel className="order-0">
              <CardTitle>Alıştırmalar</CardTitle>
            </CardLabel>
            <div className={`${style.searchSection} col-8 col-md-6 order-1`}>
            <form className="d-flex gap-1" onSubmit={filterPractice}>
            <input className="form-control" type="search" placeholder="Aramak istediğiniz soruyu yazınız" value={search} onChange={(e)=>setSearch(e.target.value.toLowerCase())}/>
            <button className="btn btn-warning w-25">Bul</button>
          </form> 
          </div>
            <div className={`${style.actionButton} order-2`}>
              <Button
                ref={ref}
                color="info"
                isLight
                icon={isLoading ? null : "PublishedWithChanges"}
                onClick={() => {
                  ref.current.blur();
                  setIsLoading(true);
                }}
              >
                {isLoading && (
                  <Spinner
                    color={isHovering ? "light" : "info"}
                    inButton
                    isSmall
                  >
                    Loading...
                  </Spinner>
                )}
                Yenile
              </Button>
            </div>
          </div>
          {!filteredPractice ?
            practices?.map((practice) => (
              <PracticeItem
                key={nanoid()}
                practice={practice.practice_id}
                learning_path_id={learning_path_id}
                text={practice.practice_title}
                imgWidth={50}
                status={practice.practice_status}
                img={"/images/" + technology + ".png"}
                practiceSubscriptionType={practice.practice_subscription_type}
              />
            ))
          :  
          // null
          filteredPractice.map((practice) => (
            <PracticeItem
              key={nanoid()}
              practice={practice.practice_id}
              learning_path_id={learning_path_id}
              text={practice.practice_title}
              imgWidth={50}
              status={practice.practice_status}
              img={"/images/" + technology + ".png"}
              practiceSubscriptionType={practice.practice_subscription_type}
            />
          ))
          }
        </div>
      </div>
    </>
  );
};

export default LearningPathPractices;
