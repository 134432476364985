import React from "react";
import SinglePath from "../components/1-SinglePath";
import { nanoid } from "nanoid";
import style from "./PathWithClass.module.scss";
import { useGetAllTrailsInStudentsClassQuery } from "../../teacher/teacherApi";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../layout/Page/Page";

const PathsWithClass = () => {
  const { data } = useGetAllTrailsInStudentsClassQuery();
  const pathsData = data && data;
  return (
    <PageWrapper>
    <Page>
    <div className={`${style.pathsContainer} d-flex flex-column mx-2 mx-lg-5`}>
      {pathsData?.length === 0 ? (
        <div className="" style={{height:"400px"}}>
            <div className={`mt-2 p-3 px-5`}>
          <span className="col-4">Sınıfınıza henüz bir patika eklenmemiştir.</span>
        </div>
        </div>
      ) : (
        pathsData?.map((path) => <SinglePath path={path} key={nanoid()} />)
      )}
    </div>
    </Page>
    </PageWrapper>
  );
};

export default PathsWithClass;
