import React from 'react'
import style from "../ParentReportWithClass.module.scss"
import StudentInfo from './StudentInfo'
import studentLogoWhite from "../../../../../../assets/dashboardImages/studentLogoWhite.svg";
import starWhite from "../../../../../../assets/dashboardImages/starWhite.svg";

const ParentHeader = ({data}) => {
    const rankInClass = data.rank_in_classroom
    const rankInGeneral = data.rank_in_general
    const allStudentsInClass = data.student_count
    const classPoints = data.classroom_points
    const GeneralPoints = data.general_points
    const fullName = data.full_name;
    const classroom = data.classroom;
  return (
    <div className={`${style.headerContainer} mx-auto`}>
                <div className={`${style.headerLeft}`}>
                    <p>{fullName}</p>
                    <p>{classroom}</p>
                </div>
                <div className={`${style.headerRight}`}>
                <StudentInfo logo={studentLogoWhite} classDescription={"Sınıf Sıralama"} detail={rankInClass + "/" + allStudentsInClass} generalDescription={"Genel Sıralama"} generalDetail={rankInGeneral}/>
                <StudentInfo logo={starWhite} classDescription={"Sınıf Puanı"} detail={classPoints} generalDescription={"Genel Puanı"} generalDetail={GeneralPoints}/>
                </div>
    </div>
  )
}

export default ParentHeader