import React from "react";
import {
  useGetClassStudentQuery,
  useHomeworksOfClassQuery,
  useRemoveStudentFromClassMutation,
} from "../teacherApi";
import Card, {
} from "../../../../components/bootstrap/Card";
// import { formatDate } from "../../../../utils/formatDate";
import PaginationButtons, {
  PER_COUNT,
  dataPagination,
} from "../../../../components/PaginationButtons";
import { useState } from "react";
import Icon from "../../../../components/icon/Icon";
import Confirm from "../../../common/Confirm";
import ClassLeaderBoard from "./ClassLeaderBoard";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setSelectedHomework } from "../teacherSlice";
import style from "./styles.module.scss";
import trash from "../../../../assets/dashboardImages/trash.svg";

const TeacherClassPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { classId } = useParams();

  // variables
  let userWarning;

  //state
  const [sutudentInClassId, setSutudentInClassId] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_COUNT["5"]);

  //query
  const { data: students } = useGetClassStudentQuery({id:classId, page:`page=${currentPage}`, page_size:`page_size=${perPage}`});
  const { data: allHomeWorks } = useHomeworksOfClassQuery(classId);
  let homeWorks = (allHomeWorks && allHomeWorks?.length > 0) && allHomeWorks?.filter((item)=>item.status===1)

  //mutation
  const [removeStudentFromClass] = useRemoveStudentFromClassMutation();

  const items = students ? students.results : [];
  //methods
  function handleRemoveStudentFromClass() {
    console.log({ class_id: classId, student_in_class_id: sutudentInClassId });

    removeStudentFromClass({
      class_id: classId,
      student_in_class_id: sutudentInClassId,
    });
    setOpenConfirmModal(false);
  }

  //render
  return (
    <div>
      <Confirm
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        onConfirm={handleRemoveStudentFromClass}
        onCancel={() => setOpenConfirmModal(false)}
      />
      <div className={`${style.RowContainer} row mt-1`}>
        <div className={`${style.RowContainerSection1} col-12 col-lg-6 px-5`}>
          <Card className={`${style.studentsCard}`}>
            <div className={`${style.customTable} mt-5 px-4`}>
              <div className={`d-flex my-3 px-2`}>
                <div
                  className={`${style.customTableHead1} ${style.customTableHead}`}
                >
                  Ad/Soyad
                </div>
                <div
                  className={`${style.customTableHead2} ${style.customTableHead}`}
                >
                  {/* Kayıt Tarihi */}
                </div>
                <div
                  className={`${style.customTableHead3} ${style.customTableHead} text-center`}
                >
                  Puan
                </div>
                <div
                  className={`${style.customTableHead4} ${style.customTableHead} text-center`}
                >Sınıftan Çıkar</div>
              </div>
              <hr
                className={`mx-auto mt-3 mb-4`}
                style={{
                  background: "#B6B5C8",
                  width: "100%",
                  position: "relative",
                  right: "0px",
                }}
              />
              <div className={`${style.customRowContainer}`}>
                {items.length === 0 ? (
                  <div>
                    <span className={`${style.emptyTable}`}>Sınıfa kayıtlı öğrenci bulunamadı.</span>
                  </div>
                ) : (
                  dataPagination(items, currentPage, perPage).map((item, i) => {
                    const isUserInfo = item.first_name === null;
                    const userInfo = (
                      <span>
                        {item.first_name} {item.last_name}
                      </span>
                    );
                    userWarning = (
                      <span className="text-danger">
                        Ad soyad bilgisi gereklidir.
                      </span>
                    );
                    return (
                      <div
                        key={item.id}
                        className={`${style.customTableRow} ${
                          i % 2 === 0 && style.customBackgroundColor
                        } d-flex align-items-center mt-1 px-2`}
                      >
                        <div
                          className={`${style.customTableRow1} cursor-pointer`}
                        >
                          <div
                            onClick={() =>
                              navigate(`/teacher/${classId}/student/${item.id}`)
                            }
                          >
                            {/* {item.first_name} {item.last_name} */}
                            {!isUserInfo ? userInfo : userWarning}
                          </div>
                        </div>
                        <div className={`${style.customTableRow2}`}>
                          <div className="d-flex">
                            <div className="flex-grow-1 d-flex align-items-center text-nowrap">
                              {/* <div>{formatDate(item.join_date)}</div> */}
                            </div>
                          </div>
                        </div>
                        <div className={`${style.customTableRow3} text-center`}>
                          <div className="mx-auto py-1">
                            <div>{item.class_points}</div>
                          </div>
                        </div>
                        <div className={`${style.customTableRow4} text-center`}>
                          <div className="d-flex justify-content-center gap-2">
                            <img
                              src={trash}
                              alt=""
                              className={`${style.trashIcon}`}
                              onClick={() => {
                                setSutudentInClassId(item.id);
                                setOpenConfirmModal(true);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
            <div className={`${style.paginationButtonsDiv}`}>
            <PaginationButtons
              data={items}
              label="öğrenci"
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              perPage={perPage}
              setPerPage={setPerPage}
              customBackgroundColor={"#F6F6F6"}
            />
            </div>
          </Card>
        </div>
        <div className={`${style.RowContainerSection2} col-12 col-lg-6 px-5`}>
          <Card className={`${style.homeWorksCard}`}>
            <div className={`${style.customTable} mt-5 px-4`}>
              <div className={`d-flex my-3 px-2`}>
                <div
                  className={`${style.customTableHead1} ${style.customTableHead}`}
                >
                  Aktif Ödevler
                </div>
              </div>
              <hr
                className={`mx-auto mt-3 mb-4`}
                style={{
                  background: "#B6B5C8",
                  width: "100%",
                  position: "relative",
                  right: "0px",
                }}
              />
              <div className={`${style.customRowContainer}`}>
                {homeWorks && homeWorks.length === 0 ? (
                  <div>
                    <span className={`${style.emptyTable}`}>Aktif Ödev Bulunamadı.</span>
                  </div>
                ) : (
                  homeWorks &&
                  homeWorks.map((item, i) => (
                    <div
                      key={item.id}
                      className={`${style.customTableRow} ${
                        i % 2 === 0 && style.customBackgroundColor
                      } d-flex align-items-center mt-1 px-2`}
                    >
                      <div
                        className={`${style.customTableRow1} cursor-pointer`}
                      >
                        <div
                          onClick={() => {
                            dispatch(setSelectedHomework(item));
                            navigate(`/teacher/new-homework`);
                          }}
                        >
                          <Icon
                            icon={"computer"}
                            size="3x"
                            className={`${style.homeworkLogo} me-2`}
                          />
                          {item.title}
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </Card>
        </div>
      </div>
      <div className={`${style.RowContainerSection3} row gap-5`}>
        {items?.length > 0 && <ClassLeaderBoard classId={classId} />}
      </div>
    </div>
  );
};

export default TeacherClassPage;
