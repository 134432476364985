import React from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../components/bootstrap/Modal";
import Logo from "../../../../components/Logo";
import Icon from "../../../../components/icon/Icon";
import Button from "../../../../components/bootstrap/Button";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import { useFormik } from "formik";
import showNotification from "../../../../components/extras/showNotification";
import { useUpdatePasswordWithAdminMutation } from "../../../../store/features/apiSlice";
import USER_ROLES from "../../../../common/data/enumUserRole";
import { useUpdatePasswordWithSchoolManagerMutation } from "../../schoolManager/schoolManagerApi";

const UpdatePasswordWithAdminModal = ({
  isOpenUpdatePasswordModal,
  setIsOpenUpdatePasswordModal,
  userId,
}) => {
  const role = localStorage.getItem("roles");
  const isAdmin = Number(role) === USER_ROLES.ADMIN.id;
  const isSchoolManager = Number(role) === USER_ROLES.SCHOOL_MANAGER.id;

const [updatePassword] = useUpdatePasswordWithAdminMutation();
const [updatePasswordWithSchoolManager] = useUpdatePasswordWithSchoolManagerMutation();

const validate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = "Şifre gereklidir.";
  }
     if (
    values.password.length > 0 &&
    !/[a-z|A-Z]/.test(values.password)
  ) {
    errors.password = "Şifre en az bir harf içermelidir.";
  }
  if (
    values.password.length > 0 &&
    !/[0-9]/.test(values.password)
  ) {
    errors.password = "Şifre en az bir rakam içermelidir.";
  }
  else if (values.password.length < 8) {
    errors.password = "Şifre en az 8 karakter olmalıdır.";
  }
  if (!values.password2) {
    errors.password2 = "Şifre tekrarı gereklidir.";
  }
  if (!errors.password && values.password !== values.password2) {
    errors.password2 = "Şifreler eşleşmiyor.";
  }
  return errors;
}
  const formik = useFormik({
    initialValues: {
        password: '',
        password2: '',
      },
    validate,
    onSubmit: () => {
      handlePassword();
    },
  });

  const handlePassword = async () => {
    const passwordValues = { ...formik.values };
    let response;
    if(isAdmin){
      response = await updatePassword({ id:userId, ...passwordValues });
    }
    if(isSchoolManager){
      response = await updatePasswordWithSchoolManager({ id:userId, ...passwordValues });
    }
    if (response.error) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Parola Değiştirme Başarısız</span>
        </span>,
        "Bir hata oluştu",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Parola Değiştirme Başarılı</span>
        </span>,
        "Parola başarıyla güncellendi.",
        "success"
      );
    }
    setIsOpenUpdatePasswordModal(false);
    formik.resetForm();
  };
  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenUpdatePasswordModal}
      setIsOpen={setIsOpenUpdatePasswordModal}
      titleId="example-title"
      size={null}
    >
      <ModalHeader setIsOpen={setIsOpenUpdatePasswordModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} /> <span className="ps-2">Yeni Şifre Oluştur</span>
          <span className="ps-2">
            <Icon icon="Key" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="row gap-3 d-flex justify-content-center">
        <div className="col-12">
                    <FormGroup isFloating label="Şifre">
                      <Input
                        name="password"
                        type="password"
                        autoComplete="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        isValid={formik.isValid}
                        onBlur={formik.handleBlur}
                        isTouched={formik.touched.password}
                        invalidFeedback={formik.errors.password}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-12">
                    <FormGroup isFloating label="Şifre (Tekrar)">
                      <Input
                        name="password2"
                        type="password"
                        autoComplete="password"
                        value={formik.values.password2}
                        onChange={formik.handleChange}
                        isValid={formik.isValid}
                        onBlur={formik.handleBlur}
                        isTouched={formik.touched.password2}
                        invalidFeedback={formik.errors.password2}
                      />
                    </FormGroup>
                  </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={async () => {
            formik.handleSubmit();
          }}
        >
          Kaydet
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UpdatePasswordWithAdminModal;
