import React, { useState } from "react";
import RestrictionCard from "../components/RestrictionCard";
import SimpleSelectList from "../components/SimpleSelectList";
import {
  useGetSchoolCoursesInClassRestrictionsQuery,
 useUpdateSchoolCoursesInClassRestrictionsMutation,
} from "../classRestrictionApi";
import { useSelector } from "react-redux";
import Confirm from "../../../../common/Confirm";

const CourseList = () => {
  const { currentClass } = useSelector((store) => store.restriction);

  const {
    data: coursesForSchoool,
    isLoading,
    error,
  } = useGetSchoolCoursesInClassRestrictionsQuery(currentClass, { skip: !currentClass });
  let selectedCourses = coursesForSchoool
    ?.filter((item) => item.restricted === true)
    ?.map((item) => item.id);

  const [updateSchoolCourses] = useUpdateSchoolCoursesInClassRestrictionsMutation(currentClass);

    //! confirm
    const [open, setOpen] = useState(false);
    const [courseId, setCourseId] = useState(null);
    const handleCancel = () => setOpen(false);
    const changeStatus = (id) => {
      setCourseId(id);
      setOpen(true);
    };
    const handleCourse = async () => {
      setRestrictedCourses(courseId);
    };

  function setRestrictedCourses(id) {
    const isIdPresent = selectedCourses.includes(Number(id));
    if (!isIdPresent) {
      selectedCourses.push(Number(id));
    } else {
      const filteredCourses = selectedCourses.filter(
        (courseId) => courseId !== Number(id)
      );
      selectedCourses = filteredCourses;
    }
    updateSchoolCourses({ id: currentClass, courses: selectedCourses });
    setOpen(false);
  }
  
  return (
    <div>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error</p>}
      {!isLoading && !error && (
        <div className="row">
           <Confirm
        open={open}
        setOpen={setOpen}
        onConfirm={handleCourse}
        onCancel={handleCancel}
      />
          <div className="col">
          <RestrictionCard>
            <div style={{ minWidth: "250px" }}>
              <SimpleSelectList
                items={coursesForSchoool}
                onSelect={(id) => changeStatus(id)}
                selectedItems={selectedCourses}
                selectKey={"id"}
                displayKey={"title"}
              />
            </div>
          </RestrictionCard>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseList;
