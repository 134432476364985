import React from "react";
import style from "./TalentStudentSkor.module.scss";

const TalentStudentSkor = ({ studentScore }) => {
  return (
    <div className={`${style.scoreContainer}`}>
      <p>
        Kazandığın puan:
        <span className={`${style.score}`}>{studentScore}</span>
      </p>
    </div>
  );
};

export default TalentStudentSkor;
