import React, { useEffect, useState } from "react";
import style from "./Announcement.module.scss";
import {
  useCreateSchoolAnnouncementMutation,
  useDeleteSchoolAnnouncementMutation,
  useGetAllSchoolAnnouncementQuery,
  useUpdateSchoolAnnouncementMutation,
} from "../../../schoolManagerApi";
import showNotification from "../../../../../../components/extras/showNotification";
import Icon from "../../../../../../components/icon/Icon";
import AnnouncementModal from "./AnnouncementModal";
import Confirm from "../../../../../common/Confirm";
import PaginationButtons, {
  dataPagination,
  PER_COUNT,
} from "../../../../../../components/PaginationButtons";
import useSortableData from "../../../../../../hooks/useSortableData";
import {
  getDayMonthYearAsString,
} from "../../../../../../utils/formatDate";
import { IconButton, Tooltip } from "@mui/material";
import { Delete, Edit } from "../../../../../../components/icon/material-icons";
import plus from "../../../../../../assets/dashboardImages/plus.svg";
import _ from "underscore";

const AnnouncementPage = () => {
  const {
    data: announcements,
  } = useGetAllSchoolAnnouncementQuery();
  let sortedNotifications  = _.sortBy(announcements, item => item.created_date).reverse();
  const [addAnnouncement] = useCreateSchoolAnnouncementMutation();
  const [updateAnnouncement] = useUpdateSchoolAnnouncementMutation();
  const [deleteAnnouncement] = useDeleteSchoolAnnouncementMutation();
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentAnnouncement, setCurrentAnnouncement] = useState(false);
  const [announceId, setAnnounceId] = useState(false);

  const [search, setSearch] = useState("");
  const [filteredAnnounces, setFilteredAnnounces] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_COUNT["10"]);

  const { items } = useSortableData(
    filteredAnnounces ? filteredAnnounces : sortedNotifications ? sortedNotifications : []
  );

  const filterAnnouncements = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const newAnnounces =
      sortedNotifications &&
      sortedNotifications.filter(
        (item) =>
          (item.title && item.title.toLowerCase().includes(search)) ||
          (item.description && item.description.toLowerCase().includes(search))
      );
    setFilteredAnnounces(newAnnounces);
  };
  useEffect(() => {
    filterAnnouncements();
    //eslint-disable-next-line
  }, [search, announcements]);
  const removeAnnounce = (e) => {
    setOpen(true);
    setAnnounceId(e.currentTarget.id && e.currentTarget.id);
  };
  const handleDelete = async () => {
    const response = await deleteAnnouncement(announceId);
    setOpen(false);
    if (response.error) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Silme İşlemi Başarısız</span>
        </span>,
        "Bir hata meydana geldi",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Silme Başarılı</span>
        </span>,
        "Duyuru başarıyla silindi.",
        "success"
      );
    }
  };
  const handleCancel = () => setOpen(false);

    //! handle announcement-------
    const handleAnnouncement = (e) => {
      console.log(
        "%cUpdatePresentation.js line:180 e",
        "color: white; background-color: #007acc;",
        e
      );
      let currentAnnouncement =
        announcements &&
        announcements.find(
          (announcement) => Number(announcement.id) === Number(e.currentTarget.id)
        );
      setCurrentAnnouncement(currentAnnouncement);
      setIsOpen(true);
    };

  return (
    <div>
     <AnnouncementModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selectedAnnouncement={currentAnnouncement}
        addAnnouncement={addAnnouncement}
        updateAnnouncement={updateAnnouncement}
      />
         <Confirm
        open={open}
        setOpen={setOpen}
        onConfirm={handleDelete}
        onCancel={handleCancel}
      />
    <div className={`${style.actionsContainer} px-2 mx-4 gap-3 mx-auto`}>
      <div className={`${style.inputContainer} `}>
        <div
          className={`${style.searchContainer} d-flex align-items-center justify-content-center`}
        >
          <form className="d-flex gap-1 w-100" onSubmit={filterAnnouncements}>
            <label className="" htmlFor="searchInput">
              <Icon
                icon="Search"
                size="2x"
                style={{ color: "#000", opacity: "0.3" }}
              />
            </label>
            <input
              className="form-control border-0 shadow-none bg-transparent"
              type="search"
              // placeholder={isMobile && "Aramak istediğiniz dersi yazınız"}
              value={search}
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
            />
            <div
          className={`${style.addCourseContainer} d-flex align-items-center gap-2 ps-2`}
          onClick={() => setIsOpen(true)}
        >
          <span>
            <img src={plus} alt="" />
          </span>
          <span>Duyuru Ekle</span>
        </div>
          </form>
        </div>
      </div>
    </div>

    <div className={`${style.customTable} mt-5 px-2`}>
      <div
        className={`${style.customHeadContainer} d-flex align-items-center my-3 px-2`}
      >
        <div className={`${style.customTableHead1} ${style.customTableHead}`}>
          Başlık
        </div>
        <div className={`${style.customTableHead2} ${style.customTableHead}`}>
          Açıklama
        </div>
        <div
          className={`${style.customTableHead3} ${style.customTableHead} text-center`}
        >
          Oluşturulma Tarihi
        </div>
        <div
          className={`${style.customTableHead4} ${style.customTableHead} text-center`}
        >
          Eylemler
        </div>
      </div>
      <div className={`${style.customRowContainer}`}>
        {announcements && items.length === 0 ? (
          <div>
            <span>Duyuru bulunamadı</span>
          </div>
        ) : (
          dataPagination(items, currentPage, perPage).map((item, i) => (
            <div
              key={item.id}
              className={`${style.customTableRow} ${
                i % 2 === 0 && style.customBackgroundColor
              } d-flex align-items-center mt-1 px-2`}
            >
              <div className={`${style.customTableColumn1} cursor-pointer`}>
                <div className={`${style.titleContainer}`}>
                  <div className={`${style.titleShort}`}>{item.title}</div>
                  <div className={`${style.titleFull}`}>{item.title}</div>
                </div>
              </div>
              <div className={`${style.customTableColumn2}`}>
                <div className="d-flex">
                  <div className={`${style.schoolContainer}`}>
                    <div
                      className={`${style.schoolShort}`}
                    >{`${item.description}`}</div>
                    <div
                      className={`${style.schoolFull}`}
                    >{`${item.description}`}</div>
                  </div>
                </div>
              </div>
              <div className={`${style.customTableColumn3} text-center`}>
                <div>{getDayMonthYearAsString(item.created_date)}</div>
              </div>

              {/* Eylemler */}
              <div
                className={`${style.customTableColumn4} d-flex justify-content-center mx-auto`}
              >
                <div className="d-flex justify-content-center gap-2">
                <IconButton id={item.id}
                 onClick={(e) => removeAnnounce(e)}
                  >
                        <Delete className="fs-3" />
                      </IconButton>

                  <Tooltip title="Edit">
                    <IconButton 
                       id={item.id}
                       onClick={(e) => handleAnnouncement(e)}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
    <PaginationButtons
      data={items}
      label="duyuru"
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      perPage={perPage}
      setPerPage={setPerPage}
    />
  </div>
  );
};

export default AnnouncementPage;
