export function nextGame(gameStep, gameId, navigate, lastStep) {
  if (gameStep.id < lastStep)
      navigate(
        `/games/${gameId}/sections/steps/${gameStep.id + 1}`
      );
      if (gameStep.id === lastStep) {
        navigate(`/games/7/sections/25/steps/284`)
      }
}
export function previousGame(gameStep, gameId, navigate, firstStep) {
  if (gameStep.id > firstStep)
      navigate(
        `/games/${gameId}/sections/steps/${gameStep.id - 1}`
      );
}
