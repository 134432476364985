// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".practice_aceEditor__\\+M\\+HU {\n  width: 99% !important;\n}\n\n@media screen and (max-width: 450px) {\n  .practice_cardContainerAceEditor__WHIZy {\n    height: 240px;\n  }\n  .practice_aceEditor__\\+M\\+HU {\n    width: 100%;\n    height: 200px !important;\n    overflow: auto;\n    font-size: 10px !important;\n  }\n}\n@media screen and (max-width: 480px) {\n  .practice_codeEditorContainer__mvWRz {\n    -ms-flex-direction: column;\n        flex-direction: column;\n    gap: 10px;\n  }\n  .practice_editorButtons__Xonmy {\n    -ms-flex-order: 1;\n        order: 1;\n  }\n  .practice_actionButtons__RB7\\+p {\n    -ms-flex-pack: end;\n        justify-content: flex-end;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/t14/practice/practice.module.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ;;AACA;EACI;IAEC,aAAA;EACH;EACE;IACI,WAAA;IACA,wBAAA;IACA,cAAA;IACA,0BAAA;EACN;AACF;AACA;EACI;IACI,0BAAA;QAAA,sBAAA;IACA,SAAA;EACN;EACE;IACI,iBAAA;QAAA,QAAA;EACN;EACE;IAEI,kBAAA;QAAA,yBAAA;EAAN;AACF","sourcesContent":[".aceEditor{\n    width: 99% !important;\n}\n@media screen and (max-width:450px) {\n    .cardContainerAceEditor{\n        // border: solid;\n     height: 240px;   \n    }\n    .aceEditor{\n        width: 100%;\n        height: 200px !important;\n        overflow: auto;\n        font-size: 10px !important;\n    }  \n}\n@media screen and (max-width:480px) {\n    .codeEditorContainer{\n        flex-direction: column;\n        gap: 10px;\n    }\n    .editorButtons{\n        order: 1;\n    }\n    .actionButtons{\n        // border: solid;\n        justify-content: flex-end;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aceEditor": "practice_aceEditor__+M+HU",
	"cardContainerAceEditor": "practice_cardContainerAceEditor__WHIZy",
	"codeEditorContainer": "practice_codeEditorContainer__mvWRz",
	"editorButtons": "practice_editorButtons__Xonmy",
	"actionButtons": "practice_actionButtons__RB7+p"
};
export default ___CSS_LOADER_EXPORT___;
