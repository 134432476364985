import React from 'react'
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper'
import Page from '../../../../layout/Page/Page'
import DashboardHeaderTitle from '../../../common/Headers/DashboardHeaderWithTitle'
import USER_ROLES from '../../../../common/data/enumUserRole'
import CourseListTableForSchool from './course-list-table/CourseListTableForSchool'
import { useDeleteSchoolCourseMutation, useGetSchoolCoursesQuery } from '../schoolManagerApi'
import { useCoursesQuery, useDeleteCourseMutation } from '../../../../store/features/apiSlice'

const ContentManagement = () => {
  const role = localStorage.getItem("roles");
  const isAdmin = Number(role) === USER_ROLES.ADMIN.id;
  const isSchoolManager = Number(role) === USER_ROLES.SCHOOL_MANAGER.id;
  const schoolId = localStorage.getItem("schoolId");
  const schoolParams = `school_status=2`
  const { data: courses } = useGetSchoolCoursesQuery(schoolParams, {skip:isAdmin});
const [deleteCourse] = useDeleteSchoolCourseMutation();
const schoolParamsForAdmin1 = `school_status=2&school=${schoolId}`
const schoolParamsForAdmin2 = `school_status=3&school=${schoolId}`
const { data:adminCourses1 } = useCoursesQuery(schoolParamsForAdmin1, {skip:!isAdmin});
const { data:adminCourses2 } = useCoursesQuery(schoolParamsForAdmin2, {skip:!isAdmin});
const adminCourses = (adminCourses1 && adminCourses2) && adminCourses1.concat(adminCourses2)
const [adminDeleteCourse] = useDeleteCourseMutation();
  return (
    <PageWrapper>
    <Page>
    <DashboardHeaderTitle />
    <div className="row h-100">
          <div className="col-12">
            {isSchoolManager && <CourseListTableForSchool courses={courses} deleteCourse={deleteCourse}/>}
            {isAdmin && <CourseListTableForSchool courses={adminCourses} deleteCourse={adminDeleteCourse}/>}
          </div>
        </div>
    </Page>
    </PageWrapper>
  )
}

export default ContentManagement