import React, { useEffect, useState } from "react";
import style from "./teacherHomeworks.module.scss";
import { Delete, Edit } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import Icon from "../../../../../components/icon/Icon";
import { setSelectedHomework } from "../../teacherSlice";
import Checks from "../../../../../components/bootstrap/forms/Checks";
import useSelectTable from "../../../../../hooks/useSelectTable";
import PaginationButtons, {
  PER_COUNT,
  dataPagination,
} from "../../../../../components/PaginationButtons";
import useSortableData from "../../../../../hooks/useSortableData";
import { useDeleteHomeWorkMutation, useHomeworksQuery } from "../../teacherApi";
import showNotification from "../../../../../components/extras/showNotification";
import { formatDate, getHourFromDate } from "../../../../../utils/formatDate";
import { useNavigate } from "react-router-dom";
import Search from "../../class/search";
import plus from "../../../../../assets/dashboardImages/plus.svg";
import { IconButton, Tooltip } from "@mui/material";
import Confirm from "../../../../common/Confirm";
import Select from "../../../../../components/bootstrap/forms/Select";
import {
  getHomeworkStatus,
  getHomeworkStatusColor,
  getSelectedHomeworks,
} from "./teacherHomeworkFunctions";

const TeacherHomeworkList = ({ isFluid }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_COUNT["10"]);
  const [filteredHomeworks, setFilteredHomeworks] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [homeworkStatus, setHomeworkStatus] = useState("Tamamı");

  const handleSearchChange = (newValue) => {
    setSearchValue(newValue);
  };
  // const activeHomeWorkStatus = `status=1`
  // let finishedHomeWorkStatus = `status=2`
  const { data: homeWorks, isLoading, isSuccess } = useHomeworksQuery();
  const [usedeleteHomeWork] = useDeleteHomeWorkMutation();

  const activeHomeworks =
    homeWorks && homeWorks.length > 0
      ? homeWorks.filter((item) => item.status === 1)
      : [];
  const futureHomeworks =
    homeWorks && homeWorks.length > 0
      ? homeWorks.filter((item) => item.status === 0)
      : [];
  const pastHomeworks =
    homeWorks && homeWorks.length > 0
      ? homeWorks.filter((item) => item.status === 2)
      : [];
  let sortedHomeworks = activeHomeworks
    .concat(futureHomeworks)
    .concat(pastHomeworks);

  const { items } = useSortableData(
    filteredHomeworks ? filteredHomeworks : sortedHomeworks ?? []
  );
  const onCurrentPageData = dataPagination(items, currentPage, perPage);
  const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageData);

  useEffect(() => {
    filterHomeworks();
    //eslint-disable-next-line
  }, [searchValue, homeWorks]);

  useEffect(() => {
    getSelectedHomeworks(sortedHomeworks, homeworkStatus, setFilteredHomeworks);
    //eslint-disable-next-line
  }, [homeworkStatus]);

  const filterHomeworks = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const newhomeWorks =
      sortedHomeworks &&
      sortedHomeworks.filter(
        (item) =>
          item.title.toLowerCase().includes(searchValue) ||
          item.classroom_name.toLowerCase().includes(searchValue)
      );
    setFilteredHomeworks(newhomeWorks);
  };

  const handleDelete = () => {
    if (selected.length > 0) {
      selected.forEach((item) => deleteHomeWork(item));
    }
    setOpen(false);
    setSelected([]);
  };
  const handlCancel = () => setOpen(false);

  useEffect(() => {
    if (selectTable.values.selectedList) {
      setSelected(selectTable.values.selectedList);
    }
  }, [selectTable.values.selectedList]);

  const deleteHomeWork = async (id) => {
    try {
      await usedeleteHomeWork(id);
    } catch (error) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Silme Başarısız</span>
        </span>,
        "Bir hata oluştu",
        "danger"
      );
    } finally {
    }
  };
  const openHomeworkForm = (selectedClass) => {
    dispatch(setSelectedHomework(selectedClass));
    navigate(`/teacher/new-homework/${selectedClass.id}`);
  };
  const handleTitleClick = (selectedItem) => {
    dispatch(setSelectedHomework(selectedItem));
    navigate(`/teacher/new-homework/${selectedItem.id}`);
  };
  if (isLoading) return "Loading";

  if (isSuccess)
    return (
      <div className={`${style.cardContainer} mt-5 pt-5`}>
        <Confirm
          open={open}
          setOpen={setOpen}
          onConfirm={handleDelete}
          onCancel={handlCancel}
        />
        {selected.length > 0 ? (
          <button
            className={`${style.allDeleteButton} btn btn-danger`}
            onClick={() => {
              setSelected(selected);
              setOpen(true);
            }}
          >
            Seçilenleri Sil
          </button>
        ) : (
          <button
            style={{ display: "none" }}
            onClick={() => {
              setSelected(selected);
              setOpen(true);
            }}
          >
            Seçilenleri Sil
          </button>
        )}
        {/* <CardHeader>
          <CardLabel>
            <CardTitle className={`${style.title} ps-3`}>Ödevlerim</CardTitle>
          </CardLabel>
        </CardHeader> */}
        <div
          className={`${style.actionsContainer} d-flex align-items-center justify-content-center px-2 mx-4 gap-3 row mx-auto`}
        >
          <div className={`${style.inputContainer} col-6 col-md-8 col-xl-9`}>
            <Search
              handleSearchChange={handleSearchChange}
              iconColor={"#5454544F"}
            />
          </div>
          <div
            className={`${style.addPracticeContainer} d-flex align-items-center gap-2 ps-2 col`}
            onClick={() => {
              dispatch(setSelectedHomework(null));
              navigate("/teacher/new-homework");
            }}
          >
            <span>
              <img src={plus} alt="" />
            </span>
            <span>Ödev Ekle</span>
          </div>
        </div>
        <div className={`${style.customTable} mt-5 px-4`}>
          <div
            className={`${style.customHeadContainer} d-flex align-items-center my-3 px-2`}
          >
            <div className={`${style.checkContainer} ${style.customTableHead}`}>
              {SelectAllCheck}
            </div>
            <div
              className={`${style.customTableHead1} ${style.customTableHead}`}
            >
              Başlık
            </div>
            <div
              className={`${style.customTableHead2} ${style.customTableHead} text-center`}
            >
              <div className={`${style.dateContainer}`}>
                <div className={`${style.dateShort}`}>Oluşturulma Tarihi</div>
                <div className={`${style.dateFull}`}>Oluşturulma Tarihi</div>
              </div>
            </div>
            <div
              className={`${style.customTableHead3} ${style.customTableHead} text-center`}
            >
              Saat
            </div>

            <div
              className={`${style.customTableHead4} ${style.customTableHead} text-center`}
            >
              Sınıf
            </div>
            <div
              className={`${style.customTableHead5} ${style.customTableHead} text-center`}
            >
              Açıklama
            </div>
            <div
              className={`${style.customTableHead6} ${style.customTableHead} text-center`}
            >
              <Select
                value={homeworkStatus}
                ariaLabel={homeworkStatus}
                className={`${style.customTableHeadSelect}`}
                list={[
                  { value: "Tamamı", text: "Tamamı" },
                  { value: "Aktif", text: "Aktif" },
                  { value: "Geçmiş", text: "Geçmiş" },
                  { value: "Gelecek", text: "Gelecek" },
                ]}
                onChange={(e) => setHomeworkStatus(e.target.value)}
              ></Select>
            </div>
            <div
              className={`${style.customTableHead7} ${style.customTableHead} text-center`}
            >
              Eylemler
            </div>
          </div>
          <div className={`${style.customRowContainer}`}>
            {homeWorks && onCurrentPageData.length === 0 ? (
              <div>
                <span>Ödev bulunamadı</span>
              </div>
            ) : (
              homeWorks &&
              dataPagination(items, currentPage, perPage).map((item, i) => (
                <div
                  key={item.id}
                  className={`${style.customTableRow} ${
                    i % 2 === 0 && style.customBackgroundColor
                  } d-flex align-items-center mt-1 px-2`}
                >
                  <div className={`${style.checkContainer}`}>
                    <Checks
                      className={`${style.checkContainerCheck}`}
                      id={item.id.toString()}
                      name="selectedList"
                      value={item.id}
                      onChange={selectTable.handleChange}
                      checked={selectTable.values.selectedList.includes(
                        item.id.toString()
                      )}
                    />
                  </div>
                  <div className={`${style.customTableColumn1}`}>
                    <div
                      className={`${style.titleContainer} cursor-pointer`}
                      onClick={() => handleTitleClick(item)}
                    >
                      <div className={`${style.titleShort}`}>{item.title}</div>
                      <div className={`${style.titleFull}`}>{item.title}</div>
                    </div>
                  </div>
                  <div className={`${style.customTableColumn2} text-center`}>
                    <div>
                      {/* <div>{formatDate(item.created_date)}</div> */}
                      <div className={`${style.dateContainer}`}>
                        <div className={`${style.dateShort}`}>
                          {formatDate(item.created_date)}
                        </div>
                        <div className={`${style.dateFull}`}>
                          {formatDate(item.created_date)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${style.customTableColumn3} text-center`}>
                    <div>
                      {getHourFromDate(item.start_date)}
                      {"-"}
                      {getHourFromDate(item.end_date)}
                    </div>
                  </div>

                  <div className={`${style.customTableColumn4} text-center`}>
                    <div className={`${style.classContainer}`}>
                      <div className={`${style.classShort}`}>
                        {item.classroom_name}
                      </div>
                      <div className={`${style.classFull}`}>
                        {item.classroom_name}
                      </div>
                    </div>
                  </div>
                  <div className={`${style.customTableColumn5} text-center`}>
                    <div className={`${style.descriptionContainer}`}>
                      <div className={`${style.descriptionShort}`}>
                        {item.description}
                      </div>
                      <div className={`${style.descriptionFull}`}>
                        {item.description}
                      </div>
                    </div>
                  </div>
                  <div className={`${style.customTableColumn6} text-center`}>
                    <div>
                      <span
                        className={`text-${getHomeworkStatusColor(
                          item.status
                        )}`}
                      >
                        {getHomeworkStatus(item.status)}
                      </span>
                    </div>
                  </div>
                  <div
                    className={`${style.customTableColumn7} text-center`}
                    style={{ opacity: item.is_active === false && "0.2" }}
                  >
                    <Tooltip title="Delete" className={`${style.actionIcon}`}>
                      <IconButton
                        onClick={() => {
                          setSelected([item.id]);
                          setOpen(true);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit" className={`${style.actionIcon}`}>
                      <IconButton onClick={() => openHomeworkForm(item)}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <PaginationButtons
          data={items}
          label="Ödev"
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </div>
    );
};

export default TeacherHomeworkList;
