import React from 'react'
import style from "./DailyPlan.module.scss";
import ellipse from "../../../../../../src/assets/dashboardImages/ellipse.svg";

const SinglePlan = ({description}) => {
  return (
    <div className={`${style.singlePlanContainer} ms-5`}>
      <div className={`${style.singlePlan} d-flex justify-content-start align-items-center gap-3 my-3`}>
        <div className={`${style.logoDiv}`}><img src={ellipse} alt="ellipse" /></div>
        <div className={`${style.description}`}>{description}</div>
      </div>
      {/* <div style={{width:"50%"}}><hr /></div> */}
    </div>
  )
}

export default SinglePlan