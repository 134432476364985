import React from "react";
// import ReactDOM from 'react-dom'; // For React 17
import { createRoot } from "react-dom/client"; // For React 18
import { BrowserRouter as Router } from "react-router-dom";
import "swiper/css/bundle";
import "./styles/styles.scss";
import App from "./App/App";
import reportWebVitals from "./reportWebVitals";
import { ThemeContextProvider } from "./contexts/themeContext";
//import "./i18n";
import { AuthProvider } from "./store/auth-store";
import { Provider } from "react-redux";
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";

const error = console.error;
console.error = (...args) => {
  if (/defaultProps/.test(args[0])) return;
  error(...args);
};

const children = (
  <Router>
    <ThemeContextProvider>
      <AuthProvider>
        <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App /> 
          </PersistGate>
        </Provider>
      </AuthProvider>
    </ThemeContextProvider>
  </Router>
);

const container = document.getElementById("root");

// ReactDOM.render(children, container); // For React 17
createRoot(container).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
