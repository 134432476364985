import React from "react";
import Button from "../../../../components/bootstrap/Button";
import _ from "underscore";
import COURSE_STATUS from "../../../../common/data/enumCourseState";
import USER_SUBSCRIPTION_TYPE from "../../../../common/data/enumUserSubscriptionType";
import { useNavigate } from "react-router";
import useDarkMode from "../../../../hooks/useDarkMode";

const AllLessons = ({courses, isLoading}) => {
  const { darkModeStatus } = useDarkMode();
  const userSubscriptionType = localStorage.getItem("subscription_type")
  // const userSubscriptionType = 2;
  const isPremium = Number(userSubscriptionType) === USER_SUBSCRIPTION_TYPE.PREMIUM.id;
  const isStandard = Number(userSubscriptionType) === USER_SUBSCRIPTION_TYPE.STANDART.id;
  // const isFree = Number(userSubscriptionType) === USER_SUBSCRIPTION_TYPE.FREE.id;


  const freeCourses = [13,14,15,101]
  const standardCourses = [115,106,19,69,70,71,72,94,105,112,113,114,116,117,118]

  let activeLessons = courses?.filter((item) => item.status === 1);
  activeLessons = _.sortBy(activeLessons, "order_number");
  let draftLessons = courses?.filter((item) => item.status === 2);
  draftLessons = _.sortBy(draftLessons, "order_number");
  let lessons = activeLessons.concat(draftLessons);

  const navigate = useNavigate();

  const handleNavigation = (path, activeTab) => {
    navigate(`${path}?activeTab=${activeTab}`);
  };
  if(isLoading){
    return <div>Yükleniyor...</div>
  }
  return (
        <div>
          <div className="row">
            {lessons?.map((lesson) => {
              const { title, id, status, technology, percentage } = lesson;
              const is_draft = status === COURSE_STATUS.DRAFT.id;
              return (
                <div
                  key={id}
                  className="LearningPaths_learningPathCard__9zgcx col-xxl-3 col-xl-4 col-md-6"
                >
                  <div className="card" style={{ maxHeight: "350px"}}>
                    <div
                      className={`card-header d-flex justify-content-center text-center ${
                        is_draft && !darkModeStatus ? "bg-l10-dark" : ""
                      }`}
                    >
                      <div className="card-label">
                        <div
                          className="card-title-wrapper"
                          style={{ height: "50px" }}
                        >
                          <h5 className="card-title">{title}</h5>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`card-body ${is_draft && !darkModeStatus ? "bg-l10-dark" : ""}`}
                    >
                      <img
                        src={`/images/${technology}.png`}
                        alt=""
                        width={128}
                        height={128}
                        className="mx-auto d-block img-fluid mb-3"
                      />
                    </div>
                    <div className={`${is_draft && !darkModeStatus ? "bg-l10-dark" : ""}`}>
                      <div
                        className="bg-light mx-auto"
                        style={{
                          border: "2px solid blue",
                          borderRadius: "5px",
                          width: "250px",
                        }}
                      >
                        <div
                          className="progress-bar-fill d-flex justify-content-center align-items-center"
                          style={{
                            width: `${percentage}%`,
                            height: "20px",
                            backgroundColor: "#B4C7E7",
                            color:darkModeStatus && "#000"
                          }}
                        >
                          {" "}
                          <div
                            className="progress-bar-percentage fw-bold"
                            style={{
                              lineHeight: "2px",
                              marginLeft: `${percentage < 20 ? "20px" : "0"}`,
                            }}
                          >
                            {percentage}%
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`card-footer shadow-3d-container ${
                        is_draft && !darkModeStatus ? "bg-l10-dark" : ""
                      }`}
                    >
                      {status === COURSE_STATUS.ACTIVE.id ? (
                          freeCourses.includes(id) ? (
                          <Button
                            color="dark"
                            className="btn btn-myButton btn-lg w-100 mb-4 shadow-3d-up-hover shadow-3d-myButton"
                            size="lg"
                            tag="a"
                            to={`/lessons/AllLessonsAndContent/${id}`}
                          >
                            Derse Gir
                          </Button>
                        ) : standardCourses.includes(id) &&
                          isStandard ? (
                          <Button
                            color="dark"
                            className="btn btn-myButton btn-lg w-100 mb-4 shadow-3d-up-hover shadow-3d-myButton"
                            size="lg"
                            tag="a"
                            to={`/lessons/AllLessonsAndContent/${id}`}
                          >
                            Derse Gir
                          </Button>
                        ) : isPremium ? (
                          <Button
                            color="dark"
                            className="btn btn-myButton btn-lg w-100 mb-4 shadow-3d-up-hover shadow-3d-myButton"
                            size="lg"
                            tag="a"
                            to={`/lessons/AllLessonsAndContent/${id}`}
                          >
                            Derse Gir
                          </Button>
                        ) : (
                          <Button
                            color="danger"
                            className="btn btn-myDanger btn-lg w-100 mb-4 shadow-3d-up-hover shadow-3d-myDanger"
                            size="lg"
                            tag="a"
                            onClick={() =>
                              handleNavigation(`/profile`, "Account Details")
                            }
                          >
                            Planı Yükselt
                          </Button>
                        )
                      ) : (
                        <Button
                          color="dark"
                          className="btn btn-myButton btn-lg w-100 mb-4 shadow-3d-up-hover shadow-3d-myButton"
                          size="lg"
                        >
                          Ders Hazırlanıyor ...
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
  );
};

export default AllLessons;
