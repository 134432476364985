import React from 'react';
import SubHeader, {
    SubHeaderLeft,
} from "../../../layout/SubHeader/SubHeader";
import Button from "../../../components/bootstrap/Button";
import style from "./profilPage.module.scss";

const HeaderNavigate = () => {
    return (
        <SubHeader>
            <SubHeaderLeft>
                <Button
                    color="info"
                    isLink
                    icon="ArrowBack"
                    tag="a"
                    to={`/`}
                    className={style.SubHeader}
                >
                    Ana Sayfaya Geri Dön
                </Button>
            </SubHeaderLeft>
            {/* <SubHeaderRight></SubHeaderRight> */}
        </SubHeader>
    );
};

export default HeaderNavigate;