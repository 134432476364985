import React from "react";
import Button from "../../../../../components/bootstrap/Button";

const TeacherAllGuidances = ({ data, type }) => {
  return (
    <>
        <div className="page container-xxl">
          <div className="row">
            {data &&
              data.map((lesson) => {
                  const { id, title, cover_image} = lesson;
                  return (
                    <div
                      className="LearningPaths_learningPathCard__9zgcx col-xxl-3 col-xl-4 col-md-6"
                      key={id}
                    >
                      <div className="card">
                        <div className="card-header">
                          <div className="card-label">
                            <div className="card-title-wrapper">
                              <h5 className="card-title">{title}</h5>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <img
                            src={`/images/${cover_image}.png`}
                            alt=""
                            width={128}
                            height={128}
                            className="mx-auto d-block img-fluid mb-3"
                          />
                        </div>
                        <div className="card-footer shadow-3d-container ">
                            <Button
                              color="dark"
                              className="btn btn-myButton btn-lg w-100 mb-4 shadow-3d-up-hover shadow-3d-myButton"
                              size="lg"
                              tag="a"
                              to={`/teacher/coursesForTeacher/guidances/${id}?type=${type}`}
                            >
                              Öğrenmeye Başla
                            </Button>
                          </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
    </>
  );
};

export default TeacherAllGuidances;
