import React from "react";
import style from "./StudentWithClass.module.scss";
import { useState } from "react";
import useDarkMode from "../../../../../hooks/useDarkMode";
import HomeworkDetailModal from "./6-HomeworkDetailModal";
import { useGetStudentHomeworkQuery } from "../../../../../store/features/apiSlice";
import Icon from "../../../../../components/icon/Icon";

const SingleHomework = ({ homework }) => {
  const { darkModeStatus } = useDarkMode();
  const [isOpenHomeworkDetailModal, setIsOpenHomeworkDetailModal] =
    useState(false);
  const [homeworkId, setHomeworkId] = useState(null);
  const { data: homeworkDetail } = useGetStudentHomeworkQuery(
    homework.id
  );
  const homeworkEndDate = homeworkDetail && homeworkDetail.end_date;
  const homeworkStatus = homeworkDetail && homeworkDetail.student_status;
  const isCompleted = homeworkStatus === 2;
  const today = new Date();
  const isDeadlinePassed = new Date(homeworkEndDate) < new Date(today);
  // const isDeadlinePassed = homeworkDetail && homeworkDetail.student_status === 2;

  const _status =
    (isDeadlinePassed && !isCompleted && "rgb(246, 109, 70)") ||
    (isDeadlinePassed && isCompleted && "rgb(246, 109, 70)") ||
    (!isDeadlinePassed && isCompleted && "rgb(96, 195, 180)") ||
    (!isDeadlinePassed && !isCompleted && "#000");

  const _statusIcon =
    (!isDeadlinePassed && isCompleted && (
      <Icon icon={"check"} size="2x"></Icon>
    )) ||
    (!isDeadlinePassed && !isCompleted && (
      <Icon icon={"DirectionsRun"} size="2x"></Icon>
    )) ||
    (isDeadlinePassed && isCompleted && (
      <Icon icon={"check"} size="2x"></Icon>
    )) ||
    (isDeadlinePassed && !isCompleted && (
      <Icon icon={"cancel"} size="2x"></Icon>
    ));

  const showHomeworkDetail = (id) => {
    setHomeworkId(id);
    setIsOpenHomeworkDetailModal(true);
  };
  return (
    <>
      <HomeworkDetailModal
        isOpenHomeworkDetailModal={isOpenHomeworkDetailModal}
        setIsOpenHomeworkDetailModal={setIsOpenHomeworkDetailModal}
        homeworkId={homeworkId}
      />
      <div className="d-flex align-items-center gap-2">
        <div>
          <span
            className={`${style.homeworkDescription}`}
            style={{
              color: darkModeStatus ? "#fff" : `${_status}`,
            }}
            onClick={() => showHomeworkDetail(homework.id)}
          >
             <div className={`${style.titleContainer}`}>
                      <div className={`${style.titleShort}`}>{homework.title}</div>
                      <div className={`${style.titleFull}`}>{homework.title}</div>
                        </div>
          </span>
        </div>
        <div
          className={`${style.homeworkLogo}`}
          style={{
            color: darkModeStatus ? "#fff" : `${_status}`,
          }}
        >
          {_statusIcon}
        </div>
      </div>
    </>
  );
};

export default SingleHomework;
