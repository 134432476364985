import React from "react";
import Modal, {
  ModalBody,
  ModalHeader,
} from "../../../../components/bootstrap/Modal";
import Card, {
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../../components/bootstrap/Card";
import { useGetClassesQuery } from "../teacherApi";
import { ContentCopy } from "@mui/icons-material";
import { useClipboard } from "use-clipboard-copy";
import showNotification from "../../../../components/extras/showNotification";
import { nanoid } from 'nanoid';

const AddStudentForm = ({ modalStatus, setModalStatus, isFluid }) => {
  const { data: classes} = useGetClassesQuery();
  const clipboard = useClipboard();
  return (
    <div>
      <Modal
        isOpen={modalStatus}
        setIsOpen={setModalStatus}
        titleId="upcomingEdit"
        isCentered
        isScrollable
        size="lg"
      >
        <ModalHeader setIsOpen={setModalStatus}><span></span></ModalHeader>
        <ModalBody>
          <div className="row g-4">
            <div className="col-12">
              <div className="mb-0">
                <div>
                  <Card stretch={isFluid}>
                    <CardHeader borderSize={1}>
                      <CardLabel icon="PersonAdd" iconColor="info">
                        <CardTitle>Öğrenci Ekle</CardTitle>
                      </CardLabel>
                    </CardHeader>
                    <CardBody className="table-responsive">
                      <table className="table table-modern">
                        <thead>
                          <tr>
                            <td style={{ width: 60 }} />
                            <th>Sınıf İsmi</th>
                            <th >Sınıf Kodu</th>

                            <td />
                          </tr>
                        </thead>
                        <tbody>
                          {classes?.map((item, index) => (
                            <tr key={nanoid()}>
                              <td></td>
                              <td>
                                <div>
                                  <div>{item.name}</div>
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{ cursor: "pointer" }}
                                  className="d-flex aling-items-center gap-2 "
                                  onClick={() => {
                                    clipboard.copy(item.code);
                                    showNotification(
                                        <span className="d-flex align-items-center gap-2">
                                        <ContentCopy/>
                                        <span>Copy The ClipBoard</span>
                                      </span>,
                                      "Panoya Kopyalandı.",
                                      "success"
                                    );
                                  }}
                                >
                                  <div>
                                    <ContentCopy />
                                  </div>
                                  <div>{item.code}</div>
                                </div>
                              </td>

                              <td></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddStudentForm;
