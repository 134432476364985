import React from "react";
import Card from "../../../../../components/bootstrap/Card";
import Button from "../../../../../components/bootstrap/Button";
import { useDispatch } from "react-redux";
import { setMenu } from "../../teacherSlice";
import { menuList } from "./menuList";
import { useGetAllClassRestrictionsQuery } from "../../../admin/school management/school restrictions/restrictionApi";

const HomeWorkLeftMenu = ({ currentClass }) => {
  const schoolId = localStorage.getItem("school");
  const isSchool = schoolId > 0;
  const dispatch = useDispatch();
  const handleClick = (menuItem) => {
    dispatch(setMenu(menuItem));
  };

  const { data, isLoading } = useGetAllClassRestrictionsQuery(currentClass, {
    skip: !isSchool,
  });

  const restrictions = {
    LESSONS: data && data[0].course,
    CODELAB: data && data[0].codelab,
    CODEMINO: data && data[0].codemino,
    CODEBOO: data && data[0].codeboo,
    CODECIZZ: data && data[0].codecizz,
  };

  const modifiedMenuList = {};

  for (const [key, value] of Object.entries(restrictions)) {
    if (value) {
      modifiedMenuList[key] = menuList[key];
    } else {
      delete modifiedMenuList[key];
    }
  }

  if (isLoading) {
    return <div>Yükleniyor...</div>;
  }
  return (
    <>
      <Card
        className="d-flex gap-3 align-items-center justify-content-center py-2"
        // style={{ minHeight: "80%" }}
        style={{ minHeight: "280px" }}
      >
        {isSchool
          ? Object.values(modifiedMenuList).map((item, index) => (
              <div className="mx-auto" key={item}>
                <Button
                  onClick={() => handleClick(item)}
                  className="btn btn-outline-primary fs-5"
                  isOutline={true}
                  style={{ width: "150px" }}
                >
                  {item}
                </Button>
                <br />
              </div>
            ))
          : Object.values(menuList).map((item, index) => (
              <div className="mx-auto" key={item}>
                <Button
                  onClick={() => handleClick(item)}
                  className="btn btn-outline-primary fs-5"
                  isOutline={true}
                  style={{ width: "150px" }}
                >
                  {item}
                </Button>
                <br />
              </div>
            ))}
      </Card>
      <Card
        className="d-flex gap-3 align-items-center justify-content-center py-3"
        // style={{ minHeight: "90%" }}
      >
        <div className="mx-auto">
          <Button
            onClick={() => handleClick("SCHOOL_COURSE_LESSONS")}
            className="btn btn-outline-primary fs-5"
            isOutline={true}
            style={{ width: "150px" }}
          >
            Kurum Dersler
          </Button>
          <br />
        </div>
        <div className="mx-auto">
          <Button
            onClick={() => handleClick("TEACHER_PRACTICES")}
            className="btn btn-outline-primary fs-5"
            isOutline={true}
            style={{ width: "150px" }}
          >
            Eğitmen Alıştırmaları
          </Button>
          <br />
        </div>
      </Card>
    </>
  );
};

export default HomeWorkLeftMenu;
