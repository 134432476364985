import React, { useEffect, useState } from "react";
import style from "./demoDashboard.module.scss";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../layout/Page/Page";
import DashboardHeader from "../../../common/Headers/DashboardHeader";
import AVATARS from "../../../../common/data/avatarsDummy";
// import TawkToChat from "../../../../common/TawkToChat";
import StudentWithoutClass from "./studentWithoutClass/StudentWithoutClass";
import StudentWithClass from "./studentWithClass/1-StudentWithClass";
import FollowingLessons from "./followingLessons/1-FollowingLessons";
import DailyPlan from "./dailyPlan/1-DailyPlan";
import Last10Days from "./last10Days/1-LastDays";
import Calendar from "./calendar/Calendar";
import {
  useGetParentInformationQuery,
  useGetStudentSubscribedClassesQuery,
} from "../../../../store/features/apiSlice";
import { useWindowSize } from "react-use";
import StudentWithOnlySchool from "./studentWithOnlySchool/1-StudentWithOnlySchool";
import { useGetAllCompetitionsQuery } from "../../admin/talent cup/talentCupApi";
import UserCompetitionInfoModal from "./customModals/UserCompetitionInfoModal";
// import UserVideoInfoModal from "./customModals/UserVideoInfoModal";
import { getUserNameFromEmail } from "../../profilPage/utils";
import Card from "../../../../components/bootstrap/Card";
// import freeBanner from "../../../../assets/banner/freeBanner.png"
// import vipBanner from "../../../../assets/banner/vipBanner.png"
import whatsappBanner from "../../../../assets/banner/whatsappBanner.png"
import USER_SUBSCRIPTION_TYPE from "../../../../common/data/enumUserSubscriptionType";
import WhatsappBanner from "./banner and announcements/banner/WhatsappBanner";
import { useGet_a_SchoolQuery } from "../../teacher/teacherApi";
import Button from "../../../../components/bootstrap/Button";

const StudentDashboard = () => {
  const userSubscriptionType = localStorage.getItem("subscription_type")
  // const isPremium = Number(userSubscriptionType) === USER_SUBSCRIPTION_TYPE.PREMIUM.id;
  const isFree = Number(userSubscriptionType) === USER_SUBSCRIPTION_TYPE.FREE.id;
  const userId = localStorage.getItem("id");
  const { data, isLoading, isError, error } =
    useGetStudentSubscribedClassesQuery();
  const {
    data: competitions,
    isLoading: isCompLoading,
    isError: isCompError,
    error: compError,
  } = useGetAllCompetitionsQuery();
  const {
    data: parentSmsInformation,
    isLoading: isParentLoading,
    isError: isParentError,
    error: parentError,
  } = useGetParentInformationQuery(userId);
  const [open, setOpen] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  function isStudentJoinedAnyContest(contests) {
    return contests?.some((contest) => contest?.is_student_joined === true);
  }
  const isStudentJoinedCompetition =
    competitions && isStudentJoinedAnyContest(competitions);
  const isParentPhoneNumber =
    parentSmsInformation && parentSmsInformation.parent_telephone_number;

  const { width } = useWindowSize();
  // eslint-disable-next-line
  const isNotMinDevice = width > 350;
  const userAvatar = localStorage.getItem("user_avatar");
  const schoolId = localStorage.getItem("school");
  const isSchoolId = schoolId > 0;
  let userName = localStorage.getItem("username");
  let userEmail = localStorage.getItem("email");
  userName = getUserNameFromEmail(userEmail);
  const subscribedStudent = data && Object.keys(data).length !== 0;
  const unassignedStudent = isSchoolId && !subscribedStudent;

  const { data: currentSchool } = useGet_a_SchoolQuery(schoolId, {
    skip: !schoolId,
  });
  const isDerslig = currentSchool?.title === "DERSLİG";

  const handleCancel = () => setOpen(false);
  useEffect(() => {
    const currentDate = new Date();
    const endDate = new Date("2024-11-10");
    if (
      currentDate <= endDate &&
      isStudentJoinedCompetition &&
      !isParentPhoneNumber
    ) {
      setOpen(true);
    }
  }, [isStudentJoinedCompetition, isParentPhoneNumber]);

  useEffect(() => {
    try {
      let videoModalShow = sessionStorage.getItem("videoModalShow");
      if (videoModalShow) return;
      setOpenVideoModal(true);
      sessionStorage.setItem("videoModalShow", "true");
    } catch (error) {
      console.error("SessionStorage hatası:", error);
    }
  }, []);

  if (isLoading || isCompLoading || isParentLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "20px",
          fontWeight: "600",
        }}
        className="w-100"
      >
        <div className={`${style.loader}`}>
          <div className={`${style.progressLoading}`}></div>
        </div>
        <p>Yükleniyor...</p>
      </div>
    );
  }

  if (isError || isCompError || isParentError) {
    return (
      <div>
        Error: {error?.message || compError?.message || parentError?.message}
      </div>
    );
  }

  return (
    <PageWrapper>
      <DashboardHeader />
      <UserCompetitionInfoModal
        open={open}
        setOpen={setOpen}
        onCancel={handleCancel}
      />
      {/* <UserVideoInfoModal
        open={openVideoModal}
        setOpen={setOpenVideoModal}
        onCancel={handleCancel}
      /> */}
      {/* <TawkToChat showWidget={isNotMinDevice}/> */}
      {/* <TawkToChat showWidget={false}/> */}
      <Page>
        {!subscribedStudent && !unassignedStudent && (
          <>
            <div
              className={`${style.banner} d-flex justify-content-between align-items-center`}
            >
              <div className={`${style.welcome}`}>
                Tekrar Hoşgeldin {`${userName}`}!
              </div>
              <div className={`${style.userAvatar}`}>
                <img
                  src={
                    userAvatar && userAvatar !== "null"
                      ? userAvatar
                      : AVATARS.defaultAvatar
                  }
                  alt=""
                />
              </div>
            </div>
            <Card className="mt-3">{whatsappBanner && <WhatsappBanner banner={whatsappBanner} />}</Card>
            {/* {isFree && <Card className="mt-3">{freeBanner && <WhatsappMarketingBanner banner={freeBanner} />}</Card>}
            {isPremium && <Card className="mt-3">{vipBanner && <WhatsappBanner banner={vipBanner} />}</Card>} */}
          </>
        )}
        {subscribedStudent && <StudentWithClass />}
        {unassignedStudent && <StudentWithOnlySchool />}
        {isDerslig && (
          <div className="d-flex justify-content-start">
            <Button
              className="py-2 px-4 text-light"
              style={{
                background: "#D81768",
                borderRadius: "6px",
                transition: "background 0.3s ease",
              }}
              onClick={() => window.open("https://www.derslig.com/", "_blank")}
              onMouseEnter={(e) => (e.target.style.background = "#b9155a")}
              onMouseLeave={(e) => (e.target.style.background = "#D81768")}
            >
              DERSLİG'e Geri Dön
            </Button>
          </div>
        )}

        <div className="row d-flex justify-content-between mt-4">
          <div className="col-lg-7 col-12">
            <FollowingLessons />
          </div>
          <div className="col-lg-5 col-12">
            <DailyPlan />
          </div>
        </div>
        <div className="row d-flex mt-4">
          <div className="col-lg-7 col-12">
            <Calendar />
          </div>
          <div className="col-lg-5 col-12">
            <Last10Days />
          </div>
        </div>
        {!subscribedStudent && !isSchoolId && (
          <div className="my-4">
            <StudentWithoutClass />
          </div>
        )}
      </Page>
    </PageWrapper>
  );
};

export default StudentDashboard;
