// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CertificateThumbnail_certificateContainer__GOf9Y {\n  position: relative;\n  display: inline-block;\n  width: 120px;\n  height: 130px;\n}\n@media screen and (min-width: 1600px) {\n  .CertificateThumbnail_certificateContainer__GOf9Y {\n    width: 125px;\n    height: 180px;\n  }\n}\n\n.CertificateThumbnail_nameContainer__OAyEp {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  -webkit-transform: translate(-50%, -50%);\n      -ms-transform: translate(-50%, -50%);\n          transform: translate(-50%, -50%);\n  font-family: \"Arial\", sans-serif;\n  font-size: 13px;\n  font-weight: 900;\n  text-align: center;\n  color: #3F59A9;\n  width: 108px;\n  max-height: 40px;\n}", "",{"version":3,"sources":["webpack://./src/pages/t14/student/leaderboardsAndRewardsWrapper/certificatesPage/DynamicThumbnail/CertificateThumbnail.module.scss"],"names":[],"mappings":"AAAG;EACC,kBAAA;EACA,qBAAA;EACA,YAAA;EACA,aAAA;AACJ;AAAI;EALD;IAMG,YAAA;IACA,aAAA;EAGJ;AACF;;AAAE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,wCAAA;MAAA,oCAAA;UAAA,gCAAA;EACA,gCAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;EACA,gBAAA;AAGJ","sourcesContent":["   .certificateContainer {\n    position: relative;\n    display: inline-block;\n    width: 120px;\n    height: 130px;\n    @media screen and (min-width:1600px) {\n      width: 125px;\n      height: 180px;\n  }\n  }\n  \n  .nameContainer {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    font-family: \"Arial\", sans-serif;\n    font-size: 13px;\n    font-weight: 900;\n    text-align: center;\n    color: #3F59A9;\n    width: 108px;\n    max-height: 40px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"certificateContainer": "CertificateThumbnail_certificateContainer__GOf9Y",
	"nameContainer": "CertificateThumbnail_nameContainer__OAyEp"
};
export default ___CSS_LOADER_EXPORT___;
