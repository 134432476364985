import React from "react";
import Result from "../../../../../components/Result";
import { useParams } from "react-router-dom";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../../layout/Page/Page";

const AdminPracticeFormResult = () => {
  const { id } = useParams();

  return (
    <PageWrapper>
      <Page>
        <Result
          id={id}
          url1="admin/practices"
          url2="admin/practice-form"
          url3="admin/test"
        />
      </Page>
    </PageWrapper>
  );
};

export default AdminPracticeFormResult;
