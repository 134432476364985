import React from 'react'
import style from "./ClassStudents.module.scss"
import _ from "underscore";

const Leaders = ({leaderBoard}) => {
  const leaders = _.sortBy(leaderBoard, "rank");
  const leader = leaders && leaders.length > 0 && {
    name:leaders[0].first_name,
    lastName:leaders[0].last_name,
    points:leaders[0].points,
    email:leaders[0].email.split("@")[0],
  }
  const leader2 = leaders && leaders.length > 1 && {
    name:leaders[1].first_name,
    lastName:leaders[1].last_name,
    points:leaders[1].points,
    email:leaders[1].email.split("@")[0],
  }
  const leader3 = leaders && leaders.length > 2 && {
    name:leaders[2].first_name,
    lastName:leaders[2].last_name,
    points:leaders[2].points,
    email:leaders[2].email.split("@")[0],
  }
  function checkFullName(leader){
    if(leader){
      if(leader.name || leader.lastName){
        return `${leader.name} ${leader.lastName}`
      }else{
        return `${leader.email}`
      }
    }
  } 
  return (
    <>
      <div className={`${style.leadersTitle} mb-5`}>Sınıfın Liderleri</div>
      <div className={`${style.rankContainer}`}>
        <div className={`${style.leftSection}`}>
          <div className={`${style.top}`}>
          <div className={`${style.titleContainer}`}>
              <div className={`${style.titleShort}`}>
               <p>{checkFullName(leader2)}</p>
              </div>
              <div className={`${style.titleFull}`}>
               <p>{checkFullName(leader2)}</p>
              </div>
            </div>
            <p>{leader2.points}</p>
          </div>
          <div className={`${style.bottom}`}>2</div>
        </div>
        <div className={`${style.centerSection}`}>
          <div className={`${style.top}`}>
          <div className={`${style.titleContainer}`}>
              <div className={`${style.titleShort}`}>
                <p>{checkFullName(leader)}</p>
              </div>
              <div className={`${style.titleFull}`}>
                <p>{checkFullName(leader)}</p>
              </div>
            </div>
            <p>{leader.points}</p>
          </div>
          <div className={`${style.bottom}`}>1</div>
        </div>
        <div className={`${style.rightSection}`}>
          <div className={`${style.top}`}>
          <div className={`${style.titleContainer}`}>
              <div className={`${style.titleShort}`}>
                <p>{checkFullName(leader3)}</p>
              </div>
              <div className={`${style.titleFull}`}>
                <p>{checkFullName(leader3)}</p>
              </div>
            </div>
            <p>{leader3.points}</p>
          </div>
          <div className={`${style.bottom}`}>3</div>
        </div>
      </div>
    </>
  )
}

export default Leaders