export const COMPETITION_KIND = {
    UNKNOWN : 0,
    PRACTICE : 1,
    PROJECT : 2,
};

export const COMPETITION_STATUS = {
    ACTIVE  : 1,
    PASSIVE  : 2,
    UPCOMING  : 3,
};
