import React, { useEffect, useState } from "react";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../layout/Page/Page";
import Button from "../../../../components/bootstrap/Button";
import { useAdminDashboardQuery } from "../../../../store/features/apiSlice";
import DashboardHeaderTitle from "../../../common/Headers/DashboardHeaderWithTitle";
import TeacherApprovement from "./teacher-approvement/TeacherApprovement";
import { Spinner } from "react-bootstrap";

const AdminDashboard = () => {
  const { data, isLoading } = useAdminDashboardQuery();
  const [userDetails, setUserDetails] = useState({
    total: null,
    premium: null,
    standart: null,
    free: null,
    teacher: null,
    student: null,
  });
  useEffect(() => {
    if (data && typeof data === 'object') {
      setUserDetails({
        total: data.number_of_the_users || 0,
        premium: data.number_of_the_premium_users || 0,
        standart: data.number_of_the_standart_users || 0,
        free: data.number_of_the_free_users || 0,
        teacher: data.number_of_the_teachers || 0,
        student: data.number_of_the_students || 0,
      });
    } else {
      setUserDetails({
        total: 0,
        premium: 0,
        standart: 0,
        free: 0,
        teacher: 0,
        student: 0,
      });
    }
  }, [data]);
  

  return (
    <PageWrapper>
      <Page container="fluid">
        <DashboardHeaderTitle />
        <div
          className="mx-auto"
          style={{ fontSize: "40px", fontWeight: "500" }}
        >
          Admin Paneli
        </div>
        {isLoading ? (
          <div
            className="w-100 d-flex justify-content-center align-items-center"
            style={{ height: "70vh" }}
          >
            <div className="text-center">
              <Spinner
                animation="border"
                role="status"
                style={{ width: "3rem", height: "3rem", color: "#007bff" }}
              >
                <span className="sr-only">...</span>
              </Spinner>
              <h3
                className="mt-3"
                style={{ color: "#007bff", fontWeight: "bold" }}
              >
                Yükleniyor...
              </h3>
            </div>
          </div>
        ) : (
          <>
            <div className="mt-5">
              <div className="h1 px-5 my-3">Kullanıcı Raporu</div>
              <div
                className="d-flex justify-content-between px-5"
                style={{ pointerEvents: "none" }}
              >
                <div className="row">
                  <div className="col-auto">
                    <Button
                      className={`bg-l50-success text-info fw-bold fs-5 px-3 py-2 rounded-pill}`}
                    >
                      Toplam :{" "}
                      <span className="fw-normal fs-3">{`${userDetails.total}`}</span>
                    </Button>
                  </div>
                  <div className="col-auto">
                    <Button
                      className={`bg-l10-info text-info fw-bold fs-5 px-3 py-2 rounded-pill}`}
                    >
                      Premium :{" "}
                      <span className="fw-normal fs-3">{`${userDetails.premium}`}</span>
                    </Button>
                  </div>
                  <div className="col-auto">
                    <Button
                      className={`bg-l10-info text-info fw-bold fs-5 px-3 py-2 rounded-pill}`}
                    >
                      Standart :{" "}
                      <span className="fw-normal fs-3">{`${userDetails.standart}`}</span>
                    </Button>
                  </div>
                  <div className="col-auto">
                    <Button
                      className={`bg-l10-info text-info fw-bold fs-5 px-3 py-2 rounded-pill}`}
                    >
                      Ücretsiz :{" "}
                      <span className="fw-normal fs-3">{`${userDetails.free}`}</span>
                    </Button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-auto">
                    <Button
                      className={`bg-l50-info text-info fw-bold fs-5 px-3 py-2 rounded-pill}`}
                    >
                      Öğretmen :{" "}
                      <span className="fw-normal fs-3">{`${userDetails.teacher}`}</span>
                    </Button>
                  </div>
                  <div className="col-auto">
                    <Button
                      className={`bg-l50-info text-info fw-bold fs-5 px-3 py-2 rounded-pill}`}
                    >
                      Öğrenci :{" "}
                      <span className="fw-normal fs-3">{`${userDetails.student}`}</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <TeacherApprovement />
            </div>
          </>
        )}
      </Page>
    </PageWrapper>
  );
};

export default AdminDashboard;
