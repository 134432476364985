import React, { useState } from 'react'
import style from "./Homeworks.module.scss"
import Card from '../../../../../components/bootstrap/Card'
import PaginationButtons, { dataPagination } from '../../../../../components/PaginationButtons'
import { nanoid } from "nanoid";
import { useHomeworksOfClassQuery } from '../../../teacher/teacherApi';
import { useParams } from 'react-router-dom';

const Homeworks = () => {
    const { classId } = useParams();
    const { data: allHomeWorks } = useHomeworksOfClassQuery(classId);
    let homeWorks = (allHomeWorks && allHomeWorks.length > 0) && allHomeWorks.filter((item)=>item.status===1)
    const items = homeWorks ? homeWorks : [];
    // const items = []
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(5);
  return (
    <Card className={`${style.cardContainerHomework}`}>
    <div className={`${style.title} pt-2 my-2`}>
      <span className={`ms-5`}>Aktif Ödevler</span>
    </div>
    <div className={`${style.customTable} px-4`}>
      <div
        className={`${style.customHeadContainer} d-flex align-items-center justify-content-between my-1 px-2`}
      >
        <div className={`${style.customTableHead1} ${style.customTableHead}`}>
          Ödev İsmi
        </div>
      </div>

      <div className={`${style.customRowContainer}`}>
        {items && items.length === 0 ? (
          <div className={`${style.emptyTable}`}>
            <span>Ödev bulunamadı</span>
          </div>
        ) : (
          items &&
          dataPagination(items, currentPage, perPage).map((item, i) => (
            <div
              key={nanoid()}
              className={`${style.customTableRow} d-flex align-items-center justify-content-between mb-2 px-2`}
            >
              <div
                className={`${style.customTableColumn1} ${style.tableColumn}`}
              >
                <div
                  className="cursor-pointer"
                >
                  <div className={`${style.studentNameContainer}`}>
                    <div className={`${style.studentNameShort}`}>
                        {item.title}
                    </div>
                    <div className={`${style.studentNameFull}`}>
                    {item.title}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
    <div className={`${style.paginationButtons}`}>
      <PaginationButtons
        data={items}
        label="ödev"
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </div>
  </Card>
  )
}

export default Homeworks