import React from "react";
import style from "./LeaderBoardForStudent.module.scss";
import Card, {
  CardBody,
} from "../../../../components/bootstrap/Card";
import AVATARS from "../../../../common/data/avatarsDummy";

const LeaderBoardOthers = ({others,userControl}) => {
    return (
        <Card stretch className={`${style.leaderBoard_rightMenu} pt-5`}>
              {others &&
                others.map((other, i) => {
                  const { rank, id, email, total_points, avatar } = other;
                  return (
                    <Card
                      className={`${style.others} mx-auto`}
                      key={id}
                    >
                      <CardBody className="d-flex justify-content-center">
                        <div className="row">
                          <div className="col-12">
                            <div className={`${style.rank}`}>
                              <h3>{rank}</h3>
                            </div>
                            <div className={`${style.othersImgDiv} ms-1`}>
                              <img
                                src={(avatar && avatar !== "null") ? avatar : AVATARS.defaultAvatarSmall}
                                alt=""
                              />
                            </div>

                            <div className="d-flex flex-column justify-content-between ps-2">
                              <div className={`${style.userEmail} mx-auto text-center`}>
                              <div className={`${style.titleContainer}`}>
                                <div className={`${style.titleShort}`}>
                                <h5
                                  style={{
                                    backgroundColor: `${Number(userControl) === Number(id) && "lightGray"
                                      }`,
                                  }}
                                  className={`${style.email}`}
                                >
                                  {email}
                                </h5>
                                </div>
                                <div className={`${style.titleFull}`}>
                                  {email}
                                </div>
                              </div>
                              </div>
                              <div className={`${style.userPoints} mx-auto text-center`}>
                                <h5
                                  style={{
                                    backgroundColor: `${Number(userControl) === Number(id) && "lightGray"
                                      }`,
                                  }}
                                  className={`${style.points}`}
                                >
                                  {total_points}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  );
                })}
            </Card>
    );
};

export default LeaderBoardOthers;