import React from "react";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../layout/Page/Page";
import CourseListTable from "./2-CourseListTable";

const CourseManagement = () => {
  return (
    <PageWrapper>
      <Page container="fluid">
        <CourseListTable/>
      </Page>
    </PageWrapper>
  );
};

export default CourseManagement;
