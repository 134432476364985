import React from "react";
import style from "../ParentReportWithClass.module.scss";
import check from "../../../../../../assets/reports/check.png";
import cancel from "../../../../../../assets/reports/cancel.png";

const HomeworkInfo = ({ homework }) => {
  const title = homework.title;
  const status = Number(homework.status) === 2 ? check : cancel;
  return (
    <div
      className={`${style.singleHomework} d-flex justify-content-between align-items-center my-2 px-3`}
    >
      <div>{title}</div>
      <div className={`${style.statusLogo} `}>
        <img src={status} alt="" />
      </div>
    </div>
  );
};

export default HomeworkInfo;
