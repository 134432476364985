import React, { useState } from "react";
import Checks from "../../../../../components/bootstrap/forms/Checks";
import Spinner from "../../../../../components/bootstrap/Spinner";

const AttendanceOfSingleLecture = ({ attendance, user, liveLessons }) => {
  const { id: liveLessonId, student_status } = attendance;
  const [busy] = useState(false);

  const liveLesson = liveLessons.filter((item) => item.id === liveLessonId);
  const liveLectureDate = liveLesson[0].date;
  function calculateEndDateAndCheckIfGreaterThanToday(startDate) {
    const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000; // 1 hafta süresi (milisaniye cinsinden)
    const endDate = new Date(startDate.getTime() + oneWeekInMilliseconds);

    const isEndDate = (date) => {
      const now = new Date();
      return date instanceof Date && date > now;
    };

    const isEndDateGreaterThanToday = isEndDate(endDate);

    return {
      endDate,
      isEndDateGreaterThanToday,
    };
  }

  const inputDate = new Date(liveLectureDate); // Başlangıç tarihi
  const { isEndDateGreaterThanToday } =
    calculateEndDateAndCheckIfGreaterThanToday(inputDate);

  return (
    <div className="d-flex">
      <Checks
        type="checkbox"
        value={student_status}
        disabled={!isEndDateGreaterThanToday || busy}
        checked={student_status === 2 ? true : false}
        // onChange={handleAttendance}
        readOnly
      />
       {busy && (
                        <Spinner color="primary" inButton isSmall>
                          Loading...
                        </Spinner>
                      )}
      {/* {liveLessonId}{currentUserId} */}
    </div>
  );
};

export default AttendanceOfSingleLecture;
