const sections = ["1", "2", "3", "4", "37", "38", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "39", "40", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36"]

const games = [
  { section: 1, first_step: 1, gameId: 1 },
  { section: 2, first_step: 11, gameId: 1 },
  { section: 3, first_step: 21, gameId: 1 },
  { section: 4, first_step: 33, gameId: 1 },
  { section: 37, first_step: 404, gameId: 1 },
  { section: 38, first_step: 414, gameId: 1 },

  { section: 5, first_step: 43, gameId: 2 },
  { section: 6, first_step: 55, gameId: 2 },
  { section: 7, first_step: 70, gameId: 2 },
  { section: 8, first_step: 80, gameId: 2 },
  { section: 9, first_step: 90, gameId: 2 },
  { section: 10, first_step: 100, gameId: 2 },

  { section: 11, first_step: 110, gameId: 3 },
  { section: 12, first_step: 120, gameId: 3 },
  { section: 13, first_step: 130, gameId: 3 },
  { section: 14, first_step: 146, gameId: 3 },
  { section: 15, first_step: 157, gameId: 3 },
  { section: 16, first_step: 167, gameId: 3 },
  { section: 17, first_step: 182, gameId: 3 },
  { section: 18, first_step: 192, gameId: 3 },

  { section: 19, first_step: 204, gameId: 4 },
  { section: 20, first_step: 214, gameId: 4 },
  { section: 21, first_step: 224, gameId: 4 },
  { section: 22, first_step: 234, gameId: 4 },
  { section: 23, first_step: 244, gameId: 4 },
  { section: 24, first_step: 254, gameId: 4 },

  { section: 39, first_step: 264, gameId: 5 },
  { section: 40, first_step: 423, gameId: 5 },

  { section: 25, first_step: 284, gameId: 7 },
  { section: 26, first_step: 294, gameId: 7 },
  { section: 27, first_step: 304, gameId: 7 },
  { section: 28, first_step: 314, gameId: 7 },
  { section: 29, first_step: 324, gameId: 7 },

  { section: 30, first_step: 334, gameId: 8 },
  { section: 31, first_step: 344, gameId: 8 },
  { section: 32, first_step: 354, gameId: 8 },
  { section: 33, first_step: 364, gameId: 8 },
  { section: 34, first_step: 374, gameId: 8 },
  { section: 35, first_step: 384, gameId: 8 },
  { section: 36, first_step: 394, gameId: 8 },
];

export function nextGame(gameStep, gameId, sectionId, navigate, lastStep) {
  if (gameStep.id < lastStep)
    navigate(`/games/${gameId}/sections/${sectionId}/steps/${gameStep.id + 1}`);

  if (gameStep.id === lastStep) {
    let currentSectionIndex = sections.indexOf(sectionId);
    let targetSection = sections[currentSectionIndex + 1];
    let targetGame = games.filter((item) => Number(item.section) === Number(targetSection))[0]
      .gameId;
    let targetStep = games.filter((item) => Number(item.section) === Number(targetSection))[0]
      .first_step;

    navigate(
      `/games/${targetGame}/sections/${targetSection}/steps/${targetStep}`
    );
  }
}
export function previousGame(gameStep, gameId, sectionId, navigate, firstStep) {
  if (gameStep.id > firstStep)
    navigate(`/games/${gameId}/sections/${sectionId}/steps/${gameStep.id - 1}`);
}
