// import React, { Component } from "react";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../layout/Page/Page";
import DashboardHeaderWithTitle from "../../../common/Headers/DashboardHeaderWithTitle";
import TeacherDashboard from "./TeacherDashboard.js";
import UnAuthorizedTeacherDashboard from "./UnAuthorizedTeacherDashboard";
import TEACHER_ROLE_STATUS from "../../../../common/data/enumTeacherRoleStatus";

const TeacherDashboardWrapper = () => {
  const teacherStatus = localStorage.getItem("userRoleStatus")
  // const teacherStatus = 1
  const isPending = Number(teacherStatus) === TEACHER_ROLE_STATUS.PENDING;
  const isUnauthorizedTeacher = isPending
  return (
    <PageWrapper>
      <DashboardHeaderWithTitle title="Anasayfa" />
      <Page>
        {!isUnauthorizedTeacher && <TeacherDashboard isFluid />}
        {isUnauthorizedTeacher && <UnAuthorizedTeacherDashboard/>}
      </Page>
    </PageWrapper>
  );
};

export default TeacherDashboardWrapper;
