import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import DashboardHeader from "../../common/Headers/DashboardHeader";

const FailPayment = () => {
  const navigate = useNavigate();

  return (
    <PageWrapper>
      <DashboardHeader />
      <Page>
        <div className="mx-auto">
          <div className="d-flex justify-content-center align-items-center mb-5 mt-3">
            <img className="thumb" src="/images/error.gif" alt="error" />
            <h1>Ödemeniz başarısız oldu</h1>
          </div>
          <div>
            <p style={{ fontSize: "20px" }}>
              Ödeme bilgilerini güncelleyerek yeniden deneyebilir, planlar
              arasından sana en uygunu seçebilirsin!
            </p>
          </div>
        </div>
        <div className="my-5 mx-auto">
          <Button
          icon='RunningWithErrors'
          color='info'
          size="lg"
          onClick={() =>
              navigate(
                  `/payment`,
              )}>Ödeme sayfasına git!</Button>
          </div>
      </Page>
    </PageWrapper>
  );
};

export default FailPayment;
