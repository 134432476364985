import React from "react";
import Page from "../../../../../layout/Page/Page";
import CourseListTable from "./2-CourseListTable";

const CourseManagement = () => {
  return (
    <>
      <Page container="fluid">
        <CourseListTable/>
      </Page>
    </>
  );
};

export default CourseManagement;
