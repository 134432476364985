import React, { useState } from "react";
import style from "./TeacherApprovement.module.scss";
import Card from "../../../../../components/bootstrap/Card";
import studentApprovementCheck from "../../../../../../src/assets/dashboardImages/studentApprovementCheck.svg";
import studentApprovementCancel from "../../../../../../src/assets/dashboardImages/studentApprovementCancel.svg";
import Icon from "../../../../../components/icon/Icon";
import {
  useAdminDashboardQuery,
  useApproveTeacherMutation,
} from "../../../../../store/features/apiSlice";
import PaginationButtons, {
  PER_COUNT,
  dataPagination,
} from "../../../../../components/PaginationButtons";
import useSortableData from "../../../../../hooks/useSortableData";
import { useNavigate } from "react-router-dom";
import TEACHER_ROLE_STATUS from "../../../../../common/data/enumTeacherRoleStatus";
import _ from "underscore";
import { formatDate2TurkishShortDate } from "../../../../../utils/formatDate";
import showNotification from "../../../../../components/extras/showNotification";

const TeacherApprovement = () => {
  const navigate = useNavigate();
  const { data} = useAdminDashboardQuery();

  let teacherApprovementList = data && data.teacher_approvement;
  teacherApprovementList =
    teacherApprovementList &&
    teacherApprovementList.filter((item) => item.role_status === 1);
  teacherApprovementList = _.sortBy(
    teacherApprovementList,
    "date_joined"
  ).reverse();

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_COUNT["10"]);
  const { items } = useSortableData(teacherApprovementList ?? []);

  const [approveTeacher] = useApproveTeacherMutation();
  async function approveOrRejectTeacher(id, isApproved) {
    const response = await approveTeacher({ teacherId: id, isApproved });
    if (response.error) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>İşlem başarısız oldu</span>
        </span>,
        "Bir hata oluştu",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          {/* <span>İşlem başarılı</span> */}
        </span>,
        response.data.role_status === 2
          ? "Öğretmen onayı reddeildi"
          : "Öğretmen onayı kabul edildi",
        response.data.role_status === 2 ? "warning" : "success"
      );
    }
  }
  const showCertificate = (id) => {
    navigate(`/admin/${id}`);
  };
  return (
    <Card className={`${style.cardContainer}`}>
      <div className="pt-2 my-2">
        <span className={`${style.title} ms-5`}>Öğretmen Onayı</span>
      </div>
      <div className={`${style.customTable} mt-1 px-4`}>
        <div
          className={`${style.customHeadContainer} d-flex align-items-center my-3 px-2`}
        >
          <div className={`${style.customTableHead1} ${style.customTableHead}`}>
            Ad ve Soyad
          </div>
          <div className={`${style.customTableHead2} ${style.customTableHead}`}>
            E-posta
          </div>

          <div className={`${style.customTableHead3} ${style.customTableHead}`}>
            Tarih
          </div>
          <div
            className={`${style.customTableHead4} ${style.customTableHead} text-center`}
          >
            Telefon
          </div>
          <div
            className={`${style.customTableHead5} ${style.customTableHead} text-center`}
          >
            Belge
          </div>
          <div
            className={`${style.customTableHead6} ${style.customTableHead} text-center`}
          >
            Onay/Ret
          </div>
        </div>
        <div className={`${style.customRowContainer}`}>
          {items.length === 0 ? (
            <div>
              <span>Kayıtlı öğretmen bulunamadı</span>
            </div>
          ) : (
            dataPagination(items, currentPage, perPage).map((item, i) => (
              <div
                key={item.id}
                className={`${style.customTableRow} ${
                  i % 2 === 0 && style.customBackgroundColor
                } d-flex align-items-center mt-1 px-2`}
              >
                <div className={`${style.customTableColumn1}`}>
                  <div className={`${style.titleContainer}`}>
                    <div className={`${style.titleShort}`}>
                      {item.full_name}
                    </div>
                    <div className={`${style.titleFull}`}>{item.full_name}</div>
                  </div>
                </div>
                <div className={`${style.customTableColumn2}`}>
                  <div className={`${style.classContainer}`}>
                    <div className={`${style.classShort}`}>{item.email}</div>
                    <div className={`${style.classFull}`}>{item.email}</div>
                  </div>
                </div>
                <div className={`${style.customTableColumn3}`}>
                  <span>{formatDate2TurkishShortDate(item.date_joined)}</span>
                </div>
                <div className={`${style.customTableColumn4} text-center`}>
                  <span>{item.telephone}</span>
                </div>
                <div className={`${style.customTableColumn5} text-center`}>
                  <div className="d-flex justify-content-center align-items-center cursor-pointer">
                    <Icon
                      icon="book"
                      size="2x"
                      onClick={() => showCertificate(item.public_id)}
                    />
                  </div>
                </div>

                {/* Eylemler */}
                <div
                  className={`${style.customTableColumn6} d-flex justify-content-center mx-auto`}
                >
                  <div
                    className={`${style.icons} d-flex justify-content-center gap-3`}
                  >
                    <img
                      src={studentApprovementCheck}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        approveOrRejectTeacher(
                          item.id,
                          TEACHER_ROLE_STATUS.APPROVED
                        )
                      }
                    />
                    <img
                      src={studentApprovementCancel}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        approveOrRejectTeacher(
                          item.id,
                          TEACHER_ROLE_STATUS.REJECTED
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <PaginationButtons
        data={items}
        label="öğretmen"
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </Card>
  );
};

export default TeacherApprovement;
