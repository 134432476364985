import React, { useState } from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../components/bootstrap/Modal";
import Logo from "../../../../components/Logo";
import Icon from "../../../../components/icon/Icon";
import Button from "../../../../components/bootstrap/Button";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import showNotification from "../../../../components/extras/showNotification";

const UserBulkAddModal = ({ isOpenBulkUserModal, setIsOpenBulkUserModal }) => {
  const baseUrl = process.env.REACT_APP_CENTRAL_API_URL;

  function getToken(){
    let token = localStorage.getItem('token');
    if(token){
       return JSON.parse(token);
    }
  }
  const token = getToken()

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = () => {
    if (file) {
      console.log(file);
      const formData = new FormData();
      formData.append("file", file);

      fetch(baseUrl + `users/bulk/`, {
        method: "POST",
        body: formData,
        headers: {
          "Content-Disposition": `form-data; filename="${file.name}"`,
          "Authorization": `Bearer ${token}`
        },
      })
      .then((response) => response.json())
      .then((data) => {
          console.log("Yükleme tamamlandı", data);
          if (data.line && data.details === "This user already exist.") {
            const existingUser = {
              first_name: data.line[0],
              last_name: data.line[1],
              email: data.line[2],
              password: data.line[3],
              user_role: data.line[4],
              subscription_type: data.line[5],
              subscription_period: data.line[6],
            };
            const alertMessage = `Kullanıcı zaten var ya da mükerrer kullanıcı: ${existingUser.email}`;
            alert(alertMessage);
          }else{
            showNotification(
              <span className="d-flex align-items-center">
                <Icon icon="Info" size="lg" className="me-1" />
                <span>Kullanıcı Ekleme Başarılı</span>
              </span>,
              "Kullanıcılar başarıyla eklendi.",
              "success"
            );
            setIsOpenBulkUserModal(false)
          }
        })
        .catch((error) => {
          console.error("Yükleme hatası", error);
          showNotification(
            <span className="d-flex align-items-center">
              <Icon icon="danger" size="lg" className="me-1" />
              <span>Kullanıcı Ekleme Başarısız</span>
            </span>,
            "Bir hata oluştu",
            "danger"
          );
        });
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="warning" size="lg" className="me-1" />
          <span>Lütfen bir dosya seçin.</span>
        </span>,
        "Dosya seçilmedi",
        "warning"
      );
    }
  };
  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenBulkUserModal}
      setIsOpen={setIsOpenBulkUserModal}
      titleId="example-title"
      size={null}
    >
      <ModalHeader setIsOpen={setIsOpenBulkUserModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} />{" "}
          <span className="ps-2">Toplu kullanıcı ekleme</span>
          <span className="ps-2">
            <Icon icon="Person" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="row gap-3 d-flex justify-content-center">
          <div className="col-lg-8">
            <FormGroup
              // className="col-4"
              id="first_name"
              isFloating
              label="Ad"
            >
              <Input
                onChange={handleFileChange}
                type="file"
                accept=".csv"
              />
            </FormGroup>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={handleUpload}
        >
          Yükle
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UserBulkAddModal;
