import style from "./LeaderBoardClassAll.module.scss";
import Card from "../../../../components/bootstrap/Card";
import AVATARS from "../../../../common/data/avatarsDummy";

const LeaderBoardClassAll = ({ classStudents}) => {
    // variables
    let userWarning;
  
  return (
      <Card className={`${style.leaderBoardCard}`}>
        <div className={`${style.customTable} mt-5 px-4`}>
          <div className={`d-flex my-3 px-2`}>
            <div
              className={`${style.customTableHead1} ${style.customTableHead}`}
            >
              Lider Tahtası
            </div>
          </div>
          <hr
            className={`mx-auto mt-3`}
            style={{
              background: "#B6B5C8",
              width: "100%",
              position: "relative",
              right: "0px",
            }}
          />
          <div className={`${style.customRowContainer}`}>
            {classStudents && classStudents.length === 0 ? (
              <div>
                <span>Henüz sıralama oluşmadı</span>
              </div>
            ) : (
              classStudents &&
              classStudents.map((student) => {
                const {
                  rank,
                  id,
                  points,
                  avatar,
                  first_name,
                  last_name,
                } = student;
                const isUserInfo = first_name === null;
                const userInfo = (
                  <span>
                    {first_name} {last_name}
                  </span>
                );
                userWarning = (
                  <span className="text-danger">
                    Ad soyad bilgisi gereklidir.
                  </span>
                );
                return (
                  <div
                    key={id}
                    className={`${style.customTableRow} mt-3 px-2 d-flex align-items-center`}
                  >
                    <div className={`${style.customTableColumn1} d-flex align-items-center justify-content-between`}>
                      <div className="d-flex align-items-center gap-2">
                        <div className={`${style.userRank}`}>{rank}</div>
                        <div className={`${style.userAvatar}`}>
                          <img
                            src={(avatar && avatar !== "null") ? avatar : AVATARS.defaultAvatarSmall}
                            alt=""
                          />
                        </div>
                        <div className={`${style.userInfo}`}>
                          {/* {first_name} {last_name} */}
                          {!isUserInfo ? userInfo : userWarning}
                        </div>
                      </div>
                      <div className={`${style.userPoints}`}>
                        {points}
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </Card>
  );
};

export default LeaderBoardClassAll;
