import React, { useEffect, useState } from "react";
import Card, { CardBody } from "../../../../components/bootstrap/Card";
import useSortableData from "../../../../hooks/useSortableData";
import PaginationButtons, {
  dataPagination,
} from "../../../../components/PaginationButtons";
import useSelectTable from "../../../../hooks/useSelectTable";
import Icon from "../../../../components/icon/Icon";
import Checks from "../../../../components/bootstrap/forms/Checks";
import { useSelector,useDispatch } from "react-redux";
import { setSelectedClass } from "../teacherSlice";

const LearningPathsTable = ({ paths, filteredLearningPath, onAdd }) => {

  const {selectedClass} = useSelector(store=>store.teacher);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const { items, requestSort, getClassNamesFor } =
    useSortableData(paths);
  const onCurrentPageData = dataPagination(items, currentPage, perPage);
  const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageData);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(setSelectedClass({...selectedClass,learning_paths:selectTable.values.selectedList}));
    //eslint-disable-next-line
  }, [selectTable.values.selectedList]);

 useEffect(() => {
   console.log("selectedclass",selectedClass)
  if(selectedClass){
  selectTable.setFieldValue("selectedList", [
    ...selectedClass.learning_paths?.map(s=>s.toString())
  ])};
//eslint-disable-next-line
  }, []);

  return (
    <div>
      <Card>
        <CardBody>
          <table className="table table-modern">
            <thead>
              <tr>
                <th style={{ width: 50 }}>{SelectAllCheck}</th>
                <th
                  onClick={() => requestSort("firstName")}
                  className="cursor-pointer text-decoration-underline"
                >
                  Başlık{" "}
                  <Icon
                    size="lg"
                    className={getClassNamesFor("firstName")}
                    icon="FilterList"
                  />
                </th>
                <th
                  onClick={() => requestSort("lastName")}
                  className="cursor-pointer text-decoration-underline"
                >
                  Açıklama{" "}
                  <Icon
                    size="lg"
                    className={getClassNamesFor("lastName")}
                    icon="FilterList"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {!filteredLearningPath
              ?
              onCurrentPageData.map((item) => (
                <tr key={item.id}>
                  <td>
                    <Checks
                      id={item.id.toString()}
                      name="selectedList"
                      value={item.id}
                      onChange={selectTable.handleChange}
                      checked={selectTable.values.selectedList.includes(
                        item.id.toString()
                      )}
                    />
                  </td>
                  <td>{item.title}</td>
                  <td>{item.explanation}</td>
                </tr>
              )):
              filteredLearningPath.map((item) => (
                <tr key={item.id}>
                  <td>
                    <Checks
                      id={item.id.toString()}
                      name="selectedList"
                      value={item.id}
                      onChange={selectTable.handleChange}
                      checked={selectTable.values.selectedList.includes(
                        item.id.toString()
                      )}
                    />
                  </td>
                  <td>{item.title}</td>
                  <td>{item.explanation}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </CardBody>
        <PaginationButtons
          data={items}
          label="ders"
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </Card>
    </div>
  );
};

export default LearningPathsTable;
