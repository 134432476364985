import React from "react";
import AddHomeworkForm from "./AddHomeworkForm";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../../../../components/bootstrap/Card";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../../layout/Page/Page";
import style from "./teacherHomeworks.module.scss";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useSingleHomeworkQuery } from "../../teacherApi";

const Homework = ({ isFluid }) => {
  const { id } = useParams();

  const selectedHomeWork = useSelector((state) => state.teacher.selectedHomeWork);
  console.log( "Selected homework: ", selectedHomeWork );

  const title = id ?`${"DÜZENLE"}` : "YENİ ÖDEV";

  return (
    <PageWrapper>
      <Page>

        <Card stretch={isFluid} className="h-100">
          <CardHeader>
            <CardLabel>
              <CardTitle className={`${style.title} ps-3`}>{title}</CardTitle>
            </CardLabel>
          </CardHeader>
          <CardBody className="table-responsive" isScrollable={isFluid}>
            {id ? <EditHomeworkById id={id} /> :
            selectedHomeWork ? <EditHomeworkBySelectedHomework selectedHomeWork={selectedHomeWork} /> :
            <NewHomework />}
          </CardBody>
        </Card>

      </Page>
    </PageWrapper>
  );
};

export default Homework;

function NewHomework() {
  return <AddHomeworkForm />
}

function EditHomeworkById({ id }) {
  console.log('%cHomework.js line:51 EditHomeWorkById', 'color: #007acc;');

  const { data: selectedHomeWork, isSuccess } = useSingleHomeworkQuery(id);

  // useEffect(() => {
  //   if (isSuccess) {
  //     console.log(selectedHomeWork);

  //     dispatch(setSelectedHomework(homework));
  //   }
  // }, [isSuccess]);

  if(isSuccess)  return <AddHomeworkForm selectedHomeWork={selectedHomeWork}/>

  return <div>Loading...</div>

}
function EditHomeworkBySelectedHomework({ selectedHomeWork }) {
console.log('%cHomework.js line:69 EditHomeworkBySelectedHomework', 'color: #007acc;');
  return <AddHomeworkForm selectedHomeWork={selectedHomeWork}/>
}