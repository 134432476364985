import Input from "../../../../components/bootstrap/forms/Input";
import Icon from "../../../../components/icon/Icon";
import classes from "./teacher-class.module.scss";

function Search({handleSearchChange, iconColor}){
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    handleSearchChange(newValue);
};
    return(
        <div className={`${classes.searchContainer} d-flex align-items-center justify-content-center`}>
              <label className="" htmlFor="searchInput">
                <Icon icon="Search" size="2x" style={{color:iconColor ?  iconColor: "#000"}}/>
              </label>
              <Input
                // ref={refSearchInput}
                id="searchInput"
                className="border-0 shadow-none bg-transparent"
                // placeholder="Ara..."
                onChange={handleInputChange}
              />
            </div>
    );
}
export default Search;
