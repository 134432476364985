import React, { useState } from "react";
import style from "./DiscountCouponWrapper.module.scss";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import DashboardHeader from "../../../common/Headers/DashboardHeader";
import Page from "../../../../layout/Page/Page";
import announce1 from "../../../../../src/assets/lessons/announce1.svg";
import announce2 from "../../../../../src/assets/lessons/announce2.svg";
import CouponList from "./Coupon_List/CouponList";

const DiscountCouponWrapper = () => {
  const _activeTab = sessionStorage.getItem("talentCupActiveTab");
  const [activeTab, setActiveTab] = useState(Number(_activeTab) || 1);

  const tabContents = [
    {
      content: <CouponList />,
      tabName: "İndirim Kuponları",
      icon1: announce1,
      icon2: announce2,
    },
  ];

  const handleTabClick = (tabNumber) => {
    if (tabNumber >= 1 && tabNumber <= tabContents.length) {
    sessionStorage.setItem("talentCupActiveTab", tabNumber);
    setActiveTab(tabNumber);}
  };
  return (
    <PageWrapper title={"Dersler"}>
      <DashboardHeader />
      <Page container={false} style={{ padding: "0" }}>
        <div className="page container-xxl">
        <div
          className={`${style.tabContainer} d-flex justify-content-evenly align-items-center mx-auto mb-5 px-3 gap-1`}
        >
          {tabContents.map((tab, index) => (
            <div
              key={index}
              className={`${style.tab} ${
                activeTab === index + 1 ? `${style.activetab}` : ""
              } d-flex justify-content-center align-items-center gap-2 p-3`}
              onClick={() => handleTabClick(index + 1)}
            >
              <div className={`${style.tabsLogo}`}>
                <img
                  src={activeTab === index + 1 ? tab.icon2 : tab.icon1}
                  alt=""
                />
              </div>
              <div className={`${style.tabsDescription}`}>
                <span>{tab.tabName}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="tab-content">
            {tabContents[activeTab - 1] && tabContents[activeTab - 1]?.content}
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default DiscountCouponWrapper;
