import React, { useEffect, useState } from "react";
import { ArrowLeft, ArrowRight } from "../../../../components/icon/bootstrap";
import Input from "../../../../components/bootstrap/forms/Input";

const SimpleSelectList = ({
  items,
  selectedItems,
  onSelect,
  selectKey,
  displayKey,
}) => {
  const [page, setPage] = useState(1);
  const [limit] = useState(8);
  const [pages, setPages] = useState(0);
  const [filteredItems, setFilteredItem] = useState([]);

  function displayPage(pageNumber, items) {
    if (!items) return [];

    const startIndex = (pageNumber - 1) * limit;
    const endIndex = startIndex + limit;
    return items.slice(startIndex, endIndex);
  }
  useEffect(() => {
    if (items) {
      setPages(Math.ceil(items.length / limit));
      setFilteredItem(items);
      setPage(1);
    }
    //eslint-disable-next-line
  }, [items]);

  return (
    <div>
      <Input
        onChange={(e) => {
          setFilteredItem(
            items.filter((item) =>
              item[displayKey]
                .toLowerCase()
                .includes(e.target.value.toLowerCase())
            )
          );
          setPage(1);
        }}
        placeholder="Arama yapınız"
      />

      <table className="table mt-4">
        <tbody>
          {displayPage(page, filteredItems).map((item) => (
            <tr key={item[selectKey]}>
              <td className="d-flex align-items-center gap-2">
                <input
                  type="checkbox"
                  checked={selectedItems?.includes(item[selectKey])}
                  id={item[selectKey].toString()}
                  onChange={(e) => {
                    onSelect(e.target.id, e.target.checked);
                  }}
                />
                {item[displayKey]}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div>
                <ArrowLeft
                  onClick={() =>
                    page === 1 ? setPage(pages) : setPage(page - 1)
                  }
                  className="cursor-pointer"
                />
                {"  "}
                {page}/{pages}
                {"  "}
                <ArrowRight
                  onClick={() =>
                    page === pages ? setPage(1) : setPage(page + 1)
                  }
                  className="cursor-pointer"
                />
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default SimpleSelectList;
