import React from "react";
import style from "./StudentApprovement.module.scss";
import { useApproveStudentMutation, useGetJoinStudentQuery } from "../../teacherApi";
import Card from "../../../../../components/bootstrap/Card";
import studentApprovementCheck from "../../../../../../src/assets/dashboardImages/studentApprovementCheck.svg"
import studentApprovementCancel from "../../../../../../src/assets/dashboardImages/studentApprovementCancel.svg"

const StudentApprovement = () => {
  const { data: joinClass } = useGetJoinStudentQuery();
  const [approveStudent] = useApproveStudentMutation();
  function approveOrRejectStudent(id, isApproved) {
    approveStudent({ student_id: id, isApproved });
  }
  
  return (
    <Card className={`${style.cardContainer}`}>
    <div className='pt-2 my-2'> 
    <span className={`${style.title} ms-5`}>Öğrenci Kayıt Onayı</span>
  </div>
    <div className={`${style.customTable} px-4`}>
      <div className={`${style.customHeadContainer} d-flex align-items-center my-3 px-2`}>
        <div
          className={`${style.customTableHead1} ${style.customTableHead}`}
        >
          Ad ve Soyad
        </div>
        <div
          className={`${style.customTableHead2} ${style.customTableHead}`}
        >
          Sınıf
        </div>
        <div
          className={`${style.customTableHead3} ${style.customTableHead} text-end`}
        >
          Durum
        </div>
      </div>
      <div className={`${style.customRowContainer}`}>
        {joinClass && joinClass.length === 0 ? (
          <div className={`${style.emptyTable}`}>
            <span>Kayıt için onay bekleyen öğrenci bulunamadı</span>
          </div>
        ) : (
          joinClass &&
          joinClass.map((item, i) => (
            <div
              key={item.id}
              className={`${style.customTableRow} ${
                i % 2 === 0 && style.customBackgroundColor
              } d-flex align-items-center mt-1 px-2`}
            >
              <div className={`${style.customTableColumn1}`}>
                <div>{item?.user_full_name}</div>
              </div>
              <div className={`${style.customTableColumn2}`}>
                 <div className={`${style.classContainer}`}>
                  <div className={`${style.classShort}`}>{item.class_name}</div>
                  <div className={`${style.classFull}`}>{item.class_name}</div>
                    </div>
              </div>
              <div className={`${style.customTableColumn3}`}>
                <div className={`${style.icons} d-flex justify-content-end gap-3 m-0 p-0`}>
                <img src={studentApprovementCheck} alt=""  style={{cursor: "pointer"}} 
                      onClick={() => approveOrRejectStudent(item.id, true)}
                      />
                      <img src={studentApprovementCancel} alt=""  style={{cursor: "pointer"}} 
                      onClick={() => approveOrRejectStudent(item.id, false)}
                      />
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  </Card>
  );
};

export default StudentApprovement;
