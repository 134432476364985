import React from 'react';

const PathIcon = ({technology,alt, width, height, opacity}) => {
    return (
        <img
        width={width || '50px'}
        height={height || '50px'}
        src={"/images/" + technology + ".png"}
        alt={alt}
        style={{opacity:opacity && opacity}}
      />
    );
};

export default PathIcon;