import React from "react";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import DashboardHeader from "../../common/Headers/DashboardHeader";
import Page from "../../../layout/Page/Page";
import {
  useGamesQuery,
} from "../../../store/features/apiSlice";
import Button from "../../../components/bootstrap/Button";
import USER_SUBSCRIPTION_TYPE from "../../../common/data/enumUserSubscriptionType";
import { useNavigate } from "react-router";
import style from "./Games.module.scss";
import { useState } from "react";
import lessonsLogo1 from "../../../../src/assets/lessons/lessonsLogo1.svg";
import lessonsLogo2 from "../../../../src/assets/lessons/lessonsLogo2.svg";
import { getGameStatusColor, getGameStatusText } from "./gameFunctions";

const Games = ({ isFluid }) => {
  const userSubscriptionType = localStorage.getItem("subscription_type");
  //  const userSubscriptionType = 3;
  const { data: games} = useGamesQuery();

  const isRestricted = (games?.length === 0) || (games && games.filter((game)=>Number(game.tag) === 1).length === 0)

  const navigate = useNavigate();

  const handleNavigation = (path, activeTab) => {
    navigate(`${path}?activeTab=${activeTab}`);
  };

  // useEffect(() => {
  //   if (window.Tawk_API) {
  //     window.Tawk_API.hideWidget();
  //   }
  //   return () => {
  //     if (window.Tawk_API) {
  //       window.Tawk_API.showWidget();
  //     }
  //   };
  // }, []);
  const [activeTab] = useState(1);

  const tabContents = [
    {
      tabName: "Oyunlar",
      icon1: lessonsLogo1,
      icon2: lessonsLogo2,
    },
  ];
  return (
    <PageWrapper title={"Oyunlar"}>
      <DashboardHeader />
      <Page container={false} style={{ padding: "0" }}>
        <div className="page container-xxl">
          <div
            className={`${style.tabContainer} d-flex justify-content-evenly align-items-center mx-auto mb-5 px-3 gap-1`}
          >
            {tabContents.map((tab, index) => (
              <div
                key={index}
                className={`${style.tab} ${
                  activeTab === index + 1 ? `${style.activetab}` : ""
                } d-flex justify-content-center align-items-center gap-2 p-3`}
              >
                <div className={`${style.tabsLogo}`}>
                  <img
                    src={activeTab === index + 1 ? tab.icon2 : tab.icon1}
                    alt=""
                  />
                </div>
                <div className={`${style.tabsDescription}`}>
                  <span>{tab.tabName}</span>
                </div>
              </div>
            ))}
          </div>
          {
            isRestricted 
            ? <h3 className="text-center mt-5">Bu sayfadaki içeriklere erişiminiz yoktur.</h3> 
            : 
          <div className="row">
            {games &&
              games
                .filter(
                  (game) =>
                    game.title !== "CodeBoo" && game.title !== "CodeCizz"
                )
                .map((game) => {
                  const { id, title, image_path, has_section, user_status } = game;
                  // userGame =
                  //   userGames && userGames.filter((item) => item.game === id);
                  // status = userGame && userGame[0] && userGame[0].status;
                  return (
                    <div
                      className="LearningPaths_learningPathCard__9zgcx col-xxl-3 col-xl-4 col-md-6"
                      key={id}
                    >
                      <div className="card">
                        <div className="card-header">
                          <div className="card-label">
                            <div className="card-title-wrapper">
                              <h5 className="card-title">{title}</h5>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <img
                            src={image_path}
                            alt=""
                            width={128}
                            height={128}
                            className="mx-auto d-block img-fluid mb-3"
                          />
                        </div>
                        {Number(id) === 1 ? (
                          has_section ? (
                            <div className="card-footer shadow-3d-container ">
                              <Button
                                className={`btn btn-${getGameStatusColor(
                                  user_status
                                )} btn-lg w-100 mb-4 shadow-3d-up-hover shadow-3d-${getGameStatusColor(
                                  user_status
                                )}`}
                                size="lg"
                                tag="a"
                                to={`/games/${id}/sections`}
                              >
                                {getGameStatusText(user_status)}
                              </Button>
                            </div>
                          ) : (
                            <div className="card-footer shadow-3d-container ">
                              <Button
                                className={`btn btn-${getGameStatusColor(
                                  user_status
                                )} btn-lg w-100 mb-4 shadow-3d-up-hover shadow-3d-${getGameStatusColor(
                                  user_status
                                )}`}
                                size="lg"
                                tag="a"
                                to={`/games/${id}/sections/steps`}
                              >
                                {getGameStatusText(user_status)}
                              </Button>
                            </div>
                          )
                        ) : Number(userSubscriptionType) !==
                          USER_SUBSCRIPTION_TYPE.FREE.id ? (
                          has_section ? (
                            <div className="card-footer shadow-3d-container ">
                              <Button
                                className={`btn btn-${getGameStatusColor(
                                  user_status
                                )} btn-lg w-100 mb-4 shadow-3d-up-hover shadow-3d-${getGameStatusColor(
                                  user_status
                                )}`}
                                size="lg"
                                tag="a"
                                to={`/games/${id}/sections`}
                              >
                                {getGameStatusText(user_status)}
                              </Button>
                            </div>
                          ) : (
                            <div className="card-footer shadow-3d-container ">
                              <Button
                                className={`btn btn-${getGameStatusColor(
                                  user_status
                                )} btn-lg w-100 mb-4 shadow-3d-up-hover shadow-3d-${getGameStatusColor(
                                  user_status
                                )}`}
                                size="lg"
                                tag="a"
                                to={`/games/${id}/sections/steps`}
                              >
                                {getGameStatusText(user_status)}
                              </Button>
                            </div>
                          )
                        ) : has_section ? (
                          <div className="card-footer shadow-3d-container ">
                            <Button
                              color="danger"
                              className="btn btn-myDanger btn-lg w-100 mb-4 shadow-3d-up-hover shadow-3d-myDanger"
                              size="lg"
                              tag="a"
                              onClick={() =>
                                handleNavigation(`/profile`, "Account Details")
                              }
                            >
                              Planı Yükselt
                            </Button>
                          </div>
                        ) : (
                          <div className="card-footer shadow-3d-container ">
                            <Button
                              color="danger"
                              className="btn btn-myDanger btn-lg w-100 mb-4 shadow-3d-up-hover shadow-3d-myDanger"
                              size="lg"
                              tag="a"
                              onClick={() =>
                                handleNavigation(`/profile`, "Account Details")
                              }
                            >
                              Planı Yükselt
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
          </div>
          }
        </div>
      </Page>
    </PageWrapper>
  );
};

export default Games;
