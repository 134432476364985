import React, {useMemo, useState } from "react";
import Card, { CardBody } from "../../../../../components/bootstrap/Card";
import { usePathsQuery } from "../../../../../store/features/apiSlice";
import { usePracticeQuery } from "../../../../../store/features/apiSlice";
import SimpleTable from "../../components/simple-table/SimpleTable";
import SimpleSelectList from "../../components/SimpleSelectList";
import _ from "lodash";

const LearningPathList = ({ setPractices, currentPractices }) => {
  const [selectedPath, setSelectedPath] = useState(null);
  const { data: paths, isLoading, error } = usePathsQuery();

  const selectPath = (id) => {
    let path = paths.find((p) => Number(p.id) === Number(id));
    setSelectedPath(path);
  };

  function addCourseWithPractice(practice) {
    console.log(currentPractices);
    let courses = _.cloneDeep(currentPractices);
    let course = selectedPath;

    const existingCourseIndex = courses.findIndex((c) => c.id === course.id);

    if (existingCourseIndex === -1) {
      console.log("course yok");
      // Course doesn't exist, add it with the practice
      courses.push({
        id: course.id,
        title: course.title,
        practices: [{ id: practice.practice, title: practice.title }],
      });
    } else {
      const existingPracticeIndex = courses[
        existingCourseIndex
      ].practices.findIndex((p) => p.id === practice.practice);

      if (existingPracticeIndex === -1) {
        console.log("Practice doesn't exist, add it");
        // Practice doesn't exist, add it
        courses[existingCourseIndex].practices.push({
          id: practice.practice,
          title: practice.title,
        });
      } else {
        courses[existingCourseIndex].practices.splice(existingPracticeIndex, 1);

        if (courses[existingCourseIndex].practices.length === 0) {
          // If no practices left, remove the course
          courses.splice(existingCourseIndex, 1);
        }
      }
    }

    setPractices(courses);
  }

  return (
    <div>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error</p>}
      {!isLoading && !error && (
        <div className="row">
          <div className="col" style={{ minWidth: "250px" }}>
            <Card style={{ width: "100%", height: "90%" }}>
              <CardBody>
                <SimpleTable
                  displayKey={"title"}
                  items={paths}
                  onSelect={selectPath}
                  selectedItems={currentPractices.map((p) => p.id)}
                />
              </CardBody>
            </Card>
          </div>
          {selectedPath && (
            <LearningPathContent
              setPractice={addCourseWithPractice}
              currentPractices={currentPractices}
              selectedPath={selectedPath}
            />
          )}
        </div>
      )}
    </div>
  );
};
function LearningPathContent({ setPractice, currentPractices, selectedPath }) {
  const {
    data: practiceList,
    isLoading,
    error,
  } = usePracticeQuery({ id: selectedPath.id });

  let selectedPractices = useMemo(
    () => extractPractices(),
    //eslint-disable-next-line
    [currentPractices, selectedPath]
  );
  function extractPractices() {
    let selectedArray = [];

    for (const item of currentPractices) {
      if (item.id === selectedPath.id) {
        for (const p of item.practices) {
          selectedArray.push(p.id);
        }
      }
    }
    console.log(selectedArray);
    if (selectedArray !== null) {
      return selectedArray;
    }
  }
  function selectPractice(id) {
    let practice = practiceList.practices.find(
      (p) => Number(p.practice) === Number(id)
    );

    if (practice) setPractice(practice);
  }

  return (
    <div className="col" style={{ minWidth: "250px" }}>
      {practiceList && (
        <Card style={{ width: "100%", height: "90%" }}>
          <CardBody>
            <SimpleSelectList
              items={practiceList.practices}
              selectedItems={selectedPractices}
              onSelect={selectPractice}
              selectKey={"practice"}
              displayKey={"title"}
            />
          </CardBody>
        </Card>
      )}
      {isLoading && <p>Loading...</p>}
      {error && <p>Path içerik alınamadı :( path id:{selectedPath.id}</p>}
    </div>
  );
}
export default LearningPathList;
