import React, { useEffect, useState } from "react";
import style from "./homeworkTracking.module.scss";
import {
  useGetActiveClassesQuery,
  useHomeworksOfClassQuery,
  useHomeworksTrackingQuery,
} from "../../teacherApi";
import FormGroup from "../../../../../components/bootstrap/forms/FormGroup";
import Select from "../../../../../components/bootstrap/forms/Select";
import HomeworkTracking from "./HomeworkTracking";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../../layout/Page/Page";

const HomeworkTrackingWrapper = () => {
  const { data: activeClasses, isSuccess } = useGetActiveClassesQuery();
  const [selectedClassId, setSelectedClassId] = useState();
  const [selectedClassName, setSelectedClassName] = useState();
  const [homeworkStatus, setHomeworkStatus] = useState("Tamamı");
  const studentParameter = selectedClassId
    ? selectedClassId
    : activeClasses && activeClasses.length > 0 && activeClasses[0].id;

  const homeworkParameter = selectedClassId
    ? selectedClassId
    : activeClasses && activeClasses.length > 0 && activeClasses[0].id;

  const { data: homeworksOfClass } = useHomeworksOfClassQuery(
    homeworkParameter,
    { skip: homeworkParameter === undefined }
  );
  const { data: studentsOfClass } = useHomeworksTrackingQuery(
    studentParameter,
    { skip: studentParameter === undefined }
  );

  useEffect(() => {
    if (activeClasses && activeClasses.length > 0) {
      setSelectedClassId(activeClasses[0].id);
      setSelectedClassName(activeClasses[0].name);
    }
    //eslint-disable-next-line
  }, [isSuccess]);

  useEffect(() => {
    if (selectedClassName) setHomeworkStatus("Tamamı");
  }, [selectedClassName]);

  return (
    <PageWrapper>
      <Page className={`${style.pageContainer}`}>
        <div className="pt-5">
          <div
            className={`${style.actionsContainer} d-flex align-items-center justify-content-between mx-auto mt-5 px-5`}
          >
            <div className={`${style.classDropdown}`}>
              <FormGroup label="Sınıf" isFloating>
                <Select
                  //   value={String(selectedClassId)}
                  //   id="state"
                  ariaLabel="Sınıf"
                  //   placeholder="Sınıf Seçiniz.."
                  list={activeClasses?.map((c) => ({
                    value: c.id,
                    text: c.name,
                  }))}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setSelectedClassId(e.target.value);
                    setSelectedClassName(
                      e.target.options[e.target.selectedIndex].text
                    );
                  }}
                />
              </FormGroup>
            </div>
            <div>
              <FormGroup label="Ödev" isFloating>
                <Select
                  ariaLabel="homeworkStatus"
                  value={homeworkStatus}
                  list={[
                    { value: "Tamamı", text: "Tamamı" },
                    { value: "Aktif", text: "Aktif" },
                    { value: "Süresi Geçmiş", text: "Süresi Geçmiş" },
                  ]}
                  onChange={(e) => setHomeworkStatus(e.target.value)}
                ></Select>
              </FormGroup>
            </div>
          </div>
          <HomeworkTracking
            studentsOfClass={studentsOfClass}
            homeworksOfClass={homeworksOfClass}
            homeworkStatus={homeworkStatus}
          />
        </div>
      </Page>
    </PageWrapper>
  );
};

export default HomeworkTrackingWrapper;
