import { useLeaderBoardsQuery } from "../../../../../store/features/apiSlice";
import LeaderBoard from "../../../leader-board/LeaderBoard";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import DashboardHeader from "../../../../common/Headers/DashboardHeader";

const StudentLeaderBoard = () => {
  const { data, error, isLoading} = useLeaderBoardsQuery();
  // useEffect(() => {
  //   if (window.Tawk_API) {
  //     window.Tawk_API.hideWidget();
  //   }
  //   return () => {
  //     if (window.Tawk_API) {
  //       window.Tawk_API.showWidget();
  //     }
  //   };
  // }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error!</div>;
  }

  return (
    <PageWrapper>
      {/* <Page> */}
        <DashboardHeader />
        <LeaderBoard data={data} />
      {/* </Page> */}
    </PageWrapper>
  );
};

export default StudentLeaderBoard;
