// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media screen and (max-width: 992px) {\n  .PathsContainer_pathsContainer__PZzEY {\n    position: relative;\n    bottom: 50px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/t14/student/student%20content%20wrapper/school-partner%20contents%20wrapper/paths%20container/PathsContainer.module.scss","webpack://./src/pages/t14/student/student content wrapper/school-partner contents wrapper/paths container/PathsContainer.module.scss"],"names":[],"mappings":"AAAA;EACI;IACI,kBAAA;IACA,YAAA;ECCN;AACF","sourcesContent":["@media screen and (max-width:992px) {\n    .pathsContainer{\n        position: relative;\n        bottom: 50px;\n    }\n}","@media screen and (max-width: 992px) {\n  .pathsContainer {\n    position: relative;\n    bottom: 50px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pathsContainer": "PathsContainer_pathsContainer__PZzEY"
};
export default ___CSS_LOADER_EXPORT___;
