import React from "react";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import DashboardHeader from "../../common/Headers/DashboardHeader";
import Page from "../../../layout/Page/Page";
import { Link, useNavigate } from "react-router-dom";
import { useGamesQuery } from "../../../store/features/apiSlice";
import Button from "../../../components/bootstrap/Button";
import USER_SUBSCRIPTION_TYPE from "../../../common/data/enumUserSubscriptionType";
import style from "./Games.module.scss"
import { useState } from "react";
import lessonsLogo1 from "../../../../src/assets/lessons/lessonsLogo1.svg";
import lessonsLogo2 from "../../../../src/assets/lessons/lessonsLogo2.svg";
import { getGameStatusColor, getGameStatusText } from "./gameFunctions";
import { nanoid } from "nanoid";

const CodeCizz = ({ isFluid }) => {
  const userSubscriptionType = localStorage.getItem("subscription_type");
  // const userSubscriptionType = 1;
  const { data: games} = useGamesQuery();

  const navigate = useNavigate();

  const handleNavigation = (path, activeTab) => {
    navigate(`${path}?activeTab=${activeTab}`);
  };
  // useEffect(() => {
  //   if (window.Tawk_API) {
  //     window.Tawk_API.hideWidget();
  //   }
  //   return () => {
  //     if (window.Tawk_API) {
  //       window.Tawk_API.showWidget();
  //     }
  //   };
  // }, []); 
  const [activeTab] = useState(1);

  const tabContents = [
    {
      tabName: "CodeCizz",
      icon1: lessonsLogo1,
      icon2: lessonsLogo2,
    },
  ];
  return (
    <PageWrapper title={"Oyunlar"}>
      <DashboardHeader />
      <Page container={false} style={{ padding: "0" }}>
        <div className="page container-xxl">
        <div
          className={`${style.tabContainer} d-flex justify-content-evenly align-items-center mx-auto mb-5 px-3 gap-1`}
        >
          {tabContents.map((tab, index) => (
            <div
            key={nanoid()}
              className={`${style.tab} ${
                activeTab === index + 1 ? `${style.activetab}` : ""
              } d-flex justify-content-center align-items-center gap-2 p-3`}
            >
              <div className={`${style.tabsLogo}`}>
                <img
                  src={activeTab === index + 1 ? tab.icon2 : tab.icon1}
                  alt=""
                />
              </div>
              <div className={`${style.tabsDescription}`}>
                <span>{tab.tabName}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="row">
        <div className="LearningPaths_learningPathCard__9zgcx col-xxl-3 col-xl-4 col-md-6">
              <div className="card">
                <div className="card-header">
                  <div className="card-label">
                    <div className="card-title-wrapper">
                      {/* <h6 className="card-subtitle" /> */}
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <img
                    src={`/images/CodeCizz.png`}
                    alt=""
                    width={128}
                    height={128}
                    className="mx-auto d-block img-fluid mb-3"
                  />
                </div>
                <div className="card-footer shadow-3d-container ">
                  <Link
                    to="/codeCizz/freeStyle"
                    className="btn btn-myButton btn-lg w-100 mb-4 shadow-3d-up-hover shadow-3d-myButton"
                  >
                    Hayal Et ve Çiz
                  </Link>
                </div>
              </div>
            </div>

            {games &&
              games
                .filter((game) => game.title === "CodeCizz")
                .map((game) => {
                  const { id, has_section, user_status } = game;
                  return (
                    <div className="LearningPaths_learningPathCard__9zgcx col-xxl-3 col-xl-4 col-md-6" key={nanoid()}>
                      <div className="card">
                        <div className="card-header">
                          <div className="card-label">
                            <div className="card-title-wrapper">
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <img
                            src={`/images/CodeCizz.png`}
                            alt=""
                            width={128}
                            height={128}
                            className="mx-auto d-block img-fluid mb-3"
                          />
                        </div>
                        {Number(userSubscriptionType) !==
                        USER_SUBSCRIPTION_TYPE.FREE.id ? (
                          has_section ? (
                            <div className="card-footer shadow-3d-container ">
                              <Button
                                className={`btn btn-${getGameStatusColor(
                                  user_status
                                )} btn-lg w-100 mb-4 shadow-3d-up-hover shadow-3d-${getGameStatusColor(
                                  user_status
                                )}`}
                                size="lg"
                                tag="a"
                                to={`/codeCizz/${id}/sections`}
                              >
                                {getGameStatusText(user_status)}
                              </Button>
                            </div>
                          ) : (
                            <div className="card-footer shadow-3d-container ">
                              <Button
                                className={`btn btn-${getGameStatusColor(
                                  user_status
                                )} btn-lg w-100 mb-4 shadow-3d-up-hover shadow-3d-${getGameStatusColor(
                                  user_status
                                )}`}
                                size="lg"
                                tag="a"
                                to={`/codeCizz/${id}/sections/steps`}
                              >
                                {getGameStatusText(user_status)}
                              </Button>
                            </div>
                          )
                        ) : has_section ? (
                          <div className="card-footer shadow-3d-container ">
                            <Button
                              color="danger"
                              className="btn btn-myDanger btn-lg w-100 mb-4 shadow-3d-up-hover shadow-3d-myDanger"
                              size="lg"
                              tag="a"
                              onClick={()=>handleNavigation(`/profile`,"Account Details")}
                            >
                              Planı Yükselt
                            </Button>
                          </div>
                        ) : (
                          <div className="card-footer shadow-3d-container ">
                            <Button
                              color="danger"
                              className="btn btn-myDanger btn-lg w-100 mb-4 shadow-3d-up-hover shadow-3d-myDanger"
                              size="lg"
                              tag="a"
                              onClick={()=>handleNavigation(`/profile`,"Account Details")}
                            >
                              Planı Yükselt
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}

        </div>
      
        </div>
      </Page>
    </PageWrapper>
  );
};

export default CodeCizz;
