import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Page from "../../../../../layout/Page/Page";
import CreateTrailModal from "./CreateTrailModal";
import { setSelectedPathId } from "../../teacherSlice";
import {
  useDeleteTeacherTrailMutation,
  useTeacherTrailsQuery,
} from "../../teacherApi";
import useSelectTable from "../../../../../hooks/useSelectTable";
import useSortableData from "../../../../../hooks/useSortableData";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import DashboardHeaderWithTitle from "../../../../common/Headers/DashboardHeaderWithTitle";
import PaginationButtons, {
  PER_COUNT,
  dataPagination,
} from "../../../../../components/PaginationButtons";
import style from "./TeacherTrails.module.scss";
import plus from "../../../../../assets/dashboardImages/plus.svg";
import { IconButton, Tooltip } from "@mui/material";
import ConfirmButton from "../../components/confirm-button";
import { Edit } from "@mui/icons-material";
import showNotification from "../../../../../components/extras/showNotification";
import Icon from "../../../../../components/icon/Icon";
import Checks from "../../../../../components/bootstrap/forms/Checks";
import Confirm from "../../../../common/Confirm";

const TeacherTrail = ({ isFluid }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isOpenNewPathModal, setIsOpenNewPathModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_COUNT["10"]);
  const { data: paths } = useTeacherTrailsQuery();

  const { items } = useSortableData(paths ?? []);
  const onCurrentPageData = dataPagination(items, currentPage, perPage);
  const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageData);

  const [deleteTrail, { isSuccess: deleteSuccess, isError: deleteError }] =
    useDeleteTeacherTrailMutation();

  const handleDelete = () => {
    if (selected.length > 0) {
      selected.forEach((item) => deleteTrail(item));
    }
    setOpen(false);
    setSelected([]);
  };
  const handlCancel = () => setOpen(false);

  useEffect(() => {
    if (selectTable.values.selectedList) {
      setSelected(selectTable.values.selectedList);
    }
  }, [selectTable.values.selectedList]);

  useEffect(() => {
    if (deleteSuccess) {
      (function showSuccess(message) {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Silme Başarılı</span>
          </span>,
          message,
          "success"
        );
      })();
    }
    if (deleteError) {
      (function showError() {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="danger" size="lg" className="me-1" />
            <span>Silme işlemi Başarısız</span>
          </span>,
          "Bir hata oluştu",
          "danger"
        );
      })();
    }
  }, [deleteSuccess, deleteError]);

  return (
    <PageWrapper>
      <DashboardHeaderWithTitle title="Patikalarım" />
      {isOpenNewPathModal && (
        <CreateTrailModal
          isOpenNewPathModal={isOpenNewPathModal}
          setIsOpenNewPathModal={setIsOpenNewPathModal}
        />
      )}
      <Page className={`${style.pageContainer}`}>
        <div className="row">
          <div className="col-12">
            <div className={`${style.cardContainer}`}>
            <Confirm
          open={open}
          setOpen={setOpen}
          onConfirm={handleDelete}
          onCancel={handlCancel}
        />
        <div className={`${style.allDeleteButtonContainer} pe-5`}>
        {selected.length > 0 ? (
          <button
            className={`${style.allDeleteButton} btn btn-danger`}
            onClick={() => {
              setSelected(selected);
              setOpen(true);
            }}
          >
            Seçilenleri Sil
          </button>
        ) : (
          <button
            style={{ visibility: "hidden" }}
            onClick={() => {
              setSelected(selected);
              setOpen(true);
            }}
          >
            Seçilenleri Sil
          </button>
        )}
        </div>
              <div
                className={`${style.actionsContainer} d-flex align-items-center justify-content-end px-2 mx-4 gap-3 mt-5`}
              >
                <div
                  className={`${style.addClassContainer} d-flex align-items-center gap-2 ps-2 me-lg-4`}
                  onClick={() => {
                    dispatch(setSelectedPathId(undefined));
                    setIsOpenNewPathModal(true);
                  }}
                >
                  <span className="d-flex">
                    <img src={plus} alt="" />
                  </span>
                  <span>Patika Ekle</span>
                </div>
              </div>
              <div className={`${style.customTable} mt-5 px-4`}>
              <div className={`${style.customHeadContainer} d-flex align-items-center my-3 px-2`}>
              <div
              className={`${style.checkContainer} ${style.customTableHead}`}
            >{SelectAllCheck}</div>
        <div className={`${style.customTableHead1} ${style.customTableHead}`}>
          Başlık
        </div>
        <div className={`${style.customTableHead2} ${style.customTableHead}`}>
          Sınıf
        </div>

        <div
          className={`${style.customTableHead3} ${style.customTableHead} text-center`}
        >
          Sıra Numarası
        </div>
        <div
          className={`${style.customTableHead4} ${style.customTableHead} text-center`}
        >
          Durum
        </div>
        <div
          className={`${style.customTableHead5} ${style.customTableHead} text-center`}
        >
          Eylemler
        </div>
      </div>
              <div className={`${style.customRowContainer}`}>
                {items.length === 0 ? (
                  <div>
                    <span>Kayıtlı patika bulunamadı</span>
                  </div>
                ) : (
                  dataPagination(items, currentPage, perPage).map((item, i) => (
                    <div
                      key={item.id}
                      className={`${style.customTableRow} ${
                        i % 2 === 0 && style.customBackgroundColor
                      } d-flex align-items-center mt-1 px-2`}
                    >
                       <div className={`${style.checkContainer}`}>
                    <Checks
                      id={item.id.toString()}
                      name="selectedList"
                      value={item.id}
                      onChange={selectTable.handleChange}
                      checked={selectTable.values.selectedList.includes(
                        item.id.toString()
                      )}
                    />
                  </div>
                      <div
                        className={`${style.customTableColumn1}`}
                      >
                        <div className={`${style.titleContainer}`}>
                      <div className={`${style.titleShort}`}>{item.title}</div>
                      <div className={`${style.titleFull}`}>{item.title}</div>
                        </div>
                      </div>
                      <div className={`${style.customTableColumn2} cursor-pointer`} 
                      onClick={() =>
                        navigate(`/teacher/class/${item.classroom.id}`, {
                          state: { classId: item.classroom.id },
                        })
                      }>
                        <div className={`${style.classContainer}`}>
                      <div className={`${style.classShort}`}>{item.classroom.name}</div>
                      <div className={`${style.classFull}`}>{item.classroom.name}</div>
                        </div>
                      </div>
                      <div
                        className={`${style.customTableColumn3} text-center`}
                      >
                        <div className="d-flex justify-content-center gap-2">
                          <span>{item.order_number}</span>
                        </div>
                      </div>
                      <div
                        className={`${style.customTableColumn4} text-center`}
                      >
                        <div
                          className="mx-auto py-1"
                          style={{
                            background:
                              item.status === 1 ? "#0fa958" : "#D9D9D9",
                          }}
                        >
                          {item.status === 2
                            ? "Taslak"
                            : item.status === 1
                            ? "Aktif"
                            : "Pasif"}
                        </div>
                      </div>

                      {/* Eylemler */}
                      <div className={`${style.customTableColumn5} d-flex justify-content-center mx-auto`}>
                          <ConfirmButton
                            icon="Delete"
                            handleClick={deleteTrail}
                            parameter={item.id}
                          />

                        <Tooltip title="Edit">
                          <IconButton
                            onClick={() => {
                              dispatch(setSelectedPathId(item.id));
                              setIsOpenNewPathModal(true);
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  ))
                )}
              </div>
              </div>
              <PaginationButtons
                data={items}
                label="patika"
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </div>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default TeacherTrail;
