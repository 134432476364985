import React, { useEffect, useState } from "react";
import style from "./ReportDetail.module.scss";
import printStyle from "../../TeacherReport.module.scss"
import {
  useGetActiveClassesQuery,
  useTeacherReportQuery,
} from "../../../../teacher/teacherApi";
import FormGroup from "../../../../../../components/bootstrap/forms/FormGroup";
import Select from "../../../../../../components/bootstrap/forms/Select";
import ClassStudents from "../classStudents/ClassStudents";
import CompletedStagesForTeacher from "../competedStagesDetail/CompletedStagesForTeacher";
import HomeworkReportForTeacher from "../homeworkReport/HomeworkReportForTeacher";
import LiveLectureAttendanceForTeacher from "../liveLecture/LiveLectureAttendanceForTeacher";
import LessonCompletedRateForTeacher from "../lessonCompletedRate/LessonCompletedRateForTeacher";
import { useTeacherReportForAdminQuery } from "../../../../../../store/features/apiSlice";
import _ from "underscore";
import USER_ROLES from "../../../../../../common/data/enumUserRole";

const ReportDetail = () => {
  const role = localStorage.getItem("roles");
  const isTeacher = Number(role) === USER_ROLES.TEACHER.id;

  const [selectedClassId, setSelectedClassId] = useState();
  const [selectedClassName, setSelectedClassName] = useState();
  const [teacherId, setTeacherId] = useState(null);
  const parametersForAdmin = !isTeacher && teacherId && selectedClassId
  const parametersForTeacher = isTeacher && selectedClassId
  
  const { data, isLoading } = useTeacherReportQuery(selectedClassId, { skip:!parametersForTeacher});
  const { data: activeClassesForTeacher, isSuccess } = useGetActiveClassesQuery();
  const { data: teacherReportForAdmin} = useTeacherReportForAdminQuery({teacherId, classId:selectedClassId}, { skip: !parametersForAdmin});

  const teacherActiveClasses = JSON.parse(localStorage.getItem('teacherClasses'));

  let modifiedTeacherActiveClasses = (teacherActiveClasses && teacherActiveClasses.length > 0) && teacherActiveClasses.map(item => {
    return { ...item, name: item.classroom_name, key:item.id };
  });  

  modifiedTeacherActiveClasses = _.sortBy(modifiedTeacherActiveClasses, "id");

  const activeClasses = teacherActiveClasses !== null ? modifiedTeacherActiveClasses : activeClassesForTeacher

  useEffect(() => {
    if (teacherActiveClasses && teacherActiveClasses.length > 0) {
      const timeout = setTimeout(() => {
        setTeacherId(teacherActiveClasses[0]?.user);
      }, 1);
      return () => clearTimeout(timeout);
    }
  }, [teacherActiveClasses]);

  useEffect(() => {
    if (isSuccess) {
      const timeout = setTimeout(() => {
        if(isTeacher){setSelectedClassId(activeClasses[0]?.id);}
        else{setSelectedClassId(activeClasses[0]?.class_field);}
        setSelectedClassName(activeClasses[0]?.name);
      }, 4);
      return () => clearTimeout(timeout);
    }
    //eslint-disable-next-line
  }, [isSuccess]);

  const classInfo = teacherReportForAdmin ? teacherReportForAdmin[0] : data && data[0]
  return (
    <>
    {/* Header Section  */}
      <div className={`${printStyle.reportHeader} mt-5`}>
        <div className={`${style.headerContainer} mx-auto`}>
          <div className={`${style.headerLeft}`}>
            <div className={`${style.titleContainer}`}>
              <div className={`${style.titleShort}`}>
                <p>{selectedClassName}</p>
              </div>
              <div className={`${style.titleFull}`}>
                <p>{selectedClassName}</p>
              </div>
            </div>
          </div>
          <div className={`${style.headerRight}`}>
            <div className={`${style.classDropdown}`}>
              <FormGroup id="state" label="Sınıf" isFloating>
                <Select
                  value={String(selectedClassId)}
                  id="state"
                  ariaLabel="Sınıf"
                  placeholder="Sınıf Seçiniz.."
                  onChange={(e) => {
                    setSelectedClassId(e.target.value);
                    setSelectedClassName(
                      e.target.options[e.target.selectedIndex].text
                    );
                  }}
                >{activeClasses?.map((c) => (
                  <option key={c.id} value={isTeacher ? c.id : c.class_field}>
                    {c.name}
                  </option>
                ))}</Select>
              </FormGroup>
            </div>
          </div>
        </div>
      </div>

      <div className="row my-3">
      <div className={`${printStyle.ClassStudents} col-12`}><ClassStudents data={classInfo} classId={selectedClassId}/></div>
      </div>
      <div className="row my-3">
      <div className={`${printStyle.CompletedStages} col-12`}><CompletedStagesForTeacher data={classInfo} isLoading={isLoading}/></div>
      </div>
      <div className="row my-3">
      <div className={`${printStyle.HomeworkReport} col-12`}><HomeworkReportForTeacher data={classInfo} isLoading={isLoading} classId={selectedClassId}/></div>
      </div>
      <div className="row my-3">
      <div className={`${printStyle.LiveLecture} col-12`}><LiveLectureAttendanceForTeacher classId={selectedClassId} isLoading={isLoading}/></div>
      </div>
      <div className="row my-3">
      <div className={`${printStyle.LessonCompleted} col-12`}><LessonCompletedRateForTeacher classId={selectedClassId}/></div>
      </div>
    </>
  );
};

export default ReportDetail;
