import React from 'react'
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper'
import Page from '../../../../layout/Page/Page'
import { adminPages } from '../../../../menu'
import SubHeader, { SubHeaderLeft } from '../../../../layout/SubHeader/SubHeader'
import Button from '../../../../components/bootstrap/Button'
import { useClearCacheMutation } from '../../../../store/features/apiSlice'

const SystemSettings = () => {
  const [clearCache, {error}] = useClearCacheMutation();
    const handleCache = async () => {
        try {
          await clearCache()
        }catch{
          console.log('Önbellek temizleme işlemi sırasında bir hata oluştu: ', error);
        }
      };
  return (
    <PageWrapper title={adminPages.settings.text}>
        <SubHeader>
        <SubHeaderLeft>
          <div className="text-muted">
            <strong>
              <span className="text-info fw-bold">{"Sistem Ayarları"}</span>
            </strong>
          </div>
        </SubHeaderLeft>
      </SubHeader>
        <Page container='fluid'>
        <div className="row">
          <div className="col-3">
            <Button
              className="btn btn-success"
              onClick={() => handleCache()}
            >
              Ön Belleği Temizle
            </Button>
          </div>
 
        </div>
        
        </Page>
    </PageWrapper>
  )
}

export default SystemSettings