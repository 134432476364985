import React, { useState } from "react";
import style from "./ClassStudents.module.scss";
import { Assessment } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import PaginationButtons from "../../../../../../components/PaginationButtons";
import { useGetClassStudentQuery } from "../../../../teacher/teacherApi";

const Students = ({classId}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const { data: studentsData } = useGetClassStudentQuery({id:classId, page:`page=${currentPage}`, page_size:`page_size=${perPage}`},{skip:!classId});
  const students = studentsData?.results ?? []
  const totalUsers = studentsData?.count;

  const navigate = useNavigate();
  const showReport = (id) => {
    navigate(`/users/information/${id}`);
  };

  function checkFullName(student) {
    if (student) {
      if (student.name) {
        return `${student.name}`;
      } else {
        return `${student.email.split("@")[0]}`;
      }
    }
  }
  return (
    <>
      <div className={`${style.studentsTitle} mb-4`}>Öğrenciler</div>
      <div className={`${style.allStudents}`}>
        {students && students.length > 0 ? (
          students.map((item) => {
            return (
              <div className={`${style.singleStudent} my-1`} key={item.id}>
                <div className={`${style.sectionLeft}`}>
                  <div className={`${style.line}`}></div>
                  <div className={`${style.studentName}`}>{checkFullName(item)}</div>
                </div>
                <div className={`${style.sectionRight}`}>
                  <Assessment
                    className="cursor-pointer"
                    onClick={() => showReport(item.user_public_id)}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <div className={`${style.emptyList}`}>
            <span>Öğrenci bulunmamaktadır</span>
          </div>
        )}
      </div>
      <div className={`${style.paginationButtons}`}>
      <PaginationButtons
        data={totalUsers ?? 0}
        label="kullanıcı"
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        perPage={perPage}
        setPerPage={setPerPage}
        total={true}
      />
      </div>
    </>
  );
};

export default Students;
