import React from 'react'
import style from "./LastDays.module.scss";
import check from "../../../../../../src/assets/dashboardImages/check.svg";

const SingleDay = ({description, date}) => {
  return (
    <div className={`${style.singleDayContainer} ms-5`}>
      <div className={`${style.singleDay} d-flex justify-content-between align-items-center my-3`}>
        <div className={`${style.leftSection} d-flex justify-content-start align-items-center gap-3`}>
        <div className={`${style.logoDiv}`}><img src={check} alt="check" /></div>
        <div className={`${style.description}`}>{description}</div>
        </div>
        <div className={`${style.date}`}>{date}</div>
      </div>
    </div>
  )
}

export default SingleDay