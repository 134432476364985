import { apiSlice } from "../../../../store/features/apiSlice";

export const talentCupApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCompetitions: builder.query({
      query: () => ({
        url: `competitions/`,
        method: "get",
      }),
      providesTags: ["competition"],
    }),
    // Competition Image
    get_a_Competition_Thumbnail: builder.query({
      query: (competitionId) => ({
        url: `competitions/${competitionId}/thumbnail/`,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token') && JSON.parse(localStorage.getItem('token'))}`,
        },
        responseHandler: async (response) => {
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        },
        method: "get",
      }),
    }),
    get_a_Competition: builder.query({
      query: (id) => ({
        url: `competitions/${id}/`,
        method: "get",
      }),
      providesTags: ["competition"],
    }),
    createCompetition: builder.mutation({
      query: (data) => ({
        url: `competitions/`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["competition"],
    }),
    updateCompetition: builder.mutation({
      query: ({ id, formData}) => ({
        url: `competitions/${id}/`,
        method: "put",
        body: formData,
      }),
      invalidatesTags: ["competition"],
    }),
    deleteCompetition: builder.mutation({
      query: (id) => ({
        url: `competitions/${id}/`,
        method: "delete",
      }),
      invalidatesTags: ["classes"],
    }),
    getListCompetitionPractices: builder.query({
      query: (id) => ({
        url: `competitions/${id}/exercises/`,
        method: "get",
      }),
      providesTags: ["competition"],
    }),
    updateCompetitionPractices: builder.mutation({
      query: ({id, practices, lessons, games}) => ({
        url: `competitions/${id}/exercises/`,
        method: "put",
        body: practices,lessons, games
      }),
      invalidatesTags: ["competition"],
    }),

    getListCompetitionJoinedStudents: builder.query({
      query: (id) => ({
        url: `competitions/${id}/students/`,
        method: "get",
      }),
      providesTags: ["competition"],
    }),
    getStudentProjectFile: builder.query({
      query: (data) => ({
        url: `competitions/${data.id}/student/${data.userId}/project/file/`,
        method: "get",
        responseHandler: async (response) => {
          // Gelen yanıtı Blob olarak döndür
          const blob = await response.blob();
          return blob;
        },
      }),
      providesTags: ["competition"],
    }),
    updateStudentProjectPoints: builder.mutation({
      query: ({competitionId, studentProjectId, points}) => ({
        url: `competitions/${competitionId}/student/project/${studentProjectId}/points/`,
        method: "put",
        body: points,
      }),
      invalidatesTags: ["competition"],
    }),
    getStudentPracticesForAdmin: builder.query({
      query: (data) => ({
        url: `competitions/${data.id}/student/${data.userId}/pratices/`,
        method: "get",
      }),
      providesTags: ["competition"],
    }),

    //For students 
    joinCompetition: builder.mutation({
      query: (id) => ({
        url: `competitions/${id}/student_join/`,
        method: "post",
      }),
      invalidatesTags: ["competition"],
    }),
    updateStudentProjectFile: builder.mutation({
      query: ({formData, id}) => ({
        url: `competitions/${id}/student/project/`,
        method: "put",
        // body: zip_file,
        prepareHeaders: (headers) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
        body: formData,
      }),
      invalidatesTags: ["competition"],
    }),
    getStudentPracticesForStudent: builder.query({
      query: (id) => ({
        url: `competitions/${id}/student/exercises/`,
        method: "get",
      }),
      providesTags: ["competition"],
    }),
    getStudentProjectForStudent: builder.query({
      query: (id) => ({
        url: `competitions/${id}/student/project/`,
        method: "get",
      }),
      providesTags: ["competition"],
    }),
    //--endpoints
  }),
});

export const {
  useGetAllCompetitionsQuery,
  useGet_a_Competition_ThumbnailQuery,
  useGet_a_CompetitionQuery,
  useCreateCompetitionMutation,
  useUpdateCompetitionMutation,
  useDeleteCompetitionMutation,

  useGetListCompetitionPracticesQuery,
  useUpdateCompetitionPracticesMutation,
  useGetListCompetitionJoinedStudentsQuery,
  useGetStudentProjectFileQuery,
  useLazyGetStudentProjectFileQuery,
  useUpdateStudentProjectPointsMutation,

  useJoinCompetitionMutation,
  useUpdateStudentProjectFileMutation,
  useGetStudentPracticesForStudentQuery,
  useGetStudentProjectForStudentQuery,
} = talentCupApi;
