import React from "react";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../../../components/bootstrap/Modal";

const UserInfoModal = ({open,setOpen}) => {
    
    return (
      <div>
        <Modal
          isOpen={open}
          setIsOpen={setOpen}
          titleId="example-title"
        >
          <ModalHeader>
            <ModalTitle id="example-title">
                {/* {title} */}
                <div className="d-flex justify-content-center align-items-center my-1">
        <img className="thumb" src="/images/friday-happy.gif" alt="tebrikler" />
        <h3>Harika! <br/> Sınıf kodunu başarıyla girdiniz.</h3>
      </div>
                </ModalTitle>
          </ModalHeader>
          <ModalBody>
          <div className="mx-auto text-justify">
          <p style={{ fontSize: "16px" }}>
          Sınıfa atanmanız öğretmen onayı sonrasında gerçekleştirilecektir. Onay işlemi tamamlandığında profiliniz otomatik olarak sınıfa atanmış öğrenci olarak güncellenecektir.
          </p>
          <p style={{ fontSize: "16px" }}>
          Sınıfınıza atanmanızın ardından sınıf bilgileri, öğretmeniniz ve ödevler gibi önemli bilgiler, profilinizin altında görüntülenecektir.
          </p>
          <p style={{ fontSize: "16px" }}>
          Eğer herhangi bir sorunuz varsa veya yardıma ihtiyacınız olursa, lütfen bize ulaşmaktan çekinmeyin. Başarılar dileriz!"
          </p>
        </div>
          </ModalBody>
        </Modal>
      </div>
    );
  };
  
  export default UserInfoModal;