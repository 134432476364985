import React from "react";
import ParentHeader from "./header/ParentHeader";
import LiveLectureAttendance from "./liveLecture/LiveLectureAttendance";
import HomeworkReport from "./homework/HomeworkReport";
import CompletedStages from "./completedStages/CompletedStages";
import LessonCompletedRate from "./lessonCompletedRate/LessonCompletedRate";
import Certificates from "./certificates/Certificates";
import Calendar from "./reportCalendar/Calendar";

const ParentReportWithClass = ({data}) => {
  console.log(data);

  return (
    <>
    <div className="mt-5">
<ParentHeader data = {data}/>
    </div>
    <div className="row my-3">
      <div className="col-12 col-lg-6"><LiveLectureAttendance data = {data}/></div>
      <div className="col-12 col-lg-6"><HomeworkReport data = {data}/></div>
    </div>
    <div className="row my-3">
      <div className="col-12 col-lg-6"><CompletedStages data = {data}/></div>
      <div className="col-12 col-lg-6"><LessonCompletedRate data = {data}/></div>
    </div>
    <div className="row my-3">
      <div className="col-12"><Certificates data = {data}/></div>
      <div className="col-12"><Calendar data = {data}/></div>
    </div>
    </>
  );
};

export default ParentReportWithClass;
