import { apiSlice } from "./apiSlice";

export const couponApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    discounts: builder.query({
      query: () => ({
        url: `/discounts/`,
        method: "get",
      }),
      providesTags: ["discount"],
    }),
    singleDiscount: builder.query({
      query: (id) => ({
        url: `/discounts/${id}`,
        method: "get",
      }),
      providesTags: ["discount"],
    }),
    createDiscount: builder.mutation({
      query: ({
        data
      }) => ({
        url: `discounts/`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["discount"],
    }),
    updateDiscount: builder.mutation({
      query: ({
        id,
        data
      }) => ({
        url: `discounts/${id}/`,
        method: "put",
        body: data,
      }),
      invalidatesTags: ["discount"],
    }),
    deleteDiscount: builder.mutation({
      query: (id) => ({
        url: `discounts/${id}/`,
        method: "delete",
      }),
      invalidatesTags: ["discount"],
    }),
    sendDiscountCoupon: builder.mutation({
      query: ({
        data
      }) => ({
        url: `discount/students/`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["discount"],
    }),

    //--endpoints
  }),
});

export const {
 useDiscountsQuery,
 useSingleDiscountQuery,
 useCreateDiscountMutation,
 useUpdateDiscountMutation,
 useDeleteDiscountMutation,
 useSendDiscountCouponMutation,
} = couponApi;
