import React from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../../../components/bootstrap/Modal";
import Logo from "../../../../../../components/Logo";
import Icon from "../../../../../../components/icon/Icon";
import Button from "../../../../../../components/bootstrap/Button";
import FormGroup from "../../../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../../../components/bootstrap/forms/Input";
import { useFormik } from "formik";
import Select from "../../../../../../components/bootstrap/forms/Select";
import showNotification from "../../../../../../components/extras/showNotification";
import { useCreateActivationKeysOfSchoolMutation } from "../../../../../../store/features/apiSlice";
import { getCustomActivationKeyErrorMessage, handleErrorMessage } from "../../../../../../utils/customErrors";

const CreateActivationCodeModalForSchool = ({
  isOpenModal,
  setIsOpenModal,
}) => {
  const [createActivationCode] = useCreateActivationKeysOfSchoolMutation();
  const schoolId = localStorage.getItem("schoolId")

  const validate = (values) => {
    let inputDate = new Date(values.code_expiry_date);
    let currentDate = new Date();
    const errors = {};
    if (!values.code_expiry_date) {
      errors.code_expiry_date = "Bitiş Tarihi giriniz!";
    }
    if (currentDate > inputDate) {
      errors.code_expiry_date = "Bugünden sonraki bir tarih giriniz";
    }
    if (!values.number_of_the_codes) {
      errors.number_of_the_codes = "Kod miktarını giriniz!";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      subscription_type: "1",
      subscription_period: "1",
      code_expiry_date: new Date().toISOString(),
      number_of_the_codes: 1,
    },
    validate,
    onSubmit: () => {
      handleActivationCode();
    },
  });
  const handleActivationCode = async () => {
    const codeValues = { ...formik.values };
    const response = await createActivationCode({
      schoolId: schoolId,
      ...codeValues,
    });
    setIsOpenModal(false);
    if (response.error) {
      const customError = handleErrorMessage(response.error.data)
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>{getCustomActivationKeyErrorMessage(customError, "Kod Oluşturma Başarısız")}</span>
        </span>,
        "Bir hata oluştu",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Kod Oluşturma Başarılı</span>
        </span>,
        "Kullanıcı bilgileri başarıyla güncellendi.",
        "success"
      );
    }
    formik.resetForm()
    setIsOpenModal(false);
  };
  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenModal}
      setIsOpen={setIsOpenModal}
      titleId="example-title"
      size={null}
    >
      <ModalHeader setIsOpen={setIsOpenModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} />{" "}
          <span className="ps-2">Aktivasyon Kodu Oluşturma</span>
          <span className="ps-2">
            <Icon icon="Person" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="row gap-3 d-flex justify-content-center">
          <div className="col-lg-8">
            <FormGroup id="subscription_type" isFloating label="Üyelik Tipi">
              <Select
                ariaLabel="subscription_type"
                onChange={formik.handleChange}
                value={formik.values.subscription_type}
                isValid={formik.isValid}
                onBlur={formik.handleBlur}
                isTouched={formik.touched.subscription_type}
                invalidFeedback={formik.errors.subscription_type}
                list={[
                  { value: "1", text: "Ücretsiz" },
                  { value: "2", text: "Standart" },
                  { value: "3", text: "Premium" },
                ]}
                className="text-dark"
              ></Select>
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup
              id="subscription_period"
              isFloating
              label="Üyelik Süresi"
            >
              <Select
                ariaLabel="subscription_period"
                onChange={formik.handleChange}
                value={formik.values.subscription_period}
                isValid={formik.isValid}
                onBlur={formik.handleBlur}
                isTouched={formik.touched.subscription_period}
                invalidFeedback={formik.errors.subscription_period}
                list={[
                  { value: "1", text: "Aylık" },
                  { value: "2", text: "Yıllık" },
                ]}
                className="text-dark"
              ></Select>
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup
              id="code_expiry_date"
              label="Son Kullanma Tarihi"
              isFloating
            >
              <Input
                type="date"
                onChange={formik.handleChange}
                value={formik.values.code_expiry_date.substring(0, 10)}
                isValid={formik.isValid}
                onBlur={formik.handleBlur}
                isTouched={formik.touched.code_expiry_date}
                invalidFeedback={formik.errors.code_expiry_date}
              />
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup
              // className="col-4"
              id="number_of_the_codes"
              isFloating
              label="Kod Miktarı"
            >
              <Input
                type="number"
                onChange={formik.handleChange}
                value={formik.values.number_of_the_codes}
                onBlur={formik.handleBlur}
                // isValid={formik.isValid}
                isTouched={formik.touched.number_of_the_codes}
                invalidFeedback={formik.errors.number_of_the_codes}
              />
            </FormGroup>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={async () => {
            formik.handleSubmit();
          }}
        >
          Kaydet
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateActivationCodeModalForSchool;
