// CouponCode.js
import React, { useState } from "react";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Button from "../../../components/bootstrap/Button";

function CouponCode({ onApplyCoupon }) {
  const [showCouponInput, setShowCouponInput] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponApplied, setCouponApplied] = useState(false);

  const toggleCouponInput = () => setShowCouponInput((prev) => !prev);

  const applyCoupon = () => {
    if (couponCode) {
      onApplyCoupon(couponCode);
      setCouponApplied(true);
    } else {
      alert("Lütfen geçerli bir kupon kodu girin.");
    }
  };

  return (
    <div>
      {/* Kupon Kodu Ekle / İptal Et bölümü */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <span
          className="text-primary cursor-pointer"
          onClick={toggleCouponInput}
          style={{ fontWeight: "600", cursor: "pointer" }}
        >
          Kupon Kodu {showCouponInput ? "İptal" : "Ekle"}
        </span>
      </div>

      {/* Kupon Kodu Giriş Alanı */}
      {showCouponInput && (
        <FormGroup className="d-flex gap-2 align-items-center mb-3">
          <Input
            type="text"
            placeholder="Kupon Kodunu Girin"
            className="bg-light text-dark"
            style={{ width: "200px" }}
            onChange={(e) => setCouponCode(e.target.value)}
            value={couponCode}
          />
          <Button color="primary" onClick={applyCoupon} disabled={couponApplied}>
            {couponApplied ? "Uygulandı" : "Uygula"}
          </Button>
        </FormGroup>
      )}
    </div>
  );
}

export default CouponCode;
