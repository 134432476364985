import React, { useState } from "react";
import style from "../ParentReportWithClass.module.scss"
import Heatmap from "./heatmap/Heatmap";
import Select from "../../../../../../components/bootstrap/forms/Select";
import { Card } from "react-bootstrap";

const Calendar = ({data}) => {
  const activaDays = data.active_days
  const [year, setYear] = useState("2024-2");
  const years = [
    { text: "2023-1", value: "2023-1" },
    { text: "2023-2", value: "2023-2" },
    { text: "2024-1", value: "2024-1" },
    { text: "2024-2", value: "2024-2" },
  ];
  const handleDateChange = (e) => {
    setYear(e.target.value);
  };
  return (
    <Card className={`${style.calendarContainer} p-3 px-5`}>
      <div className="d-flex justify-content-between align-items-center">
    <div className={`${style.title} my-3 px-3`}>Öğrenci İlerleme Takvimi</div>
    <Select
      value={year}
      onChange={handleDateChange}
      style={{ width: "100px" }}
      list={years}
      ariaLabel={year}
      className={`${style.selectDiv}`}
    ></Select>
      </div>
    <div className="">
    <Heatmap year={year} data={activaDays}/>
    </div>
  </Card>
  );
};

export default Calendar;
