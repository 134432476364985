import React from 'react'
import style from "./ClassStudents.module.scss"

const Information = ({description, value}) => {
  return (
      <>
      <div>
        <div>{description}</div>
        <div className={`${style.line}`}></div>
      </div>
        <div><span>{value}</span></div>
      </>
  )
}

export default Information