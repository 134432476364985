import React from 'react'
import style from "./LiveLectureAttendanceForTeacher.module.scss"
import { formatDate2TurkishShortDate } from '../../../../../../utils/formatDate';

const AttendanceInfo = ({attendance}) => {
    const title = attendance.title;
    const date = attendance.date;
  return (
    <div className={`${style.singleAttendance} d-flex justify-content-center align-items-center my-3 gap-2`}>
        <div className={`${style.singleAttendanceLeft} d-flex align-items-center p-2`}>
        <div className={`${style.titleContainer}`}>
                      <div className={`${style.titleShort}`}>{title}</div>
                      <div className={`${style.titleFull}`}>{title}</div>
                        </div>
        </div>
        <div className={`${style.singleAttendanceRight} d-flex align-items-center justify-content-between px-3`}>
            <div>{formatDate2TurkishShortDate(date)}</div>
        </div>
    </div>
  )
}

export default AttendanceInfo