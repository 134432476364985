import React from 'react'
import style from "./CreditCard.module.scss"

const CreditCard = ({cardOwner, cardNumber, expiryDate}) => {
    const defaultCardNumber = '0000000000000000';
    const defaultExpiryDate = 'AA/YY';
    const defaultCardOwner = '****** ******';
    function formatCardNumber(number) {
        return number.replace(/\s?/g, '').replace(/(\d{4})/g, '$1 ').trim();
      }

  return (
    <div>
        <div className={`${style.container}`}>
        <div className={`${style.payment}`}>
    
    <div className={`${style.payment__shadow_dots}`}></div>
    
    <div className={`${style.card}`}>
      
      <div className={`${style.card__visa}`}>
          </div>
      
      <div className={`${style.card__number}`}>{`${cardNumber ? formatCardNumber(cardNumber) : formatCardNumber(defaultCardNumber)}`}</div>
      <div className={`${style.card__name}`}>
        <h3>Kart Sahibi</h3>
        <p id="card-name" style={{fontSize:`${cardOwner.length > 18 ? ".8rem" : "1rem"}`}}>{`${cardOwner ? cardOwner.slice(0,60) : defaultCardOwner}`}</p>
        </div>
      
      <div className={`${style.card__expiry}`}>
        <h3>Geçerlilik Tarihi</h3>
        <p>
          <span id="month">{`${expiryDate ? expiryDate : defaultExpiryDate}`}</span>
        </p>
        </div>
        </div>
   </div>
</div>
    </div>
  )
}

export default CreditCard