import React from "react";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import SubHeader, {
  SubHeaderLeft,
} from "../../../../layout/SubHeader/SubHeader";
import Page from "../../../../layout/Page/Page";
import { adminPages } from "../../../../menu";
import SchoolListTable from "./2-SchoolListTable";

const SchoolManagement = () => {
  return (
    <PageWrapper title={adminPages.schoolManagement.text}>
      <SubHeader>
        <SubHeaderLeft>
          <div className="text-muted">
            <strong>
              <span className="text-info fw-bold">{"Kurumlar"}</span>
            </strong>
          </div>
        </SubHeaderLeft>
      </SubHeader>
      <Page container="fluid">
        <SchoolListTable/>
      </Page>
    </PageWrapper>
  );
};

export default SchoolManagement;