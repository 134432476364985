import React, { useEffect, useRef } from 'react';
import style from "./lessonsContent.module.scss";

const H5PIframe = ({ src }) => {
  const containerRef = useRef(null);
  const youtubeSrc = src.includes("youtube")

  useEffect(() => {
    const container = containerRef.current;

    const loadScript = () => {
      const script = document.createElement('script');
      script.src = 'https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js';
      script.charset = 'UTF-8';
      container.appendChild(script);
    };

    loadScript();

    return () => {
      container.innerHTML = ''; // Önceki içeriği temizle
    };
  }, []);

  return (
    <div ref={containerRef} className={`${style.H5Psize} ${youtubeSrc && style.youtubeSrcStyle}`}>
      <div dangerouslySetInnerHTML={{ __html: src }} />
    </div>
  );
};

export default H5PIframe;
