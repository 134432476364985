import React from 'react';
import { useGetActiveClassesQuery } from '../../teacherApi'
import Activities from './Activities';
const ActivitiesWrapper = () => {
    const { data: activeClasses,isSuccess } = useGetActiveClassesQuery();
    return (
        <>
            {isSuccess && activeClasses 
            && activeClasses.length > 0 ? 
            <Activities activeClasses={activeClasses} /> 
            : null}
        </>
    );
};

export default ActivitiesWrapper;