import React from "react";
import Card, {
} from "../../../../../components/bootstrap/Card";
import SchoolInfo from "./2-ClassInfo";
import { useGetSchoolBannerForAdminQuery, useGetStudentSubscribedClassesQuery } from "../../../../../store/features/apiSlice";
import { useGet_a_SchoolQuery } from "../../../teacher/teacherApi";
import Banner from "../banner and announcements/banner/Banner";

const StudentWithOnlySchool = () => {
  const school = localStorage.getItem("school");
  const {data:banner, isLoading:isLoadingBanner} = useGetSchoolBannerForAdminQuery(school)
  const { data} = useGetStudentSubscribedClassesQuery();
  const { data:schoolData} = useGet_a_SchoolQuery(school, {skip:!school});
  const studentClass = 3
  const studentClassName = data && data.class_name
  const teacherFulName = data && data.teacher_name
  const schoolName = schoolData && schoolData.title
  return (
    <div>
      <SchoolInfo studentClass={studentClass} studentClassName={studentClassName} teacherFulName={teacherFulName} schoolName={schoolName} />
      {!isLoadingBanner && <Card>
      {banner && <Banner banner={banner}/>}
      </Card>}
    </div>
  );
};

export default StudentWithOnlySchool;
