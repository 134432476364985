import React from "react";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import SubHeader, {
  SubHeaderLeft,
} from "../../../../layout/SubHeader/SubHeader";
import Page from "../../../../layout/Page/Page";
import { adminPages } from "../../../../menu";
import UserListTable from "./2-UserListTable";

const UserManagement = () => {
  return (
    <PageWrapper title={adminPages.userManagement.text}>
      <SubHeader>
        <SubHeaderLeft>
          <div className="text-muted">
            <strong>
              <span className="text-info fw-bold">{"Kullanıcı Yönetimi"}</span>
            </strong>
          </div>
        </SubHeaderLeft>
      </SubHeader>
      <Page container="fluid">
        <UserListTable/>
      </Page>
    </PageWrapper>
  );
};

export default UserManagement;
