import React, { useState } from "react";
import style from "./ActiveHomeworks.module.scss";
import Card from "../../../../../components/bootstrap/Card";
import { nanoid } from "nanoid";
import PaginationButtons, { dataPagination } from "../../../../../components/PaginationButtons";
import { useHomeworksQuery } from "../../../teacher/teacherApi";

const ActiveHomeworks = () => {
  const activeHomeWorkStatus = `status=1`
  const { data:activeHomeworks } = useHomeworksQuery(activeHomeWorkStatus);
  const items = activeHomeworks ? activeHomeworks : [];
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  return (
    <Card className={`${style.cardContainer}`}>
      <div className={`${style.title} pt-2 my-2`}>
        <span className={`ms-5`}>
          Aktif Ödevler
        </span>
      </div>
      <div className={`${style.customTable} px-4`}>
        <div
          className={`${style.customHeadContainer} d-flex align-items-center justify-content-between my-1 px-2`}
        >
          <div className={`${style.customTableHead1} ${style.customTableHead}`}>
            Ödev İsmi
          </div>
          <div className={`${style.customTableHead2} ${style.customTableHead}`}>
            Sınıf
          </div>
        </div>

        <div className={`${style.customRowContainer}`}>
          {items && items.length === 0 ? (
            <div className={`${style.emptyTable}`}>
              <span>Ödev bulunamadı</span>
            </div>
          ) : (
            items &&
            dataPagination(items, currentPage, perPage).map((item, i) => (
              <div
                key={nanoid()}
                className={`${style.customTableRow} d-flex align-items-center justify-content-between mb-2 px-2`}
                // onClick={() =>
                //   navigate(`/teacher/class/${item.id}`, {
                //     state: { classId: item.id },
                //   })
                // }
              >
                  <div
                  className={`${style.customTableColumn1} ${style.tableColumn}`}
                >
                    <div className={`${style.homeworkTitleContainer}`}>
                      <div className={`${style.homeworkTitleShort}`}>
                        {item.title}
                      </div>
                      <div className={`${style.homeworkTitleFull}`}>
                        {item.title}
                      </div>
                    </div>

                </div>
                <div
                  className={`${style.customTableColumn2} ${style.tableColumn}`}
                >
                    <div className={`${style.classNameContainer}`}>
                      <div className={`${style.classNameShort}`}>
                        {item.classroom_name}
                      </div>
                      <div className={`${style.classNameFull}`}>
                        {item.classroom_name}
                      </div>
                    </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <div className={`${style.paginationButtons}`}>
      <PaginationButtons
          data={items}
          label="ödev"
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </div>
    </Card>
  );
};

export default ActiveHomeworks;
