import React from 'react'
import ParentHeader from './header/ParentHeader'
import CompletedStages from '../parentReportWithClass/completedStages/CompletedStages'
import LessonCompletedRate from '../parentReportWithClass/lessonCompletedRate/LessonCompletedRate'
import Certificates from '../parentReportWithClass/certificates/Certificates'
import Calendar from '../parentReportWithClass/reportCalendar/Calendar'

const ParentReportWithoutClass = ({data}) => {
  return (
    <>
    <div className="mt-5">
<ParentHeader data = {data}/>
    </div>
    <div className="row my-3">
      <div className="col-12 col-lg-6"><CompletedStages data = {data}/></div>
      <div className="col-12 col-lg-6"><LessonCompletedRate data = {data}/></div>
    </div>
    <div className="row my-3">
      <div className="col-12"><Certificates data = {data}/></div>
      <div className="col-12"><Calendar data = {data}/></div>
    </div>
    </>
  )
}

export default ParentReportWithoutClass