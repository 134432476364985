import { apiSlice } from "../../../store/features/apiSlice";

export const teacherApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    teacherLearningPaths: builder.query({
      query: (data) => ({
        url: "learningpaths/teachers/",
        method: "get",
      }),
    }),
    homeworks: builder.query({
      query: (status) => ({
        url: `classes/homeworks/?${status}`,
        method: "get",
      }),
      providesTags: ["homeWork"],
    }),
    singleHomework: builder.query({
      query: (id) => ({
        url: `classes/homeworks/${id}/`,
        method: "get",
      }),
      providesTags: ["homeWork"],
    }),
    updateHomeWork: builder.mutation({
      query: ({
        id,
        content,
        title,
        description,
        start_date,
        end_date,
        points,
        classroom,
      }) => ({
        url: `classes/homeworks/${id}/`,
        method: "put",
        body: {
          content,
          title,
          description,
          start_date,
          end_date,
          points,
          classroom,
        },
      }),
      invalidatesTags: ["homeWork"],
    }),
    createHomeWork: builder.mutation({
      query: ({
        content,
        title,
        description,
        start_date,
        end_date,
        points,
        classroom,
      }) => ({
        url: `classes/homeworks/`,
        method: "post",
        body: {
          content,
          title,
          description,
          start_date,
          end_date,
          points,
          classroom,
        },
      }),
      invalidatesTags: ["homeWork"],
    }),
    deleteHomeWork: builder.mutation({
      query: (id) => ({
        url: `classes/homeworks/${id}/`,
        method: "delete",
      }),
      invalidatesTags: ["homeWork"],
    }),
    getStudent: builder.query({
      query: ({ class_id, student_id }) => ({
        url: `classes/${class_id}/students/${student_id}`,
        method: "get",
      }),
    }),
    getStudents: builder.query({
      query: () => ({
        url: `classes/teachers/students/`,
        method: "get",
      }),
      providesTags: ["students-in-class"],
    }),
    getJoinStudent: builder.query({
      query: () => ({
        url: `classes/student/approvement/`,
        method: "get",
      }),
      providesTags: ["join-students"],
    }),
    getClass: builder.query({
      query: (id) => ({
        url: `classes/${id}`,
        method: "get",
      }),
    }),
    getClasses: builder.query({
      query: (school) => ({
        url: `classes/?${school}`,
        method: "get",
      }),
      providesTags: ["classes"],
    }),
    getActiveClasses: builder.query({
      query: () => ({
        url: `classes/active/`,
        method: "get",
      }),
    }),
    createClass: builder.mutation({
      query: (data) => ({
        url: `classes/`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["classes"],
    }),
    updateClass: builder.mutation({
      query: (data) => ({
        url: `classes/${data.id}/`,
        method: "put",
        body: data,
      }),
      invalidatesTags: ["classes"],
    }),
    deleteClass: builder.mutation({
      query: (id) => ({
        url: `classes/${id}/`,
        method: "delete",
      }),
      invalidatesTags: ["classes"],
    }),
    getClassStudent: builder.query({
      query: ({ id, page, page_size }) => ({
        url: `classes/${id}/students/?${page}&${page_size}`,
        method: "get",
      }),
      providesTags: ["students-in-class"],
    }),
    getSchoolStudentsWithoutClassForSchoolManager: builder.query({
      query: ({ schoolId, page, page_size }) => ({
        url: `schools/${schoolId}/students/unassigned/?${page}&${page_size}`,
        method: "get",
      }),
      providesTags: ["students-in-class"],
    }),
    homeworksOfClass: builder.query({
      query: (id) => ({
        url: `classes/${id}/homeworks/`,
        method: "get",
      }),
      providesTags: ["homeWork"],
    }),
    homeworksTracking: builder.query({
      query: (id) => ({
        url: `classes/${id}/homeworks_tracking/`,
        method: "get",
      }),
      providesTags: ["homeWork"],
    }),
    homeworksCompletionRate: builder.query({
      query: (id) => ({
        url: `classes/${id}/homeworks_tracking/complation_rate/`,
        method: "get",
      }),
      providesTags: ["homeWork", "students-in-class"],
    }),
    approveStudent: builder.mutation({
      query: ({ student_id, isApproved }) => ({
        url: `classes/student/approvement/${student_id}/`,
        method: "put",
        body: { approvement: isApproved },
      }),
      invalidatesTags: ["join-students"],
    }),
    addStudentToClass: builder.mutation({
      query: ({ class_id, students }) => ({
        url: `classes/${class_id}/students/bulk/`,
        method: "post",
        body: students,
      }),
      invalidatesTags: ["students-in-class"],
    }),
    removeStudentFromClass: builder.mutation({
      query: ({ class_id, student_in_class_id }) => ({
        url: `classes/${class_id}/students/${student_in_class_id}/`,
        method: "delete",
      }),
      invalidatesTags: ["students-in-class"],
    }),
    classLeaderBoard: builder.query({
      query: (id) => ({
        url: `classes/${id}/leaderboard/`,
        method: "get",
      }),
      providesTags: ["students-in-class"],
    }),
    teacherTrails: builder.query({
      query: () => ({
        url: `trails/teachers/`,
        method: "get",
      }),
      providesTags: ["trail"],
    }),
    teacherSingleTrail: builder.query({
      query: (pathId) => ({
        url: `trails/teachers/${pathId}`,
        method: "get",
      }),
      providesTags: ["trail"],
    }),
    createTeacherTrail: builder.mutation({
      query: (data) => ({
        url: `trails/teachers/`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["trail"],
    }),
    updateTeacherTrail: builder.mutation({
      query: ({ data, pathId }) => ({
        url: `trails/teachers/${pathId}/`,
        method: "put",
        body: data,
      }),
      invalidatesTags: ["trail"],
    }),
    deleteTeacherTrail: builder.mutation({
      query: (id) => ({
        url: `trails/teachers/${id}`,
        method: "delete",
      }),
      invalidatesTags: ["trail"],
    }),
    getAllTrailsInStudentsClass: builder.query({
      query: () => ({
        url: `trails/teachers/students/`,
        method: "get",
      }),
      providesTags: ["trail"],
    }),
    getSchools: builder.query({
      query: () => ({
        url: `schools/`,
        method: "get",
      }),
      providesTags: ["school"],
    }),
    get_a_School: builder.query({
      query: (id) => ({
        url: `schools/${id}`,
        method: "get",
      }),
      providesTags: ["school"],
    }),
    createSchool: builder.mutation({
      query: (data) => ({
        url: `schools/`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["school"],
    }),
    updateSchool: builder.mutation({
      query: ({ id, formData }) => ({
        url: `schools/${id}/`,
        method: "put",
        body: formData,
      }),
      invalidatesTags: ["school"],
    }),
    deleteSchool: builder.mutation({
      query: (id) => ({
        url: `schools/${id}`,
        method: "delete",
      }),
      invalidatesTags: ["school"],
    }),
    updatePasswordWithTeacher: builder.mutation({
      query: ({ id, password, password2 }) => ({
        url: `users/password/teacher/${id}/`,
        method: "put",
        body: { id, password, password2 },
      }),
    }),
    getLiveLecturesForStudent: builder.query({
      query: () => ({
        url: `live_lectures/student/`,
        method: "get",
      }),
      providesTags: ["lecture"],
    }),
    getLiveLectures: builder.query({
      query: () => ({
        url: `live_lectures/teachers/`,
        method: "get",
      }),
      providesTags: ["lecture"],
    }),
    getLiveLecturesForClass: builder.query({
      query: (classId) => ({
        url: `live_lectures/classroom/${classId}/`,
        method: "get",
      }),
      providesTags: ["lecture"],
    }),
    get_a_LiveLecture: builder.query({
      query: (data) => ({
        url: `live_lectures/teachers/${data.lectureId}/`,
        method: "get",
      }),
      providesTags: ["lecture"],
    }),
    createLiveLecture: builder.mutation({
      query: (data) => ({
        url: `live_lectures/teachers/`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["lecture"],
    }),
    updateLiveLecture: builder.mutation({
      query: ({ id, live_lecture, classroom }) => ({
        url: `live_lectures/teachers/${id}/`,
        method: "put",
        body: { id, live_lecture, classroom },
      }),
      invalidatesTags: ["lecture"],
    }),
    deleteLiveLecture: builder.mutation({
      query: (id) => ({
        url: `live_lectures/teachers/${id}/`,
        method: "delete",
      }),
      invalidatesTags: ["lecture"],
    }),
    getAttendanceOf_a_LiveLecture: builder.query({
      query: (id) => ({
        url: `live_lectures/teachers/${id}/attendances/`,
        method: "get",
      }),
      providesTags: ["attendance"],
    }),
    getAttendanceOf_all_LiveLecture: builder.query({
      query: (id) => ({
        url: `live_lectures/teachers/attendances/classroom/${id}`,
        method: "get",
      }),
      providesTags: ["attendance", "students-in-class"],
    }),
    attendanceParticipationRate: builder.query({
      query: (id) => ({
        url: `classes/${id}/attendance_participation_rate/`,
        method: "get",
      }),
      providesTags: ["attendance", "students-in-class"],
    }),
    createAttendanceForClassroom: builder.mutation({
      query: (id) => ({
        url: `live_lectures/teachers/attendances/classroom/${id}/`,
        method: "post",
      }),
      invalidatesTags: ["attendance"],
    }),
    updateAttendanceOf_a_LiveLecture: builder.mutation({
      query: ({ liveLectureId, data }) => ({
        url: `live_lectures/teachers/${liveLectureId}/attendances/`,
        method: "put",
        body: data,
      }),
      invalidatesTags: ["attendance"],
    }),
    teacherReport: builder.query({
      query: (classId) => ({
        url: `users/teachers/classes/${classId}/information/`,
        method: "get",
      }),
    }),
    homeworksCompletionRateOfClassroom: builder.query({
      query: (id) => ({
        url: `classes/${id}/homework_completion_rate/`,
        method: "get",
      }),
      providesTags: ["homeWork", "students-in-class"],
    }),
    homeworkStudentsCompletionRateOfClassroom: builder.query({
      query: (id) => ({
        url: `classes/${id}/students_homework_completion_rate/`,
        method: "get",
      }),
      providesTags: ["homeWork", "students-in-class"],
    }),
    liveLectureCompletitionRateOfClassroom: builder.query({
      query: (id) => ({
        url: `classes/${id}/live_lecture_completion_rate/`,
        method: "get",
      }),
      providesTags: ["homeWork", "students-in-class"],
    }),
    liveLectureStudentsAttandanceRate: builder.query({
      query: (id) => ({
        url: `classes/${id}/students_live_lecture_attendance_rate/`,
        method: "get",
      }),
      providesTags: ["homeWork", "students-in-class"],
    }),
    courseCompletitionRateOfClassroom: builder.query({
      query: (id) => ({
        url: `classes/${id}/course_completion_rate/`,
        method: "get",
      }),
      providesTags: ["homeWork", "students-in-class"],
    }),
    adminAndSchoolManagerReport: builder.query({
      query: (teacherId, classId) => ({
        url: `users/teachers/${teacherId}classes/${classId}/information/`,
        method: "get",
      }),
    }),
    getSchoolSubscription: builder.query({
      query: (id) => ({
        url: `schools/${id}/subscriptions/`,
        method: "get",
      }),
      providesTags: ["school"],
    }),
    updateSchoolSubscription: builder.mutation({
      query: ({
        id,
        subscription_type,
        subscription_period,
        subscription_start_date,
        subscription_expiration_date,
      }) => ({
        url: `schools/${id}/subscriptions/`,
        method: "put",
        body: {
          id,
          subscription_type,
          subscription_period,
          subscription_start_date,
          subscription_expiration_date,
        },
      }),
      invalidatesTags: ["school"],
    }),

    getAllTeacherPractices: builder.query({
      query: (access_state) => ({
        url: `practices/teachers/?${access_state}`,
        method: "get",
      }),
    }),
    getAllTeacherPracticesForStudent: builder.query({
      query: () => ({
        url: `practices/students/`,
        method: "get",
      }),
    }),
    get_a_TeacherPracticeForStudent: builder.query({
      query: (id) => ({
        url: `practices/${id}/students/`,
        method: "get",
      }),
    }),

    //--endpoints
  }),
});

export const {
  useTeacherLearningPathsQuery,
  useSingleHomeworkQuery,
  useCreateHomeWorkMutation,
  useDeleteHomeWorkMutation,
  useGetStudentQuery,
  useGetStudentsQuery,
  useGetClassesQuery,
  useCreateClassMutation,
  useUpdateClassMutation,
  useDeleteClassMutation,
  useGetActiveClassesQuery,
  useApproveStudentMutation,
  useAddStudentToClassMutation,
  useRemoveStudentFromClassMutation,
  useHomeworksQuery,
  useGetJoinStudentQuery,
  useGetClassStudentQuery,
  useGetSchoolStudentsWithoutClassForSchoolManagerQuery,
  useGetClassQuery,
  useUpdateHomeWorkMutation,
  useClassLeaderBoardQuery,
  useHomeworksOfClassQuery,
  useHomeworksTrackingQuery,
  useHomeworksCompletionRateQuery,
  useHomeworkStudentsCompletionRateOfClassroomQuery,
  useTeacherTrailsQuery,
  useTeacherSingleTrailQuery,
  useCreateTeacherTrailMutation,
  useUpdateTeacherTrailMutation,
  useDeleteTeacherTrailMutation,
  useGetAllTrailsInStudentsClassQuery,
  useGetSchoolsQuery,
  useGet_a_SchoolQuery,
  useCreateSchoolMutation,
  useUpdateSchoolMutation,
  useDeleteSchoolMutation,
  useUpdatePasswordWithTeacherMutation,
  useGetLiveLecturesQuery,
  useGetLiveLecturesForClassQuery,
  useGet_a_LiveLectureQuery,
  useCreateLiveLectureMutation,
  useUpdateLiveLectureMutation,
  useDeleteLiveLectureMutation,
  useGetLiveLecturesForStudentQuery,
  useGetAttendanceOf_a_LiveLectureQuery,
  useGetAttendanceOf_all_LiveLectureQuery,
  useAttendanceParticipationRateQuery,
  useCreateAttendanceForClassroomMutation,
  useUpdateAttendanceOf_a_LiveLectureMutation,
  useTeacherReportQuery,
  useHomeworksCompletionRateOfClassroomQuery,
  useLiveLectureCompletitionRateOfClassroomQuery,
  useLiveLectureStudentsAttandanceRateQuery,
  useCourseCompletitionRateOfClassroomQuery,
  useAdminAndSchoolManagerReportQuery,
  useGetSchoolSubscriptionQuery,
  useUpdateSchoolSubscriptionMutation,
  useGetAllTeacherPracticesQuery,
  useGetAllTeacherPracticesForStudentQuery,
  useGet_a_TeacherPracticeForStudentQuery,
} = teacherApi;
