import React, { useEffect, useState } from "react";
import style from "./CompetitionListForStudent.module.scss";
import CompetitionCard from "./components/competition_card/CompetitionCard";
import useSortableData from "../../../../hooks/useSortableData";
import PaginationButtons, {
  dataPagination,
  PER_COUNT,
} from "../../../../components/PaginationButtons";
import { nanoid } from "nanoid";
import { useGetAllCompetitionsQuery } from "../../admin/talent cup/talentCupApi";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Select from "../../../../components/bootstrap/forms/Select";
import { getSelectedCompetitions } from "./competitionFunctions";
import _ from "underscore";

const CompetitionListForStudent = () => {
  const { data, isSuccess } = useGetAllCompetitionsQuery();
  let sortedData  = _.sortBy(data, item => item.application_deadline).reverse();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_COUNT["8"]);
  const [selectedCompetitionStatus, setSelectedCompetitionStatus] =
    useState("Tamamı");
  const [filteredCompetitions, setFilteredCompetitions] = useState("");
  useEffect(() => {
    getSelectedCompetitions(
      sortedCompetitions,
      selectedCompetitionStatus,
      setFilteredCompetitions
    );
    //eslint-disable-next-line
  }, [selectedCompetitionStatus, data]);

  const activeCompetitions =
    sortedData && sortedData.length > 0 ? sortedData.filter((item) => item.status === 1) : [];
  const futureCompetitions =
    sortedData && sortedData.length > 0 ? sortedData.filter((item) => item.status === 3) : [];
  const pastCompetitions =
    sortedData && sortedData.length > 0 ? sortedData.filter((item) => item.status === 2) : [];
  let sortedCompetitions = activeCompetitions
    .concat(futureCompetitions)
    .concat(pastCompetitions);
  const { items } = useSortableData(
    filteredCompetitions ? filteredCompetitions : sortedCompetitions ?? []
  );

  if (isSuccess)
  return (
    <>
      <div className="d-flex justify-content-end mx-5">
        <div className={`${style.classDropdown} pe-5 my-2`}>
          <FormGroup
            id="state"
            label="Kategori"
            isFloating
            className={`${style.classDropdownForm}`}
          >
            <Select
              value={selectedCompetitionStatus}
              ariaLabel={selectedCompetitionStatus}
              className={`${style.customTableHeadSelect}`}
              list={[
                { value: "Tamamı", text: "Tüm Yarışmalar" },
                { value: "Aktif", text: "Aktif Yarışmalar" },
                { value: "Pasif", text: "Pasif Yarışmalar" },
                { value: "Gelecek", text: "Gelecek Yarışmalar" },
              ]}
              onChange={(e) => setSelectedCompetitionStatus(e.target.value)}
            ></Select>
          </FormGroup>
        </div>
      </div>
      <div className={`${style.allCompetitions}`}>
        {items.length === 0 ? (
          <div>
            <span>Kayıtlı yarışma bulunamadı</span>
          </div>
        ) : (
          dataPagination(items, currentPage, perPage).map((item, i) => (
            <CompetitionCard competition={item} key={nanoid()} />
          ))
        )}
      </div>
      <PaginationButtons
        data={items}
        label="yarışma"
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </>
  );
};

export default CompetitionListForStudent;