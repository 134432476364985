// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;700;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ParentReport_brandLogo__q7\\+ID {\n  width: 300px;\n}\n.ParentReport_brandLogo__q7\\+ID img {\n  width: 100%;\n}\n\n.ParentReport_date__laYDo {\n  color: #6c5dd3;\n  font-family: Raleway;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 800;\n  line-height: normal;\n}\n\n@media screen and (max-width: 768px) {\n  .ParentReport_logoAndDateContainer__PHXEO {\n    margin-top: 0 !important;\n    gap: 15px;\n  }\n}\n@media screen and (max-width: 1200px) {\n  .ParentReport_mainReportContainer__XbdwF {\n    padding-top: 15px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/t14/reports/parentReports/ParentReport.module.scss"],"names":[],"mappings":"AACA;EACE,YAAA;AACF;AAAE;EACE,WAAA;AAEJ;;AACA;EACE,cAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAEF;;AAEA;EACA;IACE,wBAAA;IACA,SAAA;EACA;AACF;AAEA;EACE;IACE,iBAAA;EAAF;AACF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Raleway:wght@400;700;800&display=swap\");\n.brandLogo {\n  width: 300px;\n  img {\n    width: 100%;\n  }\n}\n.date {\n  color: #6c5dd3;\n  font-family: Raleway;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 800;\n  line-height: normal;\n}\n\n//! mobil logoAndDateContainer\n@media screen and (max-width: 768px) {\n.logoAndDateContainer{\n  margin-top: 0 !important;\n  gap:15px\n}\n}\n\n@media screen and (max-width: 1200px) {\n  .mainReportContainer{\n    padding-top: 15px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brandLogo": "ParentReport_brandLogo__q7+ID",
	"date": "ParentReport_date__laYDo",
	"logoAndDateContainer": "ParentReport_logoAndDateContainer__PHXEO",
	"mainReportContainer": "ParentReport_mainReportContainer__XbdwF"
};
export default ___CSS_LOADER_EXPORT___;
