import React from "react";
import style from "./AttendanceRate.module.scss";
import { useAttendanceParticipationRateQuery } from "../../../../teacher/teacherApi";
import { useParams } from "react-router-dom";
import { nanoid } from "nanoid";
import Information from "./Information";
import Card from "../../../../../../components/bootstrap/Card";

const AttendanceRate = () => {
  const { classId } = useParams();
  const { data:studentsAttendanceRatio } = useAttendanceParticipationRateQuery(classId); 
  console.table(studentsAttendanceRatio);
  return (
    <Card className={`${style.cardContainer}`}>
      <div className={`${style.informationsContainer}`}>
        <div className={`${style.title} my-3 mb-3 px-3`}>
          Derse Katılım Oranı En Yüksek 5 Öğrenci
        </div>
        {studentsAttendanceRatio && studentsAttendanceRatio.length < 1 && (
          <div className={`${style.emptyList}`}>
            <span>Öğrenci bulunmamaktadır</span>
          </div>
        )}
        {studentsAttendanceRatio &&
          studentsAttendanceRatio.length > 0 &&
          studentsAttendanceRatio.map((item, index) => {
            const userName = item.full_name.includes("None") ? item.email.split("@")[0] : item.full_name
            return (
              <div
                className={`${style.information} py-3 px-3 my-2`}
                key={nanoid()}
              >
                <Information
              description={userName}
              value={item.participation_rate}
              index={index}
            />
              </div>
            );
          })}
      </div>
    </Card>
  );
};

export default AttendanceRate;
