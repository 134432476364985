import React from "react";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import DashboardHeader from "../../../common/Headers/DashboardHeader";
import { useParams } from "react-router-dom";
import Page from "../../../../layout/Page/Page";
import { useGetStudentQuery } from "../teacherApi";
import TeacherStudentPage from "./TeacherStudentPage";

const TeacherStudentPageWrapper = () => {
  const { classId,studentId } = useParams();

  const { data: student } = useGetStudentQuery({class_id:classId,student_id:studentId });
  
  return (
    <PageWrapper>
      <DashboardHeader />
      <Page>
        <TeacherStudentPage isFluid student={student}/>
      </Page>
    </PageWrapper>
  );
};

export default TeacherStudentPageWrapper;
