import React from 'react'
import style from "./StudentWithClass.module.scss"
import useDarkMode from '../../../../../hooks/useDarkMode';

const StudentInfo = ({logo, description, detail}) => {
  const { darkModeStatus } = useDarkMode();
  return (
    <div className={`${style.studentInfoContainer} my-3 d-flex flex-column ps-4 gap-4 text-center py-3`}>
      <div className={`${style.logoDiv} mx-auto`}>
        <img src={logo} alt="" />
      </div>
      <div><span className={`${style.description}`}>{description}</span></div>
      <div><span className={`${style.detail}`} style={{color:darkModeStatus && "#fff"}}>{detail}</span></div>
    </div>
  )
}

export default StudentInfo