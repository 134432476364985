const USER_SUBSCRIPTION_PERIOD = {
  UNKNOWN: {
    id: 0,
    name: "unknown",
  },
  MONTHLY: {
    id: 1,
    name: "aylık",
  },
  ANNUALLY: {
    id: 2,
    name: "yıllık",
  },
};
export default USER_SUBSCRIPTION_PERIOD;
