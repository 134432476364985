import React from 'react'
import style from "./LastDays.module.scss";
import Card from '../../../../../components/bootstrap/Card';
import SingleDay from './2-SingleDay';
import { useUserActivitiesInLastDaysQuery } from '../../../../../store/features/apiSlice';
import { nanoid } from 'nanoid';

const LastDays = () => {
  const { data } =
  useUserActivitiesInLastDaysQuery();

  const last_10_days_activities = data && Object.entries(data).reverse();

  return (
    <div className={`${style.lastDaysContainer}`}>
      <Card className={`${style.cardDiv}`}>
        <div className={`${style.headerDiv} d-flex justify-content-center align-items-start flex-column ps-5`}>
          <span>Son 10 günde yaptıklarım</span>
        </div>
      <div className={`${style.daysContainer} my-2`}>
      {last_10_days_activities?.map((activite)=><SingleDay key={nanoid()} description={activite[0]} date={activite[1].slice(8, 10) + "-" + activite[1].slice(5, 7) + "-" + activite[1].slice(0, 4)}/>)}
      </div>
      </Card>
    </div>
  );
}

export default LastDays