import React from 'react'
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper'
import Page from '../../../../layout/Page/Page'
import DashboardHeaderTitle from '../../../common/Headers/DashboardHeaderWithTitle'
import SchoolManagerClassTable from './SchoolManagerClassTable'
import SchoolManagerClassTableForAdmin from './SchoolManagerClassTableForAdmin'
import USER_ROLES from '../../../../common/data/enumUserRole'

const ClassManagement = () => {
  const role = localStorage.getItem("roles");
  const isAdmin = Number(role) === USER_ROLES.ADMIN.id;
  const isSchoolManager = Number(role) === USER_ROLES.SCHOOL_MANAGER.id;
  return (
    <PageWrapper>
    <Page>
    <DashboardHeaderTitle />
    <div className="row h-100">
          <div className="col-12">
            {isSchoolManager && <SchoolManagerClassTable/>}
            {isAdmin && <SchoolManagerClassTableForAdmin/>}
          </div>
        </div>
    </Page>
    </PageWrapper>
  )
}

export default ClassManagement