import React, { useEffect, useState } from "react";
import style from "./CourseListTableForSchool.module.scss";
import PaginationButtons, {
  PER_COUNT,
  dataPagination,
} from "../../../../../components/PaginationButtons";
import useSortableData from "../../../../../hooks/useSortableData";
import USER_ROLES from "../../../../../common/data/enumUserRole";
import { Delete, Edit} from "@mui/icons-material";
import Icon from "../../../../../components/icon/Icon";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import COURSE_STATUS from "../../../../../common/data/enumCourseState";
import SchoolManagerHeader from "../../dashboard/school-manager-header/SchoolManagerHeader";
import { IconButton, Tooltip } from "@mui/material";
import plus from "../../../../../assets/dashboardImages/plus.svg";
import CreateCourseModal from "./1-CreateCourseModal";
import Confirm from "../../../../common/Confirm";
import showNotification from "../../../../../components/extras/showNotification";

const CourseListTableForSchool = ({courses, deleteCourse}) => {
  const role = localStorage.getItem("roles");
  const isAdmin = Number(role) === USER_ROLES.ADMIN.id;
  const schoolName = localStorage.getItem("schoolName");
  const schoolLogo = localStorage.getItem("schoolLogo");
  const [isOpenNewCourseModal, setIsOpenNewCourseModal] = useState(false);
  const [courseId, setCourseId] = useState(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const isMobile = useMediaQuery({ minWidth: 768 });
  const [searchValue, setSearchValue] = useState("");
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_COUNT["10"]);
  const { items } = useSortableData(
    filteredCourses ? filteredCourses : courses ? courses : []
  );

  useEffect(() => {
    filterCourses();
    //eslint-disable-next-line
  }, [searchValue]);

  const filterCourses = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const newCourses =
      courses &&
      courses.filter(
        (item) => item.title && item.title.toLowerCase().includes(searchValue)
      );
    setFilteredCourses(newCourses);
  };

  useEffect(() => {
    setSearchValue("");
    filterCourses();
    //eslint-disable-next-line
  }, [courses]);

  const addCourse = () => {
    setIsOpenNewCourseModal(true);
  };
    //! delete course--------
    const removeCourse = (e) => {
      setOpen(true);
      setCourseId(e.currentTarget.id && e.currentTarget.id);
    };
    const handleDelete = async () => {
      const response = await deleteCourse(courseId);
      setOpen(false);
      if (response.error) {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="danger" size="lg" className="me-1" />
            <span>Silme İşlemi Başarısız</span>
          </span>,
          "Bir hata meydana geldi",
          "danger"
        );
      } else {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Silme Başarılı</span>
          </span>,
          "Ders başarıyla silindi.",
          "success"
        );
      }
    };
    const handleCancel = () => setOpen(false);
    //!-----------------------------------------------

  return (
    <div>
      <CreateCourseModal
        isOpenNewCourseModal={isOpenNewCourseModal}
        setIsOpenNewCourseModal={setIsOpenNewCourseModal}
      />
       <Confirm
        open={open}
        setOpen={setOpen}
        onConfirm={handleDelete}
        onCancel={handleCancel}
      />
      <div className={`${style.schoolManagerHeader}`} style={{ zIndex: "999" }}>
        <SchoolManagerHeader schoolName={schoolName} logo={schoolLogo} />
      </div>

      <div className={`${style.actionsContainer} px-2 mx-4 gap-3 mx-auto`}>
        <div className={`${style.inputContainer} `}>
          <div
            className={`${style.searchContainer} d-flex align-items-center justify-content-center`}
          >
            <form className="d-flex gap-1 w-100" onSubmit={filterCourses}>
              <label className="" htmlFor="searchInput">
                <Icon
                  icon="Search"
                  size="2x"
                  style={{ color: "#000", opacity: "0.3" }}
                />
              </label>
              <input
                className="form-control border-0 shadow-none bg-transparent"
                type="search"
                placeholder={isMobile && "Aramak istediğiniz dersi yazınız"}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
              />
              <div
            className={`${style.addCourseContainer} d-flex align-items-center gap-2 ps-2`}
            onClick={() => addCourse()}
          >
            <span>
              <img src={plus} alt="" />
            </span>
            <span>Ders Ekle</span>
          </div>
            </form>
          </div>
        </div>
      </div>

      <div className={`${style.customTable} mt-5 px-2`}>
        <div
          className={`${style.customHeadContainer} d-flex align-items-center my-3 px-2`}
        >
          <div className={`${style.customTableHead1} ${style.customTableHead}`}>
            Başlık
          </div>
          <div className={`${style.customTableHead2} ${style.customTableHead}`}>
            Açıklama
          </div>
          <div
            className={`${style.customTableHead3} ${style.customTableHead} text-center`}
          >
            Sırası
          </div>
          <div
            className={`${style.customTableHead4} ${style.customTableHead} text-center`}
          >
            Aktiflik
          </div>
          <div
            className={`${style.customTableHead5} ${style.customTableHead} text-center`}
          >
            Eylemler
          </div>
        </div>
        <div className={`${style.customRowContainer}`}>
          {courses && items.length === 0 ? (
            <div>
              <span>Kayıtlı ders bulunamadı</span>
            </div>
          ) : (
            dataPagination(items, currentPage, perPage).map((item, i) => (
              <div
                key={item.id}
                className={`${style.customTableRow} ${
                  i % 2 === 0 && style.customBackgroundColor
                } d-flex align-items-center mt-1 px-2`}
              >
                <div className={`${style.customTableColumn1} cursor-pointer`}>
                  <div className={`${style.titleContainer}`}>
                    <div className={`${style.titleShort}`}>{item.title}</div>
                    <div className={`${style.titleFull}`}>{item.title}</div>
                  </div>
                </div>
                <div className={`${style.customTableColumn2}`}>
                  <div className="d-flex">
                    <div className={`${style.schoolContainer}`}>
                      <div
                        className={`${style.schoolShort}`}
                      >{`${item.description}`}</div>
                      <div
                        className={`${style.schoolFull}`}
                      >{`${item.description}`}</div>
                    </div>
                  </div>
                </div>
                <div className={`${style.customTableColumn3} text-center`}>
                  <div>{item.order_number}</div>
                </div>

                <div
                  className={`${style.customTableColumn4} d-flex justify-content-center mx-auto`}
                >
                  <div>
                    {item.status === COURSE_STATUS.ACTIVE.id
                      ? "Aktif"
                      : item.status === COURSE_STATUS.DRAFT.id
                      ? "Taslak"
                      : "Pasif"}
                  </div>
                </div>
                {/* Eylemler */}
                <div
                  className={`${style.customTableColumn5} d-flex justify-content-center mx-auto`}
                >
                  <div className="d-flex justify-content-center gap-2">
                  <IconButton id={item.id} onClick={(e) => removeCourse(e)}>
                          <Delete className="fs-3" />
                        </IconButton>

                    <Tooltip title="Edit">
                      <IconButton 
                      onClick={() =>isAdmin ? navigate(`/admin/updateSchoolCourse/${item.id}`) : navigate(`/schoolManager/updateSchoolCourse/${item.id}`)}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <PaginationButtons
        data={items}
        label="kullanıcı"
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </div>
  );
};

export default CourseListTableForSchool;
