import React from 'react'
import style from "./DailyPlan.module.scss";
import Card from '../../../../../components/bootstrap/Card';
import SinglePlan from './2-SinglePlan';
import { nanoid } from 'nanoid';
import useDarkMode from '../../../../../hooks/useDarkMode';

const DailyPlan = () => {
  const { darkModeStatus } = useDarkMode();
  const daily_advice = [
    "1 ders bitirebilirsin",
    "2 alıştırma çözebilirsin",
    "Codemino'da 5 adım bitirebilirsin",
    "CodeBoo'da 5 adım bitirebilirsin",
    "CodeÇizz ve CodeArt'ta 1 proje bitirebilirsin.",
  ];
  const today = new Date();

  function formatDate(inputDate) {
    const months = [
      "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
      "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
    ];
  
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
  
    return `${day} ${month} ${year}`;
  }
  return (
    <div className={`${style.dailyPlanContainer} ${darkModeStatus && style.darkMode}`}>
      <Card className={`${style.cardDiv}`}>
        <div className={`${style.headerDiv} d-flex justify-content-center align-items-start flex-column ps-5`}>
          <span>Bugünün Planı</span>
          <span>{`${formatDate(today)}`}</span>
        </div>
      <div className={`${style.plansContainer} my-2`}>
      {daily_advice?.map((item)=><SinglePlan key={nanoid()} description={item}/>)}
      </div>
      </Card>
    </div>
  );
}

export default DailyPlan