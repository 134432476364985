  export function getTotalLessonCount(courseLessons) {
    let totalLessonCount = 0;
    courseLessons.forEach(course => {
        // Iterate over each 'course' element
        // Check each 'chapters' array
        course.chapters.forEach(chapter => {
            // Iterate over each 'chapter' element
            // Get the length of each 'lessons' array and add it to the total count
            totalLessonCount += chapter.lessons.length;
        });
    });
    // Return the total count of lessons multiplied by 5
    return totalLessonCount * 5;
}
  export function getTotalPracticeCount(learningPaths) {
    let totalPracticeCount = 0;
    learningPaths.forEach(practice => {
            totalPracticeCount += practice.practices.length;
    });
    return totalPracticeCount * 5;
}
  export function getTotalGameCount(gameSteps) {
    let totalGameStepCount = 0;
    gameSteps.forEach(game => {
        game.sections.forEach(section => {
            totalGameStepCount += section.steps.length;
        });
    });
    return totalGameStepCount * 5;
}
  export function getTotalTeacherPracticeCount(practices) {
    let totalTeacherPracticeCount = practices.length;
    return totalTeacherPracticeCount * 5;
}

export function getTotalPoints(courseLessons, learningPaths, gameSteps, practices){
    let lessonPoints = getTotalLessonCount(courseLessons);
    let practicePoints = getTotalPracticeCount(learningPaths);
    let gamePoints = getTotalGameCount(gameSteps);
    let teacherPracticePoints = getTotalTeacherPracticeCount(practices);
    return lessonPoints + practicePoints + gamePoints + teacherPracticePoints;
}
