import React, { useState } from "react";
import style from "./ContentManagementWrapper.module.scss";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import DashboardHeader from "../../../common/Headers/DashboardHeader";
import Page from "../../../../layout/Page/Page";
import classPathsLogo1 from "../../../../../src/assets/lessons/classPathsLogo1.svg";
import classPathsLogo2 from "../../../../../src/assets/lessons/classPathsLogo2.svg";
import announce1 from "../../../../../src/assets/lessons/announce1.svg";
import announce2 from "../../../../../src/assets/lessons/announce2.svg";
import Card from "../../../../components/bootstrap/Card";
import AdminContentManagement from "./admin content management/AdminContentManagement";

const ContentManagementWrapper = () => {
  const _activeTab = sessionStorage.getItem("cmw_activeTab");
  const [activeTab, setActiveTab] = useState(Number(_activeTab) || 1);

  const tabContents = [
    {
      content: <AdminContentManagement partner={null} />,
      tabName: "Talent14 İçerikleri",
      icon1: announce1,
      icon2: announce2,
    },
    {
      content: <AdminContentManagement partner={"partner"} />,
      tabName: "Partner İçerikleri",
      icon1: classPathsLogo1,
      icon2: classPathsLogo2,
    },
  ];

  const handleTabClick = (tabNumber) => {
    if (tabNumber >= 1 && tabNumber <= tabContents.length) {
      sessionStorage.setItem("cmw_activeTab", tabNumber);
      setActiveTab(tabNumber);
    }
  };

  // useEffect(() => {
  //   if (window.Tawk_API) {
  //     window.Tawk_API.hideWidget();
  //   }
  //   return () => {
  //     if (window.Tawk_API) {
  //       window.Tawk_API.showWidget();
  //     }
  //   };
  // }, []);
  return (
    <PageWrapper title={"Dersler"}>
      <DashboardHeader />
      <Page container={false} style={{ padding: "0" }}>
        <Card className="h-100 mt-3 mx-lg-3">
          <div className="page container-xxl">
            <div
              className={`${style.tabContainer} d-flex justify-content-evenly align-items-center mx-auto mb-5 px-3 gap-1`}
            >
              {tabContents.map((tab, index) => (
                <div
                  key={index}
                  className={`${style.tab} ${
                    activeTab === index + 1 ? `${style.activetab}` : ""
                  } d-flex justify-content-center align-items-center gap-2 p-3`}
                  onClick={() => handleTabClick(index + 1)}
                >
                  <div className={`${style.tabsLogo}`}>
                    <img
                      src={activeTab === index + 1 ? tab.icon2 : tab.icon1}
                      alt=""
                    />
                  </div>
                  <div className={`${style.tabsDescription}`}>
                    <span>{tab.tabName}</span>
                  </div>
                </div>
              ))}
            </div>
            <div className="tab-content">
              {tabContents[activeTab - 1] && tabContents[activeTab - 1]?.content}
            </div>
          </div>
        </Card>
      </Page>
    </PageWrapper>
  );
};

export default ContentManagementWrapper;
