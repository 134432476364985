import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Checks from "../../../../../../components/bootstrap/forms/Checks";
import Page from "../../../../../../layout/Page/Page";
import {
  useAddUserLessonActivitiesMutation,
  useAllUserLessonActivitiesQuery,
  useRemoveUserLessonActiviteMutation,
} from "../../../../../../store/features/apiSlice";
import style from "./lessonsContent.module.scss";
import H5PIframe from "./5.H5PIframe";
import { H5PPlayerUI } from "@lumieducation/h5p-react";
import {ContentService} from '../../../../admin/course management/h5p/services/ContentService';
import { useRef } from "react";
import { setLessonCompletedForTeacher } from "../../../teacherLessonVideoSlice";

const LectureVideo = () => {
  const [addUserLessonActivities] = useAddUserLessonActivitiesMutation();
  const [removeUserLessonActivite] = useRemoveUserLessonActiviteMutation();
  const { data, isSuccess } = useAllUserLessonActivitiesQuery();

  const { lectureVideoContentForTeacher } = useSelector((store) => store.teacherLessonVideoSlice);

  let currentVideo =
    data && data.find((item) => item.lesson === lectureVideoContentForTeacher.videoId);
  const completedVideo = JSON.parse(localStorage.getItem("finishedLessonsForTeacher"));
  const isCompleted =
    completedVideo && completedVideo.includes(lectureVideoContentForTeacher.videoId);
  const { lessonCompletedForTeacher } = useSelector((store) => store.teacherLessonVideoSlice);
  const dispatch = useDispatch();

  const [first, setFirst] = useState(true);

  const addVideo = () => {
    if (lessonCompletedForTeacher && !currentVideo) {
      addUserLessonActivities({lesson:lectureVideoContentForTeacher.videoId, points:0});
      localStorage.setItem(
        "finishedLessonsForTeacher",
        JSON.stringify([...completedVideo, lectureVideoContentForTeacher.videoId])
      );
    }
  };
  const removeVideo = () => {
    if (!lessonCompletedForTeacher && currentVideo) {
      removeUserLessonActivite(currentVideo.id);
      const newList = completedVideo?.filter(
        (item) => item !== lectureVideoContentForTeacher.videoId
      );
      localStorage.setItem("finishedLessonsForTeacher", JSON.stringify(newList));
    }
  };

  const handleChange = () => {
    dispatch(setLessonCompletedForTeacher(!lessonCompletedForTeacher));
  };

  useEffect(() => {
    if (isSuccess)
      localStorage.setItem(
        "finishedLessonsForTeacher",
        JSON.stringify(data?.map((item) => item.lesson))
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (lectureVideoContentForTeacher.videoId) {
      if (isCompleted) {
        dispatch(setLessonCompletedForTeacher(true));
      } else {
        dispatch(setLessonCompletedForTeacher(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lectureVideoContentForTeacher.videoId]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(setLessonCompletedForTeacher(isCompleted && true));
    }, 5);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lessonCompletedForTeacher) {
      addVideo();
    } else if (!lessonCompletedForTeacher && !first) {
      removeVideo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonCompletedForTeacher]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFirst(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const h5pPlayer = useRef();
  const contentService = new ContentService('/h5p');

  const [loading, setLoading] = useState(false);
  const onPlayerInitialized = () => {
    setLoading(false);
    console.log("Player initialized, loading state:", loading);
  };
  return (
    <Page className={`${style.pageContainerLectureVideo}`}>
      <div className={`${style.videoContainer}`}>
        {lectureVideoContentForTeacher.content ?   <H5PPlayerUI
                            ref={h5pPlayer}
                            contentId={lectureVideoContentForTeacher.content}
                            contextId={undefined}
                            asUserId={"1234"}
                            readOnlyState={true}
                            loadContentCallback={
                                contentService.getPlay
                            }
                            onInitialized={onPlayerInitialized}
                            onxAPIStatement={(
                                statement,
                                context,
                                event
                            ) => console.log(statement, context, event)}
                        /> : <H5PIframe src={`${lectureVideoContentForTeacher.url}`}/>}
      
      
        <div
          className={`${style.lessonCheck} fs-4 d-flex justify-content-start`}
        >
          {/* <Checks
            type="switch"
            value={lessonCompletedForTeacher}
            label="Dersi Tamamladım"
            checked={lessonCompletedForTeacher}
            onChange={() => handleChange()}
            // disabled
          /> */}
          <span style={{color: "#666", opacity: "0.6"}}>🔒Dersi Tamamladım</span>
        </div>
        {/* <div className="d-flex flex-column mt-3">
          <h3>AÇIKLAMA</h3>
          <p
            className="mt-4 p-1"
            style={{ textAlign: "justify", fontSize: "16px"}}
          >
            {lectureVideoContentForTeacher.description ? lectureVideoContentForTeacher.description : ""}
          </p>
        </div> */}
      </div>
    </Page>
  );
};

export default LectureVideo;
