import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // localStorage kullanımı
import teacherSlice from "../pages/t14/teacher/teacherSlice";
import { apiSlice } from "./features/apiSlice";
import pathSlice from "./pathSlice";
import pathWithVideoSlice from "./pathWithVideoSlice";
import paymentSlice from "./paymentSlice";
import loginWithPaymentPlanSlice from "./loginWithPaymentPlanSlice";
import teacherLessonVideoSlice from "../pages/t14/teacher/teacherLessonVideoSlice";
import restrictionSlice from "../pages/t14/admin/school management/school restrictions/restrictionSlice";
import talentCupSlice from "../pages/t14/admin/talent cup/talentCupSlice";

// Redux Persist yapılandırması
const persistConfig = {
    key: "payment", // storage'da tutulacak key
    storage,
    whitelist: ["standartMounthly", "premiumMounthly", "totalPrice", "premiumPlan", "standartPlan"], // Kalıcı olacak state'ler
};
const persistedPaymentReducer = persistReducer(persistConfig, paymentSlice.reducer);

const store = configureStore({
    reducer: {path:pathSlice.reducer,teacher:teacherSlice.reducer, teacherLessonVideoSlice:teacherLessonVideoSlice.reducer, pathWithVideo:pathWithVideoSlice.reducer, payment: persistedPaymentReducer, loginWithPaymentPlan:loginWithPaymentPlanSlice.reducer, restriction:restrictionSlice.reducer, talentCup:talentCupSlice.reducer, [apiSlice.reducerPath]:apiSlice.reducer},
    middleware:(getDefaultMiddleware)=>getDefaultMiddleware().concat(apiSlice.middleware),
});

// Persistor oluşturulması
export const persistor = persistStore(store);
export default store;