export function getSelectedHomeworks(
  homeWorks,
  homeworkStatus,
  setFilteredHomeworks
) {
  let newhomeWorks;

  if (homeWorks) {
    if (homeworkStatus === "Tamamı") {
      setFilteredHomeworks(homeWorks);
    }
    if (homeworkStatus === "Gelecek") {
      newhomeWorks = homeWorks.filter((item) => item.status === 0);
      setFilteredHomeworks(newhomeWorks.length > 0 ? newhomeWorks : []);
    }
    if (homeworkStatus === "Aktif") {
      newhomeWorks = homeWorks.filter((item) => item.status === 1);
      setFilteredHomeworks(newhomeWorks.length > 0 ? newhomeWorks : []);
    }
    if (homeworkStatus === "Geçmiş") {
      newhomeWorks = homeWorks.filter((item) => item.status === 2);
      setFilteredHomeworks(newhomeWorks.length > 0 ? newhomeWorks : []);
    }
  }
}

const homeworkStatusData = {
  0: {
    color: "info",
    text: "Gelecek",
  },
  1: {
    color: "success",
    text: "Aktif",
  },
  2: {
    color: "danger",
    text: "Geçmiş",
  },
};

export function getHomeworkStatus(status) {
  return homeworkStatusData[status]?.text || homeworkStatusData[0]?.text;
}
export function getHomeworkStatusColor(status) {
  return homeworkStatusData[status]?.color || homeworkStatusData[0]?.color;
}
