import React, { useEffect, useState } from "react";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Icon from "../../../components/icon/Icon";
import PaginationButtons, {
  dataPagination,
} from "../../../components/PaginationButtons";
import useSortableData from "../../../hooks/useSortableData";
import useSelectTable from "../../../hooks/useSelectTable";
import Checks from "../../../components/bootstrap/forms/Checks";
import PathIcon from "../../../components/PathIcon";
import { IconButton, Tooltip } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { firstOrDefault } from "../../../helpers/helpers";
import Confirm from "../../common/Confirm";
import LEARNING_PATH_STATE from "../../../common/data/enumLearningPathState";
import _ from "underscore";
function Button(props){
  return(
    <button type="button" className={`btn btn-light-${props.class}`}>
      {props.children}
    </button>
  )
}
const   LearningPathsTable = ({paths,editItem,deleteItem}) => {
  const [search, setSearch] = useState("")
  const [filteredLearningPaths, setFilteredLearningPaths] = useState("")

  const active = <Button  class="success">Aktif</Button>
  const passive =<Button  class="danger">Pasif</Button>
  const draft = <Button class="warning">Taslak</Button>;
  const unknown = <Button class="secondary">Bilinmiyor</Button>;
  const publicPath =<Button  class="success">Herkese Açık</Button>
  const privatePath =<Button  class="danger">Özel</Button>

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);

  const { items, requestSort, getClassNamesFor } = useSortableData(!filteredLearningPaths ? paths : filteredLearningPaths);
  
  const handleDelete = () => {
    deleteItem(firstOrDefault(selected));
    setOpen(false);
  };
  const handlCancel = () => setOpen(false);
  
  const onCurrentPageData = dataPagination(items, currentPage, perPage);
  const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageData);

  useEffect(() => {
    filterLearningPaths();
    //eslint-disable-next-line
  }, [search, paths]);

  
  
  const filterLearningPaths = (e) => {
    if (e && e.preventDefault) { e.preventDefault(); }
    const newLearningPaths = paths?.filter((item)=>item.title.toLocaleLowerCase().includes(search))
    setFilteredLearningPaths(newLearningPaths)
  }
  return (
    <>
     <div className="col-12 col-md-6 mx-auto">
            <form className="d-flex gap-1" onSubmit={filterLearningPaths}>
            <input className="form-control" type="search" placeholder="Aramak istediğiniz başlığı yazınız" value={search} onChange={(e)=>setSearch(e.target.value.toLowerCase())}/>
            <button className="btn btn-warning w-25">Bul</button>
          </form> 
          </div>
     <Confirm open={open} setOpen={setOpen} onConfirm={handleDelete} onCancel={handlCancel} 
     title="Dikkat!" message='Dersi silmek istediğinize emin misiniz?' />
      <Card>
        <CardBody>
          <table className="table table-modern">
            <thead>
              <tr>
                <th>{SelectAllCheck}</th>
                <th onClick={() => requestSort("title")}>
                  Başlık
                  <Icon
                    size="lg"
                    className={getClassNamesFor("firstName")}
                    icon="FilterList"
                  />
                </th>
                <th onClick={() => requestSort("explanation")}>
                  Açıklama
                  <Icon
                    size="lg"
                    className={getClassNamesFor("explanation")}
                    icon="FilterList"
                  />
                </th>
                <th onClick={() => requestSort("technology")}>
                  Technology
                  <Icon
                    size="lg"
                    className={getClassNamesFor("technology")}
                    icon="FilterList"
                  />
                </th>
                <th onClick={() => requestSort("is_active")}>
                  Durumu
                  <Icon
                    size="lg"
                    className={getClassNamesFor("is_active")}
                    icon="FilterList"
                  />
                </th>
                <th onClick={() => requestSort("is_active")}>
                  Aktif Mi
                  <Icon
                    size="lg"
                    className={getClassNamesFor("is_active")}
                    icon="FilterList"
                  />
                </th>
                <th onClick={() => requestSort("order_number")}
                style={{ textAlign: "center" }}>
                  Sırası
                  <Icon
                    size="lg"
                    className={getClassNamesFor("order_number")}
                    icon="FilterList"
                  />
                </th>
                <th onClick={() => requestSort("award_point")}
                style={{ textAlign: "center" }}>
                  Ödül Puanı
                  <Icon
                    size="lg"
                    className={getClassNamesFor("award_point")}
                    icon="FilterList"
                  />
                </th>
                <th style={{ textAlign: "center" }}>Sil</th>
                <th style={{ textAlign: "center" }}>Düzenle</th>
              </tr>
            </thead>
            <tbody>
              {_.sortBy(onCurrentPageData,"order_number").map((item) => (
                <tr key={item.id.toString()}>
                  <td>
                    <Checks
                      id={item.id.toString()}
                      name="selectedList"
                      value={item.id}
                      onChange={selectTable.handleChange}
                      checked={selectTable.values.selectedList.includes(
                        item.id.toString()
                      )}
                    />
                  </td>
                  <td>{item.title}</td>
                  <td>{item.explanation}</td>
                  <td>
                    <PathIcon technology={item.technology} />
                  </td>
                  <td>{item.access_state === 1 ? privatePath : publicPath}</td>
                  {/* <td>{item.state ? active : passive}</td> */}
                  <td>{item.state=== LEARNING_PATH_STATE.ACTIVE.id ? active : 
                      item.state=== LEARNING_PATH_STATE.UNKNOWN.id ? unknown: 
                      item.state=== LEARNING_PATH_STATE.PASSIVE.id ? passive : draft}</td>
                  <td style={{ textAlign: "center" }}>{item.order_number}</td>
                  <td style={{ textAlign: "center" }}>{item.award_point}</td>
                  <td style={{ textAlign: "center" }}>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => {
                          setSelected([item.id]);
                          setOpen(true);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>                </td>
                  <td style={{ textAlign: "center" }}>
                    <Tooltip title="Edit">
                      <IconButton  onClick={()=>editItem(item.id)}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CardBody>
        <PaginationButtons
          data={items}
          label="ders"
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </Card>
      </>
  );
};

export default LearningPathsTable;
