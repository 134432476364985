import React, { useEffect, useState } from "react";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import DashboardHeader from "../../common/Headers/DashboardHeader";
import { studentPages } from "../../../menu";
import { useParams } from "react-router-dom";
import { post } from "../../../utils/service";
import Page from "../../../layout/Page/Page";
import { useNavigate } from "react-router-dom";
import PracticeDetail from "./PracticeDetail";
import CodeEditor from "./CodeEditor";
import PracticeResult from "./PracticeResult";
import style from "./practice.module.scss";
import { useGet_a_TeacherPracticeForStudentQuery } from "../teacher/teacherApi";

function indentCode(code) {
  if (!code) return "";
  var lines = code.split("\n");
  lines = lines.map((line) => "    " + line + "\n");
  let user_code = lines.join("");

  return user_code;
}

const PracticeForTeacher = () => {
  const { id } = useParams();
  const {data, isSuccess, isLoading} = useGet_a_TeacherPracticeForStudentQuery(id);

  const [practice, setPractice] = useState(null);
  const [userCode, setUserCode] = useState("");
  const [codeRunResult, setCodeRunResult] = useState(null);
  const [codeEditorHeight, setCodeEditorHeight] = useState(500);

  const navigate = useNavigate()

  useEffect(() => {
  if(isSuccess && data){
    setPractice(data);
    setUserCode(data.template_code || '')
    setCodeRunResult(null);
  }
  }, [isSuccess, data])
  
  const handleUserCodeChange = (value) => {
    setUserCode(value);
  };
  const onUserCodeFocus = (event) => {
    setCodeEditorHeight(500);
  };
  async function submitPractice(id, path_id, code) {
    let postData = {
      practice_id: id,
      user_code: indentCode(code),
      run_type: "submit",
    };
  

    var res = await post("practices/run/", postData);

    setCodeRunResult(res.data);
  }

  const sortedPractices =JSON.parse(localStorage.getItem("sortedPracticesForTeacher"))
  const currentIndex = sortedPractices && sortedPractices.indexOf(Number(id))
  const lastIndex = sortedPractices && sortedPractices.length-1
  const [currentPracticeIndex, setCurrentPracticeIndex] = useState(currentIndex)
  const [nextPracticeId, setNextPracticeId] = useState(null)

  const nextPractice = () =>{
    setCurrentPracticeIndex(currentPracticeIndex === lastIndex ? 0 : currentPracticeIndex + 1)
  }
  const previousPractice = () =>{
    setCurrentPracticeIndex(currentPracticeIndex === 0 ? lastIndex : currentPracticeIndex - 1)
  }
  useEffect(() => {
    const timeout = setTimeout(() => {
      setNextPracticeId(sortedPractices[currentPracticeIndex])
    }, 5);
    return () => clearTimeout(timeout);
    //eslint-disable-next-line
  }, [currentPracticeIndex]);
  
  useEffect(() => {
    if(nextPracticeId){navigate(`/paths/teacher/practice/${nextPracticeId}`)}
    //eslint-disable-next-line
  }, [nextPracticeId])

  if(isLoading){
    return <div>Yükleniyor...</div>
  }
  return (
    <PageWrapper title={studentPages.practice.text}>
      <DashboardHeader />
      <Page className={`${style.pageContainer}`} container='fluid'>
        <div className="row mb-1">
          <div className={`${style.leftSection} col-xl-4 col-lg-4`}>
            {practice && (
              <PracticeDetail
                title={practice && practice.title}
                explanation={practice.explanation}
              />
            )}
          </div>
          <div className={`${style.rightSection} col-xl-8 col-lg-8`}>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12" >
                <CodeEditor
                  handleUserCodeChange={handleUserCodeChange}
                  onUserCodeFocus={onUserCodeFocus}
                  userCode={userCode}
                  codeEditorHeight={codeEditorHeight}
                  nextPractice={nextPractice}
                  previousPractice={previousPractice}
                  currentPracticeIndex={currentPracticeIndex}
                  lastIndex={lastIndex}
                />
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <PracticeResult
                  submitPractice={submitPractice}
                  practice={practice}
                  userCode={userCode}
                  codeRunResult={codeRunResult}
                />
              </div>
            </div>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default PracticeForTeacher;
