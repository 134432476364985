import React from "react";
import style from "./HomeworkReportForTeacher.module.scss";
import { nanoid } from "nanoid";
import ChartInfo from "./ChartInfo";
import { Card, Spinner } from "react-bootstrap";
import HomeworkInfo from "./HomeworkInfo";
import Information from "./Information";
import { useHomeworksCompletionRateOfClassroomQuery, useHomeworkStudentsCompletionRateOfClassroomQuery } from "../../../../teacher/teacherApi";

const HomeworkReportForTeacher = ({ data, isLoading, classId }) => {
  const homeworks = data && data.activate_homework;
  const { data:homeworkRatioData } = useHomeworksCompletionRateOfClassroomQuery(classId, {skip:!classId});
  const { data:studentsHomeworkRatioData } = useHomeworkStudentsCompletionRateOfClassroomQuery(classId, {skip:!classId});
  const value = homeworkRatioData?.homework_completion_rate ?? 0;
  const studentsHomeworkRatio = studentsHomeworkRatioData ?? [];
  return (
    <Card className={`${style.homeworkReportContainer} p-3 px-5`}>
      <div className={`${style.chartContainer}`}>
        <div className={`${style.title} my-3 px-3`}>Ödevler</div>
        <div className="d-flex">
          <div className={`${style.homeworkContainer}`}>
            {homeworks && homeworks.length < 1 && (
              <div className={`${style.emptyHomeworkList}`}>
                <span>Ödev bulunmamaktadır</span>
              </div>
            )}
            {homeworks &&
              homeworks.length > 0 &&
              homeworks.map((homework) => (
                <HomeworkInfo key={nanoid()} homework={homework} />
              ))}
          </div>
          {isLoading ? (
            <div className="w-100 d-flex justify-content-center align-items-center" style={{ height: '70vh' }}>
            <div className="text-center">
              <Spinner animation="border" role="status" style={{ width: '3rem', height: '3rem', color: '#007bff' }}>
                <span className="sr-only">...</span>
              </Spinner>
              <h3 className="mt-3" style={{ color: '#007bff', fontWeight: 'bold' }}>Yükleniyor...</h3>
            </div>
          </div>
          ) : (
            <div className={`${style.chartDiv}`}>
              <ChartInfo value={value} />
            </div>
          )}
        </div>
      </div>
      <div className={`${style.informationsContainer}`}>
        <div className={`${style.title} my-3 mb-4 px-3`}>
          Ödev Tamamlama Oranı En Yüksek 5 Öğrenci
        </div>
        {studentsHomeworkRatio && studentsHomeworkRatio.length < 1 && (
          <div className={`${style.emptyList}`}>
            <span>Öğrenci bulunmamaktadır</span>
          </div>
        )}
        {studentsHomeworkRatio &&
          studentsHomeworkRatio.length > 0 &&
          studentsHomeworkRatio.slice(0,5).map((item, index) => {
            return (
              <div
                className={`${style.information} py-3 px-3 my-3`}
                key={nanoid()}
              >
                <Information
                  description={item.student}
                  value={item.homework_completion_rate}
                  index={index}
                  email={item.email}
                />
              </div>
            );
          })}
      </div>
    </Card>
  );
};

export default HomeworkReportForTeacher;
