import React, { useEffect, useState } from "react";
import Card, { CardBody } from "../../../../components/bootstrap/Card";
import Icon from "../../../../components/icon/Icon";
import PaginationButtons, {
  dataPagination,
} from "../../../../components/PaginationButtons";
import useSortableData from "../../../../hooks/useSortableData";
import useSelectTable from "../../../../hooks/useSelectTable";
import Checks from "../../../../components/bootstrap/forms/Checks";
import PathIcon from "../../../../components/PathIcon";
import { IconButton, Tooltip } from "@mui/material";
import { Delete, Edit, Check } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Confirm from "../../../common/Confirm";


const PracticeTable = ({practices,deleteItem, filteredPractice}) => {
  const navigate = useNavigate();
  const active = (
    <button type="button" className="btn btn-light-success">
      Aktif
    </button>
  );
  const passive = (
    <button type="button" className="btn btn-light-danger">
      Pasif
    </button>
  );
  const [open,setOpen] = useState(false);
  const [selected,setSelected] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
 
  const { items, requestSort, getClassNamesFor } = useSortableData(practices);
  const onCurrentPageData = dataPagination(items, currentPage, perPage);
  const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageData);


  const handleDelete = () => {
    if(selected.length>0){
      selected.forEach((item)=>deleteItem(item))
      ;
    }
    setOpen(false);
    setSelected([])
  }

  const handlCancel = () => setOpen(false);

  useEffect(() => {
    if(selectTable.values.selectedList){
    setSelected(selectTable.values.selectedList)
}}, [selectTable.values.selectedList])
  

  return (
    <>
    <Confirm open={open} setOpen={setOpen} 
    onConfirm={handleDelete} onCancel={handlCancel}/>
            {selected.length>0 ?  <button className="btn btn-danger" style={{display:"block", position:"fixed", right:"100px"}} onClick={()=>{setSelected(selected);
              setOpen(true);
              }}>Seçilenleri Sil</button> : <button style={{display:"none"}} onClick={()=>{setSelected(selected);
              setOpen(true);
              }}>Seçilenleri Sil</button>}

      <Card>
        <CardBody>
          <table className="table table-modern">
            <thead>
              <tr>
                <th style={{width:"10px"}}>{SelectAllCheck}</th>
                <th onClick={() => requestSort("title")}>
                  Başlık
                  <Icon
                    size="lg"
                    className={getClassNamesFor("firstName")}
                    icon="FilterList"
                  />
                </th>
                <th onClick={() => requestSort("explanation")}>
                  Açıklama
                  <Icon
                    size="lg"
                    className={getClassNamesFor("explanation")}
                    icon="FilterList"
                  />
                </th>
                <th onClick={() => requestSort("language")} style={{width:"120px", textAlign:"center"}}>
                Program Dili
                  <Icon
                    size="lg"
                    className={getClassNamesFor("language")}
                    icon="FilterList"
                  />
                </th>
                <th onClick={() => requestSort("is_active")} style={{textAlign:"center"}}>
                  Aktif Mi
                  <Icon
                    size="lg"
                    className={getClassNamesFor("is_active")}
                    icon="FilterList"
                  />
                </th>
                <th onClick={() => requestSort("point")} style={{textAlign:"center"}}>
                  Puanı
                  <Icon
                    size="lg"
                    className={getClassNamesFor("point")}
                    icon="FilterList"
                  />
                </th>
                <th style={{textAlign:"center"}}>Eylemler</th>
              </tr>
            </thead>
            <tbody>
              {!filteredPractice 
              ? 
              onCurrentPageData.map((item) => (
                <tr key={item.id.toString()}>
                  <td>
                    <Checks
                      id={item.id.toString()}
                      name="selectedList"
                      value={item.id}
                      onChange={selectTable.handleChange}
                      checked={selectTable.values.selectedList.includes(
                        item.id.toString()
                      )}
                    />
                  </td>
                  <td>{item.title}</td>
                  <td>{item.explanation}</td>
                  <td>
                    <PathIcon technology={item.language} />
                  </td>
                  <td>{item.is_active ? active : passive}</td>
                  <td>{item.points}</td>
                  <td  style={{width:"150px"}}>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => {
                          setSelected([item.id]);
                          setOpen(true);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                     <Tooltip title="Edit">
                      <IconButton  onClick={()=>{navigate("/admin/practice-form/"+item.id);}}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                     <Tooltip title="Test">
                      <IconButton  onClick={()=>{navigate("/admin/test/"+item.id);}}>
                      <Check/>
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              )) : 
              filteredPractice.map((item) => (
                <tr key={item.id.toString()}>
                  <td>
                    <Checks
                      id={item.id.toString()}
                      name="selectedList"
                      value={item.id}
                      onChange={selectTable.handleChange}
                      checked={selectTable.values.selectedList.includes(
                        item.id.toString()
                      )}
                    />
                  </td>
                  <td style={{textAlign:"justify"}}>{item.title}</td>
                  <td style={{textAlign:"justify"}}>{item.explanation}</td>
                  <td style={{textAlign:"center"}}>
                    <PathIcon technology={item.language} />
                  </td>
                  <td  style={{textAlign:"center"}}>{item.is_active ? active : passive}</td>
                  <td style={{textAlign:"center"}}>{item.points}</td>
                  <td  style={{width:"150px", textAlign:"center"}}>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => {
                          setSelected([item.id]);
                          setOpen(true);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                     <Tooltip title="Edit">
                      <IconButton  onClick={()=>{navigate("/admin/practice-form/"+item.id);}}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                     <Tooltip title="Test">
                      <IconButton  onClick={()=>{navigate("/admin/test/"+item.id);}}>
                      <Check/>
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              ))}


             


            </tbody>
          </table>
        </CardBody>
        <PaginationButtons
          data={items}
          label="alıştırma"
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </Card>
      </>
  );
};

export default PracticeTable;
