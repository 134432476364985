import React, { useState } from "react";
import style from "./Lessons.module.scss";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import DashboardHeader from "../../common/Headers/DashboardHeader";
import Page from "../../../layout/Page/Page";
import classPathsLogo1 from "../../../../src/assets/lessons/classPathsLogo1.svg";
import classPathsLogo2 from "../../../../src/assets/lessons/classPathsLogo2.svg";
import pathsLogo1 from "../../../../src/assets/lessons/pathsLogo1.svg";
import pathsLogo2 from "../../../../src/assets/lessons/pathsLogo2.svg";
import announce1 from "../../../../src/assets/lessons/announce1.svg";
import announce2 from "../../../../src/assets/lessons/announce2.svg";
import { useGetStudentSubscribedClassesQuery } from "../../../store/features/apiSlice";
import PathsWithClass from "./pathWithClass/PathsWithClass";
import LiveLessonForStudent from "./liveLessonForStudents/LiveLessonForStudent";
import PathsWithoutClassWrapper from "./pathWithoutClass/paths-without-class-wrapper/PathsWithoutClassWrapper";
import { useNavigate } from "react-router-dom";

const Lessons = () => {
  const navigate = useNavigate()
  const { data} = useGetStudentSubscribedClassesQuery();
  const subscribedStudent = data && Object.keys(data).length !== 0;

  const _activeTab = sessionStorage.getItem("activeTab");
  const [activeTab, setActiveTab] = useState(Number(_activeTab) || 1);

  const tabContents = [
    {
      content: <PathsWithoutClassWrapper />,
      tabName: "Öğrenme Patikaları",
      icon1: pathsLogo1,
      icon2: pathsLogo2,
    },
    {
      content: <PathsWithClass/>,
      tabName: "Sınıfımın Patikaları",
      icon1: classPathsLogo1,
      icon2: classPathsLogo2,
    },
    {
      content: <LiveLessonForStudent/>,
      tabName: "Canlı Ders",
      icon1: announce1,
      icon2: announce2,
    },
  ];

  const activeTabContents = subscribedStudent
    ? tabContents
    : tabContents.slice(0, 1);

  const handleTabClick = (tabNumber) => {
    if (tabNumber >= 1 && tabNumber <= tabContents.length) {
    sessionStorage.setItem("activeTab", tabNumber);
    setActiveTab(tabNumber);}
  };

  // useEffect(() => {
  //   if (window.Tawk_API) {
  //     window.Tawk_API.hideWidget();
  //   }
  //   return () => {
  //     if (window.Tawk_API) {
  //       window.Tawk_API.showWidget();
  //     }
  //   };
  // }, []);
  return (
    <PageWrapper title={"Dersler"}>
      <DashboardHeader />
      <Page container={false} style={{ padding: "0" }} className={`${style.pageContainer}`}>
        <div
          className={`${style.tabContainer} d-flex justify-content-evenly align-items-center mx-auto mb-5 px-3 gap-1`}
        >
          {activeTabContents.map((tab, index) => (
            <div
              key={index}
              className={`${style.tab} ${
                activeTab === index + 1 ? `${style.activetab}` : ""
              } d-flex justify-content-center align-items-center gap-2 p-3`}
              onClick={() => {handleTabClick(index + 1); navigate("/lessons")}}
            >
              <div className={`${style.tabsLogo}`}>
                <img
                  src={activeTab === index + 1 ? tab.icon2 : tab.icon1}
                  alt=""
                />
              </div>
              <div className={`${style.tabsDescription}`}>
                <span>{tab.tabName}</span>
              </div>
            </div>
          ))}
        </div>
         <div className="tab-content">
            {tabContents[activeTab - 1] && tabContents[activeTab - 1]?.content}
          </div>
      </Page>
    </PageWrapper>
  );
};

export default Lessons;
