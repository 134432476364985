import React from "react";
import style from "./ActiveClasses.module.scss";
import Card from "../../../../../components/bootstrap/Card";
import { useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";
import { useGetSchoolClassesQuery } from "../../schoolManagerApi";

const ActiveClasses = () => {
  const navigate = useNavigate();
  const { data: activeClass } = useGetSchoolClassesQuery();

  return (
    <Card className={`${style.cardContainer}`}>
      <div className={`${style.title} pt-2 my-2`}>
        <span className={`ms-5`} onClick={() => navigate("/schoolManager/classes")}>
          Aktif Sınıflarım
        </span>
      </div>
      <div className={`${style.customTable} px-4`}>
        <div
          className={`${style.customHeadContainer} d-flex align-items-center justify-content-between my-1 px-2`}
        >
          <div className={`${style.customTableHead1} ${style.customTableHead}`}>
            Sınıf Adı
          </div>
          <div className={`${style.customTableHead2} ${style.customTableHead}`}>
            Öğretmen adı
          </div>
        </div>

        <div className={`${style.customRowContainer}`}>
          {activeClass && activeClass.length === 0 ? (
            <div className={`${style.emptyTable}`}>
              <span>Aktif sınıf bulunamadı</span>
            </div>
          ) : (
            activeClass &&
            activeClass.map((item, i) => (
              <div
                key={nanoid()}
                className={`${style.customTableRow} ${
                  i % 2 === 0 && style.customBackgroundColor
                } d-flex align-items-center justify-content-between mb-3 px-2 cursor-pointer`}
                onClick={() =>
                  navigate(`/schoolManager/class/${item.id}`, {
                    state: { classId: item.id },
                  })
                }
              >
                <div
                  className={`${style.customTableColumn1} ${style.tableColumn}`}
                >
                  <div className={`${style.line}`}></div>
                  <div>{item.name}</div>
                </div>
                <div
                  className={`${style.customTableColumn2} ${style.tableColumn}`}
                >
                  {/* <div>{item.teacherName}</div> */}
                  <div>
                    <div className={`${style.titleContainer}`}>
                      <div className={`${style.titleShort}`}>
                        {item.teacher_name}
                      </div>
                      <div className={`${style.titleFull}`}>
                        {item.teacher_name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </Card>
  );
};

export default ActiveClasses;
