import React, { useEffect, useState } from "react";
import Card from "../../../../components/bootstrap/Card";
import PaginationButtons, {
  dataPagination,
  PER_COUNT,
} from "../../../../components/PaginationButtons";
import useSortableData from "../../../../hooks/useSortableData";
import useDarkMode from "../../../../hooks/useDarkMode";
import style from "./ClassManagement.module.scss";
import {useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import useSelectTable from "../../../../hooks/useSelectTable";
import plus from "../../../../assets/dashboardImages/plus.svg";
import Icon from "../../../../components/icon/Icon";
import { IconButton, Tooltip } from "@mui/material";
import { Edit } from "@mui/icons-material";
import showNotification from "../../../../components/extras/showNotification";
import Confirm from "../../../common/Confirm";
import Checks from "../../../../components/bootstrap/forms/Checks";
import Search from "../../teacher/class/search";
import ConfirmButton from "../../teacher/components/confirm-button";
import SchoolManagerHeader from "../dashboard/school-manager-header/SchoolManagerHeader";
import {
  useCreateClassMutation,
  useDeleteClassMutation,
  useGetClassesQuery,
  useUpdateClassMutation,
} from "../../teacher/teacherApi";
import ClassForm from "./ClassForm";
import USER_ROLES from "../../../../common/data/enumUserRole";
import { setSelectedClass } from "../../teacher/teacherSlice";
import { getCustomErrorMessage, handleErrorMessage } from "../../../../utils/customErrors";

const SchoolManagerClassTableForAdmin = ({ isFluid }) => {
  const role = localStorage.getItem("roles");
  const isAdmin = Number(role) === USER_ROLES.ADMIN.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const schoolName = localStorage.getItem("schoolName")
  const schoolLogo = localStorage.getItem("schoolLogo")
  const { schoolId } = useParams();
  const {darkModeStatus } = useDarkMode();
  const [edit, setEdit] = useState(false);
  const [classes, setClasses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalStatus, setModalStatus] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [perPage, setPerPage] = useState(PER_COUNT["10"]);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  
  const [deleteClass] =
    useDeleteClassMutation();
  const [
    createClass,
    { isError: createError, error: createErrorData },
  ] = useCreateClassMutation();
  const [
    updateClass,
    { isError: updateError, error: updateErrorData },
  ] = useUpdateClassMutation();
  const activeSchool = `school=${schoolId}`
    const { data: classesData, isSuccess } = useGetClassesQuery(activeSchool);
    const currentClassesData = classesData?.filter((item)=>item.school === Number(schoolId))

  const [filteredClasses, setFilteredClasses] = useState([]);
  const { items } = useSortableData(
    filteredClasses.length > 0 ? filteredClasses : currentClassesData ? currentClassesData : []
  );
  const onCurrentPageData = dataPagination(items, currentPage, perPage);
  const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageData);

  useEffect(() => {
    filterClasses();
    // eslint-disable-next-line
  }, [searchValue]);

    useEffect(() => {
      console.log('%cteacher-class-table.js line:45 classesData', 'color: blue;', classesData);
      if (isSuccess) {
        setClasses(classesData);
      }
    }, [classesData, isSuccess]);

    useEffect(() => {
      if(createError){
        const customError = handleErrorMessage(createErrorData.data)
        ShowError(getCustomErrorMessage(customError));
      }
      // eslint-disable-next-line
    }, [createError]);

    useEffect(() => {
      if(updateError){
        const customError = handleErrorMessage(updateErrorData.data)
        ShowError(getCustomErrorMessage(customError));
      }
      // eslint-disable-next-line
    }, [updateError]);

  const handleSearchChange = (newValue) => {
    setSearchValue(newValue);
  };

  const handleSaveClass = async (data) => {
    console.log(data.id > 0);
    if (data.id > 0) {
      updateClass(data);
    } else {
      createClass(data);
    }
  };

  const handleDelete = () => {
    if (selected.length > 0) {
      selected.forEach((item) => handleDeleteClass(item));
    }
    setOpen(false);
    setSelected([]);
  };
  const handlCancel = () => setOpen(false);

  useEffect(() => {
    if (selectTable.values.selectedList) {
      setSelected(selectTable.values.selectedList);
    }
  }, [selectTable.values.selectedList]);

  const handleDeleteClass = (id) => {
    deleteClass(id);
  };

  const getStateText = (state) => {
    switch (state) {
      case 1:
        return "Aktif";
      case 2:
        return "Pasif";
      case 3:
        return "Tamamlandı";
      default:
        return "-";
    }
  };

  const openClassForm = (selectedClass) => {
    dispatch(setSelectedClass(selectedClass));
    setModalStatus(true);
  };
  const editClassForm = (selectedClass) => {
    dispatch(setSelectedClass(selectedClass));
    setModalStatus(true);
    setEdit(true);
  };

  const filterClasses = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const newClasses =
      classes &&
      classes.filter(
        (item) =>
          (item.name && item.name.toLowerCase().includes(searchValue)) ||
          (item.school_title &&
            item.school_title.toLowerCase().includes(searchValue))
      );
    setFilteredClasses(newClasses);
  };
  return (
    <>
      <Card
        className={`${style.cardContainer} ${darkModeStatus && style.darkMode}`}
      >
        <Confirm
          open={open}
          setOpen={setOpen}
          onConfirm={handleDelete}
          onCancel={handlCancel}
        />
        {selected.length > 0 ? (
          <button
            className={`${style.allDeleteButton} btn btn-danger`}
            onClick={() => {
              setSelected(selected);
              setOpen(true);
            }}
          >
            Seçilenleri Sil
          </button>
        ) : (
          <button
            style={{ display: "none" }}
            onClick={() => {
              setSelected(selected);
              setOpen(true);
            }}
          >
            Seçilenleri Sil
          </button>
        )}
        <div className={`${style.schoolManagerHeader}`} style={{zIndex:"999"}}>
          <SchoolManagerHeader schoolName={schoolName} logo={schoolLogo}/>
        </div>
        <ClassForm
          saveClass={handleSaveClass}
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
          edit={edit}
          setEdit={setEdit}
          selectedSchoolId={schoolId}
        />
        <div className={`${style.actionsContainer} px-2 mx-4 gap-3 mx-auto`}>
          <div className={`${style.inputContainer} `}>
            <Search
              handleSearchChange={handleSearchChange}
              iconColor={"#5454544F"}
            />
          </div>
          <div
            className={`${style.addClassContainer} d-flex align-items-center gap-2 ps-2`}
            onClick={() => openClassForm(null)}
          >
            <span>
              <img src={plus} alt="" />
            </span>
            <span>Sınıf Ekle</span>
          </div>
        </div>

        <div className={`${style.customTable} mt-5 px-4`}>
          <div
            className={`${style.customHeadContainer} d-flex align-items-center my-3 px-2`}
          >
            <div className={`${style.checkContainer} ${style.customTableHead}`}>
              {SelectAllCheck}
            </div>
            <div
              className={`${style.customTableHead1} ${style.customTableHead}`}
            >
              Sınıf
            </div>
            <div
              className={`${style.customTableHead2} ${style.customTableHead}`}
            >
              Öğretmen
            </div>
            <div
              className={`${style.customTableHead3} ${style.customTableHead} text-center`}
            >
              Kayıtlı Öğrenci
            </div>
            <div
              className={`${style.customTableHead4} ${style.customTableHead} text-center`}
            >
              Durum
            </div>
            <div
              className={`${style.customTableHead5} ${style.customTableHead} text-center`}
            >
              Eylemler
            </div>
          </div>
          <div className={`${style.customRowContainer}`}>
            {items.length === 0 ? (
              <div>
                <span>Kayıtlı sınıf bulunamadı</span>
              </div>
            ) : (
              dataPagination(items, currentPage, perPage).map((item, i) => (
                <div
                  key={item.id}
                  className={`${style.customTableRow} ${
                    i % 2 === 0 && style.customBackgroundColor
                  } d-flex align-items-center mt-1 px-2`}
                >
                  <div className={`${style.checkContainer}`}>
                    <Checks
                      id={item.id.toString()}
                      name="selectedList"
                      value={item.id}
                      onChange={selectTable.handleChange}
                      checked={selectTable.values.selectedList.includes(
                        item.id.toString()
                      )}
                    />
                  </div>
                  <div
                    className={`${style.customTableColumn1} cursor-pointer`}
                    onClick={() =>
                      navigate(
                        isAdmin
                          ? `/admin/class/${item.id}`
                          : `/schoolManager/class/${item.id}`,
                        {
                          state: { classId: item.id},
                        }
                      )
                    }
                  >
                    <div className={`${style.titleContainer}`}>
                      <div className={`${style.titleShort}`}>{item.name}</div>
                      <div className={`${style.titleFull}`}>{item.name}</div>
                    </div>
                  </div>
                  <div className={`${style.customTableColumn2}`}>
                    <div className="d-flex">
                      <div className={`${style.schoolContainer}`}>
                        <div className={`${style.schoolShort}`}>{`${
                          item.teacher_name || "-"
                        }`}</div>
                        <div className={`${style.schoolFull}`}>{`${
                          item.teacher_name || "-"
                        }`}</div>
                      </div>
                    </div>
                  </div>
                  <div className={`${style.customTableColumn3} text-center`}>
                    <div className="d-flex justify-content-center align-items-center gap-2">
                      <Icon
                        icon="School"
                        size="2x"
                        style={{
                          opacity: "0.3",
                          color:
                            darkModeStatus && i % 2 !== 0 ? "white" : "black",
                        }}
                      />
                      <span>{item.student_count}</span>
                    </div>
                  </div>

                  <div className={`${style.customTableColumn4} text-center`}>
                    <div
                      className="mx-auto py-1"
                      style={{
                        background: item.state === 1 ? "#0fa958" : "#D9D9D9",
                      }}
                    >
                      {getStateText(item.state)}
                    </div>
                  </div>

                  {/* Eylemler */}
                  <div
                    className={`${style.customTableColumn5} d-flex justify-content-center mx-auto`}
                  >
                    <ConfirmButton
                      handleClick={handleDeleteClass}
                      parameter={item.id}
                    />

                    <Tooltip title="Edit">
                      <IconButton onClick={() => editClassForm(item)}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <PaginationButtons
          data={items}
          label="sınıf"
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </Card>
    </>
  );
};

export default SchoolManagerClassTableForAdmin;

function ShowError(message) {
  showNotification(
    "Hata",
    <span className="d-flex align-items-center">
      <Icon icon="Info" size="lg" className="me-1" />
      <span>{message}</span>
    </span>,
    "danger"
  );
}
