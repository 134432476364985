import React from 'react'
import Page from '../../../../../layout/Page/Page'
import GuidanceListTable from './2-GuidanceListTable'

const GuidanceManagement = () => {
  return (
    <>
    <Page container="fluid">
      <GuidanceListTable/>
    </Page>
  </>
  )
}

export default GuidanceManagement