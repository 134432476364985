import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Card, { CardBody } from "./bootstrap/Card";
import useDarkMode from "../hooks/useDarkMode";
import USER_SUBSCRIPTION_TYPE from "../common/data/enumUserSubscriptionType";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const PracticeItem = ({
  learning_path_id,
  practice,
  text,
  status,
  img,
  className,
  imgWidth,
  practiceSubscriptionType,
  teacher=null,
}) => {
  const userSubscriptionType = localStorage.getItem("subscription_type");
  //   const userSubscriptionType = 1;
  // console.log("practiceItem practice", practice)
  const { darkModeStatus } = useDarkMode();

  const _status =
    (status === 2 && "success") ||
    (status === 1 && "warning") ||
    (status === 0 && "info");

  const statusText =
    (status === 2 && "Tamamlandı") ||
    (status === 1 && "Devam Et") ||
    (status === 0 && "Başla");

  const navigate = useNavigate();

  const handleNavigation = (path, activeTab) => {
    navigate(`${path}?activeTab=${activeTab}`);
  };

  const isMobile = useMediaQuery({ maxWidth: 450 });
  // const myStyle = {
  //   fontSize: '8px !important',
  //   // border: 'solid',
  // }
  const myStyle = `
    font-size: 10px !important;
    /* Diğer stiller */
  `;
  return (
    <Card className={className}>
      <CardBody>
        <div className="row g-3 align-items-center">
          <div className="col d-flex align-items-center">
            <div className="flex-shrink-0">
              <div
                className="ratio ratio-1x1"
                style={{ width: isMobile ? 40 : 72 }}
              >
                <div
                  className={classNames(
                    "rounded-2",
                    "d-flex align-items-center justify-content-center",
                    {
                      "bg-l10-info": !darkModeStatus,
                      "bg-lo25-info": darkModeStatus,
                    }
                  )}
                  // style={{transform:`${isMobile ? "scale(50%)" : "none"}`}}
                >
                  <img
                    src={img}
                    alt="Bank Of America"
                    width={isMobile ? "20px" : imgWidth}
                    height={isMobile ? "20px" : "50px"}
                  />
                </div>
              </div>
            </div>
            <div className="flex-grow-1 ms-3 d-flex justify-content-between align-items-center">
              <div>
                <div
                  className={`fw-bold fs-6 mb-0`}
                  style={{ cssText: isMobile && myStyle }}
                >
                  {text}
                </div>
                <div
                  className="text-muted"
                  style={{ cssText: isMobile && myStyle }}
                >
                  <small>
                    Seviye: <span className="text-normal fw-bold">Kolay</span>
                  </small>
                </div>
              </div>
            </div>
          </div>
          {Number(userSubscriptionType) !== USER_SUBSCRIPTION_TYPE.FREE.id ? (
            <div className="col-auto">
              <Link to={`/paths/${teacher ? "teacher" : learning_path_id}/practice/${practice}`}>
                <div
                  className={classNames(
                    `bg-l${
                      darkModeStatus ? "o25" : "10"
                    }-${_status} text-${_status} fw-bold px-3 py-2 rounded-pill`
                  )}
                  style={{ cssText: isMobile && myStyle }}
                >
                  {statusText}
                </div>
              </Link>
            </div>
          ) : Number(practiceSubscriptionType) ===
            USER_SUBSCRIPTION_TYPE.FREE.id ? (
            <div className="col-auto">
              <a href={`/paths/${teacher ? "teacher" : learning_path_id}/practice/${practice}`}>
                <div
                  className={classNames(
                    `bg-l${
                      darkModeStatus ? "o25" : "10"
                    }-${_status} text-${_status} fw-bold px-3 py-2 rounded-pill`
                  )}
                  style={{ cssText: isMobile && myStyle }}
                >
                  {statusText}
                </div>
              </a>
            </div>
          ) : (
            <div
              className="col-auto"
              onClick={() => handleNavigation(`/profile`, "Account Details")}
            >
              <div
                className={classNames(
                  `bg-l${
                    darkModeStatus ? "o25" : "10"
                  } fw-bold px-3 py-2 rounded-pill bg-danger text-white cursor-pointer`
                )}
                style={{
                  cssText: isMobile && myStyle,
                  textDecoration: "underline",
                }}
              >
                Planı Yükselt
              </div>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};
PracticeItem.propTypes = {
  status: PropTypes.oneOf([0, 1, 2]).isRequired,
  img: PropTypes.string,
  className: PropTypes.string,
  imgWidth: PropTypes.number,
};
PracticeItem.defaultProps = {
  className: null,
  imgWidth: 150,
};

export default PracticeItem;
