import React, { useEffect } from "react";
import { useGetUserSubscriptionTypeQuery } from "../../../store/features/apiSlice";

const SuccessPayment = () => {
  const { data, isSuccess } =
    useGetUserSubscriptionTypeQuery();
  const newSubscriptionType = data && data;

  const setSubscriptionType = () => {
    switch (newSubscriptionType) {
      case 2:
        localStorage.setItem("subscription_type", 2);
        break;
      case 3:
        localStorage.setItem("subscription_type", 3);
        break;
      default:
    }
  };

  useEffect(() => {
    if (data) {
      setSubscriptionType();
    } else {
    }
    //eslint-disable-next-line
  }, [isSuccess]);
  return (
    <div className="mx-auto mt-5">
      <div className="d-flex justify-content-center align-items-center my-5">
        <img className="thumb" src="/images/friday-happy.gif" alt="tebrikler" />
        <h1>Ödemeniz başarıyla tamamlandı</h1>
      </div>
      <div className="row">
        <div className="col-7 mx-auto text-center">
          <p style={{ fontSize: "20px" }}>
            Sizi aramızda görmekten mutluyuz.{" "}
            <span className="h3">Talent14</span> ile eğitim yolculuğunuza
            yandaki menüden devam edebilir, bilginin tadını çıkarabilirsiniz!
          </p>
        </div>
        <div className="col-6 mx-auto mt-2">
          <h4>
            “Eğitim dünyayı değiştirmek için kullanabileceğiniz en güçlü
            silahtır.” <h4 className="fw-bold text-end">Nelson Mandela</h4>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default SuccessPayment;
