import React from "react";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../layout/Page/Page";
import DashboardHeaderTitle from "../../../common/Headers/DashboardHeaderWithTitle";
import SchoolManagerHeader from "../dashboard/school-manager-header/SchoolManagerHeader";
import ClassDetailInformation from "./class-detail-information/ClassDetailInformation";
import Students from "./students/Students";
import LeaderBoard from "./leader-board/LeaderBoard";
import Homeworks from "./homeworks/Homeworks";
import HomeWorkCompleted from "./homeworks/HomeWorkCompleted";
import AttendanceRate from "./attendance/attendance-rate/AttendanceRate";
import Attendance from "./attendance/Attendance";

const ClassDetails = () => {
  const schoolName = localStorage.getItem("schoolName")
  const schoolLogo = localStorage.getItem("schoolLogo")
  return (
    <PageWrapper>
      <Page>
	  <DashboardHeaderTitle />
    <div className="row">
      {/* Welcome banner */}
      <SchoolManagerHeader  schoolName={schoolName} logo={schoolLogo}/>

      {/* Information */}
      <ClassDetailInformation />

      {/* Active classes and students */}
      <div className="col-12 col-lg-6 mt-5">
          <Students />
        </div>
      <div className="col-12 col-lg-6 mt-5">
      <LeaderBoard />
        </div>
        
      {/* Homeworks and last 10 days activities */}
      <div className="col-12 col-lg-4 mt-5">
          <Homeworks />
        </div>
      <div className="col-12 col-lg-4 mt-5">
          <HomeWorkCompleted />
        </div>
      <div className="col-12 col-lg-4 mt-5">
      <AttendanceRate />
        </div>
        
        {/* Attandenca */}
      <div className="col-12 mt-5">
      <Attendance />
        </div>
    </div>
	  </Page>
    </PageWrapper>
  );
};

export default ClassDetails;
