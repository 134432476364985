import React from 'react';
import style from './TalentInfoStudent.module.scss';

const TalentInfoStudent = ({ startDate, endDate, type, score }) => {
  return (
    <div className={`${style.talentInfoContainer} row`}>
    <div className={`${style.talentInfoItem} col-3`}>
      <span className={style.label}>Başlangıç:</span>
      <span className={style.talentInfoContent}>{startDate}</span>
    </div>
    
    <div className={`${style.talentInfoItem} col-3`}>
      <span className={style.label}>Bitiş:</span>
      <span className={style.talentInfoContent}>{endDate}</span>
    </div>
  
    <div className={`${style.talentInfoItem} col-3`}>
      <span className={style.label}>Tür:</span>
      <span className={style.talentInfoContent}>{type}</span>
    </div>
    
    <div className={`${style.talentInfoItem} col-3`}>
      <span className={style.label}>Yarışma Puanı:</span>
      <span className={style.talentInfoContent}>{score}</span>
    </div>
  </div>
  
  );
};

export default TalentInfoStudent;
