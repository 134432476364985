import React, { Suspense, lazy, useCallback, useContext, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Login from "../../pages/presentation/auth/Login";
import CreatePassword from "../../pages/presentation/auth/create-password/CreatePassword";
import { AuthContext } from "../../store/auth-store";
import USER_ROLES from "../../common/data/enumUserRole";
import ForgotPassword from "../../pages/presentation/auth/forgot-password/ForgotPassword";
import ResetPassword from "../../pages/presentation/auth/reset-password/ResetPassword";

// !YA COMMON
// const LearninnPathsWrapper = lazy(() => import("../../pages/t14/paths/LearningPathWrapper.js"));
// const Practice = lazy(() => import("../../pages/t14/practice/Practice"));
// const PracticeWithoutLearningPath = lazy(() => import("../../pages/t14/practice/PracticeWithoutLearningPath.js"));
// const PracticeForTeacher = lazy(() => import("../../pages/t14/practice/PracticeForTeacher.js"));
// const LearningPath = lazy(() => import("../../pages/t14/learning-path-practices/LearningPath"));
// const StudentContentWrapper = lazy(() => import("../../pages/t14/student/student content wrapper/StudentContentWrapper"));
// const Lessons = lazy(() => import("../../pages/t14/lessons/Lessons"));
// const AllLessonsAndContent = lazy(() => import("../../pages/t14/lessonsAndContents/2-lessonsContent/1-LessonContent"));
// const SuccessfulRegister = lazy(() => import("../../pages/presentation/auth/SuccessfulRegister"));
// const ProfilPage = lazy(() => import("../../pages/t14/profilPage/ProfilPage"));

// const SuccessPayment = lazy(() => import("../../pages/t14/payment/SuccessPayment"));
// const FailPayment = lazy(() => import("../../pages/t14/payment/FailPayment"));
// const Payment = lazy(() => import("../../pages/t14/payment/Payment"));

// const ParentReport = lazy(() => import("../../pages/t14/reports/parentReports/ParentReport"));

import LearninnPathsWrapper from "../../pages/t14/paths/LearningPathWrapper.js";
import Practice from "../../pages/t14/practice/Practice";
import PracticeWithoutLearningPath from "../../pages/t14/practice/PracticeWithoutLearningPath.js";
import PracticeForTeacher from "../../pages/t14/practice/PracticeForTeacher.js";
import LearningPath from "../../pages/t14/learning-path-practices/LearningPath";
import StudentContentWrapper from "../../pages/t14/student/student content wrapper/StudentContentWrapper";
import Lessons from "../../pages/t14/lessons/Lessons";
import AllLessonsAndContent from "../../pages/t14/lessonsAndContents/2-lessonsContent/1-LessonContent";
import SuccessfulRegister from "../../pages/presentation/auth/SuccessfulRegister";
import ProfilPage from "../../pages/t14/profilPage/ProfilPage";

import SuccessPayment from "../../pages/t14/payment/SuccessPayment";
import FailPayment from "../../pages/t14/payment/FailPayment";
import Payment from "../../pages/t14/payment/Payment";

import ParentReport from "../../pages/t14/reports/parentReports/ParentReport";

// -- Ya coomon

// ADMIN
// const AdminLearningPaths = lazy(() => import("../../pages/t14/admin/learning-paths/AdminLearningPaths"));
// const AdminLearningPathContent = lazy(() => import("../../pages/t14/admin/learning-paths/AdminLearningPathContent"));
// const AdminAddPracticeToLearningPath = lazy(() => import("../../pages/t14/admin/learning-paths/AdminAddPracticeToLearningPath"));
// const AdminPractices = lazy(() => import("../../pages/t14/admin/practices/AdminPractices"));
// const AdminPracticeForm = lazy(() => import("../../pages/t14/admin/practices/forms/AdminPracticeForm"));
// const Test = lazy(() => import("../../pages/t14/admin/test/Test"));
// const AdminDashboard = lazy(() => import("../../pages/t14/admin/dashboard/AdminDashboard"));
// const AdminPracticeFormResult = lazy(() => import("../../pages/t14/admin/practices/forms/AdminPracticeFormResult"));
// const SystemSettings = lazy(() => import("../../pages/t14/admin/settings/SystemSettings"));
// const UserManagement = lazy(() => import("../../pages/t14/admin/user management/1-UserManagement"));
// const CourseManagement = lazy(() => import("../../pages/t14/admin/course management/1-CourseManagement"));
// const UpdateCourse = lazy(() => import("../../pages/t14/admin/course management/UpdateCourse/UpdateCourse"));
// const UpdateChapter = lazy(() => import("../../pages/t14/admin/course management/UpdateChapter/UpdateChapter"));
// const PathManagement = lazy(() => import("../../pages/t14/admin/path management/1-PathManagement"));
// const SchoolManagement = lazy(() => import("../../pages/t14/admin/school management/1-SchoolManagement"));
// const H5PContent = lazy(() => import("../../pages/t14/admin/course management/h5p/ContentWrapper"));
// const TeacherCertificate = lazy(() => import("../../pages/t14/admin/dashboard/teacher-approvement/TeacherCertificate"));
// const CourseManagementForTeacherWrapper = lazy(() => import("../../pages/t14/admin/course management for teacher/CourseManagementForTeacherWrapper"));
// const TeacherUpdateCourse = lazy(() => import("../../pages/t14/admin/course management for teacher/teacherCourseManagement/UpdateCourse/TeacherUpdateCourse"));
// const TeacherUpdateChapter = lazy(() => import("../../pages/t14/admin/course management for teacher/teacherCourseManagement/UpdateChapter/TeacherUpdateChapter"));
// const UpdatePresentation = lazy(() => import("../../pages/t14/admin/course management for teacher/presentation/UpdatePresentation/UpdatePresentation"));
// const UpdateGuidance = lazy(() => import("../../pages/t14/admin/course management for teacher/guidance/UpdateGuidance/UpdateGuidance"));
// const AdminContentManagement = lazy(() => import("../../pages/t14/admin/content management/ContentManagementWrapper"));
// const AdminTalentCupWrapper = lazy(() => import("../../pages/t14/admin/talent cup/TalentCupWrapper.js"));
// const AdminTalentCupDetail = lazy(() => import("../../pages/t14/admin/talent cup/competition detail/CompetitionDetail.js"));
// const CompetitionJoinedStudents = lazy(() => import("../../pages/t14/admin/talent cup/competition joined students/CompetitionJoinedStudents.js"));
// const Competition = lazy(() => import("../../pages/t14/admin/talent cup/competition/Competition.js"));
// const DiscountCouponWrapper = lazy(() => import("../../pages/t14/admin/discount coupon/DiscountCouponWrapper.js"));
// const DiscountCouponDetail = lazy(() => import("../../pages/t14/admin/discount coupon/Coupon_Detail/CouponDetail.js"));

import AdminLearningPaths from "../../pages/t14/admin/learning-paths/AdminLearningPaths";
import AdminLearningPathContent from "../../pages/t14/admin/learning-paths/AdminLearningPathContent";
import AdminAddPracticeToLearningPath from "../../pages/t14/admin/learning-paths/AdminAddPracticeToLearningPath";
import AdminPractices from "../../pages/t14/admin/practices/AdminPractices";
import AdminPracticeForm from "../../pages/t14/admin/practices/forms/AdminPracticeForm";
import Test from "../../pages/t14/admin/test/Test";
import AdminDashboard from "../../pages/t14/admin/dashboard/AdminDashboard";
import AdminPracticeFormResult from "../../pages/t14/admin/practices/forms/AdminPracticeFormResult";
import SystemSettings from "../../pages/t14/admin/settings/SystemSettings";
import UserManagement from "../../pages/t14/admin/user management/1-UserManagement";
import CourseManagement from "../../pages/t14/admin/course management/1-CourseManagement";
import UpdateCourse from "../../pages/t14/admin/course management/UpdateCourse/UpdateCourse";
import UpdateChapter from "../../pages/t14/admin/course management/UpdateChapter/UpdateChapter";
import PathManagement from "../../pages/t14/admin/path management/1-PathManagement";
import SchoolManagement from "../../pages/t14/admin/school management/1-SchoolManagement";
import H5PContent from "../../pages/t14/admin/course management/h5p/ContentWrapper";
import TeacherCertificate from "../../pages/t14/admin/dashboard/teacher-approvement/TeacherCertificate";
import CourseManagementForTeacherWrapper from "../../pages/t14/admin/course management for teacher/CourseManagementForTeacherWrapper";
import TeacherUpdateCourse from "../../pages/t14/admin/course management for teacher/teacherCourseManagement/UpdateCourse/TeacherUpdateCourse";
import TeacherUpdateChapter from "../../pages/t14/admin/course management for teacher/teacherCourseManagement/UpdateChapter/TeacherUpdateChapter";
import UpdatePresentation from "../../pages/t14/admin/course management for teacher/presentation/UpdatePresentation/UpdatePresentation";
import UpdateGuidance from "../../pages/t14/admin/course management for teacher/guidance/UpdateGuidance/UpdateGuidance";
import AdminContentManagement from "../../pages/t14/admin/content management/ContentManagementWrapper";
import AdminTalentCupWrapper from "../../pages/t14/admin/talent cup/TalentCupWrapper.js";
import AdminTalentCupDetail from "../../pages/t14/admin/talent cup/competition detail/CompetitionDetail.js";
import CompetitionJoinedStudents from "../../pages/t14/admin/talent cup/competition joined students/CompetitionJoinedStudents.js";
import Competition from "../../pages/t14/admin/talent cup/competition/Competition.js";
import DiscountCouponWrapper from "../../pages/t14/admin/discount coupon/DiscountCouponWrapper.js";
import DiscountCouponDetail from "../../pages/t14/admin/discount coupon/Coupon_Detail/CouponDetail.js";


// --Admin

// TEACHER
// const TeacherClassesWrapper = lazy(() => import("../../pages/t14/teacher/class/teacher-classes-wrapper"));
// const ClassContent = lazy(() => import("../../pages/t14/teacher/class/content/class-content"));
// const TeacherStudents = lazy(() => import("../../pages/t14/teacher/teacher-student/TeacherStudents"));
// const TeacherLearningPaths = lazy(() => import("../../pages/t14/teacher/learning-path/teacher-learning-paths"));
// const TeacherDashboardWrapper = lazy(() => import("../../pages/t14/teacher/dashboard/TeacherDashboardWrapper"));
// const TeacherPractices = lazy(() => import("../../pages/t14/teacher/practices/TeacherPractices"));
// const TeacherPracticeForm = lazy(() => import("../../pages/t14/teacher/practices/forms/TeacherPracticeForm"));
// const TeacherPracticeFormResult = lazy(() => import("../../pages/t14/teacher/practices/forms/TeacherPracticeFormResult"));
// const TeacherClassPages = lazy(() => import("../../pages/t14/teacher/class-page/TeacherClassPages"));
// const TeacherStudentPageWrapper = lazy(() => import("../../pages/t14/teacher/teacher-student-page/TeacherStudentPageWrapper"));
// const TeacherHomeworkWrapper = lazy(() => import("../../pages/t14/teacher/homeworkManagement/teacher-homeworks/TeacherHomeworkWrapper"));
// const Homework = lazy(() => import("../../pages/t14/teacher/homeworkManagement/teacher-homeworks/Homework"));
// const HomeworkManagement = lazy(() => import("../../pages/t14/teacher/homeworkManagement/HomeworkManagement"));

// const TeacherWrapper = lazy(() => import("../../pages/t14/teacher/TeacherWrapper"));
// const TeacherTrail = lazy(() => import("../../pages/t14/teacher/contentManagement/trail/TeacherTrail"));
// const CoursesForTeacherWrapper = lazy(() => import("../../pages/t14/teacher/courses for teacher/CoursesForTeacherWrapper"));
// const GuidanceDetail = lazy(() => import("../../pages/t14/teacher/courses for teacher/teacherGuidance/GuidanceDetail"));
// const GuideContent = lazy(() => import("../../pages/t14/teacher/courses for teacher/teacherGuidance/GuideContent"));
// const TeacherReport = lazy(() => import("../../pages/t14/reports/teacherReports/TeacherReport"));
// const CourseContent = lazy(() => import("../../pages/t14/teacher/courses for teacher/courses/teacherCourseContent/1-CourseContent"));
// const DocumentViewerContainer = lazy(() => import("../../pages/t14/teacher/courses for teacher/teacherResource/teacherDocuments/DocumentViewerContainer "));

import TeacherClassesWrapper from "../../pages/t14/teacher/class/teacher-classes-wrapper";
import ClassContent from "../../pages/t14/teacher/class/content/class-content";
import TeacherStudents from "../../pages/t14/teacher/teacher-student/TeacherStudents";
import TeacherLearningPaths from "../../pages/t14/teacher/learning-path/teacher-learning-paths";
import TeacherDashboardWrapper from "../../pages/t14/teacher/dashboard/TeacherDashboardWrapper";
import TeacherPractices from "../../pages/t14/teacher/practices/TeacherPractices";
import TeacherPracticeForm from "../../pages/t14/teacher/practices/forms/TeacherPracticeForm";
import TeacherPracticeFormResult from "../../pages/t14/teacher/practices/forms/TeacherPracticeFormResult";
import TeacherClassPages from "../../pages/t14/teacher/class-page/TeacherClassPages";
import TeacherStudentPageWrapper from "../../pages/t14/teacher/teacher-student-page/TeacherStudentPageWrapper";
import TeacherHomeworkWrapper from "../../pages/t14/teacher/homeworkManagement/teacher-homeworks/TeacherHomeworkWrapper";
import Homework from "../../pages/t14/teacher/homeworkManagement/teacher-homeworks/Homework";
import HomeworkManagement from "../../pages/t14/teacher/homeworkManagement/HomeworkManagement";

import TeacherWrapper from "../../pages/t14/teacher/TeacherWrapper";
import TeacherTrail from "../../pages/t14/teacher/contentManagement/trail/TeacherTrail";
import CoursesForTeacherWrapper from "../../pages/t14/teacher/courses for teacher/CoursesForTeacherWrapper";
import GuidanceDetail from "../../pages/t14/teacher/courses for teacher/teacherGuidance/GuidanceDetail";
import GuideContent from "../../pages/t14/teacher/courses for teacher/teacherGuidance/GuideContent";
import TeacherReport from "../../pages/t14/reports/teacherReports/TeacherReport";
import CourseContent from "../../pages/t14/teacher/courses for teacher/courses/teacherCourseContent/1-CourseContent";
import DocumentViewerContainer from "../../pages/t14/teacher/courses for teacher/teacherResource/teacherDocuments/DocumentViewerContainer.js";



// --Teacher

// STUDENT
// const LeaderboardsAndRewardsWrapper = lazy(() => import("../../pages/t14/student/leaderboardsAndRewardsWrapper/LeaderboardsAndRewardsWrapper"));
// const StudentLeaderBoard = lazy(() => import("../../pages/t14/student/leaderboardsAndRewardsWrapper/leaderBoard/StudentLeaderBoard"));
// const StudentDashboard = lazy(() => import("../../pages/t14/student/dashboard/StudentDashboard"));
// const PdfViewerContainer = lazy(() => import("../../pages/t14/student/leaderboardsAndRewardsWrapper/certificatesPage/PdfViewerContainer .js"));
// const StudentTalentCupWrapper = lazy(() => import("../../pages/t14/student/talentCup/TalentCupWrapperForStudent.js"));
// const StudentTalentCupDetail = lazy(() => import("../../pages/t14/student/talentCup/components/competition_detail/CompetitionDetailForStudent.js"));
import LeaderboardsAndRewardsWrapper from "../../pages/t14/student/leaderboardsAndRewardsWrapper/LeaderboardsAndRewardsWrapper";
import StudentLeaderBoard from "../../pages/t14/student/leaderboardsAndRewardsWrapper/leaderBoard/StudentLeaderBoard";
import StudentDashboard from "../../pages/t14/student/dashboard/StudentDashboard";
import PdfViewerContainer from "../../pages/t14/student/leaderboardsAndRewardsWrapper/certificatesPage/PdfViewerContainer.js";
import StudentTalentCupWrapper from "../../pages/t14/student/talentCup/TalentCupWrapperForStudent";
import StudentTalentCupDetail from "../../pages/t14/student/talentCup/components/competition_detail/CompetitionDetailForStudent";

// --STUDENT

// GAMES
// const Games = lazy(() => import('../../pages/t14/games/Games'));
// const CodeCizzFreeStyle = lazy(() => import("../../pages/t14/games/CodeCizz/CodeCizzFreeStyle"));

// const CodeBoo = lazy(() => import("../../pages/t14/games/GamesCodeBoo"));
// const CodeCizz = lazy(() => import("../../pages/t14/games/GamesCodeCizz"));
// const CodeArt = lazy(() => import("../../pages/t14/games/GamesCodeArt"));
// const CodeArtFreeStyle = lazy(() => import("../../pages/t14/games/CodeArt/CodeArtFreeStyle"));
// const GameSections = lazy(() => import("../../pages/t14/games/All_Games_With_Section/GameSections"));
// const AllGameStepsWithSection = lazy(() => import("../../pages/t14/games/All_Games_With_Section/GameStepsWithSection"));
// const GameStepWithSectionId = lazy(() => import("../../pages/t14/games/All_Games_With_Section/GameStepWithSectionId"));
// const GameStepsWithoutSection = lazy(() => import("../../pages/t14/games/All_Games_Without_Section/GameStepsWithoutSection"));
// const GameStepWithoutSectionId = lazy(() => import("../../pages/t14/games/All_Games_Without_Section/GameStepWithoutSectionId"));

import Games from '../../pages/t14/games/Games';
import CodeCizzFreeStyle from "../../pages/t14/games/CodeCizz/CodeCizzFreeStyle";

import CodeBoo from "../../pages/t14/games/GamesCodeBoo";
import CodeCizz from "../../pages/t14/games/GamesCodeCizz";
import CodeArt from "../../pages/t14/games/GamesCodeArt";
import CodeArtFreeStyle from "../../pages/t14/games/CodeArt/CodeArtFreeStyle";
import GameSections from "../../pages/t14/games/All_Games_With_Section/GameSections";
import AllGameStepsWithSection from "../../pages/t14/games/All_Games_With_Section/GameStepsWithSection";
import GameStepWithSectionId from "../../pages/t14/games/All_Games_With_Section/GameStepWithSectionId";
import GameStepsWithoutSection from "../../pages/t14/games/All_Games_Without_Section/GameStepsWithoutSection";
import GameStepWithoutSectionId from "../../pages/t14/games/All_Games_Without_Section/GameStepWithoutSectionId";


// --Games--

// const Page403 = lazy(() => import("../../pages/presentation/auth/Page403"));
// const ProtectedRoute = lazy(() => import("./ProtectedRoute"));
// const ContentManagement = lazy(() => import("../../pages/t14/teacher/contentManagement/ContentManagement"));
// const ErrorBoundaryWrapper = lazy(() => import("../../utils/ErrorBoundaryWrapper"));
// const SchoolManagerDashboard = lazy(() => import("../../pages/t14/schoolManager/dashboard/SchoolManagerDashboard"));
// const ClassManagement = lazy(() => import("../../pages/t14/schoolManager/class-management/ClassManagement"));
// const ClassDetails = lazy(() => import("../../pages/t14/schoolManager/class-details/ClassDetails"));
// const UserManagementWrapper = lazy(() => import("../../pages/t14/schoolManager/user-management/UserManagementWrapper"));
// const BannerManagementWrapper = lazy(() => import("../../pages/t14/schoolManager/banner-management/BannerManagementWrapper"));
// const ContentManagementWrapper = lazy(() => import("../../pages/t14/schoolManager/content-management/ContentManagement"));
// const UpdateAdminSchoolCourse = lazy(() => import("../../pages/t14/schoolManager/content-management/course-list-table/UpdateCourse/UpdateCourse"));
// const UpdateAdminSchoolChapter = lazy(() => import("../../pages/t14/schoolManager/content-management/course-list-table/UpdateChapter/UpdateChapter"));
// const ContentManagementForSchool = lazy(() => import("../../pages/t14/schoolManager/content-management/ContentManagement"));
// const UpdateSchoolCourse = lazy(() => import("../../pages/t14/schoolManager/content-management/course-list-table/UpdateCourse/UpdateCourse"));
// const UpdateSchoolChapter = lazy(() => import("../../pages/t14/schoolManager/content-management/course-list-table/UpdateChapter/UpdateChapter"));
// const SchoolRestrictionWrapper = lazy(() => import("../../pages/t14/admin/school management/school restrictions/SchoolRestrictionWrapper"));
// const ClassRestrictionWrapper = lazy(() => import("../../pages/t14/schoolManager/class restrictions/ClassRestrictionWrapper"));

// const PAGE_404 = lazy(() => import("../../pages/presentation/auth/Page404"));

import Page403 from "../../pages/presentation/auth/Page403";
import ProtectedRoute from "./ProtectedRoute";
import ContentManagement from "../../pages/t14/teacher/contentManagement/ContentManagement";
import ErrorBoundaryWrapper from "../../utils/ErrorBoundaryWrapper";
import SchoolManagerDashboard from "../../pages/t14/schoolManager/dashboard/SchoolManagerDashboard";
import ClassManagement from "../../pages/t14/schoolManager/class-management/ClassManagement";
import ClassDetails from "../../pages/t14/schoolManager/class-details/ClassDetails";
import UserManagementWrapper from "../../pages/t14/schoolManager/user-management/UserManagementWrapper";
import BannerManagementWrapper from "../../pages/t14/schoolManager/banner-management/BannerManagementWrapper";
import ContentManagementWrapper from "../../pages/t14/schoolManager/content-management/ContentManagement";
import UpdateAdminSchoolCourse from "../../pages/t14/schoolManager/content-management/course-list-table/UpdateCourse/UpdateCourse";
import UpdateAdminSchoolChapter from "../../pages/t14/schoolManager/content-management/course-list-table/UpdateChapter/UpdateChapter";
import ContentManagementForSchool from "../../pages/t14/schoolManager/content-management/ContentManagement";
import UpdateSchoolCourse from "../../pages/t14/schoolManager/content-management/course-list-table/UpdateCourse/UpdateCourse";
import UpdateSchoolChapter from "../../pages/t14/schoolManager/content-management/course-list-table/UpdateChapter/UpdateChapter";
import SchoolRestrictionWrapper from "../../pages/t14/admin/school management/school restrictions/SchoolRestrictionWrapper";
import ClassRestrictionWrapper from "../../pages/t14/schoolManager/class restrictions/ClassRestrictionWrapper";

import PAGE_404 from "../../pages/presentation/auth/Page404";
import { SingleCourseLessonPage } from "../../pages/t14/lessonsAndContents/singleLessonContent/SingleCourseLessonPage.js";
import { CompetitionProvider } from "../../pages/t14/admin/talent cup/CompetitionContext.js";


const ContentRoutes = () => {
  const { user, token } = useContext(AuthContext);
  const isAdmin = USER_ROLES.ADMIN.id === user?.roles || false;
  const isStudent = USER_ROLES.STUDENT.id === user?.roles || false;
  const isTeacher = USER_ROLES.TEACHER.id === user?.roles || false;
  const isSchoolManager = USER_ROLES.SCHOOL_MANAGER.id === user?.roles || false;

  const navigate = useNavigate();

  const checkAndClearLoginPath = useCallback(() => {
        const path = window.location.pathname;
    if (path === '/login' && token) {
      // Kullanıcı zaten oturum açık ve /login yazıyorsa, anasayfaya yönlendir
      navigate('/');
    }
  }, [navigate, token]);
  
  useEffect(() => {
    checkAndClearLoginPath();
  }, [token, checkAndClearLoginPath]);

  const getDashboardRoute = () => {
    return (
      <>
        <Route
          key="dashboard"
          path="/"
          element={token ? (
            isSchoolManager ? <SchoolManagerDashboard /> :
            isStudent ? <StudentDashboard /> :
            isTeacher ? <TeacherDashboardWrapper /> :
            isAdmin ? <AdminDashboard /> :
            <Login />
          ) : <Login />}
        />
        <Route
          key="dashboard-login"
          path="/login"
          element={token ? (
            isSchoolManager ? <SchoolManagerDashboard /> :
            isStudent ? <StudentDashboard /> :
            isTeacher ? <TeacherDashboardWrapper /> :
            isAdmin ? <AdminDashboard /> :
            <Login />
          ) : <Login />}
        />
      </>
    );
  };

  const LoadingSpinner = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width:'100%' }}>
      <div className="spinner" style={{
        width: '50px',
        height: '50px',
        border: '6px solid #f3f3f3',
        borderTop: '6px solid #3498db',
        borderRadius: '50%',
        animation: 'spin 1s linear infinite'
      }}></div>
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
  return (
    <Suspense fallback={<LoadingSpinner />}>
    <ErrorBoundaryWrapper>
      <Routes>
        {token ? getDashboardRoute() : <Route path="/" element={<Login />} />}
        <Route path="/login" element={<Login />} />
        <Route
          key="successfulRegister"
          path="successfulRegister"
          element={<SuccessfulRegister />}
        />
        <Route
          key="forgot-password"
          path="forgot-password"
          element={<ForgotPassword />}
        />
        <Route
          key="reset-password"
          path="reset-password/:id"
          element={<ResetPassword />}
        />
        <Route
          key="passwordless"
          path="passwordless/:id"
          element={<CreatePassword />}
        />
        <Route
          key="successPayment"
          path="successpayment"
          element={<SuccessPayment />}
        />
        <Route key="failPayment" path="failpayment" element={<FailPayment />} />
        <Route key="payment" path="payment" 
        element={
            <ProtectedRoute>
              <Payment />
            </ProtectedRoute>
          } />
        <Route key="parentReport" path="users/information/:id" element={<ParentReport />} />
        <Route key="teacherReport" path="teacherReport" element={<TeacherReport />} />

        <Route key="learningPathsWrapper" path="learning-paths-wrapper">
          <Route path="" element={<LearninnPathsWrapper />} />
          <Route path=":id" element={<ProtectedRoute path={"learning-paths-wrapper"}> <LearningPath /></ProtectedRoute>}/>
        </Route>
        <Route
          key="pathsPractice"
          path="paths/teacher/practice/:id"
          element={
            <ProtectedRoute path={"pathsPractice"}>
              <PracticeForTeacher />
            </ProtectedRoute>
          }
        />
        <Route
          key="pathsPractice"
          path="paths/:path_id/practice/:id"
          element={
            <ProtectedRoute path={"pathsPractice"}>
              <Practice />
            </ProtectedRoute>
          }
        />

        {isTeacher && (
          <Route key="teacher" path="teacher" element={<TeacherWrapper />}>
            <Route
              key="dashboard"
              path="dashboard"
              element={<TeacherDashboardWrapper />}
            />
            <Route path="classes" element={<TeacherClassesWrapper />} />
            <Route path="class-content" element={<ClassContent />} />
            <Route path="class/:classId" element={<TeacherClassPages />} />
            <Route path="students" element={<TeacherStudents />} />
            <Route
              path=":classId/student/:studentId"
              element={<TeacherStudentPageWrapper />}
            />
            <Route path="learning-paths" element={<TeacherLearningPaths />} />
            <Route path="practices" element={<TeacherPractices />} />
            <Route path="practice-form/" element={<TeacherPracticeForm />} />
            <Route path="practice-form/:id" element={<TeacherPracticeForm />} />
            <Route
              path="practice-form-result/:id"
              element={<TeacherPracticeFormResult />}
            />
            <Route
              key="teacher-homeworks"
              path="homeworks"
              element={<TeacherHomeworkWrapper />}
            />
            <Route
              key="teacher-homeworks"
              path="new-homework"
              element={<Homework />}
            />
            <Route
              key="teacher-homeworks"
              path="new-homework/:id"
              element={<Homework />}
            />
            <Route
              key="teacher-paths"
              path="teacher-paths"
              element={<TeacherTrail />}
            />
            <Route
              key="contentManagement"
              path="contentManagement"
              element={<ContentManagement />}
            />
            <Route
              key="homeworkManagement"
              path="homeworkManagement"
              element={<HomeworkManagement />}
            />
            <Route path="test/:id" element={<Test />} />
            <Route path="coursesForTeacher" element={<CoursesForTeacherWrapper />} />
            <Route path="courseContentForTeacher/:id" element={<CourseContent />} />
            <Route path="coursesForTeacher/guidances/:id" element={<GuidanceDetail />} />
            <Route path="coursesForTeacher/guidances/:id/guideContent/:guideId" element={<GuideContent />} />
             <Route
          key="showCertificate"
          path="coursesForTeacher/documents/:id"
          element={<DocumentViewerContainer  />}
        />
          </Route>
        )}
        <Route key="contents" path="studentContents" element={<StudentContentWrapper />}/>
        <Route key="lessons" path="lessons">
          <Route
            key="list"
            path=""
            element={!token ? <Navigate to="/login" /> : <Lessons />}
          />
          <Route
            path="AllLessonsAndContent/:id"
            element={
              <ProtectedRoute path={"lessons"}>
                <AllLessonsAndContent />
              </ProtectedRoute>
            }
          />
          <Route
            path="content/:id"
            element={
              <ProtectedRoute path={"lessons"}>
                <CompetitionProvider>
                <SingleCourseLessonPage/>
                </CompetitionProvider>
              </ProtectedRoute>
            }
          />
         <Route
              path="GameMapForPath/:contentName"
              element={<Lessons />}
            />
        </Route>

        {isSchoolManager && (
          <Route key="schoolManager" path="schoolManager">
            <Route path="classManagement/" element={<ClassManagement />} />
            <Route path="classManagement/:schoolId" element={<ClassManagement />} />
            <Route path="class/:classId" element={<ClassDetails />} />
            <Route path="userManagement" element={<UserManagementWrapper />} />
            <Route path="bannerManagement" element={<BannerManagementWrapper />} />
            <Route path="contentManagement" element={<ContentManagementForSchool />} />
            <Route path="updateSchoolCourse/:id" element={<UpdateSchoolCourse />} />
            <Route path="updateChapter/:id" element={<UpdateSchoolChapter />} />
            <Route path="classRestrictions" element={<ClassRestrictionWrapper />} />
          </Route>
        )}
        {isAdmin ? (
          <Route key="admin" path="admin">
            <Route
              path="h5p"
              element={<H5PContent />}
            ></Route>
            <Route
              path="learning-paths"
              element={<AdminLearningPaths />}
            ></Route>
            <Route
              path="learning-paths-content/:id"
              element={<AdminLearningPathContent />}
            ></Route>
            <Route
              path="add-practice-to-path/:id"
              element={<AdminAddPracticeToLearningPath />}
            ></Route>
            <Route path="practices" element={<AdminPractices />} />
            <Route path="practice-form/" element={<AdminPracticeForm />} />
            <Route path="practice-form/:id" element={<AdminPracticeForm />} />
            <Route
              path="practice-form-result/:id"
              element={<AdminPracticeFormResult />}
            />
            <Route path="test/:id" element={<Test />} />
            <Route path="settings" element={<SystemSettings />} />
            <Route path="userManagement" element={<UserManagement />} />
            <Route path="userManagement/school/:schoolId" element={<UserManagement />} />
            <Route path="contentManagement" element={<AdminContentManagement />} />
            <Route path="talentCup" element={<AdminTalentCupWrapper />} />
            <Route path="talentCup/CompetitionDetail/:id" element={<AdminTalentCupDetail />} />
            <Route path="talentCup/CompetitionDetail/:id/Applicants/:type" element={<CompetitionJoinedStudents />} />
            <Route path="talentCup/competition" element={<Competition />} />
            <Route path="talentCup/competition/:id" element={<Competition />} />
            <Route path="discountCoupon" element={<DiscountCouponWrapper />} />
            <Route path="discountCoupon/:id" element={<DiscountCouponDetail />} />
            <Route path="courseManagement" element={<CourseManagement />} />
            <Route path="updateCourse/:id" element={<UpdateCourse />} />
            <Route path="updateChapter/:id" element={<UpdateChapter />} />
            <Route path="pathManagement" element={<PathManagement />} />
            <Route path="schoolManagement" element={<SchoolManagement />} />
            <Route path="classManagement/:schoolId" element={<ClassManagement />} />
            <Route path="class/:classId" element={<ClassDetails />} />
            <Route path="schoolUserManagement" element={<UserManagementWrapper />} />
            <Route path="schoolContentManagement" element={<ContentManagementWrapper />} />
            <Route path="updateSchoolCourse/:id" element={<UpdateAdminSchoolCourse />} />
            <Route path="updateSchoolCourseChapter/:id" element={<UpdateAdminSchoolChapter />} />
            <Route path="schoolRestrictions" element={<SchoolRestrictionWrapper />} />
            <Route path="teacherCourseManagement" element={<CourseManagementForTeacherWrapper />} />
            <Route path="teacherUpdateCourse/:id" element={<TeacherUpdateCourse />} />
            <Route path="teacherUpdateChapter/:id" element={<TeacherUpdateChapter />} />
            <Route path="updatePresentation/:id" element={<UpdatePresentation />} />
            <Route path="updateGuidance/:id" element={<UpdateGuidance />} />
            <Route path=":id" element={<TeacherCertificate />} />
          </Route>
        ) : null}
        <Route key="*" path="*" element={<PAGE_404 />} />
        <Route path="forbidden" element={<Page403 />} />
        <Route key="profile" path="profile" element={<ProfilPage />} />

        <Route
          key="leaderBoard"
          path="leaderBoard"
          element={<StudentLeaderBoard />}
        />
        <Route key="leaderboardsAndRewards" path="leaderboardsAndRewards" element={<LeaderboardsAndRewardsWrapper />} />
        <Route
          key="showCertificate"
          path="leaderboardsAndRewards/:id"
          element={<PdfViewerContainer />}
        />
       <Route path="talentCup" element={<StudentTalentCupWrapper />} />
       <Route path="talentCup/CompetitionDetail/:id" element={<StudentTalentCupDetail />} />
       <Route
          key="pathsPractice"
          path="practice/:id"
          element={
            <ProtectedRoute path={"pathsPractice"}>
            <PracticeWithoutLearningPath />
          </ProtectedRoute>
          }
        />

        {/* GAMES */}
        <Route key="games" path="games">
          <Route
            key="list"
            path=""
            element={!token ? <Navigate to="/login" /> : <Games />}
          />
          <Route
            path=":id/sections"
            element={
              <ProtectedRoute path={"games"}>
                <GameSections />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id/sections/:sectionId/steps"
            element={
              <ProtectedRoute path={"sections"}>
                <AllGameStepsWithSection />
              </ProtectedRoute>
            }
          />
          <Route
            path=":gameId/sections/:sectionId/steps/:stepId"
            element={
              <ProtectedRoute path={"sections"}>
                <GameStepWithSectionId />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id/sections/steps"
            element={
              <ProtectedRoute path={"sections"}>
                <GameStepsWithoutSection />
              </ProtectedRoute>
            }
          />
          <Route
            path=":gameId/sections/steps/:stepId"
            element={
              <ProtectedRoute path={"sections"}>
                <GameStepWithoutSectionId />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route key="codeBoo" path="codeBoo">
          <Route
            key="list"
            path=""
            element={!token ? <Navigate to="/login" /> : <CodeBoo />}
          />
          <Route
            path=":id/sections"
            element={
              <ProtectedRoute path={"games"}>
                <GameSections />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id/sections/:sectionId/steps"
            element={
              <ProtectedRoute path={"sections"}>
                <AllGameStepsWithSection />
              </ProtectedRoute>
            }
          />
          <Route
            path=":gameId/sections/:sectionId/steps/:stepId"
            element={
              <ProtectedRoute path={"sections"}>
                <GameStepWithSectionId />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id/sections/steps"
            element={
              <ProtectedRoute path={"sections"}>
                <GameStepsWithoutSection />
              </ProtectedRoute>
            }
          />
          <Route
            path=":gameId/sections/steps/:stepId"
            element={
              <ProtectedRoute path={"sections"}>
                <GameStepWithoutSectionId />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route key="codeCizz" path="codeCizz">
          <Route
            key="list"
            path=""
            element={!token ? <Navigate to="/login" /> : <CodeCizz />}
          />
          <Route
            path=":id/sections"
            element={
              <ProtectedRoute path={"games"}>
                <GameSections />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id/sections/:sectionId/steps"
            element={
              <ProtectedRoute path={"sections"}>
                <AllGameStepsWithSection />
              </ProtectedRoute>
            }
          />
          <Route
            path=":gameId/sections/:sectionId/steps/:stepId"
            element={
              <ProtectedRoute path={"sections"}>
                <GameStepWithSectionId />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id/sections/steps"
            element={
              <ProtectedRoute path={"sections"}>
                <GameStepsWithoutSection />
              </ProtectedRoute>
            }
          />
          <Route
            path=":gameId/sections/steps/:stepId"
            element={
              <ProtectedRoute path={"sections"}>
                <GameStepWithoutSectionId />
              </ProtectedRoute>
            }
          />
          <Route
            key="freeStyle"
            path="freeStyle"
            element={!token ? <Navigate to="/login" /> : <CodeCizzFreeStyle />}
          />
        </Route>
        <Route key="codeArt" path="codeArt">
          <Route
            path=""
            element={!token ? <Navigate to="/login" /> : <CodeArt />}
          />
          <Route
            key="freeStyle"
            path="freeStyle"
            element={!token ? <Navigate to="/login" /> : <CodeArtFreeStyle />}
          />
        </Route>
        {/* Games */}
      </Routes>
    </ErrorBoundaryWrapper>
    </Suspense>
  );
};

export default ContentRoutes;
