import React, { useState } from 'react'

const HoverableItem = ({ icon }) => {
    const [isHovered, setIsHovered] = useState(false);

  return (
    <span className="iconContainer">
      {React.cloneElement(icon, {
        onMouseEnter: () => setIsHovered(true),
        onMouseLeave: () => setIsHovered(false),
        style: { transform: isHovered ? 'scale(1.2)' : 'scale(1)' }
      })}
    </span>
  );
}

export default HoverableItem