// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media screen and (max-width: 992px) {\n  .PathWithClass_pathsContainer__QGEzX {\n    position: relative;\n    bottom: 50px;\n  }\n}\n.PathWithClass_emptyTrails__LtDpd {\n  max-width: 448px;\n  height: 55px;\n  -ms-flex-negative: 0;\n      flex-shrink: 0;\n  border-radius: 25px;\n  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);\n          box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);\n  position: relative;\n}", "",{"version":3,"sources":["webpack://./src/pages/t14/lessons/pathWithClass/PathWithClass.module.scss"],"names":[],"mappings":"AAAA;EACE;IACI,kBAAA;IACA,YAAA;EACJ;AACF;AACA;EAEE,gBAAA;EACA,YAAA;EACA,oBAAA;MAAA,cAAA;EACA,mBAAA;EAEA,+CAAA;UAAA,uCAAA;EACA,kBAAA;AADF","sourcesContent":["@media screen and (max-width:992px) {\n  .pathsContainer{\n      position: relative;\n      bottom: 50px;\n  }\n}\n.emptyTrails{\n        // border: solid;\n  max-width: 448px;\n  height: 55px;\n  flex-shrink: 0;\n  border-radius: 25px;\n//   background-color: #fff;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pathsContainer": "PathWithClass_pathsContainer__QGEzX",
	"emptyTrails": "PathWithClass_emptyTrails__LtDpd"
};
export default ___CSS_LOADER_EXPORT___;
