import React from "react";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../../components/bootstrap/Modal";
import Button from "../../../../../components/bootstrap/Button";

const UserInfoWarningModal = ({openUserInfoWarningModal, setOpenUserInfoWarningModal, onConfirm}) => {
    
    return (
      <div>
        <Modal
          isOpen={openUserInfoWarningModal}
          setIsOpen={setOpenUserInfoWarningModal}
          titleId="example-title"
        >
          <ModalHeader>
            <ModalTitle id="example-title">
                {/* {title} */}
                <div className="d-flex justify-content-center align-items-center my-1">
        <img className="thumb" src="/images/friday-happy.gif" alt="tebrikler" />
        <h3>Merhaba! <br/></h3>
      </div>
                </ModalTitle>
          </ModalHeader>
          <ModalBody>
          <div className="mx-auto text-justify">
          <p style={{ fontSize: "16px" }}>
          Sınıfa katılmak için harika bir adım atıyorsun! Ancak profilinde henüz isim ve soy isim alanlarını doldurmadığını görüyoruz. Sınıfa katılıp sınıf arkadaşlarınla beraber olabilmek için profilini doldurabilir misin?
          </p>
          <p style={{ fontSize: "16px" }}>
          Bilgilerini doldurduktan sonra sınıfa katılmayı tekrar dene.
          </p>
        </div>
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={onConfirm}>
              Profil Sayfasına Git
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  };
  
  export default UserInfoWarningModal;