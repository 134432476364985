import React from 'react'
import style from "../ParentReportWithClass.module.scss"
import useDarkMode from '../../../../../../hooks/useDarkMode';

const StudentInfo = ({logo, classDescription, detail, generalDescription, generalDetail}) => {
  const { darkModeStatus } = useDarkMode();
  return (
    <div className={`${style.studentInfoContainer} d-flex align-items-center gap-3`}>
      <div className={`${style.logoDiv} mx-auto`}>
        <img src={logo} alt="" />
      </div>
      <div className={`${style.studentInfoContainerRight} d-flex align-items-center justify-content-center gap-4 px-5`}>
        <div className={`${style.firstSection} text-center`}>
      <div><span className={`${style.description}`}>{classDescription}</span></div>
      <div><span className={`${style.detail}`} style={{color:darkModeStatus && "#fff"}}>{detail}</span></div>
        </div>
        <div className={`${style.secondSection} text-center`}>
        <div><span className={`${style.description}`}>{generalDescription}</span></div>
      <div><span className={`${style.detail}`} style={{color:darkModeStatus && "#fff"}}>{generalDetail}</span></div>
        </div>
      </div>
    </div>
  )
}

export default StudentInfo