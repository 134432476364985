import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import {
  useGameStepWithoutSectionIdQuery,
  useGameStepsWithoutSectionQuery,
} from "../../../../store/features/apiSlice";
import DashboardHeader from "../../../common/Headers/DashboardHeader";
import { nextGame, previousGame } from "./setGameStepWithoutSection";
import Button from "../../../../components/bootstrap/Button";
import style from "../Games.module.scss";
import { useGetStudentPracticesForStudentQuery } from "../../admin/talent cup/talentCupApi";
// import { getSelectedStepsWithParents } from "../../student/talentCup/competitionPracticeFunctions";
import { Spinner } from "react-bootstrap";

const GameStepWithoutSectionId = ({ isFluid }) => {
  const navigate = useNavigate();
  const [competitionGames, setCompetitionGames] = useState(
    JSON.parse(localStorage.getItem("competitionGames")) || []
  );
  const urlParams = new URLSearchParams(window.location.search);
  const competition = urlParams.get("competition");
  const { gameId, stepId } = useParams();
  const { data: step, isLoading:gameLoading } = useGameStepWithoutSectionIdQuery({ gameId, stepId },{ skip: !stepId || isNaN(stepId) });
  const { data, isLoading } = useGetStudentPracticesForStudentQuery(
    competition,
    { skip: !competition }
  );
  const games = data && data[0].games;

  let stage = "PROD";
  const baseUrl = process.env.REACT_APP_CENTRAL_API_URL;
  const isProd = baseUrl.includes("app.talent14");
  if (!isProd) {
    // dev code
    stage = "DEV";
  }
  const userId = localStorage.getItem("id");
  const createMarkup = () => {
    return {
      __html: `<iframe src=${step && step.url}?user=${userId}?game=${
        step && step.id
      }?stage=${stage}?competition=${
        competition ?? null
      }  style="flex-grow: 1; border: none; margin: 0; padding: 0;"></iframe>`,
    };
  };

  // Oyunlarda sonraki adıma geçme işlevi burada ayarlandı
  const { data: steps } = useGameStepsWithoutSectionQuery({ id: gameId });
  const firstStep = steps && steps[0].id;
  const lastStep = steps && steps[steps.length - 1].id;

  const gameStep = step && step;
  function handleNextGame() {
    nextGame(gameStep, gameId, navigate, lastStep);
  }
  function handlePreviousGame() {
    previousGame(gameStep, gameId, navigate, firstStep);
  }
  const gameHeaderBackgroundColor = gameId && Number(gameId) === 6 && "#141F26";

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin === "https://gamelab.bilisimschool.com") {
        let gameResult = event.data.gameData;
        if (gameResult?.status === "success") {
          const newGame = { game: gameResult.game, competition };

          // Aynı oyunun aynı competition için kaydedilmemesi için kontrol
          const isGameExists = competitionGames.some(
            (item) => item.game === newGame.game && item.competition === newGame.competition
          );

          if (!isGameExists) {
            const updatedGames = [...competitionGames, newGame];
            setCompetitionGames(updatedGames);
            localStorage.setItem("competitionGames", JSON.stringify(updatedGames));
          }
        }
      }
    };

    window.addEventListener("message", handleMessage);

    // Event listener'ı temizle
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [competitionGames, competition]);
  return (
    <PageWrapper title={step && step.title}>
      <DashboardHeader />
      <Page container={false} style={{ padding: "0" }}>
      {!competition ? (
          <>
         {gameLoading ? <div className={style.loadingOverlay}>
          <div className={style.spinner}>
            <div className={style.ball}></div>
          </div>
          <p className={style.loadingText}>Oyun yükleniyor...</p>
        </div> : <>
         <div
            className={`${style.gameHeader} d-flex justify-content-center align-items-center gap-1`}
            style={{ backgroundColor: `${gameHeaderBackgroundColor}` }}
          >
            <div>
              <Button
                icon="ArrowBackIos"
                color="warning"
                isLight
                onClick={() => handlePreviousGame(gameId)}
              >
                önceki
              </Button>
            </div>
            <div>
              <Button
                icon="ArrowForwardIos"
                color="success"
                isLight
                onClick={() => handleNextGame(gameId)}
              >
                sonraki
              </Button>
            </div>
          </div>
          <div
            style={{ display: "flex", width: "100%", height: "100%" }}
            dangerouslySetInnerHTML={createMarkup()}
          /></>}
          </>
        ) : (
          <>
          {isLoading ? (
              <div
                className="w-100 d-flex justify-content-center align-items-center"
                style={{ height: "70vh" }}
              >
                <div className="text-center">
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ width: "3rem", height: "3rem", color: "#007bff" }}
                  >
                    <span className="sr-only">...</span>
                  </Spinner>
                  <h3
                    className="mt-3"
                    style={{ color: "#007bff", fontWeight: "bold" }}
                  >
                    Yükleniyor...
                  </h3>
                </div>
              </div>
            ) :
            <>
          <div
            className={`${style.gameHeader} d-flex justify-content-center align-items-center gap-1 flex-wrap`}
            style={{ backgroundColor: `${gameHeaderBackgroundColor}` }}
          >
             {
              games?.map((game) =>
                game.sections?.map((section) =>
                  section.steps.map((step) => (
                    <span
                      key={step.id}
                      className="step-item"
                      style={{
                        // textDecoration: "underline",
                        color: step.is_solved ? "blue" : "black",
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                      onClick={() =>
                        navigate(
                          `/games/${game.id}/sections/${section.id}/steps/${step.id}?competition=${competition}`
                        )
                      }
                    >
                      {competitionGames.some(
                        (game) =>
                          game.game === String(step.id) &&
                          game.competition === competition
                      ) || step.is_solved
                        ? "🌸"
                        : "⚪"}
                    </span>
                  ))
                )
              )}
          </div>
           <div
           style={{ display: "flex", width: "100%", height: "100%" }}
           dangerouslySetInnerHTML={createMarkup()}
         />
         </>
          }
          </>
        )}
      </Page>
    </PageWrapper>
  );
};

export default GameStepWithoutSectionId;
