import React from "react";
import style from "./HomeworkReportForTeacher.module.scss";

const HomeworkInfo = ({ homework }) => {
  const title = homework.title;
  return (
    <div
      className={`${style.singleHomework} d-flex justify-content-between align-items-center my-4 px-3`}
    >
      <div>{title}</div>
    </div>
  );
};

export default HomeworkInfo;
