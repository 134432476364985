// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CodeEditor_container__7KxUw{\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-pack: start;\n        justify-content: flex-start;\n}", "",{"version":3,"sources":["webpack://./src/pages/t14/admin/test/CodeEditor.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAa;IAAb,aAAa;IACb,oBAA2B;QAA3B,2BAA2B;AAC/B","sourcesContent":[".container{\n    display: flex;\n    justify-content: flex-start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CodeEditor_container__7KxUw"
};
export default ___CSS_LOADER_EXPORT___;
