import React, { useEffect, useState } from "react";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import DashboardHeaderWithTitle from "../../../common/Headers/DashboardHeaderWithTitle";
import { teacherPanel } from "../../../../menu";
import { get, post, del } from "../../../../utils/service";
import TeacherLearningPathsTable from "./teacher-learning-paths-table";
import PageHeader from "./page-header";
import Spinner from "../../../../components/bootstrap/Spinner";
import styles from "./page-header.module.css";
import LearninPathForm from "../../learning-paths/LearninPathForm";

const TeacherLearningPaths = () => {
  const [paths, setPaths] = useState([]);
  const [filteredPaths, setFilteredPaths] = useState([]);
  const [loading, setLoading] = useState(true);


  const handleGetPaths = async (path = {}) => {
    setLoading(true);
    const result = await get("learningpaths/teachers/");
    setPaths(result.data);
    setFilteredPaths(result.data);
    setLoading(false);
  };

  const handleSavePath = async (path = {}) => {
    try {
      await post("learningpaths/teachers/", path);
      handleGetPaths();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteItem = (id) => {
    try {
      del("learningpaths/teachers/" + id).then(() => handleGetPaths());
    } catch (error) {
    } finally {
    }
  };

 
  const onSearch = (search) => {
    if(search){

      var t = paths.filter(function (item) {
        return item.title.toLowerCase().includes(search.toLowerCase());
      });
      setFilteredPaths(t);
    }
    else {
      setFilteredPaths(paths);
    }
  };
  useEffect(() => {
    handleGetPaths();
  }, []);

  return (
    <PageWrapper title={teacherPanel.teacher_learning_paths.text}>
      <DashboardHeaderWithTitle title="Öğretmen Dersleri"/>
      <Page>
        <PageHeader
          handleSearch={onSearch}
        >
          <div className={styles.button}>
            <LearninPathForm savePath={handleSavePath} />
          </div>
        </PageHeader>
        <div className="row h-100">
          <div className="col-12">
            {loading ? <Spinner color="success"/> : paths?.length > 0 ? (
              <TeacherLearningPathsTable
                isFluid
                paths={filteredPaths}
                deleteItem={handleDeleteItem}
             
              />
            ) : (
              <span>Kayıtlı ders bulunamadı</span>
            )}
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default TeacherLearningPaths;
