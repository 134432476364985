import React, { useEffect, useMemo, useState } from "react";
import Page from "../../../../layout/Page/Page";
import { useAddUserLessonActivitiesMutation } from "../../../../store/features/apiSlice";
import style from "./SingleCourseLessonPage.module.scss";
import H5PIframe from "../2-lessonsContent/5.H5PIframe";
import { H5PPlayerUI } from "@lumieducation/h5p-react";
import { ContentService } from "../../admin/course management/h5p/services/ContentService";
import { useRef } from "react";
import { useCompetition } from "../../admin/talent cup/CompetitionContext";
import { Spinner } from "react-bootstrap";

const LectureContentVideo = ({ lesson }) => {
  const [addUserLessonActivities] = useAddUserLessonActivitiesMutation();
  const { competitionLessons, updateCompetitionLessons } = useCompetition();

  const urlParams = new URLSearchParams(window.location.search);
  const competition = urlParams.get("competition");

  const completedVideo = JSON.parse(localStorage.getItem("finishedLessons") || "[]");
  const isCompleted = completedVideo && completedVideo.includes(lesson?.id);

  const [h5pServerRuns, seth5pServerRuns] = useState(true);

  const h5pPlayer = useRef();
  const contentService = new ContentService("/h5p");
  const [loading, setLoading] = useState(true);
  const onPlayerInitialized = () => {
    setLoading(false);
    // console.log("Player initialized, loading state:", loading);
  };
  function handleXApi(statement, context, event) {
    // console.log("%c handleXApi", "color: white; background-color: #007acc;");
    // console.log(statement, context, event);

    if (statement.verb?.display["en-US"] === "completed") {
      let points = statement.result?.score?.scaled
      ? Math.floor(statement.result.score.scaled * 5)
      : 0;
      if (!isCompleted) {
        localStorage.setItem(
          "finishedLessons",
          JSON.stringify([...completedVideo, lesson.id])
        );
      }
      // console.log("\x1b[41m%s\x1b[0m", "completed ");
      //Here runs only on complete
      // let _pointSavedData = localStorage.getItem("lecturePoint");
      // console.log("lecturePoint:", _pointSavedData);
      // let lecturePoint = false;
      // if (_pointSavedData) {
      //   lecturePoint = JSON.parse(_pointSavedData);
      // }
      const userStr = localStorage.getItem("user");

      if (userStr) {
        const data = {
          lesson: lesson.id,
          points,
          competition: competition || "",
        };
        // console.log("\x1b[42m%s\x1b[0m", "Fetch isteği atıldı", data);
        const response = addUserLessonActivities(data);
        if (response.error) {
          console.error("API error:", response.error);
        }
        const newLesson = { lesson: lesson.id, competition };

        // Aynı lessonın aynı competition için kaydedilmemesi için kontrol
        const isLessonExists = competitionLessons?.some(
          (item) =>
            item.lesson === newLesson.lesson &&
            item.competition === newLesson.competition
        );
        if (!isLessonExists) {
          const updatedLessons = [...competitionLessons, newLesson];
          updateCompetitionLessons(updatedLessons);
          localStorage.setItem(
            "competitionLessons",
            JSON.stringify(updatedLessons)
          );
        }
        if (response.error) {
        } else {
        }
      }
    }
  }

  const contentSource = useMemo(() => {
    if (h5pServerRuns && lesson?.content_id) {
      return `h5p`;
    }
    return "lumi";
  }, [h5pServerRuns, lesson?.content_id]);

  useEffect(() => {
    if (contentSource === "h5p") {
      setLoading(true);
    }
  }, [contentSource]);

  return (
    <Page className={`${style.pageContainerLectureVideo}`}>
      <div className={`${style.videoContainer}`}>
        {contentSource === "h5p" ? (
          <>
          {loading &&  <div className="text-center">
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ width: "3rem", height: "3rem", color: "#007bff" }}
                  >
                    <span className="sr-only">...</span>
                  </Spinner>
                  <h3
                    className="mt-3"
                    style={{ color: "#007bff", fontWeight: "bold" }}
                  >
                    Yükleniyor...
                  </h3>
                </div>}
            <H5PPlayerUI
              ref={h5pPlayer}
              contentId={lesson?.content_id}
              contextId={undefined}
              asUserId={"1234"}
              readOnlyState={true}
              loadContentCallback={contentService.getPlay}
              onInitialized={onPlayerInitialized}
              onxAPIStatement={(statement, context, event) =>
                handleXApi(statement, context, event)
              }
            />
          </>
        ) : (
          <H5PIframe src={`${lesson?.video}`} />
        )}
        {contentSource === "lumi" && (
          <div className="d-flex flex-column mt-3">
            <h3>AÇIKLAMA</h3>
            <p
              className="mt-4 p-1"
              style={{ textAlign: "justify", fontSize: "16px" }}
            >
              {lesson?.description ? lesson?.description : ""}
            </p>
          </div>
        )}
      </div>
    </Page>
  );
};

export default LectureContentVideo;
