import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CITIES from "../../../common/data/cities";
import USER_SUBSCRIPTION_TYPE from "../../../common/data/enumUserSubscriptionType";
import Button from "../../../components/bootstrap/Button";
import Checks from "../../../components/bootstrap/forms/Checks";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Select from "../../../components/bootstrap/forms/Select";
import Icon from "../../../components/icon/Icon";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import {
  setPremiumMounthly,
  setStandartMounthly,
  setTotalPrice,
} from "../../../store/paymentSlice";
import { paymentPost } from "../../../utils/service";
import DashboardHeader from "../../common/Headers/DashboardHeader";
import axios from "axios";
import CreditCard from "./CreditCard/CreditCard";
import USER_PAYMENT_PLAN_TYPE from "../../../common/data/enumUserPaymentPlanType";
import RegisterControlModal from "./RegisterControlModal";
import { useSendDiscountCouponMutation } from "../../../store/features/couponApi";
import {
  getCustomErrorMessage,
  handleErrorMessage,
} from "../../../utils/customErrors";
import showNotification from "../../../components/extras/showNotification";
import CouponCode from "./CouponCode";

const Payment = () => {
  const subscriptionType = localStorage.getItem("subscription_type");
  // const subscriptionType = 1;

  const getSubscriptionDetails = (premiumPlan, standartPlan, premiumMounthly, standartMounthly) => {
    let subscriptionDetails = {
      subscription_type: null,
      subscription_period: null,
    };
  
    if (premiumPlan) {
      subscriptionDetails.subscription_type = 3; // Premium plan
      subscriptionDetails.subscription_period = premiumMounthly ? 2 : 1; // 2: Aylık, 1: Yıllık
    } else if (standartPlan) {
      subscriptionDetails.subscription_type = 2; // Standart plan
      subscriptionDetails.subscription_period = standartMounthly ? 2 : 1; // 2: Aylık, 1: Yıllık
    }
  
    return subscriptionDetails;
  };  

  const [sendDiscountCoupon] = useSendDiscountCouponMutation();
  const handleApplyCoupon = async (discountCode) => {
    const response = await sendDiscountCoupon({ data: { code: discountCode, ...subscriptionDetails} });
    console.log(response);
    if (response.error) {
      const customError = handleErrorMessage(response.error.data);
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>{getCustomErrorMessage(customError)}</span>
        </span>,
        "Bir hata oluştu",
        "danger"
      );
    } else {
      const discount_percentage = response.data.discount_percentage;
      const discount_amount = response.data.discount_amount;
      let discountedPrice = totalPrice;
      if (discount_percentage) {
        // Yüzde üzerinden indirim hesapla
        discountedPrice = totalPrice - (totalPrice * discount_percentage) / 100;
      } else if (discount_amount) {
        // Miktar üzerinden indirim hesapla
        discountedPrice = totalPrice - Number(discount_amount);
      }

      setFinalPrice(discountedPrice); // Güncellenmiş toplam fiyatı state'e kaydet
    }
  };

  const { standartMounthly, premiumMounthly, totalPrice, premiumPlan, standartPlan  } = useSelector(
    (store) => store.payment
  );

  const subscriptionDetails = getSubscriptionDetails(
    premiumPlan,
    standartPlan,
    premiumMounthly,
    standartMounthly
  );

  const [finalPrice, setFinalPrice] = useState(null); //indirim hesaplandıktan sonra

  const dispatch = useDispatch();
  const [first, setFirst] = useState(true);

  const addStandartPrice = () => {
    if (standartMounthly) {
      dispatch(setTotalPrice(USER_PAYMENT_PLAN_TYPE.STANDARTYEARLY.newPrice));
      localStorage.setItem(
        "lastTotalPrice",
        USER_PAYMENT_PLAN_TYPE.STANDARTYEARLY.newPrice
      );
    } else {
      dispatch(setTotalPrice(USER_PAYMENT_PLAN_TYPE.STANDARTMOUNTHLY.newPrice));
      localStorage.setItem(
        "lastTotalPrice",
        USER_PAYMENT_PLAN_TYPE.STANDARTMOUNTHLY.newPrice
      );
    }
  };
  const addPremiumPrice = () => {
    if (!premiumMounthly) {
      dispatch(setTotalPrice(USER_PAYMENT_PLAN_TYPE.PREMIUMMOUNTHLY.newPrice));
      localStorage.setItem(
        "lastTotalPrice",
        USER_PAYMENT_PLAN_TYPE.PREMIUMMOUNTHLY.newPrice
      );
    } else {
      dispatch(setTotalPrice(USER_PAYMENT_PLAN_TYPE.PREMIUMYEARLY.newPrice));
      localStorage.setItem(
        "lastTotalPrice",
        USER_PAYMENT_PLAN_TYPE.PREMIUMYEARLY.newPrice
      );
    }
  };

  const handleChangeStandart = () => {
    setFinalPrice(null)
    dispatch(setStandartMounthly(!standartMounthly));
  };
  const handleChangePremium = () => {
    setFinalPrice(null)
    dispatch(setPremiumMounthly(!premiumMounthly));
  };

  const setPaymentMethod = () => {
    let plan = localStorage.getItem("plan");
    switch (plan) {
      case "standartMounthly":
        dispatch(
          setTotalPrice(USER_PAYMENT_PLAN_TYPE.STANDARTMOUNTHLY.newPrice)
        );
        localStorage.setItem(
          "lastTotalPrice",
          USER_PAYMENT_PLAN_TYPE.STANDARTMOUNTHLY.newPrice
        );
        dispatch(setStandartMounthly(standartMounthly));
        break;
      case "standartAnnually":
        dispatch(setTotalPrice(USER_PAYMENT_PLAN_TYPE.STANDARTYEARLY.newPrice));
        localStorage.setItem(
          "lastTotalPrice",
          USER_PAYMENT_PLAN_TYPE.STANDARTYEARLY.newPrice
        );
        dispatch(setStandartMounthly(!standartMounthly));
        break;
      case "premiumMounthly":
        dispatch(
          setTotalPrice(USER_PAYMENT_PLAN_TYPE.PREMIUMMOUNTHLY.newPrice)
        );
        localStorage.setItem(
          "lastTotalPrice",
          USER_PAYMENT_PLAN_TYPE.PREMIUMMOUNTHLY.newPrice
        );
        dispatch(setPremiumMounthly(premiumMounthly));
        break;
      case "premiumAnnually":
        dispatch(setTotalPrice(USER_PAYMENT_PLAN_TYPE.PREMIUMYEARLY.newPrice));
        localStorage.setItem(
          "lastTotalPrice",
          USER_PAYMENT_PLAN_TYPE.PREMIUMYEARLY.newPrice
        );
        dispatch(setPremiumMounthly(!premiumMounthly));
        break;
      default:
      // setMerchantOid("");
    }
  };

  useEffect(() => {
    let plan = localStorage.getItem("plan");
    if (plan) {
      setPaymentMethod();
    } else {
      localStorage.setItem("lastTotalPrice", totalPrice);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!first) addStandartPrice();
    //eslint-disable-next-line
  }, [standartMounthly]);

  useEffect(() => {
    if (!first) addPremiumPrice();
    //eslint-disable-next-line
  }, [premiumMounthly]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFirst(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  //!Credit Card Display
  const [cardOwner, setCardOwner] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardExpiryDate, setCardExpiryDate] = useState("");

  //! 3D Validation
  const [paymentResponse, setPaymentResponse] = useState("");

  const validate = (values) => {
    let inputDate = new Date(
      `20${values.expiry.slice(3, 5)}-${values.expiry.slice(0, 2)}-01`
    );
    let currentDate = new Date();
    const errors = {};
    if (!values.cardOwner) {
      errors.cardOwner = "Kart sahibinin ad ve soyadını giriniz";
    } else if (!values.cardOwner.match(/^[A-Za-z\s]+$/)) {
      errors.cardOwner =
        "Türkçe karakter kullanmayınız ve sadece harf giriniz.";
    } else if (!values.cardOwner.match(/^([A-Za-z]+\s)*[A-Za-z]+$/)) {
      errors.cardOwner = "Ad soyad arasında boşluk olmalıdır";
    } else if (values.cardOwner.length < 6) {
      errors.cardOwner = "Ad soyad uzunluğu 5 ya da daha uzun olmalıdır.";
    }

    if (!values.cardNumber || values.cardNumber.includes("_")) {
      errors.cardNumber = "Geçerli bir kart numarası giriniz.";
    }

    if (!values.cvc || values.cvc.includes("_")) {
      errors.cvc = "Geçerli bir CVC numarası giriniz.";
    }

    if (!values.expiry || values.expiry.includes("_")) {
      errors.expiry = "Kartınızın son kullanma tarihini giriniz";
    } else if (inputDate < currentDate) {
      errors.expiry = "Geçerli bir tarih giriniz";
    } else if (parseInt(values.expiry.slice(0, 2)) > 12) {
      errors.expiry = "Geçerli bir ay giriniz";
    } else if (parseInt(values.expiry.slice(0, 2)) === 0) {
      errors.expiry = "Geçerli bir ay giriniz";
    }

    if (!values.fullName) {
      errors.fullName = "Kart sahibinin ad ve soyadını giriniz";
    } else if (!values.fullName.match(/^[A-Za-z\s]+$/)) {
      errors.fullName = "Türkçe karakter kullanmayınız ve sadece harf giriniz.";
    } else if (!values.fullName.match(/^([A-Za-z]+\s)*[A-Za-z]+$/)) {
      errors.fullName = "Ad soyad arasında boşluk olmalıdır";
    } else if (values.fullName.length < 6) {
      errors.fullName = "Ad soyad uzunluğu 5 ya da daha uzun olmalıdır.";
    }

    if (!values.telNumber || values.telNumber.includes("_")) {
      errors.telNumber = "Telefon numaranızı yazınız";
    }

    if (!values.city) {
      errors.city = "Bir şehir seçiniz";
    }

    return errors;
  };

  const [merchantOid, setMerchantOid] = useState("TALENT14STANDARDMONTHLY");

  const createMerchantOid = () => {
    let price = totalPrice;
    switch (price) {
      case USER_PAYMENT_PLAN_TYPE.STANDARTMOUNTHLY.newPrice:
        setMerchantOid("TALENT14STANDARDMONTHLY");
        break;
      case USER_PAYMENT_PLAN_TYPE.STANDARTYEARLY.newPrice:
        setMerchantOid("TALENT14STANDARDANNUALY");
        break;
      case USER_PAYMENT_PLAN_TYPE.PREMIUMMOUNTHLY.newPrice:
        setMerchantOid("TALENT14PREIUMMONTHLY");
        break;
      case USER_PAYMENT_PLAN_TYPE.PREMIUMYEARLY.newPrice:
        setMerchantOid("TALENT14PREIUMANNUALY");
        break;
      default:
        setMerchantOid("");
    }
  };
  useEffect(() => {
    createMerchantOid();
    //eslint-disable-next-line
  }, [totalPrice]);

  function formatExpirationMonth(date) {
    const month = date.substring(0, 2);
    return month;
  }
  function formatExpirationYear(date) {
    const year = date.substring(3, 5);
    return year;
  }

  // https://dev.paytr.com/direkt-api/direkt-api-1-adim

  let email = localStorage.getItem("email");

  const formik = useFormik({
    initialValues: {
      merchant_oid: merchantOid,
      email: email,
      cardOwner: "",
      cardNumber: "",
      expiry: "",
      cvc: "",
      fullName: "",
      telNumber: "",
      city: "",
    },
    validate,
    onSubmit: async (values) => {
      let user_ip = await axios
        .get("https://api.ipify.org/")
        .then((response) => response.data);

      if (user_ip) {
        const payment_values = {
          user_ip: user_ip,
          merchant_oid: merchantOid,
          email: values.email,

          cc_owner: values.cardOwner.toUpperCase(),
          card_number: values.cardNumber,
          expiry_month: formatExpirationMonth(values.expiry),
          expiry_year: formatExpirationYear(values.expiry),
          cvv: values.cvc,

          user_name: values.fullName.toUpperCase(),
          user_address: values.city,
          user_phone: values.telNumber,
        };
        paymentPost(
          "integrations/payment/paytr/payment/make_payment_request/",
          payment_values
        ).then((res) => {
          if (res.status === 204 || res.status === 200) {
            setPaymentResponse(res.data);
          } else {
            setPaymentResponse("");
          }
        });
      }
    },
  });

  const cities = CITIES;
  return (
    <PageWrapper>
      <DashboardHeader />
      <RegisterControlModal />
      <Page>
        <div>
          <div>
            {!paymentResponse ? (
              <div className="row g-4 pt-0">
                <div
                  className={`${
                    Number(subscriptionType) ===
                      USER_SUBSCRIPTION_TYPE.PREMIUM.id && "d-none"
                  } col-md-6 col-lg-6 mx-auto`}
                  style={{
                    display: `${
                      totalPrice ===
                        USER_PAYMENT_PLAN_TYPE.STANDARTMOUNTHLY.newPrice ||
                      totalPrice ===
                        USER_PAYMENT_PLAN_TYPE.STANDARTYEARLY.newPrice
                        ? "block"
                        : "none"
                    }`,
                  }}
                >
                  <div className="row g-4 align-items-center">
                    <div className="col-auto">
                      <Icon icon="Maps Home Work" size="5x" color="primary" />
                    </div>
                    <div className="col">
                      <h2>Standart</h2>
                    </div>
                    <div className={`fs-4 d-flex justify-content-start`}>
                      <div>
                        <span>Aylık</span>
                      </div>
                      <div className="ps-2">
                        <Checks
                          type="switch"
                          value={standartMounthly}
                          checked={standartMounthly}
                          onChange={() => handleChangeStandart()}
                          style={{
                            backgroundColor: `${
                              standartMounthly ? "#6C5DD3" : "pink"
                            }`,
                          }}
                        />
                      </div>

                      <div>
                        <span>Yıllık</span>
                      </div>
                    </div>
                    <div className="col-12">
                      {!standartMounthly ? (
                        <h3 className="h3 fw-bold">
                          <span className="h3">₺</span>
                          {USER_PAYMENT_PLAN_TYPE.STANDARTMOUNTHLY.newPrice}
                          /Aylık
                          <span
                            className="h3 ms-3 fw-bold"
                            style={{ textDecoration: "line-through" }}
                          >
                            ₺{USER_PAYMENT_PLAN_TYPE.STANDARTMOUNTHLY.oldPrice}
                          </span>
                        </h3>
                      ) : (
                        <h3 className="h3 fw-bold">
                          <span className="h3">₺</span>
                          {USER_PAYMENT_PLAN_TYPE.STANDARTYEARLY.newPrice}
                          /Yıllık
                          <span
                            className="h3 ms-3 fw-bold"
                            style={{ textDecoration: "line-through" }}
                          >
                            ₺{USER_PAYMENT_PLAN_TYPE.STANDARTYEARLY.oldPrice}
                          </span>
                        </h3>
                      )}
                    </div>
                    <div className="col-12">
                      <div className="lead">
                        <Icon icon="Done Outline" color="success" /> 200 den
                        fazla etkileşimli video ders içeriği
                      </div>
                      <div className="lead">
                        <Icon icon="Done Outline" color="success" /> 50'den
                        fazla alıştırma
                      </div>
                      <div className="lead">
                        <Icon icon="Done Outline" color="success" /> CodeArt &
                        CodeÇizz Serbest çalışma
                      </div>
                      <div className="lead">
                        <Icon icon="Done Outline" color="success" /> Kolaydan
                        zora eğitici oyunlar
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-6 mx-auto"
                  style={{
                    display: `${
                      totalPrice ===
                        USER_PAYMENT_PLAN_TYPE.PREMIUMMOUNTHLY.newPrice ||
                      totalPrice ===
                        USER_PAYMENT_PLAN_TYPE.PREMIUMYEARLY.newPrice
                        ? "block"
                        : "none"
                    }`,
                  }}
                >
                  <div className="row g-4 align-items-center">
                    <div className="col-auto">
                      <Icon icon="CustomFactory" size="5x" color="info" />
                    </div>
                    <div className="col">
                      <h2>Talent14 Premium</h2>
                    </div>
                    <div className={`fs-4 d-flex justify-content-start`}>
                      <div>
                        <span>Aylık</span>
                      </div>
                      <div className="ps-2">
                        <Checks
                          type="switch"
                          value={premiumMounthly}
                          checked={premiumMounthly}
                          onChange={() => handleChangePremium()}
                          style={{
                            backgroundColor: `${
                              premiumMounthly ? "#6C5DD3" : "#E4FF70"
                            }`,
                          }}
                        />
                      </div>

                      <div>
                        <span>Yıllık</span>
                      </div>
                    </div>
                    <div className="col-12">
                      {!premiumMounthly ? (
                        <h3 className="h3 fw-bold">
                          <span className="h3">₺</span>
                          {USER_PAYMENT_PLAN_TYPE.PREMIUMMOUNTHLY.newPrice}
                          /Aylık
                          <span
                            className="h3 ms-3 fw-bold"
                            style={{ textDecoration: "line-through" }}
                          >
                            ₺{USER_PAYMENT_PLAN_TYPE.PREMIUMMOUNTHLY.oldPrice}
                          </span>
                        </h3>
                      ) : (
                        <h3 className="h3 fw-bold">
                          <span className="h3">₺</span>
                          {USER_PAYMENT_PLAN_TYPE.PREMIUMYEARLY.newPrice}/Yıllık
                          <span
                            className="h3 ms-3 fw-bold"
                            style={{ textDecoration: "line-through" }}
                          >
                            ₺{USER_PAYMENT_PLAN_TYPE.PREMIUMYEARLY.oldPrice}
                          </span>
                        </h3>
                      )}
                    </div>
                    <div className="col-12">
                      <div className="lead">
                        <Icon icon="Done Outline" color="success" /> Tüm
                        içeriklere sınırsız erişim
                      </div>
                      <div className="lead">
                        <Icon icon="Done Outline" color="success" /> 800 den
                        fazla etkileşimli video ders içeriği
                      </div>
                      <div className="lead">
                        <Icon icon="Done Outline" color="success" /> CodeArt &
                        CodeÇizz Serbest Çalışma ve Alıştırmalar
                      </div>
                      <div className="lead">
                        <Icon icon="Done Outline" color="success" /> Kolaydan
                        zora eğitici oyunlar
                      </div>
                    </div>
                  </div>
                </div>
                {/* //! >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>PAYMENT SECTION <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< // */}
                <div className="col-lg-4">
                  <form
                    className="row g-3 align-items-center"
                    //   tag="form"
                    noValidate
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="col mt-4">
                      <h2 style={{ borderBottom: "1px solid gray" }}>
                        Ödeme Bilgisi
                      </h2>
                    </div>
                    <div>
                      <CreditCard
                        cardOwner={cardOwner}
                        cardNumber={cardNumber}
                        expiryDate={cardExpiryDate}
                      />
                    </div>

                    <div className="col-lg-12">
                      <FormGroup
                        className="col-12"
                        id="cardOwner"
                        label="Kart Sahibinin Adı Soyadı"
                      >
                        <Input
                          placeholder="Kart sahibinin adı soyadı"
                          onChange={(e) => {
                            formik.handleChange(e);
                            setCardOwner(e.target.value.toUpperCase());
                          }}
                          value={formik.values.cardOwner.toUpperCase()}
                          onBlur={formik.handleBlur}
                          isValid={formik.isValid}
                          isTouched={formik.touched.cardOwner}
                          invalidFeedback={formik.errors.cardOwner}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-lg-12 d-md-flex">
                      <FormGroup
                        className="col-12 col-md-6"
                        id="cardNumber"
                        label="Kart Numarası"
                      >
                        <Input
                          type="text"
                          mask="9999 9999 9999 9999"
                          placeholder="Kart Numaranızı Giriniz"
                          required
                          onChange={(e) => {
                            formik.handleChange(e);
                            setCardNumber(e.target.value);
                          }}
                          value={formik.values.cardNumber}
                          onBlur={formik.handleBlur}
                          isValid={formik.isValid}
                          isTouched={formik.touched.cardNumber}
                          invalidFeedback={formik.errors.cardNumber}
                        />
                      </FormGroup>
                      <FormGroup className="col-3 me-2" id="cvc" label="CVC">
                        <Input
                          type="text"
                          placeholder="CVC"
                          mask="999"
                          required
                          onChange={formik.handleChange}
                          value={formik.values.cvc}
                          onBlur={formik.handleBlur}
                          isValid={formik.isValid}
                          isTouched={formik.touched.cvc}
                          invalidFeedback={formik.errors.cvc}
                        />
                      </FormGroup>
                      <FormGroup className="col-3" id="expiry" label="Ay/Yıl">
                        <Input
                          type="text"
                          placeholder="AA/YY"
                          mask="99/99"
                          required
                          onChange={(e) => {
                            formik.handleChange(e);
                            setCardExpiryDate(e.target.value);
                          }}
                          value={formik.values.expiry}
                          onBlur={formik.handleBlur}
                          isValid={formik.isValid}
                          isTouched={formik.touched.expiry}
                          invalidFeedback={formik.errors.expiry}
                        />
                      </FormGroup>
                    </div>
                    <div className="col">
                      <h2 style={{ borderBottom: "1px solid gray" }}>
                        Üyelik Bilgisi
                      </h2>
                    </div>
                    <div className="col-lg-12">
                      <FormGroup className="col-12" id="fullName" label="">
                        <Input
                          placeholder="Ad Soyad"
                          onChange={formik.handleChange}
                          value={formik.values.fullName.toUpperCase()}
                          onBlur={formik.handleBlur}
                          isValid={formik.isValid}
                          isTouched={formik.touched.fullName}
                          invalidFeedback={formik.errors.fullName}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-lg-12 d-flex">
                      <FormGroup className="col-6" id="telNumber" label="">
                        <Input
                          type="text"
                          mask="999 999 99 99"
                          placeholder="Telefon numarası"
                          required
                          onChange={formik.handleChange}
                          value={formik.values.telNumber}
                          onBlur={formik.handleBlur}
                          isValid={formik.isValid}
                          isTouched={formik.touched.telNumber}
                          invalidFeedback={formik.errors.telNumber}
                        />
                      </FormGroup>
                      <FormGroup className="col-6" id="city" label="">
                        <Select
                          placeholder="Şehir seçiniz"
                          onChange={formik.handleChange}
                          value={formik.values.city}
                          isValid={formik.isValid}
                          onBlur={formik.handleBlur}
                          isTouched={formik.touched.city}
                          invalidFeedback={formik.errors.city}
                          // list={cities}
                          className="text-dark"
                        >
                          {cities.map((city) => (
                            <option key={city} value={city}>
                              {city}
                            </option>
                          ))}
                        </Select>
                      </FormGroup>
                    </div>
                    {/* Kupon Kodu Bileşeni */}
                    <CouponCode onApplyCoupon={handleApplyCoupon} />
                    <div className="col-lg-12">
                      <FormGroup
                        className="col-12 d-flex gap-3 align-items-center h4"
                        id="totalPrice"
                        label="Toplam Ödenecek Tutar"
                        size="lg"
                      >
                        <Input
                          type="text"
                          placeholder="Toplam Ödenecek Miktar"
                          required
                          onChange={formik.handleChange}
                          value={
                            finalPrice
                              ? finalPrice + " TL"
                              : totalPrice
                              ? totalPrice + " TL"
                              : formik.values.totalPrice + " TL"
                          }
                          onBlur={formik.handleBlur}
                          isValid={formik.isValid}
                          isTouched={formik.touched.totalPrice}
                          invalidFeedback={formik.errors.totalPrice}
                          size="lg"
                          className="bg-light text-dark"
                          style={{
                            fontSize: "20px",
                            width: "150px",
                            fontWeight: "700",
                          }}
                        />
                      </FormGroup>
                    </div>
                    <div className="col">
                      <Button
                        type="submit"
                        color="info"
                        icon="Save"
                        size="lg"
                        isDisable={!formik.isValid && !!formik.submitCount}
                        style={{ fontSize: "18px", width: "100%" }}
                      >
                        Ödemeyi Tamamla
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <iframe
                title="payment response"
                style={{ width: "100%", height: "100vh" }}
                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                srcDoc={`${paymentResponse}`}
              ></iframe>
            )}
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default Payment;
