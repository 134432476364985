import React from 'react'
import VideoListTable from './2-VideoListTable'
import Page from '../../../../../../layout/Page/Page'

const VideoManagement = () => {
  return (
    <>
    <Page container="fluid">
      <VideoListTable/>
    </Page>
  </>
  )
}

export default VideoManagement