import React, { useState } from 'react';
import Confirm from '../../../../common/Confirm';
import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const ConfirmButton = ({ handleClick, icon, text, darkModeStatus, parameter }) => {
    
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    
    return (
        <div>
            <Confirm
                open={openConfirmModal}
                setOpen={setOpenConfirmModal}
                onConfirm={() => {
                    setOpenConfirmModal(false);
                    handleClick(parameter);
                }}
                onCancel={() => setOpenConfirmModal(false)}
            />
           
            <IconButton
                onClick={() => setOpenConfirmModal(true)}
            >
                <Delete />
            </IconButton>
        </div>
    );
};

export default ConfirmButton;