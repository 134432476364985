import React from 'react'
import style from "./ActiveClasses.module.scss"
import { useGetActiveClassesQuery } from '../../teacherApi';
import Card from '../../../../../components/bootstrap/Card';
import { useNavigate } from 'react-router-dom';

const ActiveClasses = () => {
  const navigate = useNavigate();
    const { data: activeClass } = useGetActiveClassesQuery();
  return (
  <Card className={`${style.cardContainer}`}>
        <div className='pt-2 my-2'> 
        <span className={`${style.title} ms-5`} onClick={()=> navigate("/teacher/classes")}>Aktif Sınıflarım</span>
      </div>
        <div className={`${style.customTable} px-4`}>
          <div className={`${style.customHeadContainer} d-flex align-items-center my-3 px-2`}>
            <div
              className={`${style.customTableHead1} ${style.customTableHead}`}
            >
              Sınıf Adı
            </div>
          </div>
          <div className={`${style.customRowContainer}`}>
            {activeClass && activeClass.length === 0 ? (
              <div className={`${style.emptyTable}`}>
                <span>Aktif sınıf bulunamadı</span>
              </div>
            ) : (
              activeClass &&
              activeClass.map((item, i) => (
                <div
                  key={item.id}
                  className={`${style.customTableRow} ${
                    i % 2 === 0 && style.customBackgroundColor
                  } d-flex align-items-center mt-1 px-2 cursor-pointer`}
                  onClick={() =>
                              navigate(`/teacher/class/${item.id}`, {
                                state: { classId: item.id },
                              })
                            }
                >
                  <div className={`${style.customTableColumn1}`}>
                      <div>{item.name}</div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </Card>
  )
}

export default ActiveClasses

