import React from "react";
import style from "./SchoolManagerInformation.module.scss";
import classLogo from "../../../../../assets/school/classLogo.svg"
import studentLogo from "../../../../../assets/school/studentLogo.svg"
import plusLogo from "../../../../../assets/school/plusLogo.svg"
import { useNavigate } from "react-router-dom";
import { useGetTotalStudentQuery } from "../../../../../store/features/apiSlice";


const SchoolManagerInformation = ({schoolId}) => {
  const navigation = useNavigate()
  const { data: total } = useGetTotalStudentQuery();
  const totalClasses = total && total.total_classes 
  const totalStudents = total && total.total_students
    return (
      <div className={`${style.informationContainer} my-3 d-flex align-items-center justify-content-between`}>
        <div className={`${style.leftSection} d-flex justify-content-evenly align-items-center gap-5`}>
        <div className={`${style.classInformation} d-flex justify-content-center align-items-center gap-4`}>
          <div className={`${style.classLogo}`}>
            <img src={classLogo} alt="" />
          </div>
          <div className={`${style.description}`}>
          <span>Toplam Sınıf: {totalClasses}</span>
          </div>
        </div>
        <div className={`${style.line}`}></div>
        <div className={`${style.studentInformation} d-flex justify-content-center align-items-center gap-4`}>
          <div className={`${style.studentLogo}`}>
            <img src={studentLogo} alt="" />
          </div>
          <div className={`${style.description}`}>
          <span>Toplam Öğrenci: {totalStudents}</span>
          </div>
        </div>
        </div>
        <div className={`${style.righSection} d-flex justify-content-center align-items-center gap-2`}>
        <div className={`${style.plusLogo}`}>
            <img src={plusLogo} alt="" />
          </div>
          <div className={`${style.description}`} onClick={()=>navigation(`schoolManager/classManagement/${schoolId}`)}>
          <span>Sınıf ekle</span>
          </div>
        </div>
      </div>
    )
};

export default SchoolManagerInformation;
