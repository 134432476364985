import React, { useEffect, useState } from "react";
import Card, {
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../../components/bootstrap/Card";
import PaginationButtons, {
  dataPagination,
  PER_COUNT,
} from "../../../../components/PaginationButtons";
import useSortableData from "../../../../hooks/useSortableData";
import useDarkMode from "../../../../hooks/useDarkMode";
import style from "./teacher-class.module.scss";
import Search from "./search";
import ClassForm from "./3-class-form";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedClass } from "../teacherSlice";
import useSelectTable from "../../../../hooks/useSelectTable";
import plus from "../../../../assets/dashboardImages/plus.svg";
import Icon from "../../../../components/icon/Icon";
import { IconButton, Tooltip } from "@mui/material";
import { Edit } from "@mui/icons-material";
import ConfirmButton from "../components/confirm-button";
import {
  useGetClassesQuery, useCreateClassMutation,
  useUpdateClassMutation, useDeleteClassMutation
} from "../teacherApi";
import showNotification from "../../../../components/extras/showNotification";
import Confirm from "../../../common/Confirm";
import Checks from "../../../../components/bootstrap/forms/Checks";

const TeacherClassTable = ({ isFluid, isSchool}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { darkModeStatus } = useDarkMode();

  const [edit, setEdit] = useState(false);
  const [classes, setClasses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalStatus, setModalStatus] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [perPage, setPerPage] = useState(PER_COUNT["10"]);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);

  const [deleteClass] = useDeleteClassMutation();
  const [createClass, { isError: createError, error: createErrorData }] = useCreateClassMutation();
  const [updateClass, { isError: updateError, error: updateErrorData }] = useUpdateClassMutation();

  const { data: classesData, isSuccess } = useGetClassesQuery();

  const [filteredClasses, setFilteredClasses] = useState([]);
  const { items } = useSortableData(
    filteredClasses.length > 0 ? filteredClasses : classes
  );
  const onCurrentPageData = dataPagination(items, currentPage, perPage);
  const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageData);

  useEffect(() => {
    filterClasses();
    //eslint-disable-next-line
  }, [searchValue]);

  useEffect(() => {
    // console.log('%cteacher-class-table.js line:45 classesData', 'color: blue;', classesData);
    if (isSuccess) {
      setClasses(classesData);
    }
  }, [classesData, isSuccess]);

  useEffect(() => {
      createError && ShowError(createErrorData?.data.name);
      //eslint-disable-next-line
  }, [createError]);
  
  useEffect(() => {
     updateError && ShowError(updateErrorData?.data.name);
     //eslint-disable-next-line
  }, [updateError]);

  const handleSearchChange = (newValue) => {
    setSearchValue(newValue);
  };

  const handleSaveClass = async (data) => {
    if (data.id > 0) {
      updateClass(data);
    } else {
      createClass(data);
    }
  };

  const handleDelete = () => {
    if (selected.length > 0) {
      selected.forEach((item) => handleDeleteClass(item));
    }
    setOpen(false);
    setSelected([]);
  };
  const handlCancel = () => setOpen(false);

  useEffect(() => {
    if (selectTable.values.selectedList) {
      setSelected(selectTable.values.selectedList);
    }
  }, [selectTable.values.selectedList]);

  const handleDeleteClass = (id) => {
    deleteClass(id);
  };

  const getStateText = (state) => {
    switch (state) {
      case 1:
        return "Aktif";
      case 2:
        return "Pasif";
      case 3:
        return "Tamamlandı";
      default:
        return "-";
    }
  };

  const openClassForm = (selectedClass) => {
    dispatch(setSelectedClass(selectedClass));
    setModalStatus(true);
  };
  const editClassForm = (selectedClass) => {
    dispatch(setSelectedClass(selectedClass));
    setModalStatus(true);
    setEdit(true);
  };

  const filterClasses = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const newClasses =
      classes &&
      classes.filter(
        (item) =>
          (item.name && item.name.toLowerCase().includes(searchValue)) ||
          (item.school_title &&
            item.school_title.toLowerCase().includes(searchValue))
      );
    setFilteredClasses(newClasses);
  };

  return (
    <>
      <Card className={`${style.cardContainer} ${darkModeStatus && style.darkMode}`}>
      <Confirm
          open={open}
          setOpen={setOpen}
          onConfirm={handleDelete}
          onCancel={handlCancel}
        />
        {selected.length > 0 ? (
          <button
            className={`${style.allDeleteButton} btn btn-danger`}
            onClick={() => {
              setSelected(selected);
              setOpen(true);
            }}
          >
            Seçilenleri Sil
          </button>
        ) : (
          <button
            style={{ display: "none" }}
            onClick={() => {
              setSelected(selected);
              setOpen(true);
            }}
          >
            Seçilenleri Sil
          </button>
        )}
        <CardHeader>
          <CardLabel>
            <CardTitle className={`${style.title} ps-3`}>Sınıflarım</CardTitle>
          </CardLabel>
        </CardHeader>
        <ClassForm
          saveClass={handleSaveClass}
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
          edit={edit}
          setEdit={setEdit}
        />
        <div
          className={`${style.actionsContainer} px-2 mx-4 gap-3 mx-auto`}
        >
          <div className={`${style.inputContainer}`} style={{width:`${isSchool ? "100%" : null}`}}>
            <Search
              handleSearchChange={handleSearchChange}
              iconColor={"#5454544F"}
            />
          </div>
          <div
            className={`${style.addClassContainer} ${isSchool && style.schoolCheckStyle} d-flex align-items-center gap-2 ps-2`}
            onClick={() => openClassForm(null)}
          >
            <span>
              <img src={plus} alt="" />
            </span>
            <span>Sınıf Ekle</span>
          </div>
        </div>

        <div className={`${style.customTable} mt-5 px-4`}>
          <div className={`${style.customHeadContainer} d-flex align-items-center my-3 px-2`}>
          <div
              className={`${style.checkContainer} ${style.customTableHead} ${isSchool && style.schoolCheckStyle}`}
            >{SelectAllCheck}</div>
            <div
              className={`${style.customTableHead1} ${style.customTableHead}`}
            >
              Sınıf
            </div>
            <div
              className={`${style.customTableHead2} ${style.customTableHead}`}
            >
              Okul
            </div>
            <div
              className={`${style.customTableHead3} ${style.customTableHead} text-center`}
            >
              Durum
            </div>
            <div
              className={`${style.customTableHead4} ${style.customTableHead} text-center`}
              style={{width:`${isSchool ? "30%" : null}`}}
            >
              Kayıtlı Öğrenci
            </div>
            <div
              className={`${style.customTableHead5} ${style.customTableHead} ${isSchool && style.schoolCheckStyle} text-center`}
            >
              Eylemler
            </div>
          </div>
          <div className={`${style.customRowContainer}`}>
            {items.length === 0 ? (
              <div>
                <span>Kayıtlı sınıf bulunamadı</span>
              </div>
            ) : (
              dataPagination(items, currentPage, perPage).map((item, i) => (
                <div
                  key={item.id}
                  className={`${style.customTableRow} ${i % 2 === 0 && style.customBackgroundColor
                    } d-flex align-items-center mt-1 px-2`}
                >
                   <div className={`${style.checkContainer} ${isSchool && style.schoolCheckStyle}`}>
                    <Checks
                      id={item.id.toString()}
                      name="selectedList"
                      value={item.id}
                      onChange={selectTable.handleChange}
                      checked={selectTable.values.selectedList.includes(
                        item.id.toString()
                      )}
                    />
                  </div>
                  <div
                    className={`${style.customTableColumn1} cursor-pointer`}
                    onClick={() =>
                      navigate(`/teacher/class/${item.id}`, {
                        state: { classId: item.id },
                      })
                    }
                  >
                    <div className={`${style.titleContainer}`}>
                      <div className={`${style.titleShort}`}>{item.name}</div>
                      <div className={`${style.titleFull}`}>{item.name}</div>
                        </div>
                  </div>
                  <div className={`${style.customTableColumn2}`}>
                    <div className="d-flex">
                       <div className={`${style.schoolContainer}`}>
                      <div className={`${style.schoolShort}`}>{`${item.school_title || "-"}`}</div>
                      <div className={`${style.schoolFull}`}>{`${item.school_title || "-"}`}</div>
                        </div>
                    </div>
                  </div>
                  <div className={`${style.customTableColumn3} text-center`}>
                    <div
                      className="mx-auto py-1"
                      style={{
                        background: item.state === 1 ? "#0fa958" : "#D9D9D9",
                      }}
                    >
                      {getStateText(item.state)}
                    </div>
                  </div>
                  <div className={`${style.customTableColumn4} text-center`} style={{width:`${isSchool ? "30%" : null}`}}>
                    <div className="d-flex justify-content-center align-items-center gap-2">
                      <Icon
                        icon="School"
                        size="2x"
                        style={{ opacity: "0.3", color:darkModeStatus && i % 2 !== 0 ? "white" : "black" }}
                      />
                      <span>{item.student_count}</span>
                    </div>
                  </div>

                  {/* Eylemler */}
                  <div className={`${style.customTableColumn5} ${isSchool && style.schoolCheckStyle} d-flex justify-content-center mx-auto`}>
                      <ConfirmButton handleClick={handleDeleteClass} parameter={item.id} />

                    <Tooltip title="Edit">
                      <IconButton
                        onClick={() => editClassForm(item)}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>

              ))
            )}
          </div>
        </div>
        <PaginationButtons
          data={items}
          label="sınıf"
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </Card>
    </>
  );
};

export default TeacherClassTable;

function ShowError(message) {
  showNotification(
    "Hata",
    <span className="d-flex align-items-center">
      <Icon icon="Info" size="lg" className="me-1" />
      <span>{message}</span>
    </span>,
    "danger"
  );
}
