import React, { useEffect, useState } from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../../components/bootstrap/Modal";
import Logo from "../../../../../components/Logo";
import Icon from "../../../../../components/icon/Icon";
import Button from "../../../../../components/bootstrap/Button";
import FormGroup from "../../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../../components/bootstrap/forms/Input";
import { useFormik } from "formik";
import { useClearCacheMutation } from "../../../../../store/features/apiSlice";
import showNotification from "../../../../../components/extras/showNotification";
import Textarea from "../../../../../components/bootstrap/forms/Textarea";
import _ from "underscore";
import Card, {
  CardBody,
  CardHeader,
} from "../../../../../components/bootstrap/Card";
import Checks from "../../../../../components/bootstrap/forms/Checks";
import { ContentService } from "../../course management/h5p/services/ContentService";
import { useUpdateGuidanceMutation } from "../teacherEducationApi";

const ShowGuideModal = ({
  isOpenShowGuideModal,
  setIsOpenShowGuideModal,
  data,
  currentGuide,
}) => {
  const [updateGuidance] = useUpdateGuidanceMutation();
  const [clearCache] = useClearCacheMutation();
  const [contents, setContents] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredContents, setFilteredContents] = useState([]);

  const contentService = new ContentService("/h5p");
  useEffect(() => {
    async function getContent() {
      const contentList = await contentService.list();
      console.log(
        "%c6-UpdateLessonModal.js line:40 contentList",
        "color: white; background-color: #007acc;",
        contentList
      );
      setContents(
        contentList.map((m) => {
          return {
            content_id: m.contentId,
            mainLibrary: m.mainLibrary,
            title: m.title,
          };
        })
      );
    }
    getContent();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentGuide) {
      console.log(
        "%c6-UpdateLessonModal.js line:52 currentGuide",
        "color: white; background-color: #007acc;",
        currentGuide
      );
      console.log(
        "%c6-UpdateLessonModal.js line:54 currentGuide.content_id",
        "color: white; background-color: #007acc;",
        currentGuide.content_id
      );
      formik.setFieldValue("id", currentGuide.id);
      formik.setFieldValue("title", currentGuide.title);
      formik.setFieldValue("order_number", currentGuide.order_number);
      formik.setFieldValue("description", currentGuide.description);
      formik.setFieldValue("video", currentGuide.video);
      formik.setFieldValue("content_id", currentGuide.content_id);
    }else {
      formik.resetForm();
    }
    //eslint-disable-next-line
  }, [currentGuide]);

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Sunumun adını giriniz";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      id: null,
      title: "",
      order_number: 9999,
      description: null,
      video: "",
      content_id: null,
    },
    validate,
    onSubmit: () => {
      handleLesson();
    },
  });
  const handleCheckboxClick = (event) => {
    const { name, value, checked } = event.target;

    if (name === "content_id" && checked) {
      formik.setFieldValue(name, value);
    } else if (name === "content_id" && !checked) {
      formik.setFieldValue(name, null);
    }
  };
  const handleLesson = async () => {
    const guideId = formik.values.id;
    if (!guideId) {
      const guideValues = { ...formik.values };
      const payloadWithoutGuidanceParemeter = data.guides?.map((item) => {
        const { guidance, ...rest } = item;
        return rest;
      });
      let newGuides = data && [...payloadWithoutGuidanceParemeter, guideValues];
      newGuides = _.sortBy(newGuides, "order_number");
      const response = await updateGuidance({
        id: data.id,
        title: data.title,
        // ...data,
        guides: newGuides,
      });
      if (response.error) {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="danger" size="lg" className="me-1" />
            <span>Kayıt Başarısız</span>
          </span>,
          "Bir hata oluştu",
          "danger"
        );
      } else {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Kayıt Başarılı</span>
          </span>,
          "Konu başarıyla eklendi.",
          "success"
        );
      }
      clearCache();
      setIsOpenShowGuideModal(false);
      formik.resetForm();
    } else {
      const guideValues = { id: guideId, ...formik.values };
      let newGuides =
        data &&
        data.guides.map((guide) =>
          Number(guide.id) === Number(guideId) ? { ...guide, ...guideValues } : guide
        );
      newGuides = newGuides.map((item) => {
        const { guidance, ...rest } = item;
        return rest;
      });
      newGuides = _.sortBy(newGuides, "order_number");
      const response = await updateGuidance({
        id: data.id,
        title: data.title,
        ...data,
        guides: newGuides,
      });
      if (response.error) {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="danger" size="lg" className="me-1" />
            <span>Kayıt Başarısız</span>
          </span>,
          "Bir hata oluştu",
          "danger"
        );
      } else {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Kayıt Başarılı</span>
          </span>,
          "Konu başarıyla düzenlendi.",
          "success"
        );
      }
      clearCache();
      setIsOpenShowGuideModal(false);
      formik.resetForm();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      saveForm();
    }
  };
  const saveForm = async () => {
    try {
      await formik.handleSubmit();
      setIsOpenShowGuideModal(false);
    } catch (error) {}
  };

  useEffect(() => {
    if (searchValue) {
      setFilteredContents(
        contents.filter((content) =>
          content.title.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    }
  }, [contents, searchValue]);

  const _contents = searchValue ? filteredContents : contents;
  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenShowGuideModal}
      setIsOpen={setIsOpenShowGuideModal}
      titleId="example-title"
      size={null}
      onKeyDown={handleKeyDown}
    >
      <ModalHeader setIsOpen={setIsOpenShowGuideModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} /> <span className="ps-2">Konu Düzenle</span>
          <span className="ps-2">
            <Icon icon="Computer" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="row gap-3 d-flex justify-content-center">
          <div className="col-lg-8">
            <FormGroup
              // className="col-4"
              id="title"
              isFloating
              label="Başlık"
            >
              <Input
                onChange={formik.handleChange}
                value={formik.values.title}
                onBlur={formik.handleBlur}
                isValid={formik.isValid}
                isTouched={formik.touched.title}
                invalidFeedback={formik.errors.title}
              />
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup isFloating label="Sıra Numarası" id="order_number">
              <Input
                name="order_number"
                type="text"
                autoComplete="order_number"
                value={formik.values.order_number}
                onChange={formik.handleChange}
                isValid={formik.isValid}
                onBlur={formik.handleBlur}
                isTouched={formik.touched.order_number}
                invalidFeedback={formik.errors.order_number}
              />
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup
              // className="col-4"
              id="description"
              isFloating
              label="Açıklama"
            >
              <Textarea
                onChange={formik.handleChange}
                value={formik.values.description}
                onBlur={formik.handleBlur}
                isValid={formik.isValid}
                isTouched={formik.touched.description}
                invalidFeedback={formik.errors.description}
                style={{ height: "150px" }}
              />
            </FormGroup>
          </div>
          <div
            style={{ overflow: "scroll", height: "250px" }}
            className="col-lg-8"
          >
            <Card className="">
              <CardHeader>
                <Input
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  type="text"
                  placeholder="İçeriklerde Ara.."
                />
              </CardHeader>
              <CardBody>
                {
                  <table className="table table-modern">
                    <tbody>
                      {_contents?.map((item) => (
                        <tr key={item.content_id}>
                          <td>
                            <Checks
                              id={item.content_id.toString()}
                              name="content_id"
                              value={item.content_id}
                              onChange={handleCheckboxClick}
                              checked={
                                formik.values.content_id === item.content_id
                              }
                            />
                          </td>
                          <td style={{ textAlign: "justify" }}>{item.title}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                }
              </CardBody>
            </Card>
          </div>
          <div className="col-lg-8">
            <FormGroup
              // className="col-4"
              id="video"
              isFloating
              label="Embed Code"
            >
              <Input
                onChange={formik.handleChange}
                value={formik.values.video}
                onBlur={formik.handleBlur}
                isValid={formik.isValid}
                isTouched={formik.touched.video}
                invalidFeedback={formik.errors.video}
              />
            </FormGroup>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={async () => {
            formik.handleSubmit();
          }}
        >
          Kaydet
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ShowGuideModal;
