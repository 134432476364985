import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const TeacherCertificate = () => {
    const navigate = useNavigate();
  const { id } = useParams();
  const [pdfData, setPdfData] = useState(null);
  const [defaultMessage, setDefaultMessage] = useState(null);
  const baseUrl = process.env.REACT_APP_CENTRAL_API_URL;

  function getToken() {
    let token = localStorage.getItem("token");
    if (token) {
      return JSON.parse(token);
    }
  }

  const token = getToken();
  useEffect(() => {
    async function getPdf() {
      let res = await fetch(baseUrl + `file_upload/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        let blob = await res.blob();
        const url = URL.createObjectURL(blob);
        setPdfData(url);
          // PDF'yi yeni bir sekmede veya pencerede aç
          const newWindow = window.open(url, '_blank');
          if (newWindow) {
            newWindow.focus();
          }
          navigate("/")
      }
      else{
        setDefaultMessage("sertifika yok")
      }
    }
    getPdf();
    //eslint-disable-next-line
  }, []);

  if (!pdfData) {
    if(defaultMessage){
        return <div className="mx-auto h3 mt-5"><p>Sisteme kayıtlı bir sertifika bulunamadı.</p></div>;
    }
    return <p>PDF yükleniyor...</p>;
  }

//   return <iframe src={pdfData} width="100%" height="100%" title="PDF Viewer" />;
};

export default TeacherCertificate;
