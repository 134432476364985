import React, { useState } from "react";
import OffCanvas, {
  OffCanvasHeader,
  OffCanvasBody,
  OffCanvasTitle,
} from "../../../../../../components/bootstrap/OffCanvas";
import { useGetAllSchoolAnnouncementForAdminQuery } from "../../../../../../store/features/apiSlice";
import Icon from "../../../../../../components/icon/Icon";
import Notification from "./Notification";
import style from "./Notification.module.scss";
import _ from "underscore";

const Announcements = () => {
  const schoolId = localStorage.getItem("school");
  const { data: notifications } =
    useGetAllSchoolAnnouncementForAdminQuery(schoolId);
    let sortedNotifications  = _.sortBy(notifications, item => item.created_date).reverse();
    // eslint-disable-next-line
    const [newNotifications, setNewNotifications] = useState([]);
  
  const [isOpen, setIsOpen] = useState(false);

  const toggleOffCanvas = () => setIsOpen(!isOpen);
  return (
    <div>
      <Icon icon={"notifications"} size="3x" color="info" onClick={toggleOffCanvas}></Icon>
      {/* <sub className={`${style.notificationsCount}`}>{notificationsCount}</sub> */}

      <OffCanvas
        id="exampleOffCanvas"
        titleId="exampleTitle"
        isOpen={isOpen}
        setOpen={setIsOpen}
        placement="end" // "start", "top", "bottom" gibi diğer değerler de kullanılabilir
        isBodyScroll={false}
        isBackdrop={true}
        isModalStyle={false}
      >
        <OffCanvasHeader setOpen={setIsOpen}>
          <OffCanvasTitle id="exampleTitle">Duyurular</OffCanvasTitle>
        </OffCanvasHeader>
        <OffCanvasBody>
          <div className={`${style.notificationList}`}>
            {!!sortedNotifications?.length &&
              sortedNotifications.map((notification, index) => (
                <Notification
                  key={index}
                  title={notification.title}
                  description={notification.description}
                  date={notification.created_date}
                  isNew={newNotifications.some(newNotif => newNotif.created_date === notification.created_date)}
                />
              ))}
          </div>
        </OffCanvasBody>
      </OffCanvas>
    </div>
  );
};

export default Announcements;
