import React from 'react'
import PageWrapper from '../../../../../../layout/PageWrapper/PageWrapper'
import Page from '../../../../../../layout/Page/Page'
import style from "./PathsContainer.module.scss"
import { nanoid } from "nanoid";
import SinglePath from '../../../../lessons/components/1-SinglePath';

const PathsContainer = ({trails}) => {
  const data = (trails && trails.length > 0) && trails.filter((item)=>item.courses.length > 0)
  return (
    <PageWrapper>
    <Page>
  <div className={`${style.pathsContainer} d-flex flex-column mx-2`}>
    {data ? data.map((path) => (
      <SinglePath path={path} key={nanoid()} />
    )) : <div>Kayıtlı patika bulunamadı</div>}
  </div>
  </Page>
  </PageWrapper>
  )
}

export default PathsContainer