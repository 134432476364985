import React from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../../components/bootstrap/Modal";
import Logo from "../../../../../components/Logo";
import Icon from "../../../../../components/icon/Icon";
import Button from "../../../../../components/bootstrap/Button";
import FormGroup from "../../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../../components/bootstrap/forms/Input";
import { useFormik } from "formik";
import {
  useClearCacheMutation,
} from "../../../../../store/features/apiSlice";
import showNotification from "../../../../../components/extras/showNotification";
import _ from "underscore";
import { useUpdateCourseTeacherMutation } from "../teacherEducationApi";

const CreateChapterModal = ({
  isOpenNewCourseModal,
  setIsOpenNewCourseModal,
  data,
}) => {
  const [updateCourse] = useUpdateCourseTeacherMutation();
  const [clearCache] = useClearCacheMutation();

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Ünitenin adını giriniz";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      id:null,
      title: "",
      order_number: 9999,
      lessons: [
        {
          title: "Örnek video",
          order_number: 9999,
          description: "Bu video taslak olarak konulmuştur.",
          video: `<iframe width="1118" height="629" src="https://www.youtube.com/embed/lbhbT0hu8Z4" title="CodeLab Platformu | Bilişim School" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
          content_id:null,
        },
      ],
    },
    validate,
    onSubmit: () => {
      handleChapter();
    },
  });

  const handleChapter = async () => {
    const chapterValues = { ...formik.values };
    let newChapters = data && [...data.chapters, chapterValues];
    newChapters = _.sortBy(newChapters,"order_number") 
    const response = await updateCourse({
      id: data.id,
      title: data.title,
      ...data,
      chapters: newChapters,
    });
    if (response.error) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Kayıt Başarısız</span>
        </span>,
        "Bir hata oluştu",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Kayıt Başarılı</span>
        </span>,
        "Ünite başarıyla eklendi.",
        "success"
      );
    }
    clearCache();
    setIsOpenNewCourseModal(false);
    formik.resetForm();
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); 
      saveForm(); 
    }
  };
  const saveForm = async () => {
    try {
      await formik.handleSubmit();
      setIsOpenNewCourseModal(false); 
    } catch (error) {
      
    }
  };
  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenNewCourseModal}
      setIsOpen={setIsOpenNewCourseModal}
      titleId="example-title"
      size={null}
      onKeyDown={handleKeyDown} 
    >
      <ModalHeader setIsOpen={setIsOpenNewCourseModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} /> <span className="ps-2">Yeni Ünite</span>
          <span className="ps-2">
            <Icon icon="Computer" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="row gap-3 d-flex justify-content-center">
          <div className="col-lg-8">
            <FormGroup
              // className="col-4"
              id="title"
              isFloating
              label="Başlık"
            >
              <Input
                onChange={formik.handleChange}
                value={formik.values.title}
                onBlur={formik.handleBlur}
                isValid={formik.isValid}
                isTouched={formik.touched.title}
                invalidFeedback={formik.errors.title}
              />
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup isFloating label="Sıra Numarası" id="order_number">
              <Input
                name="order_number"
                type="text"
                autoComplete="order_number"
                value={formik.values.order_number}
                onChange={formik.handleChange}
                isValid={formik.isValid}
                onBlur={formik.handleBlur}
                isTouched={formik.touched.order_number}
                invalidFeedback={formik.errors.order_number}
              />
            </FormGroup>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={async () => {
            formik.handleSubmit();
          }}
        >
          Kaydet
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateChapterModal;
