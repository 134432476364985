import { IconButton } from "@mui/material";
import React from "react";
import Button from "../../../../components/bootstrap/Button";
import { CardBody } from "../../../../components/bootstrap/Card";
import COURSE_STATUS from "../../../../common/data/enumCourseState";
import { Delete, Edit } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import showNotification from "../../../../components/extras/showNotification";
import Icon from "../../../../components/icon/Icon";
import CreatePathModal from "./3-CreatePathModal";
import Confirm from "../../../common/Confirm";
import { nanoid } from "nanoid";
import {
  useDeleteTrailMutation,
  useTrailsQuery,
} from "../../../../store/features/apiSlice";
import UpdatePathModal from "./4-UpdatePathModal";

const PathListTable = () => {
  const isPartner = localStorage.getItem("schoolStatus")==="partner";
  const schoolStatusParam = `school_status=${isPartner ? 3 : 1}`;
  // mutations and queries
  const { data} = useTrailsQuery(schoolStatusParam, {skip:!schoolStatusParam});
  const [deleteTrail] = useDeleteTrailMutation();

  //states
  const [search, setSearch] = useState("");
  const [filteredPaths, setFilteredPaths] = useState("");
  const [isOpenNewCourseModal, setIsOpenNewCourseModal] = useState(false);
  const [isOpenUpdateTrailModal, setIsOpenUpdateTrailModal] = useState(false);
  const [trailId, setTrailId] = useState(null);
  const [open, setOpen] = useState(false);

  //useEffects
  useEffect(() => {
    filterPaths();
    //eslint-disable-next-line
  }, [search, data]);

  //functions
  const filterPaths = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const newPaths =
      data &&
      data.filter(
        (item) => item.title && item.title.toLowerCase().includes(search)
      );
    setFilteredPaths(newPaths);
  };

  const addTrail = () => {
    setIsOpenNewCourseModal(true);
  };

  const updateTrail = (e) => {
    setTrailId(e.currentTarget.id && e.currentTarget.id);
    setIsOpenUpdateTrailModal(true);
  };

  //! delete trail--------
  const removeTrail = (e) => {
    // console.log(e.currentTarget.id);
    setOpen(true);
    setTrailId(e.currentTarget.id && e.currentTarget.id);
  };
  const handleDelete = async () => {
    const response = await deleteTrail(trailId);
    setOpen(false);
    if (response.error) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Silme İşlemi Başarısız</span>
        </span>,
        "Bir hata meydana geldi",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Silme Başarılı</span>
        </span>,
        "Ders başarıyla silindi.",
        "success"
      );
    }
  };
  const handleCancel = () => setOpen(false);
  //!-----------------------------------------------
  return (
    <>
      <CreatePathModal
        isOpenNewCourseModal={isOpenNewCourseModal}
        setIsOpenNewCourseModal={setIsOpenNewCourseModal}
      />
      <UpdatePathModal
        isOpenUpdateTrailModal={isOpenUpdateTrailModal}
        setIsOpenUpdateTrailModal={setIsOpenUpdateTrailModal}
        trailId={trailId}
      />
      <Confirm
        open={open}
        setOpen={setOpen}
        onConfirm={handleDelete}
        onCancel={handleCancel}
      />
      <div className="row">
        <div className="col-3">
          <Button
            color="info"
            className={`ms-5 mb-4 shadow-3d-up-hover}`}
            onClick={() => addTrail()}
          >
            Yeni Patika Ekle
          </Button>
        </div>
        <div className="col-6">
          <form className="d-flex gap-1" onSubmit={filterPaths}>
            <input
              className="form-control"
              type="search"
              placeholder="Aramak istediğiniz patikayı yazınız"
              value={search}
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
            />
            <button className="btn btn-warning w-25">Bul</button>
          </form>
        </div>
      </div>
      <CardBody>
        <table className="table table-modern">
          <thead style={{ fontSize: "16px" }}>
            <tr>
              <th>Başlık</th>
              <th>Açıklama/Not</th>
              <th style={{ textAlign: "center" }}>Sırası</th>
              <th className="text-center">Aktiflik</th>
              <th className="text-center">Sil</th>
              <th style={{ textAlign: "center" }}>Ders Detayı</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "16px" }}>
            {filteredPaths
              ? filteredPaths.map((course) => {
                  const { id, title, description, order_number, status } =
                    course;
                  return (
                    <tr key={nanoid()}>
                      <td>{title}</td>
                      <td> {description}</td>
                      <td style={{ textAlign: "center" }}>{order_number}</td>
                      <td className="text-center">
                        {status === COURSE_STATUS.ACTIVE.id
                          ? "Aktif"
                          : status === COURSE_STATUS.DRAFT.id
                          ? "Taslak"
                          : "Pasif"}
                      </td>
                      <td className="text-center">
                        <IconButton id={id} onClick={(e) => removeTrail(e)}>
                          <Delete
                            className="fs-2"
                          />
                        </IconButton>
                      </td>
                      <td className="text-center">
                        <IconButton id={id} onClick={(e) => updateTrail(e)}>
                          <Edit
                            className="fs-2"
                          />
                        </IconButton>
                      </td>
                    </tr>
                  );
                })
              : data &&
                data.map((course) => {
                  const { id, title, description, order_number, status } =
                    course;
                  return (
                    <tr key={nanoid()}>
                      <td>{title}</td>
                      <td> {description}</td>
                      <td style={{ textAlign: "center" }}>{order_number}</td>
                      <td className="text-center">
                        {status === COURSE_STATUS.ACTIVE.id
                          ? "Aktif"
                          : status === COURSE_STATUS.DRAFT.id
                          ? "Taslak"
                          : "Pasif"}
                      </td>
                      <td className="text-center">
                        <IconButton id={id} onClick={(e) => removeTrail(e)}>
                          <Delete
                            className="fs-2"
                          />
                        </IconButton>
                      </td>
                      <td className="text-center">
                        <IconButton id={id} onClick={(e) => updateTrail(e)}>
                          <Edit
                            className="fs-2"
                          />
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </CardBody>
    </>
  );
};

export default PathListTable;
