import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { showError, showSuccess } from "../../../../profilPage/utils";
import Modal, { ModalBody, ModalFooter } from "../../../../../../components/bootstrap/Modal";
import CustomFormField from "../../../../../../common/customFormField/CustomFormField";
import Button from "../../../../../../components/bootstrap/Button";
import Spinner from "../../../../../../components/bootstrap/Spinner";

// Varsayılan dosyayı oluştur
const defaultFileContent = new Blob(["This is the default file content"], { type: 'text/plain' });
const defaultFile = new File([defaultFileContent], 'defaultFile.txt', { type: 'text/plain' });

const AnnouncementModal = ({
  isOpen,
  setIsOpen,
  selectedAnnouncement,
  updateAnnouncement,
  addAnnouncement,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  // eslint-disable-next-line
  const [file, setFile] = useState(defaultFile);

  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = "Başlık giriniz";
    }
    if (!values.description) {
      errors.description = "Açıklama giriniz";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      id:"",
      title: "",
      description: "",
    },
    validate,
    onSubmit: async (values) => {
      saveAnnouncement(values);
    },
  });
    //side effects
    useEffect(() => {
      initialize();
      //eslint-disable-next-line
    }, [selectedAnnouncement]);
    const initialize = async () => {
      formik.resetForm();
      if (selectedAnnouncement) {
        formik.setValues(selectedAnnouncement);
      }
    };

  const saveAnnouncement = async (values) => {
    const formData = new FormData();
    formData.append("id", values.id);
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("image", file);

    try {
      const response = selectedAnnouncement ? await updateAnnouncement({id:values.id, formData}) : await addAnnouncement(formData);
      if (response.error) {
        showError();
      } else {
        showSuccess(selectedAnnouncement ? "Duyuru Güncellendi" : "Duyuru Eklendi");
        formik.resetForm();
        setIsOpen(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      titleId="example-title"
      size={null}
    >
      <ModalBody>
        <form
          onSubmit={formik.handleSubmit}
        >
          <div className="col-12">
            <CustomFormField
              type="text"
              label="Başlık"
              name="title"
              formik={formik}
            />
          </div>
          <div className="col-12">
            <CustomFormField
              type="textArea"
              label="Açıklama"
              name="description"
              formik={formik}
              style={{ height: "150px" }}
            />
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          isDisable={isSubmitting}
          onClick={() => formik.handleSubmit()}
        >
          {isSubmitting ? (
            <Spinner inButton isSmall>
              Loading...
            </Spinner>
          ) : (
            "Gönder"
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AnnouncementModal;
