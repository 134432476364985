// import classNames from "classnames";
import React, { useContext } from "react";
// import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import Card, {
//   CardBody,
//   CardTitle,
// } from "../../../../components/bootstrap/Card";
// import useDarkMode from "../../../../hooks/useDarkMode";
// import useTourStep from "../../../../hooks/useTourStep";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import { studentPages } from "../../../../menu";
import { get, post } from "../../../../utils/service";
import CodeEditor from "./CodeEditor";
import PracticeResult from "./PracticeResult";
import TestTable from "./TestTable";
import { AuthContext } from "../../../../store/auth-store";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../../layout/SubHeader/SubHeader";
import Breadcrumb from "../../../../components/bootstrap/Breadcrumb";
import Button from "../../../../components/bootstrap/Button";

function indentCode(code) {
  if (!code) return "";

  var lines = code.split("\n");
  lines = lines.map((line) => "    " + line + "\n");
  let user_code = lines.join("");

  return user_code;
}
const Test = () => {
  const [practice, setPractice] = useState(null)
  const [userCode, setUserCode] = useState("");
  const [codeRunResult, setCodeRunResult] = useState(null);
  const [codeEditorHeight, setCodeEditorHeight] = useState(500);
  const { id } = useParams();
  const {user} = useContext(AuthContext);
  const navigate = useNavigate();

  let practiceUrl = "";
  if(user.roles === 2){
    practiceUrl = "practices/teachers/"
  }else{practiceUrl = "practices/"}

  const getPractice = async () => {
    const result = await get(`${practiceUrl}`+id);
    // console.log("çekilen practice:",result.data)
    setPractice({...result.data});
    setUserCode(result.data.template_code || '');
  };

  const handleUserCodeChange = (value) => {
    setUserCode(value);
  };

  const onUserCodeFocus = (event) => {
    setCodeEditorHeight(500);
  };

  async function submitPractice(id, path_id, code) {
    let postData = {
      user_code: indentCode(userCode),
      run_type: "submit",
      additional_code: practice.additional_code,
      tests:practice.tests.map(t =>({
        test_id: t.id,
        inputs: t.inputs,
        expected_outputs: t.expected_outputs,
        expected_outputs_for_ui:t.expected_outputs_for_ui,
        input_type: t.input_type
      }))
    };

    var res = await post("practices/test_run/", postData);

    setCodeRunResult(res.data);
  }



  useEffect(() => {
    getPractice();
    //eslint-disable-next-line
  }, [id]);

  let allPracticeUrl = "";
  let editUrl="";
  let newPracticeUrl="";
  if(user.roles === 2){
    allPracticeUrl = "teacher/practices/"
    editUrl="/teacher/practice-form/"
    newPracticeUrl="/teacher/practice-form/"
  }else{allPracticeUrl = "admin/practices/";
        editUrl="/admin/practice-form/";
        newPracticeUrl="/admin/practice-form/"}

  
  return (
    <PageWrapper title={studentPages.practice.text}>
      <SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						list={[
							{ title: 'Alıştırmalar', to: `/${allPracticeUrl}` },
							{ title: 'Test', to: '/' },
						]}
					/>
					<SubheaderSeparator />
				</SubHeaderLeft>
				<SubHeaderRight>
					<Button
          className="btn btn-info"
         onClick={()=>{navigate(`${editUrl}`+id)}}
					>
						Düzenle
					</Button>
					<Button
          className="btn btn-success"
          onClick={()=>{navigate(`${newPracticeUrl}`)}}
					>
						Yeni Alıştırma Ekle
					</Button>
				
				</SubHeaderRight>
			</SubHeader>
      <Page container="fluid">
        <div className="row mb-1">
          <div className="col-xl-4 col-lg-4 col-md-4">
            {practice && <TestTable practice={practice} />}
          </div>
          <div className="col-xl-8 col-lg-8 col-md-8">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <CodeEditor
                  handleUserCodeChange={handleUserCodeChange}
                  onUserCodeFocus={onUserCodeFocus}
                  userCode={userCode}
                  codeEditorHeight={codeEditorHeight}
                  template_code={practice ? practice.template_code : null}
                />
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <PracticeResult
                  submitPractice={submitPractice}
                  practice={practice}
                  userCode={userCode}
                  // path_id={1}
                  codeRunResult={codeRunResult}
                />
              </div>
            </div>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default Test;
