const activationStatusData = {
    1: {
      color: "danger",
      text: "Pasif",
    },
    2: {
      color: "success",
      text: "Aktif",
    },
    3: {
      color: "warning",
      text: "Kullanımda",
    },
  };

  export function getActivationStatusColor(status) {
    return activationStatusData[status]?.color ||activationStatusData[1]?.color;
  }

  export function getActivationStatusText(status) {
    return activationStatusData[status]?.text || activationStatusData[1]?.text;
  }

