import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import SubHeader, {
  SubHeaderLeft,
} from "../../../../../layout/SubHeader/SubHeader";
import Button from "../../../../../components/bootstrap/Button";
import DashboardHeader from "../../../../common/Headers/DashboardHeader";
import Page from "../../../../../layout/Page/Page";
import {
  useSingleGuidanceForTeacherQuery,
  useSinglePresentationForTeacherQuery,
} from "../../../admin/course management for teacher/teacherEducationApi";

const GuidanceDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [guidancePr, setGuidancePr] = useState(false);
  const [presentationPr, setPresentationPr] = useState(false);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const guidanceParam = searchParams.get("type");

    if (guidanceParam) {
      if (guidanceParam === "guidance") setGuidancePr(true);
      if (guidanceParam === "presentation") setPresentationPr(true);
    }
  }, []);

  const { data: guidances } = useSingleGuidanceForTeacherQuery({ id });
  const { data: presentations } = useSinglePresentationForTeacherQuery({ id });

  let guidanceSections = guidances && guidances;
  let presentationSections = presentations && presentations;

  const data = guidancePr
    ? guidanceSections
    : presentationPr
    ? presentationSections
    : [];
  const page = guidancePr ? "Rehber" : "Sunum";

  const showDetail = (guideId, guide) => {
    localStorage.setItem("guide", JSON.stringify(guide));
    navigate(
      `/teacher/coursesForTeacher/guidances/${id}/guideContent/${guideId}`
    );
  };

  let guides;

  if (guidancePr) {
    guides = data && data.guides?.length > 0 ? data.guides : [];
  } else {
    guides = data && data.slides?.length > 0 ? data.slides : [];
  }

  return (
    <PageWrapper>
      <DashboardHeader />
      <SubHeader className={"mt-5 pt-3 mt-md-0 pt-md-0"}>
        <SubHeaderLeft>
          <Button color="info" isLink icon="ArrowBack" tag="a" onClick={()=>navigate(-1)}>
            {`${page}`} Sayfasına Geri Dön
          </Button>
        </SubHeaderLeft>
      </SubHeader>
      <Page>
        <div className="row">
          <div className="col-lg-12">
            <div className="card-header px-0 bg-transparent">
              <div className="card-label">
                <div className="card-title-wrapper">
                  <h5 className="card-title">{data && data.title}</h5>
                </div>
              </div>
            </div>
            {guides.map((guide) => {
              const { id: guideId, title } = guide;
              return (
                <div className="card " key={guideId}>
                  <div className="card-body">
                    <div className="row g-3 align-items-center">
                      <div className="col d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <div
                            className="ratio ratio-1x1"
                            style={{ width: "72px" }}
                          >
                            <div className="rounded-2 d-flex align-items-center justify-content-center bg-l10-info">
                              <img
                                src={`/images/${data.cover_image}.png`}
                                alt="Bank Of America"
                                width={50}
                                height="50px"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex-grow-1 ms-3 d-flex justify-content-between align-items-center">
                          <div>
                            <div className="fw-bold fs-6 mb-0">{title}</div>
                            <div className="text-muted">
                              <small>
                                Seviye:{" "}
                                <span className="text-normal fw-bold">
                                  Kolay
                                </span>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto">
                        <Button
                          className={`bg-l10-info text-info fw-bold px-3 py-2 rounded-pill}`}
                          size="lg"
                          tag="a"
                          // to={url}
                          //   onClick={navigate(`/guideContent/${guideId}`)}
                          onClick={(e) => showDetail(guideId, guide)}
                        >
                          Başla
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default GuidanceDetail;
