import React from "react";
import Header, { HeaderLeft } from "../../../layout/Header/Header";
import CommonHeaderRight from "./CommonHeaderRight";

const DashboardHeaderTitle = (props) => {
  return (
    <Header>
      <HeaderLeft>
        <div className="text-muted">
          <strong>
            <span className="text-info fw-bold">{props.title}</span>
          </strong>
        </div>
      </HeaderLeft>
      <CommonHeaderRight />
    </Header>
  );
};

export default DashboardHeaderTitle;
