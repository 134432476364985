import React from "react";
import { styled } from "@mui/material/styles";
// import Paper from "@mui/material/Paper";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import style from "./TestResult.module.css";

const Item = styled(Typography)`
color:#098919
`;
const Fail = styled(Typography)`
color:#b80d28
`;
/* const Success = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: "#098919",
})); */

function TestResult({ test }) {
  let testName = test.successful ? (
    <Item>{"Test #" + test.test_id}</Item>
  ) : (
    <Fail>{"Test #" + test.test_id}</Fail>
  );

  return (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{testName}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className={style.testparams}>
            Test Girdileri: <span className={style.inputs}> {test.inputs} </span>
            </div>
            <Divider variant="fullWidth" />
            <div className={style.testparams}>
            Beklenen Çıktı: <span className={style.expected}> {test.expected_outputs_for_ui}</span>
            </div>
            <Divider variant="fullWidth" />
            <div className={style.testparams}>
            Program Çıktısı: <span className={style.actual}> {test.test_output || "YOK"}</span>
            </div>
          </AccordionDetails>
        </Accordion>
  );
}

export default TestResult;
