import React from "react";
import Dropdown, {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "../../../../components/bootstrap/Dropdown";
import Button from "../../../../components/bootstrap/Button";
import style from "./CodeEditor.module.css";

const modes = [
  "python",
  "javascript",
  "java",
  "csharp",
  "html",
  "css",
  "golang",
  "ruby",
  "mysql",
];

const themes = [
  { name: "Koyu (Default)", value: "monokai" },
  { name: "Alacakaranlık", value: "twilight" },
  { name: "Açık", value: "tomorrow" },
  { name: "Terminal", value: "terminal" },
  { name: "Gri", value: "kuroir" },
  { name: "Beyaz", value: "github" },
  { name: "Xcode", value: "xcode" },
  { name: "Textmate", value: "textmate" },
];

const CodeEditorToolbar = ({ mode, theme, setMode, setTheme }) => {
  return (
    <div>
      <div className={style.container}>
        <div className={style.item}>
          <Dropdown
            direction="down" // 'up' || 'end' || 'down' || 'start'
            isButtonGroup={true}
          >
            <DropdownToggle hasIcon={false}>
              <Button icon="ArrowDropDown" isLight color="success" rounded="0">
              {mode}
              </Button>
            </DropdownToggle>
            <DropdownMenu size="md">
              {modes.map((mode) => (
                <DropdownItem isHeader={true}>
                  {mode!=="python" ?  
                  <Button isDisable rounded="0">
                    {mode}
                  </Button> : 
                   <Button  onClick={() => setMode(mode)} rounded="0">
                   {mode}
                 </Button>}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className={style.item}>
          <Dropdown isButtonGroup={true}>
            <DropdownToggle hasIcon={false}>
            <Button icon="ArrowDropDown" isLight color="success" rounded="0">
              {theme}
              </Button>
            </DropdownToggle>
            <DropdownMenu size="sm">
              {themes.map((theme) => (
                <DropdownItem isHeader={true}>
                  <Button onClick={() => setTheme(theme)} rounded="0">
                    {theme.name}
                  </Button>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default CodeEditorToolbar;
