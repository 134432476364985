import React from 'react'
import ArticleListTable from './2-ArticleListTable'
import Page from '../../../../../../layout/Page/Page'

const ArticleManagement = () => {
  return (
    <>
    <Page container="fluid">
      <ArticleListTable/>
    </Page>
  </>
  )
}

export default ArticleManagement