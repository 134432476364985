import React from "react";
import style from "./lessonsContent.module.scss";
import {useDispatch } from "react-redux";
import Lecture from "./3-Lecture";
import Icon from "../../../../../../components/icon/Icon";
import Button from "../../../../../../components/bootstrap/Button";
import { useNavigate } from "react-router-dom";
import USER_SUBSCRIPTION_TYPE from "../../../../../../common/data/enumUserSubscriptionType";
import { setCurrentChapterForTeacher } from "../../../teacherLessonVideoSlice";

const Chapter = ({ chapter, index, lessonId, openChapterIndex }) => {
  const userSubscriptionType = localStorage.getItem("subscription_type");
  // const userSubscriptionType = 1
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isOpen = openChapterIndex === index;

  const { title, lessons: videos, learning_path } = chapter;

  const setA = (video) => {
    dispatch(setCurrentChapterForTeacher({ index, lessonId, video }));
  };
  const showModal = () => {
    if (index === openChapterIndex) {
      dispatch(setCurrentChapterForTeacher({ index: -1, lessonId }));
    } else {
      dispatch(setCurrentChapterForTeacher({ index, lessonId }));
    }
  };

  return (
    <div className={`${style.lesson} row g-3 mb-1`}>
      {
        <div className="section">
          <div
            className="col-12 d-flex align-items-center justify-content-between"
            style={{ minHeight: "53px", cursor: "pointer" }}
            index={index}
            onClick={() => showModal(index)}
          >
            <Button
              className={`${style.lessonTitle} w-100 p-3`}
              isLight={!isOpen}
              color="info"
            >
              <span className="d-flex gap-1 text-start">
                {index + 1}. <span className="d-flex">{title}</span>
              </span>

              <span style={{ cursor: "pointer" }}>
                {!isOpen ? (
                  <Icon icon="KeyboardArrowRight" size="2x" />
                ) : (
                  <Icon icon="KeyboardArrowDown" size="2x" />
                )}
              </span>
            </Button>
          </div>
          <div>
            {isOpen && (
              <>
                {videos?.map((video) => (
                  <Lecture
                    video={video}
                    key={video.id}
                    index={index}
                    lessonId={lessonId}
                    setVideo={setA}
                  />
                ))}
                {
                  <div
                    style={{
                      display: `${
                        (!learning_path ||
                          Number(userSubscriptionType) !==
                            USER_SUBSCRIPTION_TYPE.PREMIUM.id) &&
                        "none"
                      }`,
                    }}
                  >
                    <div
                      className={`${style.lessonPractice} d-flex align-items-center gap-1 ps-3`}
                      onClick={() => {
                        learning_path &&
                          navigate(`/learning-path/${learning_path}`);
                      }}
                    >
                      <span className="d-flex align-items-center gap-3 my-3 text-success">
                        <Icon icon="Flag" color={"success"} size={"lg"} /> Konu
                        İle İlgili Alıştırmalar
                      </span>
                    </div>
                  </div>
                }
              </>
            )}
          </div>
        </div>
      }
    </div>
  );
};

export default Chapter;
