import React, { useEffect, useState } from "react";
import Card, { CardBody } from "../../../../components/bootstrap/Card";
import Button from "../../../../components/bootstrap/Button";
import { Delete, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Icon from "../../../../components/icon/Icon";
import Confirm from "../../../common/Confirm";
import showNotification from "../../../../components/extras/showNotification";
import {
  useDeleteSchoolMutation,
  useGetSchoolsQuery,
} from "../../teacher/teacherApi";
import ShowSchoolModal from "./3-ShowSchoolModal";
import useSortableData from "../../../../hooks/useSortableData";
import { useNavigate } from "react-router-dom";
import _ from "underscore";
import HoverableItem from "../../../common/HoverableItem";

const SchoolListTable = () => {
  const navigate = useNavigate();
  const { data } = useGetSchoolsQuery();
  const [deleteSchool] = useDeleteSchoolMutation();

  const [search, setSearch] = useState("");
  const [filteredSchools, setFilteredSchools] = useState("");

  const { items } = useSortableData(
    filteredSchools?.length > 0 ? filteredSchools : data ? data : []
  );

  const sortedSchools = _.sortBy(items, "id");

  const filterSchools = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const newSchools =
      data &&
      data.filter(
        (item) => item.title && item.title.toLowerCase().includes(search)
      );
    setFilteredSchools(newSchools);
  };

  useEffect(() => {
    filterSchools();
    //eslint-disable-next-line
  }, [search, data]);

  const [isOpenShowSchoolModal, setIsOpenShowSchoolModal] = useState(false);
  const [schoolId, setSchoolId] = useState(null);
  const [open, setOpen] = useState(false);

  const addSchool = () => {
    setSchoolId(null);
    setIsOpenShowSchoolModal(true);
  };

  //! delete course--------
  const removeSchool = (e) => {
    setOpen(true);
    setSchoolId(e.currentTarget.id && e.currentTarget.id);
  };
  const handleDelete = async () => {
    const response = await deleteSchool(schoolId);
    setOpen(false);
    if (response.error) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Silme İşlemi Başarısız</span>
        </span>,
        "Bir hata meydana geldi",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Silme Başarılı</span>
        </span>,
        "Kurum başarıyla silindi.",
        "success"
      );
    }
  };
  const handleCancel = () => setOpen(false);
  //!-----------------------------------------------

  // update school
  const updateSchool = (e) => {
    setSchoolId(e.currentTarget.id && e.currentTarget.id);
    setIsOpenShowSchoolModal(true);
  };

  const handleSchool = (id, title, logo) => {
    navigate(`/admin/classManagement/${id}`);
    localStorage.setItem("schoolName", title);
    localStorage.setItem("schoolLogo", logo);
    localStorage.setItem("schoolId", id);
  };
  const handleSchoolContentManagement = (id, title, logo) => {
    navigate(`/admin/schoolContentManagement`);
    localStorage.setItem("schoolName", title);
    localStorage.setItem("schoolLogo", logo);
    localStorage.setItem("schoolId", id);
  };
  const handleSchoolUserManagement = (id, title, logo) => {
    navigate(`/admin/schoolUserManagement`);
    localStorage.setItem("schoolName", title);
    localStorage.setItem("schoolLogo", logo);
    localStorage.setItem("schoolId", id);
  };
  const handleSchoolRestrictions = (id, title, logo) => {
    navigate(`/admin/schoolRestrictions`);
    localStorage.setItem("schoolName", title);
    localStorage.setItem("schoolLogo", logo);
    localStorage.setItem("schoolId", id);
  };
  return (
    <Card>
      <ShowSchoolModal
        isOpenShowSchoolModal={isOpenShowSchoolModal}
        setIsOpenShowSchoolModal={setIsOpenShowSchoolModal}
        schoolId={schoolId}
      />
      <Confirm
        open={open}
        setOpen={setOpen}
        onConfirm={handleDelete}
        onCancel={handleCancel}
      />
      <div className="row">
        <div className="col-3">
          <Button
            color="info"
            className={`ms-5 mb-4 shadow-3d-up-hover}`}
            onClick={() => addSchool()}
          >
            Yeni Kurum Ekle
          </Button>
        </div>
        <div className="col-6">
          <form className="d-flex gap-1" onSubmit={filterSchools}>
            <input
              className="form-control"
              type="search"
              placeholder="Aramak istediğiniz kurumu yazınız"
              value={search}
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
            />
            <button className="btn btn-warning w-25">Bul</button>
          </form>
        </div>
      </div>
      <CardBody>
        <table className="table table-modern">
          <thead style={{ fontSize: "16px" }}>
            <tr>
              <th>Başlık</th>
              <th className="text-center">ID</th>
              <th className="text-center">Kısıtlamalar</th>
              <th className="text-center">Kullanıcı Yönetimi</th>
              <th className="text-center">İçerik Yönetimi</th>
              <th className="text-center">Sınıf Yönetimi</th>
              <th className="text-center">Eylemler</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "16px" }}>
            {items.length === 0 ? (
              <div>
                <span>Kayıtlı kurum bulunamadı</span>
              </div>
            ) : (
              sortedSchools.map((school) => {
                const { id, title, name:logo } = school;
                return (
                  <tr key={id}>
                    <td>{title}</td>
                    <td className="text-center">{id}</td>
                    <td className="text-center">
                      <HoverableItem icon={<Icon
                        size="2x"
                        onClick={() => handleSchoolRestrictions(id, title, logo)}
                        className="cursor-pointer"
                        icon={"Business"}
                      />}/>
                    </td>
                    <td className="text-center">
                    <HoverableItem icon={<Icon
                        size="2x"
                        onClick={() =>
                          navigate(`/admin/userManagement/school/${id}`)
                        }
                        className="cursor-pointer mx-3"
                        icon={"ManageAccounts"}
                      />}/>
                      <HoverableItem icon={<Icon
                        size="2x"
                        onClick={() => handleSchoolUserManagement(id, title, logo)}
                        className="cursor-pointer"
                        icon={"code"}
                      />}/>
                    </td>
                    <td className="text-center">
                    <HoverableItem icon={<Icon
                        size="2x"
                        onClick={() => handleSchoolContentManagement(id, title, logo)}
                        className="cursor-pointer"
                        icon={"MenuBook"}
                      />}/>
                    </td>
                    <td className="text-center">
                    <HoverableItem icon={<Icon
                        size="2x"
                        onClick={() => handleSchool(id, title, logo)}
                        className="cursor-pointer"
                        icon={"CalendarViewMonth"}
                      />}/>
                    </td>
                    <td className="text-center">
                      <IconButton  id={id} onClick={(e) => removeSchool(e)}>
                        <Delete
                          className="fs-2"
                        />
                      </IconButton>
                      <IconButton id={id} onClick={(e) => updateSchool(e)}>
                        <Edit
                          className="fs-2"
                        />
                      </IconButton>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
};

export default SchoolListTable;
