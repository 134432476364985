import React, { useEffect, useState } from "react";
import style from "./TeacherHeader.module.scss";
import { useGetTotalStudentQuery } from "../../../../../store/features/apiSlice";
import bannerStudent from "../../../../../assets/dashboardImages/bannerStudent.png";
import defaultSchool from "../../../../../assets/school/defaultSchoolLogo.png";
import { useGet_a_SchoolQuery } from "../../teacherApi";
import { getSchoolLogo } from "../../../schoolManager/dashboard/school-manager-header/SchoolManagerHeader";
import Spinner from "../../../../../components/bootstrap/Spinner";

const TeacherHeader = () => {
  const [loading, setLoading] = useState(true);
  const school = localStorage.getItem("school");
  const { data: schoolData } = useGet_a_SchoolQuery(school, { skip: !school });
  const { data: total } = useGetTotalStudentQuery();

  function convertUsername(username) {
    if (typeof username !== "string") {
        return '';
    }
    username = username.replace(/i/g, "İ");
    username = username.toUpperCase();
    return username;
}


  let name = convertUsername(localStorage.getItem("name"));
  let surName = convertUsername(localStorage.getItem("surname"));
  const schoolName = schoolData && schoolData.title;
  const spinner = (
    <Spinner color="primary" style={{ marginTop: "20px" }} />
  )

const [schoolLogo, setSchoolLogo] = useState(null);

useEffect(() => {
  async function fetchData() {
    const logoUrl = await getSchoolLogo(school);
    if (logoUrl) {
      setSchoolLogo(logoUrl);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }
  fetchData();
}, [school]);
  return (
    <div
      className={`${style.teacherHeaderContainer} my-3 d-flex align-items-center justify-content-between`}
    >
      <img className={`${style.banner}`} src={bannerStudent} alt="" />
      <div className={`${style.leftSection}`}>
        <div className={`${style.teacherHeaderLeft}`}>
          {name} {surName}
        </div>
        <div className={`${style.teacherHeaderRight}`}>
          <div
            className={`${style.left} d-flex flex-column align-items-center`}
          >
            <p>Sınıf</p>
            <span>{total?.total_classes}</span>
          </div>
          <div className={`${style.center}`}></div>
          <div
            className={`${style.right} d-flex flex-column align-items-center`}
          >
            <p>Öğrenci</p>
            <span>{total?.total_students}</span>
          </div>
        </div>
      </div>
      {school > 0 &&
        <div className={`${style.rightSection}`}>
          {loading ? spinner: <div className={`${style.schoolLogo}`}>
              {!schoolLogo ? (
            <img src={defaultSchool} alt="" />
          ) : (
            <img
              src={schoolLogo}
              alt=""
              width={64}
              height={64}
              className="mx-auto d-block"
            />
          )}
          </div>}
          <div className={`${style.schoolNameContainer}`}>
            <div className={`${style.schoolNameShort}`}>{schoolName}</div>
            <div className={`${style.schoolNameFull}`}>{schoolName}</div>
          </div>
        </div>
      }
    </div>
  );
};

export default TeacherHeader;
