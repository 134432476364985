import style from "./CompetitionCard.module.scss";
import Button from "../../../../../../components/bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { getDayAndMonthAsString } from "../../../../../../utils/formatDate";
import { COMPETITION_KIND } from "../../../../../../common/data/enumCompetition";
import { useGet_a_Competition_ThumbnailQuery } from "../../../../admin/talent cup/talentCupApi";
import { getCompetitionTypeColor } from "../../competitionFunctions";
import TalentResponsiveImage from "../../../../admin/talent cup/components/TalentResponsiveImage";
import { Alert, Spinner } from "react-bootstrap";

const CompetitionCard = ({ competition }) => {
  const competitionId = competition?.id;
  const { data: logoUrl, error, isLoading } = useGet_a_Competition_ThumbnailQuery(competitionId, {skip:!competitionId});

  const navigate = useNavigate();
  function showCardDetail() {
    navigate(`./CompetitionDetail/${competitionId}`);
  }
  return (
    <div className={`${style.competitionCard} card p-2`}>
      <div className="col-12 p-2">
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '200px' }}>
      {isLoading ? (
        <div className="text-center">
          <Spinner animation="grow" role="status" style={{ width: '3rem', height: '3rem', color: '#007bff' }}>
            <span className="sr-only">Loading...</span>
          </Spinner>
          <p className="mt-2">Logo yükleniyor...</p>
        </div>
      ) : error ? (
        <Alert variant="danger" className="text-center">
          <i className="fas fa-exclamation-triangle" style={{ color: '#dc3545', fontSize: '1.5rem' }}></i>
          <p className="mt-2">Error fetching logo</p>
        </Alert>
      ) : (
        <TalentResponsiveImage src={logoUrl} />
      )}
    </div>
      </div>
      <p className={`${style.competitionTitle} mt-2`}>{competition.title}</p>
      <div className={`${style.competitionKind} mb-2 bg-${getCompetitionTypeColor(competition.kind)}`}>
        <span   className={``}>{getCompetitionKind(competition.kind)}</span>
      </div>
      <div className={`${style.applicationInfo} d-flex gap-5 my-3`}>
        <div className={`${style.item2}`}>
          <p className={`${style.title}`}>Başlangıç</p>
          <p className={`${style.date}`}>
            {getDayAndMonthAsString(competition.start_date)}
          </p>
        </div>
        <div className={`${style.item3}`}>
          <p className={`${style.title}`}>Bitiş</p>
          <p className={`${style.date}`}>
            {getDayAndMonthAsString(competition.end_date)}
          </p>
        </div>
      </div>
      <Button
        className={`${style.competitionDetail} bg-info w-75 text-light my-2`}
        onClick={() => showCardDetail()}
      >
        Detaylı Bilgi
      </Button>
    </div>
  );
};

export default CompetitionCard;

export function getCompetitionKind(kind) {
  if (kind === COMPETITION_KIND.PRACTICE) {
    return "Görev";
  } else if (kind === COMPETITION_KIND.PROJECT) {
    return "Proje";
  } else {
    return "";
  }
}
