import React, { useEffect, useState } from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../components/bootstrap/Modal";
import Logo from "../../../../components/Logo";
import Icon from "../../../../components/icon/Icon";
import Button from "../../../../components/bootstrap/Button";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import { useFormik } from "formik";
import Select from "../../../../components/bootstrap/forms/Select";
import {
  useCoursesQuery,
  useCreateTrailMutation,
} from "../../../../store/features/apiSlice";
import showNotification from "../../../../components/extras/showNotification";
import Label from "../../../../components/bootstrap/forms/Label";
import _ from "underscore";
import Textarea from "../../../../components/bootstrap/forms/Textarea";
import { useGetSchoolsQuery } from "../../teacher/teacherApi";

const CreatePathModal = ({ isOpenNewCourseModal, setIsOpenNewCourseModal }) => {
  const isPartner = localStorage.getItem("schoolStatus") === "partner";
  // mutations and queries
  const [createTrail] = useCreateTrailMutation();
  const { data } = useCoursesQuery();

  const { data: schools } = useGetSchoolsQuery();
  const schoolsList =
    schools &&
    schools.map((item) => {
      return {
        text: item.title,
        value: String(item.id),
      };
    });

  // constants
  let activeLessons = data?.filter((item) => item.status === 1);
  activeLessons = _.sortBy(activeLessons, "order_number");
  let lessons = activeLessons;

  //states
  const [selectedLessons, setSelectedLessons] = useState([]);

  //useEffects
  useEffect(() => {
    formik.setFieldValue("courses", selectedLessons);
    //eslint-disable-next-line
  }, [selectedLessons]);

  useEffect(() => {
    getSchoolStatus();
    //eslint-disable-next-line
  }, [isPartner]);

  //functions
  const handleLesson = (lessonId) => {
    if (selectedLessons.includes(lessonId)) {
      setSelectedLessons(selectedLessons.filter((id) => id !== lessonId));
    } else {
      setSelectedLessons([...selectedLessons, lessonId]);
    }
  };

  function getSchoolStatus() {
    if (isPartner) {
      formik.setFieldValue("school_status", "3");
    } else {
      formik.resetForm()
      formik.setFieldValue("school_status", "1");
    }
  }
  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Dersin adını giriniz";
    }

    if (!values.description) {
      errors.description = "Açıklama giriniz";
    }

    if (values.school_status === "3" && !values.school) {
      errors.school = "Okul seçiniz";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      courses: selectedLessons,
      order_number: "9999",
      status: "1",
      school: null,
      school_status: "",
    },
    validate,
    onSubmit: () => {
      // console.log(formik.values.courses);
      handleTrail();
    },
  });

  const handleTrail = async () => {
    const pathValues = { ...formik.values };
    const response = await createTrail(pathValues);
    if (response.error) {
      console.log(response.error);
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Kayıt Başarısız</span>
        </span>,
        "Bir hata oluştu",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Kayıt Başarılı</span>
        </span>,
        "Patika başarıyla eklendi.",
        "success"
      );
    }
    setIsOpenNewCourseModal(false);
    setSelectedLessons([]);
    formik.resetForm();
  };
  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenNewCourseModal}
      setIsOpen={setIsOpenNewCourseModal}
      titleId="example-title"
      size={null}
    >
      <ModalHeader setIsOpen={setIsOpenNewCourseModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} /> <span className="ps-2">Yeni Patika</span>
          <span className="ps-2">
            <Icon icon="Computer" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="row gap-3 d-flex justify-content-center">
          <div className="col-lg-8">
            <FormGroup
              // className="col-4"
              id="title"
              isFloating
              label="Başlık"
            >
              <Input
                onChange={formik.handleChange}
                value={formik.values.title}
                onBlur={formik.handleBlur}
                isValid={formik.isValid}
                isTouched={formik.touched.title}
                invalidFeedback={formik.errors.title}
              />
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup
              // className="col-4"
              id="description"
              isFloating
              label="Açıklama"
            >
              <Textarea
                onChange={formik.handleChange}
                value={formik.values.description}
                onBlur={formik.handleBlur}
                isValid={formik.isValid}
                isTouched={formik.touched.description}
                invalidFeedback={formik.errors.description}
                style={{ height: "150px" }}
              />
            </FormGroup>
          </div>
          <div
            className="col-lg-8 ms-3 pt-2 rounded-2"
            style={{ backgroundColor: "#F8F9FA" }}
          >
            <span className="h5">Ders Başlığı</span>
            <div
              className="mt-2"
              style={{ maxHeight: "200px", overflow: "scroll" }}
            >
              {lessons?.map((lesson) => (
                <FormGroup key={lesson.id}>
                  <Label className="d-flex">
                    <input
                      type="checkbox"
                      checked={selectedLessons.includes(lesson.id)}
                      onChange={() => handleLesson(lesson.id)}
                      name="selectedLessons"
                      value={lesson.id}
                    />
                    <span className="mx-3">{lesson.title}</span>
                  </Label>
                </FormGroup>
              ))}
            </div>
          </div>
          <div className="col-lg-8">
            <FormGroup isFloating label="Sıra Numarası" id="order_number">
              <Input
                name="order_number"
                type="text"
                autoComplete="order_number"
                value={formik.values.order_number}
                onChange={formik.handleChange}
                isValid={formik.isValid}
                onBlur={formik.handleBlur}
                isTouched={formik.touched.order_number}
                invalidFeedback={formik.errors.order_number}
              />
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup id="status">
              <Select
                placeholder="Aktiflik"
                ariaLabel="status"
                onChange={formik.handleChange}
                value={formik.values.status}
                isValid={formik.isValid}
                onBlur={formik.handleBlur}
                isTouched={formik.touched.status}
                invalidFeedback={formik.errors.status}
                list={[
                  { value: "0", text: "Pasif" },
                  { value: "1", text: "Aktif" },
                  { value: "2", text: "Taslak" },
                ]}
                className="text-dark"
              ></Select>
            </FormGroup>
          </div>
          {isPartner && (
            <>
              <div className="col-lg-8">
                <FormGroup id="school" label="Okul Seç" isFloating>
                  <Select
                    ariaLabel="school"
                    placeholder="Okul"
                    onChange={formik.handleChange}
                    value={formik.values.school ?? ""}
                    isValid={formik.isValid}
                    onBlur={formik.handleBlur}
                    isTouched={formik.touched.school}
                    invalidFeedback={formik.errors.school}
                    list={schoolsList}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-8">
                <FormGroup id="school_status">
                  <Select
                    placeholder="Aktiflik"
                    ariaLabel="school_status"
                    onChange={formik.handleChange}
                    value={formik.values.school_status ?? ""}
                    isValid={formik.isValid}
                    onBlur={formik.handleBlur}
                    isTouched={formik.touched.school_status}
                    invalidFeedback={formik.errors.school_status}
                    list={[
                      { value: "1", text: "Default" },
                      { value: "2", text: "Okul" },
                      { value: "3", text: "Partner" },
                    ]}
                    className="text-dark"
                    disabled
                  ></Select>
                </FormGroup>
              </div>
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={async () => {
            formik.handleSubmit();
          }}
        >
          Kaydet
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreatePathModal;
