import React, { useEffect, useState } from "react";
import { useGetAllPracticesQuery } from "../../../../../store/features/apiSlice";
import Card, { CardBody } from "../../../../../components/bootstrap/Card";
import SimpleSelectList from "../components/SimpleSelectList";

const CompetitionPracticeListContent = ({
  setCompetitionPractices,
  currentPractices,
}) => {
  const { data:practiceList, isLoading, error } = useGetAllPracticesQuery();
  const [selectedPractices, setSelectedPractices] = useState([]);
  useEffect(() => {
    setSelectedPractices(currentPractices?.map((item) => Number(item.id)));
  }, [currentPractices]);

  async function selectPractice(id) {
    let practice = practiceList.find((p) => Number(p.id) === Number(id));
    const isPractice = selectedPractices.includes(Number(id));
  
    if (!isPractice) {
      setSelectedPractices((prev) => [...prev, Number(id)]);
  
      const newPractices = [...currentPractices, practice];
      setCompetitionPractices(newPractices);
    } else {
      const filteredPractices = selectedPractices.filter(
        (item) => Number(id) !== Number(item)
      );
      const filteredPracticesTest = currentPractices.filter(
        (item) => Number(practice.id) !== Number(item.id)
      );
  
      setSelectedPractices(filteredPractices);
      setCompetitionPractices(filteredPracticesTest);
    }
  }
  
  return (
    <div>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error</p>}
      {!isLoading && !error && (
        <div className="row">
          <div className="col" style={{ minWidth: "250px" }}>
            <Card style={{ width: "100%", height: "90%" }}>
              <CardBody>
                <SimpleSelectList
                  items={practiceList}
                  selectedItems={selectedPractices}
                  onSelect={selectPractice}
                  selectKey={"id"}
                  displayKey={"title"}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompetitionPracticeListContent;
