import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Page from "../../../layout/Page/Page";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Button from "../../../components/bootstrap/Button";
import { loginUser } from "./loginHelpers";
import useToken from "../../../utils/useToken";
import Spinner from "../../../components/bootstrap/Spinner";
import Select from "../../../components/bootstrap/forms/Select";
import Privacy from "./Privacy";
import { useFormik } from "formik";
import TeacherForm from "./register/TeacherForm";
import StudentForm from "./register/StudentForm";
import { AuthContext } from "../../../store/auth-store";
import showNotification from "../../../components/extras/showNotification";
import Icon from "../../../components/icon/Icon";
import { useDispatch, useSelector } from "react-redux";
import { setRegister } from "../../../store/loginWithPaymentPlanSlice";
import { useEffect } from "react";
import style from "./customLogin/login.module.scss";
import loginBrandLogo from "../../../assets/loginBrandLogo.png";
import loginComputer from "../../../assets/loginComputer.png";
import LoadingSpinner from "./login animation/LoadingSpinner";
import RegisterControlModal from "../../t14/payment/RegisterControlModal";

const LoginHeader = ({ isNewUser }) => {
  if (isNewUser) {
    return (
      <>
        <div className={`${style.loginWelcome} mb-1`}>Kayıt ol</div>
        <div className={`${style.loginWelcomeMessage}`}>
          Uygulamayı kullanmaya devam etmek için kayıt olun
        </div>
      </>
    );
  }
  return (
    <>
      <div className={`${style.loginWelcome} mb-1`}>Hoş geldiniz</div>
      <div className={`${style.loginWelcomeMessage}`}>
        Devam etmek için giriş yap!
      </div>
    </>
  );
};

const Login = ({ isSignUp }) => {
  const { setToken } = useToken();
  // const { darkModeStatus } = useDarkMode();
  const [isNewUser, setIsNewUser] = useState(isSignUp);
  const [userType, setUserType] = useState("1");
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const { paymentPlan, register } = useSelector(
    (store) => store.loginWithPaymentPlan
  );
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cntTxt = useContext(AuthContext);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.email) errors.email = "Email adresi gerekli!";
    if (!values.password) errors.password = "Şifre gerekli!";
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: async (values) => {
      setBusy(true);

      const response = await loginUser({
        email: values.email,
        password: values.password,
      });
      if (response.status === "success") {
        setToken({
          ...response,
          points: JSON.stringify(response.points),
        });

        cntTxt.login(response.token, {
          ...response,
        });

        const targetURL = paymentPlan ? "/payment" : "/";
        navigate(targetURL);

        dispatch(paymentPlan && setRegister(true));
      } else {
        setError(response.message);
        console.log(error);
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Uyarı</span>
          </span>,
          <p style={{ fontSize: "14px" }}>Kullanıcı adı ya da parola yanlış</p>,
          "danger"
        );
      }
      setBusy(false);
    },
  });

  // TEACHER REGISTER SECTION
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const teacherRegisterParam = searchParams.get("teacherRegister");
    const studentRegisterParam = searchParams.get("studentRegister");

    if (teacherRegisterParam) {
      setIsNewUser(true);
      setUserType("2");
    }
    if (studentRegisterParam) {
      setIsNewUser(true);
      setUserType("1");
    }
  }, []);
console.log("paymentPlan",paymentPlan);
  useEffect(() => {
    if (paymentPlan && !register) {
      setIsNewUser(true);
    } else {
    }
  }, [paymentPlan, register]);

  // useEffect(() => {
  //   if (window.Tawk_API) {
  //     window.Tawk_API.hideWidget();
  //   }
  //   return () => {
  //     if (window.Tawk_API) {
  //       window.Tawk_API.showWidget();
  //     }
  //   };
  // }, []);

  if (formik.isSubmitting) {
    return (
      <div className="w-100 h-100">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <PageWrapper
    title={isNewUser ? "Sign Up" : "Login"}
    className={`${style.loginBackground}`}
    >
    <RegisterControlModal />
      <Page className="p-0">
        <div className={`${style.loginContainer}`}>
          <div className={`${style.loginContainerLeft}`}>
            <div className={`${style.logo}`}>
              <img src={loginBrandLogo} alt="" />
            </div>
            <div className={`${style.imgDiv}`}>
              <img src={loginComputer} alt="" />
            </div>
          </div>
          <div className={`${style.loginContainerRight} px-5`}>
            <LoginHeader isNewUser={isNewUser} />

            <div className={`${style.buttonsDiv} my-4`}>
              <div className={`${style.leftButton}`}>
                <Button
                  // color={darkModeStatus ? "light" : "dark"}
                  isLight={!!isNewUser}
                  className="rounded-1 w-100"
                  style={{
                    color: `${isNewUser ? "#fff" : "#6C5DD3"}`,
                    background: `${isNewUser ? "#6C5DD3" : "#fff"}`,
                  }}
                  size="lg"
                  onClick={() => {
                    setIsNewUser(!isNewUser);
                  }}
                >
                  Giriş Yap
                </Button>
              </div>
              <div className={`${style.rightButton}`}>
                <Button
                  // color={darkModeStatus ? "light" : "dark"}
                  isLight={!isNewUser}
                  className="rounded-1 w-100"
                  style={{
                    color: `${isNewUser ? "#6C5DD3" : "#fff"}`,
                    background: `${isNewUser ? "#fff" : "#6C5DD3"}`,
                  }}
                  size="lg"
                  onClick={() => {
                    setIsNewUser(!isNewUser);
                  }}
                  disabled={busy}
                >
                  Kayıt Ol
                </Button>
              </div>
            </div>

            <form
              className="row g-4"
              onSubmit={formik.handleSubmit}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  formik.handleSubmit();
                }
              }}
            >
              {isNewUser ? (
                <>
                  <div className="col-12">
                    <FormGroup isFloating label="Hesap">
                      <Select
                        id="example"
                        list={[
                          {
                            text: "Öğrenci Hesabı",
                            value: "1",
                          },
                          {
                            text: "Öğretmen Hesabı",
                            value: "2",
                            // disabled: "true",
                          },
                        ]}
                        onChange={(e) => setUserType(e.target.value)}
                        value={userType}
                        ariaLabel="Aria Label"
                      />
                    </FormGroup>
                  </div>

                  {userType === "2" ? (
                    <TeacherForm
                      setError={setError}
                      setIsNewUser={() => setIsNewUser(false)}
                    />
                  ) : (
                    <StudentForm
                      setError={setError}
                      setIsNewUser={() => setIsNewUser(false)}
                    />
                  )}
                </>
              ) : (
                <>
                  <div className="col-12">
                    <FormGroup
                      isFloating
                      label="Kullanıcı adın veya emailin"
                      className={`${style.FormGroup}`}
                    >
                      <Input
                        name="email"
                        value={formik.email}
                        onChange={formik.handleChange}
                        isValid={formik.isValid}
                        onBlur={formik.handleBlur}
                        isTouched={formik.touched.email}
                        invalidFeedback={formik.errors.email}
                        autoComplete=""
                      />
                    </FormGroup>
                  </div>
                  <div className="col-12">
                    <FormGroup isFloating label="Şifre">
                      <Input
                        name="password"
                        value={formik.password}
                        onChange={formik.handleChange}
                        isValid={formik.isValid}
                        onBlur={formik.handleBlur}
                        isTouched={formik.touched.password}
                        invalidFeedback={formik.errors.password}
                        type={showPassword ? "text" : "password"}
                        autoComplete="password"
                      />
                    </FormGroup>
                  <div className="form-check mt-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="showPasswordCheckbox"
                      onChange={toggleShowPassword}
                      checked={showPassword}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="showPasswordCheckbox"
                      style={{ color: "white" }}
                    >
                      Şifreyi Göster
                    </label>
                  </div>
                  </div>
                  <div className={`${style.saveAndLoginButton} col-12`}>
                    <Button
                      color="info"
                      className="w-100 py-3"
                      onClick={formik.handleSubmit}
                      disabled={busy}
                    >
                      {busy && (
                        <Spinner color="primary" inButton isSmall>
                          Loading...
                        </Spinner>
                      )}
                      Giriş Yap
                    </Button>
                  </div>
                  <div className="text-end">
                    <Link
                      to="/forgot-password"
                      className={classNames(
                        "text-decoration-none me-3 text-light"
                      )}
                    >
                      Şifremi Unuttum
                    </Link>
                  </div>
                </>
              )}
            </form>
            <Privacy isNewUser={isNewUser} classNames={classNames} />
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

Login.propTypes = {
  isSignUp: PropTypes.bool,
};
Login.defaultProps = {
  isSignUp: false,
};

export default Login;
