import React from "react";
import style from "../ParentReportWithClass.module.scss";
import { nanoid } from "nanoid";
import ChartInfo from "./ChartInfo";
import { Card } from "react-bootstrap";
import StageInfo from "./StageInfo";

const CompletedStages = ({ data }) => {
  const course = data.course;
  const codelab = data.codelab;
  const codemino = data.game.codemino;
  const codeboo = data.game.codeboo;
  const codecizz = data.game.codecizz;
  
  const value = [course, codelab, codemino, codeboo, codecizz]

  const stages = [
    {title:"Dersler", color:"#6C5DD3"},
    {title:"CodeLab", color:"#998FE3"},
    {title:"CodeMino", color:"#352985"},
    {title:"CodeBoo", color:"#918CB3"},
    {title:"CodeÇizz", color:"#D3D2D8"},
  ]
  return (
    <Card className={`${style.completedStagesContainer} p-3 px-5`}>
      <div className={`${style.title} my-3 px-3`}>Tamamlanan Aşamalar</div>
      <div className="d-flex">
        <div className={`${style.stageContainer}`}>
          {stages?.map((stage) => (
            <StageInfo key={nanoid()} stage={stage} />
          ))}
        </div>
        <div  className={`${style.chartDiv}`}>
          <ChartInfo value={value}/>
        </div>
      </div>
    </Card>
  );
};

export default CompletedStages;
