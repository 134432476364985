import React from 'react';
import TestResult from './TestResult';

function CodeRunFail({tests}) {
  var testResults = tests.map((test)=>{
    return <TestResult key={test.test_id} test={test}/>
});
return (
    <div className='success'>
        <img className='thumb' src="/images/error.gif" alt="error" />
        {testResults}
    </div>
);
}       

export default CodeRunFail;