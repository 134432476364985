import React from "react";
import Button from "../../../../../components/bootstrap/Button";
import Separator from "../../../../../components/separator/Separator";
import style from "./teacherHomeworks.module.scss";
import Icon from "../../../../../components/icon/Icon";

const titleStyle = {
  display: "inline-block",
};

export default function TaskPreview({
  setOpenAddTaskForm,
  content,
  openAddTaskForm,
}) {
  // console.log("content geliyor",content);
  const { course_lessons, learning_paths, game_steps, teacher_practices } =
    content;
  const schoolCourses = course_lessons?.filter(
    (item) => item.school_status === 2
  );
  const defaultCourses = course_lessons?.filter(
    (item) => item.school_status === 1 || item.school_status === 3
  );
  return (
    <div>
      <div className="d-flex justify-content-between">
        <h3 className={`${style.titleStyle}`} style={titleStyle}>
          Tanımlanmış Görevler
        </h3>
        {openAddTaskForm ? (
          <Button
            className={`${style.addContent} mb-2`}
            onClick={() => setOpenAddTaskForm(!openAddTaskForm)}
            style={{ width: "180px" }}
          >
            İçerikleri Gizle <Icon icon={"LockOpen"} size="2x"></Icon>
          </Button>
        ) : (
          <Button
            className={`${style.addContent} mb-2`}
            onClick={() => setOpenAddTaskForm(!openAddTaskForm)}
            style={{ width: "180px" }}
          >
            İçerikleri Göster <Icon icon={"Lock"} size="2x"></Icon>
          </Button>
        )}
      </div>
      <Separator />
      <div
        className="d-flex flex-wrap gap-5"
        style={{ maxHeight: "150px", overflow: "scroll" }}
      >
        {defaultCourses && defaultCourses.length > 0 && (
          <div>
            <h3>Dersler</h3>
            {defaultCourses.map((lesson) => (
              <div key={lesson.id}>
                {lesson.title}
                <div>
                  <ul>
                    {lesson.chapters.map((chapter) => (
                      <li key={chapter.id}>
                        {chapter.title}
                        <ul>
                          {chapter.lessons.map((l) => (
                            <li key={l.id}>{l.title}</li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        )}
        {/* <CourseContent courses={content.course_lessons} /> */}
        {learning_paths && learning_paths.length > 0 && (
          //  <LearningPathContent practices={content.learning_paths}/>
          <div>
            <h3>CodeLab</h3>
            <div>
              <ul>
                {learning_paths.map((lp) => (
                  <li key={lp.id}>
                    {lp.title}
                    <ul>
                      {lp.practices.map((p) => (
                        <li key={p.id}>{p.title}</li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {game_steps && game_steps.length > 0 && (
          <div>
            <h3>Oyunlar</h3>
            {game_steps.map((game) => (
              <div key={game.id}>
                {game.title}
                <div>
                  {game.sections ? (
                    <ul>
                      {game.sections?.map((section) => (
                        <li key={section.id}>
                          {section.title}
                          <ul>
                            {section.steps.map((step) => (
                              <li key={step.id}>{step.title}</li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <ul>
                      {game.steps?.map((step) => (
                        <li key={step.id}>{step.title}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
        {schoolCourses && schoolCourses.length > 0 && (
          <div>
            <h3>Kurum Dersleri</h3>
            {schoolCourses.map((lesson) => (
              <div key={lesson.id}>
                {lesson.title}
                <div>
                  <ul>
                    {lesson.chapters.map((chapter) => (
                      <li key={chapter.id}>
                        {chapter.title}
                        <ul>
                          {chapter.lessons.map((l) => (
                            <li key={l.id}>{l.title}</li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        )}

        {teacher_practices && teacher_practices.length > 0 && (
          <div>
            <h3>Eğitmen Alıştırmaları</h3>
            <div>
              <ul>
                {teacher_practices.map((p) => (
                  <li key={p.id}>{p.title}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      {course_lessons &&
        course_lessons.length === 0 &&
        learning_paths &&
        learning_paths.length === 0 &&
        teacher_practices &&
        teacher_practices.length === 0 &&
        game_steps &&
        game_steps.length === 0 && (
          <div className={`${style.emptyTable} my-2 fs-5`}>
            <span>Tanımlanmış görev bulunmamaktadır.</span>
          </div>
        )}
    </div>
  );
}
