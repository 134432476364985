import React from "react";
import Logo from "../../../../../../components/Logo";
import Icon from "../../../../../../components/icon/Icon";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
} from "../../../../../../components/bootstrap/Modal";
import { useGetStudentPracticesForStudentQuery } from "../../../../admin/talent cup/talentCupApi";
// import {
//   getSelectedLessonsWithParents,
//   getSelectedPractices,
//   getSelectedStepsWithParents,
// } from "./competitionPracticeFunctions";

const CompetitionPracticeDetailModal = ({
  isOpen,
  setIsOpen,
  competitionId,
}) => {
  const { data, isLoading } = useGetStudentPracticesForStudentQuery(
    competitionId,
    { skip: !competitionId }
  );
  const navigate = useNavigate();

  const practices = data && data[0].practices;
  const games = data && data[0].games;
  const lessons = data && data[0].lessons;
  const isPractices = practices && practices.length > 0;
  const isGameSteps = games && games.length > 0;
  const isLessons = lessons && lessons.length > 0;

  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      titleId="example-title"
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} /> <span className="ps-2">Görev Detayı</span>
          <span className="ps-2">
            <Icon icon="Computer" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <div>Yükleniyor...</div>
        ) : (
          <div className="row gap-3 d-flex justify-content-center">
            <div className="col-lg-10">
              <div>
                {isGameSteps && (
                  <div className="d-flex gap-1">
                    <div>
                      <h6>Oyunlar :</h6>
                      <ul>
                        {games?.map((game) => {
                          const { title, sections, id: gameId } = game;
                          return (
                            <li key={nanoid()}>
                              <ul className="p-0">
                                {title} ,{" "}
                                {sections?.map((section) => {
                                  const {
                                    title: sectionTitle,
                                    steps,
                                    id: sectionId,
                                  } = section;
                                  return (
                                    <li key={nanoid()} className="ms-4">
                                      <span>
                                        {sectionTitle}
                                        <ul>
                                          {steps.map((step) => (
                                            <li
                                              key={step.id}
                                              className="cursor-pointer"
                                              style={{
                                                textDecoration: "underline",
                                                color: `${
                                                  step.is_solved ? "blue" : null
                                                }`,
                                              }}
                                              // onClick={() =>
                                              //   navigate(
                                              //     `/games/${gameId}/sections${sectionId ? "/" + sectionId + "/" : "/"}steps/${
                                              //       step.id
                                              //     }?competition=${competitionId}`
                                              //   )
                                              // }
                                            >
                                              <a
                                                href={`/games/${gameId}/sections${
                                                  sectionId
                                                    ? "/" + sectionId + "/"
                                                    : "/"
                                                }steps/${
                                                  step.id
                                                }?competition=${competitionId}`}
                                              >
                                                {step.title}{" "}
                                                {step.is_solved && "👌Çözüldü"}
                                              </a>
                                            </li>
                                          ))}
                                        </ul>
                                      </span>
                                    </li>
                                  );
                                })}
                              </ul>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                )}

                {isPractices && (
                  <div className="d-flex gap-1">
                    <div>
                      <h6>Alıştırmalar :</h6>
                      <ul>
                        {practices?.map((practice) => {
                          const { title, id, is_solved } = practice;
                          return (
                            <li
                              key={nanoid()}
                              onClick={() =>
                                navigate(
                                  `/practice/${id}?competition=${competitionId}`
                                )
                              }
                              className="cursor-pointer"
                              style={{ textDecoration: "underline" }}
                            >
                              <span
                                style={{
                                  color: `${is_solved ? "blue" : null}`,
                                }}
                              >
                                {title} {is_solved && "👌Çözüldü"}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                )}

                {isLessons && (
                  <div>
                    <h6>Dersler</h6>
                    {lessons.map((lesson) => (
                      <div
                        key={lesson.id}
                      >
                        {lesson.title}
                        <div>
                          <ul>
                            {lesson.chapters.map((chapter) => (
                              <li key={chapter.id}>
                                {chapter.title}
                                <ul>
                                  {chapter.lessons.map((l) => (
                                    <li key={l.id}>
                                      <span
                                        className="cursor-pointer"
                                        style={{ textDecoration: "underline", color: `${l.is_solved ? "blue" : null}`, }}
                                        onClick={() =>
                                          navigate(
                                            `/lessons/content/${l.id}?competition=${competitionId}`
                                          )
                                        }
                                      >
                                        {l.title} {l.is_solved && "👌Çözüldü"}
                                      </span>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <hr />
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default CompetitionPracticeDetailModal;
