import React from 'react';
import Card, {
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import LEARNING_PATH_STATE from '../../common/data/enumLearningPathState';
import USER_SUBSCRIPTION_TYPE from '../../common/data/enumUserSubscriptionType';
import { useNavigate } from 'react-router';

const LearningPathGridItem = ({
	// eslint-disable-next-line react/prop-types
	id,
	// eslint-disable-next-line react/prop-types
	name,
	// eslint-disable-next-line react/prop-types
	category,
	// eslint-disable-next-line react/prop-types
	img,
	// eslint-disable-next-line react/prop-types
	color,
	// eslint-disable-next-line react/prop-types
	lp_state,	
}) => {
	const { darkModeStatus } = useDarkMode();
	const is_draft = lp_state === LEARNING_PATH_STATE.DRAFT.id;
	 const userSubscriptionType = localStorage.getItem("subscription_type")
	//  const userSubscriptionType = 1
	
	const navigate = useNavigate();

	const handleNavigation = (path, activeTab) => {
	  navigate(`${path}?activeTab=${activeTab}`);
	}; 
	return (
		<Card>
			<CardHeader className={`${is_draft && !darkModeStatus ? 'bg-l10-dark' : ''}`}>
				<CardLabel>
					<CardTitle>
						{name}						
					</CardTitle>
				</CardLabel>
				
			</CardHeader>
			<CardBody className={`${is_draft && !darkModeStatus ? 'bg-l10-dark' : ''}`}>
				<img
					src={img}
					alt=''
					width={128}
					height={128}
					className='mx-auto d-block img-fluid mb-3'
				/>
				
			</CardBody>
			<CardFooter className={`shadow-3d-container ${is_draft && !darkModeStatus ? 'bg-l10-dark' : ''}`}>
				{is_draft ? (
					<Button
						color='myButton'
						className={`w-100 mb-4 shadow-3d-up-hover shadow-3d-${
							darkModeStatus ? 'light' : 'myButton'
						}`}
						size='lg'>
						Alıştırma Hazırlanıyor ...
					</Button>
				) : (
					id === 220 || Number(userSubscriptionType) === USER_SUBSCRIPTION_TYPE.PREMIUM.id ?
					(
						<Button
							color='myButton'
							className={`w-100 mb-4 shadow-3d-up-hover shadow-3d-${
								darkModeStatus ? 'light' : 'myButton'
							}`}
							size='lg'
							tag='a'
							to={`/learning-paths-wrapper/${id}`}>
							Alıştırmaya Gir
						</Button>
					) :
					(
						<Button
							color="danger"
                            className="btn btn-myDanger btn-lg w-100 mb-4 shadow-3d-up-hover shadow-3d-myDanger"
                            size="lg"
                            tag="a"
                            onClick={() =>
                              handleNavigation(`/profile`, "Account Details")
                            }
							>
							Planı Yükselt
						</Button>
					)
				)}
			</CardFooter>
		</Card>
	);
};

export default LearningPathGridItem;
