import React from "react";
import Modal, { ModalBody, ModalFooter } from "../../../../../components/bootstrap/Modal";
import disapproved from "../../../../../assets/dashboardImages/disapproved.png";
import Button from "../../../../../components/bootstrap/Button";

const UnApprovedTeacherModal = ({openUnApprovedTeacherModal,setOpenUnApprovedTeacherModal, onCancel}) => {
    
    return (
      <div>
        <Modal
          isOpen={openUnApprovedTeacherModal}
          setIsOpen={setOpenUnApprovedTeacherModal}
          titleId="example-title"
          size={"xl"}
        >
          <ModalBody>
          <div className="d-flex flex-column flex-lg-row align-items-center">
          <div>
            <p className="text-danger fw-bold" style={{ fontSize: "calc(1rem + 2vw)" }}>Belgeniz Onaylanmadı</p>
            <p className="h-4" style={{ color: "#68686d", fontSize: "20px" }}>
              Öğretmen olarak yaptığınız başvuru reddedildi. Geçerli bir
              belgeniz olması durumunda tekrar başvuru yapabilirsiniz.
            </p>
          </div>
          <div style={{height:"500px"}}>
        <img src={disapproved} alt="" style={{ width: "500px", height:"100%" }} />{" "}
        </div>
        </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onCancel}>
              Kapat
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  };
  
  export default UnApprovedTeacherModal;