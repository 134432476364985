import React from "react";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Select from "../../components/bootstrap/forms/Select";
import Input from "../../components/bootstrap/forms/Input";
import Textarea from "../../components/bootstrap/forms/Textarea";
import { nanoid } from 'nanoid';

const CustomFormField = ({
  type,
  label,
  formik,
  name,
  options,
  disabled = false,
  readOnly = false,
  mask = null,
  style,
}) => {
  // console.log(formik);
  return (
    <div>
      {type === "select" ? (
        <FormGroup id={name} isFloating label={label}>
          <Select
            ariaLabel={name}
            onChange={formik.handleChange}
            value={formik.values[name]}
            isValid={formik.isValid}
            onBlur={formik.handleBlur}
            isTouched={formik.touched[name]}
            invalidFeedback={formik.errors[name]}
            list={options}
            className="text-dark"
            disabled={disabled}
            readOnly={readOnly}
          >
             {options.map((option) => (
      <option key={option.key || nanoid()} value={option.value}>
        {option.text}
      </option>
    ))}
          </Select>
        </FormGroup>
      ) : type === "textArea" ? (
        <FormGroup id={name} isFloating label={label}>
          <Textarea
            onChange={formik.handleChange}
            value={formik.values[name]}
            onBlur={formik.handleBlur}
            isValid={formik.isValid}
            isTouched={formik.touched[name]}
            invalidFeedback={formik.errors[name]}
            style={style}
          />
        </FormGroup>
      ) : type === "number" ? (
        <FormGroup id={name} isFloating label={label}>
          <Input
            type={type}
            onChange={formik.handleChange}
            value={formik.values[name]}
            onBlur={formik.handleBlur}
            isValid={formik.isValid}
            isTouched={formik.touched[name]}
            invalidFeedback={formik.errors[name]}
            min={0}
            disabled={disabled}
            readOnly={readOnly}
            mask={mask}
          />
        </FormGroup>
      ) : (
        <FormGroup id={name} isFloating label={label}>
          <Input
            type={type}
            onChange={formik.handleChange}
            value={formik.values[name]}
            onBlur={formik.handleBlur}
            isValid={formik.isValid}
            isTouched={formik.touched[name]}
            invalidFeedback={formik.errors[name]}
            min={0}
            disabled={disabled}
            readOnly={readOnly}
            mask={mask}
          />
        </FormGroup>
      )}
    </div>
  );
};

export default CustomFormField;
