import React, { useEffect} from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../../components/bootstrap/Modal";
import Logo from "../../../../../components/Logo";
import Icon from "../../../../../components/icon/Icon";
import Button from "../../../../../components/bootstrap/Button";
import FormGroup from "../../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../../components/bootstrap/forms/Input";
import { useFormik } from "formik";
import Select from "../../../../../components/bootstrap/forms/Select";
import showNotification from "../../../../../components/extras/showNotification";
import USER_ROLES from "../../../../../common/data/enumUserRole";
import { useGet_a_SchoolUserQuery, useUpdateSchoolUserMutation } from "../../schoolManagerApi";

const UpdateUserModalForSchoolManager = ({ isOpenModal, setIsOpenModal, userId }) => {
  const { data, isSuccess } = useGet_a_SchoolUserQuery(userId, {skip:userId===null});
  const [updateUser] = useUpdateSchoolUserMutation();

  useEffect(() => {
    if (isSuccess) {
      formik.setFieldValue("first_name", data.first_name === "" ? null : data.first_name);
      formik.setFieldValue("last_name", data.last_name === "" ? null : data.last_name);
      formik.setFieldValue("email", data.email);
      formik.setFieldValue("role", data.role);
      formik.setFieldValue("user_avatar", data.user_avatar);
      formik.setFieldValue("telephone", data.telephone);
      formik.setFieldValue("parent_telephone_number", data.parent_telephone_number);
      formik.setFieldValue("school", data.school);
    }
    // eslint-disable-next-line
  }, [isSuccess, data]);

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Email adresi gereklidir.";
    }
    if (
      values.email.length > 0 &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = "Geçersiz email adresi.";
    }
    if ((Number(formik.values.role) === USER_ROLES.STUDENT.id) && (!values.parent_telephone_number || values.parent_telephone_number.includes("_"))) {
      errors.parent_telephone_number = "Veli telefon numarasını yazınız";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      role: 1,
      user_avatar:"",
      telephone: "",
      parent_telephone_number:"",
      school:""
    },
    validate,
    onSubmit: () => {
      handleUser();
    },
  });
  const handleUser = async () => {
    const userValues = { ...formik.values };
    const response = await updateUser({
      id: userId,
      ...userValues,
    });
    setIsOpenModal(false);
    if (response.error) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Güncelleme Başarısız</span>
        </span>,
        "Bir hata oluştu",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Güncelleme Başarılı</span>
        </span>,
        "Kullanıcı bilgileri başarıyla güncellendi.",
        "success"
      );
    }
    setIsOpenModal(false);
  };
  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenModal}
      setIsOpen={setIsOpenModal}
      titleId="example-title"
      size={null}
    >
      <ModalHeader setIsOpen={setIsOpenModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} />{" "}
          <span className="ps-2">Kullanıcı Bilgisi Güncelleme</span>
          <span className="ps-2">
            <Icon icon="Person" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="row gap-3 d-flex justify-content-center">
          <div className="col-lg-8">
            <FormGroup
              // className="col-4"
              id="first_name"
              isFloating
              label="Ad"
            >
              <Input
                onChange={formik.handleChange}
                value={formik.values.first_name}
                onBlur={formik.handleBlur}
                // isValid={formik.isValid}
                isTouched={formik.touched.first_name}
                invalidFeedback={formik.errors.first_name}
              />
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup
              // className="col-4"
              id="last_name"
              isFloating
              label="Soyad"
            >
              <Input
                onChange={formik.handleChange}
                value={formik.values.last_name}
                onBlur={formik.handleBlur}
                // isValid={formik.isValid}
                isTouched={formik.touched.last_name}
                invalidFeedback={formik.errors.last_name}
              />
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup isFloating label="Email" id="email">
              <Input
                name="email"
                type="email"
                autoComplete="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                isValid={formik.isValid}
                onBlur={formik.handleBlur}
                isTouched={formik.touched.email}
                invalidFeedback={formik.errors.email}
              />
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup id="role">
              <Select
              ariaLabel="role"
                placeholder="Profil Tipi"
                onChange={formik.handleChange}
                value={String(formik.values.role)}
                isValid={formik.isValid}
                onBlur={formik.handleBlur}
                isTouched={formik.touched.role}
                invalidFeedback={formik.errors.role}
                list={[
                  { value: "1", text: "Öğrenci" },
                  { value: "2", text: "Öğretmen" },
                ]}
                className="text-dark"
              ></Select>
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup id="telNumber" isFloating label="Telefon numarası">
              <Input
                type="text"
                mask="999 999 99 99"
                onChange={formik.handleChange}
                value={formik.values.telNumber}
                onBlur={formik.handleBlur}
                isValid={formik.isValid}
                isTouched={formik.touched.telNumber}
                invalidFeedback={formik.errors.telNumber}
              />
            </FormGroup>
          </div>
          {Number(formik.values.role) === USER_ROLES.STUDENT.id && (
            <>
          <div className="col-lg-8">
            <FormGroup id="parent_telephone_number" isFloating label="Veli Telefon Numarası">
              <Input
                type="text"
                mask="999 999 99 99"
                onChange={formik.handleChange}
                value={formik.values.parent_telephone_number}
                onBlur={formik.handleBlur}
                isValid={formik.isValid}
                isTouched={formik.touched.parent_telephone_number}
                invalidFeedback={formik.errors.parent_telephone_number}
              />
            </FormGroup>
          </div>
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={async () => {
            formik.handleSubmit();
          }}
        >
          Güncelle
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UpdateUserModalForSchoolManager;
