import React from "react";
import style from "./ClassDetailInformation.module.scss";
import teacherLogo from "../../../../../assets/school/teacherLogo.svg"
import { useParams } from "react-router-dom";
import { useGetClassQuery } from "../../../teacher/teacherApi";
import { useGet_a_SchoolClassQuery } from "../../schoolManagerApi";
import USER_ROLES from "../../../../../common/data/enumUserRole";


const ClassDetailInformation = () => {
  const role = localStorage.getItem("roles");
  const isAdmin = Number(role) === USER_ROLES.ADMIN.id;
  const isSchoolManager = Number(role) === USER_ROLES.SCHOOL_MANAGER.id;

  const { classId } = useParams();
  const { data} = useGetClassQuery(classId, {skip:!isAdmin});
  const { data:managerData} = useGet_a_SchoolClassQuery(classId, {skip:!isSchoolManager});
  const class_name = (isAdmin && data) ? data.name : (isSchoolManager && managerData) ? managerData.name :""
  const teacherName = (isAdmin && data) ? data.teacher_name : (isSchoolManager && managerData) ? managerData.teacher_name :""

  return (
      <div className={`${style.informationContainer} my-3 d-flex align-items-center justify-content-between`}>
                <div className={`${style.leftSection} d-flex justify-content-center align-items-center`}>
          <div className={`${style.description}`}>
          <span>{class_name}</span>
          </div>
        </div>

        <div className={`${style.rightSection} d-flex align-items-center`}>
        <div className={`${style.classInformation} d-flex justify-content-start align-items-center gap-4 ms-2`}>
          <div className={`${style.classLogo}`}>
            <img src={teacherLogo} alt="" />
          </div>
          <div className={`${style.description}`}>
          <span>Öğretmen: {teacherName}</span>
          </div>
        </div>
        </div>
      </div>
    )
};

export default ClassDetailInformation;
