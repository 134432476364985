import React, { useEffect, useState } from "react";
import Card, {
  CardActions,
  CardBody,
  CardLabel,
  CardTitle,
} from "../../../../components/bootstrap/Card";
import Icon from "../../../../components/icon/Icon";
import {
  dataPagination,
} from "../../../../components/PaginationButtons";
import useSortableData from "../../../../hooks/useSortableData";
import useSelectTable from "../../../../hooks/useSelectTable";
import Checks from "../../../../components/bootstrap/forms/Checks";
import { CardHeader, IconButton, Tooltip } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { firstOrDefault } from "../../../../helpers/helpers";
import Confirm from "../../../common/Confirm";
import Button from "../../../../components/bootstrap/Button";

const PracticeTable = ({ practices, deleteItem, changeOrder }) => {
  const [search, setSearch] = useState("")
  const [filteredPractice, setFilteredPractice] = useState("")
  // console.log("PracticeTable", practices);
  const currentPage = 1;
  const perPage = 50;
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selected, setSelected] = useState([]);
  const { items, requestSort, getClassNamesFor } = useSortableData(
    !filteredPractice ? practices.map((i) => ({ ...i, id: i.practice })) : filteredPractice.map((i) => ({ ...i, id: i.practice }))
  );
  const onCurrentPageData = dataPagination(items, currentPage, perPage);
  const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageData);

  const handleDelete = () => {
    deleteItem(firstOrDefault(selected));
    setOpenConfirm(false);
  };
  const handlCancel = () => setOpenConfirm(false);

  console.log("%c Render PracticeTable", "color: #00f");

  useEffect(() => {
    filterPractice();
    //eslint-disable-next-line
  }, [search, practices]);

  
  
  const filterPractice = (e) => {
    if (e && e.preventDefault) { e.preventDefault(); }
    const newPractices = practices?.filter((item)=>item.title.toLocaleLowerCase().includes(search))
    setFilteredPractice(newPractices)
  }

  return (
    <>
      <Confirm
        open={openConfirm}
        setOpen={setOpenConfirm}
        onConfirm={handleDelete}
        onCancel={handlCancel}
      />
      <Card style={{ height: 500 }}>
          <div className="col-12 col-md-6 mx-auto">
            <form className="d-flex gap-1" onSubmit={filterPractice}>
            <input className="form-control" type="search" placeholder="Aramak istediğiniz soruyu yazınız" value={search} onChange={(e)=>setSearch(e.target.value.toLowerCase())}/>
            <button className="btn btn-warning w-25">Bul</button>
          </form> 
          </div>
        <CardHeader>
          <CardLabel icon="Public">
            <CardTitle>Derse Tanımlı Alıştırmalar</CardTitle>
          </CardLabel>
          <CardActions>
            <Button color="success" hoverShadow="default" icon="Save" isLight>
              Alıştırma Ekle
            </Button>
          </CardActions>
        </CardHeader>
        <CardBody className='table-responsive' isScrollable>
          {onCurrentPageData.length > 0 ? (
            <table className="table table-modern">
              <thead>
                <tr>
                  <th>{SelectAllCheck}</th>
                  <th onClick={() => requestSort("title")}>
                    Başlık
                    <Icon
                      size="lg"
                      className={getClassNamesFor("firstName")}
                      icon="FilterList"
                    />
                  </th>
                  <th onClick={() => requestSort("explanation")}>
                    Sırası
                    <Icon
                      size="lg"
                      className={getClassNamesFor("explanation")}
                      icon="FilterList"
                    />
                  </th>

                  <th>Sil</th>
                  <th>Düzenle</th>
                </tr>
              </thead>
              <tbody>
                {onCurrentPageData.map((item) => (
                  <tr key={item.id.toString()}>
                    <td>
                      <Checks
                        id={item.id.toString()}
                        name="selectedList"
                        value={item.id}
                        onChange={selectTable.handleChange}
                        checked={selectTable.values.selectedList.includes(
                          item.id.toString()
                        )}
                      />
                    </td>
                    <td>{item.title}</td>
                    <td>{item.order_number}</td>

                    <td>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => {
                            setSelected([item.id]);
                            setOpenConfirm(true);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip title="Edit">
                        <IconButton onClick={() => changeOrder(item)}>
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <span>Alıştırma bulunmamaktadır.</span>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default PracticeTable;
