import React from "react";
import Result from "../../../../../components/Result";
import { useParams } from "react-router-dom";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../../layout/Page/Page";

const TeacherPracticeFormResult = () => {
    const { id } = useParams();
  return (
    <PageWrapper>
    <Page>
      <Result
        id={id}
        url1="teacher/practices"
        url2="teacher/practice-form"
        url3="teacher/test"
      />
    </Page>
  </PageWrapper>
  )
}

export default TeacherPracticeFormResult