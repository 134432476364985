import React, { useState } from "react";
import Card, {
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../../components/bootstrap/Card";
import Select from "../../../../components/bootstrap/forms/Select";
import styles from "./teacherStudentPage.module.scss";
import Heatmap from "./teacherHeatmap/Heatmap";
import { useNavigate } from "react-router-dom";
import AVATARS from "../../../../common/data/avatarsDummy";
import style from "./teacherStudentPage.module.scss";
import Icon from "../../../../components/icon/Icon";
import { useCoursesQuery } from "../../../../store/features/apiSlice";
import CertificateThumbnail from "../../student/leaderboardsAndRewardsWrapper/certificatesPage/DynamicThumbnail/CertificateThumbnail";
import certificateTemplate from "../../student/leaderboardsAndRewardsWrapper/certificatesPage/certificateTemplate.png";
import Button from "../../../../components/bootstrap/Button";
import UpdatePasswordWithTeacherModal from "./UpdatePasswordWithTeacherModal";
import {
  useGetClassStudentQuery,
  useHomeworksOfClassQuery,
} from "../teacherApi";
import { useEffect } from "react";

const TeacherStudentPage = ({student }) => {
  const navigate = useNavigate();

  const [isOpenUpdatePasswordModal, setIsOpenUpdatePasswordModal] =
    useState(false);

  const [userId, setUserId] = useState(null);
  const [year, setYear] = useState("2024-2");
  const years = [
    { text: "2023-1", value: "2023-1" },
    { text: "2023-2", value: "2023-2" },
    { text: "2024-1", value: "2024-1" },
    { text: "2024-2", value: "2024-2" },
  ];
  const handleDateChange = (e) => {
    setYear(e.target.value);
  };
  //query
  const { data: students, isSuccess } = useGetClassStudentQuery(
    {id:student && student?.class_field},
    { skip: student === undefined }
  );
  const { data: allLessons } = useCoursesQuery();

  let classId = student && student.class_field;
  const { data: classHomeworks } = useHomeworksOfClassQuery(classId, {
    skip: classId === undefined,
  });
  const active_homeworks =
    classHomeworks && classHomeworks.filter((item) => item.status === 1);

  useEffect(() => {
    if (students && student) {
      let studentUser = students?.results?.filter((item) => Number(item.id) === Number(student.id));
      setUserId(studentUser[0].user_id);
    }
    //eslint-disable-next-line
  }, [isSuccess]);

  const openModal = (e) => {
    setIsOpenUpdatePasswordModal(true);
  };

  const isUserInfo = student && student.first_name === null;
  const userInfo = (
    <span>
      {student?.first_name} {student?.last_name}
    </span>
  );
  const userWarning = (
    <span className="text-danger fs-5">Ad soyad bilgisi gereklidir.</span>
  );
  return (
    <>
      <UpdatePasswordWithTeacherModal
        isOpenUpdatePasswordModal={isOpenUpdatePasswordModal}
        setIsOpenUpdatePasswordModal={setIsOpenUpdatePasswordModal}
        userId={userId}
      />
      <Card className={`${style.mainCardContainer} py-3 px-5 h-100`}>
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <CardHeader>
            <CardLabel>
              <CardTitle className={`${style.title}`}>
                {/* {student?.first_name} {student?.last_name} */}
                {!isUserInfo ? userInfo : userWarning}
              </CardTitle>
            </CardLabel>
          </CardHeader>
          <Button
            className={`${style.resetPasswordButton}`}
            onClick={() => openModal()}
          >
            Şifre Sıfırla
          </Button>
        </div>
        {/* student general info */}
        <div
          className="d-md-flex align-items-center justify-content-between my-4"
          style={{ width: "100%" }}
        >
          <div className={`${style.avatarDiv}`}>
            <div className={`${style.avatar}`}>
              {" "}
              <img
                src={
                  student?.user_avatar && student?.user_avatar !== "null"
                    ? student?.user_avatar
                    : AVATARS.defaultAvatarSmall
                }
                alt="Avatar"
                width={70}
                height={70}
              />
            </div>
          </div>

          <div className={`${style.studentsCardDiv}`}>
            <Card className={`${style.studentsCard} m-0 py-4`}>
              <div className={`${style.studentsCardTitle} px-4 mt-2`}>
                Öğrenci Genel Bilgiler
              </div>
              <div className={`${style.customTable}`}>
                <div
                  className={`${style.headContainer} d-flex align-items-center my-3 px-4`}
                >
                  <div
                    className={`${style.customTableHead1} ${style.customTableHead}`}
                  >
                    Ad / Soyad
                  </div>
                  <div
                    className={`${style.customTableHead2} ${style.customTableHead}`}
                  >
                    Sınıf
                  </div>
                  <div
                    className={`${style.customTableHead3} ${style.customTableHead} text-center`}
                  >
                    Sınıf İçi Sıralama
                  </div>
                  <div
                    className={`${style.customTableHead4} ${style.customTableHead} text-center`}
                  >
                    Genel Puan
                  </div>
                  <div
                    className={`${style.customTableHead5} ${style.customTableHead} text-center`}
                  >
                    Genel Sıralama
                  </div>
                  <div
                    className={`${style.customTableHead6} ${style.customTableHead} text-center`}
                  >
                    Puanı
                  </div>
                </div>
                <div className={`${style.customRowContainer}`}>
                  <div
                    className={`${style.customTableRow} d-flex align-items-center mt-1 px-4`}
                  >
                    <div className={`${style.customTableColumn1}`}>
                      <div>
                        <div>
                          {student?.first_name} {student?.last_name}
                        </div>
                      </div>
                    </div>
                    <div className={`${style.customTableColumn2}`}>
                      <div>
                        <div>{student?.classroom}</div>
                      </div>
                    </div>
                    <div className={`${style.customTableColumn3} text-center`}>
                      <div>{student?.rank_in_class}</div>
                    </div>
                    <div className={`${style.customTableColumn4} text-center`}>
                      <div>{student?.general_points}</div>
                    </div>
                    <div className={`${style.customTableColumn5} text-center`}>
                      <div>{student?.rank_in_general}</div>
                    </div>
                    <div className={`${style.customTableColumn6} text-center`}>
                      <div>{student?.class_points}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="row">
          {/* Active homeworks */}
          <div className="col-12 col-md-5">
            <Card className={`${style.homeWorksCard}`}>
              <div className={`${style.customTable} mt-5 px-4`}>
                <div className={`d-flex my-3 px-2`}>
                  <div
                    className={`${style.customTableHead1} ${style.customTableHead}`}
                  >
                    Aktif Ödevler
                  </div>
                </div>
                <hr
                  className={`mx-auto mt-3 mb-4`}
                  style={{
                    background: "#B6B5C8",
                    width: "100%",
                    position: "relative",
                    right: "0px",
                  }}
                />
                <div className={`${style.customRowContainer}`}>
                  {active_homeworks && active_homeworks.length === 0 ? (
                    <div>
                      <span>Aktif Ödev Bulunamadı</span>
                    </div>
                  ) : (
                    active_homeworks &&
                    active_homeworks.length > 0 &&
                    active_homeworks.map((homework, i) => (
                      <div
                        key={homework.id}
                        className={`${style.customTableRow} ${
                          i % 2 === 0 && style.customBackgroundColor
                        } d-flex align-items-center mt-1 px-2`}
                      >
                        <div
                          className={`${style.customTableRow1} cursor-pointer`}
                        >
                          <div
                            onClick={() => {
                              navigate(`/teacher/new-homework/${homework.id}`);
                            }}
                          >
                            <Icon
                              icon={"computer"}
                              size="3x"
                              className={`${style.homeworkLogo} me-2`}
                            />
                            {homework.title}
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </Card>
          </div>

          {/* Calendar */}
          <div className="col-12 col-md-7 ">
            <Card className={`${style.calendarCard}`}>
              <div className="d-flex justify-content-between align-items-center ">
                <CardHeader className={`${style.calendarCardHeader}`}>
                  <span>Öğrenci İlerleme Takvimi</span>
                </CardHeader>
                <Select
                  ariaLabel="year"
                  value={year}
                  onChange={handleDateChange}
                  size="sm"
                  style={{ width: "100px" }}
                  list={years}
                ></Select>
              </div>
              <div className="border border-solid p-1 rounded-2 my-2">
                <div className={`${styles.calendar}`}>
                  <Heatmap year={year} activeDay={student?.active_days} />
                </div>
              </div>
            </Card>
          </div>
        </div>

        {/* Certificate */}
        <Card className={`${style.certificateCard}`}>
          <CardHeader className={`${style.certificateCardHeader}`}>
            <span>Kazandığı Sertifikalar</span>
          </CardHeader>
          <CardBody>
            <div className="d-flex gap-2 flex-wrap">
              {student?.certificates && student.certificates.length > 0 ? (
                allLessons?.map((course) => {
                  // `allLessons?` ile `allLessons`'ın `undefined` olup olmadığı kontrol edilir
                  const userCertificate = student.certificates.find(
                    (item) => item.course_id === course.id
                  );
                  return (
                    userCertificate && (
                      <div
                        className={`${style.certificateDiv}`}
                        key={course.id}
                      >
                        <CertificateThumbnail
                          name={course.title}
                          src={certificateTemplate}
                        />
                      </div>
                    )
                  );
                })
              ) : (
                <div>Sertifika Bulunamadı</div>
              )}
            </div>
          </CardBody>
        </Card>
      </Card>
    </>
  );
};
export default TeacherStudentPage;
