import React from "react";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import DashboardHeader from "../../../../common/Headers/DashboardHeader";
import Page from "../../../../../layout/Page/Page";
import PathsWithoutClassWrapper from "../../../lessons/pathWithoutClass/paths-without-class-wrapper/PathsWithoutClassWrapper";

const SpecialForYouWrapper = () => {
  // useEffect(() => {
  //   if (window.Tawk_API) {
  //     window.Tawk_API.hideWidget();
  //   }
  //   return () => {
  //     if (window.Tawk_API) {
  //       window.Tawk_API.showWidget();
  //     }
  //   };
  // }, []);
  return (
    <PageWrapper title={"Dersler"}>
      <DashboardHeader />
      <Page
      >
        <PathsWithoutClassWrapper/>
      </Page>
    </PageWrapper>
  );
};

export default SpecialForYouWrapper;
