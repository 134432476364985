const USER_SUBSCRIPTION_TYPE = {
    UNKNOWN : {
        id: 0,
        name: 'unknown',
    },
    FREE : {
        id: 1,
        name: 'free',
    },
    STANDART : {
        id: 2,
        name: 'standart',
    },
    PREMIUM : {
        id: 3,
        name: 'premium',
    },
};
export default USER_SUBSCRIPTION_TYPE;