import React from "react";
import style from "./CertificatesPage.module.scss";
import Card, { CardBody } from "../../../../../components/bootstrap/Card";
import Page from "../../../../../layout/Page/Page";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import {
  useCoursesQuery,
  useUserBadgesQuery,
  useUserSertificatesQuery,
} from "../../../../../store/features/apiSlice";
import DashboardHeader from "../../../../common/Headers/DashboardHeader";
import { useNavigate } from "react-router-dom";
import AVATARS from "../../../../../common/data/avatarsDummy";
import certificateTemplate from "./certificateTemplate.png"
import CertificateThumbnail from "../certificatesPage/DynamicThumbnail/CertificateThumbnail";
import _ from "underscore";
import UserInfoModal from "./UserInfoModal";
import { useState } from "react";
import { nanoid } from "nanoid";

const CertificatesPage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { data } = useUserBadgesQuery();
  const { data: dataCertificate } = useUserSertificatesQuery();
  const { data:allLessons } = useCoursesQuery();
  let activeLessons = allLessons?.filter((item)=>item.status===1)
  activeLessons = _.sortBy(activeLessons,"order_number")
  const userLessons = [];
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];
      userLessons.push({ key, value });
    }
  }

  let userEmail = localStorage.getItem("email");
  const userName = localStorage.getItem("username");
  const userAvatar = localStorage.getItem("user_avatar");
  let defaultUserName = userEmail.split("@")[0];

  const showCertificate = (id) => {
    navigate(`/leaderboardsAndRewards/${id}`);
  };
  const name = localStorage.getItem("name");
  const surname = localStorage.getItem("surname");
  const userInfoCheck = (name || surname) === "null"
  const handleNavigation = () => {
   navigate("/profile")
   setOpen(false);
  };
  const handleCancel = () => setOpen(false);
  
  // useEffect(() => {
  //   if (window.Tawk_API) {
  //     window.Tawk_API.hideWidget();
  //   }
  //   return () => {
  //     if (window.Tawk_API) {
  //       window.Tawk_API.showWidget();
  //     }
  //   };
  // }, []);
  return (
    <PageWrapper>
      <DashboardHeader />
      <Page>
      <UserInfoModal
        open={open}
        setOpen={setOpen}
        onConfirm={handleNavigation}
        onCancel={handleCancel}
      />
        <div className={`${style.certificates} row h-100`}>
          <div className="col-lg-3 col-md-6">
            <Card stretch className={`${style.certificates_leftMenu} pt-5`}>
              <CardBody>
                <div
                  className={`${style.userAvatarDiv} d-flex justify-content-center mx-auto`}
                >
                  <img src={(userAvatar && userAvatar!=="null") ? userAvatar : AVATARS.defaultAvatarSmall} alt="" />
                </div>
                <div className={`${style.userName}`}>
                  <span>{(userName && userName!=="null") ? userName : defaultUserName}</span>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-lg-9 col-md-6">
            <Card
              stretch
              className={`${style.certificates_rightMenu} d-flex flex-column row`}
            >
              <CardBody>
                <div className={`${style.bottomCard} col-lg-11 mx-auto py-3`}>
                  <div className="d-flex gap-2 flex-wrap">
                    {activeLessons.map((course) => {
                      const userCertificate = dataCertificate?.find(
                        (item) => item.course === course.id
                      );
                      return (
                        <div
                        key={nanoid()}
                          className={`${style.certificateDiv} mx-auto`}
                          style={{pointerEvents:userCertificate ? "all" : "none", cursor: "pointer", filter: userCertificate ? "none" : "blur(6px)" }}
                          onClick={() => userInfoCheck ? setOpen(true) : showCertificate(userCertificate?.id)}
                        >
                          <CertificateThumbnail name={course.title} src={certificateTemplate} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default CertificatesPage;
