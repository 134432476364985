import React, { useState } from "react";
import style from "./SchoolOrPartnerContents.module.scss";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../../layout/Page/Page";
import DashboardHeader from "../../../../common/Headers/DashboardHeader";
import classPathsLogo1 from "../../../../../assets/lessons/classPathsLogo1.svg";
import classPathsLogo2 from "../../../../../assets/lessons/classPathsLogo2.svg";
import pathsLogo1 from "../../../../../assets/lessons/pathsLogo1.svg";
import pathsLogo2 from "../../../../../assets/lessons/pathsLogo2.svg";
import PathsContainer from "./paths container/PathsContainer";
import CourseContainer from "./course container/CourseContainer";

const SchoolOrPartnerContents = ({
  isPartner,
  trails,
  trailLoading,
  courses,
  courseLoading,
}) => {
  const _activeTab = sessionStorage.getItem("activeTabSchoolOrPartnerContents");
  const [activeTab, setActiveTab] = useState(Number(_activeTab) || 1);
  const tabContents = [
    {
      content: <PathsContainer trails={trails} trailLoading={trailLoading} />,
      tabName: "Patikalar",
      icon1: pathsLogo1,
      icon2: pathsLogo2,
    },
    {
      content: <CourseContainer courses={courses} isLoading={courseLoading} />,
      tabName: "Dersler",
      icon1: classPathsLogo1,
      icon2: classPathsLogo2,
    },
  ];
  const handleTabClick = (tabNumber) => {
    if (tabNumber >= 1 && tabNumber <= tabContents.length) {
    sessionStorage.setItem("activeTabSchoolOrPartnerContents", tabNumber);
    setActiveTab(tabNumber);}
  };
  return (
    <PageWrapper title={"Dersler"}>
      <DashboardHeader />
      <Page
        container={false}
        style={{ padding: "0" }}
        className={`${style.pageContainer}`}
      >
        {isPartner && (
          <div
            className={`${style.tabContainer} d-flex justify-content-evenly align-items-center mx-auto mb-5 px-3 gap-1`}
          >
            {tabContents.map((tab, index) => (
              <div
                key={index}
                className={`${style.tab} ${
                  activeTab === index + 1 ? `${style.activetab}` : ""
                } d-flex justify-content-center align-items-center gap-2 p-3`}
                onClick={() => handleTabClick(index + 1)}
              >
                <div className={`${style.tabsLogo}`}>
                  <img
                    src={activeTab === index + 1 ? tab.icon2 : tab.icon1}
                    alt=""
                  />
                </div>
                <div className={`${style.tabsDescription}`}>
                  <span>{tab.tabName}</span>
                </div>
              </div>
            ))}
          </div>
        )}
        {isPartner ? (
          <div className="tab-content">
            {tabContents[activeTab - 1].content}
          </div>
        ) : (
          <CourseContainer courses={courses} isLoading={courseLoading} />
        )}
      </Page>
    </PageWrapper>
  );
};

export default SchoolOrPartnerContents;
