import React, { useEffect, useState } from "react";
import Checks from "../../../../../components/bootstrap/forms/Checks";
import FormGroup from "../../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../../components/bootstrap/forms/Input";
import Wizard, { WizardItem } from "../../../../../components/Wizard";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import SubHeader, {
  SubHeaderLeft,
} from "../../../../../layout/SubHeader/SubHeader";
import { adminPages } from "../../../../../menu";
import { useFormik } from "formik";
import { get, post, put } from "../../../../../utils/service";
import { useNavigate } from "react-router-dom";
import Textarea from "../../../../../components/bootstrap/forms/Textarea";
import Button from "../../../../../components/bootstrap/Button";
import TestsTable from "./TestsTable";
import ListGroup, {
  ListGroupItem,
} from "../../../../../components/bootstrap/ListGroup";
import Badge from "../../../../../components/bootstrap/Badge";
import { useParams } from "react-router-dom";
import Select from "../../../../../components/bootstrap/forms/Select";
import Page from "../../../../../layout/Page/Page";
import "./style.css";
import showNotification from "../../../../../components/extras/showNotification";
import Icon from "../../../../../components/icon/Icon";
import {
  useClearCacheMutation,
  usePathsQuery,
} from "../../../../../store/features/apiSlice";
import useSortableData from "../../../../../hooks/useSortableData";
import { nanoid } from "nanoid";

function isEqual(item1, item2) {
  if (item1.inputs !== item2.inputs) return false;
  if (item1.expected_outputs !== item2.expected_outputs) return false;
  if (item1.is_default_test !== item2.is_default_test) return false;
  if (item1.input_type !== item2.input_type) return false;
  if (item1.expected_outputs_for_ui !== item2.expected_outputs_for_ui)
    return false;

  return true;
}

const AdminPracticeForm = () => {
  const [clearCache] = useClearCacheMutation();
  const navigate = useNavigate();
  const [practiceId, setPracticeId] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const { id } = useParams();

  const [filteredLearningPaths, setFilteredLearningPaths] = useState([]);
  const { data: learningPaths } = usePathsQuery();

  const { items } = useSortableData(
    filteredLearningPaths?.length > 0
      ? filteredLearningPaths
      : learningPaths
      ? learningPaths
      : []
  );

  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = "Başlık giriniz!";
    }
    if (!values.explanation) {
      errors.explanation = "Açıklama giriniz!";
    }
    // if (values.explanation.length > 2000) {
    //   errors.explanation = "Lütfen maksimum 2000 karakter giriniz";
    // }
    if (!values.language) {
      errors.language = "Programlama dili seçiniz!";
    }
    if (!values.points) {
      errors.points = "Puan giriniz!";
    }
    if (values.points && values.points > 100) {
      errors.points = "Lütfen 1-100 arasında bir değer giriniz";
    }
    if (parseInt(values.points) < 1) {
      errors.points = "Sıfırdan büyük puan giriniz!";
    }
    if (!values.level) {
      errors.level = "Seviye seçiniz!";
    }
    if (!values.type) {
      errors.type = "Tür giriniz!";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      id: null,
      title: "",
      explanation: "",
      template_code: "",
      additional_code: "",
      language: "python",
      level: "beginner",
      type: "test",
      points: 10,
      access_state: "1",
      is_active: false,
      tests: [],
      learning_paths: selectedItems,
    },
    validate,
    onSubmit: () => {
      postPractice();
    },
  });

  const validateTest = (values) => {
    const errors = {};
    if (!values.expected_outputs) {
      errors.expected_outputs = "Gerekli alan!";
    }
    return errors;
  };

  const testsFormik = useFormik({
    initialValues: {
      id:null,
      inputs: "",
      expected_outputs: "",
      is_default_test: false,
      input_type: 1,
      expected_outputs_for_ui: "",
    },
    validate: validateTest,
    onSubmit: (values, { resetForm }) => {
      let temp = formik.values.tests;
      temp.push(testsFormik.values);
      formik.setFieldValue("tests", [...temp]);
      resetForm({ values: "" });
    },
  });

  const postPractice = async () => {
    const pr = { ...formik.values };
    if (id > 0) {
      put(`practices/${id}/`, { id, ...pr }).then((res) => {
        if (res.status === 204 || res.status === 200)
          navigate("/admin/practices", { replace: true });
        clearCache();
      });
    } else {
      post(`practices/`, pr).then((res) => {
        console.log(" res:", res);
        if (res.status === 201) {
          setPracticeId(res.data.id);
          showNotification(
            <span className="d-flex align-items-center">
              <Icon icon="Info" size="lg" className="me-1" />
              <span>Kaydedildi</span>
            </span>,
            "Alıştırma başarıyla kaydedildi.",
            "success"
          );
        } else {
          showNotification(
            <span className="d-flex align-items-center">
              <Icon icon="Info" size="lg" className="me-1" />
              <span>Kaydedilemedi</span>
            </span>,
            res.error.response.data.explanation
              ? res.error.response.data.explanation
              : "Alıştırma kaydedilemedi",
            "danger"
          );
        }
        clearCache();
      });
    }
  };

  const deleteTest = (test) => {
    let temp = formik.values.tests.filter((t) => !isEqual(t, test));
    formik.setFieldValue("tests", [...temp]);
  };

  React.useEffect(() => {
    if (id) {
      get("practices/" + id).then((data) => {
        formik.setValues({ ...data.data });
        setSelectedItems(data.data.learning_paths);
      });
    }
    //eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (practiceId) navigate("/admin/practice-form-result/" + practiceId);
    //eslint-disable-next-line
  }, [practiceId]);

  const handleSearchChange = (newValue) => {
    setSearchValue(newValue);
  };

  const filterLearningPaths = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const newStudents =
      learningPaths &&
      learningPaths.filter(
        (item) => item.title && item.title.toLowerCase().includes(searchValue)
      );
    setFilteredLearningPaths(newStudents);
  };

  //effects
  useEffect(() => {
    filterLearningPaths();
    //eslint-disable-next-line
  }, [searchValue]);

  const handleCheckboxChange = (learning_path_id, practice_order_number) => {
    setSelectedItems((prevSelectedItems) => {
      const existingItemIndex = prevSelectedItems.findIndex(
        (item) => item.learning_path_id === learning_path_id
      );

      // Eğer learningPathId zaten seçilmişse, seçimi kaldır
      if (existingItemIndex !== -1) {
        const updatedItems = [...prevSelectedItems];
        updatedItems.splice(existingItemIndex, 1);
        return updatedItems;
      } else {
        // Eğer learningPathId daha önce seçilmemişse, seç
        return [
          ...prevSelectedItems,
          { learning_path_id, practice_order_number: 999 },
        ];
      }
    });
  };

  useEffect(() => {
    if (selectedItems) {
      formik.setFieldValue("learning_paths", selectedItems);
    }
    //eslint-disable-next-line
  }, [selectedItems]);

  return (
    <div style={{ width: "100%" }}>
      <PageWrapper title={adminPages.practice.text}>
        <SubHeader>
          <SubHeaderLeft>
            <div className="text-muted">
              <strong>
                <span className="text-info fw-bold">{"Alıştırmalar"}</span>
              </strong>
            </div>
          </SubHeaderLeft>
        </SubHeader>
        <Page>
          <div className="display-4 fw-bold py-3">
            {"Yeni Alıştırma"}
            {/* {formik.values.title || "Yeni Alıştırma"} */}
          </div>
          <div className="row">
            <Wizard
              className="mb-0"
              stretch={false}
              onSubmit={formik.handleSubmit}
            >
              <WizardItem id="content">
                <div>
                  <div className="row g-4">
                    <div className="col-12">
                      <FormGroup id="title" label="Başlık" isFloating>
                        <Input
                          placeholder="Başlık"
                          onChange={formik.handleChange}
                          value={formik.values.title}
                          name="title"
                          isValid={formik.isValid}
                          onBlur={formik.handleBlur}
                          isTouched={formik.touched.title}
                          invalidFeedback={formik.errors.title}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-12">
                      <Textarea
                        placeholder="Açıklama"
                        onChange={formik.handleChange}
                        value={formik.values.explanation}
                        name="explanation"
                        isValid={formik.isValid}
                        onBlur={formik.handleBlur}
                        isTouched={formik.touched.explanation}
                        invalidFeedback={formik.errors.explanation}
                      ></Textarea>
                    </div>
                  </div>
                </div>
              </WizardItem>
              <WizardItem id="content">
                <div>
                  <div className="row g-4">
                    <div
                      className="col-12"
                      style={{ height: "250px", overflow: "scroll" }}
                    >
                      <Input
                        value={searchValue}
                        onChange={(e) => handleSearchChange(e.target.value)}
                        type="text"
                        placeholder="İçeriklerde Ara.."
                        className="mb-2"
                      />
                      {items.length === 0 ? (
                        <div>
                          <span>Kayıtlı öğrenme yolu bulunamadı</span>
                        </div>
                      ) : (
                        items.map((item, i) => {
                          return (
                            <div className="d-flex" key={nanoid()}>
                              <div>
                                <Checks
                                  id={item.id.toString()}
                                  name="selectedList"
                                  value={item.id}
                                  onChange={() =>
                                    handleCheckboxChange(
                                      item.id,
                                      item.practice_order_number
                                    )
                                  }
                                  checked={selectedItems.some(
                                    (selectedItem) =>
                                      selectedItem.learning_path_id === item.id
                                  )}
                                />
                              </div>
                              <div>{item.title}</div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
              </WizardItem>
              <WizardItem id="general">
                <div>
                  <div className="row g-4">
                    <div className="col-12">
                      <Select
                      ariaLabel="Program"
                        list={[
                          { text: "Python", value: "python", key:nanoid() },
                          { text: "JavaScript", value: "javascript", key:nanoid() },
                          { text: "CSharp", value: "csharp", key:nanoid() },
                          { text: "Html", value: "HTML-CSS", key:nanoid() },
                          { text: "CSS", value: "HTML-CSS", key:nanoid() },
                        ]}
                        onChange={formik.handleChange}
                        value={formik.values.language}
                        name="language"
                        isValid={formik.isValid}
                        onBlur={formik.handleBlur}
                        isTouched={formik.touched.language}
                        invalidFeedback={formik.errors.language}
                      />
                    </div>
                    <div className="col-12">
                      <Select
                      ariaLabel="Seviye"
                        list={[
                          { text: "Beginner", value: "beginner", key:nanoid() },
                          { text: "Advance", value: "advance", key:nanoid() },
                          { text: "Intermediate", value: "intermediate", key:nanoid() },
                        ]}
                        onChange={formik.handleChange}
                        value={formik.values.level}
                        name="level"
                        isValid={formik.isValid}
                        onBlur={formik.handleBlur}
                        isTouched={formik.touched.level}
                        invalidFeedback={formik.errors.level}
                      />
                    </div>
                    <div className="col-12">
                      <Select
                      ariaLabel="Tür"
                        list={[
                          { text: "Text", value: "text", key:nanoid() },
                          { text: "Block", value: "block", key:nanoid() },
                        ]}
                        onChange={formik.handleChange}
                        value={formik.values.type}
                        name="type"
                        isValid={formik.isValid}
                        onBlur={formik.handleBlur}
                        isTouched={formik.touched.type}
                        invalidFeedback={formik.errors.type}
                      />
                    </div>
                    <div className="col-12">
                      <FormGroup id="point" label="Puani" isFloating>
                        <Input
                          id="points"
                          name="points"
                          type="number"
                          max="100"
                          step="5"
                          onChange={formik.handleChange}
                          value={formik.values.points}
                          isValid={formik.isValid}
                          onBlur={formik.handleBlur}
                          isTouched={formik.touched.points}
                          invalidFeedback={formik.errors.points}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-12">
                      <Select
                      ariaLabel="Durum"
                        list={[
                          { text: "Private", value: "1", key:nanoid() },
                          { text: "Public", value: "2", key:nanoid() },
                        ]}
                        onChange={formik.handleChange}
                        value={formik.values.access_state}
                        name="access_state"
                        isValid={formik.isValid}
                        onBlur={formik.handleBlur}
                        isTouched={formik.touched.access_state}
                        invalidFeedback={formik.errors.access_state}
                      />
                    </div>
                    <div className="col-12">
                      <FormGroup id="is_active" isFloating>
                        <Checks
                          id="herhangibir"
                          name="is_active"
                          label="Aktif Mi"
                          onChange={formik.handleChange}
                          checked={formik.values.is_active}
                        />
                      </FormGroup>
                      {/* <FormGroup id="is_active" isFloating>
                        <Checks
                          id="flexCheckDefault"
                          label="Aktif Mi?"
                          name="is_active"
                          onChange={formik.handleChange}
                          checked={formik.values.is_active}
                        />
                      </FormGroup> */}
                    </div>
                  </div>
                </div>
              </WizardItem>
              <WizardItem id="content">
                <div>
                  <div className="row g-4">
                    <div className="col-12">
                      <Textarea
                        name="template_code"
                        ariaLabel="Başlangıç Kodu"
                        placeholder="Başlangıç Kodu   ör : print('merhaba')"
                        onChange={formik.handleChange}
                        value={formik.values.template_code}
                      ></Textarea>
                    </div>
                    <div className="col-12">
                      <Textarea
                      ariaLabel="Ek Kod"
                        name="additional_code"
                        placeholder="Ek Kod"
                        onChange={formik.handleChange}
                        value={formik.values.additional_code}
                      ></Textarea>
                    </div>
                  </div>
                </div>
              </WizardItem>
              <WizardItem id="test">
                <div>
                  <div className="row g-4">
                    <div className="col-12">
                      <Select
                        id="input_type"
                        ariaLabel="input_type"
                        list={[
                          {
                            text: "parameter",
                            value: 1, key:nanoid()
                          },
                          {
                            text: "keyboard",
                            value: 2, key:nanoid()
                          },
                        ]}
                        onChange={testsFormik.handleChange}
                        value={testsFormik.values.input_type}
                      />
                    </div>
                    <div className="col-12">
                      <FormGroup id="inputs" label="Girdiler" isFloating>
                        <Input
                          onChange={testsFormik.handleChange}
                          value={testsFormik.values.inputs}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-12">
                      <FormGroup
                        id="expected_outputs"
                        label="Beklenen Çıktı"
                        isFloating
                      >
                        <Input
                          onChange={testsFormik.handleChange}
                          value={testsFormik.values.expected_outputs}
                          isValid={testsFormik.isValid}
                          onBlur={testsFormik.handleBlur}
                          isTouched={testsFormik.touched.expected_outputs}
                          invalidFeedback={testsFormik.errors.expected_outputs}
                          validFeedback="Looks good!"
                        />
                      </FormGroup>
                    </div>
                    <div className="col-12">
                      <FormGroup
                        id="expected_outputs_for_ui"
                        label="UI Beklenen Çıktı"
                        isFloating
                      >
                        <Input
                          onChange={testsFormik.handleChange}
                          value={testsFormik.values.expected_outputs_for_ui}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-12">
                      <FormGroup id="is_default_test" label="" isFloating>
                        <Checks
                          id="is_default_test"
                          label="Varsayılan Mı"
                          onChange={testsFormik.handleChange}
                          checked={testsFormik.values.is_default_test}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-12">
                      <Button
                        color="success"
                        onClick={testsFormik.handleSubmit}
                        isDisable={
                          !testsFormik.isValid && !!testsFormik.submitCount
                        }
                      >
                        Ekle
                      </Button>
                    </div>
                    <div className="col-12">
                      {formik.values.tests && (
                        <TestsTable
                          tests={formik.values.tests}
                          deleteTest={deleteTest}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </WizardItem>
              <WizardItem id="summary">
                <ListGroup isFlush>
                  <ListGroupItem>
                    <Badge color="info" isLight rounded={0}>
                      Başlık
                    </Badge>{" "}
                    {formik.errors.title ? (
                      <span className="error">{formik.errors.title}</span>
                    ) : (
                      formik.values.title
                    )}
                  </ListGroupItem>
                  <ListGroupItem>
                    <Badge color="info" isLight rounded={0}>
                      Açıklama:
                    </Badge>{" "}
                    {formik.errors.explanation ? (
                      <span className="error">{formik.errors.explanation}</span>
                    ) : (
                      formik.values.explanation
                    )}
                  </ListGroupItem>
                  <ListGroupItem>
                    <Badge color="info" isLight rounded={0}>
                      Programlama Dili:
                    </Badge>{" "}
                    {formik.errors.language ? (
                      <span className="error">{formik.errors.language}</span>
                    ) : (
                      formik.values.language
                    )}
                  </ListGroupItem>
                  <ListGroupItem>
                    <Badge color="info" isLight rounded={0}>
                      Seviye:
                    </Badge>{" "}
                    {formik.errors.level ? (
                      <span className="error">{formik.errors.level}</span>
                    ) : (
                      formik.values.level
                    )}
                  </ListGroupItem>
                  <ListGroupItem>
                    <Badge color="info" isLight rounded={0}>
                      Türü:
                    </Badge>{" "}
                    {formik.errors.type ? (
                      <span className="error">{formik.errors.type}</span>
                    ) : (
                      formik.values.type
                    )}
                  </ListGroupItem>
                  <ListGroupItem>
                    <Badge color="info" isLight rounded={0}>
                      Puanı:
                    </Badge>{" "}
                    {formik.errors.points ? (
                      <span className="error">{formik.errors.points}</span>
                    ) : (
                      formik.values.points
                    )}
                  </ListGroupItem>
                  <ListGroupItem>
                    <Badge color="info" isLight rounded={0}>
                      Aktif Mi:
                    </Badge>{" "}
                    {formik.values.is_active ? "Aktif" : "Pasif"}
                  </ListGroupItem>
                  <ListGroupItem>
                    <Badge color="info" isLight rounded={0}>
                      Başlangıç Kodu:
                    </Badge>{" "}
                    {formik.values.template_code}
                  </ListGroupItem>
                  <ListGroupItem>
                    <Badge color="info" isLight rounded={0}>
                      Ek Kod:
                    </Badge>{" "}
                    {formik.values.additional_code}
                  </ListGroupItem>
                  <ListGroupItem>
                    <TestsTable tests={formik.values.tests} deleteTest={deleteTest}/>
                  </ListGroupItem>
                </ListGroup>
              </WizardItem>
            </Wizard>
          </div>
        </Page>
      </PageWrapper>
    </div>
  );
};

export default AdminPracticeForm;
