import React from "react";
import style from "./LiveLectureAttendanceForTeacher.module.scss";
import AttendanceInfo from "./AttendanceInfo";
import { nanoid } from "nanoid";
import ChartInfo from "./ChartInfo";
import { Card, Spinner } from "react-bootstrap";
import Information from "./Information";
import { useGetLiveLecturesQuery, useLiveLectureCompletitionRateOfClassroomQuery, useLiveLectureStudentsAttandanceRateQuery } from "../../../../teacher/teacherApi";

const LiveLectureAttendanceForTeacher = ({ classId, isLoading }) => {
  const { data: teacherLiveLessons } = useGetLiveLecturesQuery();
  const { data: liveLectureCompletionData } = useLiveLectureCompletitionRateOfClassroomQuery(classId, {skip:!classId});
  const { data: liveLectureAttendanceData } = useLiveLectureStudentsAttandanceRateQuery(classId, {skip:!classId});
  const attendances = teacherLiveLessons ?? [];
  const value = liveLectureCompletionData?.live_lecture_completion_rate ?? 0;
  const studentsLiveLectureAttendanceRatio = liveLectureAttendanceData ?? [];
  return (
    <Card className={`${style.liveLectureAttendanceContainer} p-3 px-5`}>
        <div className={`${style.chartContainer}`}>
      <div className={`${style.title} my-3 px-3`}>Canlı Ders Katılım Durumu</div>
      <div className="d-flex">
        <div className={`${style.attendanceContainer}`}>
          {attendances?.map((attendance) => (
            <AttendanceInfo key={nanoid()} attendance={attendance.live_lecture} />
          ))}
        </div>
        {isLoading ? (
            <div className="w-100 d-flex justify-content-center align-items-center" style={{ height: '70vh' }}>
            <div className="text-center">
              <Spinner animation="border" role="status" style={{ width: '3rem', height: '3rem', color: '#007bff' }}>
                <span className="sr-only">...</span>
              </Spinner>
              <h3 className="mt-3" style={{ color: '#007bff', fontWeight: 'bold' }}>Yükleniyor...</h3>
            </div>
          </div>
          ) : (
            <div className={`${style.chartDiv}`}>
              <ChartInfo value={value} />
            </div>
          )}
      </div>
        </div>
        <div className={`${style.informationsContainer}`}>
        <div className={`${style.title} my-3 mb-4 px-3`}>
          Ders Katılım Oranı En Yüksek 5 Öğrenci
        </div>
        {studentsLiveLectureAttendanceRatio && studentsLiveLectureAttendanceRatio.length < 1 && (
          <div className={`${style.emptyList}`}><span>Öğrenci bulunmamaktadır</span></div>
        )}
        {studentsLiveLectureAttendanceRatio &&
          studentsLiveLectureAttendanceRatio.length > 0 &&
          studentsLiveLectureAttendanceRatio.slice(0,5).map((item, index) => {
            return (
              <div
                className={`${style.information} py-3 px-3 my-3`}
                key={nanoid()}
              >
                <Information description={item.student} value={item.live_lecture_attendance_rate} email={item.email} index={index} />
              </div>
            );
          })}
      </div>
    </Card>
  );
};

export default LiveLectureAttendanceForTeacher;
