import React from 'react'
import style from "../ParentReportWithoutClass.module.scss"
import useDarkMode from '../../../../../../hooks/useDarkMode';

const StudentInfo = ({logo, logo2, generalDescription, generalDetail}) => {
  const { darkModeStatus } = useDarkMode();
  return (
    <div className={`${style.studentInfoContainer} d-flex align-items-center justify-content-center gap-3`}>
      <div className={`${style.logoDiv}`}>
        <img className={`${style.logo1}`} src={logo} alt="" />
        <img className={`${style.logo2}`} src={logo2} alt="" />
      </div>

      <div className={`${style.line} mx-2`}></div>

        <div className={`${style.secondSection} text-center`}>
        <div><span className={`${style.description}`}>{generalDescription}</span></div>
      <div><span className={`${style.detail}`} style={{color:darkModeStatus && "#fff"}}>{generalDetail}</span></div>
        </div>
    </div>
  )
}

export default StudentInfo