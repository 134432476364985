import React, { useEffect, useState } from "react";
import USER_ROLES from "../../../../../common/data/enumUserRole";
import { useSchoolStudentsDetailQuery } from "../../schoolManagerApi";
import { useGetSchoolStudentsDetailQuery } from "../../../../../store/features/apiSlice";
import style from "./SchoolStudentsDetail.module.scss";
import Icon from "../../../../../components/icon/Icon";
import { useMediaQuery } from "react-responsive";
import useSortableData from "../../../../../hooks/useSortableData";
import Button from "../../../../../components/bootstrap/Button";
import { downloadExcel } from "../../../../../common/downloadExcel";
import PaginationButtons, { dataPagination, PER_COUNT } from "../../../../../components/PaginationButtons";

const SchoolStudentsDetail = () => {

  const role = localStorage.getItem("roles");
  const isAdmin = Number(role) === USER_ROLES.ADMIN.id;
  const isSchoolManager = Number(role) === USER_ROLES.SCHOOL_MANAGER.id;
  const schoolId = isSchoolManager
    ? localStorage.getItem("school")
    : localStorage.getItem("schoolId");
  const { data: studentsForAdmin } = useGetSchoolStudentsDetailQuery(schoolId, {
    skip: !isAdmin || !schoolId,
  });
  const { data: studentsForManager } = useSchoolStudentsDetailQuery(schoolId, {
    skip: !schoolId || !isSchoolManager,
  });
  const allStudetns = isAdmin ? studentsForAdmin : studentsForManager;

  const isMobile = useMediaQuery({ minWidth: 768 });

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_COUNT["10"]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const { items, requestSort, getClassNamesFor } = useSortableData(
    filteredUsers ? filteredUsers : allStudetns ? allStudetns : []
  );

  useEffect(() => {
    filterUsers();
    //eslint-disable-next-line
  }, [searchValue]);

  const filterUsers = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const newUsers =
      allStudetns &&
      allStudetns.filter(
        (item) => item.email && item.email.toLowerCase().includes(searchValue)
      );
    setFilteredUsers(newUsers);
  };

  useEffect(() => {
    setSearchValue("");
    filterUsers();
    //eslint-disable-next-line
  }, [allStudetns]);

  const handleReport = () => {
    const data = items;
    const headers = [
      { key: "full_name", label: "Kullanıcı Adı" },
      { key: "email", label: "E-posta" },
      { key: "classroom_name", label: "Sınıf" },
      { key: "general_points", label: "Genel Puan" },
      { key: "active_days_count", label: "Aktif Günler" },
      { key: "classroom_points", label: "Sınıf Puanı" },
    ];
    const fileName = "kullanici_listesi.xlsx";
    downloadExcel(data, headers, fileName);
  };
  return (
    <div>
      <Button
        className={`${style.messageButton} btn`}
        color="primary"
        onClick={() => handleReport()}
      >
        Excel olarak indir
      </Button>
      <div className={`${style.actionsContainer} px-2 mx-4 gap-3 mx-auto`}>
        <div className={`${style.inputContainer} `}>
          <div
            className={`${style.searchContainer} d-flex align-items-center justify-content-center`}
          >
            <form className="d-flex gap-1 w-100" onSubmit={filterUsers}>
              <label className="" htmlFor="searchInput">
                <Icon
                  icon="Search"
                  size="2x"
                  style={{ color: "#000", opacity: "0.3" }}
                />
              </label>
              <input
                className="form-control border-0 shadow-none bg-transparent"
                type="search"
                placeholder={
                  isMobile && "Aramak istediğiniz kullanıcıyı yazınız"
                }
                // placeholder="Aramak istediğiniz kullanıcıyı yazınız"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
              />
            </form>
          </div>
        </div>
      </div>

      <div className={`${style.customTable} mt-5 px-4`}>
        <div
          className={`${style.customHeadContainer} d-flex align-items-center my-3 px-2`}
        >
          <div className={`${style.customTableHead1} ${style.customTableHead}`} onClick={() => requestSort("full_name")}>
            Ad Soyad
            <Icon
              size="lg"
              className={getClassNamesFor("full_name")}
              icon="FilterList"
            />
          </div>
          <div className={`${style.customTableHead2} ${style.customTableHead}`}>
            Email
          </div>

          <div
            className={`${style.customTableHead3} ${style.customTableHead} text-center`}
            onClick={() => requestSort("classroom_name")}
          >
            Sınıf
            <Icon
              size="lg"
              className={getClassNamesFor("classroom_name")}
              icon="FilterList"
            />
          </div>
          <div
            className={`${style.customTableHead4} ${style.customTableHead} text-center`}
            onClick={() => requestSort("general_points")}
          >
            Genel Puan
            <Icon
              size="lg"
              className={getClassNamesFor("general_points")}
              icon="FilterList"
            />
          </div>
          <div
            className={`${style.customTableHead5} ${style.customTableHead} text-center`}
            onClick={() => requestSort("active_days_count")}
          >
            Aktif Günler
            <Icon
              size="lg"
              className={getClassNamesFor("active_days_count")}
              icon="FilterList"
            />
          </div>
          <div
            className={`${style.customTableHead6} ${style.customTableHead} text-center`}
            onClick={() => requestSort("classroom_points")}
          >
            Sınıf Puanı
            <Icon
              size="lg"
              className={getClassNamesFor("classroom_points")}
              icon="FilterList"
            />
          </div>
        </div>
        <div className={`${style.customRowContainer}`}>
          {items.length === 0 ? (
            <div>
              <span>Kayıtlı öğrenci bulunamadı</span>
            </div>
          ) : (
            dataPagination(items, currentPage, perPage).map((student, i) => {
              const {
                id,
                full_name,
                email,
                classroom_name,
                classroom_points,
                general_points,
                active_days_count
              } = student;
              return (
                <div
                  key={id}
                  className={`${style.customTableRow} ${
                    i % 2 === 0 && style.customBackgroundColor
                  } d-flex align-items-center mt-1 px-2`}
                >
                  <div className={`${style.customTableColumn1}`}>
                    <div>
                      <div className={`${style.titleContainer}`}>
                        <div className={`${style.titleShort}`}>
                          {full_name
                            ? full_name
                            : email.split("@")[0]}
                        </div>
                        <div className={`${style.titleFull}`}>
                          {full_name
                            ? full_name
                            : email.split("@")[0]}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${style.customTableColumn2}`}>
                    <div className={`${style.descriptionContainer}`}>
                      <div className={`${style.descriptionShort}`}>
                        {email}
                      </div>
                      <div className={`${style.descriptionFull}`}>
                        {email}
                      </div>
                    </div>
                  </div>
                  <div className={`${style.customTableColumn3} text-center`}>
                    <div>{classroom_name}</div>
                  </div>
                  <div className={`${style.customTableColumn4} text-center`}>
                    {general_points}
                  </div>
                  <div className={`${style.customTableColumn5} text-center`}>
                    {active_days_count}
                  </div>
                  <div className={`${style.customTableColumn6} text-center`}>
                    {classroom_points}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      <PaginationButtons
          data={items}
          label="kullanıcı"
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
    </div>
  );
};

export default SchoolStudentsDetail;
