import React from "react";
import style from "./CertificateThumbnail.module.scss";

const CertificateThumbnail = ({ name, src }) => {
  const maxLength = 28;
  const trimmedName =
    name.length > maxLength ? `${name.substring(0, maxLength)}.` : name;

  function countWords(text) {
    const trimmedText = text.trim();
    const wordCount = trimmedText.split(/\s+/).length;

    return wordCount;
  }

  return (
    <div className={`${style.certificateContainer}`}>
      <img src={src} alt="user certificate" />
      <div
        className={`${style.nameContainer}`}
        style={{ fontSize: `${countWords(name) > 3 ? "11.5px" : "13px"}` }}
      >
        {trimmedName}
      </div>
    </div>
  );
};

export default CertificateThumbnail;
