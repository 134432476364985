import React, { useEffect, useState } from "react";
import PageWrapper from "../../../../../../layout/PageWrapper/PageWrapper";
import SubHeader, {
  SubHeaderLeft,
  SubHeaderRight,
} from "../../../../../../layout/SubHeader/SubHeader";
import Button from "../../../../../../components/bootstrap/Button";
import Page from "../../../../../../layout/Page/Page";
import Card, {
  CardBody,
  CardFooter,
  CardFooterRight,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../../../../components/bootstrap/Card";
import FormGroup from "../../../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../../../components/bootstrap/forms/Input";
import showNotification from "../../../../../../components/extras/showNotification";
import Icon from "../../../../../../components/icon/Icon";
import {
  useClearCacheMutation,
} from "../../../../../../store/features/apiSlice";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import Confirm from "../../../../../common/Confirm";
import { useLocation } from "react-use";
import CreateLessonModal from "../5-CreateLessonModal";
import UpdateLessonModal from "../6-UpdateLessonModal";
import _ from "underscore";
import {
  useSingleCourseTeacherQuery,
  useUpdateCourseTeacherMutation,
} from "../../teacherEducationApi";

const TeacherUpdateChapter = () => {
  let { id: chapterId } = useParams();
  chapterId = Number(chapterId);
  const location = useLocation();
  const { id, chapterTitle } = location.state.usr;
  const { data, isSuccess } = useSingleCourseTeacherQuery({
    id,
  });
  const [clearCache] = useClearCacheMutation();
  const [updateCourse] = useUpdateCourseTeacherMutation();

  const [open, setOpen] = useState(false);
  const [lessonId, setLessonId] = useState(null);
  const [currentLesson, setCurrentLesson] = useState(null);
  const [isOpenCreateLessonModal, setIsOpenCreateLessonModal] = useState(false);
  const [isOpenUpdateLessonModal, setIsOpenUpdateLessonModal] = useState(false);

  let currentChapter =
    data &&
    data.chapters.find(
      (chapter) => Number(chapter.id) === Number(chapterId) || chapter.title === chapterTitle
    );
  const navigate = useNavigate();

  const TABS = {
    CHAPTER_DETAIL: "Chapter Details",
    LESSON: "Lesson",
  };
  const [activeTab, setActiveTab] = useState(TABS.LESSON);

  useEffect(() => {
    if (isSuccess && data) {
      if (currentChapter) {
        formik.setFieldValue("title", currentChapter.title);
        formik.setFieldValue("order_number", currentChapter.order_number);
        formik.setFieldValue("lessons", currentChapter.lessons);
      }
    }
    //eslint-disable-next-line
  }, [isSuccess, data]);

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Ünitenin adını giriniz";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      title: "",
      order_number: 9999,
      lessons: [],
    },
    validate,
    onSubmit: () => {
      handleChapter();
    },
  });

  //! update chapter
  const handleChapter = async () => {
    const chapterValues = { id: chapterId, ...formik.values };
    let newChapters =
      data &&
      data.chapters.map((chapter) =>
        Number(chapter.id) === Number(chapterId) ? { ...chapter, ...chapterValues } : chapter
      );
    newChapters = _.sortBy(newChapters, "order_number");
    const response = await updateCourse({
      id: data.id,
      title: data.title,
      ...data,
      chapters: newChapters,
    });
    if (response.error) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Güncelleme Başarısız</span>
        </span>,
        "Bir hata oluştu",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Güncelleme Başarılı</span>
        </span>,
        "Ünite bilgileri başarıyla güncellendi.",
        "success"
      );
    }
    clearCache();
  };

  //! delete lesson--------
  const removeLesson = (e) => {
    setOpen(true);
    setLessonId(e.currentTarget.id && e.currentTarget.id);
  };
  const handleDelete = async () => {
    let updatedLessons = currentChapter.lessons.filter(
      (item) => Number(item.id) !== Number(lessonId)
    );
    updatedLessons = _.sortBy(updatedLessons, "order_number");
    let updatedChapter = currentChapter && {
      ...currentChapter,
      lessons: updatedLessons,
    };
    let newChapters =
      data &&
      data.chapters.map((chapter) =>
        chapter.title === currentChapter.title
          ? { ...chapter, ...updatedChapter }
          : chapter
      );
    const response = await updateCourse({
      id,
      title: data.title,
      ...data,
      chapters: newChapters,
    });
    setOpen(false);
    if (response.error) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Silme İşlemi Başarısız</span>
        </span>,
        "Bir hata meydana geldi",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Silme Başarılı</span>
        </span>,
        "Ünite başarıyla silindi.",
        "success"
      );
    }
    clearCache();
  };
  const handleCancel = () => setOpen(false);

  //!create lesson
  const addLesson = (e) => {
    setIsOpenCreateLessonModal(true);
  };

  //! update lesson
  const updateLesson = (e) => {
    let updatingLesson =
      currentChapter &&
      currentChapter.lessons.find((lesson) => Number(lesson.id) === Number(e.currentTarget.id));
    setCurrentLesson(updatingLesson);
    setIsOpenUpdateLessonModal(true);
  };

  const handleNavigation = (path, activeTab) => {
    navigate(`${path}?activeTab=${activeTab}`);
  };

  return (
    <PageWrapper title={"Ünite Bilgileri"}>
      <CreateLessonModal
        isOpenCreateLessonModal={isOpenCreateLessonModal}
        setIsOpenCreateLessonModal={setIsOpenCreateLessonModal}
        chapterId={chapterId}
        data={data}
        currentChapter={currentChapter}
      />
      <UpdateLessonModal
        isOpenUpdateLessonModal={isOpenUpdateLessonModal}
        setIsOpenUpdateLessonModal={setIsOpenUpdateLessonModal}
        chapterId={chapterId}
        data={data}
        currentChapter={currentChapter}
        currentLesson={currentLesson}
      />
      <Confirm
        open={open}
        setOpen={setOpen}
        onConfirm={handleDelete}
        onCancel={handleCancel}
      />
      <SubHeader>
        <SubHeaderLeft>
          <Button color="info" isLink icon="ArrowBack" tag="a" to={-1}>
            Ders Bilgileri Sayfasına Geri Dön
          </Button>
        </SubHeaderLeft>
        <SubHeaderRight></SubHeaderRight>
      </SubHeader>
      <Page>
        <div className="row h-100">
          <div className="col-xl-3 col-lg-4 col-md-4">
            <Card stretch>
              <CardHeader>
                <CardLabel icon="Edit" iconColor="info">
                  <CardTitle>Ünite Düzenle</CardTitle>
                </CardLabel>
              </CardHeader>
              <CardBody isScrollable>
                <div className="row g-3">
                  <div className="col-12">
                    <Button
                      icon="Info"
                      color="info"
                      className="w-100 p-3"
                      isLight={TABS.CHAPTER_DETAIL !== activeTab}
                      onClick={() => setActiveTab(TABS.CHAPTER_DETAIL)}
                    >
                      Ünite Bilgileri
                    </Button>
                  </div>
                  <div className="col-12">
                    <Button
                      icon="Computer"
                      color="info"
                      className="w-100 p-3"
                      isLight={TABS.LESSON !== activeTab}
                      onClick={() => setActiveTab(TABS.LESSON)}
                    >
                      Konular
                    </Button>
                  </div>
                  <div className="col-12 border-bottom" />
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-xl-9 col-lg-8 col-md-8">
            {TABS.CHAPTER_DETAIL === activeTab && (
              <Card
                stretch
                tag="form"
                noValidate
                onSubmit={formik.handleSubmit}
              >
                <CardHeader>
                  <CardLabel icon="Details" iconColor="info">
                    <CardTitle>Ünite Bilgileri</CardTitle>
                  </CardLabel>
                </CardHeader>
                <CardBody className="pb-0" isScrollable>
                  <div className="row g-4">
                    <div className="col-lg-8">
                      <FormGroup
                        // className="col-4"
                        id="title"
                        isFloating
                        label="Başlık"
                      >
                        <Input
                          onChange={formik.handleChange}
                          value={formik.values.title}
                          onBlur={formik.handleBlur}
                          isValid={formik.isValid}
                          isTouched={formik.touched.title}
                          invalidFeedback={formik.errors.title}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-lg-8">
                      <FormGroup
                        isFloating
                        label="Sıra Numarası"
                        id="order_number"
                      >
                        <Input
                          name="order_number"
                          type="text"
                          autoComplete="order_number"
                          value={formik.values.order_number}
                          onChange={formik.handleChange}
                          isValid={formik.isValid}
                          onBlur={formik.handleBlur}
                          isTouched={formik.touched.order_number}
                          invalidFeedback={formik.errors.order_number}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </CardBody>
                <CardFooter>
                  <CardFooterRight>
                    <Button
                      icon="DoneOutline"
                      color="success"
                      isLight
                      onClick={async () => {
                        formik.handleSubmit();
                      }}
                    >
                      Güncelle
                    </Button>
                    <Button
                      icon="Cancel"
                      color="danger"
                      isLight
                      tag="a"
                      onClick={() =>
                        handleNavigation(`/admin/updateCourse/${id}`, "Chapter")
                      }
                    >
                      İptal
                    </Button>
                  </CardFooterRight>
                </CardFooter>
              </Card>
            )}
            {TABS.LESSON === activeTab && (
              <Card stretch tag="form" noValidate>
                <CardHeader>
                  <CardLabel icon="Computer" iconColor="success">
                    <CardTitle>Konu İçerikleri</CardTitle>
                  </CardLabel>
                  <Button
                    color="info"
                    className={`ms-5 mb-4 shadow-3d-up-hover}`}
                    onClick={() => addLesson()}
                  >
                    Konu Ekle
                  </Button>
                </CardHeader>
                <CardBody>
                  <table className="table table-modern">
                    <thead style={{ fontSize: "16px" }}>
                      <tr>
                        <th>Başlık</th>
                        <th style={{ textAlign: "center" }}>Sırası</th>
                        <th className="text-center">Sil</th>
                        <th style={{ textAlign: "center" }}>Konu Detayı</th>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: "16px" }}>
                      {currentChapter &&
                        currentChapter.lessons.map((lesson) => {
                          const { id, title, order_number } = lesson;
                          return (
                            <tr key={id}>
                              <td>{title}</td>
                              <td style={{ textAlign: "center" }}>
                                {order_number}
                              </td>
                              <td className="text-center">
                                <IconButton
                                  id={id}
                                  onClick={(e) => removeLesson(e)}
                                >
                                  <Delete className="fs-2" />
                                </IconButton>
                              </td>
                              <td className="text-center">
                                <IconButton
                                  id={id}
                                  onClick={(e) => updateLesson(e)}
                                >
                                  <Edit className="fs-2" />
                                </IconButton>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            )}
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default TeacherUpdateChapter;
