import React from 'react'
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/bootstrap/Button';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { setRegister } from '../../../store/loginWithPaymentPlanSlice';

const SuccessfulRegister = () => {
  const navigate = useNavigate();
const dispatch = useDispatch();
const {paymentPlan} = useSelector((store) => store.loginWithPaymentPlan);
const checkPaymentPlan = () =>{
  if(paymentPlan){
    dispatch(setRegister(true));
    navigate(-1)
  }else{
    navigate("/login")
  }
}

return (
    <PageWrapper className={"bg-success"}>
      <Page className="p-0">
        <div className="row h-100 align-items-center justify-content-center">
          <div className="col-xl-6 col-lg-6 col-md-8 shadow-3d-container">
            <Card className="shadow-3d-dark bg-info">
              <CardBody>
                <div className="text-center my-5">
                    <h1 >Kayıt işleminiz tamamlandı.</h1>
                  <Button color="warning" size="lg" onClick={()=>checkPaymentPlan()}>Giriş sayfasına git.</Button>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
}

export default SuccessfulRegister
