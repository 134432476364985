import React from "react";
import Button from "../../../../../components/bootstrap/Button";
import useDarkMode from "../../../../../hooks/useDarkMode";
import { useCoursesForTeacherQuery } from "../../../admin/course management for teacher/teacherEducationApi";
import { TEACHER_COURSE_STATUS } from "../../../../../common/data/enumTeacherEducationCourseState";
import useSortableData from "../../../../../hooks/useSortableData";

const TeacherCourses = () => {
  const { darkModeStatus } = useDarkMode();
  const { data } = useCoursesForTeacherQuery();
  const { items } = useSortableData(
      data
      ? data
      : []
  );

  return (
        <div className="page container-xxl">
          <div className="row">
          {items.length === 0 ? (
            <div>
              <span>Kayıtlı ders bulunamadı</span>
            </div>
          ) : (
            items?.map((lesson) => {
              const { title, id, status, cover_image } = lesson;
              const is_draft = status === TEACHER_COURSE_STATUS.DRAFT.id;
              return (
                <div
                  key={id}
                  className="LearningPaths_learningPathCard__9zgcx col-xxl-3 col-xl-4 col-md-6"
                >
                  <div className="card" style={{ maxHeight: "350px"}}>
                    <div
                      className={`card-header d-flex justify-content-center text-center ${
                        is_draft && !darkModeStatus ? "bg-l10-dark" : ""
                      }`}
                    >
                      <div className="card-label">
                        <div
                          className="card-title-wrapper"
                          style={{ height: "50px" }}
                        >
                          <h5 className="card-title">{title}</h5>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`card-body ${is_draft && !darkModeStatus ? "bg-l10-dark" : ""}`}
                    >
                      <img
                        src={`/images/${cover_image}.png`}
                        alt=""
                        width={128}
                        height={128}
                        className="mx-auto d-block img-fluid mb-3"
                      />
                    </div>
                    <div
                      className={`card-footer shadow-3d-container ${
                        is_draft && !darkModeStatus ? "bg-l10-dark" : ""
                      }`}
                    >
                      {status === TEACHER_COURSE_STATUS.ACTIVE.id ? (
                          <Button
                            color="dark"
                            className="btn btn-myButton btn-lg w-100 mb-4 shadow-3d-up-hover shadow-3d-myButton"
                            size="lg"
                            tag="a"
                            to={`/teacher/courseContentForTeacher/${id}`}
                          >
                            Derse Gir
                          </Button>
                        ) : (
                        <Button
                          color="dark"
                          className="btn btn-myButton btn-lg w-100 mb-4 shadow-3d-up-hover shadow-3d-myButton"
                          size="lg"
                        >
                          Ders Hazırlanıyor ...
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          )}
          </div>
        </div>
  );
};

export default TeacherCourses;
