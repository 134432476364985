import React, { useEffect, useState } from 'react'
import style from "./Activities.module.scss"
import Card from '../../../../../components/bootstrap/Card'
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup'
import Select from '../../../../../components/bootstrap/forms/Select'
import { formatDate2TurkishShortDate, isDateInLastTenDays } from '../../../../../utils/formatDate'
import { nanoid } from "nanoid";
import circle from "../../../../../assets/dashboardImages/circle.png"
import { useHomeworksOfClassQuery } from '../../../teacher/teacherApi'

const Activities = ({ activeClasses }) => {
  const [selectedClassId, setSelectedClassId] = useState()

  const { data: homeworksOfClass } = useHomeworksOfClassQuery(selectedClassId ? selectedClassId : activeClasses[0].id);

  useEffect(() => {
    if (activeClasses && activeClasses.length > 0) {
      setSelectedClassId(activeClasses[0].id)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Card className={`${style.cardContainer}`}>
      <div className={`${style.titleAndDropdownContainer}`}>
    <div className={`${style.leftSection} pt-2 my-3`}> 
    <span className={`${style.title} ms-5`}>Son 10 günlük çalışmalar</span>
  </div>
  <div className={`${style.classDropdown} pe-5 my-2`}>
      <FormGroup id="state" label="Sınıf" isFloating className={`${style.classDropdownForm}`}>
        <Select
          value={String(selectedClassId)}
          id="state"
          ariaLabel="Sınıf"
          placeholder="Sınıf Seçiniz.."
          list={activeClasses?.map(c => ({ value: c.id, text: c.name }))}
          onChange={(e) => { console.log(e.target.value); setSelectedClassId(e.target.value) }}
          className={`${style.classDropdownSelect}`}
        />
      </FormGroup>
    </div>
      </div>
     <div className={`${style.customTable} px-4`}>
        <div
          className={`${style.customHeadContainer} d-flex align-items-center justify-content-between my-1 px-2`}
        >
          <div className={`${style.customTableHead1} ${style.customTableHead}`}>
            Tarih
          </div>
          <div className={`${style.customTableHead2} ${style.customTableHead}`}>
            
          </div>
          <div className={`${style.customTableHead3} ${style.customTableHead}`}>
            Açıklama
          </div>
        </div>

        <div className={`${style.customRowContainer}`}>
          {homeworksOfClass && homeworksOfClass.length === 0 ? (
            <div className={`${style.emptyTable}`}>
              <span>Aktivite bulunamadı</span>
            </div>
          ) : (
            homeworksOfClass &&
            homeworksOfClass.filter(activite => isDateInLastTenDays(activite.created_date)).map((item, i) => (
              <div
                key={nanoid()}
                className={`${style.customTableRow} ${
                  i % 2 === 0 && style.customBackgroundColor
                } d-flex align-items-center justify-content-between mb-3 px-2 cursor-pointer`}
              >
                <div
                  className={`${style.customTableColumn1} ${style.tableColumn}`}
                >
                  <div>{formatDate2TurkishShortDate(item.created_date)}</div>
                </div>
                <div
                  className={`${style.customTableColumn2} ${style.tableColumn}`}
                >
                  <div><img src={circle} alt="" /></div>
                </div>
                <div
                  className={`${style.customTableColumn3} ${style.tableColumn}`}
                >
                  <div>
                    <div className={`${style.titleContainer}`}>
                      <div className={`${style.titleShort}`}>
                        {item.title}
                      </div>
                      <div className={`${style.titleFull}`}>
                        {item.title}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
  </Card>
  )
}

export default Activities