import React from "react";
import { useFormik } from "formik";
import Modal, {
  ModalBody,
  ModalHeader,
} from "../../../../components/bootstrap/Modal";
import { OffCanvasTitle } from "../../../../components/bootstrap/OffCanvas";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import Select from "../../../../components/bootstrap/forms/Select";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/bootstrap/Button";
import { setSelectedClass } from "../../teacher/teacherSlice";
import { useUsersQuery } from "../../../../store/features/apiSlice";
import { useSchoolUsersQuery } from "../schoolManagerApi";
import USER_ROLES from "../../../../common/data/enumUserRole";

const ClassForm = ({
  saveClass,
  selectedSchoolId,
  modalStatus,
  setModalStatus,
}) => {
  const role = localStorage.getItem("roles");
  const isAdmin = Number(role) === USER_ROLES.ADMIN.id;
  const isSchoolManager = Number(role) === USER_ROLES.SCHOOL_MANAGER.id;

  const { selectedClass } = useSelector((store) => store.teacher);
  const dispatch = useDispatch();
  const schoolTeachersForAdmin =`school=${selectedSchoolId}&role=2`
  const schoolTeachersForManager =`role=2`
  const { data:schoolUsersForAdmin } = useUsersQuery(schoolTeachersForAdmin, {skip:!isAdmin});
  const { data:schoolUsersForManager } = useSchoolUsersQuery(schoolTeachersForManager, {skip:!isSchoolManager});

  let schoolUsers;
  if(isAdmin){
    schoolUsers = (isAdmin && schoolUsersForAdmin) && schoolUsersForAdmin.results
  }else{
    schoolUsers = (isSchoolManager && schoolUsersForManager) && schoolUsersForManager.results
  }
  const defaultTeacher = [{ text: "Yok", value: 0 }];
  let teacherList =
  (schoolUsers && schoolUsers.length > 0) ?
  schoolUsers.map((item) => {
    // const teacher_id = item.first_name+" "+item.last_name
    const teacher_id = item.email;
    return {
      text: teacher_id,
      value: item.id,
    };
  }) : [];
  const newTeacherList =
  teacherList && defaultTeacher.concat(teacherList);

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Ad giriniz!";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      id: 0,
      name: "",
      teacher_id:null,
      school: selectedSchoolId,
      state: "1",
      // learning_paths: [],
    },
    validate,
    onSubmit: async (values) => {
      saveClass({
        id: values.id,
        name: values.name,
        teacher_id: parseInt(values.teacher_id, 10),
        state: parseInt(values.state, 10),
        school: parseInt(values.school, 10),
        // learning_paths: selectedClass.learning_paths,
      });
      dispatch(setSelectedClass({ ...selectedClass }));
      setModalStatus(false);
      formik.resetForm();
    },
  });

  // const handleAddPath = (paths) => {
  //   formik.setFieldValue(
  //     "learning_paths",
  //     paths.map((i) => Number(i))
  //   );
  // };

  const initialize = async () => {
    formik.resetForm();
    if (selectedClass) formik.setValues(selectedClass);
    console.log(selectedClass);
    // await handleGetSchools();
  };

  React.useEffect(() => {
    initialize();
    //eslint-disable-next-line
  }, [modalStatus]);

  return (
    <div>
      <Modal
        isOpen={modalStatus}
        setIsOpen={setModalStatus}
        titleId="upcomingEdit"
        isCentered
        isScrollable
        size={null}
      >
        <ModalHeader setIsOpen={setModalStatus}>
          <OffCanvasTitle id="upcomingEdit">
            {selectedClass
              ? "Sınıf Düzenleme Ekranı"
              : "Yeni Sınıf Ekleme Ekranı"}
          </OffCanvasTitle>
        </ModalHeader>
        <ModalBody>
          <div className="row g-4">
            <div className="col-12">
              <div
                className="mb-0"
                stretch={false}
                onSubmit={formik.handleSubmit}
              >
                {/* <WizardItem id="content"> */}
                <div>
                  <form className="row g-4" onSubmit={formik.handleSubmit}>
                    <div className="col-12">
                      <FormGroup id="name" label="Ad" isFloating>
                        <Input
                          placeholder="Ad"
                          onChange={formik.handleChange}
                          value={formik.values.name}
                          isValid={formik.isValid}
                          onBlur={formik.handleBlur}
                          isTouched={formik.touched.name}
                          invalidFeedback={formik.errors.name}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-12">
            <FormGroup id="teacher_id" label="Öğretmen" isFloating >
              <Select
                placeholder="Öğretmen"
                onChange={formik.handleChange}
                value={formik.values.teacher_id}
                isValid={formik.isValid}
                onBlur={formik.handleBlur}
                isTouched={formik.touched.teacher_id}
                invalidFeedback={formik.errors.teacher_id}
                list={newTeacherList}
                className="text-dark"
                ariaLabel={"teacher"}
              ></Select>
            </FormGroup>
          </div>
                    <div className="col-12">
                      <FormGroup id="state" label="Durum" isFloating>
                        <Select
                          id="state"
                          ariaLabel="Durum"
                          placeholder="Durum seçiniz..."
                          list={[
                            { value: "1", text: "Aktif" },
                            { value: "2", text: "Pasif" },
                            { value: "3", text: "Tamamlandı" },
                          ]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.state}
                          isValid={formik.isValid}
                          isTouched={formik.touched.state}
                          invalidFeedback={formik.errors.state}
                        />
                      </FormGroup>
                    </div>
                    <div className="d-flex justify-content-end gap-3">
                      <div className="d-flex ">
                        <Button
                          onClick={formik.handleSubmit}
                          className="bg-info"
                        >
                          Kaydet
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
                {/* </WizardItem> */}

                {/* <WizardItem id="learning-paths">
                <ClassLearningPaths/>
                </WizardItem> */}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ClassForm;
