import React, { useEffect, useState } from "react";
import PageWrapper from "../../../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../../../layout/Page/Page";
import style from "./TeacherArticles.module.scss";
import Search from "../../../class/search";
import useSortableData from "../../../../../../hooks/useSortableData";
import { nanoid } from "nanoid";
import { useArticlesForTeacherQuery } from "../../../../admin/course management for teacher/teacherEducationApi";
import SingleArticle from "./SingleArticle";


const TeacherArticles = () => {
  const {data:teacherArticles} = useArticlesForTeacherQuery();
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (newValue) => {
    setSearchValue(newValue);
  };
  const { items } = useSortableData(
    filteredArticles
      ? filteredArticles
      : teacherArticles
      ? teacherArticles
      : []
  );

  const filterArticles = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const newArticles =
      teacherArticles &&
      teacherArticles.filter(
        (item) => item.title && item.title.toLowerCase().includes(searchValue)
      );
    setFilteredArticles(newArticles);
  };

  //effects
  useEffect(() => {
    filterArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  
  return (
    <PageWrapper>
      <Page className={`${style.pageContainer}`}>
        <div className={`${style.actionsContainer} px-2 mx-4 gap-3 mx-auto`}>
          <div className={`${style.inputContainer} `}>
            <Search
              handleSearchChange={handleSearchChange}
              iconColor={"#5454544F"}
            />
          </div>
          <div
            className={`${style.addClassContainer} d-flex align-items-center justify-content-center`}
          >
            <span>Ara</span>
          </div>
        </div>
        <div
          className={`${style.articlesContainer} d-flex flex-column mt-4 gap-3 px-5`}
        >
          {items.length === 0 ? (
            <div>
              <span>Kayıtlı makale bulunamadı</span>
            </div>
          ) : (
            items.map((item, i) => <div className="w-100" key={nanoid()}><SingleArticle title={item.title} url={item.url}/></div>)
          )}
        </div>
      </Page>
    </PageWrapper>
  );
};

export default TeacherArticles;