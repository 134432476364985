import { useFormik } from "formik";
import React, { useContext } from "react";
import Button from "../../../../../components/bootstrap/Button";
import FormGroup from "../../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../../components/bootstrap/forms/Input";
import Modal, {
  ModalBody,
  ModalHeader,
} from "../../../../../components/bootstrap/Modal";
import { OffCanvasTitle } from "../../../../../components/bootstrap/OffCanvas";
import { AuthContext } from "../../../../../../src/store/auth-store";

const AddStudentForm = ({
  modalStatus,
  setModalStatus,
  handleSaveStudents,
  handleGetStudents,
}) => {
  // const [students, setStudents] = useState([]);
  const {user} = useContext(AuthContext);
  console.log("user",user)

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Ad giriniz!";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      id: "",
      first_name: "",
      last_name: "",
      student_email:"",
      class_field: 51,
      invited_by_user: "",
    },
    validate,
    onSubmit: async (values) => {
      handleSaveStudents({
        // id: values.id,
        first_name: values.name,
        last_name: values.lastname,
        student_email: values.email,
        class_field: 1,
        invited_by_user: user.id,
      });
      setModalStatus(false);
      formik.resetForm();
    },
  });

  const initialize = async () => {
    formik.resetForm();
    await handleGetStudents();
   };


  React.useEffect(() => {
    initialize();
    //eslint-disable-next-line
  }, [modalStatus]);
  return (
    <div>
      <Modal
        isOpen={modalStatus}
        setIsOpen={setModalStatus}
        titleId="upcomingEdit"
        isCentered
        isScrollable
        size="lg"
      >
        <ModalHeader setIsOpen={setModalStatus}>
          <OffCanvasTitle id="upcomingEdit">Yeni Öğrenci Ekle</OffCanvasTitle>
        </ModalHeader>
        <ModalBody>
          <div className="row g-4">
            <div className="col-12">
              <div>
                <div className="row g-4">
                  <div className="col-12">
                    <FormGroup id="name" label="Ad" isFloating>
                      <Input
                        placeholder="Ad"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        isValid={formik.isValid}
                        onBlur={formik.handleBlur}
                        isTouched={formik.touched.name}
                        invalidFeedback={formik.errors.name}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-12">
                    <FormGroup id="lastname" label="Soyad" isFloating>
                      <Input
                        placeholder="Soyad"
                        onChange={formik.handleChange}
                        value={formik.values.lastname}
                        isValid={formik.isValid}
                        onBlur={formik.handleBlur}
                        isTouched={formik.touched.lastname}
                        invalidFeedback={formik.errors.lastname}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-12">
                    <FormGroup id="email" label="Email" isFloating>
                      <Input
                        placeholder="Email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        isValid={formik.isValid}
                        onBlur={formik.handleBlur}
                        isTouched={formik.touched.email}
                        invalidFeedback={formik.errors.email}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-12">
                    <Button
                      onClick={() => formik.handleSubmit()}
                      color="success"
                      icon="Save"
                      isLight
                    >
                      Kaydet
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddStudentForm;
