import React, { useState } from "react";
import style from "./StudentWithClass.module.scss";
import SingleHomework from "./5-SingleHomework";
import useDarkMode from "../../../../../hooks/useDarkMode";
import Icon from "../../../../../components/icon/Icon";

const ActiveHomeworks = ({ homeworks }) => {
  const { darkModeStatus } = useDarkMode();
  const activeHomeworks = homeworks ? homeworks : [];
  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(0);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const totalItems = activeHomeworks.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const startIndex = currentPage * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);
  const displayedItems = activeHomeworks.slice(startIndex, endIndex);

  return (
    <div
      className={`${style.activeHomeworksContainer} my-3 d-flex flex-column gap-4 text-center`}
    >
      <div>
        <span
          className={`${style.title}`}
          style={{ color: darkModeStatus && "#fff" }}
        >
          Tüm Ödevler
        </span>
      </div>
      <div
        className={`${style.homeworkControl}`}
        style={{ display: activeHomeworks.length > 0 && "none" }}
      >
        <span style={{ color: darkModeStatus && "#fff" }}>
          Ödeviniz bulunmamaktadır.
        </span>
      </div>
      <div
        className={`${style.activeHomeworks} mx-auto`}
        style={{ display: !activeHomeworks.length > 0 && "none" }}
      >
        {displayedItems.map((item, i) => (
          <SingleHomework key={i} homework={item} />
        ))}
      </div>
      <div className={`${style.arrowBack}`}>
        <Icon
          icon="ArrowBackIosNew"
          size="5x"
          onClick={handlePreviousPage}
          style={{ display: currentPage === 0 && "none", cursor: "pointer" }}
        />
      </div>
      <div className={`${style.arrowNext}`}>
        <Icon
          icon="ArrowForwardIos"
          size="5x"
          onClick={handleNextPage}
          style={{
            display: (currentPage === totalPages - 1 || activeHomeworks.length === 0) && "none",
            cursor: "pointer",
          }}
        />
      </div>
    </div>
  );
};

export default ActiveHomeworks;
