import React from "react";
import TeacherHeader from "./teacher-header/TeacherHeader";
import ActiveClasses from "./active-classes/ActiveClasses";
import StudentApprovement from "./student-approvement/StudentApprovement";
import ActiveHomeworks from "./active-homeworks/ActiveHomeworks";
import ActivitiesWrapper from "./activities/ActivitiesWrapper";

const TeacherDashboard = ({ isFluid }) => {
  return (
    <>
      <div className="row">
        {/* Welcome banner */}
        <TeacherHeader />

        {/* Active classes and students */}
        <div className="col-12 col-lg-6 mt-5">
          <ActiveClasses />
        </div>
        <div className="col-12 col-lg-6 mt-5">
          <StudentApprovement />
        </div>

        {/* Homeworks and timeline */}
        <div className="col-12 col-lg-6 mt-2">
          <ActiveHomeworks />
        </div>
        <div className="col-12 col-lg-6 mt-2">
          <ActivitiesWrapper />
        </div>
      </div>
    </>
  );
};

export default TeacherDashboard;
