// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".demoDashboard_banner__savEZ {\n  height: 90px;\n  width: 100%;\n  border-radius: 20px;\n  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);\n          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);\n  padding: 1rem;\n}\n.demoDashboard_banner__savEZ .demoDashboard_welcome__FtJDK {\n  font-family: Poppins;\n  font-size: 20px;\n  font-weight: 700;\n  line-height: 30px;\n  letter-spacing: 0.07em;\n  text-align: center;\n  color: #6C5DD3;\n}\n.demoDashboard_banner__savEZ .demoDashboard_userAvatar__HWl2R {\n  width: 80px;\n  height: 80px;\n}\n.demoDashboard_banner__savEZ .demoDashboard_userAvatar__HWl2R img {\n  width: 100%;\n  height: 100%;\n}", "",{"version":3,"sources":["webpack://./src/pages/t14/student/dashboard/demoDashboard.module.scss"],"names":[],"mappings":"AAAA;EACG,YAAA;EACA,WAAA;EACA,mBAAA;EACA,uDAAA;UAAA,+CAAA;EACA,aAAA;AACH;AAAG;EACC,oBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;EACA,cAAA;AAEJ;AAAG;EACI,WAAA;EACA,YAAA;AAEP;AADO;EACI,WAAA;EACA,YAAA;AAGX","sourcesContent":[".banner{\n   height: 90px;\n   width: 100%; \n   border-radius: 20px;\n   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);\n   padding: 1rem;\n   .welcome{\n    font-family: Poppins;\n    font-size: 20px;\n    font-weight: 700;\n    line-height: 30px;\n    letter-spacing: 0.07em;\n    text-align: center;\n    color:#6C5DD3;\n   }\n   .userAvatar{\n       width: 80px;\n       height: 80px;\n       img{\n           width: 100%;\n           height: 100%;\n       }\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": "demoDashboard_banner__savEZ",
	"welcome": "demoDashboard_welcome__FtJDK",
	"userAvatar": "demoDashboard_userAvatar__HWl2R"
};
export default ___CSS_LOADER_EXPORT___;
