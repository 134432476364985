import React, { useEffect} from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../../../components/bootstrap/Modal";
import Logo from "../../../../../../components/Logo";
import Icon from "../../../../../../components/icon/Icon";
import Button from "../../../../../../components/bootstrap/Button";
import FormGroup from "../../../../../../components/bootstrap/forms/FormGroup";
import { useFormik } from "formik";
import showNotification from "../../../../../../components/extras/showNotification";
import { useDeleteActivationKeyMessageOfSchoolMutation, useGetActivationKeyMessageOfSchoolForAdminQuery, useUpdateActivationKeyMessageOfSchoolMutation } from "../../../../../../store/features/apiSlice";
import Textarea from "../../../../../../components/bootstrap/forms/Textarea";
import USER_ROLES from "../../../../../../common/data/enumUserRole";

const ShowActivationMessageModalForSchool = ({
  isOpenMessageModal,
  setIsOpenMessageModal,
}) => {
  const role = localStorage.getItem("roles");
  const isAdmin = Number(role) === USER_ROLES.ADMIN.id;
  const schoolId = localStorage.getItem("schoolId")
  const { data, isSuccess} = useGetActivationKeyMessageOfSchoolForAdminQuery(schoolId, {skip:!isAdmin});
  const [updateMessage] = useUpdateActivationKeyMessageOfSchoolMutation();
  const [deleteMessage, { isSuccess: deleteSuccess, isError: deleteError }] = useDeleteActivationKeyMessageOfSchoolMutation();

  useEffect(() => {
    if (deleteSuccess) {
      (function showSuccess(message) {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Silme Başarılı</span>
          </span>,
          message,
          "success"
        );
      })();
    }
    if (deleteError) {
      (function showError() {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="danger" size="lg" className="me-1" />
            <span>Silme işlemi Başarısız</span>
          </span>,
          "Bir hata oluştu",
          "danger"
        );
      })();
    }
    formik.resetForm()
    //eslint-disable-next-line
  }, [deleteSuccess, deleteError]);

  useEffect(() => {
    if (isSuccess) {
      formik.setFieldValue("message", data.message === "" ? null : data.message);
    }
    //eslint-disable-next-line
  }, [isSuccess]);

  const formik = useFormik({
    initialValues: {
      message: "",
    },
    onSubmit: () => {
      handleActivationMessage();
    },
  });
  const handleActivationMessage = async () => {
    const message = { ...formik.values };
    const response = await updateMessage({
      schoolId: schoolId,
      ...message,
    });
    setIsOpenMessageModal(false);
    if (response.error) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>İşlem Başarısız</span>
        </span>,
        "Bir hata oluştu",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>İşlem Başarılı</span>
        </span>,
        "Kullanıcı bilgileri başarıyla güncellendi.",
        "success"
      );
    }
    setIsOpenMessageModal(false);
  };
  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenMessageModal}
      setIsOpen={setIsOpenMessageModal}
      titleId="example-title"
      size={null}
    >
      <ModalHeader setIsOpen={setIsOpenMessageModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} />{" "}
          <span className="ps-2">Aktivasyon Mesajı</span>
          <span className="ps-2">
            <Icon icon="message" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="row gap-3 d-flex justify-content-center">
          <div className="col-lg-11">
            <FormGroup
              // className="col-4"
              id="message"
              isFloating
              label="Aktivasyon Mesajı"
            >
             <Textarea
                onChange={formik.handleChange}
                value={formik.values.message}
                onBlur={formik.handleBlur}
                isValid={formik.isValid}
                isTouched={formik.touched.message}
                invalidFeedback={formik.errors.message}
                style={{ height: "150px"}}
              />
            </FormGroup>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="delete"
          color="danger"
          isLight
          onClick={async () => {
            deleteMessage(schoolId)
          }}
        >
          Sil
        </Button>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={async () => {
            formik.handleSubmit();
          }}
        >
          {data?.message ? "Güncelle" : "Kaydet"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ShowActivationMessageModalForSchool;
