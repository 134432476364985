import React, { useEffect, useState } from "react";
import PageWrapper from "../../../../../../layout/PageWrapper/PageWrapper";
import SubHeader, {
  SubHeaderLeft,
  SubHeaderRight,
} from "../../../../../../layout/SubHeader/SubHeader";
import Button from "../../../../../../components/bootstrap/Button";
import Page from "../../../../../../layout/Page/Page";
import Card, {
  CardBody,
  CardFooter,
  CardFooterRight,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../../../../components/bootstrap/Card";
import showNotification from "../../../../../../components/extras/showNotification";
import Icon from "../../../../../../components/icon/Icon";
import {
  useClearCacheMutation,
  useSingleCourseQuery,
  useUpdateCourseMutation,
} from "../../../../../../store/features/apiSlice";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import Confirm from "../../../../../common/Confirm";
import { useLocation } from "react-use";
import _ from "underscore";
import { useGetSchoolsQuery } from "../../../../teacher/teacherApi";
import CreateChapterModal from "../2-CreateChapterModal";
import {
  useGetSingleSchoolCourseQuery,
  useUpdateSchoolCourseMutation,
} from "../../../schoolManagerApi";
import { CoursePrograms } from "../../../../admin/course management/constant/CourseProgramList";
import USER_ROLES from "../../../../../../common/data/enumUserRole";
import CustomFormField from "../../../../../../common/customFormField/CustomFormField";

const rowStyle = {
  cursor: "pointer",
};

const UpdateSchoolCourse = () => {
  const role = localStorage.getItem("roles");
  const isAdmin = Number(role) === USER_ROLES.ADMIN.id;
  const { id } = useParams();
  const {
    data: schoolManagerSingleCourseData,
    isSuccess: schoolManagerIsSuccess,
  } = useGetSingleSchoolCourseQuery(id, { skip: id === null });
  const { data: adminSingleCourseData, isSuccess: adminIdSuccess } =
    useSingleCourseQuery(id, { skip: id === null });
  const data = isAdmin ? adminSingleCourseData : schoolManagerSingleCourseData;
  const isSuccess = isAdmin ? adminIdSuccess : schoolManagerIsSuccess;
  const [clearCache] = useClearCacheMutation();
  const [updateCourse] = useUpdateSchoolCourseMutation();
  const [adminUpdateCourse] = useUpdateCourseMutation();
  const [open, setOpen] = useState(false);
  const [courseId, setCourseId] = useState(null);
  const [isOpenNewCourseModal, setIsOpenNewCourseModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { data: schools } = useGetSchoolsQuery();
  const schoolsList =
    schools &&
    schools.map((item) => {
      return {
        text: item.title,
        value: item.id,
      };
    });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const activeTabParam = searchParams.get("activeTab");
    if (activeTabParam) {
      setActiveTab(activeTabParam);
    }
  }, [location.search]);

  const TABS = {
    COURSE_DETAIL: "Course Details",
    CHAPTER: "Chapter",
  };
  const [activeTab, setActiveTab] = useState(TABS.CHAPTER);

  useEffect(() => {
    if (isSuccess && data) {
      formik.setFieldValue("title", data.title);
      formik.setFieldValue("description", data.description);
      formik.setFieldValue("technology", data.technology);
      formik.setFieldValue("status", data.status);
      formik.setFieldValue("order_number", data.order_number);
      formik.setFieldValue("chapters", data.chapters);
      formik.setFieldValue("school", data.school);
      formik.setFieldValue("school_status", data.school_status);
    }
    //eslint-disable-next-line
  }, [isSuccess, data]);

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Dersin adını giriniz";
    }

    if (!values.technology) {
      errors.technology = "Program dilini seçiniz";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      title: "",
      description: null,
      technology: null,
      order_number: "",
      status: 1,
      level: null,
      points: null,
      labels: null,
      chapters: [],
      school: null,
      school_status: 2,
    },
    validate,
    onSubmit: () => {
      handleCourse();
    },
  });

  const handleCourse = async () => {
    const courseValues = { ...formik.values };
    const response = isAdmin
      ? await adminUpdateCourse({
          id,
          ...courseValues,
        })
      : await updateCourse({
          id,
          ...courseValues,
        });
    if (response.error) {
      console.log(response.error);
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Güncelleme Başarısız</span>
        </span>,
        "Bir hata oluştu",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Güncelleme Başarılı</span>
        </span>,
        "Ders bilgileri başarıyla güncellendi.",
        "success"
      );
    }
    clearCache();
  };

  //! delete chapter--------
  const removeChapter = (e) => {
    setOpen(true);
    setCourseId(e.currentTarget.id && e.currentTarget.id);
  };
  const handleDelete = async () => {
    let newChapters = data.chapters.filter(
      (item) => Number(item.id) !== Number(courseId)
    );
    newChapters = _.sortBy(newChapters, "order_number");
    const response = isAdmin
      ? await adminUpdateCourse({
          id,
          title: data.title,
          ...data,
          chapters: newChapters,
        })
      : await updateCourse({
          id,
          title: data.title,
          ...data,
          chapters: newChapters,
        });
    setOpen(false);
    if (response.error) {
      console.log(response.error);
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Silme İşlemi Başarısız</span>
        </span>,
        "Bir hata meydana geldi",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Silme Başarılı</span>
        </span>,
        "Ünite başarıyla silindi.",
        "success"
      );
    }
    clearCache();
  };
  const handleCancel = () => setOpen(false);

  //! create chapter---------
  const addChapter = () => {
    setCourseId(id);
    setIsOpenNewCourseModal(true);
  };

  //! update chapter-------
  const updateChapter = (e) => {
    console.log(
      "%cUpdateSchoolCourse.js line:180 e",
      "color: white; background-color: #007acc;",
      e
    );
    let currentChapter =
      data &&
      data.chapters.find(
        (chapter) => Number(chapter.id) === Number(e.currentTarget.id)
      );
    navigate(isAdmin ? `/admin/updateSchoolCourseChapter/${e.currentTarget.id}` : `/schoolManager/updateChapter/${e.currentTarget.id}`, {
      state: { id: id, chapterTitle: currentChapter.title },
    });
  };
  return (
    <PageWrapper title={"Ders Bilgileri"}>
      <CreateChapterModal
        isOpenNewCourseModal={isOpenNewCourseModal}
        setIsOpenNewCourseModal={setIsOpenNewCourseModal}
        courseId={courseId}
        data={data}
      />
      <Confirm
        open={open}
        setOpen={setOpen}
        onConfirm={handleDelete}
        onCancel={handleCancel}
      />
      <SubHeader>
        <SubHeaderLeft>
          <Button
            color="info"
            isLink
            icon="ArrowBack"
            tag="a"
            to={`${isAdmin ? "/admin/schoolContentManagement/" :"/schoolManager/contentManagement/"}`}
            // className={style.SubHeader}
          >
            Ders Yönetimi Sayfasına Geri Dön
          </Button>
        </SubHeaderLeft>
        <SubHeaderRight></SubHeaderRight>
      </SubHeader>
      <Page>
        <div className="row h-100">
          <div className="col-xl-3 col-lg-4 col-md-4">
            <Card stretch>
              <CardHeader>
                <CardLabel icon="Edit" iconColor="info">
                  <CardTitle>Ders Düzenle</CardTitle>
                </CardLabel>
              </CardHeader>
              <CardBody isScrollable>
                <div className="row g-3">
                  <div className="col-12">
                    <Button
                      icon="Info"
                      color="info"
                      className="w-100 p-3"
                      isLight={TABS.COURSE_DETAIL !== activeTab}
                      onClick={() => setActiveTab(TABS.COURSE_DETAIL)}
                    >
                      Ders Bilgileri
                    </Button>
                  </div>
                  <div className="col-12">
                    <Button
                      icon="Computer"
                      color="info"
                      className="w-100 p-3"
                      isLight={TABS.CHAPTER !== activeTab}
                      onClick={() => setActiveTab(TABS.CHAPTER)}
                    >
                      Üniteler
                    </Button>
                  </div>
                  <div className="col-12 border-bottom" />
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-xl-9 col-lg-8 col-md-8">
            {TABS.COURSE_DETAIL === activeTab && (
              <Card
                stretch
                tag="form"
                noValidate
                onSubmit={formik.handleSubmit}
              >
                <CardHeader>
                  <CardLabel icon="Details" iconColor="info">
                    <CardTitle>Ders Bilgileri</CardTitle>
                  </CardLabel>
                </CardHeader>
                <CardBody className="pb-0" isScrollable>
                  <form
                    onSubmit={formik.handleSubmit}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        formik.handleSubmit();
                      }
                    }}
                  >
                    <div className="row g-4">
                      <div className="col-lg-8">
                      <CustomFormField
                          type="text"
                          label="Başlık"
                          name={"title"}
                          formik={formik}
                        />
                      </div>
                      <div className="col-lg-8">
                      <CustomFormField
                          type="text"
                          label="Açıklama/Not"
                          name={"description"}
                          formik={formik}
                        />
                      </div>
                      <div className="col-lg-8">
                      <CustomFormField
                          type="select"
                          label="Logo"
                          name={"technology"}
                          options={CoursePrograms}
                          formik={formik}
                        />
                      </div>
                      <div className="col-lg-8">
                      <CustomFormField
                          type="text"
                          label="Sıra Numarası"
                          name={"order_number"}
                          formik={formik}
                        />
                      </div>
                      <div className="col-lg-8">
                      <CustomFormField
                          type="select"
                          label="Aktiflik"
                          name={"status"}
                          options={[
                            { value: "0", text: "Pasif" },
                            { value: "1", text: "Aktif" },
                            { value: "2", text: "Taslak" },
                          ]}
                          formik={formik}
                        />
                      </div>
                      <div className="col-lg-8">
                      <CustomFormField
                              type="select"
                              label="Okul"
                              name={"school"}
                              options={schoolsList}
                              formik={formik}
                            />
                      </div>
                      <div className="col-lg-8">
                      <CustomFormField
                              type="select"
                              label="Ders Kaynağı"
                              name={"school_status"}
                              options={[
                                { value: "1", text: "Default" },
                                { value: "2", text: "Okul" },
                                { value: "3", text: "Partner" },
                              ]}
                              formik={formik}
                              disabled={true}
                            />
                      </div>
                    </div>
                  </form>
                </CardBody>
                <CardFooter>
                  <CardFooterRight>
                    <Button
                      icon="DoneOutline"
                      color="success"
                      isLight
                      onClick={async () => {
                        formik.handleSubmit();
                      }}
                    >
                      Güncelle
                    </Button>
                    <Button
                      icon="Cancel"
                      color="danger"
                      isLight
                      tag="a"
                      to={`/admin/courseManagement/`}
                    >
                      İptal
                    </Button>
                  </CardFooterRight>
                </CardFooter>
              </Card>
            )}
            {TABS.CHAPTER === activeTab && (
              <Card stretch tag="form" noValidate>
                <CardHeader>
                  <CardLabel icon="Computer" iconColor="success">
                    <CardTitle>Ünite Bilgileri</CardTitle>
                  </CardLabel>
                  <Button
                    color="info"
                    className={`ms-5 mb-4 shadow-3d-up-hover}`}
                    onClick={() => addChapter()}
                  >
                    Ünite Ekle
                  </Button>
                </CardHeader>
                <CardBody>
                  <table className="table table-modern">
                    <thead style={{ fontSize: "16px" }}>
                      <tr>
                        <th>Başlık</th>
                        <th style={{ textAlign: "center" }}>Sırası</th>
                        <th className="text-center">Sil</th>
                        <th style={{ textAlign: "center" }}>Ünite Detayı</th>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: "16px" }}>
                      {data &&
                        data.chapters.map((course) => {
                          const { id, title, order_number } = course;
                          return (
                            <tr key={id}>
                              <td
                                id={id}
                                style={rowStyle}
                                onClick={(e) => updateChapter(e)}
                              >
                                {title}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {order_number}
                              </td>
                              <td className="text-center">
                                <IconButton
                                  id={id}
                                  onClick={(e) => removeChapter(e)}
                                >
                                  <Delete className="fs-2" />
                                </IconButton>
                              </td>
                              <td className="text-center">
                                <IconButton
                                  id={id}
                                  onClick={(e) => updateChapter(e)}
                                >
                                  <Edit className="fs-2" />
                                </IconButton>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            )}
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default UpdateSchoolCourse;
