import React from 'react'
import style from "./TalentResponsiveImage.module.scss"

const TalentResponsiveImage = ({src, alt=""}) => {
  return (
    <div className={`${style.imageContainer}`}>
    <img className={`${style.responsiveImage}`} src={src} alt={alt} />
  </div>
  )
}

export default TalentResponsiveImage