import React from "react";
import TeacherHomeworkList from "./TeacherHomeworkList";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import DashboardHeaderWithTitle from "../../../../common/Headers/DashboardHeaderWithTitle";
import Page from "../../../../../layout/Page/Page";
import style from "./teacherHomeworks.module.scss"

const TeacherHomeworkWrapper = () => {
  return (
    <PageWrapper>
      <DashboardHeaderWithTitle title="Ödevlerim" />
      <Page className={`${style.pageContainer}`}>
        <div className="row h-100">
          <div className="col-12">
            <TeacherHomeworkList isFluid />
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default TeacherHomeworkWrapper;
