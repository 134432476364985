import React from "react";
import style from "./lessonsContent.module.scss";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import { useSelector, useDispatch } from "react-redux";
import Chapter from "./2-Chapter";
import LectureVideo from "./4-LectureVideo";
import { useParams } from "react-router-dom";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSingleCourseQuery } from "../../../../store/features/apiSlice";
import Page from "../../../../layout/Page/Page";
import Card, {
  CardBody,
  CardLabel,
  CardTitle,
} from "../../../../components/bootstrap/Card";
import {
  lectureVideoContent,
  setCurrentChapter,
} from "../../../../store/pathWithVideoSlice";
import DashboardHeader from "../../../common/Headers/DashboardHeader";

const LessonContent = () => {
  const { currentChapter } = useSelector((store) => store.pathWithVideo);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data, isSuccess } = useSingleCourseQuery(id, { skip: id === null });

  const [openChapterIndex, setOpenChapterIndex] = useState(0);
  useEffect(() => {
    let foundLesson = currentChapter.find((s) => s.lessonId === id);

    if (foundLesson) {
      setOpenChapterIndex(foundLesson.index);
      dispatch(
        lectureVideoContent({
          url: foundLesson.video.video,
          description: foundLesson.video.description,
          videoId: foundLesson.video.id,
          content: foundLesson.video.content_id,
        })
      );
    } else {
      setOpenChapterIndex(0);
      let defaultVideo;
      let defaultDescription;
      let defaultVideoId;
      let defaultContent;
      if (isSuccess) {
        defaultVideo = data?.chapters[0].lessons[0].video;
        defaultDescription = data?.chapters[0].lessons[0].description;
        defaultVideoId = data?.chapters[0].lessons[0].id;
        defaultContent = data?.chapters[0].lessons[0].content_id;
        dispatch(
          lectureVideoContent({
            url: defaultVideo,
            description: defaultDescription,
            videoId: defaultVideoId,
            content: defaultContent,
          })
        );
        dispatch(
          setCurrentChapter({
            lessonId: id,
            index: 0,
            video: data?.chapters[0].lessons[0],
          })
        );
      }
    }
    //eslint-disable-next-line
  }, [currentChapter, isSuccess]);

  const [divHeight, setDivHeight] = useState(0);
  const heightRef = useRef(null);
  useEffect(() => {
    setDivHeight(heightRef.current.clientHeight);
  }, []);

  return (
    <PageWrapper>
      <DashboardHeader />
      <Page container="fluid" className={`${style.pageContainerCourseContent}`}>
        <div className={`${style.allPaths} row h-100`}>
          <div className="d-flex flex-column-reverse flex-xl-row">
            <div
              className={`${style.leftMenu} col-xl-3 col-12`}
              style={{ height: `${divHeight}px` }}
            >
              <Card stretch className={`${style.leftSectionCard} h-100`}>
                <CardBody isScrollable>
                  <CardLabel icon="Class" iconColor="info" className="mb-2">
                    <CardTitle>{isSuccess && data.title}</CardTitle>
                  </CardLabel>
                  {isSuccess &&
                    data.chapters?.map((chapter, i) => (
                      <Chapter
                        chapter={chapter}
                        key={chapter.id}
                        index={i}
                        // isOpen={i === openChapterIndex}
                        openChapterIndex={openChapterIndex}
                        lessonId={id}
                      />
                    ))}
                </CardBody>
              </Card>
            </div>
            <div
              className={`${style.videoScreen} col-xl-9 col-12 px-3`}
              ref={heightRef}
            >
              <Card className={`${style.videoScreenCard} h-100`}>
                <CardBody className="pb-0">
                  <LectureVideo />
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default LessonContent;
