import React from "react";
import style from "./lessonsContent.module.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  lectureVideoContent,
} from "../../../../store/pathWithVideoSlice";
import Icon from "../../../../components/icon/Icon";
import {
  useAllUserLessonActivitiesQuery,
} from "../../../../store/features/apiSlice";
import USER_SUBSCRIPTION_TYPE from "../../../../common/data/enumUserSubscriptionType";
import useDarkMode from "../../../../hooks/useDarkMode";

const Lecture = ({ video, index, lessonId, setVideo }) => {
  const { darkModeStatus } = useDarkMode();
  const userSubscriptionType = localStorage.getItem("subscription_type");
  // const userSubscriptionType = 1
  const dispatch = useDispatch();
  const { currentChapter } = useSelector((store) => store.pathWithVideo);
  const {
    title,
    video: url,
    description,
    subscription_type: contentSubscriptionType,
    content_id:content,
  } = video;

  const { data: dataActivities} =
    useAllUserLessonActivitiesQuery();

  const completedLessons =
    dataActivities && dataActivities.map((item) => item.lesson);

  const isCompleted = completedLessons && completedLessons.includes(video.id);

  const lessonAndVideoIndex = currentChapter.findIndex(
    (s) => s.lessonId === lessonId
  );

  let activeVideo = { id: 0 };
  if (lessonAndVideoIndex === -1) {
    activeVideo = 0;
  } else {
    activeVideo = currentChapter[lessonAndVideoIndex].video;
  }

  const changeVideo = () => {
    dispatch(lectureVideoContent({ url, description, content }));
    setVideo(video);
  };
  return (
    <div>
      <>
        {Number(contentSubscriptionType) === USER_SUBSCRIPTION_TYPE.FREE.id ? (
          <div
            className={`${style.lessonVideo} d-flex justify-content-start align-items-center gap-1 ps-3`}
            onClick={() => {
              changeVideo();
            }}
          >
            <span
              className="d-flex align-items-center gap-3"
              style={{
                color: `${video.id === activeVideo.id ? "#4d69fa" : darkModeStatus ? "#fff" : "black"}`,
              }}
            >
              <span className="d-flex align-items-center gap-1">
                {video.id === activeVideo.id && isCompleted ? (
                  <Icon icon="Check" color={"info"} size={"lg"} />
                ) : video.id !== activeVideo.id && isCompleted ? (
                  <Icon icon="Check" color={"warning"} size={"lg"} />
                ) : (
                  <Icon icon="Circle" color={"warning"} />
                )}
              </span>
              {title}
            </span>
            {/* <span style={{fontSize:"14px", fontWeight:"100"}} className="ps-4">{video.duration}</span> */}
          </div>
        ) : Number(contentSubscriptionType) !== USER_SUBSCRIPTION_TYPE.FREE.id &&
          Number(userSubscriptionType) !== USER_SUBSCRIPTION_TYPE.FREE.id ? (
          <div
            className={`${style.lessonVideo} d-flex justify-content-start align-items-center gap-1 ps-3`}
            onClick={() => {
              changeVideo();
            }}
          >
            <span
              className="d-flex align-items-center gap-3"
              style={{
                color: `${video.id === activeVideo.id ? "#4d69fa" : darkModeStatus ? "#fff" : "black"}`,
              }}
            >
              <span className="d-flex align-items-center gap-1">
                {video.id === activeVideo.id && isCompleted ? (
                  <Icon icon="Check" color={"info"} size={"lg"} />
                ) : video.id !== activeVideo.id && isCompleted ? (
                  <Icon icon="Check" color={"warning"} size={"lg"} />
                ) : (
                  <Icon icon="Circle" color={"warning"} />
                )}
              </span>
              {title}
            </span>
            {/* <span style={{fontSize:"14px", fontWeight:"100"}} className="ps-4">{video.duration}</span> */}
          </div>
        ) : (
          <div
            className={`${style.lessonVideo} d-flex justify-content-start align-items-center gap-1 ps-3`}
            // onClick={() => {
            //   changeVideo();
            // }}
          >
            <span
              className="d-flex align-items-center gap-3"
              style={{
                color: `${video.id === activeVideo.id ? "#4d69fa" : "black"}`,opacity: "0.2", pointerEvents:"none"
              }}
            >
              <span className="d-flex align-items-center gap-1">
                {video.id === activeVideo.id && isCompleted ? (
                  <Icon icon="Check" color={"info"} size={"lg"} />
                ) : video.id !== activeVideo.id && isCompleted ? (
                  <Icon icon="Check" color={"warning"} size={"lg"} />
                ) : (
                  <Icon icon="Circle" color={"warning"} />
                )}
              </span>
              {title}
            </span>
            {/* <span style={{fontSize:"14px", fontWeight:"100"}} className="ps-4">{video.duration}</span> */}
          </div>
        )}
      </>
    </div>
  );
};

export default Lecture;
