import React from 'react'
import style from "./ContentManagement.module.scss"
import { useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import DashboardHeader from '../../../common/Headers/DashboardHeader';
import Page from '../../../../layout/Page/Page';
import announce1 from "../../../../../src/assets/lessons/announce1.svg";
import announce2 from "../../../../../src/assets/lessons/announce2.svg";
import pathsLogo1 from "../../../../../src/assets/lessons/pathsLogo1.svg";
import pathsLogo2 from "../../../../../src/assets/lessons/pathsLogo2.svg";
import TeacherTrail from './trail/TeacherTrail';
import Card from '../../../../components/bootstrap/Card';
import LiveLesson from './liveLesson/LiveLesson';

const ContentManagement = () => {
    const _activeTab = sessionStorage.getItem("contentActiveTab");
    const [activeTab, setActiveTab] = useState(Number(_activeTab) || 1);
  
    const tabContents = [
      // {
      //   content: <LessonManagement />,
      //   tabName: "Ders Yönetimi",
      //   icon1: lessonsLogo1,
      //   icon2: lessonsLogo2,
      // },
      {
        content: <TeacherTrail />,
        tabName: "Patika Yönetimi",
        icon1: pathsLogo1,
        icon2: pathsLogo2,
      },
      {
        content: <LiveLesson />,
        tabName: "Canlı Ders",
        icon1: announce1,
        icon2: announce2,
      },
    ];

    const handleTabClick = (tabNumber) => {
      if (tabNumber >= 1 && tabNumber <= tabContents.length) {
        sessionStorage.setItem("contentActiveTab", tabNumber);
        setActiveTab(tabNumber);}
      };
  return (
    <PageWrapper title={"İçerik Yönetimi"}>
    <DashboardHeader />
    <Page container={false} style={{ padding: "0" }}>
        <Card className="h-100 mt-3 mx-lg-3">
      <div
        className={`${style.tabContainer} d-flex justify-content-evenly align-items-center mx-auto mt-2 mb-5 px-3 gap-1`}
      >
        {tabContents.map((tab, index) => (
          <div
            key={index}
            className={`${style.tab} ${
              activeTab === index + 1 ? `${style.activetab}` : ""
            } d-flex justify-content-center align-items-center gap-2 p-3`}
            onClick={() => handleTabClick(index + 1)}
          >
            <div className={`${style.tabsLogo}`}>
              <img
                src={activeTab === index + 1 ? tab.icon2 : tab.icon1}
                alt=""
              />
            </div>
            <div className={`${style.tabsDescription}`}>
              <span>{tab.tabName}</span>
            </div>
          </div>
        ))}
      </div>
       <div className="tab-content">
            {tabContents[activeTab - 1] && tabContents[activeTab - 1]?.content}
          </div>
        </Card>
    </Page>
  </PageWrapper>
  )
}

export default ContentManagement