import React from "react";
import style from "../ParentReportWithClass.module.scss";
import { Card } from "react-bootstrap";
import CertificateInfo from "./CertificateInfo";

const Certificates = ({ data }) => {
  let currentCertificates = data.certificates;
  // Aynı ID'ye sahip objelerden yalnızca birini göster
let uniqCertificates = currentCertificates?.reduce((acc, obj) => {
  const existingObj = acc.find(item => item.course_id === obj.course_id);
  if (!existingObj) {
    acc.push(obj);
  }
  return acc;
}, []);
// uniqCertificates = uniqCertificates.concat(uniqCertificates).concat(uniqCertificates)
  const courseData = data.course_completion_rate;
  // const currentCertificates = [];
  return (
    <Card className={`${style.certificatesContainer} p-3 px-5`}>
      <div className={`${style.title} my-3 px-3`}>Sertifikalar</div>
      <div className={`${style.certificates} d-flex gap-4 py-1`}>
        {(!uniqCertificates || uniqCertificates.length < 1) ? (
          <div className={`${style.emptyCertificate}`}>
            <p>Sertifika bulunmamaktadır</p>
          </div>
        ) : (
          uniqCertificates.map((item) => (
            <CertificateInfo
              certificateData={item}
              courseData={courseData}
              key={item.id}
            />
          ))
        )}
      </div>
    </Card>
  );
};

export default Certificates;
