import React, {useState } from "react";
import style from "./CompetitionList.module.scss";
import CompetitionCard from "../competition card/CompetitionCard";
import plus from "../../../../../assets/dashboardImages/plus.svg";
import { useNavigate } from "react-router-dom";
import { useGetAllCompetitionsQuery } from "../talentCupApi";
import useSortableData from "../../../../../hooks/useSortableData";
import PaginationButtons, { dataPagination, PER_COUNT } from "../../../../../components/PaginationButtons";
import { nanoid } from "nanoid";
import _ from "underscore";

const CompetitionList = () => {
  const navigate = useNavigate();

  const { data } = useGetAllCompetitionsQuery();
  let sortedData  = _.sortBy(data, item => item.application_deadline).reverse();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_COUNT["8"]);
  const { items } = useSortableData(sortedData ? sortedData : []);

  const addCompetition = () => {
    navigate("/admin/talentCup/competition")
  };

  return (
    <>
      <div className="d-flex justify-content-end mx-5">
        <div
          className={`${style.addCompetitionContainer} d-flex align-items-center gap-2 ps-2`}
          onClick={() => addCompetition()}
        >
          <span>
            <img src={plus} alt="" />
          </span>
          <span>Yarışma Ekle</span>
        </div>
      </div>
      <div className={`${style.allCompetitions}`}>
        {items.length === 0 ? (
            <div>
              <span>Kayıtlı yarışma bulunamadı</span>
            </div>
          ) : (
            dataPagination(items, currentPage, perPage).map((item, i) => (
              <CompetitionCard competition={item} key={nanoid()}/>
            ))
          )}
      </div>
         <PaginationButtons
        data={items}
        label="Yarışma"
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </>
  );
};

export default CompetitionList;