import React, { useState } from "react";
import style from "./BadgeContainer.module.scss";
import BadgesFloor from "../../../../../../assets/badges/BadgesFloor.png";
import kesifci from "../../../../../../assets/badges/kesifci-removebg-preview.png";
import algoritmikDahi from "../../../../../../assets/badges/algoritmikDahi-removebg-preview.png";
import tamamlayici from "../../../../../../assets/badges/tamamlayici-removebg-preview.png";
import kodlamasampiyonu from "../../../../../../assets/badges/kodlamasampiyonu-removebg-preview.png";
import codebooharikasi from "../../../../../../assets/badges/codebooharikasi.png";
import mukemmelperformans from "../../../../../../assets/badges/mukemmelperformans-removebg-preview.png";
import bilgikasifi from "../../../../../../assets/badges/bilgikasifi-removebg-preview.png";
import renklidunya from "../../../../../../assets/badges/renklidunya-removebg-preview.png";
import multiyetenek from "../../../../../../assets/badges/multiyetenek-removebg-preview.png";
import cizimvirtuozu from "../../../../../../assets/badges/cizimvirtuozu-removebg-preview.png";
import codeminokahramani from "../../../../../../assets/badges/codeminokahramani.png";
import beceriefendisi from "../../../../../../assets/badges/beceriefendisi-removebg-preview.png";
import BadgeCardModal from "./badgeCard/BadgeCardModal";

const BadgeContainer = ({ floor = "first", badges }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState(null);
  const badgeFloor = {
    first: {
      width: "220px",
      top: "-37px",
      left: "30px",
      badge1: {
        badge_id: 1,
        img: kesifci,
        name: "Keşifçi",
        desc: "Platforma giriş yaptıktan sonra ilk aşamanı tamamladın!",
        is_earned: badges[0].is_earned,
      },
      badge2: {
        badge_id: 2,
        img: tamamlayici,
        name: "Tamamlayıcı",
        desc: "Videolu konu anlatımı kategorisinde toplam 10 etkinlik tamamladın.",
        is_earned: badges[1].is_earned,
      },
      badge3: {
        badge_id: 3,
        img: algoritmikDahi,
        name: "Algoritmik Dahi",
        desc: "Codelab kategorisi içerisinde 5 alıştırma tamamladın!",
        is_earned: badges[2].is_earned,
      },
    },
    second: {
      width: "210px",
      top: "-50px",
      left: "30px",
      badge1: {
        badge_id: 4,
        img: kodlamasampiyonu,
        name: "Kodlama Şampiyonu",
        desc: "Codelab kategorisinde 10 aşama tamamladın.",
        is_earned: badges[3].is_earned,
      },
      badge2: {
        badge_id: 5,
        img: codeminokahramani,
        name: "Codemino Kahramanı",
        desc: "CodeMino kategorisinde 10 aşama tamamladınız.",
        is_earned: badges[4].is_earned,
      },
      badge3: {
        badge_id: 6,
        img: mukemmelperformans,
        name: "Mükemmel Performans",
        desc: "Uygulama platformu içerisinde toplam 50 etkinlik tamamladın.",
        is_earned: badges[5].is_earned,
      },
    },
    third: {
      width: "205px",
      top: "-50px",
      left: "35px",
      badge1: {
        badge_id: 7,
        img: cizimvirtuozu,
        name: "Çizim Virtüözü",
        desc: "CodeÇizz alıştırma kategorisinde 3 etkinlik tamamladın.",
        is_earned: badges[6].is_earned,
      },
      badge2: {
        badge_id: 8,
        img: codebooharikasi,
        name: "Codeboo Harikası",
        desc: "CodeBoo alıştırma kategorisinde 5 etkinlik tamamladın.",
        is_earned: badges[7].is_earned,
      },
      badge3: {
        badge_id: 9,
        img: beceriefendisi,
        name: "Beceri Efendisi",
        desc: "Platform içerisindeki genel liderlik sıralamasında ilk 10’da yer aldın.",
        is_earned: badges[8].is_earned,
      },
    },
    fourth: {
      width: "210px",
      top: "-50px",
      left: "35px",
      badge1: {
        badge_id: 10,
        img: bilgikasifi,
        name: "Bilgi Kaşifi",
        desc: "Her kategoride en az 1 etkinlik tamamladın.",
        is_earned: badges[9].is_earned,
      },
      badge2: {
        badge_id: 11,
        img: renklidunya,
        name: "Renkli Dünya",
        desc: "Tüm kategorilerde 3 etkinlik tamamladın.",
        is_earned: badges[10].is_earned,
      },
      badge3: {
        badge_id: 12,
        img: multiyetenek,
        name: "Multi Yetenek",
        desc: "Tüm kategorilerde 15 etkinlik tamamladın.",
        is_earned: badges[11].is_earned,
      },
    },
  };

  function openBadgeCard(name, avatar, desc, is_earned) {
    setSelectedBadge({ name, avatar, desc, is_earned});
    setIsOpen(true);
  }

  const { badge1, badge2, badge3, top, left, width } = badgeFloor[floor];
  return (
    <div className={`${style.BadgeContainer}`}>
      <BadgeCardModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        badge={selectedBadge}
      />
      <div
        className={`${style.BadgeImagesContainer} d-flex justify-content-center gap-3`}
        style={{ width: `${width}`, top: `${top}`, left: `${left}` }}
      >
        <div
          className={`${style.badge}`}
          style={{ opacity:`${!(badge1.is_earned) ? "0.3" : "1"}` }}
          onClick={() => openBadgeCard(badge1.name, badge1.img, badge1.desc, badge1.is_earned)}
        >
          <div className={`${style.BadgeImg}`}>
            <img src={badge1.img} alt="" />
          </div>
          <p className="px-0">{badge1.name}</p>
        </div>
        <div
          className={`${style.badge}`}
          style={{ opacity:`${!(badge2.is_earned) ? "0.3" : "1"}` }}
          onClick={() => openBadgeCard(badge2.name, badge2.img, badge2.desc, badge2.is_earned)}
        >
          <div className={`${style.BadgeImg}`}>
            <img src={badge2.img} alt="" />
          </div>
          <p className="px-0">{badge2.name}</p>
        </div>
        <div
          className={`${style.badge}`}
          style={{ opacity:`${!(badge3.is_earned) ? "0.3" : "1"}` }}
          onClick={() => openBadgeCard(badge3.name, badge3.img, badge3.desc, badge3.is_earned)}
        >
          <div className={`${style.BadgeImg}`}>
            <img src={badge3.img} alt="" />
          </div>
          <p className="px-0">{badge3.name}</p>
        </div>
      </div>
      <div className={`${style.BadgeFloor}`}>
        <img src={BadgesFloor} alt="" />
      </div>
    </div>
  );
};

export default BadgeContainer;
