import React, { useEffect, useState } from "react";
import style from "./TeacherStudent.module.scss";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Card, {
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../../components/bootstrap/Card";
import PaginationButtons, {
  PER_COUNT,
  dataPagination,
} from "../../../../components/PaginationButtons";
import { useNavigate } from "react-router-dom";
import {
  useGetStudentsQuery,
  useRemoveStudentFromClassMutation,
} from "../teacherApi";
import useSortableData from "../../../../hooks/useSortableData";
import { formatDate } from "../../../../utils/formatDate";
import Confirm from "../../../common/Confirm";
import plus from "../../../../assets/dashboardImages/plus.svg";
import AddStudentForm from "./AddStudentForm";
import Search from "../class/search";
import ConfirmButton from "../components/confirm-button";
import useSelectTable from "../../../../hooks/useSelectTable";
import Checks from "../../../../components/bootstrap/forms/Checks";
import DashboardHeader from "../../../common/Headers/DashboardHeader";

const TeacherStudents = () => {
  const navigate = useNavigate();

  // variables
  let userWarning;
  const schoolId = localStorage.getItem("school")
  const isSchool = schoolId > 0;

  //query
  const { data: students} = useGetStudentsQuery();

  //states
  const [modalStatus, setModalStatus] = useState(false);
  const [classId, setClassId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_COUNT["10"]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  //pagination
  const { items } = useSortableData(
    filteredStudents?.length > 0 ? filteredStudents : students ? students : []
  );
  const onCurrentPageData = dataPagination(items, currentPage, perPage);
  const { selectTable } = useSelectTable(onCurrentPageData);

  //mutation
  const [removeStudentFromClass] = useRemoveStudentFromClassMutation();

  const handleDelete = () => {
    if (selected.length > 0) {
      selected.forEach(async (item) => {
         removeStudentFromClass({
      class_id: JSON.parse(item).class_field,
      student_in_class_id: JSON.parse(item).id,
    })
      });
    }
    setOpen(false);
    setSelected([]);
    setSelectAll(false)
  };
  const handlCancel = () => setOpen(false);

  useEffect(() => {
    if (selectTable.values.selectedList) {
      setSelected(selectTable.values.selectedList);
    }
  }, [selectTable.values.selectedList]);

  //methods
  function handleRemoveStudentFromClass(sutudentInClassId) {
    console.log({ class_id: classId, student_in_class_id: sutudentInClassId });

    removeStudentFromClass({
      class_id: classId,
      student_in_class_id: sutudentInClassId,
    });
  }

  const openClassForm = () => {
    setModalStatus(true);
  };

  const handleSearchChange = (newValue) => {
    setSearchValue(newValue);
  };

  const filterStudents = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const newStudents =
      students &&
      students.filter(
        (item) =>
          (item.first_name &&
            item.first_name.toLowerCase().includes(searchValue)) ||
          (item.last_name &&
            item.last_name.toLowerCase().includes(searchValue)) ||
          (item.class_name &&
            item.class_name.toLowerCase().includes(searchValue))
      );
    setFilteredStudents(newStudents);
  };

  //effects
  useEffect(() => {
    filterStudents();
    //eslint-disable-next-line
  }, [searchValue]);

const handleSelectAll = () => {
  setSelectAll(!selectAll);

  if (selectAll) {
    // Seçiliyse, listeyi temizleyin
    selectTable.values.selectedList = [];
  } else {
    // Seçili değilse, sourceArray'deki tüm öğeleri ekleyin
    items.forEach(item => {
      const jsonItem = JSON.stringify({ id: item.id, class_field: item.class_field });
      selectTable.values.selectedList.push(jsonItem);
    });
  }
};
  return (
    <PageWrapper>
      <DashboardHeader/>
      <Page>
        <>
          <Card className={`${style.cardContainer} h-100`}>
          <Confirm
          open={open}
          setOpen={setOpen}
          onConfirm={handleDelete}
          onCancel={handlCancel}
        />
        {selected.length > 0 ? (
          <button
            className={`${style.allDeleteButton} btn btn-danger`}
            onClick={() => {
              setSelected(selected);
              setOpen(true);
            }}
          >
            Seçilenleri Sil
          </button>
        ) : (
          <button
            style={{ display: "none" }}
            onClick={() => {
              setSelected(selected);
              setOpen(true);
            }}
          >
            Seçilenleri Sil
          </button>
        )}
            <CardHeader>
              <CardLabel>
                <CardTitle className={`${style.title} ps-3`}>
                  Öğrencilerim
                </CardTitle>
              </CardLabel>
            </CardHeader>
            <AddStudentForm
              modalStatus={modalStatus}
              setModalStatus={setModalStatus}
              isFluid
            />
            <div
              className={`${style.actionsContainer} d-flex align-items-center justify-content-center px-2 mx-4 gap-3 row mx-auto`}
            >
              <div className={`${style.inputContainer} col-7 col-xl-9`} style={{width:`${isSchool ? "100%" : null}`}}>
                <Search
                  handleSearchChange={handleSearchChange}
                  iconColor={"#5454544F"}
                />
              </div>
              <div
                className={`${style.addStudentsContainer} ${isSchool && style.schoolCheckStyle} d-flex align-items-center gap-2 ps-2 col`}
                onClick={() => openClassForm(null)}
              >
                <span>
                  <img src={plus} alt="" />
                </span>
                <span>Öğrenci Ekle</span>
              </div>
            </div>

            <div className={`${style.customTable} mt-5 px-4`}>
              <div className={`${style.customHeadContainer} d-flex align-items-center my-3 px-2`}>
              <div
              className={`${style.checkContainer} ${style.customTableHead} ${isSchool && style.schoolCheckStyle}`}
            ><Checks
            id='selectAll'
            checked={selectAll}
            onChange={handleSelectAll}
          /></div>
                <div
                  className={`${style.customTableHead1} ${style.customTableHead}`}
                >
                  Ad/Soyad
                </div>
                <div
                  className={`${style.customTableHead2} ${style.customTableHead}`}
                >
                  Sınıf
                </div>
                <div
                  className={`${style.customTableHead3} ${style.customTableHead} text-center`}
                >
                  Kayıt Tarihi
                </div>
                <div
                  className={`${style.customTableHead4} ${style.customTableHead} text-center`}
                  style={{width:`${isSchool ? "30%" : null}`}}
                >
                  Puan
                </div>
                <div
                  className={`${style.customTableHead5} ${style.customTableHead} ${isSchool && style.schoolCheckStyle} text-center`}
                >
                  Eylemler
                </div>
              </div>
              <div className={`${style.customRowContainer}`}>
                {items.length === 0 ? (
                  <div>
                    <span>Kayıtlı öğrenci bulunamadı</span>
                  </div>
                ) : (
                  dataPagination(items, currentPage, perPage).map((item, i) => {
                    const isUserInfo = item.first_name === null;
                    const userInfo = (
                      <span>
                        {item.first_name} {item.last_name}
                      </span>
                    );
                    userWarning = (
                      <span className="text-danger">
                        Ad soyad bilgisi gereklidir.
                      </span>
                    );
                    return (
                      <div
                        key={item.id}
                        className={`${style.customTableRow} ${i % 2 === 0 && style.customBackgroundColor
                          } d-flex align-items-center mt-1 px-2`}
                      >
                         <div className={`${style.checkContainer} ${isSchool && style.schoolCheckStyle}`}>
                    <Checks
                      id={item.id.toString()}
                      name="selectedList"
                      value={JSON.stringify({ id: item.id, class_field: item.class_field })}
                      onChange={selectTable.handleChange}
                      checked={selectTable.values.selectedList.some(
                        (obj) => JSON.parse(obj).id === item.id
                      )}
                    />
                  </div>
                        <div
                          className={`${style.customTableColumn1} cursor-pointer`}
                          onClick={() =>
                            navigate(
                              `/teacher/${item.class_field}/student/${item.id}`
                            )
                          }
                        >
                          <div className={`${style.titleContainer} cursor-pointer`}>
                      <div className={`${style.titleShort}`}>{!isUserInfo ? userInfo : userWarning}</div>
                      <div className={`${style.titleFull}`}>{!isUserInfo ? userInfo : userWarning}</div>
                        </div>
                        </div>
                        <div
                          className={`${style.customTableColumn2} cursor-pointer`}
                          onClick={() =>
                            navigate(`/teacher/class/${item.class_field}`, {
                              state: { classId: item.class_field },
                            })
                          }
                        >
                          <div className={`${style.classContainer} cursor-pointer`}>
                      <div className={`${style.classShort}`}>{item.class_name}</div>
                      <div className={`${style.classFull}`}>{item.class_name}</div>
                        </div>
                        </div>
                        <div
                          className={`${style.customTableColumn3} text-center`}
                        >
                          <div>
                            <div>{formatDate(item.join_date)}</div>
                          </div>
                        </div>
                        <div
                          className={`${style.customTableColumn4} text-center`}
                          style={{width:`${isSchool ? "30%" : null}`}}
                        >
                          <div className="d-flex justify-content-center gap-2">
                            <div>{item.class_points}</div>
                          </div>
                        </div>
                        <div
                          className={`${style.customTableColumn5} ${isSchool && style.schoolCheckStyle} text-center`}
                        >
                          <div className="d-flex justify-content-center gap-2">
                   
                            {/* Eylemler */}
                            <div onClick={() => {
                              setClassId(item.class_field);
                            }}>

                                <ConfirmButton handleClick={handleRemoveStudentFromClass} parameter={item.id} />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
            <PaginationButtons
              data={items}
              label="öğrenci"
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              perPage={perPage}
              setPerPage={setPerPage}
            />
          </Card>
        </>
      </Page>
    </PageWrapper>
  );
};

export default TeacherStudents;
