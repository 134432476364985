import React from 'react'
import PageWrapper from '../../../../../layout/PageWrapper/PageWrapper'
import Page from '../../../../../layout/Page/Page'
import DashboardHeaderTitle from '../../../../common/Headers/DashboardHeaderWithTitle'
import SchoolManagerHeader from '../../../schoolManager/dashboard/school-manager-header/SchoolManagerHeader'
import SchoolRestrictions from './SchoolRestrictions'

const SchoolRestrictionWrapper = () => {
    const schoolName = localStorage.getItem("schoolName")
    const schoolLogo = localStorage.getItem("schoolLogo")
  return (
    <PageWrapper>
    <Page>
    <DashboardHeaderTitle />
  <div className="row" style={{height:"320px"}}>
    {/* Welcome banner */}
    <SchoolManagerHeader  schoolName={schoolName} logo={schoolLogo} />

    {/* School restrictions page */}
    <SchoolRestrictions/>
    
  </div>
    </Page>
  </PageWrapper>
  )
}

export default SchoolRestrictionWrapper