import React from "react";
import Input from "../../../../components/bootstrap/forms/Input";
import Icon from "../../../../components/icon/Icon";
import styles from "./page-header.module.css";

const PageHeader = ({ handleSearch, children }) => {
    const [search, setSearch] = React.useState("");

  return (
    <div className={styles.container}>
      <div className={styles.search}>
       <div className={styles.searchInput}>
       <label className="" htmlFor="searchInput">
          <Icon icon="Search" size="2x" color="primary" />
        </label>
        <Input
          // ref={refSearchInput}
          id="searchInput"
          className="border-0 shadow-none bg-transparent"
          placeholder="Ara..."
          onChange={(event)=> setSearch(event.target.value)}
          value={search}
          onKeyPress={(event) => {
            if (event.key === "Enter") handleSearch(search)
        }}
        onKeyDown={(event) => {
            if (event.key === "Backspace" && search.length ===1) handleSearch("");
        }}
        />
        </div>
      </div>
      {children}
    </div>
  );
};

export default PageHeader;
