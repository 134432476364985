import React, { useEffect, useState } from "react";
import style from "./demoDashboard.module.scss";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../layout/Page/Page";
import DashboardHeader from "../../../common/Headers/DashboardHeader";
import AVATARS from "../../../../common/data/avatarsDummy";
// import TawkToChat from "../../../../common/TawkToChat";
import StudentWithoutClass from "./studentWithoutClass/StudentWithoutClass";
import StudentWithClass from "./studentWithClass/1-StudentWithClass";
import FollowingLessons from "./followingLessons/1-FollowingLessons";
import DailyPlan from "./dailyPlan/1-DailyPlan";
import Last10Days from "./last10Days/1-LastDays";
import Calendar from "./calendar/Calendar";
import { useGetParentInformationQuery, useGetStudentSubscribedClassesQuery } from "../../../../store/features/apiSlice";
import { useWindowSize } from 'react-use';
import StudentWithOnlySchool from "./studentWithOnlySchool/1-StudentWithOnlySchool";
import { useGetAllCompetitionsQuery } from "../../admin/talent cup/talentCupApi";
import UserCompetitionInfoModal from "./UserCompetitionInfoModal";

const StudentDashboard = () => {
  const userId = localStorage.getItem("id");
  const { data } = useGetStudentSubscribedClassesQuery();
  const { data:competitions } = useGetAllCompetitionsQuery();
  const { data: parentSmsInformation} =
  useGetParentInformationQuery(userId);
  const [open, setOpen] = useState(false);
  function isStudentJoinedAnyContest(contests) {
    return contests?.some(contest => contest?.is_student_joined === true);
}
const isStudentJoinedCompetition = competitions && isStudentJoinedAnyContest(competitions)
const isParentPhoneNumber = parentSmsInformation && parentSmsInformation.parent_telephone_number;

  const { width } = useWindowSize();
  // eslint-disable-next-line
  const isNotMinDevice = width > 350
  const subscribedStudent = data && Object.keys(data).length !== 0
  const userAvatar = localStorage.getItem("user_avatar");
  let school = localStorage.getItem("school");
  let userName = localStorage.getItem("username");
  let userEmail = localStorage.getItem("email")
  userEmail = userEmail !== "null" && userEmail.split("@")[0];
  userName =
  userName && userName !== "null"
  ? userName
  : userEmail;
  school = school !== "null"
  const unassignedStudent = school && !subscribedStudent;

  const handleCancel = () => setOpen(false);
  useEffect(() => {
    const currentDate = new Date();
    const endDate = new Date("2024-11-10");
    if (currentDate <= endDate && isStudentJoinedCompetition && !isParentPhoneNumber) {
      setOpen(true);
    }
}, [isStudentJoinedCompetition, isParentPhoneNumber]);

  return (
    <PageWrapper>
      <DashboardHeader />
      <UserCompetitionInfoModal open={open} setOpen={setOpen} onCancel={handleCancel} />
      {/* <TawkToChat showWidget={isNotMinDevice}/> */}
      {/* <TawkToChat showWidget={false}/> */}
      <Page>
      {(!subscribedStudent && !unassignedStudent) &&  <div className={`${style.banner} d-flex justify-content-between align-items-center`}>
          <div className={`${style.welcome}`}>
            Tekrar Hoşgeldin {`${userName}`}!
          </div>
          <div className={`${style.userAvatar}`}>
            <img
              src={
                userAvatar && userAvatar !== "null"
                  ? userAvatar
                  : AVATARS.defaultAvatar
              }
              alt=""
            />
          </div>
        </div>}
        {subscribedStudent && <StudentWithClass/>}
        {unassignedStudent && <StudentWithOnlySchool/>}
        <div className="row d-flex justify-content-between mt-4">
          <div className="col-lg-7 col-12">
        <FollowingLessons/>
          </div>
          <div className="col-lg-5 col-12">
        <DailyPlan/>
          </div>
        </div>
        <div className="row d-flex mt-4">
        <div className="col-lg-7 col-12">
          <Calendar/>
          </div>
            <div className="col-lg-5 col-12">
            <Last10Days/>
            </div>
        </div>
       {(!subscribedStudent && !school) && <div className="my-4">
        <StudentWithoutClass/>
        </div>}
      </Page>
    </PageWrapper>
  );
};

export default StudentDashboard;
