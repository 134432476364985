import React from 'react'
import PresentationListTable from './2-PresentationListTable'
import Page from '../../../../../layout/Page/Page'

const PresentationManagement = () => {
  return (
    <>
    <Page container="fluid">
      <PresentationListTable/>
    </Page>
  </>
  )
}

export default PresentationManagement