import React, { useEffect, useState } from "react";
import style from "./CompetitionDetail.module.scss";
import Button from "../../../../../components/bootstrap/Button";
import Page from "../../../../../layout/Page/Page";
import { useNavigate, useParams } from "react-router-dom";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import SubHeader, {
  SubHeaderLeft,
} from "../../../../../layout/SubHeader/SubHeader";
import {
  useDeleteCompetitionMutation,
  useGet_a_Competition_ThumbnailQuery,
  useGet_a_CompetitionQuery,
} from "../talentCupApi";
import { formatDateTime } from "../../../../../utils/formatDate";
import { getCompetitionKind } from "../competition card/CompetitionCard";
import Confirm from "../../../../common/Confirm";
import { showError, showSuccess } from "../../../profilPage/utils";
import { useDispatch } from "react-redux";
import { setSelectedCompetition } from "../talentCupSlice";
import { COMPETITION_KIND } from "../../../../../common/data/enumCompetition";
import TalentDescription from "../components/TalentDescription";
import TalentResponsiveImage from "../components/TalentResponsiveImage";
import TalentInfo from "../components/TalentInfo";

const CompetitionDetail = () => {
  const dispatch = useDispatch();
  let { id: competitionId } = useParams();
  const {
    data: logoUrl,
    isLoading,
  } = useGet_a_Competition_ThumbnailQuery(competitionId, {
    skip: !competitionId,
  });
  const {
    data: competition,
    isLoading: competitionLoading,
  } = useGet_a_CompetitionQuery(competitionId, { skip: !competitionId });
  const isProjectCompetition =
    Number(competition?.kind) === COMPETITION_KIND.PROJECT;
  useEffect(() => {
    if (competition) {
      dispatch(setSelectedCompetition(competition));
    }
    // eslint-disable-next-line
  }, [competition]);

  const [deleteCompetition] = useDeleteCompetitionMutation();

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const [open, setOpen] = useState(false);
  //! delete competition--------
  const removeCompetition = (e) => {
    setOpen(true);
    // setCompetitionId(e.currentTarget.id && e.currentTarget.id);
  };
  const handleDelete = async () => {
    const response = await deleteCompetition(competitionId);
    setOpen(false);
    if (response.error) {
      showError();
    } else {
      goBack();
      showSuccess("Silme Başarılı");
    }
  };
  const handleCancel = () => setOpen(false);
  //!-----------------------------------------------
  if (competitionLoading || isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <PageWrapper title={"Yarışma Bilgileri"}>
      <SubHeader>
        <SubHeaderLeft>
          <Button
            color="info"
            isLink
            icon="ArrowBack"
            tag="a"
            onClick={goBack}
            // className={style.SubHeader}
          >
            Yarışmalar Sayfasına Geri Dön
          </Button>
        </SubHeaderLeft>
      </SubHeader>
      <Page>
        <Confirm
          open={open}
          setOpen={setOpen}
          onConfirm={handleDelete}
          onCancel={handleCancel}
        />
        <div className="card">
          <div className="row">
            <div className="col-6">
              <TalentResponsiveImage src={logoUrl} />
            </div>
            <div className={`${style.talendDesc} col-5 mx-auto`}>
              <h2>Açıklamalar</h2>
              <TalentDescription content={competition.description} />
            </div>
            <div className={`${style.talentInfo} col-6`}>
              <TalentInfo
                startDate={formatDateTime(competition.start_date)}
                endDate={formatDateTime(competition.end_date)}
                type={getCompetitionKind(competition.kind)}
                score={competition.points || 0}
              />
            </div>
            <div className={`${style.talentActionsButton} col-6`}>
              <Button
                className={`${style.applicationsButton} my-2`}
                onClick={() =>
                  navigate(
                    `/admin/talentCup/CompetitionDetail/${competitionId}/Applicants/${
                      isProjectCompetition ? "Project" : "Practice"
                    }`
                  )
                }
              >
                Başvurular
              </Button>
              <Button
                className={`${style.editButton} my-2`}
                onClick={() =>
                  navigate(`/admin/talentCup/competition/${competitionId}`)
                }
              >
                Düzenle
              </Button>
              <Button
                className={`${style.deleteButton} my-2 bg-danger text-light px-4`}
                onClick={(e) => removeCompetition(e)}
              >
                Sil
              </Button>
            </div>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default CompetitionDetail;
