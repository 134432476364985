import React from "react";
import TeacherClassPage from "./TeacherClassPage";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import DashboardHeader from "../../../common/Headers/DashboardHeader";
import Page from "../../../../layout/Page/Page";
import { teacherPanel } from "../../../../menu";
import { useParams } from "react-router-dom";
import { useGetClassQuery } from "../teacherApi";
import { ContentCopy } from "@mui/icons-material";
import showNotification from "../../../../components/extras/showNotification";
import { useClipboard } from "use-clipboard-copy";
import style from "./styles.module.scss"
import copyIcon from "../../../../assets/dashboardImages/copyIcon.svg"
import Card from "../../../../components/bootstrap/Card";

const TeacherClassPages = () => {
  const schoolId = localStorage.getItem("school");
  const { classId } = useParams();
  const { data: item} = useGetClassQuery(classId);
  const clipboard = useClipboard();

  return (
    <>
      <PageWrapper title={teacherPanel.teacher_students.text}>
        <DashboardHeader />
        <Page className={`${style.pageContainer}`}>
          <Card className="px-5 py-3">
          <div className={`${style.topContainer} px-5 row d-flex justify-content-between`}>
            <div className={`${style.classTitle} py-3 col-6`}>
              {item?.name}
            </div>
            {!(schoolId > 0) && <div
            className={`${style.classCode} col-6 mt-2 d-flex justify-content-center align-items-center gap-2`}
              onClick={() => {
                clipboard.copy(item?.code);
                showNotification(
                  <span className="d-flex align-items-center gap-2">
                    <ContentCopy />
                    <span>Copy The ClipBoard</span>
                  </span>,
                  "Panoya Kopyalandı.",
                  "success"
                );
              }}
            >
              <div>
                 <span>Sınıf Kodu</span>
              <span className="">
                {item?.code}
                {/* <ContentCopy /> */}
              </span>
              </div>
                <div className={`${style.copyIcon} align-self-start mt-2`}><img src={copyIcon} alt="" /></div>
             
            </div>}
          </div>
          <div className="row h-100">
            <div className="col-12">
              <TeacherClassPage isFluid class_id={classId} />
            </div>
          </div>
          </Card>
        </Page>
      </PageWrapper>
    </>
  );
};

export default TeacherClassPages;
