import React from "react";
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
} from "../../../../components/bootstrap/Modal";
import { useNavigate } from "react-router";
import style from "./UserCompetitionModal.module.scss";
const UserCompetitionInfoModal = ({ open, setOpen }) => {
  const navigate = useNavigate();

  const handleNavigation = (path, activeTab) => {
    navigate(`${path}?activeTab=${activeTab}`);
    setOpen(false);
  };

  return (
    <div>
      <Modal isOpen={open} setIsOpen={setOpen} isCentered titleId="competition-info-title">
        <ModalHeader>
          <ModalTitle id="competition-info-title">
            <div className={`${style.competitionInfoTitle} d-flex justify-content-center align-items-center my-1`}>
              <h3>🎁 Kazanan Herkese Hediye!</h3>
            </div>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className={`${style.modalText} mx-auto text-justify`}>
            <p>
              Merhaba, yarışmamıza katıldığınız için teşekkür ederiz! Her
              katılımcımızı sürpriz hediyeler bekliyor.
            </p>
            <p>
              Ödülleri doğru bir şekilde ulaştırabilmemiz için veli telefon bilginize ihtiyacımız var.
              Lütfen iletişim bilgilerinizi güncelleyin.
            </p>
          </div>
          <div
            className={`${style.updateProfileButton} cursor-pointer`}
            onClick={()=>handleNavigation(`/profile`, "Parent Information System")}
          >
            👉 Veli Telefon Bilgisi Ekle
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UserCompetitionInfoModal;
