import React, { useState } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/webpack-resolver";
import Card, {
  CardBody,
} from "../../../../components/bootstrap/Card";
import CodeEditorToolbar from "./CodeEditorToolbar";

function CodeEditor({
  handleUserCodeChange,
  onUserCodeFocus,
  codeEditorHeight,
  userCode,
  template_code
}) {
  const [mode, setMode] = useState("python");
  const [theme, setTheme] = useState({ name: "Koyu (Default)", value: "monokai" });

  const onChange = (value) => {
    handleUserCodeChange(value);
  };
  return (

<>
<CodeEditorToolbar mode={mode} theme={theme.name} setMode={setMode} setTheme={setTheme}/>

    <Card stretch={true}>
     
        <CardBody className="table-responsive" isScrollable={false}>
        <AceEditor
        className=""
          value={userCode}
          // placeholder={template_code}
          height={`${codeEditorHeight}px`}
          width="60vw"
          mode={mode}
          theme={theme.value}
          onChange={onChange}
          name="my_code_editor"
          onFocus={onUserCodeFocus}
          editorProps={{
            $blockScrolling: true,
          }}
          fontSize={18}
          showPrintMargin={false}
          showGutter={true}
          highlightActiveLine={false}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 1,
          }}
          wrapEnabled={true}
          />
        </CardBody>
      </Card>
          </>
  );
}

export default CodeEditor;
