//Converts date string to dd Month yyyy eg: 30 Ağustos 2023
export const formatDate = (time) => {
  const parsedDate = new Date(time);
  const options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };
  return parsedDate.toLocaleString('tr-TR', options);
}

function formatTime(timeString) {
  // Eğer timeString hh:mm:ss formatındaysa, sadece geri dön.
  if (/^\d{2}:\d{2}:\d{2}$/.test(timeString)) {
      return timeString;
  }

  // Aksi takdirde, hh:mm:ss.SSSSSS veya hh:mm formatındaysa hh:mm:ss formatına dönüştür.
  // const parts = timeString.split(':');
  const parts = timeString ? timeString.split(':') : [];
  let hour = parts[0] || '00';
  let minute = parts[1] || '00';
  let second = (parts[2] && parts[2].split('.')[0]) || '00';

  // Tek basamaklı saat, dakika veya saniye varsa başına 0 ekle
  hour = hour.padStart(2, '0');
  minute = minute.padStart(2, '0');
  second = second.padStart(2, '0');

  return `${hour}:${minute}:${second}`;
}

function combineDateAndTime(date, time) {
  const edittedTime = formatTime(time)
  const parsedDate = new Date(date);
  const [hours, minutes] = edittedTime.split(":");
  // const [hours, minutes] = time.split(":");
  parsedDate.setHours(hours);
  parsedDate.setMinutes(minutes);
  return parsedDate;
}
//"2023-08-28T16:19:00+03:00"
function splitDateAndTime(date) {
  if(!date){
    return new Error("")
  }
  const [dateString, timeString] = date.split("T");
  const [time] = timeString.split("+");
  const edittedTime = formatTime(time).slice(0,5)
  return { dateString, time:edittedTime };
  // return { dateString, edittedTime };
}
//Converts date string to dd-mm-yyyy eg: 30-08-2023
function formatDate2TurkishShortDate(inputDateString) {
  const inputDate = new Date(inputDateString);

  const day = inputDate.getDate().toString().padStart(2, '0');
  const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
  const year = inputDate.getFullYear();

  return `${day}-${month}-${year}`;
}

function isDateInLastTenDays(inputDateString) {
  const inputDate = new Date(inputDateString);
  const currentDate = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = currentDate - inputDate;

  // Calculate the time difference in days
  const timeDifferenceInDays = timeDifference / (1000 * 60 * 60 * 24);

  return timeDifferenceInDays <= 10;
}

function getHourMinute(inputTime) {
  const [hourPart, minutePart] = inputTime.split(':');
  return `${hourPart}:${minutePart}`;
}

function formatDateTime(inputDateTime) {
  // Verilen tarih dizesi
  var date = new Date(inputDateTime);

  // Tarih ve saat bilgisini alıp istediğiniz formatta yazdırma
  var day = date.getDate();
  var month = date.getMonth() + 1; // Ay indeksi 0'dan başlar, bu yüzden 1 ekliyoruz.
  var year = date.getFullYear();
  var hour = date.getHours();
  var minute = date.getMinutes();

  // İki haneli olması için kontrol ve gerekirse başına 0 ekleme
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;
  hour = hour < 10 ? '0' + hour : hour;
  minute = minute < 10 ? '0' + minute : minute;

  // Sonuçları birleştirme
  var formattedDateTime = day + '.' + month + '.' + year + ' / ' + hour + ':' + minute;

  // Sonucu döndürme
  return formattedDateTime;
}

function getHourFromDate(dateTimeString) {
  const dateTime = new Date(dateTimeString);
  const hour = dateTime.getHours();
  const minute = dateTime.getMinutes();
  // const second = dateTime.getSeconds();

  // Saat, dakika ve saniye bilgilerini string olarak birleştirme
  const formattedTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;

  return formattedTime;
}

function getDayMonthYearAsString(inputDate) {
  const months = [
    "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
    "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
  ];

  const date = new Date(inputDate);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`; //8 Temmuz 2024
}

function getDayAndMonthAsString(inputDate) {
  const months = [
    "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
    "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
  ];

  const date = new Date(inputDate);
  const day = date.getDate();
  const month = months[date.getMonth()];

  return `${day} ${month}`; //8 Temmuz
}

export { combineDateAndTime, splitDateAndTime, formatDate2TurkishShortDate,isDateInLastTenDays, getHourMinute, formatDateTime, getHourFromDate, getDayMonthYearAsString, getDayAndMonthAsString }