// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContentRestrictions_classDropdown__mqzfP {\n  width: 30%;\n}\n@media (max-width: 769px) {\n  .ContentRestrictions_classDropdown__mqzfP {\n    width: 50%;\n  }\n}\n@media (max-width: 500px) {\n  .ContentRestrictions_classDropdown__mqzfP {\n    width: 100%;\n  }\n}\n\n.ContentRestrictions_cardContainer__G3ddL {\n  overflow: \"scroll\";\n  height: \"400px\";\n}\n@media (max-width: 800px) {\n  .ContentRestrictions_cardContainer__G3ddL {\n    height: 50vh;\n    overflow: scroll;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/t14/schoolManager/class%20restrictions/content%20restrictions/ContentRestrictions.module.scss","webpack://./src/pages/t14/schoolManager/class restrictions/content restrictions/ContentRestrictions.module.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;ACCJ;ADAI;EAFJ;IAGQ,UAAA;ECGN;AACF;ADFI;EALJ;IAMQ,WAAA;ECKN;AACF;;ADFA;EACI,kBAAA;EACA,eAAA;ACKJ;ADJI;EAHJ;IAIQ,YAAA;IACA,gBAAA;ECON;AACF","sourcesContent":[".classDropdown{\n    width: 30%;\n    @media (max-width: 769px) {\n        width: 50%;\n      }\n    @media (max-width: 500px) {\n        width: 100%;\n      }\n}\n\n.cardContainer{\n    overflow: \"scroll\";\n    height: \"400px\";\n    @media (max-width: 800px) {\n        height: 50vh;\n        overflow: scroll;\n      }\n}",".classDropdown {\n  width: 30%;\n}\n@media (max-width: 769px) {\n  .classDropdown {\n    width: 50%;\n  }\n}\n@media (max-width: 500px) {\n  .classDropdown {\n    width: 100%;\n  }\n}\n\n.cardContainer {\n  overflow: \"scroll\";\n  height: \"400px\";\n}\n@media (max-width: 800px) {\n  .cardContainer {\n    height: 50vh;\n    overflow: scroll;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"classDropdown": "ContentRestrictions_classDropdown__mqzfP",
	"cardContainer": "ContentRestrictions_cardContainer__G3ddL"
};
export default ___CSS_LOADER_EXPORT___;
