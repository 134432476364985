import React, { useState } from "react";
import style from "./liveLessonForStudents.module.scss";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../layout/Page/Page";

import useSortableData from "../../../../hooks/useSortableData";
import DashboardHeaderWithTitle from "../../../common/Headers/DashboardHeaderWithTitle";
import PaginationButtons, {
  PER_COUNT,
  dataPagination,
} from "../../../../components/PaginationButtons";
import CircleSvg from "./CircleSvg";
import Button from "../../../../components/bootstrap/Button";
import { useGetLiveLecturesForStudentQuery } from "../../teacher/teacherApi";
import { formatDate2TurkishShortDate, getHourMinute } from "../../../../utils/formatDate";
import _ from "underscore";

const LiveLessonForStudent = ({ isFluid }) => {

  const { data: liveLessons } = useGetLiveLecturesForStudentQuery();

  const customOrder = [2, 1, 3];

  let sortedLiveLessons  = _.sortBy(liveLessons, item => item.live_lecture.date);
  sortedLiveLessons  = _.sortBy(sortedLiveLessons, item => customOrder.indexOf(item.status));

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_COUNT["10"]);
  const { items } = useSortableData(sortedLiveLessons ?? []);

  const statusData = {
    1: {
      color: "#6697EF",
      text: "BAŞLAMADI",
      lessonText: "Derse Katıl",
    },
    2: {
      color: "#618E38",
      text: "BAŞLADI",
      lessonText: "Hemen Katıl",
    },
    3: {
      color: "#F24F1D",
      text: "BİTTİ",
      lessonText: "Kaydı İzle",
    },
  };

  function getStatusColor(status) {
    return statusData[status]?.color || "Bilinmeyen Durum";
  }

  function getStatusText(status) {
    return statusData[status]?.text || "Bilinmeyen Durum";
  }

  function getLessonText(status) {
    return statusData[status]?.lessonText || "Bilinmeyen Durum";
  }
  return (
    <PageWrapper className={`${style.pageContainer}`}>
      <DashboardHeaderWithTitle title="Canlı Ders" />
      <Page className={`${style.pageContainer}`}>
        <div className="row">
          <div className="col-12">
            <div className={`${style.cardContainer}`}>
              <div className={`${style.customTable} px-4`}>
                <div
                  className={`${style.customHeadContainer} d-flex align-items-center my-3 px-2`}
                >
                  <div
                    className={`${style.customTableHead1} ${style.customTableHead}`}
                  >
                    Başlık
                  </div>
                  <div
                    className={`${style.customTableHead2} ${style.customTableHead}`}
                  >
                    Açıklama
                  </div>

                  <div
                    className={`${style.customTableHead3} ${style.customTableHead} text-center`}
                  >
                    Tarih
                  </div>
                  <div
                    className={`${style.customTableHead4} ${style.customTableHead} text-center`}
                  >
                    Saat Aralığı
                  </div>
                  <div
                    className={`${style.customTableHead5} ${style.customTableHead} text-start`}
                  >
                    Durum
                  </div>
                  <div
                    className={`${style.customTableHead6} ${style.customTableHead} text-center`}
                  >
                    Katıl
                  </div>
                </div>
                <div className={`${style.customRowContainer}`}>
                  {items.length === 0 ? (
                    <div>
                      <span>Kayıtlı ders bulunamadı</span>
                    </div>
                  ) : (
                    dataPagination(items, currentPage, perPage).map(
                      (item, i) => (
                        <div
                          key={item.id}
                          className={`${style.customTableRow} ${
                            i % 2 === 0 && style.customBackgroundColor
                          } d-flex align-items-center mt-1 px-2`}
                        >
                          <div
                            className={`${style.customTableColumn1}`}
                          >
                            <div>
                              <div className={`${style.titleContainer}`}>
                      <div className={`${style.titleShort}`}>{item.live_lecture.title}</div>
                      <div className={`${style.titleFull}`}>{item.live_lecture.title}</div>
                        </div>
                            </div>
                          </div>
                          <div className={`${style.customTableColumn2}`}>
                            <div className={`${style.descriptionContainer}`}>
                      <div className={`${style.descriptionShort}`}>{item.live_lecture.description}</div>
                      <div className={`${style.descriptionFull}`}>{item.live_lecture.description}</div>
                        </div>
                          </div>
                          <div
                            className={`${style.customTableColumn3} text-center`}
                          >
                            <div>{formatDate2TurkishShortDate(item.live_lecture.date)}</div>
                          </div>
                          <div
                            className={`${style.customTableColumn4} text-center`}
                          >
                            <div>{getHourMinute(item.live_lecture.start_time)} - {getHourMinute(item.live_lecture.end_time)}</div>
                          </div>
                          <div
                            className={`${style.customTableColumn5} d-flex align-items-center justify-content-start`}
                          >
                            <div
                              className={`${style.circleAndStatus} d-flex align-items-center justify-content-center justify-content-md-start gap-2`}
                            >
                              <div className="d-none d-md-block">
                                <CircleSvg
                                  fillColor={getStatusColor(item.status)}
                                />
                              </div>
                              {getStatusText(item.status)}
                            </div>
                          </div>

                          <div
                            className={`${style.customTableColumn6} text-center`}
                          >
                            <div className={`${style.recordButton} text-center`}>
                              <Button
                                style={{
                                  backgroundColor: getStatusColor(item.status),
                                  pointerEvents: `${
                                    item.status === 3 &&
                                    !item.live_lecture.recorded_link &&
                                    "none"
                                  }`,
                                }}
                              >
                                {item.status === 3 &&
                                !item.live_lecture.recorded_link ? (
                                  <span>Kayıt Eklenmedi</span>
                                ) : (
                                  <a
                                    href={
                                      item.live_lecture.recorded_link
                                        ? item.live_lecture.recorded_link
                                        : item.live_lecture.live_link
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      textDecoration: "none",
                                      color: "#fff",
                                    }}
                                  >
                                    {getLessonText(item.status)}
                                  </a>
                                )}
                              </Button>
                            </div>
                          </div>
                        </div>
                      )
                    )
                  )}
                </div>
              </div>
              <PaginationButtons
                data={items}
                label="canlı ders"
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </div>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default LiveLessonForStudent;
