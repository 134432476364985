import React, { useState, useEffect } from "react";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import SubHeader, {
  SubHeaderLeft, SubHeaderRight,
} from "../../../layout/SubHeader/SubHeader";
import LearningPathPractices from "./LearningPathPractices";
import { studentPages } from "../../../menu";
import { useParams } from "react-router-dom";
import { get } from "../../../utils/service";
import _ from "underscore"
import DashboardHeader from "../../common/Headers/DashboardHeader";
import style from "./LearningPath.module.scss"

const LearningPath = () => {
  // useMinimizeAside();
  const [learningPath, setLearningPath] = useState({
    technology: ""
  });
  const [practices, setPractices] = useState(null);
  const { id } = useParams()

  const handlePosts = async () => {
    try {
      const { data } = await get(`learningpaths/${id}/practices/user_status/`);
      setLearningPath({ ...data });

      const practices = _.sortBy(data.practices, "order_number");
      setPractices(practices);

    } catch (err) {
    } finally {
    }
  };

  useEffect(() => {
    handlePosts();
    //eslint-disable-next-line
  }, [id]);

  return (
    <PageWrapper title={studentPages.learningPathContent.text}>
      <div className="d-block d-md-none">
      <DashboardHeader/>
      </div>
      <SubHeader>
        <SubHeaderLeft>
          <div className="text-muted d-none d-md-block">
            <strong>
              <span className="text-info fw-bold">{learningPath ? learningPath.title : "Yükleniyor.."}</span>
            </strong>
          </div>
        </SubHeaderLeft>
        <SubHeaderRight>
          <div className="text-muted mt-5 d-block d-sm-none">
            <strong>
              <span className="text-info fw-bold">{learningPath ? learningPath.title : "Yükleniyor.."}</span>
            </strong>
          </div>
        </SubHeaderRight>
      </SubHeader>
      <Page className={`${style.pageContainer}`}>
        <LearningPathPractices learning_path_id={id} practices={practices} technology={learningPath.technology} />
      </Page>
    </PageWrapper>
  );
};

export default LearningPath;
