import React, { useEffect, useState } from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../components/bootstrap/Modal";
import Logo from "../../../../components/Logo";
import Icon from "../../../../components/icon/Icon";
import Button from "../../../../components/bootstrap/Button";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import { useFormik } from "formik";
import Select from "../../../../components/bootstrap/forms/Select";
import {
  useCoursesQuery,
  useSingleTrailQuery,
  useUpdateTrailMutation,
} from "../../../../store/features/apiSlice";
import showNotification from "../../../../components/extras/showNotification";
import Label from "../../../../components/bootstrap/forms/Label";
import _ from "underscore";
import Textarea from "../../../../components/bootstrap/forms/Textarea";
import { useGetSchoolsQuery } from "../../teacher/teacherApi";

const UpdatePathModal = ({
  isOpenUpdateTrailModal,
  setIsOpenUpdateTrailModal,
  trailId,
}) => {
  const isPartner = localStorage.getItem("schoolStatus") === "partner";
  // mutations and queries
  const [updateTrail] = useUpdateTrailMutation();
  const {
    data: trailData,
    isSuccess: trailIsSuccess,
  } = useSingleTrailQuery({ id: trailId });
  const { data } = useCoursesQuery();

  const { data: schools } = useGetSchoolsQuery();
  const schoolsList =
    schools &&
    schools.map((item) => {
      return {
        text: item.title,
        value: item.id,
      };
    });

  // constants
  let activeLessons = data?.filter((item) => item.status === 1);
  activeLessons = _.sortBy(activeLessons, "order_number");
  let lessons = activeLessons;

  //states
  const [selectedLessons, setSelectedLessons] = useState([]);

  //useEffects
  useEffect(() => {
    formik.setFieldValue("courses", selectedLessons);
    //eslint-disable-next-line
  }, [selectedLessons]);

  useEffect(() => {
    if (trailIsSuccess && trailData) {
      formik.setFieldValue("title", trailData.title);
      formik.setFieldValue("description", trailData.description);
      formik.setFieldValue("status", trailData.status);
      formik.setFieldValue("order_number", trailData.order_number);
      formik.setFieldValue("school", trailData.school);
      formik.setFieldValue("school_status", trailData.school_status);
      const prevLessons = trailData.courses.map((item) => item.id);
      setSelectedLessons(prevLessons);
    }
    //eslint-disable-next-line
  }, [trailIsSuccess, trailData]);

  //functions
  const handleLesson = (lessonId) => {
    if (selectedLessons.includes(lessonId)) {
      setSelectedLessons(selectedLessons.filter((id) => id !== lessonId));
    } else {
      setSelectedLessons([...selectedLessons, lessonId]);
    }
  };


  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Dersin adını giriniz";
    }

    if (!values.description) {
      errors.description = "Açıklama giriniz";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      courses: [],
      order_number: 9999,
      status: "1",
      school: null,
      school_status:"1",
    },
    validate,
    onSubmit: () => {
      // console.log(formik.values.courses);
      handleTrail();
    },
  });

  const handleTrail = async () => {
    const pathValues = { ...formik.values };
    const response = await updateTrail({ id: trailId, ...pathValues });
    if (response.error) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Kayıt Başarısız</span>
        </span>,
        "Bir hata oluştu",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Kayıt Başarılı</span>
        </span>,
        "Patika başarıyla güncellendi.",
        "success"
      );
    }
    setIsOpenUpdateTrailModal(false);
    formik.resetForm();
  };
  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenUpdateTrailModal}
      setIsOpen={setIsOpenUpdateTrailModal}
      titleId="example-title"
      size={null}
    >
      <ModalHeader setIsOpen={setIsOpenUpdateTrailModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} /> <span className="ps-2">Patika Düzenle</span>
          <span className="ps-2">
            <Icon icon="Computer" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="row gap-3 d-flex justify-content-center">
          <div className="col-lg-8">
            <FormGroup
              // className="col-4"
              id="title"
              isFloating
              label="Başlık"
            >
              <Input
                onChange={formik.handleChange}
                value={formik.values.title}
                onBlur={formik.handleBlur}
                isValid={formik.isValid}
                isTouched={formik.touched.title}
                invalidFeedback={formik.errors.title}
              />
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup
              // className="col-4"
              id="description"
              isFloating
              label="Açıklama"
            >
              <Textarea
                onChange={formik.handleChange}
                value={formik.values.description}
                onBlur={formik.handleBlur}
                isValid={formik.isValid}
                isTouched={formik.touched.description}
                invalidFeedback={formik.errors.description}
                style={{ height: "150px" }}
              />
            </FormGroup>
          </div>
          <div
            className="col-lg-8 ms-3 pt-2 rounded-2"
            style={{ backgroundColor: "#F8F9FA" }}
          >
            <span className="h5">Ders Başlığı</span>
            <div
              className="mt-2"
              style={{ maxHeight: "200px", overflow: "scroll" }}
            >
              {lessons?.map((lesson) => (
                <FormGroup key={lesson.id}>
                  <Label className="d-flex">
                    <input
                      type="checkbox"
                      checked={selectedLessons.includes(lesson.id)}
                      onChange={() => handleLesson(lesson.id)}
                      name="selectedLessons"
                      value={lesson.id}
                    />
                    <span className="mx-3">{lesson.title}</span>
                  </Label>
                </FormGroup>
              ))}
            </div>
          </div>
          <div className="col-lg-8">
            <FormGroup isFloating label="Sıra Numarası" id="order_number">
              <Input
                name="order_number"
                type="text"
                autoComplete="order_number"
                value={formik.values.order_number}
                onChange={formik.handleChange}
                isValid={formik.isValid}
                onBlur={formik.handleBlur}
                isTouched={formik.touched.order_number}
                invalidFeedback={formik.errors.order_number}
              />
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup id="status">
              <Select
                placeholder="Aktiflik"
                ariaLabel="status"
                onChange={formik.handleChange}
                value={formik.values.status}
                isValid={formik.isValid}
                onBlur={formik.handleBlur}
                isTouched={formik.touched.status}
                invalidFeedback={formik.errors.status}
                list={[
                  { value: "0", text: "Pasif" },
                  { value: "1", text: "Aktif" },
                  { value: "2", text: "Taslak" },
                ]}
                className="text-dark"
              ></Select>
            </FormGroup>
          </div>
          {isPartner && (
              <>
                <div className="col-lg-8">
                  <FormGroup id="school" label="Okul Seç" isFloating>
                    <Select
                      ariaLabel="school"
                      placeholder="Okul"
                      onChange={formik.handleChange}
                      value={formik.values.school}
                      isValid={formik.isValid}
                      onBlur={formik.handleBlur}
                      isTouched={formik.touched.school}
                      invalidFeedback={formik.errors.school}
                      list={schoolsList}
                      disabled
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-8">
                  <FormGroup id="school_status">
                    <Select
                      placeholder="Aktiflik"
                      ariaLabel="school_status"
                      onChange={formik.handleChange}
                      value={formik.values.school_status}
                      isValid={formik.isValid}
                      onBlur={formik.handleBlur}
                      isTouched={formik.touched.school_status}
                      invalidFeedback={formik.errors.school_status}
                      list={[
                        { value: "1", text: "Default" },
                        { value: "2", text: "Okul" },
                        { value: "3", text: "Partner" },
                      ]}
                      className="text-dark"
                      disabled
                    ></Select>
                  </FormGroup>
                </div>
              </>
            )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={async () => {
            formik.handleSubmit();
          }}
        >
          Güncelle
        </Button>
        <Button
          icon="Cancel"
          color="danger"
          isLight
          tag="a"
          onClick={() => setIsOpenUpdateTrailModal(false)}
        >
          İptal
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UpdatePathModal;
