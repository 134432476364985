const DifficultyLevels = Object.freeze({
  EASY: 1,
  MEDIUM: 2,
  HARD: 3,
});

export default DifficultyLevels;

export function getDifficultyText(difficulty) {
  if (difficulty === undefined || difficulty === null) {
    console.warn("Zorluk seviyesi tanımlanmadı. Varsayılan olarak 'Kolay' dönülüyor.");
    return "Kolay"; // Varsayılan zorluk seviyesi
  }

  switch (difficulty) {
    case DifficultyLevels.EASY:
      return "Kolay";
    case DifficultyLevels.MEDIUM:
      return "Orta";
    case DifficultyLevels.HARD:
      return "İleri";
    default:
      throw new Error("Geçersiz zorluk seviyesi: " + difficulty);
  }
}
