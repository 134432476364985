// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".page-header_container__vLpbk{\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-pack: justify;\n        justify-content: space-between;\n}\n\n.page-header_searchInput__6MaZJ{\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-pack: center;\n        justify-content: center;\n    -ms-flex-align: center;\n        align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/t14/teacher/learning-path/page-header.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAa;IAAb,aAAa;IACb,sBAA8B;QAA9B,8BAA8B;AAClC;;AAEA;IACI,oBAAa;IAAb,aAAa;IACb,qBAAuB;QAAvB,uBAAuB;IACvB,sBAAmB;QAAnB,mBAAmB;AACvB","sourcesContent":[".container{\n    display: flex;\n    justify-content: space-between;\n}\n\n.searchInput{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "page-header_container__vLpbk",
	"searchInput": "page-header_searchInput__6MaZJ"
};
export default ___CSS_LOADER_EXPORT___;
