import React from 'react'
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper'
import Page from '../../../../layout/Page/Page'
import DashboardHeaderTitle from '../../../common/Headers/DashboardHeaderWithTitle'
import SchoolManagerHeader from '../dashboard/school-manager-header/SchoolManagerHeader'
import UserManagementForSchool from './UserManagementForSchool'

const UserManagementWrapper = () => {
    const schoolName = localStorage.getItem("schoolName")
    const schoolLogo = localStorage.getItem("schoolLogo")
  return (
    <PageWrapper>
    <Page>
    <DashboardHeaderTitle />
  <div className="row" style={{height:"320px"}}>
    {/* Welcome banner */}
    <SchoolManagerHeader  schoolName={schoolName} logo={schoolLogo} />

    {/* User management page */}
    <UserManagementForSchool/>
    
  </div>
    </Page>
  </PageWrapper>
  )
}

export default UserManagementWrapper