import React, { useRef, useState } from "react";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import DashboardHeader from "../../../../common/Headers/DashboardHeader";
import SubHeader, {
  SubHeaderLeft,
} from "../../../../../layout/SubHeader/SubHeader";
import Button from "../../../../../components/bootstrap/Button";
import style from "./TeacherGuidance.module.scss";
import { ContentService } from "../../../admin/course management/h5p/services/ContentService";
import { H5PPlayerUI } from "@lumieducation/h5p-react";
import H5PIframe from "./H5PIframe";

const GuideContent = () => {
  const page = "Önceki";
  const teacherGuide = JSON.parse(localStorage.getItem("guide"));

  const h5pPlayer = useRef();
  const contentService = new ContentService("/h5p");

  // Loading durumu için useState kullanın
  const [loading, setLoading] = useState(true);

  const onPlayerInitialized = () => {
    console.log(
      "%cContentListEntryComponent.tsx line:590 loading",
      "color: #007acc;",
      loading
    );
    setLoading(false); // Loading durumunu güncelleyin
  };

  return (
    <PageWrapper>
      <DashboardHeader />
      <SubHeader className={"mt-5 pt-3 mt-md-0 pt-md-0"}>
        <SubHeaderLeft>
          <Button color="info" isLink icon="ArrowBack" tag="a" to={-1}>
            {`${page}`} Sayfaya Geri Dön
          </Button>
        </SubHeaderLeft>
      </SubHeader>
      <div className={`${style.videoContainer} mx-auto`}>
        {teacherGuide.content_id ? (
          <div className={`${style.h5pContainer}`}>
            <H5PPlayerUI
              ref={h5pPlayer}
              contentId={teacherGuide.content_id}
              contextId={undefined}
              asUserId={"1234"}
              readOnlyState={true}
              loadContentCallback={contentService.getPlay}
              onInitialized={onPlayerInitialized}
              onxAPIStatement={(statement, context, event) =>
                console.log(statement, context, event)
              }
            />
          </div>
        ) : (
          <H5PIframe src={`${teacherGuide.video}`} />
        )}
      </div>
    </PageWrapper>
  );
};

export default GuideContent;
