import React from "react";
import style from "./TeacherDocuments.module.scss";
import emptyFileIcon from "../../../../../../assets/emptyFileIcon.png";
import CertificateThumbnail from "./DynamicThumbnail/CertificateThumbnail";
import { useNavigate } from "react-router-dom";

const CertificateInfo = ({ certificateData }) => {
  const { title, id } = certificateData;
  const navigate = useNavigate();

  const showCertificate = (id) => {
    navigate(`/teacher/coursesForTeacher/documents/${id}`);
  };
  return (
    <div>
      <div
        className={`${style.certificateDiv} mx-auto`}
        style={{ cursor: "pointer" }}
        onClick={() => showCertificate(id)}
      >
        <CertificateThumbnail name={"PDF"} src={emptyFileIcon} />
      </div>
      <div className={`${style.certificateTitle}`}>
        <div className={`${style.titleContainer}`}>
          <div className={`${style.titleShort}`}>{title}</div>
          <div className={`${style.titleFull}`}>{title}</div>
        </div>
      </div>
    </div>
  );
};

export default CertificateInfo;
