import React from 'react'
import style from "./ParentReport.module.scss"
import talent from "../../../../assets/dashboardImages/talent.png";
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Page from '../../../../layout/Page/Page';
import ParentReportWithClass from './parentReportWithClass/ParentReportWithClass';
import ParentReportWithoutClass from './parentReportWithoutClass/ParentReportWithoutClass';
import { useParentReportQuery } from '../../../../store/features/apiSlice';
import { useParams } from 'react-router-dom';

const ParentReport = () => {
  let publicId = useParams()
  publicId = publicId.id
  const { data} = useParentReportQuery(publicId);
  const isSubscribedStudent = data && data[0].classroom !== null;
// Bugünün tarihini al
const today = new Date();

// Tarih öğelerini al (gün, ay, yıl)
const day = today.getDate();
const month = today.getMonth() + 1; // Ay 0'dan başlar, bu nedenle 1 ekliyoruz.
const year = today.getFullYear();
const studentData = data && data[0]

return (
    <PageWrapper>
      <Page className={`${style.mainReportContainer}`}>
        <div className={`${style.logoAndDateContainer} d-flex flex-md-row flex-column-reverse`}>
      <div className={`${style.brandLogo} mx-auto`}>
        <img src={talent} alt="" />
      </div>
      <div className={`${style.date} align-self-end`}>{day}.{month}.{year}</div>
        </div>
        {(studentData && isSubscribedStudent) && <ParentReportWithClass data = {studentData}/>}
        {(studentData && !isSubscribedStudent) && <ParentReportWithoutClass data = {studentData}/>}
      </Page>
    </PageWrapper>
  )
}

export default ParentReport