import React, { useEffect, useState } from "react";
import { useGetAllTeacherPracticesQuery } from "../../teacherApi";
import Card, { CardBody } from "../../../../../components/bootstrap/Card";
import SimpleSelectList from "../../components/SimpleSelectList";

const TeacherPracticeListContent = ({
  setTeacherPractices,
  currentPractices,
}) => {
  // let practiceParams = `access_state=1`
  const {
    data: teacherPracticeList,
    isLoading,
    error,
  } = useGetAllTeacherPracticesQuery();
  const [selectedPractices, setSelectedPractices] = useState([])
  useEffect(() => {
    setSelectedPractices(currentPractices?.map((item) => Number(item.id)))
    // eslint-disable-next-line
  }, [])
  
  async function selectPractice(id) {
    let practice = teacherPracticeList.find(
      (p) => Number(p.id) === Number(id)
    );
    const isPractice = selectedPractices.includes(Number(id))
    if (!isPractice) {
      setSelectedPractices((prev)=>([...prev, Number(id)]))
      //! current practice objesinin genişletilebilir olup olmadığını kontrol ediyorum.
      if(Object.isExtensible(currentPractices)){
        currentPractices?.push(practice)
      }else{
        currentPractices = [...currentPractices,practice]
      }
    } else {
      const filteredPractices = selectedPractices.filter(
        (item) => Number(id) !== Number(item)
      );
      const filteredPracticesTest = currentPractices.filter(
        (item) => Number(practice.id) !== Number(item.id)
      );
      setSelectedPractices(filteredPractices)
      currentPractices = filteredPracticesTest;
    }
    setTeacherPractices(currentPractices);
  }
  return (
    <div>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error</p>}
      {!isLoading && !error && (
        <div className="row">
          <div className="col" style={{ minWidth: "250px" }}>
            <Card style={{ width: "100%", height: "90%" }}>
              <CardBody>
                <SimpleSelectList
                  items={teacherPracticeList}
                  selectedItems={selectedPractices}
                  onSelect={selectPractice}
                  selectKey={"id"}
                  displayKey={"title"}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};

export default TeacherPracticeListContent;
