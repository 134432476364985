import { createSlice} from "@reduxjs/toolkit";
import USER_PAYMENT_PLAN_TYPE from "../common/data/enumUserPaymentPlanType";


const paymentSlice = createSlice({
    name: "payment",
    initialState: {
        standartMounthly:false,
        premiumMounthly:false,
        premiumPlan:false,
        standartPlan:false,
        totalPrice:USER_PAYMENT_PLAN_TYPE.STANDARTMOUNTHLY.newPrice,
    },
    reducers: {
        setStandartMounthly: (state, action) => {
            state.standartMounthly = action.payload;
        },
        setPremiumMounthly: (state, action) => {
            state.premiumMounthly = action.payload;
        },
        setPremiumPlan: (state, action) => {
            state.premiumPlan = action.payload;
        },
        setStandartPlan: (state, action) => {
            state.standartPlan = action.payload;
        },
        setTotalPrice: (state, action) => {
            state.totalPrice = action.payload;
        },
    }
});

export const {setStandartMounthly, setPremiumMounthly, setTotalPrice, setPremiumPlan, setStandartPlan} = paymentSlice.actions;

export default paymentSlice;