import React, { useEffect, useState } from "react";
import style from "./homeworkTracking.module.scss";
import _ from "underscore";
import StudentProgress from "./studentProgress/StudentProgress";
import { nanoid } from "nanoid";

const HomeworkTracking = ({
  homeworksOfClass,
  homeworkStatus,
  studentsOfClass,
}) => {
  const [selectedHomeworks, setSelectedHomeworks] = useState(
    homeworksOfClass ?? []
  );

  // ödev takibinde tablo başlıklarını buradan çekiyorum
  let orderedSelectedHomeworks = _.sortBy(selectedHomeworks, "id").reverse();
  orderedSelectedHomeworks = _.sortBy(orderedSelectedHomeworks, "status");

  useEffect(() => {
    if (homeworksOfClass) {
      // console.log(homeworksOfClass, "effect");
      setSelectedHomeworks(homeworksOfClass);
    }
  }, [homeworksOfClass]);

  useEffect(() => {
    setSelectedHomeworks(filtreleArray(homeworksOfClass, homeworkStatus));
    //eslint-disable-next-line
  }, [homeworkStatus]);

  function filtreleArray(array, homeworkStatus) {
    if (!array || array.length === 0) {
      return [];
    }

    if (homeworkStatus === "Tamamı") {
      return array;
    }

    if (homeworkStatus === "Aktif") {
      return array.filter((item) => item.status === 1);
    }

    if (homeworkStatus === "Süresi Geçmiş") {
      return array.filter((item) => item.status === 2);
    }

    return array;
  }
  const star = String.fromCharCode(11088);
  return (
    <div className={`${style.cardContainer}`}>
      <div className={`${style.homeworkTrackTable} mt-5 px-4`}>
        <div className="d-flex gap-2">
          <div className={`${style.tableLeftSection}`}>
            <div
              className={`${style.leftSectionTop} d-flex align-items-center justify-content-center mb-3`}
            >
              Öğrenciler
            </div>

            {/* Student Name and Homework Complete Percentage */}
            {studentsOfClass &&
              studentsOfClass.length > 0 &&
              studentsOfClass.map((item) => (
                <div
                  className={`${style.leftSectionBottom} d-flex align-items-center`}
                  key={nanoid()}
                >
                  <StudentProgress
                    studentName={item.full_name}
                    rate={item.homework_completion_rate}
                  />
                </div>
              ))}
          </div>

          <div className={`${style.tableRightSection}`}>
            {/* Homework Titles */}
            <div
              className={`${style.rightSectionTop} d-flex align-items-center`}
            >
              {orderedSelectedHomeworks &&
              orderedSelectedHomeworks.length < 1 ? (
                <span
                  className={`${style.homeworkTitle} d-flex align-items-center justify-content-center w-100`}
                  style={{ borderRadius: "7px" }}
                >
                  Sınıfa atanmış ödev bulunmamaktadır
                </span>
              ) : (
                orderedSelectedHomeworks &&
                orderedSelectedHomeworks.map((item, i) => {
                  return (
                    <div
                      className={`${style.homeworkTitle} d-flex align-items-center justify-content-center`}
                      style={{
                        color: item.status === 1 ? "#fff" : "#c6c6c6",
                        borderRadius: i === 0 && "7px",
                      }}
                      key={nanoid()}
                    >
                      <div className={`${style.titleContainer}`}>
                        <div className={`${style.titleShort}`}>
                          {item.title}
                        </div>
                        {/* <div className={`${style.titleShort}`}>{item.id}</div> */}
                        <div className={`${style.titleFull}`}>{item.title}</div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>

            {/* Homework Complete Status */}
            <div className={`${style.rightSectionBottom} mt-3`}>
              {studentsOfClass &&
                studentsOfClass.length > 0 &&
                studentsOfClass.map((student, studentIndex) => {
                  let homeworks = _.sortBy(student.homeworks, "id").reverse();
                  homeworks = _.sortBy(homeworks, "status");
                  let filteredHomeworks = filtreleArray(
                    homeworks,
                    homeworkStatus
                  );
                  return (
                    <div
                      className={`${style.rightSectionBottom} ${
                        studentIndex % 2 === 0 && style.customBackgroundColor
                      } d-flex align-items-center`}
                      key={nanoid()}
                    >
                      {filteredHomeworks.map((homework, i) => {
                        return (
                          <div
                            className={`${style.studentHomeworkStatus} ${
                              studentIndex % 2 === 0 &&
                              style.customBackgroundColor
                            } d-flex align-items-center justify-content-center`}
                            style={{
                              borderRadius: i === 0 && "7px",
                            }}
                            key={nanoid()}
                          >
                            {`${homework.student_status === 2 ? star : "-"}`}{" "}
                            {/* {homework.id} */}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeworkTracking;
