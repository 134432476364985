import React from "react";
import style from "./FollowingLessons.module.scss";
import Card from "../../../../../components/bootstrap/Card";
import SingleTopic from "./2-SingleTopic";
import { useFollowingLessonsQuery } from "../../../../../store/features/apiSlice";
import { nanoid } from 'nanoid';
import useDarkMode from "../../../../../hooks/useDarkMode";

const FollowingLessons = () => {
  const { darkModeStatus } = useDarkMode();
  const {
    data: followingLessonsData,
  } = useFollowingLessonsQuery();
  const following_lessons =
    followingLessonsData && Object.entries(followingLessonsData);
  return (
    <div className={`${style.followingLessonsContainer} ${darkModeStatus && style.darkMode}`}>
      <Card className={`${style.card} p-5`}>
        <div>
          <span className={`${style.title}`}>Takip Ettiğim Konular</span>
        </div>
        <div className={`${style.topicsContainer}`}>
          {following_lessons?.map((lesson) => (
            <SingleTopic topicName={lesson[0]} rate={lesson[1].percentage} key={nanoid()} id={lesson[1].id}/>
          ))}
        </div>
      </Card>
    </div>
  );
};

export default FollowingLessons;
