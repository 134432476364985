import React, { useEffect, useState } from 'react'
import style from "./Activities.module.scss"
import Card from '../../../../../components/bootstrap/Card'
import FormGroup from '../../../../../components/bootstrap/forms/FormGroup'
import Select from '../../../../../components/bootstrap/forms/Select'
import { useHomeworksOfClassQuery } from '../../teacherApi'
import { formatDate2TurkishShortDate, isDateInLastTenDays } from '../../../../../utils/formatDate'

const Activities = ({ activeClasses }) => {
  const [selectedClassId, setSelectedClassId] = useState()

  const { data: homeworksOfClass } = useHomeworksOfClassQuery(selectedClassId ? selectedClassId : activeClasses[0].id);

  useEffect(() => {
    if (activeClasses && activeClasses.length > 0) {
      setSelectedClassId(activeClasses[0].id)
    }
     //eslint-disable-next-line
  }, [])

  return (
    <Card className={`${style.cardContainer}`}>
      <div className={`${style.titleAndDropdownContainer}`}>
    <div className={`${style.leftSection} pt-2 my-3`}> 
    <span className={`${style.title} ms-5`}>Son 10 günlük çalışmalar</span>
  </div>
  <div className={`${style.classDropdown} pe-5 my-2`}>
      <FormGroup id="state" label="Sınıf" isFloating className={`${style.classDropdownForm}`}>
        <Select
          value={String(selectedClassId)}
          id="state"
          ariaLabel="Sınıf"
          placeholder="Sınıf Seçiniz.."
          list={activeClasses?.map(c => ({ value: c.id, text: c.name }))}
          onChange={(e) => { console.log(e.target.value); setSelectedClassId(e.target.value) }}
          className={`${style.classDropdownSelect}`}
        />
      </FormGroup>
    </div>
      </div>
    <div className={`${style.customTable} px-4`}>
      <div className={`${style.customHeadContainer} d-flex align-items-center my-3 px-2`}>
        <div
          className={`${style.customTableHead1} ${style.customTableHead}`}
        >
          Tarih
        </div>
        <div
          className={`${style.customTableHead2} ${style.customTableHead}`}
        >
          Açıklama
        </div>
      </div>
      <div className={`${style.customRowContainer}`}>
        {homeworksOfClass && homeworksOfClass.length === 0 ? (
          <div className={`${style.emptyTable}`}>
            <span>Kayıtlı aktivite bulunamadı</span>
          </div>
        ) : (
          homeworksOfClass && homeworksOfClass.filter(activite => isDateInLastTenDays(activite.created_date)).map((item, i) => (
            <div
              key={item.id}
              className={`${style.customTableRow} ${
                i % 2 === 0 && style.customBackgroundColor
              } d-flex align-items-center mt-1 px-2`}
            >
              <div className={`${style.customTableColumn1}`}>
                <div>{formatDate2TurkishShortDate(item.created_date)}</div>
              </div>
              <div className={`${style.customTableColumn2}`}>
                 <div className={`${style.titleContainer} cursor-pointer`}>
                  <div className={`${style.titleShort}`}>{item.title}</div>
                  <div className={`${style.titleFull}`}>{item.title}</div>
                    </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  </Card>
  )
}

export default Activities