import React, { useState } from "react";
import { useFormik } from "formik";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../components/bootstrap/Modal";
import Button from "../../../components/bootstrap/Button";
import { OffCanvasTitle } from "../../../components/bootstrap/OffCanvas";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import { Add } from "@mui/icons-material";
import Select from "../../../components/bootstrap/forms/Select";
import Label from "../../../components/bootstrap/forms/Label";

const LearninPathForm = ({savePath}) => {
  const [modalStatus, setModalStatus] = useState(false);
  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = "Başlık giriniz!";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      title: "",
      explanation: "",
      technology: "python",
      award_point: 0,
      order_number:10000,
      access_state: "1",
      state:"1"
    },
    validate,
    onSubmit: async (values, {resetForm}) => {
      // console.log("values-----",values)
     savePath({...values, practices:[]}); 
          resetForm()   
        // console.log("state",values.state)
     setModalStatus(false);
    },
  });

  return (
    <div>
      <Button
        className=""
        color="success"
        shadow="default"
        hoverShadow="none"
        onClick={() => {
          setModalStatus(true);
        }}
      >
        <Add />
        Ders Ekle
      </Button>
      <Modal
        isOpen={modalStatus}
        setIsOpen={setModalStatus}
        titleId="upcomingEdit"
        isCentered
        isScrollable
        size="lg"
      >
        <ModalHeader setIsOpen={setModalStatus}>
          <OffCanvasTitle id="upcomingEdit">Yeni Ders Ekle</OffCanvasTitle>
        </ModalHeader>
        <ModalBody>
          <div className="row g-4">
            <div className="col-12">
              <FormGroup id="title" label="Başlık" isFloating>
                <Input
                  placeholder="Başlık"
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  isValid={formik.isValid}
                  onBlur={formik.handleBlur}
                  isTouched={formik.touched.title}
                  invalidFeedback={formik.errors.title}
                />
              </FormGroup>
            </div>
            <div className="col-12">
              <FormGroup id="explanation" label="Açıklama" isFloating>
                <Input
                  placeholder="Açıklama"
                  onChange={formik.handleChange}
                  value={formik.values.explanation}
                />
              </FormGroup>
            </div>
            <div className="col-12">
            <Label>Program Dili</Label>
            <Select
                        list={[
                          { text: "Python", value: "python" },
                          { text: "JavaScript", value: "javascript" },
                          { text: "CSharp", value: "csharp" },
                          { text: "Html", value: "html" },
                          { text: "CSS", value: "css" },
                        ]}
                        onChange={formik.handleChange}
                        value={formik.values.technology}
                        name="technology"
                        isValid={formik.isValid}
                        onBlur={formik.handleBlur}
                        isTouched={formik.touched.technology}
                        invalidFeedback={formik.errors.technology}
                        ariaLabel={"technology"}
                      />
            </div>
            <div className="col-12">
              <FormGroup id="award_point" label="Ödül Puanı" isFloating>
                <Input
                  placeholder="Ödül Puanı"
                  onChange={formik.handleChange}
                  value={formik.values.award_point}
                  type="number"
                />
              </FormGroup>
            </div>
            <div className="col-12">
            <FormGroup id="order_number" label="Sıra Numarası" isFloating>
                <Input
                  // placeholder="order_number"
                  onChange={formik.handleChange}
                  value={formik.values.order_number}
                  type="number"
                />
              </FormGroup>
            </div>

            <div className="col-12">
            <Label>Erişim Durumu</Label>
            <Select
                  list={[
                    {
                      value: 1,
                      text: "Özel",
                    },
                    {
                      value: 2,
                      text: "Herkese Açık",
                    },
                  ]}
                  onChange={formik.handleChange}
                  value={formik.values.access_state}
                  name="access_state"
                  isValid={formik.isValid}
                  onBlur={formik.handleBlur}
                  isTouched={formik.touched.access_state}
                  invalidFeedback={formik.errors.access_state}
                  ariaLabel={"access_state"}
                />
            </div>
            <div className="col-12">
            <Label>Durum</Label>
            <Select
                  list={[
                    {
                      value: 0,
                      text: "Bilinmiyor",
                    },
                    {
                      value: 1,
                      text: "Aktif",
                    },
                    {
                      value: 2,
                      text: "Pasif",
                    },
                    {
                      value: 3,
                      text: "Taslak",
                    },
                  ]}
                  onChange={formik.handleChange}
                  value={formik.values.state}
                  name="state"
                  isValid={formik.isValid}
                  onBlur={formik.handleBlur}
                  isTouched={formik.touched.state}
                  invalidFeedback={formik.errors.state}
                  ariaLabel={"state"}
                />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="bg-transparent">
          <Button
            color="info"
            className="w-100"
            onClick={async () => {
              formik.handleSubmit();
              let res = await formik.validateForm();
              if(!res.title){
                setModalStatus(false);
              }
            }}
          >
            Kaydet
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default LearninPathForm;
