import React from "react";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import DashboardHeader from "../../common/Headers/DashboardHeader";
import LearningPathGridItem from "../../common/LearningPathGridItem";
import { studentPanel } from "../../../menu";
import LEARNING_PATH_STATE from '../../../common/data/enumLearningPathState';
import style from "./LearningPaths.module.scss"
import _ from "underscore";
import { usePathsQuery } from "../../../store/features/apiSlice";

const LearninnPaths = () => {
  const { data, error } = usePathsQuery();
  const customOrder = [1, 3];
  let paths = data?.filter(item=>item.state !== 2)
  paths = _.sortBy(paths, "order_number")
  paths = _.sortBy(paths, item => customOrder.indexOf(item.state));

  const isRestricted = error?.status === 403;
  
  // useEffect(() => {
  //   if (window.Tawk_API) {
  //     window.Tawk_API.hideWidget();
  //   }
  //   return () => {
  //     if (window.Tawk_API) {
  //       window.Tawk_API.showWidget();
  //     }
  //   };
  // }, []);
  return (
    <PageWrapper title={studentPanel.learningPaths.text}>
      <DashboardHeader/>
      <Page>
        {isRestricted 
        ? <h3 className="text-center mt-5">Bu sayfadaki içeriklere erişiminiz yoktur.</h3> 
        : 
        <div className="row">
          {paths &&
            paths
              .filter(x=> x.state === LEARNING_PATH_STATE.ACTIVE.id || x.state === LEARNING_PATH_STATE.DRAFT.id)
              .map((path) => (
              <div key={path.id} className={`${style.learningPathCard} col-xxl-3 col-xl-4 col-md-6`}>
                <LearningPathGridItem
				          key={path.id}
                  id={path.id}
                  name={path.title}
                  lp_state={path.state}
                  img={"/images/"+path.technology+".png"}
                />
              </div>)
            )}
        </div>
        }
      </Page>
    </PageWrapper>
  );
};

export default LearninnPaths;
