import React from "react";
import style from "./LeaderBoard.module.scss";
import { useParams } from "react-router-dom";
import { useClassLeaderBoardQuery } from "../../../teacher/teacherApi";
import Card from "../../../../../components/bootstrap/Card";
import AVATARS from "../../../../../common/data/avatarsDummy";

const LeaderBoard = () => {
  const { classId } = useParams();
  const { data: classStudents } = useClassLeaderBoardQuery(classId);
  // const classStudents = []
  let userWarning;

  let leader;
  let others;

  if (classStudents && classStudents.length > 0) {
    let _leader = classStudents.find((item) => item.rank === 1);
    let userName = (_leader.first_name && _leader.first_name !== null) ? `${_leader.first_name} ${_leader.last_name}` : _leader.email.split("@")[0]
    leader = {
      id: _leader.id,
      avatar: _leader.avatar,
      email: userName,
      total_points: _leader.points,
    };

    let _others =
      classStudents && classStudents.filter((i) => i.rank !== 1).slice(0, 4);
    others = _others.map((i) => {
      let userName = (i.first_name && i.first_name !== null) ? `${i.first_name} ${i.last_name}` : i.email.split("@")[0]
      return {
        id: i.id,
        rank: i.rank,
        avatar: i.avatar,
        email: userName,
        total_points: i.points,
      };
    });
  }
  return (
    <Card className={`${style.cardContainer}`}>
      <div className={`${style.title} pt-2 my-2`}>
        <span className={`ms-5`}>Sınıf Liderleri</span>
      </div>
      <div className={`${style.leaderBoardContainer} d-flex px-5`}>
        <div>
          <div style={{ height: "36px" }}></div>
          <div
            className={`${style.leaderSection} d-flex flex-column justify-content-center align-items-center`}
          >
            <div>1.</div>
            <div className={`${style.userAvatar}`}>
              <img
                src={
                  leader && leader.avatar && leader.avatar !== "null"
                    ? leader.avatar
                    : AVATARS.defaultAvatarSmall
                }
                alt=""
              />
            </div>
            <div>{leader && leader.email}</div>
            <div>{leader && leader.total_points}</div>
          </div>
        </div>
        <div className={`${style.customTable}`}>
          <div
            className={`${style.customHeadContainer} d-flex align-items-center justify-content-between my-1 px-2`}
          >
            <div
              className={`${style.customTableHead1} ${style.customTableHead}`}
            >
              Ad Soyad
            </div>
            <div
              className={`${style.customTableHead2} ${style.customTableHead}`}
            >
              Puan
            </div>
          </div>
          <div className={`${style.customRowContainer}`}>
            {others && others.length === 0 ? (
              <div>
                <span>Henüz sıralama oluşmadı</span>
              </div>
            ) : (
              others &&
              others.map((student) => {
                const { rank, id, email, total_points, avatar } = student;
                const isUserInfo = email === null;
                const userInfo = <span>{email}</span>;
                userWarning = (
                  <span className="text-danger">
                    Ad soyad bilgisi gereklidir.
                  </span>
                );
                return (
                  <div
                    key={id}
                    className={`${style.customTableRow} mt-3 px-2 d-flex align-items-center`}
                  >
                    <div
                      className={`${style.customTableColumn1} d-flex align-items-center justify-content-between`}
                    >
                      <div className="d-flex align-items-center gap-2">
                        <div className={`${style.userRank}`}>{rank}</div>
                        <div className={`${style.userAvatar}`}>
                          <img
                            src={
                              avatar && avatar !== "null"
                                ? avatar
                                : AVATARS.defaultAvatarSmall
                            }
                            alt=""
                          />
                        </div>
                        <div className={`${style.userInfo}`}>
                          {!isUserInfo ? userInfo : userWarning}
                        </div>
                      </div>
                      <div className={`${style.userPoints}`}>
                        {total_points}
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default LeaderBoard;
