import React, { useEffect, useState } from "react";
import style from "./ActivationCodeListForSchool.module.scss"
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
} from "../../../../../../components/bootstrap/Modal";
import Logo from "../../../../../../components/Logo";
import Icon from "../../../../../../components/icon/Icon";
import USER_ROLES from "../../../../../../common/data/enumUserRole";
import { get } from "../../../../../../utils/service";
import FormGroup from "../../../../../../components/bootstrap/forms/FormGroup";
import Select from "../../../../../../components/bootstrap/forms/Select";
import { useGetActivationKeyCreateDatesOfSchoolForAdminQuery } from "../../../../../../store/features/apiSlice";
import _ from "underscore";
import Button from "../../../../../../components/bootstrap/Button";
import { downloadExcel } from "../../../../../../common/downloadExcel";

const ShowActivationKeysModalForSchool = ({
  isOpenActivationKeysModal,
  setIsOpenActivationKeysModal,
  users,
}) => {
  const role = localStorage.getItem("roles");
  const isAdmin = Number(role) === USER_ROLES.ADMIN.id;
  const schoolId = localStorage.getItem("schoolId")
  const [activationKeys, setActivationKeys] = useState(null);
  const [activeDate, setActiveDate] = useState("Tamamı");
  const {data}=useGetActivationKeyCreateDatesOfSchoolForAdminQuery(schoolId, {skip:!isAdmin})
  const sortedData = (data && data.length > 0) && _.sortBy(data).reverse() 
const dates = ["Tamamı"].concat(sortedData)
const dateParameter = activeDate !== "Tamamı" && `created_date=${activeDate}`

const getActivationKeys = async() => {
  if(isAdmin){
    get(`schools/${schoolId}/activation_key/txt/?${dateParameter}`).then(
      (res)=>{
        if (res.status === 204 || res.status === 200){
          setActivationKeys(res.data)
        }else{
          console.log("hata");
        }
      }
    )
  }
  }
  useEffect(() => {
      getActivationKeys()
      //eslint-disable-next-line
  }, [activeDate, users])

  const dataItems = activationKeys?.split(',');
  function DataList({ items }) {
    return (
      <div className={`${style.flexContainer}`}>
        {items.map((item, index) => (
          <div className={`${style.flexItem}`} key={index}>{item}</div>
        ))}
      </div>
    );
  }
console.log("dataItems",dataItems);
  const baseUrl = process.env.REACT_APP_CENTRAL_API_URL;

function getToken(){
  let token = localStorage.getItem('token');
  if(token){
     return JSON.parse(token);
  }
}

const token = getToken()

const downloadText = async () => {
  try {
    let res = await fetch(baseUrl + `schools/${schoolId}/activation_key/txt/?${dateParameter}`,{
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`
        }
    });
    const text = await res.text(); // Metin olarak al

    // Bir Blob oluştur
    const blob = new Blob([text], { type: 'text/plain' });

    // Blob için bir URL oluştur
    const downloadUrl = window.URL.createObjectURL(blob);

    // `<a>` elementi oluştur ve indirme işlemini tetikle
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = "fileName";
    document.body.appendChild(link);
    link.click();

    // Temizlik yap
    link.remove();
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error('Dosya indirilirken bir hata oluştu', error);
  }
};
const handleReport = () => {
  const data = dataItems.map((item, index) => ({
    username: item.trim(),
  }));
  const headers = [
    { key: "username", label: "Aktivasyon Kodu" },
  ];
  const fileName = "Aktivasyon_kod_listesi.xlsx";
  downloadExcel(data, headers, fileName);
};
  
  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenActivationKeysModal}
      setIsOpen={setIsOpenActivationKeysModal}
      titleId="example-title"
      size={"xl"}
      fullScreen={true}
    >
      <ModalHeader setIsOpen={setIsOpenActivationKeysModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} />{" "}
          <span className="ps-2">Aktif Kodlar</span>
          <span className="ps-2">
            <Icon icon="message" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
      <div className="d-flex justify-content-end gap-2">
      <Button
            className={`${style.messageButton} btn`}
            color="primary"
            onClick={() => handleReport()}
          >
            Excel olarak indir
          </Button>
      <Button
            className={`${style.messageButton} btn`}
            color="primary"
            onClick={downloadText}
          >
            Seçili Kodları İndir
          </Button>

        <div className={`pe-5`}>
          <FormGroup id="state" label="Oluşturma Tarihi" isFloating>
            <Select
              value={activeDate ?? ""}
              id="state"
              ariaLabel="Sınıf"
              placeholder="Sınıf Seçiniz.."
              list={dates?.map((date) => ({ value: date, text: date }))}
              onChange={(e) => {
                setActiveDate(e.target.value);
              }}
            />
          </FormGroup>
        </div>
      </div>
        <div className="d-flex justify-content-center">
          <div className="col-lg-12 mt-3">
            {/* <div>{activationKeys}</div> */}
            <DataList items={dataItems} />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ShowActivationKeysModalForSchool;

