import React, { useEffect, useState } from "react";
import { CardBody } from "../../../../../components/bootstrap/Card";
import Button from "../../../../../components/bootstrap/Button";
import { Delete, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Icon from "../../../../../components/icon/Icon";
import Confirm from "../../../../common/Confirm";
import showNotification from "../../../../../components/extras/showNotification";
import { useNavigate } from "react-router-dom";
import CreateCourseModal from "./3-CreateCourseModal";
import { useCoursesTeacherQuery, useDeleteCourseTeacherMutation } from "../teacherEducationApi";
import useSortableData from "../../../../../hooks/useSortableData";
import { TEACHER_COURSE_STATUS } from "../../../../../common/data/enumTeacherEducationCourseState";

const rowStyle ={
  cursor: 'pointer'
}


const CourseListTable = () => {
  const navigate = useNavigate();
  const { data} = useCoursesTeacherQuery();
  const [deleteCourse] = useDeleteCourseTeacherMutation();

  const [search, setSearch] = useState("");
  const [filteredCourses, setFilteredCourses] = useState("");

  const { items} = useSortableData(
    filteredCourses ? filteredCourses : data ? data : []
  );

  const filterCourse = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const newCourses =
      data &&
      data.filter(
        (item) => item.title && item.title.toLowerCase().includes(search)
      );
    setFilteredCourses(newCourses);
  };

  useEffect(() => {
    filterCourse();
    //eslint-disable-next-line
  }, [search, data]);

  const [isOpenNewCourseModal, setIsOpenNewCourseModal] = useState(false);
  const [courseId, setCourseId] = useState(null);
  const [open, setOpen] = useState(false);

  const addCourse = () => {
    setIsOpenNewCourseModal(true);
  };

  //! delete course--------
  const removeCourse = (e) => {
    setOpen(true);
    setCourseId(e.currentTarget.id && e.currentTarget.id);
  };
  const handleDelete = async () => {
    const response = await deleteCourse(courseId);
    setOpen(false);
    if (response.error) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Silme İşlemi Başarısız</span>
        </span>,
        "Bir hata meydana geldi",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Silme Başarılı</span>
        </span>,
        "Ders başarıyla silindi.",
        "success"
      );
    }
  };
  const handleCancel = () => setOpen(false);
  //!-----------------------------------------------

  return (
    <>
      <CreateCourseModal
        isOpenNewCourseModal={isOpenNewCourseModal}
        setIsOpenNewCourseModal={setIsOpenNewCourseModal}
      />
      <Confirm
        open={open}
        setOpen={setOpen}
        onConfirm={handleDelete}
        onCancel={handleCancel}
      />
      <div className="row">
        <div className="col-3">
          <Button
            color="info"
            className={`ms-5 mb-4 shadow-3d-up-hover}`}
            onClick={() => addCourse()}
          >
            Yeni Ders Ekle
          </Button>
        </div>
        <div className="col-6">
          <form className="d-flex gap-1" onSubmit={filterCourse}>
            <input
              className="form-control"
              type="search"
              placeholder="Aramak istediğiniz dersi yazınız"
              value={search}
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
            />
            <button className="btn btn-warning w-25">Bul</button>
          </form>
        </div>
      </div>
      <CardBody>
        <table className="table table-modern">
          <thead style={{ fontSize: "16px" }}>
            <tr>
              <th>Başlık</th>
              <th>Açıklama/Not</th>
              <th style={{ textAlign: "center" }}>Sırası</th>
              <th className="text-center">Aktiflik</th>
              <th className="text-center">Sil</th>
              <th style={{ textAlign: "center" }}>Ders Detayı</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "16px" }}>
            {(items && items.length > 0)
              ? items.map((course) => {
                  const {
                    id,
                    title,
                    description,
                    order_number,
                    status,
                  } = course;
                  return (
                    <tr key={id}>
                      <td style={rowStyle} onClick={() => navigate(`/admin/teacherUpdateCourse/${id}`)}>{title}</td>
                      <td> {description}</td>
                      <td style={{ textAlign: "center" }}>{order_number}</td>
                      <td className="text-center">
                        {status === TEACHER_COURSE_STATUS.ACTIVE.id
                          ? "Aktif"
                          : status === TEACHER_COURSE_STATUS.DRAFT.id
                          ? "Taslak"
                          : "Pasif"}
                      </td>
                      <td className="text-center">
                        <IconButton id={id} onClick={(e) => removeCourse(e)}>
                          <Delete
                            className="fs-2"
                          />
                        </IconButton>
                      </td>
                      <td className="text-center">
                        <IconButton  id={id} onClick={() => navigate(`/admin/teacherUpdateCourse/${id}`)}>
                          <Edit
                            className="fs-2"
                          />
                        </IconButton>
                      </td>
                    </tr>
                  );
                }) : <tr><td>Ders bulunamadı</td></tr>
            }
          </tbody>
        </table>
      </CardBody>
    </>
  );
};

export default CourseListTable;
