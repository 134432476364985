import React, { useEffect, useState} from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../../components/bootstrap/Modal";
import Logo from "../../../../../components/Logo";
import Icon from "../../../../../components/icon/Icon";
import Button from "../../../../../components/bootstrap/Button";
import Input from "../../../../../components/bootstrap/forms/Input";
import { useFormik } from "formik";
import {
  useClearCacheMutation,
  useUpdateCourseMutation,
} from "../../../../../store/features/apiSlice";
import showNotification from "../../../../../components/extras/showNotification";
import _ from "underscore";
import Card, { CardBody, CardHeader } from "../../../../../components/bootstrap/Card";
import Checks from "../../../../../components/bootstrap/forms/Checks";
import { useUpdateSchoolCourseMutation } from "../../schoolManagerApi";
import USER_ROLES from "../../../../../common/data/enumUserRole";
import { ContentService } from "../../../admin/course management/h5p/services/ContentService";
import CustomFormField from "../../../../../common/customFormField/CustomFormField";

const UpdateLessonModal = ({ isOpenUpdateLessonModal, setIsOpenUpdateLessonModal, data, chapterId, currentChapter, currentLesson}) => {
  const role = localStorage.getItem("roles");
  const isAdmin = Number(role) === USER_ROLES.ADMIN.id;
  const [updateCourse] = useUpdateSchoolCourseMutation();
  const [adminUpdateCourse] = useUpdateCourseMutation();
  const [clearCache] = useClearCacheMutation();
  const [contents,setContents] = useState([]);
  const [searchValue,setSearchValue] = useState('');
  const [filteredContents,setFilteredContents] = useState([]);

  const contentService = new ContentService('/h5p');
  useEffect(() => {
    async function getContent(){
      const contentList = await contentService.list();
      console.log('%c6-UpdateLessonModal.js line:40 contentList', 'color: white; background-color: #007acc;', contentList);
      setContents(contentList.map(m=>{
        return {
          content_id:m.contentId,
          mainLibrary : m.mainLibrary,
          title: m.title,
        }
      }));
    }
    getContent();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (chapterId) {
        formik.setFieldValue("chapter", chapterId);
    }
    //eslint-disable-next-line
  }, [chapterId]);

  useEffect(() => {
    if (currentLesson) {
        console.log('%c6-UpdateLessonModal.js line:52 currentLesson', 'color: white; background-color: #007acc;', currentLesson);
        console.log('%c6-UpdateLessonModal.js line:54 currentLesson.content_id', 'color: white; background-color: #007acc;', currentLesson.content_id);
        formik.setFieldValue("title", currentLesson.title);
        formik.setFieldValue("order_number", currentLesson.order_number);
        formik.setFieldValue("description", currentLesson.description);
        formik.setFieldValue("video", currentLesson.video);
        formik.setFieldValue("subscription_type", currentLesson.subscription_type);
        formik.setFieldValue("source", currentLesson.source);
        formik.setFieldValue("chapter", currentLesson.chapter);
        formik.setFieldValue("content_id", currentLesson.content_id);
    }
    //eslint-disable-next-line
  }, [currentLesson]);

  const validate = (values) => {

    const errors = {};

    if (!values.title) {
      errors.title = "Konunun adını giriniz";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      title: "",
      order_number: 9999,
      description: null,
      video:"",
      subscription_type:3,
      points:5,
      source:null,
      chapter:chapterId
    },
    validate,
    onSubmit: () => {
      handleLesson();
    },
  });
  const handleCheckboxClick = (event) => {
    const { name, value, checked } = event.target;
  
    if (name === "content_id" && checked) {
      formik.setFieldValue(name, value);
    } else if (name === "content_id" && !checked) {
      formik.setFieldValue(name, null);
    }
  };
  const handleLesson = async()=>{

    const { ...lessonValues } = formik.values;
    
    let updatedLessons = currentChapter && currentChapter.lessons.map((lesson)=>lesson.title === currentLesson.title ? {...lesson, ...lessonValues}:lesson);    
    updatedLessons = _.sortBy(updatedLessons,"order_number") 
    let updatedChapter = currentChapter && {...currentChapter,lessons:updatedLessons}
    let newChapters = data && data.chapters.map((chapter)=>chapter.title === currentChapter.title ? {...chapter, ...updatedChapter}:chapter);   
          const response =isAdmin ? await adminUpdateCourse(
            { id:data.id,
             title: data.title,
             ...data,
             chapters: newChapters,}
           ) : await updateCourse(
           { id:data.id,
            title: data.title,
            ...data,
            chapters: newChapters,}
          );
          if (response.error) {
            showNotification(
              <span className="d-flex align-items-center">
                <Icon icon="danger" size="lg" className="me-1" />
                <span>Kayıt Başarısız</span>
              </span>,
              "Bir hata oluştu",
              "danger"
            );
          } else {
            showNotification(
              <span className="d-flex align-items-center">
                <Icon icon="Info" size="lg" className="me-1" />
                <span>Kayıt Başarılı</span>
              </span>,
              "Konu başarıyla düzenlendi.",
              "success"
            );
          }
          clearCache();
          setIsOpenUpdateLessonModal(false);
          formik.resetForm();
        }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); 
      saveForm(); 
    }
  };
  const saveForm = async () => {
    try {
      await formik.handleSubmit();
      setIsOpenUpdateLessonModal(false); 
    } catch (error) {
      
    }
  };

  useEffect(() => {
    if(searchValue){
      setFilteredContents(contents.filter(content=>content.title.toLowerCase().includes(searchValue.toLowerCase())));
    }
  },[contents,searchValue])
  
  const _contents = searchValue ? filteredContents : contents;
  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenUpdateLessonModal}
      setIsOpen={setIsOpenUpdateLessonModal}
      titleId="example-title"
      size={null}
      onKeyDown={handleKeyDown} 
    >
      <ModalHeader setIsOpen={setIsOpenUpdateLessonModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} /> <span className="ps-2">Konu Düzenle</span>
          <span className="ps-2">
            <Icon icon="Computer" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="row gap-3 d-flex justify-content-center">
          <div className="col-lg-8">
          <CustomFormField
              type="text"
              label="Başlık"
              name={"title"}
              formik={formik}
            />
          </div>
          <div className="col-lg-8">
          <CustomFormField
              type="text"
              label="Sıra Numarası"
              name={"order_number"}
              formik={formik}
            />
          </div>
          <div className="col-lg-8">
          <CustomFormField
              type="textArea"
              label="Açıklama"
              name={"description"}
              formik={formik}
              style={{ height: "150px" }}
            />
          </div>
          {isAdmin && (
            <div
              style={{ overflow: "scroll", height: "250px" }}
              className="col-lg-8"
            >
              <Card className="">
                <CardHeader>
                  <Input
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    type="text"
                    placeholder="İçeriklerde Ara.."
                  />
                </CardHeader>
                <CardBody>
                  {
                    <table className="table table-modern">
                      <tbody>
                        {_contents?.map((item) => (
                          <tr key={item.content_id}>
                            <td>
                              <Checks
                                id={item.content_id.toString()}
                                name="content_id"
                                value={item.content_id}
                                onChange={handleCheckboxClick}
                                checked={
                                  formik.values.content_id === item.content_id
                                }
                              />
                            </td>
                            <td style={{ textAlign: "justify" }}>
                              {item.title}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  }
                </CardBody>
              </Card>
            </div>
          )}
          <div className="col-lg-8">
          <CustomFormField
              type="text"
              label="Ders Ekle"
              name={"video"}
              formik={formik}
            />
          </div>
        </div>
      </ModalBody>
        <ModalFooter>
          <Button
            icon="DoneOutline"
            color="success"
            isLight
            onClick={async () => {
              formik.handleSubmit();
            }}
          >
            Kaydet
          </Button>
        </ModalFooter>
    </Modal>
  );
};

export default UpdateLessonModal;
