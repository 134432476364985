export function getSelectedCompetitions(
    competitions,
    competitionStatus,
    setFilteredCompetitions
  ) {
    let newCompetitions;
  
    if (competitions) {
      if (competitionStatus === "Tamamı") {
        setFilteredCompetitions(competitions);
      }
      if (competitionStatus === "Gelecek") {
        newCompetitions = competitions.filter((item) => item.status === 3);
        setFilteredCompetitions(
          newCompetitions.length > 0 ? newCompetitions : []
        );
      }
      if (competitionStatus === "Aktif") {
        newCompetitions = competitions.filter((item) => item.status === 1);
        setFilteredCompetitions(
          newCompetitions.length > 0 ? newCompetitions : []
        );
      }
      if (competitionStatus === "Pasif") {
        newCompetitions = competitions.filter((item) => item.status === 2);
        setFilteredCompetitions(
          newCompetitions.length > 0 ? newCompetitions : []
        );
      }
    }
  }

  
  const competitionStatusData = {
    0: {
      color: "info",
      text: "Gelecek",
    },
    1: {
      color: "success",
      text: "Aktif",
    },
    2: {
      color: "danger",
      text: "Geçmiş",
    },
  };
  const competitionTypeData = {
    0: {
      color: "info",
      text: "Alıştırma",
    },
    1: {
      color: "success",
      text: "Proje",
    },
  };
  
  export function getCompetitionStatus(status) {
    return competitionStatusData[status]?.text || competitionStatusData[0]?.text;
  }
  export function getCompetitionStatusColor(status) {
    return competitionStatusData[status]?.color || competitionStatusData[0]?.color;
  }
  export function getCompetitionTypeColor(status) {
    return competitionTypeData[status]?.color || competitionTypeData[0]?.color;
  }