import React, { useState } from 'react'
import style from "./BannerManagementForSchool.module.scss"
import studentLogoPrimary from "../../../../assets/dashboardImages/studentLogoPrimary.svg";
import studentLogoWhite from "../../../../assets/dashboardImages/studentLogoWhite.svg";
import teacherLogoPrimary from "../../../../assets/school/teacherLogoPrimary.svg";
import teacherLogoWhite from "../../../../assets/school/teacherLogoWhite.svg";
import BannerPage from './contents/banner/BannerPage';
import AnnouncementPage from './contents/announcement/AnnouncementPage';

const BannerManagementForSchool = () => {
    const _activeTab = sessionStorage.getItem("userListActiveTab");
    const [activeTab, setActiveTab] = useState(Number(_activeTab) || 1);

    const tabContents = [
      {
        content: <BannerPage/>,
        tabName: "Banner",
        icon1: teacherLogoPrimary,
        icon2: teacherLogoWhite,
      },
      {
        content: <AnnouncementPage/>,
        tabName: "Duyuru",
        icon1: studentLogoPrimary,
        icon2: studentLogoWhite,
      },
    ];
  
    const handleTabClick = (tabNumber) => {
      if (tabNumber >= 1 && tabNumber <= tabContents.length) {
      sessionStorage.setItem("userListActiveTab", tabNumber);
      setActiveTab(tabNumber);}
    };
  return (
      <>
      <div className="h-100 mt-3 mx-lg-3">
        <div
          className={`${style.tabContainer} d-flex justify-content-evenly align-items-center mx-auto mt-2 mb-5 px-3 gap-1`}
          // style={{maxWidth:isAdmin && "450px"}}
        >
          {tabContents.map((tab, index) => (
            <div
              key={index}
              className={`${style.tab} ${
                activeTab === index + 1 ? `${style.activetab}` : ""
              } d-flex justify-content-center align-items-center gap-2 p-3`}
              onClick={() => handleTabClick(index + 1)}
            >
              <div className={`${style.tabsLogo}`}>
                <img
                  src={activeTab === index + 1 ? tab.icon2 : tab.icon1}
                  alt=""
                />
              </div>
              <div className={`${style.tabsDescription}`}>
                <span>{tab.tabName}</span>
              </div>
            </div>
          ))}
        </div>
         <div className="tab-content">
            {tabContents[activeTab - 1] && tabContents[activeTab - 1]?.content}
          </div>
        </div>
      </>
  )
}

export default BannerManagementForSchool