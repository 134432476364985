import React, {useState } from "react";
import style from "./Banner.module.scss";
import Button from "../../../../../../components/bootstrap/Button";
import {
  useCreateSchoolBannerMutation,
  useDeleteSchoolBannerMutation,
  useGetSchoolBannerQuery,
  useUpdateSchoolBannerMutation,
} from "../../../schoolManagerApi";
import Confirm from "../../../../../common/Confirm";
import showNotification from "../../../../../../components/extras/showNotification";
import Icon from "../../../../../../components/icon/Icon";
import BannerModal from "./BannerModal";

const BannerPage = () => {
  const { data = "", error, isLoading} = useGetSchoolBannerQuery();
  const [addBanner] = useCreateSchoolBannerMutation();
  const [updateBanner] = useUpdateSchoolBannerMutation();
  const [deleteBanner] = useDeleteSchoolBannerMutation();
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isBanner, setIsBanner] = useState(false);

  const removeBanner = (e) => {
    setOpen(true);
  };
  const handleDelete = async () => {
    const response = await deleteBanner();
    setOpen(false);
    if (response.error) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Silme İşlemi Başarısız</span>
        </span>,
        "Bir hata meydana geldi",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Silme Başarılı</span>
        </span>,
        "Banner başarıyla silindi.",
        "success"
      );
    }
  };
  const handleCancel = () => setOpen(false);
  return (
    <>
      <BannerModal isOpen={isOpen} setIsOpen={setIsOpen} isBanner={isBanner} addBanner={addBanner} updateBanner={updateBanner}/>
      <Confirm
        open={open}
        setOpen={setOpen}
        onConfirm={handleDelete}
        onCancel={handleCancel}
      />
      <div>
        {(!data || error) ? (
          <Button
            className="bg-info text-light my-2"
            onClick={() => setIsOpen(true)}
          >
            Banner Ekle
          </Button>
        ) : (
          <div className="d-flex gap-2">
            <Button className="bg-info text-light my-2" onClick={() => {setIsBanner(true); setIsOpen(true)}}>Güncelle</Button>{" "}
            <Button className="bg-danger text-light my-2" onClick={() => removeBanner()}>Sil</Button>
          </div>
        )}
        <div className={`${style.bannerContainer}`}>
          {isLoading ? (
            <div>Loading...</div>
          ) : (data && !error) ? (
            <img src={data} alt="Banner Alanı" />
          ) : (
            <div className={`${style.bannerArea}`}>
              <p>Afişiniz Burada Gözükecek</p>
              <span>Afişinizin daha iyi görünmesi için yüklediğiniz resmin boyutlarını 1178/400 olarak ayarlayınız</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BannerPage;
