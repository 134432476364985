import React, { useEffect, useState } from "react";
import PageWrapper from "../../../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../../../layout/Page/Page";
import style from "./TeacherDocuments.module.scss";
import Search from "../../../class/search";
import useSortableData from "../../../../../../hooks/useSortableData";
import { nanoid } from "nanoid";
import { useDocumentsForTeacherQuery } from "../../../../admin/course management for teacher/teacherEducationApi";
import CertificateInfo from "./CertificateInfo";

const TeacherDocuments = () => {
  const { data:teacherDocuments } = useDocumentsForTeacherQuery();
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (newValue) => {
    setSearchValue(newValue);
  };
  const { items } = useSortableData(
    filteredDocuments
      ? filteredDocuments
      : teacherDocuments
      ? teacherDocuments
      : []
  );

  const filterDocuments = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const newDocuments =
      teacherDocuments &&
      teacherDocuments.filter(
        (item) => item.title && item.title.toLowerCase().includes(searchValue)
      );
    setFilteredDocuments(newDocuments);
  };

  //effects
  useEffect(() => {
    filterDocuments();
    //eslint-disable-next-line
  }, [searchValue]);

  return (
    <PageWrapper>
      <Page className={`${style.pageContainer} `}>
        <div className={`${style.actionsContainer} px-2 mx-4 gap-3 mx-auto`}>
          <div className={`${style.inputContainer} `}>
            <Search
              handleSearchChange={handleSearchChange}
              iconColor={"#5454544F"}
            />
          </div>
          <div
            className={`${style.addClassContainer} d-flex align-items-center justify-content-center`}
          >
            <span>Ara</span>
          </div>
        </div>
        <div
          className={`${style.documentsContainer} ${style.certificates} d-flex align-items-center justify-content-center flex-wrap gap-4 mt-4`}
        >
          {items.length === 0 ? (
            <div>
              <span>Kayıtlı doküman bulunamadı</span>
            </div>
          ) : (
            items.map((item, i) => (
                <CertificateInfo
                  certificateData={item}
                  key={nanoid()}
                />
              ))
          )}
        </div>
      </Page>
    </PageWrapper>
  );
};

export default TeacherDocuments;
