import React from "react";
import style from "../ParentReportWithClass.module.scss";
import AttendanceInfo from "./AttendanceInfo";
import { nanoid } from "nanoid";
import ChartInfo from "./ChartInfo";
import { Card } from "react-bootstrap";

const LiveLectureAttendance = ({ data }) => {
  const attendances = data.live_lecture_attendances;
  const value = data.live_lecture_completion_rate
  return (
    <Card className={`${style.liveLectureAttendanceContainer} p-3 px-5`}>
      <div className={`${style.title} my-3 px-3`}>Canlı Ders Katılım Durumu</div>
      <div className="d-flex">
        <div className={`${style.attendanceContainer}`}>
          {attendances?.map((attendance) => (
            <AttendanceInfo key={nanoid()} attendance={attendance} />
          ))}
        </div>
        <div className={`${style.chartDiv}`}>
          <ChartInfo value={value}/>
        </div>
      </div>
    </Card>
  );
};

export default LiveLectureAttendance;
