import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Button from "../../../components/bootstrap/Button";
import Card, {
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../components/bootstrap/Card";
import {
  useConfirmVerificationCodeForParentMutation,
  useCreateVerificationCodeForParentMutation,
  useGetParentInformationQuery,
  useUpdateParentInformationMutation,
} from "../../../store/features/apiSlice";
import { showError, showSuccess } from "./utils";
import CustomFormField from "../../../common/customFormField/CustomFormField";
import {
  getCustomErrorMessage,
  handleErrorMessage,
} from "../../../utils/customErrors";
import Modal, {
  ModalBody,
} from "../../../components/bootstrap/Modal";
import SvgClose from "../../../components/icon/material-icons/Close";

const ParentInformationSystem = () => {
  const [open, setOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(300);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [smsVerificationCode, setSmsVerificationCode] = useState("");
  const userId = localStorage.getItem("id");
  const { data: parentSmsInformation, isSuccess: smsInformationSuccess } =
    useGetParentInformationQuery(userId);
  const [createVerificationCode] = useCreateVerificationCodeForParentMutation();
  const [updateParentInformation] = useUpdateParentInformationMutation();
  const [confirmVerificationCode] =
    useConfirmVerificationCodeForParentMutation();
  const isParentSmsInformation =
    parentSmsInformation && parentSmsInformation.parent_information_system;
  const ParentPhoneNumber =
    parentSmsInformation && parentSmsInformation.parent_telephone_number;
  useEffect(() => {
    if (smsInformationSuccess) {
      formik.setFieldValue("parent_information_system", isParentSmsInformation);
      formik.setFieldValue("parent_telephone_number", ParentPhoneNumber);
    }
    //eslint-disable-next-line
  }, [smsInformationSuccess]);
  useEffect(() => {
    let timer;
    if (isCodeSent && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      clearInterval(timer);
      // Süre bittiğinde yapılacak işlemler
    }
    return () => clearInterval(timer); // Temizleme işlemi
  }, [isCodeSent, timeLeft]);

  async function confirmNumber() {
    let smsCode = smsVerificationCode;
    if (smsVerificationCode.length === 0) {
      alert("kodu giriniz");
    } else {
      const response = await confirmVerificationCode({
        id: userId,
        verification_code: smsCode,
      });
      if (response.error) {
        showError();
      } else {
        showSuccess("Telefon numarası başarıyla eklendi");
        setTimeLeft(300);
        setIsCodeSent(false);
      }
    }
  }

  const validate = (values) => {
    const errors = {};
    if (
      !values.parent_telephone_number ||
      values.parent_telephone_number.includes("_")
    ) {
      errors.parent_telephone_number = "Veli telefon numarasını yazınız";
    }
    if (
      (!values.parent_telephone_number ||
        values.parent_telephone_number.includes("_")) &&
      values.parentReport
    ) {
      errors.parent_telephone_number = "Veli telefon numarasını yazınız";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      id: userId,
      parent_telephone_number: ParentPhoneNumber || "",
      parent_information_system: isParentSmsInformation || false,
    },
    validate,
    onSubmit: async (values) => {
      const response = await createVerificationCode(
        values.parent_telephone_number
      );
      if (response.error) {
        showError();
      } else {
        setIsCodeSent(true);
        setTimeLeft(300);
      }
    },
  });
  const handleCheckboxChange = async (e) => {
    formik.setFieldValue(e.target.name, e.target.checked);
    const response = await updateParentInformation({
      id: userId,
      parent_information_system: e.target.checked,
    });
    // console.log(response);
    if (response.error) {
      const customError = handleErrorMessage(response.error.data);
      showError(
        <span>{getCustomErrorMessage(customError, "Kayıt Başarısız")}</span>
      );
      formik.setFieldValue(e.target.name, false);
    } else {
      if (response.data.parent_information_system) {
        setOpen(true);
      }
    }
  };

  const handleCancel = () => setOpen(false);
  return (
    <>
      <UserInfoModal open={open} setOpen={setOpen} onCancel={handleCancel} />
      <Card stretch tag="form" noValidate onSubmit={formik.handleSubmit}>
        <CardHeader>
          <CardLabel icon="Contacts" iconColor="info">
            <CardTitle>Veli Bilgilendirme Sistemi</CardTitle>
          </CardLabel>
        </CardHeader>
        <CardBody className="pb-0" isScrollable>
          <div className="row g-4">
            <>
              <div className="col-lg-8 d-flex align-items-center justify-content-between gap-2 flex-wrap">
                <div className="d-flex align-items-start gap-1">
                  <CustomFormField
                    type="text"
                    label="Veli Telefon Numarası"
                    name={"parent_telephone_number"}
                    formik={formik}
                    mask="999 999 99 99"
                  />
                  <Button
                    type="submit"
                    className="mt-2"
                    color="info"
                    isDisable={
                      (!formik.isValid && !!formik.submitCount) ||
                      (isCodeSent && isParentSmsInformation) ||
                      (!ParentPhoneNumber && isParentSmsInformation)
                    }
                  >
                    {isCodeSent ? `Yeniden Gönder (${timeLeft})` : "SMS Gönder"}
                  </Button>
                </div>
                {isCodeSent && timeLeft > 0 && (
                  <div className="d-flex gap-2">
                    <input
                      type="text"
                      placeholder="Kodu giriniz"
                      value={smsVerificationCode}
                      onChange={(e) => setSmsVerificationCode(e.target.value)}
                      style={{ width: "100px" }}
                    />{" "}
                    <Button color="info" onClick={confirmNumber}>
                      Tamam
                    </Button>
                  </div>
                )}
              </div>
              {ParentPhoneNumber && (
                <div className="ps-2 d-flex">
                  <input
                    type="checkbox"
                    name="parent_information_system"
                    checked={formik.values.parent_information_system}
                    onChange={handleCheckboxChange}
                  />
                  <span className="ps-2">
                    Veli Bilgilendirme Sistemini Aktive Et
                  </span>
                </div>
              )}
              <p>
                * Aylık veli bilgilendirme raporu almak için lütfen telefon
                numaranızı girip doğrulama işlemini tamamlayınız.
              </p>
              <p>
                * Ardından aylık öğrenci raporu almak isterseniz "Veli
                Bilgilendirme Sistemini Aktive Et" seçeneğini işaretleyiniz.
              </p>
              <p>
                * Eğer aylık öğrenci raporu almak istemiyorsanız "Veli
                Bilgilendirme Sistemini Aktive Et" işaretini kaldırınız.
              </p>
            </>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
export default ParentInformationSystem;

export const UserInfoModal = ({ open, setOpen }) => {
  return (
    <div>
      <Modal isOpen={open} setIsOpen={setOpen} titleId="example-title" size={null} isCentered>
        <ModalBody>
          <div className="d-flex justify-content-end fs-5">
        <SvgClose style={{transform:"scale(1.5)", cursor:"pointer"}} onClick={()=>setOpen(false)}/>
          </div>
          <div className="mx-auto text-center py-3 px-5" style={{fontWeight:"500", fontFamily:"Poppins"}}>
            <p style={{ fontSize: "16px" }}>
              Merhaba Sevgili Öğrencimiz;
            </p>
            <p style={{ fontSize: "16px" }}>
              Veli telefon numaranız başarılı bir şekilde eklenmiştir.
            </p>
            <p style={{ fontSize: "16px" }}>
              Her ayın 28'inde vermiş olduğunuz veli telefon numarasına aylık ders raporunuz gönderilecektir.
            </p>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
