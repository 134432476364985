import React, { useEffect, useState } from "react";
import RestrictionCard from "../components/RestrictionCard";
import {
  useGetSchoolRestrictionsQuery,
  useUpdateSchoolRestrictionsMutation,
} from "../restrictionApi";
import Icon from "../../../../../../components/icon/Icon";
import Confirm from "../../../../../common/Confirm";

const CodelabAndGamesList = () => {
  const [currentValues, setCurrentValues] = useState([
    { id: 1, title: "CodeMino", value: true },
    { id: 2, title: "CodeBoo", value: false },
    { id: 3, title: "CodeCizz", value: false },
    { id: 4, title: "CodeLab", value: true },
  ]);
  const schoolId = localStorage.getItem("schoolId");

  const { data, isSuccess } =
    useGetSchoolRestrictionsQuery(schoolId);

  const [updateSchoolRestrictions] = useUpdateSchoolRestrictionsMutation();

  useEffect(() => {
    if (isSuccess) {
      setCurrentValues([
        {
          id: 1,
          title: "CodeMino",
          value: data.game_access.includes(1) ? true : false,
        },
        {
          id: 2,
          title: "CodeBoo",
          value: data.game_access.includes(2) ? true : false,
        },
        {
          id: 3,
          title: "CodeCizz",
          value: data.game_access.includes(3) ? true : false,
        },
        { id: 4, title: "CodeLab", value: data.learning_path_access },
      ]);
    }
    //eslint-disable-next-line
  }, [isSuccess]);

  //! confirm
  const [open, setOpen] = useState(false);
  const [courseId, setCourseId] = useState(null);
  const handleCancel = () => setOpen(false);
  const changeStatus = (id) => {
    setCourseId(id);
    setOpen(true);
  };
  const handleCourse = async () => {
    toggleValue(courseId);
  };

  const toggleValue = (id) => {
    const newValues = currentValues.map((item) => {
      if (item.id === id) {
        return { ...item, value: !item.value };
      }
      return item;
    });
    setCurrentValues(newValues);
    localStorage.setItem("schoolRestrictionValues", JSON.stringify(newValues));
    sendSchoolRestrictions();
  };

  function findTrueValues(objList) {
    const trueIds = [];

    for (const obj of objList) {
      if (obj.value === true) {
        trueIds.push(obj.id);
      }
    }

    return trueIds;
  }
  function sendSchoolRestrictions() {
    const newValues = JSON.parse(
      localStorage.getItem("schoolRestrictionValues")
    );
    updateSchoolRestrictions({
      id: schoolId,
      learning_path_access: newValues[3].value,
      game_access: findTrueValues(newValues.slice(0, 3)),
    });
    setOpen(false);
  }
  return (
    <div className="row">
        <Confirm
        open={open}
        setOpen={setOpen}
        onConfirm={handleCourse}
        onCancel={handleCancel}
      />
      <div className="col">
        <RestrictionCard>
          <div style={{ minWidth: "250px", height: "250px" }}>
            <table className="table mt-4">
              <tbody>
                {currentValues.map((item) => (
                  <tr key={item.id}>
                    <td className="d-flex align-items-center gap-2">
                      <input
                        type="checkbox"
                        checked={!item.value}
                        // onChange={(e) => toggleValue(item.id)}
                        onChange={(e) => changeStatus(item.id)}
                      />
                      {item.title}
                      {!item.value && (
                        <Icon
                          icon="Lock"
                          color="danger"
                          size="lg"
                          className="me-1"
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </RestrictionCard>
      </div>
    </div>
  );
};

export default CodelabAndGamesList;
