import AVATARS from "../../../common/data/avatarsDummy";
import { nanoid } from "nanoid";
import showNotification from "../../../components/extras/showNotification";
import Icon from "../../../components/icon/Icon";

export const avatars = [
    { text: "boy1", value: AVATARS.boy_1 },
    { text: "boy2", value: AVATARS.boy_2 },
    { text: "boy3", value: AVATARS.boy_3 },
    { text: "boy4", value: AVATARS.boy_4 },
    { text: "boy5", value: AVATARS.boy_5 },
    { text: "boy6", value: AVATARS.boy_6 },
    { text: "boy7", value: AVATARS.boy_7 },
    { text: "boy8", value: AVATARS.boy_8 },
    { text: "boy9", value: AVATARS.boy_9 },
    { text: "boy10", value: AVATARS.boy_10 },
    { text: "girl1", value: AVATARS.girl_1 },
    { text: "girl2", value: AVATARS.girl_2 },
    { text: "girl3", value: AVATARS.girl_3 },
    { text: "girl4", value: AVATARS.girl_4 },
    { text: "girl5", value: AVATARS.girl_5 },
    { text: "girl6", value: AVATARS.girl_6 },
    { text: "girl7", value: AVATARS.girl_7 },
    { text: "girl8", value: AVATARS.girl_8 },
    { text: "girl9", value: AVATARS.girl_9 },
    { text: "girl10", value: AVATARS.girl_10 },
  ];

  export const years = []
  const currentYear = new Date().getFullYear();

  for (let i = currentYear; i >= currentYear - 100; i--) {
    years.push({ text: String(i), value: i, key:nanoid() });
  }

  export function capitalize(word) {
    if (word) {
      const lower = word.toLowerCase();
      return word.charAt(0).toUpperCase() + lower.slice(1);
    }
    return;
  }

  export function showError(customError) {
    showNotification(
      <span className="d-flex align-items-center">
        <Icon icon="danger" size="lg" className="me-1" />
        {customError || <span>Kayıt Başarısız</span>}
      </span>,
      "Bir hata oluştu",
      "danger"
    );
  }

  export function showSuccess(message) {
    showNotification(
      <span className="d-flex align-items-center">
        <Icon icon="Info" size="lg" className="me-1" />
        <span>Kayıt Başarılı</span>
      </span>,
      message,
      "success"
    );
  }

  export const getLocalStorageItem = (key) => {
    let value = null;
  
    try {
      const storedValue = localStorage.getItem(key);
      if (storedValue === null) {
        value = JSON.parse(storedValue);
      }else{
        value = storedValue
      }
    } catch (error) {
      console.error('Error parsing JSON from localStorage:', error);
      value = null; // veya uygun bir varsayılan değer
    }
  
    return value;
  };