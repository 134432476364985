import React from "react";
import Button from "../../../../../components/bootstrap/Button";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../../components/bootstrap/Modal";

const UserInfoModal = ({open,setOpen,onCancel,onConfirm,title = 'Dikkat',message='İşlemi onaylıyor musunuz?'}) => {
    
    return (
      <div>
        <Modal
          isOpen={open}
          setIsOpen={setOpen}
          titleId="example-title"
        >
          <ModalHeader>
            <ModalTitle id="example-title">
                {/* {title} */}
                <div className="d-flex justify-content-center align-items-center my-1">
        <img className="thumb" src="/images/friday-happy.gif" alt="tebrikler" />
        <h3>Tebrikler! Sertifika almaya hak kazandınız.</h3>
      </div>
                </ModalTitle>
          </ModalHeader>
          <ModalBody>
          <div className="mx-auto text-center">
          <p style={{ fontSize: "20px" }}>
            Profil sayfanızdan ad soyad bilginizi girdikten sonra sertifikanızı görüntüleyebilirsiniz.
          </p>
        </div>
          </ModalBody>
          <ModalFooter>
            <Button color="info" onClick={onCancel}>
              İptal
            </Button>
            <Button color="success" onClick={onConfirm}>
              Profil Sayfasına Git
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  };
  
  export default UserInfoModal;