import React, { useEffect, useState, useContext } from "react";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { useLocation } from "react-router-dom";
import DashboardHeader from "../../common/Headers/DashboardHeader";
import HeaderNavigate from "./HeaderNavigate";
import ProfileLeftMenu from "./ProfileLeftMenu";
import ProfileDetail from "./ProfileDetail";
import ChangePassword from "./ChangePassword";
import USER_ROLES from "../../../common/data/enumUserRole";
import { AuthContext } from "../../../store/auth-store";
import AccountDetail from "./AccountDetail";
import ActivationKey from "./ActivationKey";
import ParentInformationSystem from "./ParentInformationSystem";

const ProfilPage = () => {
  const { user } = useContext(AuthContext);
  const roles = user.roles;
  const userRole =
    (roles === "" && 'Misafir') ||
    (roles === null && 'Kullanıcı') ||
    (roles === USER_ROLES.STUDENT.id && USER_ROLES.STUDENT.name) ||
    (roles === USER_ROLES.TEACHER.id && USER_ROLES.TEACHER.name) ||
    (roles === USER_ROLES.SCHOOL_MANAGER.id && USER_ROLES.SCHOOL_MANAGER.name) ||
    (roles === USER_ROLES.EDITOR.id && USER_ROLES.EDITOR.name) ||
    (roles === USER_ROLES.USER_SUPPORT.id && USER_ROLES.USER_SUPPORT.name) ||
    (roles === USER_ROLES.ADMIN.id && USER_ROLES.ADMIN.name);

  const location = useLocation();

  const TABS = {
    PROFIL_DETAIL: "Profil Details",
    CHANGE_PASSWORD: "Change Password",
    ACCOUNT_DETAIL: "Account Details",
    ACTIVATION_KEY: "Activation Key",
    PARENT_INFORMATION_SYSTEM: "Parent Information System",
  };
  const [activeTab, setActiveTab] = useState(TABS.PROFIL_DETAIL);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const activeTabParam = searchParams.get('activeTab');
    if (activeTabParam) {
      setActiveTab(activeTabParam);
    }
  }, [location.search]);

  // useEffect(() => {
  //   if (window.Tawk_API) {
  //     window.Tawk_API.hideWidget();
  //   }
  //   return () => {
  //     if (window.Tawk_API) {
  //       window.Tawk_API.showWidget();
  //     }
  //   };
  // }, []);

  return (
    <PageWrapper title={"Profil Bilgileri"}>
      <DashboardHeader/>
      <div className="mt-5 mt-md-0 pt-4 pt-md-0">
      <HeaderNavigate />
      </div>
      <Page>
        <div className="row h-100">
          <div className="col-xl-3 col-lg-4 col-md-4">
            <ProfileLeftMenu userRole={userRole} activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>
          <div className="col-xl-9 col-lg-8 col-md-8">
            {TABS.PROFIL_DETAIL === activeTab && (
              <ProfileDetail userRole={userRole}/>
            )}
            {TABS.CHANGE_PASSWORD === activeTab && (
              <ChangePassword />
            )}
            {TABS.ACCOUNT_DETAIL === activeTab && (
              <AccountDetail />
            )}
            {TABS.ACTIVATION_KEY === activeTab && (
              <ActivationKey />
            )}
            {TABS.PARENT_INFORMATION_SYSTEM === activeTab && (
              <ParentInformationSystem />
            )}
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default ProfilPage;
