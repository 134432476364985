import React from 'react';
import Card, {
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
  } from "../../../components/bootstrap/Card";
  import Button from "../../../components/bootstrap/Button";
  import USER_ROLES from "../../../common/data/enumUserRole";

  const ProfileLeftMenu = (props) => {
    const {activeTab,setActiveTab,userRole} = props;
    const TABS = {
        PROFIL_DETAIL: "Profil Details",
        CHANGE_PASSWORD: "Change Password",
        ACCOUNT_DETAIL: "Account Details",
        ACTIVATION_KEY: "Activation Key",
        PARENT_INFORMATION_SYSTEM: "Parent Information System",
      };
    return (
        <Card stretch>
        <CardHeader>
          <CardLabel icon="Person" iconColor="info">
            <CardTitle>Profil Bilgileri</CardTitle>
          </CardLabel>
        </CardHeader>
        <CardBody isScrollable>
          <div className="row g-3">
            <div className="col-12">
              <Button
                icon="Contacts"
                color="info"
                className="w-100 p-3"
                isLight={TABS.PROFIL_DETAIL !== activeTab}
                onClick={() => setActiveTab(TABS.PROFIL_DETAIL)}
              >
                Profilim
              </Button>
            </div>
            <div className="col-12">
              <Button
                icon="Contacts"
                color="info"
                className="w-100 p-3"
                isLight={TABS.CHANGE_PASSWORD !== activeTab}
                onClick={() => setActiveTab(TABS.CHANGE_PASSWORD)}
              >
                Parola Değiştir
              </Button>
            </div>
            {userRole === USER_ROLES.STUDENT.name &&
            <div className="col-12">
            <Button
              icon="Contacts"
              color="info"
              className="w-100 p-3"
              // isDisable={true}
              isLight={TABS.ACCOUNT_DETAIL !== activeTab}
              onClick={() => setActiveTab(TABS.ACCOUNT_DETAIL)}
            >
              Hesap Bilgileri
            </Button>
          </div>
            }
            {userRole === USER_ROLES.STUDENT.name &&
            <div className="col-12">
            <Button
              icon="Contacts"
              color="info"
              className="w-100 p-3"
              // isDisable={true}
              isLight={TABS.ACTIVATION_KEY !== activeTab}
              onClick={() => setActiveTab(TABS.ACTIVATION_KEY)}
            >
              Aktivasyon Kodu
            </Button>
          </div>
            }
            <hr />
            {userRole === USER_ROLES.STUDENT.name &&
            <div className="col-12">
            <Button
              icon="Contacts"
              color="info"
              className="w-100 p-3"
              // isDisable={true}
              isLight={TABS.PARENT_INFORMATION_SYSTEM !== activeTab}
              onClick={() => setActiveTab(TABS.PARENT_INFORMATION_SYSTEM)}
            >
              Veli Bilgilendirme Sistemi
            </Button>
          </div>
            }
            <div className="col-12 border-bottom" />
          </div>
        </CardBody>
      </Card>
    );
};

export default ProfileLeftMenu;