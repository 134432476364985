import React, { useState } from "react";
import {
  useGetAttendanceOf_a_LiveLectureQuery,
  useUpdateAttendanceOf_a_LiveLectureMutation,
} from "../../../teacherApi";
import Checks from "../../../../../../components/bootstrap/forms/Checks";
import Spinner from "../../../../../../components/bootstrap/Spinner";

const AttendanceOfSingleLecture = ({ attendance, user, liveLessons }) => {
  const { id: liveLessonId, student_status } = attendance;
  const [busy, setBusy] = useState(false);

  const liveLesson = liveLessons.filter((item) => item.id === liveLessonId);
  const liveLectureDate = liveLesson[0].live_lecture.date;

  // mutations and queries
  const { data: attendanceList } =
    useGetAttendanceOf_a_LiveLectureQuery(attendance.id);

  const [updateAttendanceOfLiveLecture] =
    useUpdateAttendanceOf_a_LiveLectureMutation();

  const currentUser =
    attendanceList &&
    attendanceList.length > 0 &&
    attendanceList.filter((item) => item.student === user);

  let newUser = currentUser && currentUser[0];

  let newStatus = student_status === 2 ? 1 : 2;

  newUser = { ...newUser, status: newStatus };
  newUser = [newUser];
  
  const handleAttendance = async () => {
    setBusy(true);
    const data = newUser;
    const response = await updateAttendanceOfLiveLecture({
      liveLectureId: liveLessonId,
      data: data,
    });
    if (response.error) {
      console.log(response.error);
    } else {
    }
    setBusy(false);
  };

  function calculateEndDateAndCheckIfGreaterThanToday(startDate) {
    const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000; // 1 hafta süresi (milisaniye cinsinden)
    const endDate = new Date(startDate.getTime() + oneWeekInMilliseconds);

    const isEndDate = (date) => {
      const now = new Date();
      return date instanceof Date && date > now;
    };

    const isEndDateGreaterThanToday = isEndDate(endDate);

    return {
      endDate,
      isEndDateGreaterThanToday,
    };
  }

  const inputDate = new Date(liveLectureDate); // Başlangıç tarihi
  const { isEndDateGreaterThanToday } =
    calculateEndDateAndCheckIfGreaterThanToday(inputDate);

  return (
    <div className="d-flex">
      <Checks
        type="checkbox"
        value={student_status}
        disabled={!isEndDateGreaterThanToday || busy}
        checked={student_status === 2 ? true : false}
        onChange={handleAttendance}
      />
       {busy && (
                        <Spinner color="primary" inButton isSmall>
                          Loading...
                        </Spinner>
                      )}
      {/* {liveLessonId}{currentUserId} */}
    </div>
  );
};

export default AttendanceOfSingleLecture;
